import React, { useEffect, useState } from 'react'
import { checkNameAvailabilityFromServer, schema } from '../../utils/JobUtils'

import AddEditJobPopUp from '../JobMonitoring/JMDashboard/AddEditPopUp'
import { ProjectType } from '../../enum/ProjectType.enum'
import StringConstants from '../../constants/StringConstants'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../common/DDS/Toast/Toast'
import { ValidationResult } from 'joi'
import { configureJob } from '../../api/jobs/Jobs.service'
import { getOrganizationProjectsThingsToDo } from '../../api/organization/Organization.service'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useStoreContext } from '../../store/StoreContext'

interface JobFormData {
  name: string
  slug: string
  description: string
  cronExpression: string
  monitorJob: boolean
}

interface addJobPopupProps {
  newJobOpen: boolean
  setNewJobOpen: any
  navigateToJobMonitoring: any
}
const AddJobPopup: React.FC<addJobPopupProps> = ({
  newJobOpen,
  setNewJobOpen,
  navigateToJobMonitoring
}) => {
  const store = useStoreContext()

  //jobStates
  const defaultFormData: JobFormData = {
    name: '',
    slug: '',
    description: '',
    cronExpression: '',
    monitorJob: true
  }
  const [jobFormData, setJobFormData] = useState<any>(defaultFormData)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [isInvalidJobName, setIsInvalidJobName] = useState(false)
  const [isInvalidJobSlug, setIsInvalidJobSlug] = useState(false)
  useEffect(() => {
    const validated: ValidationResult = schema.validate(
      {
        name: jobFormData.name.trim(),
        slug: jobFormData.slug.trim(),
        cronExpression: jobFormData.cronExpression,
        description: jobFormData.description
      },
      {
        abortEarly: false
      }
    )
    const requiredFields = Object.keys(jobFormData).filter(
      (field) => field !== 'description'
    )
    const areAllRequiredFieldsFilled = requiredFields.every(
      (field) => jobFormData[field] !== '' && jobFormData[field] !== null
    )
    if (validated.error) {
      setIsButtonDisabled(true)
    } else {
      setIsButtonDisabled(!areAllRequiredFieldsFilled)
    }
  }, [jobFormData])

  const resetJobForm = () => {
    setJobFormData(defaultFormData)
  }
  const resetCheckNameValidation = () => {
    setIsInvalidJobSlug(false)
    setIsInvalidJobName(false)
  }

  const cancelAddJob = () => {
    resetCheckNameValidation()
    setNewJobOpen(false)
    resetJobForm()
  }
  const handleJobDataChange = (field: string, value: string) => {
    resetCheckNameValidation()
    setJobFormData((prevData: any) => {
      const updatedData = { ...prevData, [field]: value }

      if (
        field === 'name' &&
        prevData.slug ===
          prevData.name
            .toLowerCase()
            .replace(/-/g, '')
            .replace(/_/g, '-')
            .replace(/[^a-z0-9\s-]/g, '')
            .replace(/\s+/g, '-')
      ) {
        updatedData.slug = value
          .toLowerCase()
          .replace(/-/g, '')
          .replace(/_/g, '-')
          .replace(/[^a-z0-9\s-]/g, '')
          .replace(/\s+/g, '-')
      }

      return updatedData
    })
  }
  const handleAddMonitorSubmit = () => {
    setButtonLoading(true)
    const nameCheckPromises = []

    if (!isEmpty(jobFormData.name)) {
      nameCheckPromises.push(
        checkNameAvailabilityFromServer(jobFormData.name.trim())
      )
    }
    if (!isEmpty(jobFormData.slug)) {
      nameCheckPromises.push(
        checkNameAvailabilityFromServer(jobFormData.slug.trim(), true)
      )
    }
    if (nameCheckPromises.length > 0) {
      Promise.allSettled(nameCheckPromises)
        .then((results) => {
          let success = true
          results.forEach((result, index) => {
            if (result.status !== 'fulfilled') {
              success = false
              if (!isEmpty(jobFormData.name) && index === 0) {
                if (result.reason === StringConstants.NAME_ALREADY_TAKEN) {
                  setIsInvalidJobName(true)
                } else {
                  ToastNotification({
                    type: 'error',
                    message: ToastMessageConstants.SOMETHING_WENT_WRONG
                  })
                }
              } else {
                if (result.reason === StringConstants.SLUG_ALREADY_TAKEN) {
                  setIsInvalidJobSlug(true)
                } else {
                  ToastNotification({
                    type: 'error',
                    message: ToastMessageConstants.SOMETHING_WENT_WRONG
                  })
                }
              }
            }
          })
          if (success) {
            addJob()
          } else {
            setButtonLoading(false)
          }
        })
        .catch((err) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.SOMETHING_WENT_WRONG
          })
          setButtonLoading(false)
        })
    } else {
      addJob()
    }
  }

  const addJob = () => {
    store.uiStore.setGlobalLoader(true)
    configureJob(jobFormData)
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.JOB_MONITORING.CREATE_JOB.SUCCESS
        })
        getOrganizationProjectsThingsToDo()
          .then((response: any) => {
            store.thingsToDoStore.setProgressData(response.data)
            if (
              (response?.data?.progress == 3 &&
                (store.scopeStore.getSelectedProject().type ==
                  ProjectType.SALESFORCE_ONLY ||
                  store.scopeStore.getSelectedProject().type ==
                    ProjectType.MULESOFT_ONLY)) ||
              (response?.data?.progress == 5 &&
                store.scopeStore.getSelectedProject().type ==
                  ProjectType.FULL_STACK_ONLY)
            ) {
              store.thingsToDoStore.setShowThingsToDo(false)
            } else {
              store.thingsToDoStore.setShowThingsToDo(true)
            }
          })
          .catch((error) => console.log(error))
          .finally(() => {
            store.uiStore.setGlobalLoader(false)
          })
        resetCheckNameValidation()
        navigateToJobMonitoring()
      })
      .catch((err) => {
        setButtonLoading(false)
        store.uiStore.setGlobalLoader(false)
        const quotaErrorRegex =
          /Job quota in the organization \(id\)=\([0-9a-fA-F-]{36}\) is full\. Subscribe to get more jobs\./
        if (quotaErrorRegex.test(err)) {
          ToastNotification({
            type: 'error',
            message:
              ToastMessageConstants.JOB_MONITORING.CREATE_JOB.ERROR
                .LIMIT_EXCEEDED
          })
        } else {
          ToastNotification({
            type: 'error',
            message:
              ToastMessageConstants.JOB_MONITORING.CREATE_JOB.ERROR.DEFAULT
          })
        }
      })
      .finally(() => {
        resetJobForm()
        setNewJobOpen(false)
        setButtonLoading(false)
      })
  }

  return (
    <AddEditJobPopUp
      open={newJobOpen}
      isAdd
      setOpen={setNewJobOpen}
      onCancel={cancelAddJob}
      onSubmit={handleAddMonitorSubmit}
      loading={store.uiStore.getGlobalLoader()}
      buttonLoading={buttonLoading}
      buttonDisabled={isButtonDisabled}
      values={jobFormData}
      isInvalidJobName={isInvalidJobName}
      isInvalidJobSlug={isInvalidJobSlug}
      handleInputChange={handleJobDataChange}
    />
  )
}

export default observer(AddJobPopup)
