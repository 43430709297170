import Joi, { ValidationErrorItem } from 'joi'
import React, { useEffect, useState } from 'react'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'
import { isEmpty, isNull } from 'lodash'

import { BtoA } from '../../../utils/UrlUtils'
import Button from '../../common/Button/Button'
import Dropdown from '../../common/Dropdown/Dropdown'
import InputField from '../../common/InputField/InputField'
import { IoIosArrowForward } from 'react-icons/io'
import MulesoftIcon from '../../common/Icon/MulesoftIcon'
import { ProjectType } from '../../../enum/ProjectType.enum'
import RegexConstants from '../../../constants/RegexConstants'
import SalesforceIcon from '../../common/Icon/SalesforceIcon'
import StringConstants from '../../../constants/StringConstants'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import ToggleButton from '../../common/ToggleButtons/ToggleButtons'
import { TypographyDDS } from '../../common/Typography/TypographyDDS'
import UrlConstants from '../../../constants/UrlConstants'
import ValidationConstants from '../../../constants/ValidationConstants'
import { checkNameAvailability } from '../../../api/common/Common.service'
import { getValidationStatus } from '../../../utils/ValidationUtils'
import infoIcon from '../../../assets/icons/infoIcon.svg'
import { observer } from 'mobx-react-lite'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'
import thingsToDo from '../../../pages/ThingsToDo/thingsToDo'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useStoreContext } from '../../../store/StoreContext'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.375em;
  gap: 1.25em;
`
const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.625em;
  z-index: 100;
`
const ColorText = styled.div`
  color: ${palette.colors.primary};
  display: inline;
`
const NameIntervalContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.625em;
`
const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement='top' />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    backgroundColor: palette.colors.white,
    border: `1.5px solid ${palette.colors.borderColor}`,
    boxShadow: `0px 4px 15px rgba(0, 0, 0, 0.25)`
  }
})

const ContentDisplayContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // margin-top: 25px;
  // padding-bottom: 20px;
  align-items: center;
`
const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // padding-bottom: 20px;
  gap: 30px;
`
const ParaDark = styled.div`
  font-weight: 400;
  line-height: 20px;
  color: ${palette.colors.textDark2};
`

const BasicInfoStep: React.FC<{
  handleNext?: (data: any) => void
  formState: any
  setFormState: any
  fromThingsToDo?: boolean
}> = ({ handleNext = () => null, formState, setFormState, fromThingsToDo }) => {
  const store = useStoreContext()
  const [checked, setChecked] = useState<boolean>(false)
  const gaEventTracker = useAnalyticsEventTracker(gaPageConstants.BASIC_INFO)
  const [selectedProjectId, setSelectedProjectId] = React.useState<
    string | null
  >(null)
  const [noProjectAdded, setNoProjectAdded] = React.useState<boolean>(false)
  const [noEnvironmentAdded, setNoEnvironmentAdded] =
    React.useState<boolean>(false)
  const [selectedEnvId, setSelectedEnvId] = React.useState<string | null>(null)

  useEffect(() => {
    if (store.addCheckStore.getData().id) {
      const { url, checkName, checkInterval, elementId, checkContent } =
        store.addCheckStore.getData()
      setFormState({
        elementId: elementId || '',
        url,
        checkName,
        checkInterval,
        checkContent
      })
    }
  }, [store.addCheckStore.getData()])

  const [validationErrorItems, setValidationErrorItems] = useState<
    ValidationErrorItem[]
  >([])
  const check = store.ahcReportsStore.getReports().find((item: any) => {
    return item.id === store.addCheckStore.getData().id
  })
  const [isInvalidAppCheckName, setIsInvalidAppCheckName] = useState(false)
  const [checkNameHelperText, setCheckNameHelperText] =
    React.useState<string>('')
  const schema = Joi.object({
    checkName: Joi.string().pattern(RegexConstants.NAME_REGEX).required(),
    elementId:
      formState.checkContent || formState.elementId
        ? Joi.string().required()
        : '',
    url: Joi.string().uri().required()
  })
  const intervalDropdown = [
    {
      id: '1',
      name: '15 minutes',
      value: 15
    },
    {
      id: '2',
      name: '5 minutes',
      value: 5
    },
    {
      id: '3',
      name: '1 minute',
      value: 1
    }
  ]
  const [selectedOption, setSelectedOption] = React.useState<{
    id: string
    name: string
    value: number
  }>({
    id: '1',
    name: '15 minutes',
    value: 15
  })

  useEffect(() => {
    if (!fromThingsToDo)
      setSelectedEnvId(store.scopeStore.getSelectedEnvironment().id)
  }, [store.scopeStore.getSelectedEnvironment().type])

  useEffect(() => {
    if (!isEmpty(store.projectAndEnvironmentStore.getEnvironmentName())) {
      const environmentType =
        store.projectAndEnvironmentStore.getEnvironmentName() ==
        StringConstants.ENVIRONMENT_NAMES.PROD
          ? StringConstants.ENVIRONMENT_TYPE_VALUES.PROD
          : StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
      const environment = store.scopeStore
        .getEnvironments()
        .filter((record: any) => record.type == environmentType)
      setSelectedEnvId(environment[0]?.id)
    }
  }, [store.projectAndEnvironmentStore.getEnvironmentName()])

  useEffect(() => {
    if (store.addCheckStore.getData()) {
      const { checkInterval } = store.addCheckStore.getData()
      const interval = intervalDropdown.find(
        (interval) => interval.value === checkInterval
      )
      if (interval) setSelectedOption(interval)
    }
  }, [store.addCheckStore.getData()])

  const handleIntervalSelect = (id: string) => {
    const interval = intervalDropdown.find((interval) => interval.id === id)
    const value = interval?.value || 15
    setFormState({ ...formState, checkInterval: value })
    if (interval) setSelectedOption(interval)
  }

  const getProjectsWithType = () => {
    const projects = store.scopeStore.getProjects()
    projects.forEach((p) => {
      if (p.type === ProjectType.SALESFORCE_ONLY)
        p.icon = <SalesforceIcon width={18} height={18} />
      if (p.type === ProjectType.MULESOFT_ONLY)
        p.icon = <MulesoftIcon width={18} height={18} />
    })
    return projects
  }

  const getEnvironmentsOfProjects = () => {
    if (store.scopeStore.getSelectedProject().id) {
      const envs = store.projectAndEnvironmentStore
        .getProjectsAndEnvironments()
        .filter(
          (projectAndEnvironment: any) =>
            projectAndEnvironment.id ===
            store.scopeStore.getSelectedProject()?.id
        )
        ?.map((selectProject: any) => selectProject.environments)
      return envs[0].map((environment: any) => {
        return {
          ...environment,
          name:
            environment.type == StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
              ? StringConstants.ENVIRONMENT_NAMES.NON_PROD
              : StringConstants.ENVIRONMENT_NAMES.PROD
        }
      })
    } else return []
  }

  const handleProjectChange = (id: string) => {
    console.log(id)
    setSelectedProjectId(id)
    store.scopeStore.setProjectId(id)
    const project = store.scopeStore
      .getProjects()
      .filter((record: any) => record.id === id)
    store.scopeStore.setSelectedProject(project[0])
    store.projectAndEnvironmentStore.setSelectedProject(project[0])
  }

  const handleEnvironmentChange = (id: string) => {
    const env = store.scopeStore.getEnvironments().filter((env) => {
      return env.id == id
    })
    store.scopeStore.setEnvironmentId(id)
    if (!isEmpty(env)) {
      const updatedEnvironmentName =
        env[0]?.type == StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
          ? StringConstants.ENVIRONMENT_NAMES.NON_PROD
          : StringConstants.ENVIRONMENT_NAMES.PROD
      store.projectAndEnvironmentStore.setEnvironmentName(
        updatedEnvironmentName
      )
      // store.scopeStore.setSelectedEnvironment(env[0])
    }
    setSelectedEnvId(id)
    if (id) {
      setNoEnvironmentAdded(false)
    }
  }

  return (
    <Container>
      <TypographyDDS.Title
        type='h3'
        variant='medium'
        style={{ textAlign: 'center' }}
      >
        Provide the following <ColorText>details</ColorText>
      </TypographyDDS.Title>
      {fromThingsToDo ? (
        <DropdownContainer>
          <Dropdown
            id='project-dashboard-dropdown'
            ignoreTopNavBar={true}
            title='Project'
            maxWidth='280px'
            secondaryTitleText='Select Projects'
            borderRadius='0 0 8px 8px'
            dropdownOptions={getProjectsWithType()}
            padding='8px 10px'
            selectedOptionFontSize='14px'
            handleChange={handleProjectChange}
            defaultSelected={{
              name: store.scopeStore.getSelectedProject().name,
              icon:
                store.scopeStore.getSelectedProject().id &&
                store.scopeStore.getSelectedProject().type ===
                  ProjectType.SALESFORCE_ONLY ? (
                  <SalesforceIcon width={18} height={18} />
                ) : undefined
            }}
            minWidth='200px'
            disabled={true}
          />
          <Dropdown
            id='environment-dashboard-dropdown'
            ignoreTopNavBar={true}
            title='Environment'
            maxWidth='280px'
            secondaryTitleText='Select Environment'
            borderRadius='0 0 8px 8px'
            dropdownOptions={getEnvironmentsOfProjects()}
            padding='8px 10px'
            selectedOptionFontSize='14px'
            handleChange={handleEnvironmentChange}
            defaultSelected={{
              name: store.projectAndEnvironmentStore.environmentName
            }}
            minWidth='200px'
            disabled={
              store.uiStore.getGlobalLoader() || store.addCheckStore.loading
            }
            error={
              noEnvironmentAdded &&
              isEmpty(store.projectAndEnvironmentStore.getEnvironmentName())
            }
            helperText={
              noEnvironmentAdded &&
              isEmpty(store.projectAndEnvironmentStore.getEnvironmentName())
                ? ValidationConstants.ENVIRONMENT.ENVIRONMENT_TYPE
                : ''
            }
          />
        </DropdownContainer>
      ) : (
        <DropdownContainer>
          <InputField
            label='Project'
            value={
              store.scopeStore.getSelectedProject().name
                ? store.scopeStore.getSelectedProject().name
                : ''
            }
            disabled
            width='100%'
          />
          <InputField
            label='Environment'
            value={
              store.scopeStore.getSelectedEnvironment().type
                ? store.scopeStore.getSelectedEnvironment().type ==
                  StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
                  ? StringConstants.ENVIRONMENT_NAMES.NON_PROD
                  : StringConstants.ENVIRONMENT_NAMES.PROD
                : ''
            }
            disabled
            width='100%'
          />
        </DropdownContainer>
      )}
      <InputField
        id='Check URL'
        label='Check URL'
        fullWidth
        name='url'
        onChange={(e) => {
          setFormState({ ...formState, url: e.target.value })
        }}
        value={formState.url}
        error={getValidationStatus(validationErrorItems, 'url')}
        helperText={
          getValidationStatus(validationErrorItems, 'url')
            ? ValidationConstants.CREATE_CHECK.URL
            : ''
        }
      />
      <NameIntervalContainer>
        <InputField
          id='Application Name'
          label='Application Name'
          fullWidth
          name='checkName'
          onChange={(e) =>
            setFormState({ ...formState, checkName: e.target.value })
          }
          value={formState.checkName}
          maxLength={StringConstants.NAME_FIELD_MAX_LENGTH}
          error={
            getValidationStatus(validationErrorItems, 'checkName') ||
            isInvalidAppCheckName
          }
          helperText={
            getValidationStatus(validationErrorItems, 'checkName')
              ? ValidationConstants.CREATE_CHECK.CHECK_NAME
              : checkNameHelperText
          }
        />
        <span>
          <Dropdown
            dropdownOptions={intervalDropdown}
            title='Select Check Interval'
            defaultSelected={selectedOption}
            handleChange={handleIntervalSelect}
            // disabled
          />
        </span>
      </NameIntervalContainer>
      <ContentDisplayContainer>
        <div style={{ display: 'flex' }}>
          <TypographyDDS.Title
            id='content toggle'
            type='h5'
            variant='medium'
            color='textDark2'
          >
            Content Display Monitoring
          </TypographyDDS.Title>

          <NoMaxWidthTooltip
            title={
              <div>
                <TypographyDDS.Paragraph
                  size='caption'
                  variant='semiBold'
                  color='textDark5'
                >
                  Enable this to ensure the website content is displayed to your
                  users
                </TypographyDDS.Paragraph>
              </div>
            }
          >
            <img
              src={infoIcon}
              style={{
                marginLeft: '4px',
                alignSelf: 'start',
                paddingRight: '4px',
                paddingTop: '4px',
                height: '18px',
                width: '18px'
              }}
            />
          </NoMaxWidthTooltip>
        </div>
        <ToggleButton
          checked={checked || formState.checkContent}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setChecked(e.target.checked)
            setFormState({ ...formState, checkContent: e.target.checked })
          }}
        />
      </ContentDisplayContainer>
      {checked || formState.checkContent ? (
        <Content>
          <div
            style={{
              maxWidth: '430px',
              textAlign: 'justify',
              display: 'flex',
              gap: '1.25em'
            }}
          >
            <TypographyDDS.Paragraph
              size='para'
              variant='medium'
              color={'textDark5'}
            >
              Enter the ID value of a HTML tag that is associated to an active
              element of your website.
            </TypographyDDS.Paragraph>

            <InputField
              fullWidth
              id='Element ID'
              label='Element ID'
              placeholder='Enter ID Value'
              onChange={(e) => {
                setFormState({ ...formState, elementId: e.target.value })
              }}
              value={formState.elementId}
              error={getValidationStatus(validationErrorItems, 'elementId')}
              helperText={
                getValidationStatus(validationErrorItems, 'elementId')
                  ? ValidationConstants.CREATE_CHECK.ELEMENT_ID
                  : ''
              }
            />
          </div>
        </Content>
      ) : (
        ''
      )}
      <Button
        id='Primary info Next'
        endIcon={<IoIosArrowForward />}
        variant='contained'
        fullWidth
        loading={store.addCheckStore.loading}
        disabled={store.ahcReportsStore.getLoading()}
        onClick={() => {
          if (!selectedProjectId) {
            setNoProjectAdded(true)
          }
          if (!selectedEnvId) {
            setNoEnvironmentAdded(true)
          }
          // TODO will find a way not to reuse the same logic twice i.e from EditJobsPopup

          if (selectedEnvId) {
            if (fromThingsToDo && selectedProjectId && !isNull(selectedEnvId)) {
              const projectId = selectedProjectId
              store.scopeStore.setProjectId(projectId)
              if (projectId !== store.scopeStore.getSelectedProject().id) {
                gaEventTracker(gaEventConstants.SWITCH_PROJECT)
              }
              const project = store.scopeStore
                .getProjects()
                .filter((record: any) => record.id === projectId)
              store.scopeStore.setSelectedProject(project[0])
              store.projectAndEnvironmentStore.setSelectedProject(project[0])
              if (project && project.length > 0) {
                store.scopeStore.setEnvironments(project[0].environments)
              }
              const envId = selectedEnvId
              store.scopeStore.setEnvironmentId(envId)
              if (envId !== store.scopeStore.getSelectedEnvironment().id) {
                gaEventTracker(gaEventConstants.SWITCH_ENVIRONMENT)
              }
              const environment = store.scopeStore
                .getEnvironments()
                .filter((record: any) => record.id == envId)
              store.scopeStore.setSelectedEnvironment(environment[0])
              store.filterStore.setFiltersApplied(false)
            }

            gaEventTracker(gaEventConstants.ADD_CHECK)
            const type = formState.url.includes('https') ? 'https' : 'http'
            const validationResult = schema.validate(
              { ...formState, checkName: formState.checkName.trim() },

              {
                abortEarly: false,
                allowUnknown: true
              }
            )
            if (validationResult.error)
              setValidationErrorItems(validationResult.error.details)
            else {
              store.addCheckStore.setLoading(true)
              setValidationErrorItems([])
              if (
                store.addCheckStore.getData().checkName === formState.checkName
              ) {
                handleNext(null)
                store.addCheckStore.setData({
                  ...formState
                })
              }
              store.addCheckStore.setLoading(true)

              if (
                check?.checkName.toLowerCase() !==
                formState.checkName.toLowerCase()
              ) {
                checkNameAvailability(
                  UrlConstants.CHECK_HEALTH_CHECK_NAME_AVAILABILITY.USECASE,
                  formState.checkName.trim()
                )
                  .then(() => {
                    handleNext(null)
                    store.addCheckStore.setData({
                      ...formState,
                      checkName: formState.checkName.trim(),
                      type
                    })
                  })
                  .catch((error) => {
                    if (error === StringConstants.NAME_ALREADY_TAKEN) {
                      setIsInvalidAppCheckName(true)
                      setCheckNameHelperText(
                        ValidationConstants.APP_HEALTH_CHECK
                          .APP_HEALTH_CHECK_NAME_NOT_AVAILABLE
                      )
                    } else {
                      ToastNotification({
                        type: 'error',
                        message: ToastMessageConstants.SOMETHING_WENT_WRONG
                      })
                    }
                  })
                  .finally(() => {
                    store.addCheckStore.setLoading(false)
                  })
              } else {
                handleNext(null)
                store.addCheckStore.setLoading(false)
                store.addCheckStore.setData({
                  ...formState,
                  checkName: formState.checkName.trim(),
                  type
                })
              }
            }
          }
        }}
      >
        Next
      </Button>
    </Container>
  )
}

export default observer(BasicInfoStep)
