import * as OrganizationTypes from './Organization.types'

import ApiManager from '../Api.manager'
import { FilterTypes } from '../../enum/Filter.enum'
import HttpConstants from '../../constants/HttpConstants'
import UrlConstants from '../../constants/UrlConstants'
import { getFiltersForApi } from '../../utils/FilterUtils'

export const createOrganization = (
  payload: OrganizationTypes.CreateOrganizationPayload
) => {
  const usecase = UrlConstants.CREATE_ORGANIZATION.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}
// query and header
export const findOrganization = (payload: void) => {
  const usecase = UrlConstants.FIND_ORGANIZATION.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}

// header
export const softDeleteOrganization = (payload: void) => {
  const usecase = UrlConstants.SOFT_DELETE_ORGANIZATION.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.DELETE_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}

// header
export const updateOrganization = (
  payload: OrganizationTypes.UpdateOrganizationPayload
) => {
  const usecase = UrlConstants.UPDATE_ORGANIZATION.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

// query parameter
export const findAllOrganizations = (payload: void) => {
  const usecase = UrlConstants.FIND_ORGANIZATIONS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}

// header
export const restoreOrganization = (payload: void) => {
  const usecase = UrlConstants.RESTORE_ORGANIZATION.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}

// header
export const updateOrganizationMember = (payload: string, _userId: string) => {
  const usecase = UrlConstants.UPDATE_ORGANIZATION_MEMBER.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      { role: payload, '_user-id': _userId }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        let message = error.data?.message
        if (!message) {
          message = error.data.error?.message
        }
        reject(message)
      })
  })
}

//header and query
export const findOrganizationMember = (payload: void) => {
  const usecase = UrlConstants.FIND_ORGANIZATION_MEMBER.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}

export const getOrganizationProjectsThingsToDo = (payload: void) => {
  const usecase = UrlConstants.THINGS_TO_DO_PROGRESS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}

//header and query
export const deleteOrganizationMember = (userId: string) => {
  const usecase = UrlConstants.DELETE_ORGANIZATION_MEMBER.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.DELETE_METHOD,
      { '_user-id': userId }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}
export const getRoleFilters = () => {
  const usecase = UrlConstants.GET_ROLE_FILTER.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      {}
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else throw response
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

//query
export const findAllMembers = (queryParams: { 'expand-user': boolean }) => {
  const usecase = UrlConstants.FIND_ORGANIZATION_MEMBERS.USECASE
  const filters = getFiltersForApi(FilterTypes.EXISTING_MEMBERS, queryParams)
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      filters
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const transferOwnership = (payload: any) => {
  const usecase = UrlConstants.TRANSFER_OWNERSHIP.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response: any) => {
        if (response.status === 200) resolve(response.data)
        else reject(response)
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const uploadOrganizationAvatar = (payload: any) => {
  const usecase = UrlConstants.UPLOAD_ORGANIZATION_AVATAR.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)

        if (error.status === 0) {
          reject('File size is too large. Please choose a smaller file')
        } else if (error.status === 413) {
          reject('File size is too large. Please choose a smaller file.')
        } else if ([401, 403].includes(error.status)) {
          reject(error.data?.message || 'Authentication error')
        } else {
          reject(error.data?.error?.message || 'An unexpected error occurred')
        }
      })
  })
}

export const organizationMemberLookUp = (payload: any) => {
  const usecase = UrlConstants.ORGANIZATION_MEMBER_LOOK_UP.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}
export const unassignedOrganizationMemberLookUpForProject = (payload: any) => {
  const usecase =
    UrlConstants.UNASSIGNED_ORGANIZATION_MEMBER_LOOK_UP_FOR_PROJECT.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}

export const deleteOrganizationAvatar = (organizationId: string) => {
  const usecase = UrlConstants.DELETE_ORGANIZATION_AVATAR.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.DELETE_METHOD,
      { '_organization-id': organizationId }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

//header
