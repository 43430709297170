import { isUndefined, startCase } from 'lodash'

import { flattenObject } from './FlattenObjectsUtils'

export const camelCaseToTitleCase = (heading: any) => {
  const titleCaseHeading = heading.map((camelCaseString: any) => {
    const spacedWords = camelCaseString.replace(/([A-Z])/g, ' $1')
    return startCase(spacedWords)
  })
  return titleCaseHeading
}

export const convertJsonToCsvDownload = (data: any, fileName: string) => {
  const json = data.map((json: any) => flattenObject(json))
  data = json as any[]

  const fields = Object.keys(data[0])
  const heading = camelCaseToTitleCase(fields)
  const replacer = function (key: any, value: any) {
    return !isUndefined(value) ? value : '-'
  }
  let csv = data.map(function (row: any) {
    return fields
      .map(function (fieldName) {
        return JSON.stringify(row[fieldName], replacer)
      })
      .join(',')
  })
  csv.unshift(heading.join(','))
  csv = csv.join('\r\n')
  downLoadFileAsCsv(csv, fileName)
}

export const downLoadFileAsCsv = (data: any, fileName: string) => {
  const blob = new Blob([data as string], {
    type: 'data:text/csv;charset=utf-8,'
  })
  const blobURL = window.URL.createObjectURL(blob)

  // Create new tag for downloading the file
  const anchor = document.createElement('a')
  anchor.download = fileName
  anchor.href = blobURL
  anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(
    ':'
  )
  anchor.click()

  // Remove URL.createObjectURL. The browser should not save the reference to the file.
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    URL.revokeObjectURL(blobURL)
  }, 100)
}
