import { Grid, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import DividerLine from '../../components/common/DividerLine/DividerLine'
import KeyValueTable from '../../components/APIMonitoringPage/KeyValueTable'
import { TypographyDDS } from '../../components/common/Typography/TypographyDDS'
import UsageCard from '../../components/SalesforceAudit/common/UsageCard'
import arrowLeftIcon from '../../assets/icons/leftArrow.svg'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import palette from '../../global/pallete'
import { timeFormats } from '../../enum/TIME'
import { useStoreContext } from '../../store/StoreContext'

const UserDetails: React.FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { salesforceAuditStore } = useStoreContext()
  const loading = salesforceAuditStore.getLoading()
  const data = salesforceAuditStore.getUserById(params.id!)
  const tableData = {
    [`User's Name`]: data.Name,
    'License Type': data.Profile?.UserLicense?.Name,
    Profile: data.Profile?.Name,
    Status: data.IsActive ? 'Active' : 'Inactive',
    'Last Login':
      data.LastLoginDate &&
      moment(data.LastLoginDate).format(timeFormats.fullMonthDateYearTime),
    Email: data.Email
  }
  const handleGoBack = () => {
    const segments = location.pathname.split('/').filter(Boolean)
    segments.pop()
    const newPath = '/' + segments.join('/')
    navigate(newPath)
  }
  return (
    <Grid container width='80%' spacing={2}>
      <Grid item xs={12}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          style={{ cursor: 'pointer' }}
          onClick={() => handleGoBack()}
        >
          <img src={arrowLeftIcon} style={{ height: '16px' }} />
          <TypographyDDS.Paragraph size='para' color='textDark'>
            Go Back
          </TypographyDDS.Paragraph>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <TypographyDDS.Title type='h3' variant='bold'>
          {data.Name}
        </TypographyDDS.Title>
      </Grid>
      <Grid item xs={12}>
        <UsageCard noGutter>
          <TypographyDDS.Title
            type='h5'
            style={{
              margin: '24px'
            }}
          >
            Basic Details
          </TypographyDDS.Title>
          <DividerLine />
          <KeyValueTable data={tableData} noHeader />
        </UsageCard>
      </Grid>
    </Grid>
  )
}
export default observer(UserDetails)
