import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import React, { useEffect, useState } from 'react'

import { TypographyDDS } from '../../Typography/TypographyDDS'
import styled from '@emotion/styled'

interface TimeInputStepperProps {
  time: { hours: number; minutes: number }
  onChange?: (time: { hours: number; minutes: number }) => void
  date?: { from: Date; to: Date }
}

interface InputStepperProps {
  val: number
  onChange: (val: number) => void
  limit: number
}
const Container = styled.div`
  display: flex;
  align-items: center;
`
const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const InputStepper: React.FC<InputStepperProps> = ({
  val,
  onChange = () => null,
  limit
}) => {
  const mod = (n: number, m: number) => ((n % m) + m) % m
  return (
    <StepperContainer>
      <IoIosArrowUp
        fontSize='large'
        onClick={() => onChange(mod(val + 1, limit))}
      />
      <TypographyDDS.Title type='h4' variant='medium'>
        {val}
      </TypographyDDS.Title>
      <IoIosArrowDown
        fontSize='large'
        onClick={() => onChange(mod(val - 1, limit))}
      />
    </StepperContainer>
  )
}

const TimeInputStepper: React.FC<TimeInputStepperProps> = ({
  time,
  onChange,
  date
}) => {
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  useEffect(() => {
    if (date?.from.getDate() === date?.to.getDate() && time.hours >= hours) {
      setHours(time.hours)
    } else {
      setHours(hours)
    }
    if (
      date?.from.getDate() === date?.to.getDate() &&
      hours === time.hours &&
      time.minutes >= minutes
    ) {
      setMinutes(time.minutes)
    } else {
      setMinutes(minutes)
    }
    onChange?.({ hours, minutes })
  }, [minutes, hours])
  return (
    <Container>
      <InputStepper
        val={
          date?.from.getDate() === date?.to.getDate() && time.hours >= hours
            ? time.hours
            : hours
        }
        onChange={setHours}
        limit={24}
      />
      <TypographyDDS.Title
        type='h3'
        variant='medium'
        style={{ marginLeft: '11px', marginRight: '11px' }}
      >
        hrs
      </TypographyDDS.Title>

      <TypographyDDS.Title
        type='h3'
        variant='medium'
        style={{ marginLeft: '11px', marginRight: '11px' }}
      >
        :
      </TypographyDDS.Title>
      <InputStepper
        val={
          date?.from.getDate() === date?.to.getDate() &&
          hours === time.hours &&
          time.minutes >= minutes
            ? time.minutes
            : minutes
        }
        onChange={setMinutes}
        limit={60}
      />
      <TypographyDDS.Title
        type='h3'
        variant='medium'
        style={{ marginLeft: '11px', marginRight: '11px' }}
      >
        mins
      </TypographyDDS.Title>
    </Container>
  )
}

export default TimeInputStepper
