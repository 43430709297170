import { DDSTooltip } from 'den-design-system'
import React from 'react'
import { TooltipProps } from './Tooltip.types'
import pallete from '../../../global/newPallete'

const Tooltip: React.FC<TooltipProps> = ({
  id,
  label,
  additionalLabel,
  className,
  position,
  children
}) => {
  return (
    <DDSTooltip
      style={{ zIndex: 999999, backgroundColor: pallete.colors.surface1 }}
      id={id}
      label={label}
      theme='light'
      shape='roundedRectangle'
      additionalLabel={additionalLabel}
      className={' override-tooltip-arrow ' + className}
      position={position}
    >
      {children}
    </DDSTooltip>
  )
}

export default Tooltip
