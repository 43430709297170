export enum Feature {
  PROJECT = 'project',
  ENVIRONMENT = 'environment',
  USER = 'user',
  EXCEPTION = 'exception',
  JOB_MONITORING = 'jobMonitoring',
  API_MONITORING = 'apiMonitoring',
  APPLICATION_HEALTH_CHECK = 'applicationHealthCheck',
  APPLICATION_HEALTH_CHECK_LOG = 'applicationHealthCheckLog',
  JOB_MONITORING_LOG = 'jobMonitoringLog'
}
