import React, { PropsWithChildren } from 'react'

import pallete from '../../../global/newPallete'

const BottomBar: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      style={{ borderColor: pallete.colors.stroke2 }}
      className={`bottom-0 w-full flex sticky
         justify-end h-[76px] gap-[12px] items-center z-50 px-[32px] py-[20px] border-t-1 shadow-top bg-white`}
    >
      <div className='flex gap-[12px] justify-end'>{children}</div>
    </div>
  )
}

export default BottomBar
