export default {
  DESCRIPTION_PLACEHOLDER: '',
  DESCRIPTION_LABEL: 'Description (Optional)',
  DELETE_JOB_TITLE: 'Delete Job',
  CRON_LABEL: 'Cron Expression',
  JOB_ID_LABEL: 'Job ID',
  JOB_SLUG_LABEL: 'Job Slug',
  JOB_NAME_LABEL: 'Job Name',
  ENVIRONMENT_LABEL: 'Environment',
  PROJECT_LABEL: 'Project',
  EDIT_JOB_TITLE: 'Update Job',
  ADD_MONITOR_TITLE: 'Add Monitor',
  START_MONITOR_SWITCH_LABEL: 'Start',
  STOP_MONITOR_SWITCH_LABEL: 'Stop',
  COPY_JOB_TITLE: 'Import Jobs',
  DELETE_JOB_DESC_TEXT:
    'This action will delete the job and all logs associated with it which can’t be recovered. Are you sure you want to delete this job?',
  COPY_JOB_DESC_TEXT:
    'This action will import all jobs from the selected environment.',
  COPY_JOB_CHOOSE_ENV_TEXT: 'Choose your environment:',
  COPY_JOB_SELECT_ENV_PLACEHOLDER: 'Select Environment',
  COPY_JOB_BUTTON: 'Import Jobs',
  ADD_MONITOR_BUTTON: 'Add Monitor',
  YES_BUTTON: 'Yes',
  NO_BUTTON: 'No',
  CANCEL_BUTTON: 'Cancel',
  DELETE_BUTTON: 'Delete',
  EDIT_BUTTON: 'Edit',
  MONITORING_LABEL: 'Monitoring',
  FILTER_BUTTON: 'Filter',
  RECENT_STATUS: 'Recent Status',
  START_MONITOR_BUTTON_LABEL: 'Start Monitoring',
  STOP_MONITOR_BUTTON_LABEL: 'Stop Monitoring',
  LATEST_CLIENT_VERSION: 'Latest Client Version',
  CREATED_BY: 'Created By',
  CREATED_ON: 'Created On',
  JOB_DESCRIPTION: 'Job Description',
  INTEGRATE_TITLE: 'Integrate to monitor',
  INTEGRATE_CAPTION:
    'Integrate the below code snippet in your code base to start monitoring logs',
  CODE_SNIPPET: 'Code snippet',
  COPIED_TOOLTIP_LABEL: 'Copied',
  FAILED_TO_COPY: 'Failed to copy text',
  ALL_JOBS_TAB: 'All Jobs',
  SUCCESS_JOBS_TAB: 'Success Jobs',
  RUNNING_JOBS_TAB: 'Running Jobs',
  FAILED_JOBS_TAB: 'Failed Jobs',
  OVERVIEW_TAB: 'Overview',
  LOGS_TAB: 'Logs',

  JOB_NAME_HEADER: 'Job Name',
  JOB_ID_HEADER: 'Job ID',
  JOB_SLUG_HEADER: 'Job Slug',
  LATEST_CLIENT_VERSION_HEADER: 'Latest Client Version',
  CREATED_BY_HEADER: 'Created By',
  CREATED_ON_HEADER: 'Created On',
  MONITORING_HEADER: 'Monitoring',
  RECENT_STATUS_HEADER: 'Recent Status',
  NO_SUCCESS_JOBS: 'No Success Job found',
  NO_RUNNING_JOBS: 'No Running Job found',
  NO_FAILED_JOBS: 'No Failed Job found'
}
