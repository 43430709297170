import {
  ChevronDown,
  ChevronUp,
  Notification as NotificationIcon,
  Settings
} from '@carbon/icons-react'
import { DDSMenu, DDSTypography } from 'den-design-system'
import React, { useState } from 'react'
import {
  UrlWithOnlyOrgId,
  UrlWithOrgProjEnvIds,
  replaceUrl
} from '../../../utils/UrlUtils'
import {
  acceptUserInvite,
  findAllMyUserInvites,
  rejectUserInvite
} from '../../../api/userInvites/UserInvites.service'
import { deleteToken, getDataFromCookie } from '../../../utils/AuthUtils'

import { Button } from '../../New/Button/Button'
import HttpConstants from '../../../constants/HttpConstants'
import { InvitePopup } from '../../../pages/NotificationNew/Components/InvitesPopup'
import { FiLogOut as LogOutIcon } from 'react-icons/fi'
import NotificationWrapper from '../../../pages/NotificationNew/Page/NotificationWrapper'
import { OrganizationUserRoles } from '../../../enum/OrganizationUserRoles.enum'
import StringConstants from '../../../constants/StringConstants'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import { addQueryParamsToUrl } from '../../../utils/UrlGenerator'
import { leaveRoom } from '../../../gateway'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'
import vigilIcon from '../../../assets/icons/vigilSmallLogo.svg'

interface CreateOrgNavbarProps {
  onClick?: () => void
  padding?: string
  margin?: string
  width?: string

  onChange: (event: React.SyntheticEvent, newValue: number) => void
  currentTab: number
  inviteMembers: boolean
  showNotifications?: boolean
  // setCurrentTab: React.Dispatch<React.SetStateAction<number>>
}

const Navbar: React.FC<CreateOrgNavbarProps> = ({
  onChange = () => null,
  currentTab,
  inviteMembers,
  showNotifications = false
}) => {
  const store = useStoreContext()

  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [profileMenuOpen, setProfileMenuOpen] = useState(false)
  const [invitePopUpOpen, setInvitePopUpOpen] = useState<boolean>(false)
  const [selectedInvite, setSelectedInvite] = useState<any>()
  const [acceptLoading, setAcceptLoading] = useState<boolean>(false)
  const isDemo = window.location.host.includes('demo.vigilnow.com')
  const [rejectLoading, setRejectLoading] = useState<boolean>(false)
  const notificationCount =
    store.userStore.getNotificationCount() +
    store.userStore.getInvitationCount()
  const navigate = useNavigate()

  //to handle user invite accept
  const handleAcceptUserInvite = () => {
    setAcceptLoading(true)
    acceptUserInvite({ $token: selectedInvite.token })
      .then(() => {
        store.scopeStore.setOrganizationId(selectedInvite.organization.id)
        localStorage.removeItem(
          StringConstants.LOCAL_STORAGE.CURRENT_PAGE_DETAILS
        )
        store.scopeStore.setProjectId('')
        store.scopeStore.setEnvironmentId('')
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.USER_INVITE.ACCEPT_USER_INVITE.SUCCESS
        })

        navigate(
          addQueryParamsToUrl(
            routeConstants.REDIRECT,
            selectedInvite.role !== OrganizationUserRoles.BILLING
              ? {
                  redirect: selectedInvite?.project
                    ? UrlWithOrgProjEnvIds(routeConstants.OVERVIEW, store)
                    : UrlWithOnlyOrgId(routeConstants.NO_PROJECT, store)
                }
              : {
                  redirect:
                    UrlWithOnlyOrgId(
                      routeConstants.MANAGE_ORGANIZATION,
                      store
                    ) + '?tab=3'
                },
            HttpConstants.GET_METHOD
          )
        )
      })
      .catch((err) =>
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SOMETHING_WENT_WRONG
        })
      )
      .finally(() => setAcceptLoading(false))
  }

  //to reject accept invite
  const handleRejectUserInvite = () => {
    setRejectLoading(true)
    rejectUserInvite({ $token: selectedInvite.token })
      .then(() => {
        findAllMyUserInvites()
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.USER_INVITE.REJECT_USER_INVITE.SUCCESS
        })
        setInvitePopUpOpen(false)
      })
      .catch((err) =>
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SOMETHING_WENT_WRONG
        })
      )
      .finally(() => setRejectLoading(false))
  }

  const removeSandboxUserDetails = () => {
    localStorage.removeItem('project_type')
    localStorage.removeItem('tour_disabled')
    localStorage.removeItem('sandboxuser_email')
    localStorage.removeItem('sandboxuser_role')
  }

  const navigateToAccountSettings = () => {
    localStorage.removeItem(StringConstants.ACTIVE_TAB)
    const route = replaceUrl(
      routeConstants.ACCOUNT_SETTING,
      store.scopeStore.getScope()['organization-id'] as string,
      store.scopeStore.getScope()['project-id'] as string,
      store.scopeStore.getScope()['environment-id'] as string
    )

    navigate({
      pathname: route
    })
  }

  return (
    <div className='w-full flex items-center justify-between'>
      <div>
        <img src={vigilIcon} alt='logo' />
      </div>

      <div className='flex items-center space-x-[20px]'>
        <div className='relative'>
          {!window.location.pathname
            .split('/')
            .includes('allNotifications') && (
            <div
              id='notification-menu'
              onClick={() => {
                setDropdownOpen(!isDropdownOpen)
              }}
              className='relative cursor-pointer'
            >
              <NotificationIcon
                color={pallete.colors.textDark2}
                size={24}
                style={{ pointerEvents: 'none' }}
              />
              {notificationCount !== 0 && (
                <div className='absolute top-[-10px] right-[-10px] min-w-0 rounded-full border border-white text-[10px] font-bold text-white bg-red-600 shadow-[0px_15px_25px_rgba(71,78,93,0.15)] h-[24px] w-[24px] flex items-center justify-center'>
                  {notificationCount > 0 && notificationCount < 100
                    ? notificationCount
                    : '99+'}
                </div>
              )}
            </div>
          )}

          <div className='absolute -top-[32px] -right-[120px]'>
            {isDropdownOpen && (
              <NotificationWrapper
                isDropdownOpen={true}
                setDropdownOpen={setDropdownOpen}
                setSelectedInvite={setSelectedInvite}
                setInviteOpen={setInvitePopUpOpen}
              />
            )}
            {invitePopUpOpen && (
              <InvitePopup
                showHeader
                title={
                  selectedInvite?.project
                    ? 'Project Invite'
                    : 'Organization Invite'
                }
                acceptLoading={acceptLoading}
                rejectLoading={rejectLoading}
                modalOpen={invitePopUpOpen}
                setModalOpen={setInvitePopUpOpen}
                primaryButtonText='Accept Invite'
                secondaryButtonText='Reject Invite'
                primaryButtonDisabled={acceptLoading || rejectLoading}
                secondaryButtonDisabled={acceptLoading || rejectLoading}
                primaryButtonClick={() => {
                  handleAcceptUserInvite()
                }}
                secondaryButtonClick={() => {
                  handleRejectUserInvite()
                }}
                showFooter={true}
                content={
                  <div className='flex flex-col p-[24px] gap-[24px]'>
                    <div>
                      <DDSTypography.Title
                        variant='semiBold'
                        type='h4'
                        color={pallete.colors.textDark3}
                      >
                        {`Do you want to join `}
                        <span style={{ fontWeight: 700 }}>
                          {selectedInvite?.project
                            ? '"Project - ' +
                              selectedInvite?.project?.name +
                              '"'
                            : selectedInvite?.organization?.name}
                        </span>
                        {` as `}
                        <span style={{ fontWeight: 700 }}>
                          {selectedInvite?.role}
                        </span>
                        {`?`}
                      </DDSTypography.Title>
                    </div>
                  </div>
                }
              />
            )}
          </div>
        </div>
        <button
          id='dropdown-avatar'
          onClick={(e) => {
            setProfileMenuOpen(!profileMenuOpen)
          }}
          className='relative flex items-center rounded bg-yellow-200'
          type='button'
        >
          <img
            id='dropdown-avatar'
            className='rounded-full border object-fill
            w-[32px] h-[32px]'
            src={store.userStore.avatar}
            alt='user photo'
            style={{ borderColor: pallete.colors.stroke2 }}
          />
          <div>
            {!profileMenuOpen && (
              <ChevronDown
                size={18}
                color={pallete.colors.textDark4}
                style={{ paddingLeft: '4px', paddingRight: '0px' }}
              />
            )}
            {profileMenuOpen && (
              <ChevronUp
                size={18}
                color={pallete.colors.textDark4}
                style={{ paddingLeft: '4px', paddingRight: '0px' }}
              />
            )}
          </div>
          <div className='flex pt-[42px]'>
            {profileMenuOpen && (
              <DDSMenu
                id='profile-menu'
                open={profileMenuOpen}
                setOpen={setProfileMenuOpen}
                parentId='dropdown-avatar'
                shape='roundedRectangle'
                style={{ minWidth: 'fit-content' }}
                className='absolute z-50 right-[0px] !w-auto border-2 shadow-sm max-h-[380px] override-profile-menu'
              >
                <Button
                  id='account-setting'
                  label={
                    <DDSTypography.Paragraph
                      size='para'
                      variant='semiBold'
                      color={pallete.colors.neuButtonText}
                    >
                      Profile
                    </DDSTypography.Paragraph>
                  }
                  type='ghost'
                  size='small'
                  startIcon={
                    <Settings size={16} color={pallete.colors.neuButtonText} />
                  }
                  onClick={() => {
                    localStorage.removeItem(StringConstants.ACTIVE_TAB)
                    navigateToAccountSettings()
                  }}
                />
                <Button
                  id='logout'
                  type='ghost'
                  size='small'
                  startIcon={
                    <LogOutIcon size={16} color={pallete.colors.warningRed2} />
                  }
                  onClick={async () => {
                    localStorage.removeItem(
                      StringConstants.LOCAL_STORAGE.BANNER_CLOSED_FLAG
                    )
                    localStorage.removeItem(
                      StringConstants.LOCAL_STORAGE.CURRENT_PAGE_DETAILS
                    )
                    localStorage.removeItem(StringConstants.ACTIVE_TAB)
                    localStorage.removeItem(StringConstants.ENVIRONMENT_TYPE)
                    const userData: any = getDataFromCookie()
                    await deleteToken()
                    leaveRoom(userData.sub)
                    store.clearStore()
                    if (isDemo) {
                      removeSandboxUserDetails()
                      navigate(routeConstants.SANDBOX_SCREEN)
                    } else navigate(routeConstants.SIGN_IN)
                  }}
                  label={
                    <DDSTypography.Paragraph
                      size='para'
                      variant='semiBold'
                      color={pallete.colors.warningRed2}
                    >
                      Logout
                    </DDSTypography.Paragraph>
                  }
                  disabled={store.uiStore.getGlobalLoader()}
                />
              </DDSMenu>
            )}
          </div>
        </button>
      </div>
    </div>
  )
}

export default observer(Navbar)
