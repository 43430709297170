import { AddAlt, SubtractAlt } from '@carbon/icons-react'
import React, { ChangeEvent, useCallback } from 'react'

import { DDSInput } from 'den-design-system'
import Input from '../../../New/Input/Input'
import { inRange } from 'lodash'
import pallete from '../../../../global/newPallete'

interface CounterProps {
  value: any
  minValue?: number
  maxValue?: number
  onChange?: (value: number) => void
  onConfirm?: (value: number) => void
  disableNoSubscription?: boolean
}

const Counter: React.FC<CounterProps> = ({
  value,
  minValue = 1,
  maxValue = Number.MAX_SAFE_INTEGER,
  onChange = () => null,
  onConfirm = () => null,
  disableNoSubscription = false
}) => {
  const isInputValid = useCallback(
    (value: string) => {
      const numberValue = parseInt(value, 10)
      return (
        /^[0-9]+$/.test(value) && inRange(numberValue, minValue, maxValue + 1)
      )
    },
    [minValue, maxValue]
  )

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (isInputValid(value)) {
      const numberValue = parseInt(value, 10)
      onChange(numberValue)
    }
  }

  const handleIncrement = () => {
    if (value < maxValue) {
      const newValue = value + 1
      onChange(newValue)
    }
  }

  const handleDecrement = () => {
    if (value > minValue) {
      const newValue = value - 1
      onChange(newValue)
    }
  }

  return (
    <div
      className={` ${disableNoSubscription && 'cursor-not-allowed pointer-events-none opacity-50'}  flex flex-row gap-x-[8px] items-center justify-center override-input`}
    >
      <SubtractAlt
        size='20px'
        style={{ cursor: 'pointer', color: pallete.colors.textDark2 }}
        onClick={handleDecrement}
        className={``}
      />
      <Input
        id='counter-input'
        validateInput={false}
        name='thresholdValue'
        value={value}
        width='132px'
        textAlign='center'
        onChange={handleChange}
        onBlur={() => onConfirm(value)}
      />
      <AddAlt
        size='20px'
        style={{ cursor: 'pointer', color: pallete.colors.textDark2 }}
        onClick={handleIncrement}
      />
    </div>
  )
}

export default Counter
