import React, { CSSProperties } from 'react'

import { DDSTypography } from 'den-design-system'
import pallete from '../../../global/newPallete'

interface NoRecordProps {
  text: string | JSX.Element | null
  imageSrc: string
  style?: CSSProperties
  button?: any
}

const NoRecord: React.FC<NoRecordProps> = ({
  text,
  imageSrc,
  style,
  button
}) => {
  return (
    <div
      className='flex flex-col items-center justify-center p-[24px]'
      style={{ ...style }}
    >
      <img
        src={imageSrc}
        alt='Illustration'
        className='select-none cursor-not-allowed pointer-events-none'
        style={{ width: '120px', height: '120px' }}
      />
      <DDSTypography.Title
        type='h5'
        variant='medium'
        color={pallete.colors.textDark4}
        className='text-center'
      >
        {text}
      </DDSTypography.Title>
      <div className='pt-[24px]'>{button}</div>
    </div>
  )
}

export default NoRecord
