import React, { useEffect, useRef, useState } from 'react'

import { Cyclist } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import { OrganizationUserRoles } from '../../../enum/OrganizationUserRoles.enum'
import StringConstants from '../../../constants/StringConstants'
import { observer } from 'mobx-react-lite'
import palette from '../../../global/newPallete'
import pallete from '../../../global/newPallete'
import { replaceUrl } from '../../../utils/UrlUtils'
import routeConstants from '../../../constants/RouteConstants'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

interface CircularProgressSectionProps {
  isSidebarExpanded: boolean
  toggleSidebar: () => void
  progressValue: any
  percentageFilled: any
  selectCheck?: boolean
  disableNoSubscription?: boolean
}

const CircularProgress: React.FC<CircularProgressSectionProps> = ({
  isSidebarExpanded,
  toggleSidebar,
  progressValue,
  percentageFilled,
  disableNoSubscription
}) => {
  const store = useStoreContext()
  const navigate = useNavigate()
  const buttonRef = useRef<HTMLDivElement>(null)
  const [isThingsToDoActive, setIsThingsToDoActive] = useState(false)

  const [selectedOption, setselectedOption] = React.useState<boolean>(false)

  const gettingStartedHandler = {
    id: 20,
    title: 'Get Started',
    link: routeConstants.THINGS_TO_DO,
    leftIcon: <Cyclist />,
    topIcon: <Cyclist size={24} color={pallete.colors.primary2} />
  }

  useEffect(() => {
    const thingsToDo = window.location.href.includes('things-to-do')
    setIsThingsToDoActive(thingsToDo)
  }, [window.location.href])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        if (!isThingsToDoActive) {
          setselectedOption(false)
        }
      }
    }

    if (isThingsToDoActive) {
      store.uiStore.setSidebarItem(gettingStartedHandler)
      setselectedOption(true)
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isThingsToDoActive])
  const strokeDashoffset = (1 - percentageFilled) * (2 * Math.PI * 35)

  return (
    <div
      className={`${disableNoSubscription && 'cursor-not-allowed pointer-events-none  opacity-50'}`}
      onClick={() => {
        localStorage.setItem(
          StringConstants.ACTIVE_TAB,
          StringConstants.TAB_VALUES.GETTING_STARTED
        )
      }}
    >
      {store.thingsToDoStore.getShowThingsToDo() &&
      isSidebarExpanded &&
      store.userStore.getUserRole() !== OrganizationUserRoles.BILLING ? (
        <div
          ref={buttonRef}
          className='flex items-center w-full h-full cursor-pointer'
          onClick={() => {
            setselectedOption(true)
            navigate(
              replaceUrl(
                routeConstants.THINGS_TO_DO,
                store.scopeStore.getScope()['organization-id'] as string,
                store.scopeStore.getScope()['project-id'] as string,
                store.scopeStore.getScope()['environment-id'] as string
              )
            )
          }}
          style={{
            backgroundColor:
              localStorage.getItem(StringConstants.ACTIVE_TAB) ===
              StringConstants.TAB_VALUES.GETTING_STARTED
                ? palette.colors.primary1
                : 'transparent'
          }}
        >
          <div className='relative w-[80px]'>
            <div className='w-[55px] h-[55px] 2xl:w-[65px] 2xl:h-[65px]'>
              <svg
                className='w-full h-full ml-[12px]'
                viewBox='0 0 100 100'
                style={{ cursor: 'pointer' }}
              >
                <circle
                  className='text-gray-200 stroke-current'
                  strokeWidth='7'
                  cx='50'
                  cy='50'
                  r='35'
                  fill='transparent'
                ></circle>
                Copy code
                <circle
                  className={`progress-ring__circle stroke-current ${
                    store.uiStore.getGlobalLoader() ||
                    store.uiStore.getprojectEnvironmentLoader()
                      ? 'text-white'
                      : 'text-green-500'
                  }`}
                  strokeWidth='7'
                  strokeLinecap='round'
                  cx='50'
                  cy='50'
                  r='35'
                  fill='transparent'
                  strokeDasharray={2 * Math.PI * 35}
                  strokeDashoffset={strokeDashoffset}
                  transform='rotate(-90 50 50)'
                ></circle>
                <text
                  x='50'
                  y='52'
                  fontFamily='Verdana'
                  fontSize='26'
                  textAnchor='middle'
                  alignmentBaseline='middle'
                  fill='white'
                >
                  {store.uiStore.getGlobalLoader() ||
                  store.uiStore.getprojectEnvironmentLoader()
                    ? '0'
                    : progressValue}
                </text>
              </svg>
              {localStorage.getItem(StringConstants.ACTIVE_TAB) ===
                StringConstants.TAB_VALUES.GETTING_STARTED && (
                <div
                  className='absolute top-0 left-0 w-[8px] h-full'
                  style={{ backgroundColor: palette.colors.primary2 }}
                />
              )}
            </div>
          </div>
          <div
            className='cursor-pointer'
            onClick={() => {
              setselectedOption(true)
              navigate(
                replaceUrl(
                  routeConstants.THINGS_TO_DO,
                  store.scopeStore.getScope()['organization-id'] as string,
                  store.scopeStore.getScope()['project-id'] as string,
                  store.scopeStore.getScope()['environment-id'] as string
                )
              )
            }}
          >
            {isSidebarExpanded && (
              <DDSTypography.Paragraph
                variant='medium'
                size='para'
                color={pallete.colors.textDark1}
              >
                Get Started
                <br />
                <DDSTypography.Paragraph
                  size='caption'
                  variant='medium'
                  style={{ color: pallete.colors.textDark6 }}
                >
                  {store.uiStore.getGlobalLoader() ||
                  store.uiStore.getprojectEnvironmentLoader()
                    ? '0/0 Completed'
                    : `${store.thingsToDoStore.getProgressData().progress}/${store.thingsToDoStore.getTotalProgressCount()} Completed`}
                </DDSTypography.Paragraph>
              </DDSTypography.Paragraph>
            )}
          </div>
        </div>
      ) : (
        <div>
          {store.thingsToDoStore.getShowThingsToDo() &&
          store.userStore.getUserRole() !== OrganizationUserRoles.BILLING ? (
            <div className='flex justify-center items-center w-full h-full'>
              <div className='relative my-[12px] 2xl:my-[6px]'>
                <div
                  className='flex items-center w-full'
                  onClick={() => {
                    setselectedOption(true)
                    navigate(
                      replaceUrl(
                        routeConstants.THINGS_TO_DO,
                        store.scopeStore.getScope()[
                          'organization-id'
                        ] as string,
                        store.scopeStore.getScope()['project-id'] as string,
                        store.scopeStore.getScope()['environment-id'] as string
                      )
                    )
                  }}
                  style={{
                    backgroundColor:
                      localStorage.getItem(StringConstants.ACTIVE_TAB) ===
                      StringConstants.TAB_VALUES.GETTING_STARTED
                        ? palette.colors.primary1
                        : 'transparent'
                  }}
                >
                  <svg
                    className='w-full h-full mx-[12px] '
                    viewBox='0 0 100 100'
                    style={{ cursor: 'pointer' }}
                  >
                    <circle
                      className='text-gray-200 stroke-current'
                      strokeWidth='7'
                      cx='50'
                      cy='50'
                      r='35'
                      fill='transparent'
                    ></circle>
                    <circle
                      className={`progress-ring__circle stroke-current ${
                        store.uiStore.getGlobalLoader() ||
                        store.uiStore.getprojectEnvironmentLoader()
                          ? 'text-white'
                          : 'text-green-500'
                      }`}
                      strokeWidth='7'
                      strokeLinecap='round'
                      cx='50'
                      cy='50'
                      r='35'
                      fill='transparent'
                      strokeDasharray={2 * Math.PI * 35}
                      strokeDashoffset={strokeDashoffset}
                      transform='rotate(-90 50 50)'
                    ></circle>
                    <text
                      x='50'
                      y='52'
                      fontFamily='Verdana'
                      fontSize='26'
                      textAnchor='middle'
                      alignmentBaseline='middle'
                      fill='white'
                    >
                      {store.uiStore.getGlobalLoader() ||
                      store.uiStore.getprojectEnvironmentLoader()
                        ? '0'
                        : progressValue}
                    </text>
                  </svg>
                  {localStorage.getItem(StringConstants.ACTIVE_TAB) ===
                    StringConstants.TAB_VALUES.GETTING_STARTED && (
                    <div
                      className='absolute top-0 left-0 w-[8px] h-full'
                      style={{ backgroundColor: palette.colors.primary2 }}
                    />
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}
export default observer(CircularProgress)
