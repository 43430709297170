import React from 'react'
import { TypographyDDS } from '../Typography/TypographyDDS'

const CustomErrorToast: React.FC<{ heading: string; message: string }> = ({
  heading,
  message
}) => {
  return (
    <div>
      <TypographyDDS.Paragraph variant='semiBold' size='caption' color='error'>
        {heading}
      </TypographyDDS.Paragraph>

      <TypographyDDS.Paragraph
        style={{ wordBreak: 'break-word' }}
        variant='medium'
        size='caption'
        color='black'
      >
        {message}
      </TypographyDDS.Paragraph>
    </div>
  )
}

export default CustomErrorToast
