import { isObject, isUndefined } from 'lodash'

import CopyOnClick from '../common/CopyOnClick/CopyOnClick'
import React from 'react'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import { css } from '@emotion/react'
import palette from '../../global/pallete'
import styled from '@emotion/styled'

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
// `
const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0px;
`
const RightColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const StyledTh = styled.th`
  text-align: left;
  color: ${palette.colors.textDark2};
  width: 50%;
`
const StyledTr = styled.tr<{ header?: boolean }>`
  td,
  th {
    border-bottom: 1px solid ${palette.colors.borderColor};
    padding: 19px;
  }
  .left-column {
    border-right: 1px solid ${palette.colors.borderColor};
    ${({ header }) =>
      !header &&
      css`
        text-align: center;
        width: 500px;
        max-width: 100%;
      `}
  }
`

const TableFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 15px;
`
const NoDataWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
`

const UrlValueWrapper = styled.span`
  max-width: 250px;
`
const CopyOnClickWrapper = styled.div`
  margin-left: 15px;
`

const KeyValueTable: React.FC<{
  data?: Record<string, string>
  urlVariant?: boolean
  totalCount?: number
  noHeader?: boolean
}> = ({ data = {}, urlVariant = false, totalCount, noHeader }) => {
  const renderKey = (key: any) => {
    if (urlVariant)
      return (
        <TypographyDDS.Paragraph size='para' variant='semiBold' color='black'>
          {key}
        </TypographyDDS.Paragraph>
      )
    return key
  }
  return (
    <>
      <StyledTable>
        {!urlVariant && !noHeader && (
          <thead>
            <StyledTr header={true}>
              <StyledTh className='left-column'>
                <TypographyDDS.Paragraph
                  size='para'
                  variant='semiBold'
                  color='black'
                >
                  Attribute
                </TypographyDDS.Paragraph>
              </StyledTh>
              <StyledTh>
                <TypographyDDS.Paragraph
                  size='para'
                  variant='semiBold'
                  color='black'
                >
                  Value
                </TypographyDDS.Paragraph>
              </StyledTh>
            </StyledTr>
          </thead>
        )}
        {Object.keys(data).length === 0 ? (
          <tbody>
            <StyledTr>
              <NoDataWrapper style={{ width: !urlVariant ? '200%' : '100%' }}>
                <TypographyDDS.Title
                  type='h5'
                  variant='medium'
                  color='textDark2'
                >
                  No data
                </TypographyDDS.Title>
              </NoDataWrapper>
            </StyledTr>
          </tbody>
        ) : (
          <tbody style={{ width: '50%' }}>
            {Object.keys(data).map((key) => (
              <StyledTr key={key} header={!urlVariant}>
                <td className='left-column'>
                  <TypographyDDS.Paragraph size='para' color='textDark'>
                    {renderKey(key)}
                  </TypographyDDS.Paragraph>
                </td>
                <td>
                  {urlVariant ? (
                    <RightColumnContainer>
                      <UrlValueWrapper>
                        <TypographyDDS.Paragraph
                          size='para'
                          color='textDark'
                          ellipsis
                          className='key-value-table-hover-api-monitoring'
                        >
                          {isObject(data[key])
                            ? JSON.stringify(data[key])
                            : data[key]}
                        </TypographyDDS.Paragraph>
                      </UrlValueWrapper>
                      <CopyOnClickWrapper id='copy'>
                        <CopyOnClick
                          value={data[key] || ''}
                          color={palette.colors.lightGrey2}
                          label='Copy'
                        />
                      </CopyOnClickWrapper>
                    </RightColumnContainer>
                  ) : (
                    <TypographyDDS.Paragraph
                      size='para'
                      color='textDark'
                      style={{
                        maxWidth: '400px',
                        overflow: 'hidden',
                        wordBreak: 'break-all'
                      }}
                    >
                      {isObject(data[key])
                        ? JSON.stringify(data[key])
                        : data[key]}
                    </TypographyDDS.Paragraph>
                  )}
                </td>
              </StyledTr>
            ))}
          </tbody>
        )}
      </StyledTable>
      {!isUndefined(totalCount) && totalCount !== 0 && (
        <TableFooter>
          <TypographyDDS.Paragraph size='para' color='textDark3'>
            Results {Object.keys(data).length === 0 ? 0 : 1} -{' '}
            {Object.keys(data).length} of {totalCount}
          </TypographyDDS.Paragraph>
        </TableFooter>
      )}
    </>
  )
}

export default KeyValueTable
