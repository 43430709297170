import React, { useEffect, useState } from 'react'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'
import { isEmpty, isUndefined } from 'lodash'

import Button from '../../common/Button/Button'
import { Candidate } from '../../../types/store.types'
import GoBack from './GoBack'
import { IoIosArrowForward } from 'react-icons/io'
import MultiSelect from '../../common/MultiSelect/MultiSelect'
import SkipStep from './SkipStep'
import { TypographyDDS } from '../../common/Typography/TypographyDDS'
import { observer } from 'mobx-react-lite'
import palette from '../../../global/pallete'
import { projectMemberLookUp } from '../../../api/project/ProjectMember.service'
import styled from '@emotion/styled'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useStoreContext } from '../../../store/StoreContext'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6em;
  align-items: center;
  gap: 1.25em;
`
const ColorText = styled.div`
  color: ${palette.colors.primary};
  display: inline;
`
const ActionWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`
const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
const AlertSettingsStep: React.FC<{
  handleNext: (data: any) => void
  handleBack: () => void
}> = ({ handleNext, handleBack }) => {
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.ALERT_SETTINGS_STEP
  )
  const [selectedUsers, setSelectedUsers] = useState<any>([])
  const [userOptions, setUserOptions] = useState<any>([])
  const [openMultiSelect, setOpenMultiSelect] = useState<boolean>(false)
  const [loadOptions, setLoadOptions] = React.useState<boolean>(false)
  const store = useStoreContext()
  const handleSearch = async (value: any) => {
    setUserOptions([])
    if (value.length >= 3) {
      setLoadOptions(true)
      setOpenMultiSelect(true)

      const response: any = await projectMemberLookUp({
        'name-or-email': value,
        self: true
      })
      let data = response.data
      data = data.filter(
        (item: any) =>
          !selectedUsers.map((user: any) => user.id).includes(item.id)
      )
      if (data.length === 0) setOpenMultiSelect(false)
      setUserOptions(
        data.map(({ user, role, id }: any) => {
          return {
            id,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            position: role
          }
        })
      )
    } else setOpenMultiSelect(false)

    setLoadOptions(false)
  }
  useEffect(() => {
    const alertCandidates =
      store.addCheckStore.getData().expandedAlertCandidates
    if (!isUndefined(alertCandidates)) {
      const candidates = (alertCandidates as Candidate[]).map(
        ({ user, role, id, name, email, projectMemberId }: any) => {
          return {
            id: projectMemberId ? projectMemberId : id,
            name: name ? name : `${user.firstName} ${user.lastName}`,
            email: email ? email : user.email,
            role: role
          }
        }
      )
      setUserOptions([...candidates])
      setSelectedUsers([...candidates])
    }
  }, [store.addCheckStore.getData().expandedAlertCandidates])

  return (
    <Container>
      <TypographyDDS.Title type='h3' variant='medium'>
        Add <ColorText>teammates</ColorText> who should be alerted
      </TypographyDDS.Title>

      <MultiSelect
        options={userOptions}
        value={selectedUsers}
        disableDelete
        disableClearable
        optionsLabelAccessor={(user: any) => user.name}
        onChange={(users: any) => {
          setSelectedUsers(users)
          setOpenMultiSelect(false)
          setUserOptions([])
        }}
        onDeleteSelection={({ id }) =>
          setSelectedUsers(
            selectedUsers.filter((option: any) => option.id !== id)
          )
        }
        variant='small'
        label='Teammates'
        placeholder='Enter Email/Name of the teammates to be alerted'
        onTextChange={handleSearch}
        openDropdown={openMultiSelect}
        setOpenDropdown={setOpenMultiSelect}
        loadingOption={loadOptions}
      />

      <Button
        id='Alert Setting Next'
        onClick={() => {
          store.addCheckStore.setData({
            alertCandidates:
              selectedUsers.length === 0
                ? store.addCheckStore.getData().alertCandidates
                : selectedUsers
          })
          handleNext(null)
          gaEventTracker(gaEventConstants.ADDED_TEAMMATES)
        }}
        variant='contained'
        endIcon={
          <IoIosArrowForward
            style={{
              fontSize: 16
            }}
          />
        }
        fullWidth
        disabled={isEmpty(selectedUsers) || store.addCheckStore.getLoading()}
      >
        Next
      </Button>
      <ActionContainer>
        <ActionWrapper
          onClick={() => {
            store.addCheckStore.setData({
              alertCandidates:
                selectedUsers.length === 0
                  ? store.addCheckStore.getData().alertCandidates
                  : selectedUsers
            })
            if (!store.addCheckStore.getLoading()) {
              handleBack()
            }
          }}
        >
          <GoBack />
        </ActionWrapper>
        <ActionWrapper
          onClick={() => {
            store.addCheckStore.setData({
              alertCandidates:
                selectedUsers.length === 0
                  ? store.addCheckStore.getData().alertCandidates
                  : selectedUsers
            })
            if (!store.addCheckStore.getLoading()) {
              handleNext(null)
            }
          }}
        >
          <SkipStep />
        </ActionWrapper>
      </ActionContainer>
    </Container>
  )
}
export default observer(AlertSettingsStep)
