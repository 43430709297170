export default {
  //common
  BILLING: 'Billing',
  MEMBER: 'Member',
  INVITE_MEMBERS: 'Invite Member',
  ASSIGN_MEMBERS: 'Assign Member',
  INVITE_MEMBERS_CAPTION: 'Invite members to ',
  LETS_COLLABORATE: `Let's Collaborate!!`,
  MEMBER_EMAIL: 'Member Email',
  MEMBER_EMAIL_PLACEHOLDER: 'Enter email',
  MEMBER_ROLE: 'Member Role',
  MEMBER_ROLE_PLACEHOLDER: 'Select role',
  SEND_INVITE: 'Send Invite',
  CANCEL: 'Cancel',
  ERRORS: 'errors',
  EXCEPTIONS: 'exceptions',
  EXCEPTIONS_TITLE: 'Exceptions',
  ERRORS_TITLE: 'Errors',
  API_MONITORING_TITLE: 'API Monitoring',
  UPTIME_CHECKS_TITLE: 'Uptime Checks',
  JOBS_TITLE: 'Job Status',
  APEX_JOBS_TITLE: 'APEX Jobs',
  ADD_CHECK_LABEL: 'Add Check',
  ADD_JOB_LABEL: 'Add Job',
  REFRESH_LABEL: 'Refresh',
  NO_RECORDS_FOUND: 'No records found for last 7 days',
  START_MONITORING_APPLICATIONS: 'Start monitoring your applications',
  EXCEPTION_TITLE: 'Exception',
  ERROR_TITLE: 'Error',
  //exceptions
  TOTAL_EXCEPTIONS: 'Total Exceptions',
  TOTAL_ERRORS: 'Total Errors',
  MOST_COMMON_EXCEPTION: 'Most Common Exceptions',
  MOST_COMMON_ERROR: 'Most Common Errors',
  MOST_CRITICAL_EXCEPTION: 'Most Critical Exceptions',
  MOST_CRITICAL_ERRORS: 'Most Critical Errors',
  RECENT_EXCEPTIONS: 'Recent Exceptions',
  RECENT_ERRORS: 'Recent Errors',
  EXCEPTIONS_SEVERITY: 'Exceptions Severity',
  ERRORS_SEVERITY: 'Errors Severity',
  EXCEPTIONS_STATUS: 'Exceptions Status',
  ERRORS_STATUS: 'Errors Status',
  VIEW: 'View',
  //uptime checks
  HIGHEST_AVAILABILITY: 'Highest Availability',
  HIGHEST_DOWNTIME: 'Highest Downtime',
  SLOWEST_RESPONSE_TIME: 'Slowest Response Time',
  TOP_10_CHECKS: 'Top 10 Checks',
  TOTAL_CHECKS: 'Total Checks',
  APPLICATIONS_UP: 'Applications Up',
  APPLICATIONS_DOWN: 'Applications Down',
  RECENT_CHECKS: 'Recent Checks',
  DOWN: 'Down',
  UP: 'Up',
  CHECK_NAME: 'Check Name',
  AVAILABILITY: 'Availability',
  URL: 'URL',
  DOWN_TIME: 'Downtime',
  RESPONSE_TIME: 'Response Time',
  //job monitoring
  MOST_EXECUTED_JOB: 'Most Executed Job',
  MOST_FAILED_JOB: 'Most Failed Job',
  SLOWEST_RUN_TIME: 'Slowest Run Time',
  TOP_10_JOBS: 'Top 10 Jobs',
  TOTAL_JOBS: 'Total Jobs',
  SUCCESSFUL_JOBS: 'Successful Jobs',
  FAILED_JOBS: 'Failed Jobs',
  RECENT_JOBS: 'Recent Jobs',
  SUCCESS: 'Success',
  FAILED: 'Failed',
  CRON_JOB: 'Cron Job',
  EXECUTIONS: 'Executions',
  RUN_TIME: 'Run Time',
  RESPONSE: 'Response',

  //API monitoring
  DELETE: 'DELETE',
  MOST_REQUESTED_API: 'Most Requested API',
  MOST_FAILED_API: 'Most Failed API',
  TOTAL_APIs: 'Total APIs',
  SUCCESSFUL_APIs: 'Successful APIs',
  FAILED_APIs: 'Failed APIs',
  API_RESPONSE_STATUS_CODE: 'API Response Status Code',
  API_RECENT: 'Recent APIs',
  CALLS: 'Calls',
  URL_TEMPLATE: 'URL Template',
  TOTAL_REQUESTS: 'Total Requests',
  HTTP_METHOD: 'HTTP Method'
}
