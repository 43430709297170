export interface Registry {
  unregister: () => void
}

export interface Callable {
  [key: string]: (data: any) => void
}

export interface Subscriber {
  [key: string]: Callable
}

export interface IEventBus {
  dispatch<T>(event: string, arg?: T): void
  register(event: string, callback: () => void): void
  unregister(event: string): void
}

export class EventBus implements IEventBus {
  private subscribers: Subscriber
  private static nextId = 0
  private static instance?: EventBus = undefined

  private constructor() {
    this.subscribers = {}
  }

  public static getInstance(): EventBus {
    if (this.instance === undefined) {
      this.instance = new EventBus()
    }

    return this.instance
  }

  public dispatch<T>(event: string, arg?: T): void {
    const subscriber = this.subscribers[event]
    if (subscriber === undefined) {
      return
    }

    Object.keys(subscriber).forEach((key) => subscriber[key](arg))
  }

  public register(event: string, callback: (data: any) => void): void {
    const id = this.getNextId()
    if (!this.subscribers[event]) this.subscribers[event] = {}
    this.subscribers[event][0] = callback
  }

  public unregister = (event: string) => {
    delete this.subscribers[event][0]
    if (Object.keys(this.subscribers[event]).length === 0)
      delete this.subscribers[event]
  }

  private getNextId(): number {
    return EventBus.nextId++
  }
}
