import pallete from '../global/newPallete'

export const SeverityTypes = [
  {
    label: 'Critical',
    value: 'Critical',
    style: {
      color: pallete.colors.warningRed1
    }
  },
  {
    label: 'Major',
    value: 'Major',
    style: { color: pallete.colors.warningOrange }
  },
  {
    label: 'Moderate',
    value: 'Moderate',
    style: { color: pallete.colors.warningBlue }
  },
  {
    label: 'Minor',
    value: 'Minor',
    style: { color: pallete.colors.textDark6 }
  }
]
