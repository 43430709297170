import './global/global.css'
import './styles.css'

import App from './App'
import { DDSTheme } from 'den-design-system'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import MobileTabletView from './components/MobileTabletView/MobileTabletView'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { StoreProvider } from './store/StoreContext'
import { ThemeProvider } from '@mui/material'
import theme from './global/muiTheme'
import { useIsMobile } from './hooks/Mobile'
import { useIsTablet } from './hooks/Mobile'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const Main: React.FC = () => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  if (isMobile) {
    return (
      <>
        <MobileTabletView
          titleFontWeight={700}
          titleFontSize='22px'
          titleLineHeight='28px'
          descriptionFontWeight={500}
          descriptionFontSize='16px'
          descriptionLineHeight='20px'
        />
      </>
    )
  }

  if (isTablet) {
    return (
      <>
        <MobileTabletView
          titleFontWeight={700}
          titleFontSize='32px'
          titleLineHeight='40px'
          descriptionFontWeight={500}
          descriptionFontSize='24px'
          descriptionLineHeight='30px'
        />
      </>
    )
  }

  return (
    //<React.StrictMode>
    <DDSTheme>
      <ThemeProvider theme={theme}>
        <GoogleReCaptchaProvider
          reCaptchaKey={
            process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string
          }
          container={{
            parameters: {
              badge: 'inline'
            }
          }}
        >
          <StoreProvider>
            <App />
          </StoreProvider>
        </GoogleReCaptchaProvider>
      </ThemeProvider>
    </DDSTheme>
    //</React.StrictMode>
  )
}

root.render(<Main />)
