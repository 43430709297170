import {
  Api,
  Apps,
  ChartStepper,
  Code,
  Debug,
  DocumentMultiple_01,
  PillsAdd,
  Settings,
  Template
} from '@carbon/icons-react'

import { OrganizationType } from '../enum/OrganizationType.enum'
import { OrganizationUserRoles } from '../enum/OrganizationUserRoles.enum'
import { ProjectType } from '../enum/ProjectType.enum'
import React from 'react'
import StringConstants from '../constants/StringConstants'
import pallete from '../global/newPallete'
import routeConstants from '../constants/RouteConstants'

/**
 * Util function to get side bar tabs
 * @param {string} userRole - role of the logged in user
 * @returns {any} - tab headers
 */
export const getSideBarTabs = (
  userRole: string,
  type?: string,
  orgType?: string
) => {
  const fullStackTabs = [
    {
      id: 1,
      title: StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.OVERVIEW,
      leftIcon: <Template size={20} />,
      topIcon: <Template size={24} color={pallete.colors.primary2} />,
      link: routeConstants.OVERVIEW,
      tourId: 'Overview',
      sidebarTitle: StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.OVERVIEW
    },
    {
      id: 2,
      title: StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.EXCEPTIONS,
      leftIcon: <Debug size={20} />,
      link: routeConstants.GROUPED_ERROR_MONITORING,
      topIcon: <Debug size={24} color={pallete.colors.primary2} />,
      tourId: 'Exceptions',
      sidebarTitle: StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.EXCEPTIONS
    },
    {
      id: 3,
      title: StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.API_MONITORING,
      leftIcon: <Api size={20} />,
      link: routeConstants.API_MONITORING,
      topIcon: <Api size={24} color={pallete.colors.primary2} />,
      tourId: 'Api-Monitoring',
      sidebarTitle:
        StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.API_MONITORING
    },
    {
      id: 4,
      title: StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.UPTIME_CHECKS,
      leftIcon: <PillsAdd size={20} />,
      link: routeConstants.AHC_PROJECTS_DASHBOARD,
      topIcon: <PillsAdd size={24} color={pallete.colors.primary2} />,
      tourId: 'Uptime-Checks',
      sidebarTitle:
        StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.UPTIME_CHECKS
    },
    {
      id: 5,
      title: StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.ESCALATIONS,
      leftIcon: <ChartStepper size={20} />,
      link: routeConstants.ESCALATION_SETS,
      topIcon: <ChartStepper size={24} color={pallete.colors.primary2} />,
      tourId: 'Escalations',
      sidebarTitle: StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.ESCALATIONS
    },
    {
      id: 6,
      title: StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.INCIDENT,
      leftIcon: <DocumentMultiple_01 size={20} />,
      link: routeConstants.INCIDENT,
      topIcon: (
        <DocumentMultiple_01 size={24} color={pallete.colors.primary2} />
      ),
      tourId: 'Incident',
      sidebarTitle: StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.INCIDENT
    },
    {
      id: 7,
      title: StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.SCHEDULED_JOBS,
      leftIcon: <Code size={20} />,
      link: routeConstants.JOB_MONITORING,
      topIcon: <Code size={24} color={pallete.colors.primary2} />,
      tourId: 'Jobs',
      sidebarTitle:
        StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.SCHEDULED_JOBS
    },
    // {
    //   id: 8,
    //   title: StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.VIGIL_PARTNER,
    //   leftIcon: <UserMultiple size={20} />,
    //   link: routeConstants.ESCALATION_SETS_OVERVIEW
    // topIcon: <UserMultiple size={24} color={pallete.colors.primary2}
    // },

    {
      id: 9,
      title: StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.SETTINGS,
      leftIcon: <Settings size={20} />,
      link:
        userRole !== OrganizationUserRoles.BILLING
          ? routeConstants.PROJECT_SETTING
          : routeConstants.MANAGE_ORGANIZATION,
      topIcon: <Settings size={24} color={pallete.colors.primary2} />,
      tourId: 'Setting',
      sidebarTitle:
        StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.PROJECT_SETTINGS
    }
  ]
  const salesforceTabs = [
    {
      id: 1,
      title: StringConstants.SIDE_NAV_BAR.SALESFORCE_PROJECTS.OVERVIEW,
      leftIcon: <Template size={20} />,
      link: routeConstants.OVERVIEW,
      topIcon: <Template size={24} color={pallete.colors.primary2} />,
      tourId: 'Overview',
      sidebarTitle: StringConstants.SIDE_NAV_BAR.SALESFORCE_PROJECTS.OVERVIEW
    },
    {
      id: 2,
      title: StringConstants.SIDE_NAV_BAR.SALESFORCE_PROJECTS.ERROR,
      leftIcon: <Debug size={20} />,
      link: routeConstants.GROUPED_ERROR_MONITORING,
      topIcon: <Debug size={24} color={pallete.colors.primary2} />,
      tourId: 'Error',
      sidebarTitle: StringConstants.SIDE_NAV_BAR.SALESFORCE_PROJECTS.ERROR
    },
    {
      id: 4,
      title: StringConstants.SIDE_NAV_BAR.SALESFORCE_PROJECTS.UPTIME_CHECKS,
      leftIcon: <PillsAdd size={20} />,
      link: routeConstants.AHC_PROJECTS_DASHBOARD,
      topIcon: <PillsAdd size={24} color={pallete.colors.primary2} />,
      tourId: 'Uptime-Checks',
      sidebarTitle:
        StringConstants.SIDE_NAV_BAR.SALESFORCE_PROJECTS.UPTIME_CHECKS
    },
    {
      id: 5,
      title: StringConstants.SIDE_NAV_BAR.SALESFORCE_PROJECTS.ESCALATIONS,
      leftIcon: <ChartStepper size={20} />,
      link: routeConstants.ESCALATION_SETS_OVERVIEW,
      topIcon: <ChartStepper size={24} color={pallete.colors.primary2} />,
      tourId: 'Escalations',
      sidebarTitle: StringConstants.SIDE_NAV_BAR.SALESFORCE_PROJECTS.ESCALATIONS
    },
    {
      id: 6,
      title: StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.INCIDENT,
      leftIcon: <DocumentMultiple_01 size={20} />,
      link: routeConstants.INCIDENT,
      topIcon: (
        <DocumentMultiple_01 size={24} color={pallete.colors.primary2} />
      ),
      tourId: 'Incident',
      sidebarTitle: StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.INCIDENT
    },

    ...(type === ProjectType.SALESFORCE_ONLY
      ? [
          // {
          //   id: 7,
          //   title: StringConstants.SIDE_NAV_BAR.SALESFORCE_PROJECTS.APEX_JOBS,
          //   leftIcon: <Code size={20} />,
          //   link: routeConstants.JOB_MONITORING,
          //   topIcon: <Code size={24} color={pallete.colors.primary2} />
          // },
          {
            id: 8,
            title: 'Salesforce Audit',
            leftIcon: <Apps size={20} />,
            link: routeConstants.SALESFORCE_AUDIT_DASHBOARD,
            topIcon: <Apps size={24} color={pallete.colors.primary2} />,
            tourId: 'SalesForce',
            sidebarTitle: 'Salesforce Audit'
          }
        ]
      : []),
    {
      id: 9,
      title: StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.SETTINGS,
      leftIcon: <Settings size={20} />,
      link:
        userRole !== OrganizationUserRoles.BILLING
          ? routeConstants.PROJECT_SETTING
          : routeConstants.MANAGE_ORGANIZATION,
      topIcon: <Settings size={24} color={pallete.colors.primary2} />,
      tourId: 'Setting',
      sidebarTitle:
        StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.PROJECT_SETTINGS
    }
  ]
  let tabs

  switch (true) {
    case type === ProjectType.SALESFORCE_ONLY ||
      type === ProjectType.MULESOFT_ONLY ||
      orgType === OrganizationType.SALESFORCE_ECOSYSTEM_ONLY:
      tabs = salesforceTabs
      break

    case userRole !== OrganizationUserRoles.BILLING:
      tabs = fullStackTabs
      break

    default:
      tabs = [fullStackTabs[7]]
      break
  }

  return tabs
}
