import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import CryptoJS from 'crypto-js'
import HttpConstants from '../../constants/HttpConstants'
import PageLoadingOverlay from '../../components/common/PageLoadingOverlay/PageLoadingOverlay'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../../components/common/DDS/Toast/Toast'
import { addQueryParamsToUrl } from '../../utils/UrlGenerator'
import { findUserByMail } from '../../api/user/User.service'
import routeConstants from '../../constants/RouteConstants'
import { validateUserInvite } from '../../api/userInvites/UserInvites.service'

const UserInviteRedirect: React.FC = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const token: string = searchParams.get('token') as string
    validateUserInvite({ $token: token })
      .then((response: any) => {
        findUserByMail(response.data.invitee)
          .then(() => {
            navigate(
              addQueryParamsToUrl(
                routeConstants.REDIRECT,
                {
                  redirect: `${routeConstants.INVITES}`
                },
                HttpConstants.GET_METHOD
              )
            )
          })
          .catch((err: any) => {
            // If User Invite is valid, user is not an existing member of Vigil - Take them to signup page with token
            if (err.exceptionName === 'RecordNotFoundException') {
              const data = CryptoJS.AES.encrypt(
                JSON.stringify({
                  'invite-token': token,
                  'organization-name': response.data.organizationName,
                  'organization-type': response.data.organizationType,
                  email: response.data.invitee
                }),
                process.env.REACT_APP_ENCRYPTION_KEY
                  ? process.env.REACT_APP_ENCRYPTION_KEY
                  : 'secret_key'
              ).toString()
              navigate(
                addQueryParamsToUrl(
                  routeConstants.SIGN_UP,
                  { t: data },
                  HttpConstants.GET_METHOD
                )
              )
            } else {
              navigate(routeConstants.REDIRECT)
              ToastNotification({
                type: 'error',
                message: ToastMessageConstants.SOMETHING_WENT_WRONG
              })
            }
          })
      })
      .catch(() => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SOMETHING_WENT_WRONG
        })
        navigate(routeConstants.REDIRECT)
      })
  }, [])

  return <PageLoadingOverlay />
}

export default UserInviteRedirect
