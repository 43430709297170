import React, { useEffect, useState } from 'react'
import {
  formatDateOfDifferentFormat,
  timeZone
} from '../../../../utils/DateTimeUtils'

import { DDSTypography } from 'den-design-system'
import DisplayCard from '../../../../components/common/Exceptions/DisplayCard'
import GraphDisplayCard from '../../../../components/common/Exceptions/GraphDisplayCard'
import OverviewDoughnutChart from '../../../../components/common/Exceptions/OverviewDoughnutChart'
import { ProjectType } from '../../../../enum/ProjectType.enum'
import Spinner from '../../../../components/common/Spinner/Spinner'
import StockChart from '../../../../components/ExceptionStatistics/StockChart'
import StringConstants from '../../../../constants/StringConstants'
import { ToastMessageConstants } from '../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../components/common/DDS/Toast/Toast'
import { getStatisticsOfAnExceptionGroupByGroupId } from '../../../../api/exception/Exception.service'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../global/newPallete'
import { timeFormats } from '../../../../enum/TIME'
import { toPairs } from 'lodash'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { useStoreContext } from '../../../../store/StoreContext'

const Overview: React.FC = () => {
  const store = useStoreContext()
  const { id } = useParams()
  const [firstReported, setFirstReported] = useState<string>('')
  const [lastReported, setLastReported] = useState<string>('')
  const [maximumReport, setMaximumReport] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [stockChartData, setStockChartData] = useState<any>({})
  const [doughnutChartData, setDoughnutChartData] = useState<any>([])

  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY

  useEffect(() => {
    setLoading(true)
    const exceptionGroupId = location.pathname.split('/').includes('grouped')
      ? store.exceptionsStoreV2.getCurrentExceptionId()
      : id
    getStatisticsOfAnExceptionGroupByGroupId({
      $exceptionGroupId: exceptionGroupId as string
    })
      .then((response: any) => {
        //updating all local states
        setFirstReported(response.data.firstReportedTime)
        setLastReported(response.data.latestReportedTime)
        setMaximumReport(
          Object.values(response.data.maximumReportedInADay)[0] as string
        )
        setStockChartData(
          toPairs(response.data.responsesByInterval).map(([key, value]) => {
            key = formatDateOfDifferentFormat(key, 'DD/MM/YYYY', 'YYYY-MM-DD')
            return [Number(new Date(key).getTime()), value]
          })
        )
        const messagesLabels = [
          response.data.mostOccurredExceptionMessage.message !== '-'
            ? response.data.mostOccurredExceptionMessage.message
            : null,
          response.data.secondMostOccurredExceptionMessage.message !== '-'
            ? response.data.secondMostOccurredExceptionMessage.message
            : null,
          'Others'
        ]
        const messagesCount = [
          response.data.mostOccurredExceptionMessage.count,
          response.data.secondMostOccurredExceptionMessage.count > 0
            ? response.data.secondMostOccurredExceptionMessage.message
            : null,
          response.data.others.count
        ]
        setDoughnutChartData({ labels: messagesLabels, counts: messagesCount })
        setLoading(false)
        //Updating store
        store.exceptionsStoreV2.setStatisticsData(response.data)
        store.exceptionsStoreV2.setStockChartData(
          toPairs(response.data.responsesByInterval).map(([key, value]) => {
            key = formatDateOfDifferentFormat(key, 'DD/MM/YYYY', 'YYYY-MM-DD')
            return [Number(new Date(key).getTime()), value]
          })
        )
        store.exceptionStore.setIsRefreshed(false)
      })
      .catch((e: any) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.EXCEPTIONS.GET_STATISTICS.ERROR
        })
      })
  }, [
    store.exceptionStore.getIsRefreshed(),
    store.exceptionsStoreV2.getCurrentExceptionId()
  ])

  return (
    <div className='flex h-[100%] justify-center '>
      {!loading ? (
        <div className='w-full h-full flex flex-col gap-y-[24px] py-[24px]'>
          <div className='grid grid-cols-3 justify-between gap-x-[16px]'>
            <DisplayCard
              id='#'
              heading='First Reported on'
              content={timeZone(
                store.userStore.getUserPreference().timeZone,
                firstReported,
                timeFormats.fullMonthDateYearTime
              )}
            />
            <DisplayCard
              id='#'
              heading='Last Reported on'
              content={timeZone(
                store.userStore.getUserPreference().timeZone,
                lastReported,
                timeFormats.fullMonthDateYearTime
              )}
            />
            <DisplayCard
              id='#'
              heading='Maximum Reported in a day'
              content={!loading ? maximumReport : <Spinner />}
            />
          </div>
          <div className='grid grid-cols-2 justify-between gap-x-[20px] h-[52vh]'>
            <GraphDisplayCard
              heading={isFullStackProject ? 'Exception Trend' : 'Error Trend'}
              content={
                <div className='flex flex-col h-full justify-between align-middle items-center'>
                  <div className='flex flex-col justify-center mt-[100px]'>
                    <StockChart
                      data={store.exceptionsStoreV2.getStockChartData()}
                      startDate={new Date(
                        store.exceptionsStoreV2.getStatisticsData().firstReportedTime
                      ).getTime()}
                      single={
                        Object.keys(
                          store.exceptionsStoreV2.getStatisticsData()
                            .responsesByInterval
                        ).length === 1
                      }
                      value={
                        Object.keys(
                          store.exceptionsStoreV2.getStatisticsData()
                            .responsesByInterval
                        ).length === 1 &&
                        Object.values(
                          store.exceptionsStoreV2.getStatisticsData()
                            .responsesByInterval
                        )[0]
                      }
                      width={500}
                    />
                  </div>
                  <DDSTypography.Paragraph
                    variant='semiBold'
                    size='para'
                    className='text-center whitespace-nowrap flex justify-center items-center gap-x-[4px] !text-[9.569px]'
                    color={pallete.colors.textDark6}
                  >
                    <span
                      className='h-[1px] w-[16px]'
                      style={{ backgroundColor: pallete.colors.stroke1 }}
                    ></span>
                    Reported Date
                  </DDSTypography.Paragraph>
                </div>
              }
            />

            <GraphDisplayCard
              heading={
                isFullStackProject ? 'Exception Message(s)' : 'Error Message(s)'
              }
              content={
                <OverviewDoughnutChart
                  data={[
                    store.exceptionsStoreV2.getStatisticsData()
                      ?.mostOccurredExceptionMessage?.count,
                    store.exceptionsStoreV2.getStatisticsData()
                      ?.secondMostOccurredExceptionMessage?.count,
                    store.exceptionsStoreV2.getStatisticsData()?.others?.count >
                    0
                      ? store.exceptionsStoreV2.getStatisticsData()?.others
                          ?.count
                      : 0
                  ]}
                  labels={[
                    store.exceptionsStoreV2.getStatisticsData()
                      ?.mostOccurredExceptionMessage?.count > 0
                      ? `${store.exceptionsStoreV2.getStatisticsData()?.mostOccurredExceptionMessage.message} - ${store.exceptionsStoreV2.getStatisticsData()?.mostOccurredExceptionMessage.count}`
                      : '',
                    store.exceptionsStoreV2.getStatisticsData()
                      ?.secondMostOccurredExceptionMessage?.count > 0
                      ? `${store.exceptionsStoreV2.getStatisticsData()?.secondMostOccurredExceptionMessage.message} - ${store.exceptionsStoreV2.getStatisticsData()?.secondMostOccurredExceptionMessage.count}`
                      : '',
                    store.exceptionsStoreV2
                      .getStatisticsData()
                      ?.others?.filter(
                        (other: any) =>
                          other.message !== '-' && other.count !== 0
                      ).length > 0
                      ? StringConstants.OTHERS
                      : ''
                  ]}
                  colors={[
                    pallete.colors.stroke1,
                    pallete.colors.primary1,
                    pallete.colors.warningBlue
                  ]}
                />
              }
            />
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  )
}

export default observer(Overview)
