import React, { useState } from 'react'

import { AccessRoleConstants } from '../../../constants/AccessRoleConstants'
import Copyright from '../../../assets/images/copyright.png'
import { DDSTypography } from 'den-design-system'
import DocumentHeader from '../../Helmet/DocumentHeader'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import Navbar from '../Navbar/Navbar'
import SecureComponent from '../../Layout/SecureComponent'
import VigilGreyOut from '../../../assets/icons/Vigil Logo Greyed out.svg'
import { gaPageConstants } from '../../../constants/GAConstants'
import noOrgImage from '../../../assets/images/NoOrgImage.svg'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

const NoOrganizationJoined: React.FC = () => {
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.NO_ORGANIZATION
  )
  const store = useStoreContext()
  const navigate = useNavigate()
  const [noOrg, setNoOrg] = useState<boolean>(false)

  React.useEffect(() => {
    if (store.userStore.getOrganizations().length > 0) {
      navigate(routeConstants.REDIRECT)
    } else {
      setNoOrg(true)
      store.uiStore.setSidebarItem({})
    }
    store.uiStore.setGlobalLoader(false)
  }, [])
  return (
    <>
      {noOrg && (
        <>
          <DocumentHeader header={HeaderConstants.NO_ORGANIZATION} />
          <div className='bg-white h-[93.9vh] 2xl:h-[94.9vh] w-full overflow-hidden flex flex-col justify-between'>
            <div className=' px-[32px] py-[16px]'>
              <Navbar
                onChange={() => null}
                currentTab={0}
                inviteMembers
                showNotifications
              />
            </div>

            <div className='flex flex-col items-center w-full justify-center p-[32px] flex-1'>
              <div className='flex items-center justify-center'>
                <img src={noOrgImage} className='pb-[12px]' />
              </div>

              <div className=' flex-col w-full space-y-[12px] text-center'>
                <span
                  style={{
                    fontWeight: '700',
                    fontSize: '32px',
                    color: pallete.colors.textDark3,
                    lineHeight: '40px'
                  }}
                >
                  You are not part of any Organization.
                </span>
                <DDSTypography.Title
                  type='h3'
                  variant='medium'
                  color={pallete.colors.textDark4}
                >
                  Please check your notifications for any invitations
                </DDSTypography.Title>
              </div>
            </div>
            {/* <ButtonWrapper>
            <Button
              variant='contained'
              fullWidth
              endIcon={<BsChevronRight />}
              background={palette.colors.gradientBlue}
              margin='1.25em 0'
              height='3.5em'
              onClick={() => {
                navigate(routeConstants.CREATE_ORGANIZATION)
                gaEventTracker(gaEventConstants.INITIATE_CREATE_ORGANIZATION)
              }}
            >
              Create An Organization
            </Button>
          </ButtonWrapper> */}
          </div>

          <div className='flex flex-row  overflow-hidden w-full bg-white bottom-0 items-center justify-center override-copyright gap-x-[4px]'>
            <DDSTypography.Paragraph
              size='caption'
              variant='medium'
              color={pallete.colors.textDark10}
              className='mb-[32px] top-[1.5px] relative'
            >
              @ 2024
            </DDSTypography.Paragraph>
            <img
              src={VigilGreyOut}
              color={pallete.colors.textDark10}
              className='mb-[32px]'
            />
          </div>
        </>
      )}
    </>
  )
}

export default SecureComponent(NoOrganizationJoined, AccessRoleConstants.ALL)
