import ApiManager from '../Api.manager'
import { FilterTypes } from '../../enum/Filter.enum'
import HttpConstants from '../../constants/HttpConstants'
import UrlConstants from '../../constants/UrlConstants'
import { getFiltersForApi } from '../../utils/FilterUtils'

export const getAllIncidents = (queryParams: any) => {
  const usecase = UrlConstants.FIND_ALL_INCIDENTS.USECASE

  const params = {
    ...queryParams,
    includeCancelToken: true
  }

  if (queryParams['created-from']) {
    params['created-from'] = new Date(Number(queryParams['created-from']))
  }

  if (queryParams['created-to']) {
    params['created-to'] = new Date(Number(queryParams['created-to']))
  }

  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      params
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const createIncident = (payload: any) => {
  const usecase = UrlConstants.CREATE_AN_INCIDENT.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const getAnIncident = (payload: any) => {
  const usecase = UrlConstants.FIND_AN_INCIDENT.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error
        )
      })
  })
}

export const updateAnIncident = (payload: any) => {
  const usecase = UrlConstants.UPDATE_AN_INCIDENT.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else throw response
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.errors
              ? error.data.error.errors
              : error.data.error.message
        )
      })
  })
}

export const getIncidentFilters = () => {
  const usecase = UrlConstants.GET_INCIDENT_FILTERS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      {}
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else throw response
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const deleteAnIncident = (payload: any) => {
  const usecase = UrlConstants.DELETE_AN_INCIDENT.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.DELETE_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) resolve(response.data)
        else throw response
      })
      .catch((error) => {
        reject(error.data.message)
      })
  })
}

export const getAllCommentsOfIncident = (payload: any) => {
  const usecase = UrlConstants.GET_ALL_COMMENTS_OF_INCIDENT.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const getAllCommentsOfIncidentWithoutAuthorization = (payload: any) => {
  const usecase =
    UrlConstants.GET_ALL_COMMENTS_OF_INCIDENT_WITHOUT_AUTHORIZATION.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCall(usecase, HttpConstants.GET_METHOD, payload)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const addComment = (payload: any) => {
  const usecase = UrlConstants.ADD_COMMENT_TO_INCIDENT.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const updateComment = async (payload: any) => {
  const usecase = UrlConstants.UPDATE_COMMENT_OF_INCIDENT.USECASE

  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )

      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const deleteComment = (payload: any) => {
  const usecase = UrlConstants.DELETE_COMMENT_OF_INCIDENT.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.DELETE_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const getAnIncidentByToken = (payload: { $token: string }) => {
  const usecase = UrlConstants.FIND_AN_INCIDENT_BY_TOKEN.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCall(usecase, HttpConstants.GET_METHOD, payload)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error
        )
      })
  })
}
