import { isNull, isUndefined } from 'lodash'

import { format } from 'date-fns'
import moment from 'moment-timezone'
import { timeFormats } from '../enum/TIME'

export const timeZone = (
  timeZone: string | undefined,
  timeStamp: Date | string | number,
  timeFormat: timeFormats
) => {
  const formattedTime = moment(timeStamp)
    .tz(
      isUndefined(timeZone)
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : timeZone
    )
    .format(timeFormat)
  return formattedTime.replace(/^(\w+)/, (match) => match.slice(0, 3))
}

export const convertToUTC = (dateString: string) => {
  const momentDateWithOffset = moment(dateString, 'M/D/YYYY Z')
  const utcDate = momentDateWithOffset.utc()

  const formattedUTCDate = utcDate.toISOString()

  return formattedUTCDate
}

export const getDateString = (date: Date | null, displayTime: boolean) => {
  if (!date) return '--'
  else if (displayTime) {
    return `${format(
      date,
      'dd - M - yyyy'
    )} ${date.getHours()}:${date.getMinutes()}`
  } else {
    return format(date, 'dd - M - yyyy')
  }
}

export const timeMetric = (value: number, metric: string) => {
  if (value === 0) return ''
  //Calculating time in milliseconds
  switch (metric) {
    case 's':
      value *= 1000
      break
    case 'm':
      value *= 60000
      break
    case 'h':
      value *= 3600000
      break
    case 'd':
      value *= 86400000
      break
    default:
      value *= 1
      break
  }
  //converting milliseconds to days if its greater than 365days converting to year
  const days = value / 86400000
  if (days === 365) return `${(days / 365).toFixed(2)} year`
  else if (days > 365 && days / 365 >= 2)
    return `${(days / 365).toFixed(2)} years`
  //converting milliseconds to hour if its greater than 48hr converting to days
  const hr = value / 3600000
  if (hr > 48) return `${days.toFixed(2)} days`
  //converting milliseconds to min if its greater than 120min converting to hours
  const min = value / 60000
  if (min === 1) return `${min.toFixed(2)} min`
  if (min >= 2 && min < 120) return `${min.toFixed(2)} mins`
  if (min >= 120) return `${hr.toFixed(2)} hrs`
  //converting milliseconds to seconds
  const sec = value / 1000
  if (sec === 1) return `${sec.toFixed(2)} sec`
  else if (sec >= 2) return `${sec.toFixed(2)} secs`

  return `${value.toFixed(2)} ms`
}

export const msToTimeUnits = (valueInMs: number) => {
  switch (true) {
    case valueInMs < 1000:
      return `${roundTimeValue(valueInMs)} ms`
    case valueInMs < 60000:
      return `${roundTimeValue(valueInMs / 1000)} sec`
    case valueInMs < 3600000:
      return `${roundTimeValue(valueInMs / 60000)} min`
    default:
      return `${roundTimeValue(valueInMs / 3600000)} hrs`
  }
}
export const roundTimeValue = (value: number, decimalPlaces = 2) => {
  const result = Number(value.toFixed(decimalPlaces))
  const result2 = Number(value.toFixed(0))
  return result === result2 ? result2 : result
}

/**
 * Util function to format give date
 * @param {Date | string} date - date to be formatted
 * @param {string} format - format
 * @returns {string} - formatted date string
 */
export const formatDate = (date: Date | string, format: string): string =>
  moment(date).format(format)

/**
 * Util function to check whether the given date1 is after the given date2
 * @param {Date} date1 - start date
 * @param {Date} date2 - end date
 * @returns {Boolean} - returns true if start date is after end date, else returns false
 */
export const isAfter = (date1: Date, date2: Date): boolean =>
  moment(date1).isAfter(date2)

/**
 * Util function to change a date string to required format
 * @param {string} date - date string
 * @param {string} dateFormat - date format of that date
 * @param {string} requiredFormat - required format
 * @returns {string} - formatted date string
 */
export const formatDateOfDifferentFormat = (
  date: string,
  dateFormat: string,
  requiredFormat: string
) => {
  return moment(date, dateFormat).format(requiredFormat)
}

export const getStartOfTheDate = (date: Date) => moment(date).startOf('day')

export const getEndOfTheDate = (date: Date) => moment(date).endOf('day')

export const subtractFromDate = (date: Date, amount: number) =>
  moment(date).subtract(amount, 'days').toDate()

export const formatMonth = (value: string) => {
  const shortMonthName = value.split(' ')[0].slice(0, 3)
  const restOfTheDate = value.split(' ').slice(1)
  return [shortMonthName, ...restOfTheDate].join(' ')
}

export const getTimeForActivity = (inputTime: string): string => {
  const now = new Date()
  const past = new Date(inputTime)
  const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000)

  const secondsInMinute = 60
  const secondsInHour = 60 * secondsInMinute
  const secondsInDay = 24 * secondsInHour

  const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
    return date.toLocaleDateString(undefined, options)
  }

  if (diffInSeconds < secondsInMinute) {
    return `${diffInSeconds} seconds ago`
  } else if (diffInSeconds < secondsInHour) {
    const minutes = Math.floor(diffInSeconds / secondsInMinute)
    return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} ago`
  } else if (diffInSeconds < secondsInDay) {
    const hours = Math.floor(diffInSeconds / secondsInHour)
    return `${hours} ${hours > 1 ? 'hours' : 'hour'} ago`
  } else if (diffInSeconds < 2 * secondsInDay) {
    const days = Math.floor(diffInSeconds / secondsInDay)
    return `${days} ${days > 1 ? 'days' : 'day'} ago`
  } else {
    return formatDate(past)
  }
}

export const getNotifiedTime = (inputTime: string): string => {
  const now = new Date()
  const past = new Date(inputTime)
  const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000)

  const secondsInMinute = 60
  const secondsInHour = 60 * secondsInMinute
  const secondsInDay = 24 * secondsInHour

  const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
    return date.toLocaleDateString(undefined, options)
  }

  if (diffInSeconds < secondsInMinute) {
    return `${diffInSeconds} s ago`
  } else if (diffInSeconds < secondsInHour) {
    const minutes = Math.floor(diffInSeconds / secondsInMinute)
    return `${minutes}m ago`
  } else if (diffInSeconds < secondsInDay) {
    const hours = Math.floor(diffInSeconds / secondsInHour)
    return `${hours}h ago`
  } else if (diffInSeconds < 2 * secondsInDay) {
    const days = Math.floor(diffInSeconds / secondsInDay)
    return `${days}d ago`
  } else {
    return formatDate(past)
  }
}
