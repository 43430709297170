import {
  ChevronLeft,
  ChevronRight,
  Help,
  Information
} from '@carbon/icons-react'
import { DDSButton, DDSMenu, DDSTypography } from 'den-design-system'
import React, { useEffect, useRef, useState } from 'react'

import StringConstants from '../../../constants/StringConstants'
import packageJson from '../../../../package.json'
import palette from '../../../global/newPallete'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

interface BottomSectionProps {
  isSidebarExpanded: boolean
  toggleSidebar: () => void
}

const BottomSection: React.FC<BottomSectionProps> = ({
  isSidebarExpanded,
  toggleSidebar
}: BottomSectionProps) => {
  const store = useStoreContext()
  const [isHelpDropdownOpen, setIsHelpDropdownOpen] = useState(false)

  const [selectedOption, setselectedOption] = useState<boolean>(false)
  const helpDropdownRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  const handleButtonClick = (button: boolean) => {
    setselectedOption(button)
  }

  const SelectedColor = (
    isHelpDropdownOpen: boolean,
    selectedOption: boolean,
    palette: any
  ) => {
    return isHelpDropdownOpen && selectedOption
      ? palette.colors.white
      : palette.colors.lightWhite
  }
  const SelectedOptionColor = (selectedOption: boolean, palette: any) => {
    return selectedOption ? palette.colors.textDark3 : palette.colors.textDark5
  }
  const SelectedPrimaryColor = (
    isHelpDropdownOpen: boolean,
    selectedOption: boolean,
    palette: any
  ) => {
    return isHelpDropdownOpen && selectedOption
      ? palette.colors.primary1
      : 'transparent'
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        helpDropdownRef.current &&
        !helpDropdownRef.current.contains(event.target as Node)
      ) {
        setIsHelpDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const toggleHelpDropdown = () => {
    setIsHelpDropdownOpen(!isHelpDropdownOpen)
  }

  const needHelp = (
    <>
      <DDSMenu
        parentId='help-menu'
        open={isHelpDropdownOpen}
        onClose={() => setIsHelpDropdownOpen(false)}
        className='!shadow-selectMenu'
      >
        <div className='flex justify-between items-center '>
          <div>
            <DDSTypography.Title
              type='h5'
              variant='semiBold'
              color={palette.colors.textDark5}
            >
              {StringConstants.NEED_HELP_SECTION.NEED_HELP}
            </DDSTypography.Title>
          </div>
        </div>
        <hr
          className='border border-solid border-t-[1px] border-b-[0px] border-l-[0px] border-r-[0px] my-[12px]'
          style={{ borderColor: palette.colors.stroke2 }}
        />
        <a
          href=''
          className='flex items-center p-[8px] hover:bg-gray-100 rounded'
          onClick={() => {
            navigate(routeConstants.COMING_SOON)
            setIsHelpDropdownOpen(false)
          }}
        >
          <DDSTypography.Paragraph
            size='para'
            variant='semiBold'
            color={pallete.colors.textDark5}
          >
            {StringConstants.NEED_HELP_SECTION.USER_DOCUMENTATION}
          </DDSTypography.Paragraph>
        </a>
        <a
          href=''
          className='flex items-center p-[8px] hover:bg-gray-100 rounded'
          onClick={(e: any) => {
            window.location.href = `mailto:${StringConstants.SUPPORT_EMAIL}`
            e.preventDefault()
            setIsHelpDropdownOpen(false)
          }}
        >
          <DDSTypography.Paragraph
            size='para'
            variant='semiBold'
            color={pallete.colors.textDark5}
          >
            {StringConstants.NEED_HELP_SECTION.CONTACT_SUPPORT}
          </DDSTypography.Paragraph>
        </a>
      </DDSMenu>
    </>
  )

  return (
    <>
      <div
        id='help-menu'
        className={`flex flex-col gap-[8px] 2xl:gap-[8px] flex-grow cursor-pointer ${
          isSidebarExpanded ? '' : 'justify-center'
        }`}
      >
        {isSidebarExpanded ? (
          <div className='relative' id='help-menu'>
            <div
              id='help-menu'
              className={`relative flex items-center justify-start h-full py-[8px] px-[20px] w-full bg-transparent hover:bg-opacity-30 rounded ${
                isSidebarExpanded ? '!px-[20px]' : 'justify-center'
              }`}
              style={{
                color: SelectedColor(
                  isHelpDropdownOpen,
                  selectedOption,
                  palette
                ),
                backgroundColor: SelectedPrimaryColor(
                  isHelpDropdownOpen,
                  selectedOption,
                  palette
                )
              }}
              onClick={() => {
                handleButtonClick(true)
                toggleHelpDropdown()
              }}
            >
              <div
                id='help-menu'
                className={`flex items-center`}
                style={{
                  color: selectedOption
                    ? palette.colors.white
                    : palette.colors.lightWhite
                }}
              >
                <Help
                  id='help-menu'
                  size={20}
                  style={{
                    color: SelectedColor(
                      isHelpDropdownOpen,
                      selectedOption,
                      palette
                    )
                  }}
                />
                {isSidebarExpanded && (
                  <div
                    id='help-menu'
                    className={`ml-[12px] flex flex-col max-w-[120px] flex-wrap`}
                  >
                    <DDSTypography.Paragraph
                      size='para'
                      variant='medium'
                      style={{
                        color: SelectedColor(
                          isHelpDropdownOpen,
                          selectedOption,
                          palette
                        )
                      }}
                    >
                      <span id='help-menu'>
                        {StringConstants.NEED_HELP_SECTION.NEED_HELP}
                      </span>
                    </DDSTypography.Paragraph>
                  </div>
                )}
                {isHelpDropdownOpen && selectedOption && (
                  <div
                    id='help-menu'
                    className={`absolute top-[0px] left-[0px] w-[8px] h-full`}
                    style={{ backgroundColor: palette.colors.primary2 }}
                  />
                )}
              </div>
            </div>

            {isHelpDropdownOpen && (
              <div
                id='help-menu'
                className='z-10 flex absolute lg:top-[-12px] 2xl:top-[0px] left-[100%] ml-[16px] !w-[250px] max-w-[300px]'
              >
                {needHelp}
              </div>
            )}
          </div>
        ) : (
          <div
            id='help-menu'
            className='flex flex-col gap-[12px] cursor-pointer '
          >
            <div
              id='help-menu'
              className={`relative flex items-center justify-start py-[8px] px-[20px] w-full bg-transparent hover:bg-opacity-30 rounded ${
                isSidebarExpanded ? 'px-[20px]' : 'justify-center 2xl:p-[16px]'
              }`}
              style={{
                color: selectedOption
                  ? palette.colors.white
                  : palette.colors.lightWhite,
                backgroundColor: SelectedPrimaryColor(
                  isHelpDropdownOpen,
                  selectedOption,
                  palette
                )
              }}
              onClick={() => {
                handleButtonClick(true)
                toggleHelpDropdown()
              }}
            >
              <div
                id='help-menu'
                className={`flex items-center`}
                style={{
                  color: SelectedColor(
                    isHelpDropdownOpen,
                    selectedOption,
                    palette
                  )
                }}
              >
                <Information
                  id='help-menu'
                  size={20}
                  style={{
                    color: SelectedColor(
                      isHelpDropdownOpen,
                      selectedOption,
                      palette
                    )
                  }}
                />
              </div>
              {isHelpDropdownOpen && selectedOption && (
                <div
                  id='help-menu'
                  className={`absolute top-[0px] left-[0px] w-[8px] h-full`}
                  style={{ backgroundColor: palette.colors.primary2 }}
                />
              )}
            </div>

            {isHelpDropdownOpen && (
              <div
                id='help-menu'
                className={`z-10 flex absolute lg:bottom-[12px] ${store.thingsToDoStore.getShowThingsToDo() ? '2xl:bottom-[70px]' : '2xl:bottom-[120px] lg:bottom-[50px]'} left-[100%] ml-[16px] !w-[250px] max-w-[300px]`}
              >
                {needHelp}
              </div>
            )}
          </div>
        )}

        <div className={`flex ${isSidebarExpanded ? '' : 'justify-center'}`}>
          <div className='text-white'>
            {isSidebarExpanded ? (
              <a
                onClick={toggleSidebar}
                className='flex items-center cursor-pointer '
              >
                <div className='flex items-center justify-start w-full gap-[12px] px-[20px] py-[8px]'>
                  <div>
                    <ChevronLeft
                      className='w-[20px] h-[20px] '
                      color={palette.colors.textDark6}
                    />
                  </div>

                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={palette.colors.textDark6}
                    className=''
                  >
                    {StringConstants.COLLAPSE}
                  </DDSTypography.Paragraph>
                </div>
              </a>
            ) : (
              <div>
                <ChevronRight
                  className=' w-[20px] h-[20px] mx-[20px] my-[8px]  2xl:ml-[20px]  cursor-pointer'
                  color={palette.colors.textDark6}
                  onClick={toggleSidebar}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default BottomSection
