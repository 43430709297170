import React from 'react'
import DistributionBar from './common/DistributionBar'
import palette from '../../global/pallete'
interface UsageBarProps {
  usage: number // in percentage
}
const UsageBar: React.FC<UsageBarProps> = ({ usage }) => {
  const distributions = [
    { value: usage, color: palette.colors.primary },
    { value: 100-usage, color: palette.colors.primary8 }
  ]
  return <DistributionBar distributions={distributions} rounded height={14}/>
}

export default UsageBar
