import React, { useState } from 'react'

import { Button } from '../New/Button/Button'
import CopyToClipboard from 'react-copy-to-clipboard'
import { DDSTypography } from 'den-design-system'
import Input from '../New/Input/Input'
import Modal from '../common/DDS/Modal/Modal'
import UptimeStringConstants from '../../constants/UptimeStringConstants'
import pallete from '../../global/newPallete'
import { useStoreContext } from '../../store/StoreContext'

interface DeletePopupProps {
  open: boolean
  check: any
  setOpen: any
  buttonLoading: boolean
  onCancel: () => void
  onDelete: () => void
}

const UCDeleteCheckPopup: React.FC<DeletePopupProps> = ({
  open,
  setOpen,
  check,
  buttonLoading,
  onCancel,
  onDelete
}) => {
  const DeleteFooter: React.FC = () => {
    const store = useStoreContext()
    return (
      <div className='flex override-btn-min-width justify-end px-[24px] py-[16px] gap-[10px]'>
        <Button
          id='no-modal-btn'
          type='neutral'
          disabled={buttonLoading}
          label={UptimeStringConstants.CANCEL}
          onClick={onCancel}
        />
        <Button
          id='yes-modal-btn'
          type='dangerFilled'
          disabled={buttonLoading || store.userStore.getNoActiveSubscription()}
          loading={buttonLoading}
          label={UptimeStringConstants.DELETE}
          onClick={onDelete}
        />
      </div>
    )
  }

  return (
    <Modal
      open={open}
      title={UptimeStringConstants.DELETE_POPUP_TITLE}
      setOpen={setOpen}
      footer={<DeleteFooter />}
      hideCloseIcon={false}
      showHeader
      onClosePress={() => {
        setOpen(false)
      }}
    >
      <div className='flex flex-col p-[24px] items-center justify-center'>
        <div>
          <DDSTypography.Title
            type='h4'
            variant='semiBold'
            className=''
            color={pallete.colors.textDark3}
          >
            {`The uptime check of "${check?.checkName}" will be permanently deleted.`}
          </DDSTypography.Title>
        </div>
      </div>
    </Modal>
  )
}

export default UCDeleteCheckPopup
