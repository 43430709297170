import { Copy, Information, Warning } from '@carbon/icons-react'
import { DDSTooltip, DDSTypography } from 'den-design-system'

import CopyButton from '../JMDashboard/CopyButton'
import React from 'react'
import Tooltip from '../../New/Tooltip/Tooltip'
import pallete from '../../../global/newPallete'

interface UCCardData {
  id: string
  heading: string
  content: string | React.ReactNode
  showCopy?: boolean
  showInfo?: boolean
  secondaryText?: string
  copyText?: string
}

const JMCard: React.FC<UCCardData> = ({
  id,
  heading,
  content,
  secondaryText,
  showInfo = false,
  showCopy = false,
  copyText = ''
}) => {
  return (
    <div
      id={id}
      style={{ borderColor: pallete.colors.stroke2 }}
      className='flex flex-col gap-[8px] rounded-md border bg-white py-[16px] px-[20px]'
    >
      <div className='flex justify-between items-center'>
        <div>
          <DDSTypography.Paragraph
            size='para'
            variant='regular'
            className='flex items-center gap-[4px]'
            color={pallete.colors.textDark5}
          >
            {heading}{' '}
          </DDSTypography.Paragraph>
        </div>
        {showCopy && (
          <div className='flex justify-end'>
            <CopyButton copyText={copyText} />
          </div>
        )}
        {secondaryText && (
          <div className='flex justify-end'>
            <Tooltip
              id='info-tooltip'
              className='max-w-[250px]'
              label={secondaryText}
            >
              <Information color={pallete.colors.textDark3} />
            </Tooltip>
          </div>
        )}
      </div>
      <DDSTypography.Title
        type='h5'
        variant='medium'
        color={pallete.colors.textDark3}
      >
        {content}
      </DDSTypography.Title>
    </div>
  )
}

export default JMCard
