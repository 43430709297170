import { DDSDatePicker } from 'den-design-system'
import { DatePickerProps } from './Datepicker.types'
import React from 'react'

type datepickerProps = {
  shape?: 'roundedRectangle' | 'rectangle'
} & DatePickerProps
const DatePicker: React.FC<datepickerProps> = ({
  id,
  name,
  minDate,
  maxDate,
  disabled,
  inputHeight,
  open,
  shape = 'rectangle',
  format,
  placeholder,
  defaultValue,
  value,
  required,
  label,
  errorMessage,
  inputWidth,
  popUpClassName,
  onChange,
  onBlur,
  onFocus,
  className
}) => {
  return (
    <div
      id={id}
      className={` ${shape === 'roundedRectangle' ? 'override-datepicker-shape' : ''}`}
    >
      <DDSDatePicker
        name={name}
        shape='rounded'
        minDate={minDate}
        popUpClassName={popUpClassName}
        maxDate={maxDate}
        disabled={disabled}
        open={open}
        format={format}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        required={required}
        label={label}
        errorMessage={errorMessage}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        inputHeight={inputHeight}
        inputWidth={inputWidth}
        className={className}
      />
    </div>
  )
}

export default DatePicker
