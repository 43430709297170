import { DDSLoader } from 'den-design-system'
import React from 'react'
import { TypographyDDS } from '../Typography/TypographyDDS'
import styled from '@emotion/styled'

const Container = styled.div`
  position: relative;
`
const TopContainer = styled.div<{ data?: any; noMarginTop?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ data }) => (data ? 'flex-end' : 'center')};
  align-items: ${({ data }) => (data ? 'flex-end' : 'center')};
  width: ${({ data }) => (data ? `${data + '%'}` : 'inherit')};
  margin-top: ${({ noMarginTop }) => (noMarginTop ? '0px' : '20px')};
`
const Spinner: React.FC<{
  size?: number
  actionText?: string
  data?: any
  noMarginTop?: boolean
}> = ({ size = 40, actionText, data, noMarginTop = false }) => {
  return (
    <TopContainer data={data} noMarginTop={noMarginTop}>
      <Container>
        {/* <CircularProgress
          variant='determinate'
          style={{
            color: palette.colors.borderColor
          }}
          size={size}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant='indeterminate'
          disableShrink
          style={{
            color: palette.colors.primary,
            animationDuration: '600ms',
            position: 'absolute',
            left: 0
          }}
          size={size}
          thickness={4}
        /> */}
        <DDSLoader loading={true} size='small' />
      </Container>
      <TypographyDDS.Title type='h4' color='primary' variant='medium'>
        {actionText}
      </TypographyDDS.Title>
    </TopContainer>
  )
}

export default Spinner
