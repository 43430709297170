import { Helmet } from 'react-helmet'
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStoreContext } from '../../store/StoreContext'

interface DocumentHeaderProps {
  header: string
}

const DocumentHeader: React.FC<DocumentHeaderProps> = ({ header }) => {
  const store = useStoreContext()
  const notificationCount =
    store.userStore.getNotificationCount() +
    store.userStore.getInvitationCount()
  return (
    <>
      <Helmet>
        <title>
          {notificationCount > 0
            ? ' (' + (notificationCount > 99 ? '99+' : notificationCount) + ') '
            : ''}{' '}
          {header}
        </title>
      </Helmet>
    </>
  )
}

export default observer(DocumentHeader)
