import { Grid, Stack } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import DividerLine from '../../components/common/DividerLine/DividerLine'
import KeyValueTable from '../../components/APIMonitoringPage/KeyValueTable'
import React from 'react'
import { TypographyDDS } from '../../components/common/Typography/TypographyDDS'
import UsageCard from '../../components/SalesforceAudit/common/UsageCard'
import arrowLeftIcon from '../../assets/icons/leftArrow.svg'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import { timeFormats } from '../../enum/TIME'
import { useStoreContext } from '../../store/StoreContext'

const ApexTriggerDetails: React.FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { salesforceAuditStore } = useStoreContext()
  const loading = salesforceAuditStore.getLoading()
  const data = salesforceAuditStore.getApexTriggerById(params.id!)
  const tableData = {
    'Trigger Name': data.Name,
    'Event Type': salesforceAuditStore.getApexTriggerEventString(params.id!),
    'API Version': data.ApiVersion,
    Status: data.Status,
    Length: data.LengthWithoutComments,
    'Created by': salesforceAuditStore.getNameById(data.CreatedById),
    'Created on': moment(data.CreatedDate).format(
      timeFormats.fullMonthDateYearTime
    ),
    'Last Modified by': salesforceAuditStore.getNameById(data.LastModifiedById),
    'Modified on': moment(data.LastModifiedDate).format(
      timeFormats.fullMonthDateYearTime
    )
  }
  const handleGoBack = () => {
    const segments = location.pathname.split('/').filter(Boolean)
    segments.pop()
    const newPath = '/' + segments.join('/')
    navigate(newPath)
  }
  return (
    <Grid container width='80%' paddingTop='16px' spacing={2}>
      <Grid item xs={12}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          style={{ cursor: 'pointer' }}
          onClick={() => handleGoBack()}
        >
          <img src={arrowLeftIcon} style={{ height: '16px' }} />
          <TypographyDDS.Paragraph size='para' color='textDark'>
            Go Back
          </TypographyDDS.Paragraph>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <TypographyDDS.Title type='h3' variant='bold'>
          {data.Name}
        </TypographyDDS.Title>
      </Grid>
      <Grid item xs={12}>
        <UsageCard noGutter>
          <TypographyDDS.Title
            type='h5'
            style={{
              margin: '24px'
            }}
          >
            Basic Details
          </TypographyDDS.Title>
          <DividerLine />
          <KeyValueTable data={tableData} noHeader />
        </UsageCard>
      </Grid>
      <Grid item xs={12}>
        <UsageCard noGutter>
          <TypographyDDS.Title
            type='h5'
            style={{
              margin: '24px'
            }}
          >
            Apex Body
          </TypographyDDS.Title>
          <DividerLine />
          <div style={{ overflow: 'scroll', maxHeight: '300px' }}>
            <pre style={{ padding: '24px' }}>
              <code>
                <TypographyDDS.Paragraph
                  size='para'
                  style={{
                    fontFamily: 'Mulish'
                  }}
                >
                  {data.Body}
                </TypographyDDS.Paragraph>
              </code>
            </pre>
          </div>
        </UsageCard>
      </Grid>
    </Grid>
  )
}
export default observer(ApexTriggerDetails)
