import Icon from '../Icon/Icon'
import { IconButton as MuiIconButton } from '@mui/material'
import React from 'react'
import closeIcon from '../../../assets/icons/close.svg'
import palette from '../../../global/pallete'

interface ButtonStyledProps {
  fontSize?: string
  color?: string
}

interface ButtonProps extends ButtonStyledProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  children?: React.ReactNode
  disabled?: boolean
}

const IconButton: React.FC<ButtonProps> = ({
  onClick,
  children = <img src={closeIcon} alt='closeIcon' />,
  color = palette.colors.textDark2,
  fontSize = '18px',
  disabled = false
}) => {
  return (
    <MuiIconButton
      id='close'
      aria-label='close'
      onClick={onClick}
      disabled={disabled}
    >
      <Icon color={color} fontSize={fontSize}>
        {children}
      </Icon>
    </MuiIconButton>
  )
}

export default IconButton
