import { BsChevronRight } from 'react-icons/bs'
import Button from '../Button/Button'
import DocumentHeader from '../../Helmet/DocumentHeader'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import React from 'react'
import { TypographyDDS } from '../Typography/TypographyDDS'
import palette from '../../../global/pallete'
import routeConstants from '../../../constants/RouteConstants'
import styled from '@emotion/styled'
import toolIcon from '../../../assets/icons/toolIcon.svg'
import { useNavigate } from 'react-router-dom'
import vigilIcon from '../../../assets/icons/vigilTMLogo.svg'

interface ItemContainerProps {
  width?: string
  height?: string
  padding?: string
  margin?: string
}

const Container = styled.div`
  min-height: 100vh;
  background-color: ${palette.colors.white};
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 48px 192px 0px;

  @media screen and (max-width: 1780px) {
    padding: 48px 140px 0px;
  }

  @media screen and (max-width: 1200px) {
    padding: 48px 50px 0px;
  }

  @media screen and (max-width: 600px) {
    padding: 20px 20px 0px;
  }
`

// const Header = styled.div`
//   display: flex;
//   justify-content: center;
//   padding-bottom: 48px;
// `

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  padding-bottom: 100px;
`

const Left = styled.div``

// const Right = styled.div``

const BackgroundImgContainer = styled.div`
  position: absolute;
  bottom: 20%;
  right: 5%;
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ItemContainer = styled.div<ItemContainerProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};

  @media screen and (max-width: 900px) {
    width: 100%;
    padding: 0px 0px;
  }
`

const ComingSoon = () => {
  const navigate = useNavigate()
  return (
    <Container>
      <DocumentHeader header={HeaderConstants.COMING_SOON} />

      <Wrapper>
        <Left>
          <ImageWrapper>
            <img
              src={vigilIcon}
              style={{
                width: '155px',
                margin: '2rem 2rem 4rem 0'
              }}
            />
          </ImageWrapper>
          <TypographyDDS.ExtraLarge
            variant='bold'
            style={{
              paddingBottom: '10px',
              maxWidth: '1098px',
              background: `${palette.colors.primary}`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            COMING SOON!
          </TypographyDDS.ExtraLarge>

          <TypographyDDS.Title
            type='h1'
            variant='semiBold'
            style={{
              paddingBottom: '10px',
              maxWidth: '1098px',
              background: `${palette.colors.textDark}`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            We are building this feature...
          </TypographyDDS.Title>
          <ItemContainer width='756px' margin='32px 0px 32px'>
            <Button
              variant='contained'
              endIcon={<BsChevronRight />}
              width='105%'
              height='56px'
              background={palette.colors.gradientBlue}
              onClick={() => {
                navigate(routeConstants.REDIRECT)
              }}
            >
              Return to Homepage
            </Button>
          </ItemContainer>
        </Left>
      </Wrapper>

      <BackgroundImgContainer>
        <img
          src={toolIcon}
          style={{ width: '520px', height: '480px', opacity: '0.04' }}
        />
      </BackgroundImgContainer>
    </Container>
  )
}

export default ComingSoon
