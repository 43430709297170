import { ChevronRight, Close } from '@carbon/icons-react'
import React, { useCallback, useEffect, useState } from 'react'
import {
  calculateStartAndEndByDateRange,
  calculateStartAndEndByDayWeekMonthYear
} from '../../utils/CommonUtils'
import {
  getApiByURLWeeklyLogs,
  getApiUsersByURLGraph
} from '../../api/apiMonitoring/ApiMonitoring.service'
import { useParams, useSearchParams } from 'react-router-dom'

import APICustomCardTooltip from './Components/APICustomCardTooltip'
import ApiGroupingStringConstants from '../../constants/ApiGroupingStringConstants'
import { DDSTypography } from 'den-design-system'
import NoFilterImage from '../../assets/images/folder-not-found 1.svg'
import NoRecord from '../common/NoContent/NoRecord'
import NoRecordImage from '../../assets/images/setting.svg'
import Search from '../New/Search/Search'
import SelectDropdown from '../common/GraphSelect/GraphSelect'
import Spinner from '../common/Spinner/Spinner'
import Status from './Components/Status'
import StringConstants from '../../constants/StringConstants'
import SuccessFailureGraph from './Components/SuccessFailureLineGraph'
import Table from '../common/DDS/Table/Table'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../common/DDS/Toast/Toast'
import Tooltip from '../New/Tooltip/Tooltip'
import { WeeklyAPILogsDateFilter } from '../../enum/ApiUsageWeeklyLogs.enum'
import { getAllQueryParamsAsObjectFromUrl } from '../../utils/UrlUtils'
import { isNull } from 'lodash'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import pallete from '../../global/newPallete'
import { useStoreContext } from '../../store/StoreContext'

interface viewHeaderGroupedApiDataProp {
  headerData: any
  setTabIndex: any
  viewHeaderGroupedApiData: () => void
  setTriggerFilter: any
}

interface ContentWithRedPercentProps {
  value: number
  percent: number
}

const APIURLGraphUsage: React.FC<viewHeaderGroupedApiDataProp> = ({
  headerData,
  setTabIndex,
  setTriggerFilter,
  viewHeaderGroupedApiData
}) => {
  const { id } = useParams()

  const store = useStoreContext()
  const [tableData, setTableData] = useState<any>()
  const [hoverText, setHoverText] = useState<any>('')
  const [logData, setLogData] = useState<any>()
  const [loading, setLoading] = React.useState(false)
  const [logLoading, setLogLoading] = React.useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchText, setSearchText] = useState('')
  const [selectedDateFilters, setSelectedDateFilters] = useState<any>()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [activeFilter, setActiveFilter] = useState<any>()
  const [timeFilter, setTimeFilter] = useState(
    WeeklyAPILogsDateFilter.THIS_WEEK
  )
  const [selectGraphPointIsSuccess, setSelectGraphPointIsSuccess] =
    useState<any>()

  const [totalCount, setTotalCount] = useState<number>(0)

  const timeFilterOptions = [
    { value: WeeklyAPILogsDateFilter.THIS_WEEK, label: 'This Week' },
    { value: WeeklyAPILogsDateFilter.LAST_WEEK, label: 'Last Week' },
    { value: WeeklyAPILogsDateFilter.THIS_MONTH, label: 'This Month' },
    { value: WeeklyAPILogsDateFilter.LAST_MONTH, label: 'Last 1 Month' },
    { value: WeeklyAPILogsDateFilter.LAST_3_MONTHS, label: 'Last 3 Months' }
  ]

  const timeNoDataFilterOptions = [
    {
      value: WeeklyAPILogsDateFilter.THIS_WEEK,
      label: 'This Week',
      noDataText: 'No data available for this week.'
    },
    {
      value: WeeklyAPILogsDateFilter.LAST_WEEK,
      label: 'Last Week',
      noDataText: 'No data available for last week.'
    },
    {
      value: WeeklyAPILogsDateFilter.THIS_MONTH,
      label: 'This Month',
      noDataText: 'No data available for this month.'
    },
    {
      value: WeeklyAPILogsDateFilter.LAST_MONTH,
      label: 'Last 1 Month',
      noDataText: 'No data available for the last month.'
    },
    {
      value: WeeklyAPILogsDateFilter.LAST_3_MONTHS,
      label: 'Last 3 Months',
      noDataText: 'No data available for the last 3 months.'
    }
  ]

  const activeFilterData = timeNoDataFilterOptions.find(
    (filter) => filter.value === timeFilter
  )

  const noDataMessage = activeFilterData?.noDataText || 'No data available.'

  const viewApiByURLWeeklyLogs = async (timeFilter: any) => {
    setLogLoading(true)
    store.uiStore.setGlobalLoader(true)

    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)

    let dateRange = ''
    switch (timeFilter) {
      case WeeklyAPILogsDateFilter.THIS_WEEK:
        dateRange = 'THIS_WEEK'
        break
      case WeeklyAPILogsDateFilter.LAST_WEEK:
        dateRange = 'LAST_WEEK'
        break
      case WeeklyAPILogsDateFilter.THIS_MONTH:
        dateRange = 'THIS_MONTH'
        break
      case WeeklyAPILogsDateFilter.LAST_MONTH:
        dateRange = 'LAST_MONTH'
        break
      case WeeklyAPILogsDateFilter.LAST_3_MONTHS:
        dateRange = 'LAST_3_MONTHS'
        break
      default:
        dateRange = 'THIS_WEEK'
    }

    getApiByURLWeeklyLogs({
      apiGroupId: id as string,
      'date-range': dateRange,
      'current-date': new Date(),
      'time-zone': store.userStore.getUserPreference().timeZone,
      ...allQueryParams
    })
      .then((response: any) => {
        setLogData(response)
        store.apiDetailsStore.setRefresh(false)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        setLogLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const filteredTableData = async (
    dateFilters: {
      month?: number
      year?: number
      week?: number
      day?: number
    },
    isSuccess: boolean,
    hoverText: any
  ) => {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)

    setSelectedDateFilters(dateFilters)
    setActiveFilter(dateFilters)
    setHoverText(hoverText)

    let dateRange = ''
    switch (timeFilter) {
      case WeeklyAPILogsDateFilter.THIS_WEEK:
        dateRange = 'THIS_WEEK'
        break
      case WeeklyAPILogsDateFilter.LAST_WEEK:
        dateRange = 'LAST_WEEK'
        break
      case WeeklyAPILogsDateFilter.THIS_MONTH:
        dateRange = 'THIS_MONTH'
        break
      case WeeklyAPILogsDateFilter.LAST_MONTH:
        dateRange = 'LAST_MONTH'
        break
      case WeeklyAPILogsDateFilter.LAST_3_MONTHS:
        dateRange = 'LAST_3_MONTHS'
        break
      default:
        dateRange = 'THIS_WEEK'
    }

    getApiUsersByURLGraph({
      apiGroupId: id as string,
      ...allQueryParams,
      'date-range': dateRange,
      'is-success': isSuccess,
      month: dateFilters.month as number,
      year: dateFilters.year as number,
      week: dateFilters.week as number,
      day: dateFilters.day as number,
      'current-date': new Date(),
      'time-zone': store.userStore.getUserPreference().timeZone,
      size: StringConstants.THIRD_TABLE_SIZE,
      page: '1'
    })
      .then((response: any) => {
        setTableData(response.data)
        setTotalCount(response.totalCount)
        setCurrentPage(response.page)
        setSearchParams({
          ...allQueryParams,
          page: '1'
        })
        store.apiDetailsStore.setRefresh(false)
        store.filterStore.setFilters(response.filters)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const filteredURLTableData = async (
    timeFilter: any,
    pageNo?: number,
    isResetSuccess?: boolean,
    searchText?: string
  ) => {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)

    let dateRange = ''
    switch (timeFilter) {
      case WeeklyAPILogsDateFilter.THIS_WEEK:
        dateRange = 'THIS_WEEK'
        break
      case WeeklyAPILogsDateFilter.LAST_WEEK:
        dateRange = 'LAST_WEEK'
        break
      case WeeklyAPILogsDateFilter.THIS_MONTH:
        dateRange = 'THIS_MONTH'
        break
      case WeeklyAPILogsDateFilter.LAST_MONTH:
        dateRange = 'LAST_MONTH'
        break
      case WeeklyAPILogsDateFilter.LAST_3_MONTHS:
        dateRange = 'LAST_3_MONTHS'
        break
      default:
        dateRange = 'THIS_WEEK'
    }

    getApiUsersByURLGraph({
      apiGroupId: id as string,
      ...allQueryParams,
      'date-range': dateRange,
      'current-date': new Date(),
      'time-zone': store.userStore.getUserPreference().timeZone,
      size: StringConstants.THIRD_TABLE_SIZE,
      page: pageNo || 1,
      ...(searchText ? { 'search-text': searchText } : {}),
      ...(selectGraphPointIsSuccess && !isResetSuccess
        ? { 'is-success': selectGraphPointIsSuccess === '1' ? 'true' : 'false' }
        : {})
    })
      .then((response: any) => {
        setTableData(response.data)
        setTotalCount(response.totalCount)
        setCurrentPage(response.page)
        store.apiDetailsStore.setRefresh(false)
        store.filterStore.setFilters(response.filters)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>
    return (...args: any[]) => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => func(...args), delay)
    }
  }

  const debouncedSearch = useCallback(
    debounce((searchValue: string) => {
      const allQueryParams = Object.fromEntries(searchParams.entries())
      setSearchParams({
        ...allQueryParams,
        'search-text': searchValue,
        page: '1'
      })
      filteredURLTableData(timeFilter, 1, true, searchValue)
    }, 500),
    [searchParams, setSearchParams]
  )

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value
    setSearchText(searchValue)
    debouncedSearch(searchValue)
  }

  const handleClearFilter = () => {
    setSelectGraphPointIsSuccess(null)
    setActiveFilter(null)
    setHoverText('')

    let filterToClear = ''
    switch (timeFilter) {
      case WeeklyAPILogsDateFilter.THIS_WEEK:
        filterToClear = 'THIS_WEEK'
        break
      case WeeklyAPILogsDateFilter.LAST_WEEK:
        filterToClear = 'LAST_WEEK'
        break
      case WeeklyAPILogsDateFilter.THIS_MONTH:
        filterToClear = 'THIS_MONTH'
        break
      case WeeklyAPILogsDateFilter.LAST_MONTH:
        filterToClear = 'LAST_MONTH'
        break
      case WeeklyAPILogsDateFilter.LAST_3_MONTHS:
        filterToClear = 'LAST_3_MONTHS'
        break
      default:
        filterToClear = 'THIS_WEEK'
    }
    filteredURLTableData(filterToClear, undefined, true)
  }

  useEffect(() => {
    setSelectGraphPointIsSuccess(null)
    viewApiByURLWeeklyLogs(timeFilter)
    filteredURLTableData(timeFilter, undefined, true)
  }, [timeFilter])

  useEffect(() => {
    if (store.apiDetailsStore.getRefresh() === true) {
      store.uiStore.setGlobalLoader(true)
      filteredURLTableData(timeFilter)
      viewHeaderGroupedApiData()
      viewApiByURLWeeklyLogs(timeFilter)
    }
  }, [store.apiDetailsStore.getRefresh()])

  useEffect(() => {
    const pageFromQueryParams = searchParams.get('page')
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (!isNull(pageFromQueryParams)) {
      setCurrentPage(Number(pageFromQueryParams))
      viewApiByURLWeeklyLogs(timeFilter)
      filteredURLTableData(timeFilter)
    } else {
      setSearchParams({
        ...allQueryParams,
        page: '1'
      })
      setCurrentPage(1)
    }
  }, [store.scopeStore.getScope()])

  const routeGroupedDetails = (id: string, responseStatusCodes: any) => {
    let start: Date | undefined
    let end: Date | undefined

    if (selectedDateFilters) {
      const { startDate, endDate } = calculateStartAndEndByDayWeekMonthYear({
        day: selectedDateFilters.day,
        week: selectedDateFilters.week,
        month: selectedDateFilters.month,
        year: selectedDateFilters.year,
        currentDate: new Date()
      })

      start = startDate
      end = endDate

      console.log(start, end, 'Start & End Dates')
    } else if (timeFilter) {
      const currentDate = new Date()
      const { startDate, endDate } = calculateStartAndEndByDateRange(
        timeFilter,
        currentDate
      )
      start = startDate
      end = endDate
    }

    if (!start || !end) {
      console.error('Start and End dates could not be determined.')
      return
    }

    setSearchParams({
      tab: '1',
      page: '1',
      users: id,
      'response-status-code': responseStatusCodes,
      'created-from': start.getTime().toString(),
      'created-to': end.getTime().toString(),
      'time-zone': store.userStore.getUserPreference()?.timeZone
    })
  }

  const ContentWithRedPercent: React.FC<ContentWithRedPercentProps> = ({
    value,
    percent
  }) => {
    return (
      <div className='flex items-center gap-1'>
        <span>{value}</span>
        <span style={{ color: pallete.colors.textDark6 }}>({percent}%)</span>
      </div>
    )
  }

  const successPercent = Math.round(
    (headerData?.successApiCount / headerData?.apiCount) * 100
  )

  const failurePercent = 100 - successPercent

  const apiData = [
    {
      id: 1,
      heading: 'Total API Count',
      content: headerData?.apiCount
    },
    {
      id: 2,
      heading: 'Total Users',
      content: headerData?.userCount
    },
    {
      id: 3,
      heading: 'Total Success APIs',
      content: (
        <ContentWithRedPercent
          value={headerData?.successApiCount}
          percent={successPercent}
        />
      )
    },
    {
      id: 4,
      heading: 'Total Failure APIs',
      content: (
        <ContentWithRedPercent
          value={headerData?.apiCount - headerData?.successApiCount}
          percent={failurePercent}
        />
      )
    }
  ]

  const columns = [
    {
      columnHeaderStyle: { width: '50%' },
      columnDataStyle: { width: '50%' },
      heading: (
        <div className='flex justify-start max-w-[250px] text-start row items-start'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.USERS}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'userName',
      render: (text: any) => (
        <div className='flex justify-start text-start row items-start max-w-[120px]'>
          <div>
            <Tooltip
              id={`tooltip-name-${text}`}
              label={text}
              className={`override-tooltip-arrow ${
                typeof text === 'string' && text.length > 15 ? text : '!hidden'
              }`}
            >
              <div>
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  color={pallete.colors.textDark4}
                  className='tooltip-container sm:block overflow-hidden truncate max-w-[120px]'
                >
                  {text || text === 0 ? text : '-'}
                </DDSTypography.Paragraph>
              </div>
            </Tooltip>
          </div>
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '50%' },
      columnDataStyle: { width: '50%' },
      heading: (
        <div className='flex justify-center max-w-[300px] text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Count
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'status',
      render: (text: any, fullItem: any) => {
        const failureCount = fullItem?.totalCalls - fullItem?.successCalls
        const successCount = fullItem?.successCalls

        return (
          <div className='flex justify-center max-w-[300px] items-center'>
            {failureCount === 0 ? (
              <Status
                count={successCount}
                label={successCount > 1 ? 'Successes' : 'Success'}
                textColor={pallete.colors.warningGreen}
                bgColor={pallete.colors.warningGreenBg}
                borderTopLeftRadius='4px'
                borderBottomLeftRadius='4px'
                borderTopRightRadius='4px'
                borderBottomRightRadius='4px'
                textAlignment='left'
                paddingBottom='10px'
                paddingTop='10px'
                paddingLeft='16px'
                paddingRight='16px'
              />
            ) : successCount === 0 ? (
              <Status
                count={failureCount}
                label={failureCount > 1 ? 'Failures' : 'Failure'}
                textColor={pallete.colors.warningRed1}
                bgColor={pallete.colors.warningRed5}
                borderTopLeftRadius='4px'
                borderBottomLeftRadius='4px'
                borderTopRightRadius='4px'
                borderBottomRightRadius='4px'
                textAlignment='left'
                paddingBottom='10px'
                paddingTop='10px'
                paddingLeft='16px'
                paddingRight='16px'
              />
            ) : (
              <>
                <Status
                  count={failureCount}
                  label={failureCount > 1 ? 'Failures' : 'Failure'}
                  textColor={pallete.colors.warningRed1}
                  bgColor={pallete.colors.warningRed5}
                  borderTopLeftRadius='4px'
                  borderBottomLeftRadius='4px'
                  textAlignment='center'
                />
                <Status
                  count={successCount}
                  label={successCount > 1 ? 'Successes' : 'Success'}
                  textColor={pallete.colors.warningGreen}
                  bgColor={pallete.colors.warningGreenBg}
                  borderTopRightRadius='4px'
                  borderBottomRightRadius='4px'
                  textAlignment='center'
                />
              </>
            )}
          </div>
        )
      }
    },
    {
      columnHeaderStyle: { width: '20px' },
      columnDataStyle: { width: '20px' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='cursor-pointer'>
          <ChevronRight
            size={16}
            color={pallete.colors.FillButton}
            onClick={() => {
              setTriggerFilter(true)
              routeGroupedDetails(fullItem.id, fullItem.responseStatusCodes)
              setTabIndex('1')
            }}
          />
        </div>
      )
    }
  ]

  const previousDate = new Date()
  previousDate.setDate(previousDate.getDate() - 1)

  // Format the date (e.g., "Nov 14, 2024")
  const formattedDate = previousDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })

  return (
    <div className='flex flex-col py-[24px] h-full'>
      <div className='grid grid-cols-4 gap-4 pb-[24px]'>
        {apiData.map((item) => (
          <APICustomCardTooltip
            key={item.id}
            id={item.id}
            heading={item.heading}
            content={item.content}
          />
        ))}
      </div>
      <div className=' rounded-lg border h-[66vh] p-[20px]'>
        <div className='flex space-x-[16px] h-full'>
          {/* Left Side: Graph, Dropdown, Last Sync, and API Usage */}
          <div className='w-[60%] h-full'>
            <div className='flex flex-col'>
              {/* Header Section: API Usage (Left) and Last Sync + Dropdown (Right) */}
              <div className='flex justify-between items-center mb-[16px]'>
                {/* Left: API Usage */}
                <DDSTypography.Paragraph
                  size='para'
                  variant='regular'
                  color={pallete.colors.textDark5}
                >
                  API Usage
                </DDSTypography.Paragraph>

                {/* Right: Last Sync and Dropdown */}
                <div className='flex items-center flex-shrink-0 space-x-[12px]'>
                  <span
                    style={{
                      fontWeight: '400',
                      fontSize: '14px',
                      color: pallete.colors.textDark5
                    }}
                  >
                    Last sync: <span>{formattedDate}</span>
                  </span>
                  {activeFilter && (
                    <div className='inline-flex items-center gap-[8px] px-[12px] h-[32px] py-[8px] bg-blue-50 text-gray-700 rounded-md'>
                      <span className='text-sm font-medium'>
                        {selectGraphPointIsSuccess === '1'
                          ? 'Success on'
                          : 'Failure on'}{' '}
                        {hoverText
                          .replace(
                            /(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday)/,
                            (match: any) => match.slice(0, 3)
                          )
                          .replace(
                            /(January|February|March|April|May|June|July|August|September|October|November|December)/,
                            (match: any) => match.slice(0, 3)
                          )}
                      </span>

                      <button
                        className='hover:bg-blue-100 rounded-full transition-colors'
                        aria-label='Dismiss'
                      >
                        <Close size={14} onClick={handleClearFilter} />
                      </button>
                    </div>
                  )}

                  <div
                    className='flex items-center rounded-[6px] h-[32px]'
                    style={{
                      border: `1px solid ${pallete.colors.neuButtonStokeDis}`
                    }}
                  >
                    <SelectDropdown
                      options={timeFilterOptions}
                      value={timeFilter}
                      onChange={(value: any) => {
                        setTimeFilter(value)
                        setActiveFilter(null)
                        const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                          location.search
                        )
                        setSearchParams({ ...allQueryParams, page: '1' })
                      }}
                      placeholder='This Week'
                    />
                  </div>
                </div>
              </div>

              {/* Graph */}
              <div className='container mx-auto h-[55vh] 2xl:h-[56vh]'>
                {Array.isArray(logData) && logData.length === 0 ? (
                  <NoRecord
                    imageSrc={NoRecordImage}
                    style={{ height: '45vh' }}
                    text={<div>No results found</div>}
                  />
                ) : (
                  <SuccessFailureGraph
                    data={logData}
                    setSelectGraphPointIsSuccess={setSelectGraphPointIsSuccess}
                    filteredTableData={(dateFilters, isSuccess, hoverText) =>
                      filteredTableData(dateFilters, isSuccess, hoverText)
                    }
                  />
                )}
              </div>
            </div>
          </div>

          {/* Right Side: Search and Table */}
          <div className='w-[40%] h-full'>
            <div className='mb-[16px]'>
              <Search
                placeholder='Search for user'
                id='log-search'
                name='logSearch'
                onChange={handleSearch}
              />
            </div>

            {loading ||
            logLoading ||
            store.uiStore.getGlobalLoader() ||
            !tableData ? (
              <div className='flex justify-center items-center h-[50vh]'>
                <Spinner />
              </div>
            ) : totalCount > 0 ? (
              <div className='table-border-override override-table-container'>
                <Table
                  tableStyle={{
                    borderColor: pallete.colors.stroke2
                  }}
                  data={tableData}
                  columns={columns}
                  totalCount={totalCount}
                  rowsPerPage={8}
                  disabled={
                    loading ||
                    store.uiStore.getGlobalLoader() ||
                    store.uiStore.getprojectEnvironmentLoader()
                  }
                  currentPage={currentPage}
                  onPageChange={(page: number) => {
                    const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                      location.search
                    )
                    setCurrentPage(page)
                    filteredURLTableData(timeFilter, page)
                    setSearchParams({
                      ...allQueryParams,
                      page: page.toString(),
                      ...(selectedDateFilters && {
                        'is-success':
                          selectGraphPointIsSuccess === '1' ? 'true' : 'false'
                      })
                    })
                  }}
                  loading={loading}
                  overrideHeight={true}
                />
              </div>
            ) : (
              <>
                <div className='border border-gray-200 rounded-lg  p-[16px] flex items-center justify-center 2xl:h-[56.8vh] h-[54.8vh]'>
                  <div className='table w-full h-full'>
                    <div className='table-row'>
                      <div className='table-cell align-middle text-center'>
                        <NoRecord
                          imageSrc={
                            totalCount === 0 &&
                            store.filterStore.getFiltersApplied()
                              ? NoFilterImage
                              : NoRecordImage
                          }
                          style={{ height: '50vh' }}
                          text={
                            totalCount === 0 &&
                            store.filterStore.getFiltersApplied() ? (
                              <>{noDataMessage}</>
                            ) : (
                              <>{noDataMessage}</>
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(APIURLGraphUsage)
