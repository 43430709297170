import React from 'react'

export const copyJsonToClipboard = (
  data: any,
  id: string | null,
  timeout: number,
  setCopy: React.Dispatch<React.SetStateAction<string | null>>
) => {
  navigator.clipboard
    .writeText(data)
    .then(() => {
      if (id) {
        setCopy(id)
        setTimeout(() => {
          setCopy(null)
        }, timeout)
      }
    })
    .catch((error) =>
      console.error('Failed to copy JSON to clipboard: ', error)
    )
}
