export enum ProjectType {
  SALESFORCE_ONLY = 'SALESFORCE_ONLY',
  FULL_STACK_ONLY = 'FULL_STACK_ONLY',
  MULESOFT_ONLY = 'MULESOFT_ONLY'
}

export enum ProjectTypeDisplayNames {
  SALESFORCE_ONLY = 'Salesforce org',
  FULL_STACK_ONLY = 'Full-stack app',
  MULESOFT_ONLY = 'Mulesoft app'
}
