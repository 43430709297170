import { DDSButton, DDSModal, DDSTypography } from 'den-design-system'

import { Button } from '../../New/Button/Button'
import React from 'react'
import { SetStateAction } from 'react'
import pallete from '../../../global/newPallete'

interface ModalFooterProps {
  primaryButtonText?: string
  secondaryButtonText?: string
  onYes?: () => any
  onNo?: () => any
  primaryButtonDisabled?: boolean
  secondaryButtonDisabled?: boolean
  deleteLoading?: boolean
  editLoading?: boolean
}
const ModalFooter: React.FC<ModalFooterProps> = ({
  onYes,
  onNo,
  primaryButtonText = '',
  secondaryButtonText = '',
  primaryButtonDisabled,
  secondaryButtonDisabled,
  deleteLoading,
  editLoading
}) => {
  return (
    <div className='flex flex-row gap-x-[8px] px-[24px] py-[16px] override-btn-min-width justify-end'>
      {secondaryButtonText !== '' && (
        <Button
          id='secondary-btn'
          label={secondaryButtonText}
          type='neutral'
          size='small'
          disabled={editLoading || deleteLoading}
          onClick={() => (onNo ? onNo() : undefined)}
        />
      )}
      {primaryButtonText !== '' && (
        <Button
          id='primary-btn'
          disabled={deleteLoading || editLoading}
          type='filled'
          size='small'
          loading={editLoading || deleteLoading}
          label={primaryButtonText}
          onClick={() => (onYes ? onYes() : undefined)}
        />
      )}
    </div>
  )
}

interface StatusIncidentPopupProp {
  modalOpen: boolean
  setModalOpen: React.Dispatch<SetStateAction<boolean>>

  content: React.ReactNode
  showFooter?: boolean
  primaryButtonClick?: any
  secondaryButtonClick?: any
  primaryButtonText?: string
  secondaryButtonText?: string
  primaryButtonDisabled?: boolean
  secondaryButtonDisabled?: boolean
  deleteLoading?: boolean
  editLoading?: boolean
}
export const StatusIncidentPopup: React.FC<StatusIncidentPopupProp> = ({
  modalOpen,
  setModalOpen,
  content,
  primaryButtonClick,
  secondaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  primaryButtonDisabled,
  secondaryButtonDisabled,
  showFooter = false,
  deleteLoading,
  editLoading
}) => {
  return (
    <DDSModal
      open={modalOpen}
      setOpen={setModalOpen}
      defaultFooter={false}
      onIconClose={() => setModalOpen(false)}
      hideCloseIcon={deleteLoading || editLoading}
    >
      <div>
        <div
          className='flex items-start flex-row py-[16px] px-[24px]  border-b'
          style={{ borderBottomColor: pallete.colors.stroke2 }}
        >
          <DDSTypography.Title
            variant='bold'
            type='h4'
            color={pallete.colors.textDark4}
            className='text-center flex items-start'
          >
            Monitor Incident
          </DDSTypography.Title>
        </div>
        {content}
      </div>
      {showFooter && (
        <ModalFooter
          onYes={primaryButtonClick}
          onNo={secondaryButtonClick}
          deleteLoading={deleteLoading}
          editLoading={editLoading}
          primaryButtonText={primaryButtonText}
          secondaryButtonText={secondaryButtonText}
          primaryButtonDisabled={primaryButtonDisabled}
          secondaryButtonDisabled={secondaryButtonDisabled}
        />
      )}
    </DDSModal>
  )
}
