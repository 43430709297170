import React, { useState } from 'react'
import { dateRange, getIconForProjectType } from '../../utils/OverviewUtils'

import { Button } from '../New/Button/Button'
import { DDSTypography } from 'den-design-system'
import InviteMemberPopup from './InviteMemberPopup'
import { OrganizationUserRoles } from '../../enum/OrganizationUserRoles.enum'
import OverviewStringConstants from '../../constants/OverviewStringConstants'
import { ProjectType } from '../../enum/ProjectType.enum'
import SideChip from '../New/SideChip/SideChip'
import { login } from '../../api/Auth.service'
import pallete from '../../global/newPallete'
import { useStoreContext } from '../../store/StoreContext'

const OverviewHeader = () => {
  const store = useStoreContext()
  const [invitePopupOpen, setInvitePopupOpen] = useState<boolean>(false)
  const userTimeZone = store.userStore.getUserPreference().timeZone
  const formattedDateRange = dateRange(userTimeZone)

  return store.scopeStore.getSelectedProject().name ? (
    <div className='flex items-center justify-between'>
      <div className='flex gap-[10px] items-center w-full'>
        {store.scopeStore.getSelectedProject()?.type !==
          ProjectType.FULL_STACK_ONLY && (
          <div className='override-project-icon'>
            {getIconForProjectType(store.scopeStore.getSelectedProject()?.type)}
          </div>
        )}
        <DDSTypography.Title
          type='h3'
          variant='semiBold'
          color={pallete.colors.textDark3}
        >
          {store.scopeStore.getSelectedProject().name}
        </DDSTypography.Title>
      </div>
      <div className='flex gap-[10px]'>
        <SideChip
          id='date-range-chip'
          label='Date Range'
          value={formattedDateRange}
        />
        {store.userStore.getUserRole() === OrganizationUserRoles.OWNER && (
          <Button
            size='small'
            id='invite-member-btn'
            type='filled'
            label={OverviewStringConstants.INVITE_MEMBERS}
            onClick={() => setInvitePopupOpen(true)}
            disabled={store.userStore.getNoActiveSubscription()}
          />
        )}
      </div>
      <InviteMemberPopup
        open={invitePopupOpen}
        setOpen={setInvitePopupOpen}
        loading={store.uiStore.getGlobalLoader()}
      />
    </div>
  ) : (
    <></>
  )
}

export default OverviewHeader
