import { DDSButton, DDSTypography } from 'den-design-system'
import React, { useEffect, useRef, useState } from 'react'
import {
  getAllCommentsOfIncident,
  getAllIncidents,
  getAnIncident
} from '../../../api/incident/incident.service'
import { getTimeForActivity, timeZone } from '../../../utils/DateTimeUtils'
import { useNavigate, useParams } from 'react-router-dom'

import { ActivityMessagesConstant } from '../../../constants/IncidentPageConstants'
import Breadcrumbs from '../../../components/common/DDS/Breadcrumbs/Breadcrumbs'
import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import { Download } from '@carbon/icons-react'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import Spinner from '../../../components/common/Spinner/Spinner'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import html2pdf from 'html2pdf.js'
import { isUndefined } from 'lodash'
import pallete from '../../../global/newPallete'
import { replaceUrl } from '../../../utils/UrlUtils'
import routeConstants from '../../../constants/RouteConstants'
import { timeFormats } from '../../../enum/TIME'
import { useStoreContext } from '../../../store/StoreContext'
import vigilLogo from '../../../assets/images/vigilLogo.png'

const IncidentOverView: React.FC = () => {
  const { incidentId } = useParams()
  const [incidentDetails, setIncidentDetails] = useState<any>({})
  const [activityMessages, setActivityMessages] = React.useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  const store = useStoreContext()

  const downloadPdf = () => {
    const element = document.getElementById('pdfContent')
    const options = {
      margin: 10,
      filename: 'incident-overview.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    }

    html2pdf()
      .from(element)
      .set(options)
      .toPdf()
      .get('pdf')
      .then((pdf: any) => {
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const imageWidth = 162.095
        const imageHeight = 25
        const pxToMm = (px: number) => (px * 25.4) / 96
        const imageWidthMm = pxToMm(imageWidth)
        const imageHeightMm = pxToMm(imageHeight)
        const imageX = pdfWidth - imageWidthMm - 10 // 10mm margin
        const imageY = 10
        pdf.setPage(1)
        pdf.addImage(
          vigilLogo,
          'PNG',
          imageX,
          imageY,
          imageWidthMm,
          imageHeightMm
        )
      })
      .save()
  }

  const fetchAnIncident = () => {
    setLoading(true)
    getAnIncident({ $incidentId: incidentId })
      .then((response: any) => {
        setIncidentDetails(response.data)
      })
      .catch((error: any) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.INCIDENT.GET_ALL_INCIDENTS.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const fetchAllActivities = () => {
    setLoading(true)
    getAllCommentsOfIncident({ $incidentId: incidentId as string })
      .then((response: any) => {
        setActivityMessages(response.data)
      })
      .catch((error: any) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.INCIDENT.GET_ALL_INCIDENTS.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    if (!isUndefined(incidentDetails.incidentNumber)) {
      store.breadcrumbStore.setMultipleBreadcrumbsOptions([
        {
          label: 'Incidents',
          link:
            replaceUrl(
              routeConstants.INCIDENT,
              store.scopeStore.getScope()['organization-id'] as string,
              store.scopeStore.getScope()['project-id'] as string,
              store.scopeStore.getScope()['environment-id'] as string
            ) + '?page=1&tab=1'
        },
        {
          label: incidentDetails?.incidentNumber,
          link: replaceUrl(
            routeConstants.MANAGE_INCIDENTS,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ).replace(':incidentId', String(incidentId))
        },
        {
          label: 'Post-mortem',
          link: replaceUrl(
            routeConstants.UPDATE_INCIDENT,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          )
        }
      ])
    }
  }, [store.breadcrumbStore.getBreadcrumbsOptions(), incidentDetails])

  useEffect(() => {
    fetchAllActivities()
    fetchAnIncident()
  }, [])

  return (
    <>
      <DocumentHeader header={HeaderConstants.INCIDENT} />
      {loading || store.uiStore.getGlobalLoader() ? (
        <Spinner />
      ) : (
        <div
          className='relative h-[100%] flex flex-col py-[24px]'
          id='pdfContent'
        >
          <div>
            <div className='flex flex-row pb-[8px] justify-between'>
              <DDSTypography.Title
                type='h3'
                variant='bold'
                className='max-w-[600px]'
                color={pallete.colors.textDark3}
              >
                {incidentDetails.briefDescription}
              </DDSTypography.Title>
              <div
                className='flex flex-row gap-x-[8px]'
                data-html2canvas-ignore='true'
              >
                <DDSButton
                  label={
                    <DDSTypography.Paragraph
                      size='para'
                      variant='semiBold'
                      color={pallete.colors.white}
                    >
                      Download
                    </DDSTypography.Paragraph>
                  }
                  type='filled'
                  shape='roundedRectangle'
                  onClick={downloadPdf}
                  buttonHoverTextColor={pallete.colors.textDark10}
                  size='small'
                  style={{
                    color: pallete.colors.white
                  }}
                  disabled={store.userStore.getNoActiveSubscription()}
                  className='whitespace-nowrap !min-w-0'
                  buttonHoverBackgroundColor={pallete.colors.fillButtonhover}
                  startIcon={<Download color={pallete.colors.white} />}
                />
              </div>
            </div>
            <div className='flex flex-row gap-x-[12px] items-baseline'>
              <div className='flex flex-row gap-x-[8px] items-baseline'>
                <DDSTypography.Paragraph
                  size='caption'
                  variant='medium'
                  color={pallete.colors.textDark6}
                >
                  Created by:
                </DDSTypography.Paragraph>
                <DDSTypography.Paragraph
                  size='para'
                  variant='semiBold'
                  color={pallete.colors.textDark3}
                  className='whitespace-nowrap'
                >
                  {incidentDetails.createdBy}
                </DDSTypography.Paragraph>
              </div>
              <div className='flex flex-row pl-[12px] border-l gap-x-[8px] items-baseline'>
                <DDSTypography.Paragraph
                  size='caption'
                  variant='medium'
                  color={pallete.colors.textDark6}
                >
                  Created on:
                </DDSTypography.Paragraph>
                <DDSTypography.Paragraph
                  size='para'
                  variant='semiBold'
                  color={pallete.colors.textDark3}
                  className='whitespace-nowrap'
                >
                  {timeZone(
                    store.userStore.getUserPreference().timeZone,
                    incidentDetails?.createdAt,
                    timeFormats.fullMonthDateYear
                  )}
                </DDSTypography.Paragraph>
              </div>
            </div>
          </div>

          <div className='flex flex-col gap-y-[8px] pt-[24px]'>
            <DDSTypography.Title
              type='h5'
              variant='semiBold'
              color={pallete.colors.textDark3}
              className='custom-label'
            >
              Long Description
            </DDSTypography.Title>
            <DDSTypography.Paragraph
              variant='regular'
              size='para'
              color={pallete.colors.textDark5}
              style={{
                overflowWrap: 'break-word'
              }}
            >
              {incidentDetails.longDescription}
            </DDSTypography.Paragraph>
          </div>
          {incidentDetails?.savedLinks?.length > 0 && (
            <div className='pt-[24px]'>
              <DDSTypography.Title
                type='h5'
                variant='semiBold'
                color={pallete.colors.textDark3}
                className='custom-label'
              >
                Reference links
              </DDSTypography.Title>
              <div className=' flex flex-col  gap-y-[8px]'>
                {incidentDetails?.savedLinks?.map((link: any, index: any) => (
                  <DDSTypography.Paragraph
                    key={index}
                    size='para'
                    variant='medium'
                    color={pallete.colors.primary2}
                    className='flex flex-col gap-[8px] pr-[32px]'
                  >
                    <a
                      onClick={(e: any) => {
                        e.preventDefault()
                        window.open(link, '_blank', 'noopener,noreferrer')
                      }}
                      href={link}
                      style={{
                        textDecorationLine: 'underline',
                        textDecorationColor: pallete.colors.textDark2,
                        cursor: 'pointer',
                        padding: '8px 12px',
                        color: pallete.colors.primary2
                      }}
                    >
                      {link}
                    </a>
                  </DDSTypography.Paragraph>
                ))}
              </div>
            </div>
          )}
          {incidentDetails?.activityCount?.length > 0 && (
            <div className='pt-[24px]'>
              <DDSTypography.Title
                type='h5'
                variant='semiBold'
                color={pallete.colors.textDark3}
                className='custom-label'
              >
                Activity
              </DDSTypography.Title>
              <div className='flex flex-col gap-y-[24px] my-[8px]'>
                <div>
                  {activityMessages && Array.isArray(activityMessages)
                    ? activityMessages.map((item, index) => (
                        <>
                          {item.isPublic && (
                            <div
                              className='flex flex-col  justify-between w-full'
                              key={index}
                            >
                              <div className='flex flex-row w-min items-center gap-x-[8px]'>
                                <DDSTypography.Title
                                  type='h5'
                                  variant='bold'
                                  className='whitespace-nowrap'
                                  color={pallete.colors.textDark4}
                                >
                                  {item?.actionedByUser?.firstName}
                                </DDSTypography.Title>
                                <DDSTypography.Paragraph
                                  size='caption'
                                  variant='regular'
                                  style={{ color: pallete.colors.textDark7 }}
                                  className='whitespace-nowrap'
                                >
                                  {getTimeForActivity(item?.createdAt)}
                                </DDSTypography.Paragraph>
                              </div>
                              <DDSTypography.Paragraph
                                size='para'
                                variant='regular'
                                className='pb-[24px]'
                                style={{
                                  color: pallete.colors.textDark5,
                                  overflowWrap: 'anywhere'
                                }}
                              >
                                {item?.message}
                              </DDSTypography.Paragraph>
                            </div>
                          )}
                        </>
                      ))
                    : null}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default IncidentOverView
