import { CheckmarkOutline, UserFollow } from '@carbon/icons-react'
import React, { useCallback, useEffect, useState } from 'react'
import {
  organizationMemberLookUp,
  transferOwnership
} from '../../../../../../api/organization/Organization.service'

import { Button } from '../../../../../../components/New/Button/Button'
import { DDSTypography } from 'den-design-system'
import HttpConstants from '../../../../../../constants/HttpConstants'
import { OrganizationUserRoles } from '../../../../../../enum/OrganizationUserRoles.enum'
import SelectMemberStep from './SelectMemberStep'
import SettingConstants from '../../../../../../constants/SettingConstants'
import TextConfirmationSection from './TextConfirm'
import { ToastMessageConstants } from '../../../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../../../components/common/DDS/Toast/Toast'
import { TransferOwnerPopup } from '../../../SettingPopups/TransferOwnerPopup'
import { addQueryParamsToUrl } from '../../../../../../utils/UrlGenerator'
import { getUserDetails } from '../../../../../../api/user/User.service'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../../../global/newPallete'
import routeConstants from '../../../../../../constants/RouteConstants'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../../../../../store/StoreContext'

interface UserOption {
  id: string
  name: string
  email: string
  position: string
}

interface TransferOwnershipRequest {
  id?: string
  name?: any
  retainMembership?: boolean
}

const TransferOwnerShip: React.FC = () => {
  const [ownershipPopupOpen, setOwnershipPopupOpen] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [transferOwnershipRequest, setTransferOwnershipRequest] =
    useState<TransferOwnershipRequest>({})
  const store = useStoreContext()
  const [loading, setLoading] = useState<boolean>(false)
  const [userOptions, setUserOptions] = useState<UserOption[]>([])
  const [selectedUser, setSelectedUser] = useState<UserOption[] | null>(null)
  const [internalStep, setInternalStep] = useState<number>(0)
  const [openMultiSelect, setOpenMultiSelect] = useState<boolean>(false)
  const [loadOptions, setLoadOptions] = useState<boolean>(false)
  const navigate = useNavigate()

  const setTransferOwnershipRequestData = (data: any) => {
    setTransferOwnershipRequest({ ...transferOwnershipRequest, ...data })
  }

  const steps = [
    { icon: <UserFollow size={20} />, label: 'Select New Owner' },
    {
      icon: <CheckmarkOutline size={20} />,
      label: 'Verify & Transfer Ownership'
    }
  ]

  const contextMessage = transferOwnershipRequest.retainMembership
    ? 'You will be removed as an owner of this organization'
    : 'You will be removed from the organization and will not have any access to this organization'

  const handleNextStep = useCallback(() => {
    if (currentStep === 0) {
      setCurrentStep((prev) => prev + 1)
    } else {
      handleTransferOwnership()
    }
  }, [currentStep])

  const handleTransferOwnership = useCallback(() => {
    setLoading(true)
    transferOwnership({
      organizationMemberId: transferOwnershipRequest.id,
      retainMembership: transferOwnershipRequest.retainMembership
    })
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.ORGANIZATION.TRANSFER_OWNERSHIP.SUCCESS
        })
        store.uiStore.setSidebarItem({})
        navigate(routeConstants.REDIRECT)
      })
      .catch((error: any) => {
        ToastNotification({
          type: 'error',
          message: error
        })
      })
      .finally(() => {
        setLoading(false)
        setOwnershipPopupOpen(false)
        setCurrentStep(0)
      })
  }, [
    transferOwnershipRequest.id,
    transferOwnershipRequest.retainMembership,
    store.uiStore,
    navigate
  ])

  const handleSearch = useCallback(async (value: string) => {
    setLoadOptions(true)
    setUserOptions([])
    if (value.length >= 3) {
      setOpenMultiSelect(true)

      const response: any = await organizationMemberLookUp({
        'name-or-email': value
      })
      const data = response.data
      if (data.length === 0) setOpenMultiSelect(false)
      setUserOptions(
        data
          .filter(({ role }: any) => role !== 'OWNER') // Filter out items where role is 'OWNER'
          .map(({ user, role, id }: any) => ({
            id,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            position: role
          }))
      )
    } else setOpenMultiSelect(false)
    setLoadOptions(false)
  }, [])

  const renderStep = useCallback(() => {
    switch (currentStep) {
      case 0:
        return (
          <SelectMemberStep
            handleNext={handleNextStep}
            setData={setTransferOwnershipRequestData}
            selectedUser={selectedUser}
            handleSearch={handleSearch}
            setSelectedUser={setSelectedUser}
            userOptions={userOptions}
            memberSelectInternalStep={internalStep}
            openMultiSelect={openMultiSelect}
            setOpenMultiSelect={setOpenMultiSelect}
            loadOptions={loadOptions}
            setClose={() => setOwnershipPopupOpen(false)}
          />
        )
      case 1:
        return (
          <TextConfirmationSection
            handleSubmit={handleNextStep}
            context={contextMessage}
            selectedUser={selectedUser}
            buttonText='Transfer Ownership'
            confirmationText={`Transfer Ownership to `}
            loading={loading}
            goBack={() => {
              setCurrentStep(0)
              setInternalStep(0)
            }}
          />
        )
      default:
        return null
    }
  }, [
    currentStep,
    handleNextStep,
    setTransferOwnershipRequestData,
    selectedUser,
    handleSearch,
    userOptions,
    internalStep,
    openMultiSelect,
    loadOptions,
    contextMessage,
    transferOwnershipRequest.name,
    loading
  ])

  return (
    <>
      <div className='flex flex-col'>
        <div>
          <DDSTypography.Paragraph
            size='para'
            variant='regular'
            color={pallete.colors.textDark6}
          >
            {`Transferring your organization's ownership will remove you as an
        owner of this organization. Learn more about `}
            <span
              onClick={() => {
                navigate(routeConstants.ROLES_AND_ACTIONS)
              }}
              style={{ color: pallete.colors.textDark2, cursor: 'pointer' }}
            >
              {SettingConstants.ROLES_ACTION}
            </span>
            .
          </DDSTypography.Paragraph>
          <DDSTypography.Paragraph
            size='para'
            variant='regular'
            color={pallete.colors.textDark6}
          >
            {SettingConstants.NOTE}
          </DDSTypography.Paragraph>
        </div>
        <div className='mt-[20px]'>
          <Button
            id='add-slack'
            type='filled'
            startIcon={<UserFollow size={16} color={pallete.colors.white} />}
            size='small'
            label='Transfer Ownership'
            disabled={store.userStore.getNoActiveSubscription() || loading}
            onClick={() => {
              setOwnershipPopupOpen(true)
            }}
          />
        </div>
        <TransferOwnerPopup
          showHeader
          title='Transfer Ownership'
          modalOpen={ownershipPopupOpen}
          setModalOpen={setOwnershipPopupOpen}
          content={
            <div className='flex flex-col px-[24px] pt-[24px]'>
              <div>
                <div className='flex items-center justify-center pb-[32px]'>
                  {steps.map((step, index) => (
                    <div key={index} className='flex items-center'>
                      <span
                        className='mr-[6px]'
                        style={{
                          color:
                            currentStep === index
                              ? pallete.colors.textDark2
                              : pallete.colors.textDark7
                        }}
                      >
                        {step.icon}
                      </span>
                      <span
                        style={{
                          color:
                            currentStep === index
                              ? pallete.colors.textDark2
                              : pallete.colors.textDark7,
                          fontSize: '16px',
                          fontWeight: 600,
                          lineHeight: '18px'
                        }}
                      >
                        {step.label}
                      </span>

                      {index < steps.length - 1 && (
                        <span
                          style={{ color: pallete.colors.textDark7 }}
                          className='mx-[12px]'
                        >
                          ----------
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              {renderStep()}
            </div>
          }
        />
      </div>
    </>
  )
}

export default observer(TransferOwnerShip)
