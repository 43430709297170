import { makeAutoObservable, observable } from 'mobx'

import { RootStore } from './RootStore'

export interface Environment {
  [key: string]: string | boolean
}

export class EnvironmentStore {
  environments: Environment[]
  loading: boolean
  totalCount: number
  currentPage: number
  constructor(private rootStore: RootStore) {
    this.environments = []
    this.loading = false
    this.totalCount = 0
    this.currentPage = 1

    makeAutoObservable(this, {
      environments: observable.struct,
      loading: observable.struct,
      totalCount: observable.struct,
      currentPage: observable.struct
    })
  }

  setEnvironments(environments: Environment[]) {
    this.environments = environments
  }

  getEnvironments() {
    return this.rootStore.environmentStore.environments
  }

  setLoading(loading: boolean) {
    this.loading = loading
  }

  getLoading(): boolean {
    return this.rootStore.environmentStore.loading
  }

  setTotalCount(totalCount: number) {
    this.totalCount = totalCount
  }

  getTotalCount(): number {
    return this.rootStore.environmentStore.totalCount
  }

  getCurrentPage(): number {
    return this.rootStore.environmentStore.currentPage
  }

  setCurrentPage(currentPage: number) {
    this.currentPage = currentPage
  }

  reset() {
    this.environments = []
    this.loading = false
    this.totalCount = 0
    this.currentPage = 1
  }
}
