import Joi, { ValidationErrorItem } from 'joi'
import React, { useState } from 'react'
import {
  checkInstanceAvailability,
  makeApiCallToInstance
} from '../../api/salesforceAudit/salesforceAudit.service'

import Button from '../common/Button/Button'
import CreateIcon from '../../assets/icons/createIcon.svg'
import CreateIconBlue from '../../assets/icons/createIconBlue.svg'
import InputField from '../common/InputField/InputField'
import Popup from '../common/Popup/Popup'
import RegexConstants from '../../constants/RegexConstants'
import StringConstants from '../../constants/StringConstants'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../common/DDS/Toast/Toast'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import { UrlForSalesforceConnect } from '../../utils/UrlUtils'
import ValidationConstants from '../../constants/ValidationConstants'
import breakpoints from '../../global/breakpoints'
import { getValidationStatus } from '../../utils/ValidationUtils'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { useStoreContext } from '../../store/StoreContext'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.9rem;
  }
`

const InstanceUrlPopup: React.FC<{
  handleClose: () => void
  open: boolean
}> = ({ handleClose, open }) => {
  const [validationErrorItems, setValidationErrorItems] = useState<
    ValidationErrorItem[]
  >([])
  const [formState, setFormState] = useState<string>('')
  const [isInvalidUrl, setIsInvalidUrl] = useState<boolean>(false)
  const [isInstanceAvailable, setIsInstanceAvailable] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const schema = Joi.object({
    url: Joi.string()
      .pattern(RegexConstants.SALESFORCE_INSTANCE_URL_REGEX)
      .required()
  })
  const store = useStoreContext()
  return (
    <Popup
      open={open}
      loading={loading}
      handleClose={() => {
        setFormState('')
        setValidationErrorItems([])
        setIsInvalidUrl(false)
        setIsInstanceAvailable(true)
        handleClose()
      }}
      headerText='Connect Organization'
      headerIcon={<img src={CreateIconBlue} />}
      height='auto'
    >
      <Container>
        <TypographyDDS.Paragraph
          size='para'
          color='black'
          style={{ textAlign: 'center', paddingTop: '25px' }}
        >
          Please connect and authorize using System Administrator Account of
          Salesforce
        </TypographyDDS.Paragraph>
        <InputField
          label='Salesforce Instance URL'
          placeholder=''
          width='460px'
          margin='10px 0 0 0'
          onChange={(e) => setFormState(e.target.value)}
          value={formState}
          error={
            getValidationStatus(validationErrorItems, 'url') ||
            isInvalidUrl ||
            !isInstanceAvailable
          }
          helperText={
            getValidationStatus(validationErrorItems, 'url') || isInvalidUrl
              ? ValidationConstants.CONNECT_SALESFORCE_ORGANIZATION.INVALID_URL
              : !isInstanceAvailable
                ? ValidationConstants.CONNECT_SALESFORCE_ORGANIZATION
                    .INSTANCE_UN_AVAILABLE
                : ''
          }
        />

        <Button
          variant='contained'
          margin='12px 0 0 0'
          startIcon={<img src={CreateIcon} />}
          onClick={async () => {
            setLoading(true)
            const validationResult = schema.validate(
              { url: formState.trim() },
              {
                abortEarly: false
              }
            )
            if (validationResult.error)
              setValidationErrorItems(validationResult.error.details)
            else {
              const res = await makeApiCallToInstance(formState).catch(() => {
                console.log('invalid url')
              })
              if (res) {
                setIsInvalidUrl(false)
                await checkInstanceAvailability(formState.trim())
                  .then(() => {
                    setIsInstanceAvailable(true)
                    window.location.href = UrlForSalesforceConnect(
                      formState,
                      store.scopeStore.getScope()['organization-id'] as string,
                      store.scopeStore.getScope()['project-id'] as string,
                      store.scopeStore.getScope()['environment-id'] as string
                    )
                  })
                  .catch((error: any) => {
                    if (error === StringConstants.INSTANCE_ALREADY_CONNECTED) {
                      setIsInstanceAvailable(false)
                    } else {
                      ToastNotification({
                        type: 'error',
                        message: ToastMessageConstants.SOMETHING_WENT_WRONG
                      })
                    }
                    setIsInstanceAvailable(false)
                  })
              } else {
                setIsInvalidUrl(true)
              }
              setValidationErrorItems([])
            }
            setLoading(false)
          }}
        >
          Connect
        </Button>
      </Container>
    </Popup>
  )
}

export default observer(InstanceUrlPopup)
