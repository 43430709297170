import { Button } from '../../New/Button/Button'
import { DDSTypography } from 'den-design-system'
import JMStringConstants from '../../../constants/JMStringConstants'
import Modal from '../../common/DDS/Modal/Modal'
import React from 'react'
import { TrashCan } from '@carbon/icons-react'
import pallete from '../../../global/newPallete'
import { useStoreContext } from '../../../store/StoreContext'

interface deleteProps {
  open: boolean
  setOpen: any
  buttonLoading: boolean
  onCancel: () => void
  onDelete: () => void
}
const JMDeletePopup: React.FC<deleteProps> = ({
  open,
  setOpen,
  buttonLoading,
  onCancel,
  onDelete
}) => {
  const DeleteJobFooter: React.FC = () => {
    const store = useStoreContext()
    return (
      <div className='flex override-btn-min-width justify-end px-[24px] py-[16px] gap-[10px]'>
        <Button
          id='no-modal-btn'
          type='neutral'
          disabled={buttonLoading}
          label={JMStringConstants.NO_BUTTON}
          onClick={onCancel}
        />
        <Button
          id='yes-modal-btn'
          type='dangerFilled'
          disabled={buttonLoading || store.userStore.getNoActiveSubscription()}
          loading={buttonLoading}
          label={JMStringConstants.YES_BUTTON}
          onClick={onDelete}
        />
      </div>
    )
  }
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      showHeader
      title={JMStringConstants.DELETE_JOB_TITLE}
      className='w-[550px]'
      footer={<DeleteJobFooter />}
      onClosePress={() => null}
    >
      <div className='flex flex-col p-[24px] items-center justify-center'>
        <div className='flex justify-center items-center gap-[4px]'>
          <DDSTypography.Title
            type='h4'
            variant='semiBold'
            className=''
            color={pallete.colors.textDark3}
          >
            {JMStringConstants.DELETE_JOB_DESC_TEXT}
          </DDSTypography.Title>
        </div>
      </div>
    </Modal>
  )
}

export default JMDeletePopup
