import { isEmpty, isString } from 'lodash'

export const getLowerCase = (text: string) =>
  isNaN(+text) ? text.toLowerCase() : text.toString()

export const isJSON = (string: string) => {
  try {
    JSON.parse(string)
  } catch (e) {
    return false
  }
  return true
}

export const convertToSlug = (string: string) => {
  if (!isEmpty(string) && isString(string))
    return string
      .toLowerCase()
      .replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-')
      .replace('_', '-')
  else return ''
}
