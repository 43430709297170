import {
  debouncedAHCSearchContextsAPI,
  getHealthCheckReports,
  getHealthReportDetails
} from '../api/appHealthCheck/AHC.service'
import { makeAutoObservable, observable } from 'mobx'

import { FilterTypes } from '../enum/Filter.enum'
import { Report } from '../types/store.types'
import { RootStore } from './RootStore'
import StringConstants from '../constants/StringConstants'
import { cloneDeep } from 'lodash'
import { constructContextsFromQuery } from '../utils/CommonUtils'
import { getFiltersForApi } from '../utils/FilterUtils'
import moment from 'moment'

export interface Data {
  data: Report[]
  filters: Record<string, string>
  totalCount: number
}
const searchContextsLookup: Record<
  string,
  {
    context: string
    label: string
    query: string
  }
> = {
  name: {
    context: 'name',
    label: 'Check Name',
    query: 'name-like'
  },
  url: {
    context: 'url',
    label: 'Check URL',
    query: 'url-like'
  }
}

const SearchContextMap = {
  'url-like': 'url',
  'name-like': 'name'
}
export class AhcReportsStore {
  reports: Report[]
  activeReport: null | Report
  count: number
  filters: object
  page: number
  size: number
  loading: boolean
  error: string | null
  dateFilter: {
    'created-from': string
    'created-to': string
  } | null
  statusFilter: {
    'previous-check-status': string
  } | null
  performanceGraphKey: number

  searchContexts: string[]
  searchContextsLoading: boolean
  searchQuery: string
  selectedContexts: any[]
  constructor(private rootStore: RootStore) {
    this.reports = []
    this.activeReport = null
    this.count = 0
    this.page = 1
    this.size = 5
    this.filters = {}
    this.loading = false
    this.statusFilter = null
    this.error = null
    this.performanceGraphKey = 0
    this.searchContexts = []
    this.searchContextsLoading = false
    this.searchQuery = ''
    this.dateFilter = null
    this.selectedContexts = []
    makeAutoObservable(this, {
      reports: observable.struct,
      selectedContexts: observable.struct
    })
  }
  getPerformanceGraphKey() {
    return this.performanceGraphKey
  }

  setData({ data, filters, totalCount }: Data) {
    this.rootStore.ahcReportsStore.reports = cloneDeep(data)
    this.rootStore.ahcReportsStore.filters = filters
    this.rootStore.ahcReportsStore.count = totalCount
  }

  getReports() {
    return this.rootStore.ahcReportsStore.reports
  }

  setCount(count: number) {
    this.rootStore.ahcReportsStore.count = count
  }
  getCount() {
    return this.rootStore.ahcReportsStore.count
  }
  getFilters() {
    return this.rootStore.ahcReportsStore.filters
  }
  setActiveReport(report: Report | null) {
    this.rootStore.ahcReportsStore.activeReport = report
    this.rootStore.ahcReportsStore.performanceGraphKey += 1
  }
  getActiveReport() {
    return this.rootStore.ahcReportsStore.activeReport
  }
  setPage(page: number) {
    this.rootStore.ahcReportsStore.page = page
  }
  getPage() {
    return this.rootStore.ahcReportsStore.page
  }
  setLoading(loading: boolean) {
    this.rootStore.ahcReportsStore.loading = loading
  }
  getLoading() {
    return this.rootStore.ahcReportsStore.loading
  }
  setDateFilter(
    dateRange: { 'created-from': string; 'created-to': string } | null
  ) {
    this.rootStore.ahcReportsStore.dateFilter = dateRange
      ? { ...dateRange }
      : dateRange
  }
  getDateFilter() {
    return this.rootStore.ahcReportsStore.dateFilter
  }
  setStatusFilter(statusFilter: { 'previous-check-status': string } | null) {
    this.rootStore.ahcReportsStore.statusFilter = statusFilter
      ? { ...statusFilter }
      : statusFilter
  }
  getStatusFilter() {
    return this.rootStore.ahcReportsStore.statusFilter
  }
  setError(error: string | null) {
    this.rootStore.ahcReportsStore.error = error
  }
  getError() {
    return this.rootStore.ahcReportsStore.error
  }
  async getReportsFromApi(filters?: any, page = StringConstants.DEFAULT_PAGE) {
    const apiFilters = getFiltersForApi(FilterTypes.AHC, filters)
    this.setSearchContexts([])

    this.setLoading(true)
    this.rootStore.uiStore.setGlobalLoader(true)
    try {
      const response = (await getHealthCheckReports({
        page,
        size: this.size,
        ...apiFilters
      })) as Data
      if (
        response.data.length > 0 ||
        this.rootStore.filterStore.getFiltersApplied()
      ) {
        this.rootStore.filterStore.setFilterDisabled(false)
      }
      this.setData(response)
      if (
        response.data.length !== 0 &&
        window.location.pathname.split('/').slice(-1)[0] === 'dashboard'
      ) {
        if (
          response.filters['created-to'] &&
          moment(response.filters['created-to']).isAfter(new Date())
        ) {
          const currentDay = new Date()
          currentDay.setSeconds(0)
          response.filters['created-to'] = currentDay.toISOString()
        }

        const contexts: any[] = constructContextsFromQuery(
          response.filters,
          searchContextsLookup,
          SearchContextMap
        )
        this.setSelectedContexts(contexts)

        this.rootStore.filterStore.setFilters(response.filters)
      }
      const contexts: any[] = constructContextsFromQuery(
        response.filters,
        searchContextsLookup,
        SearchContextMap
      )
      this.setSelectedContexts(contexts)
      this.setError(null)
    } catch (e) {
      console.log('Error', e)
      throw e
    } finally {
      this.setLoading(false)
      this.rootStore.uiStore.setGlobalLoader(false)
    }
  }

  async getReportDetailsFromAPI($ahcId: string, queryParams?: any) {
    const filters = getFiltersForApi(FilterTypes.AHC, queryParams)
    this.setLoading(true)
    this.rootStore.uiStore.setGlobalLoader(true)
    this.setActiveReport(null)
    try {
      const response = (await getHealthReportDetails({
        $ahcId,
        ...filters
      })) as any
      this.setActiveReport(response.data)
    } finally {
      this.setLoading(false)
      this.rootStore.uiStore.setGlobalLoader(false)
    }
  }

  async getSearchContextsFromAPI(query: string, queryParams: any) {
    const filters = getFiltersForApi(FilterTypes.AHC, queryParams)
    this.setSearchContexts([])
    if (query.trim().length < 3) return
    this.setSearchContextsLoading(true)
    try {
      const { data } = await debouncedAHCSearchContextsAPI(query, filters)
      this.setSearchContexts(data)
      this.rootStore.ahcReportsStore.searchQuery = query
    } catch (e) {
      console.log(e)
    } finally {
      this.setSearchContextsLoading(false)
    }
  }

  getSearchQuery() {
    return this.rootStore.ahcReportsStore.searchQuery
  }
  setSearchContexts(contexts: string[]) {
    this.rootStore.ahcReportsStore.searchContexts = contexts
  }
  getSearchContexts() {
    return this.rootStore.ahcReportsStore.searchContexts.map((context) => ({
      id: searchContextsLookup[context].query,
      label: `${searchContextsLookup[context].label} : ${this.getSearchQuery()}`
    }))
  }
  setSearchContextsLoading(loading: boolean) {
    this.rootStore.ahcReportsStore.searchContextsLoading = loading
  }
  getSearchContextsLoading() {
    return this.rootStore.ahcReportsStore.searchContextsLoading
  }
  setSelectedContexts(contexts: any[]) {
    this.rootStore.ahcReportsStore.selectedContexts = contexts
  }

  getSelectedContexts() {
    return this.rootStore.ahcReportsStore.selectedContexts
  }
  reset() {
    this.reports = []
    this.activeReport = null
    this.count = 0
    this.page = 1
    this.size = 5
    this.filters = {}
    this.loading = false
    this.statusFilter = null
    this.error = null
  }
}
