export enum OrganizationType {
  SALESFORCE_ECOSYSTEM_ONLY = 'SALESFORCE_ECOSYSTEM_ONLY',
  FULL_STACK_ONLY = 'FULL_STACK_ONLY',
  HYBRID = 'HYBRID'
}

export enum OrganizationTypeDisplayNames {
  SALESFORCE_ECOSYSTEM_ONLY = 'Salesforce orgs + Mulesoft apps',
  FULL_STACK_ONLY = 'Full-stack apps only',
  HYBRID = 'Salesforce, Mulesoft and Full-stack apps'
}
