import { IoIosArrowForward } from 'react-icons/io'
import React from 'react'
import { TypographyDDS } from '../../common/Typography/TypographyDDS'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3em;
`
const IconWrapper = styled.div`
  padding-top: 7px;
`
const SkipStep: React.FC = () => {
  return (
    <Container id={'skip-step-component'}>
      <TypographyDDS.Paragraph size='para' variant='medium'>
        Skip this step
      </TypographyDDS.Paragraph>
      <IconWrapper>
        <IoIosArrowForward
          style={{
            color: palette.colors.primary
          }}
        />
      </IconWrapper>
    </Container>
  )
}

export default SkipStep
