import React from 'react'
import UCAddUpdateCheckForm from '../../../components/UptimeChecksPage/UCAddCheckForm'

const UCUpdateCheck = () => {
  return (
    <div className='flex flex-col h-[100vh] items-start self-stretch'>
      <UCAddUpdateCheckForm isAdd={false} />
    </div>
  )
}

export default UCUpdateCheck
