import React, { useState } from 'react'

import { Button } from '../../../../../../components/New/Button/Button'
import { DDSTypography } from 'den-design-system'
import Input from '../../../../../../components/New/Input/Input'
import SettingConstants from '../../../../../../constants/SettingConstants'
import pallete from '../../../../../../global/newPallete'
import { useStoreContext } from '../../../../../../store/StoreContext'

interface TextConfirmationSectionProps {
  confirmationText: string
  context: string
  selectedUser: any
  handleSubmit: () => void
  buttonText?: string
  loading: boolean
  goBack: () => void
}

const TextConfirmationSection: React.FC<TextConfirmationSectionProps> = ({
  confirmationText,
  selectedUser,
  context,
  handleSubmit,
  buttonText = 'Submit',
  loading,
  goBack
}) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [disableButton, setDisableButton] = useState<boolean>(false)
  const [validationError, setValidationError] = useState<string | null>(null)
  const store = useStoreContext()
  return (
    <>
      <div className='mb-[16px] italic'>
        <DDSTypography.Title
          type='h3'
          variant='semiBold'
          color={pallete.colors.textDark3}
        >
          To transfer ownership and remove yourself from the organization, type{' '}
          <span
            style={{
              color: pallete.colors.textDark3,
              fontSize: '18px',
              fontWeight: 700,
              lineHeight: '28px',
              fontStyle: 'italic',
              transform: 'skew(-10deg)',
              display: 'inline-block'
            }}
          >
            {`"${confirmationText} ${selectedUser?.name}"`}
          </span>{' '}
          to proceed
        </DDSTypography.Title>
      </div>

      <div className='mb-4'>
        <Input
          width='100%'
          onChange={(e) => {
            setInputValue(e.target.value)
          }}
          disabled={loading}
          required={true}
          labelPosition='top'
          validate={(value: string) => {
            if (inputValue === confirmationText + selectedUser?.name) {
              setValidationError(null)
              setDisableButton(false)
              return { error: false, message: '' }
            } else {
              const errorMessage = 'The confirmation text does not match.'
              setValidationError(errorMessage)
              setDisableButton(true)
              return { error: true, message: errorMessage }
            }
          }}
          validateInput={true}
          label={
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark3}
            >
              {SettingConstants.TEXT_CONFIRMATION}
            </DDSTypography.Paragraph>
          }
          id='input'
          name='Input'
          placeholder='Type here'
        />
      </div>
      <DDSTypography.Paragraph
        size='para'
        variant='semiBold'
        color={pallete.colors.textDark6}
      >
        Note : You will be removed from the organization and will not have any
        access to this organization
      </DDSTypography.Paragraph>
      <hr className='w-full mt-[24px] pb-[16px] border-t border-gray-300' />
      <div className='flex gap-[10px] justify-end pb-[16px]'>
        <Button
          id='cancel-transfer'
          type='neutral'
          width='120px'
          size='small'
          label='Back'
          onClick={goBack}
          disabled={loading || store.uiStore.getGlobalLoader()}
        />
        <Button
          id='next-transfer'
          type='filled'
          width='120px'
          size='small'
          loading={loading}
          label='Next'
          disabled={
            disableButton ||
            loading ||
            inputValue !== confirmationText + selectedUser?.name ||
            store.uiStore.getGlobalLoader()
          }
          onClick={handleSubmit}
        />
      </div>
    </>
  )
}

export default TextConfirmationSection
