import React, { useEffect, useState } from 'react'
import { Router, useNavigate, useSearchParams } from 'react-router-dom'

import CryptoJS from 'crypto-js'
import HttpConstants from '../../constants/HttpConstants'
import PartnerInvitePopUp from '../../components/InvitePopup/partnerInvitePopUp'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../../components/common/DDS/Toast/Toast'
import { addQueryParamsToUrl } from '../../utils/UrlGenerator'
import { findUserByMail } from '../../api/user/User.service'
import routeConstants from '../../constants/RouteConstants'
import { validatePartner } from '../../api/partner/Partner.service'

const PartnerRedirect: React.FC = () => {
  const navigate = useNavigate()
  const [organizationName, setOrganizationName] = useState()
  const [email, setEmail] = useState()
  const [searchParams, setSearchParams] = useSearchParams()
  const [partnerPopUp, setPartnerPopUp] = useState<boolean>(false)
  const token: string = searchParams.get('token') as string
  useEffect(() => {
    validatePartner({ $token: token })
      .then((response: any) => {
        setOrganizationName(response.data.organizationName)
        setEmail(response.data.email)

        findUserByMail(response.data.email)
          .then(() => setPartnerPopUp(true))
          .catch((err: any) => {
            if (err.exceptionName === 'RecordNotFoundException') {
              const data = CryptoJS.AES.encrypt(
                JSON.stringify({
                  organizationName: response.data.organizationName,
                  firstName: response.data.firstName,
                  lastName: response.data.lastName,
                  email: response.data.email
                }),
                process.env.REACT_APP_ENCRYPTION_KEY
                  ? process.env.REACT_APP_ENCRYPTION_KEY
                  : 'secret_key'
              ).toString()

              navigate(
                addQueryParamsToUrl(
                  routeConstants.SIGN_UP,

                  {
                    token: token,
                    organizationIsPartner: 'true',
                    partner: data
                  },
                  HttpConstants.GET_METHOD
                )
              )
            }
          })
      })
      .catch((err: any) => {
        if (err.exceptionName === 'RecordNotFoundException') {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.PARTNER.PARTNER_ORGANIZATION.ERROR
          })
          navigate(routeConstants.SIGN_UP)
        }
      })
  }, [])
  return (
    <PartnerInvitePopUp
      open={partnerPopUp}
      handleClose={() => setPartnerPopUp(!partnerPopUp)}
      organization={organizationName ? organizationName : ''}
      email={email ? email : ''}
      token={token}
    />
  )
}

export default PartnerRedirect
