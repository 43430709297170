const ValidationConstants = {
  PASSWORD: {
    PASSWORDS_MISMATCH: 'Passwords did not match',
    INVALID_PASSWORD_REGEX:
      'Use minimum 8 characters, upper & lower case, 1 number, 1 special character',
    SAME_OLD_PASSWORD:
      'Enter a new password which is different from the already existing one.'
  },
  ENVIRONMENT: {
    ENVIRONMENT_NAME: 'Please enter a valid environment name',
    ENVIRONMENT_TYPE: 'Please select a valid environment type',
    ENVIRONMENT_NAME_NOT_AVAILABLE:
      'Environment name is already taken. Please enter a different name'
  },
  DELETE_CONFIRMATION: {
    CONFIRMATION_TEXT: 'Confirmation texts do not match',
    CONFIRMATION_TEXT_EMPTY: 'Confirmation text cannot be empty'
  },
  PROJECT: {
    PROJECT_NAME: 'Please enter a valid project name',
    PROJECT_NAME_NOT_AVAILABLE:
      'Project name is already taken. Please enter a different name',
    PROJECT_TYPE_EMPTY: 'Project type should not be empty',
    PROJECT_NAME_CONSECUTIVE_SPACE: 'Please avoid using consecutive spaces.'
  },
  CREATE_JOB: {
    JOB_NAME: 'Job name should not be empty',
    JOB_SLUG: 'Job slug should not be empty',
    JOB_NAME_INVALID:
      'Use atleast one alphabet and numbers, spaces, _, -, ., #',
    JOB_SLUG_INVALID: 'Use only lowercase alphabets, numbers, - (hyphen)',
    CRON_EXPRESSION: 'Please enter a valid cron expression',
    DESCRIPTION: 'Please enter a valid description',
    JOB_NAME_NOT_AVAILABLE: 'Job name is already taken',
    JOB_SLUG_NOT_AVAILABLE: 'Job slug is already taken'
  },
  INCIDENT: {
    INCIDENT_LINK: 'Link should not be empty'
  },
  INVITE_MEMBER: {
    MEMBER_EMAIL: 'Member email should not be empty',
    MEMBER_ROLE: 'Member role should not be empty',
    VALID_EMAIL: 'Please enter a valid email'
  },
  SIGN_UP: {
    FIRST_NAME: 'First name cannot be empty',
    LAST_NAME: 'Last name cannot be empty',
    EMAIL: 'Email cannot be empty',
    PASSWORD: 'Password cannot be empty',
    ORGANIZATION_NAME: 'Organization cannot be empty',
    ORGANIZATION_TYPE: 'Please select an option',
    VALID_EMAIL: 'Please enter a valid email'
  },
  ORGANIZATION: {
    ORGANIZATION_NAME:
      'Organization name should not be empty and should not have any special characters other than _, -, ., #',
    ORGANIZATION_NAME_ERROR:
      'Organization name is already taken. Please enter a different name',
    ORGANIZATION_NAME_EMPTY: 'Organization name should not be empty',
    ORGANIZATION_NAME_INVALID:
      'Organization name should not have any special characters other than _, -, ., #',
    ORGANIZATION_TYPE_EMPTY: 'Organization type should not be empty'
  },
  UPDATE_EMAIL: {
    EMAIL: 'Enter Valid Email'
  },
  NO_MEMBERS_ADDED: {
    PROJECT_MEMBERS: 'Add at least one project member'
  },
  NO_ROLE_ADDED: 'Please specify the role',
  TRANSFER_OWNERSHIP: {
    ORGANIZATION_MEMBER: 'This user is not part of the organization'
  },
  CREATE_CHECK: {
    CHECK_NAME: 'Please enter a valid check name',
    URL: 'Please enter a valid url',
    ELEMENT_ID: 'Please enter element Id'
  },
  APP_HEALTH_CHECK: {
    APP_HEALTH_CHECK_NAME: 'Please enter a valid check name',
    APP_HEALTH_CHECK_NAME_NOT_AVAILABLE: 'Application name is already taken'
  },
  UPDATE_ESCALATION_RULE: {
    UNIQUE_RECIPIENT_EMAIL: 'Escalation rules cannot have duplicate emails'
  },
  GENERIC: {
    REQUIRED_VALUE: 'This field is required',
    INVALID_NAME_REGEX: 'Use only letters, numbers, spaces, _ , - , or .',
    MIN_LENGTH: 'This field needs to be at least {length} characters long',
    INVALID_URL: 'Invalid URL',
    INVALID_EMAIL: 'Invalid email'
  },
  SETTINGS: {
    INVALID_HOST: 'Please enter a valid host'
  },

  INVALID_CAPTCHA: 'Captcha verification failed, try again!',
  CONNECT_SALESFORCE_ORGANIZATION: {
    INVALID_URL: 'Please enter a valid URL',
    INSTANCE_UN_AVAILABLE:
      'The instance is already connected. Please connect to a different instance'
  }
}

export default ValidationConstants
