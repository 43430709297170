import * as React from 'react'

import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { TypographyDDS } from '../Typography/TypographyDDS'
import { css } from '@emotion/react'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

const Divider = styled.div<{ lastRow?: boolean }>`
  ${({ lastRow }) =>
    !lastRow
      ? css`
          border-bottom: 1px solid ${palette.colors.borderColor};
          width: 17ch;
          align-items: center;
          margin-left: auto;
          margin-right: auto;
        `
      : 'none'}
`

const exceptionsOptions = [
  {
    id: 0,
    name: 'Refresh'
  },
  {
    id: 1,
    name: 'Export to CSV'
  }
]

const options = [
  {
    id: 0,
    name: 'Refresh'
  }
]
const ITEM_HEIGHT = 48

const MenuDropdown: React.FC<{
  exceptionsPresent?: boolean
  exceptions?: boolean
  loading?: boolean
  actions?: (() => void)[]
  disabled?: boolean
}> = ({ exceptions, actions, exceptionsPresent, loading, disabled }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const StyledMenu = styled(Menu)(() => ({
    '.MuiMenu-paper	': {
      borderRadius: '0.5em',
      border: `1.5px solid ${palette.colors.borderColor}`,
      boxShadow: 'none'
    },
    '.MuiList-root': {
      paddingTop: '0',
      paddingBottom: '0'
    }
  }))

  const StyledMenuItem = styled(MenuItem)<{ lastRow?: boolean }>(() => ({
    '&.MuiMenuItem-root': {
      padding: '15px'
    },
    '&.hover': {
      backgroundColor: `${palette.colors.borderColor}`
    }
  }))

  return (
    <div>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        disabled={loading || disabled}
      >
        <MoreVertIcon style={{ height: '24px', width: '24px' }} />
      </IconButton>
      <StyledMenu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch'
          }
        }}
      >
        {exceptions
          ? exceptionsOptions.map((option: any) => (
              <>
                <StyledMenuItem
                  key={option.id}
                  selected={option.name === ''}
                  disabled={option.id === 1 && !exceptionsPresent}
                  onClick={(e) => {
                    handleClose()
                    actions && actions[option.id]()
                  }}
                >
                  <TypographyDDS.Paragraph
                    size='para'
                    variant='semiBold'
                    color='textDark'
                  >
                    {option.name}
                  </TypographyDDS.Paragraph>
                </StyledMenuItem>
                <Divider lastRow={option.id == 1 ? true : false} />
              </>
            ))
          : options.map((option: any) => (
              <>
                <StyledMenuItem
                  key={option.id}
                  selected={option.name === ''}
                  onClick={(e) => {
                    handleClose()
                    actions && actions[option.id]()
                  }}
                >
                  <TypographyDDS.Paragraph
                    size='para'
                    variant='semiBold'
                    color='textDark'
                  >
                    {option.name}
                  </TypographyDDS.Paragraph>
                </StyledMenuItem>
                <Divider lastRow={option.id == 1 ? true : false} />
              </>
            ))}
      </StyledMenu>
    </div>
  )
}

export default MenuDropdown
