import { Autocomplete, Paper, TextField, Tooltip } from '@mui/material'
import { IoIosArrowDown, IoIosClose } from 'react-icons/io'
import React, { createRef, useEffect, useImperativeHandle } from 'react'
import { isArray, isEmpty } from 'lodash'

import Chip from '../Chip/Chip'
import LinearProgressBar from '../ProgressBar/ProgressBar'
import { RefObject } from '../InputField/Interface/Interface'
import Spinner from '../Spinner/Spinner'
import { TextContentConstants } from '../../../constants/TextContentConstants'
import Typography from '../Typography/Typography'
import { TypographyDDS } from '../Typography/TypographyDDS'
import { observer } from 'mobx-react-lite'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'
import { useStoreContext } from '../../../store/StoreContext'

interface MultiSelectProps {
  optionsLabelAccessor: (option: any) => string
  options: any
  onChange?: (value: any) => void
  value: any
  onDeleteSelection?: (option: any) => void
  fullWidth?: boolean
  renderOptions?: (options: any) => JSX.Element
  placeholder?: string
  label?: string
  onTextChange?: (value: string) => void
  validationError?: false | string
  singleSelect?: boolean
  disableClearable?: boolean
  variant?: 'normal' | 'small'
  loading?: boolean
  blurOnSelect?: boolean
  openDropdown: boolean
  setOpenDropdown: (data: any) => void
  loadingOption?: boolean
  width?: string
  backgroundColor?: string
  hoverBackgroundColor?: string
  disableDelete?: boolean
  disabled?: boolean
  clearOnBlur?: boolean
  id?: string
}

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop: string) => !prop.startsWith('$')
})(({ hoverBackground, backgroundColorForTextField, ...rest }: any) => ({
  '.MuiFilledInput-input': {
    paddingTop: '1.6em',
    paddingBottom: '0.625em',
    marginTop: 'auto'
  },
  '.MuiFilledInput-root': {
    borderRadius: '8px',
    border: `1px solid ${palette.colors.borderColor}`,
    background: backgroundColorForTextField,
    '&.Mui-focused': {
      // border: `2px solid ${palette.colors.primary3} `,
      background: hoverBackground
    },
    '&:hover': {
      background: hoverBackground
    },
    paddingBottom: '8px',
    paddingTop: '8px'
  }
}))

const StyledAutoComplete = styled(Autocomplete)((props: any) => ({
  '& input::placeholder': {
    fontSize: '12px'
  },
  '&.MuiFilledInput-input': {
    paddingTop: '1.6em'
  },
  '&.MuiAutocomplete-root': {
    border: `0px`
  },

  '.MuiFilledInput-root': {
    minHeight: '61.5px',

    ':before': {
      border: '0px'
    },
    '&:hover': {
      ':before': {
        border: '0px'
      }
    }
  },
  '.MuiInputLabel-root': {
    fontSize: '12px',
    fontWeight: palette.fontWeight.medium,
    lineHeight: '16px',
    color: `${palette.colors.textDark2}`,
    '&.Mui-error': {
      color: `${palette.colors.error} !important`
    }
  },
  '.MuiAutocomplete-endAdornment': {
    pointerEvents:
      props.options.length == 0 || props.isCurrentUserOption ? 'none' : 'all',
    opacity: props.options.length == 0 || props.isCurrentUserOption ? 0.4 : 1
  },
  '.MuiAutocomplete-clearIndicator': {
    pointerEvents: 'all'
  }
}))

const OptionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const OptionListWrapper = styled.li`
  margin-top: 0.5em;
  &:hover {
    cursor: pointer;
    background: ${palette.colors.borderColor} !important;
  }
  border-radius: 8px;
`
const MultiSelectWrapper = styled.div<{ disabled?: boolean }>`
  ${(props) => props.disabled && 'pointer-events: none;  opacity:0.4;'}
  width:100%;
`

const CustomPaper = (props: any, width: string) => {
  return (
    <Paper
      elevation={0}
      {...props}
      anchororigin={{ horizontal: 'left' }}
      sx={{
        bgcolor: palette.colors.white,
        border: `1px solid ${palette.colors.borderColor}`,
        padding: '0.5em',
        width,
        left: '0',
        alignSelf: 'flex-start',
        marginTop: '6px',

        maxHeight: '30vh',
        overflow: 'scroll',
        '.MuiAutocomplete-listbox': {
          overflow: 'unset',
          maxHeight: 'none',
          padding: '0 0 8px 0'
        }
      }}
    />
  )
}

const MultiSelect = React.forwardRef<RefObject, MultiSelectProps>(
  (
    {
      optionsLabelAccessor,
      renderOptions = () => null,
      options,
      onChange = () => null,
      value,
      onDeleteSelection = () => null,
      fullWidth = true,
      placeholder = '',
      label = '',
      onTextChange = () => null,
      validationError,
      singleSelect = false,
      disableClearable = false,
      variant = 'normal',
      loading,
      blurOnSelect = false,
      openDropdown = true,
      setOpenDropdown,
      loadingOption = false,
      width = '100%',
      backgroundColor = palette.colors.lightBack,
      hoverBackgroundColor = palette.colors.backgroundLight,
      disableDelete = false,
      disabled = false,
      clearOnBlur = false,
      id = ''
    },
    ref
  ) => {
    const store = useStoreContext()
    const getPlaceholder = () => {
      if (value) {
        if (value.length === 0) return placeholder
        return ''
      }
      return placeholder
    }
    const [isCurrentUserOption, setIsCurrentUserOption] = React.useState(false)
    const [loadingMultiSelect, setLoadingMultiSelect] =
      React.useState<boolean>(false)
    const inputRef = createRef<any>()
    const [ellipsisWidth, setEllipsisWidth] = React.useState<number>(0)
    const [textWidth, setTextWidth] = React.useState<number>(0)
    const [autoCompleteText, setAutoCompleteText] = React.useState<string>('')

    const optionContainerRef = React.useCallback((node: any) => {
      if (node !== null) {
        setEllipsisWidth(node.getBoundingClientRect().width)
      }
    }, [])
    const emailRef = React.useCallback((node: any) => {
      if (node !== null) {
        setTextWidth(node.getBoundingClientRect().width)
      }
    }, [])
    useImperativeHandle(ref, () => ({ startLoading, stopLoading }))
    const startLoading = () => {
      setLoadingMultiSelect(true)
    }
    const stopLoading = () => {
      setLoadingMultiSelect(false)
    }

    useEffect(() => {
      const isCurrentUser2 = () => {
        if (value[0]?.email === store.userStore.getEmail()) {
          setIsCurrentUserOption(true)
        }
      }
      if (value) {
        isCurrentUser2()
      }
    }, [value, setIsCurrentUserOption])

    const defaultOptionRender = (option: any) => {
      const keys = Object.keys(option).filter((k) => k != 'id')
      return (
        <Tooltip
          title={
            ellipsisWidth - 50 < textWidth ? (option['email'] as string) : ''
          }
        >
          <OptionTextContainer>
            {keys.map((key, index) => {
              if (index === 0)
                return (
                  <TypographyDDS.Paragraph
                    size='para'
                    variant='medium'
                    color='textDark'
                    key={key}
                  >
                    {option[key]}
                  </TypographyDDS.Paragraph>
                )
              return (
                <TypographyDDS.Paragraph
                  key={key}
                  size='para'
                  variant='medium'
                  color='textDark'
                  ellipsis
                  style={{
                    width: `${ellipsisWidth - 50}px`
                  }}
                >
                  {key === 'email' ? (
                    <span ref={emailRef}>{option[key]}</span>
                  ) : (
                    <span>{option[key]}</span>
                  )}
                </TypographyDDS.Paragraph>
              )
            })}
          </OptionTextContainer>
        </Tooltip>
      )
    }

    return (
      <MultiSelectWrapper
        id={id}
        disabled={loading || disabled}
        onBlur={() => {
          setOpenDropdown(false)
          store.ahcReportsStore.setSearchContexts([])
          store.jobStore.setSearchContexts([])
          store.exceptionStore.setSearchContexts([])
          if (label === 'Search')
            store.uiStore.getAxiosSource().forEach((token) => {
              token.cancel('Request cancelled')
            })
        }}
      >
        <StyledAutoComplete
          isCurrentUserOption={isCurrentUserOption}
          open={openDropdown && autoCompleteText.length >= 3}
          multiple={singleSelect ? false : true}
          value={value}
          blurOnSelect={blurOnSelect}
          options={options}
          filterOptions={(options: any) => options}
          disableClearable={disableClearable}
          getOptionLabel={optionsLabelAccessor}
          isOptionEqualToValue={(option: any, value: any) => {
            return option.id === value.id
          }}
          onChange={(event: any, value: any) => {
            onChange(value)
          }}
          onOpen={() => options.length > 0 && setOpenDropdown(true)}
          renderOption={(props: any, option: any) => {
            return (
              <OptionListWrapper {...props} ref={optionContainerRef}>
                {defaultOptionRender(option)}
              </OptionListWrapper>
            )
          }}
          loading={loadingOption}
          loadingText={<Spinner />}
          sx={
            variant === 'small'
              ? {
                  '.MuiFilledInput-root': {
                    height: '40px !important',
                    paddingTop: '10px !important',
                    paddingBottom: '0px !important'
                  }
                }
              : {}
          }
          renderTags={(tagValue: any) => {
            return tagValue.map((option: any, index: number) => (
              <>
                {!isEmpty(optionsLabelAccessor(option)) && (
                  <>
                    {disableDelete && value.length === 1 ? (
                      <Tooltip
                        title={
                          <div>
                            <TypographyDDS.Paragraph
                              size='caption'
                              variant='semiBold'
                              color='white'
                            >
                              {optionsLabelAccessor(option)}
                            </TypographyDDS.Paragraph>
                          </div>
                        }
                        arrow
                        placement='top'
                        disableHoverListener={
                          optionsLabelAccessor(option).length <=
                          TextContentConstants.MAX_TOOLTIP_LENGTH
                        }
                      >
                        <div>
                          <div
                            style={{
                              maxWidth: '120px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            <Chip
                              key={index}
                              label={optionsLabelAccessor(option)}
                              margin='14px 0 0 0'
                            />
                          </div>
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={
                          <div>
                            <TypographyDDS.Paragraph
                              size='caption'
                              variant='semiBold'
                              color='white'
                            >
                              {optionsLabelAccessor(option)}
                            </TypographyDDS.Paragraph>
                          </div>
                        }
                        arrow
                        placement='top'
                        disableHoverListener={
                          optionsLabelAccessor(option).length <=
                          TextContentConstants.MAX_CLOSE_ICON_TOOLTIP_LENGTH
                        }
                      >
                        <div>
                          <div
                            style={{
                              maxWidth: '120px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            <Chip
                              key={index}
                              label={optionsLabelAccessor(option)}
                              onDelete={() => onDeleteSelection(option)}
                              showCloseIcon={true}
                              deleteIcon={
                                <IoIosClose
                                  style={{ height: '20px', width: '20px' }}
                                />
                              }
                              margin='14px 0 0 0'
                              disabled={store.uiStore.getGlobalLoader()}
                            />
                          </div>
                        </div>
                      </Tooltip>
                    )}
                  </>
                )}
              </>
            ))
          }}
          fullWidth={fullWidth}
          onClose={() => setOpenDropdown(false)}
          popupIcon={
            <IoIosArrowDown style={{ height: '18px', width: '18px' }} />
          }
          PaperComponent={(props: any) => CustomPaper(props, width)}
          clearOnBlur={label === 'Search' || clearOnBlur}
          renderInput={(params: any) => (
            <StyledTextField
              {...params}
              variant='filled'
              hoverBackground={hoverBackgroundColor}
              backgroundColorForTextField={backgroundColor}
              label={label}
              placeholder={getPlaceholder()}
              InputProps={{ ...params.InputProps, disableUnderline: true }}
              InputLabelProps={{
                shrink: true
              }}
              onChange={({ target }: any) => {
                setIsCurrentUserOption(false)
                onTextChange(target.value)
                setAutoCompleteText(target.value)
              }}
              helperText={validationError}
              error={!!validationError}
              ref={inputRef}
            />
          )}
          filterSelectedOptions
          disabled={disabled}
        />
        <>{loadingMultiSelect && <LinearProgressBar />}</>
      </MultiSelectWrapper>
    )
  }
)
MultiSelect.displayName = 'MultiSelect'
export default observer(MultiSelect)
