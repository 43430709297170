import { DDSModal, DDSTypography } from 'den-design-system'
import React, { SetStateAction } from 'react'

import pallete from '../../../../global/newPallete'

interface TransferOwnerShipProp {
  modalOpen: boolean
  setModalOpen: React.Dispatch<SetStateAction<boolean>>

  content: React.ReactNode
  showFooter?: boolean
  primaryButtonClick?: any
  secondaryButtonClick?: any
  primaryButtonText?: string
  secondaryButtonText?: string
  primaryButtonDisabled?: boolean
  secondaryButtonDisabled?: boolean
  loading?: boolean
  title?: string
  showHeader: boolean
  footer?: React.ReactNode
}
export const TransferOwnerPopup: React.FC<TransferOwnerShipProp> = ({
  modalOpen,
  setModalOpen,
  content,
  showHeader,
  title = ''
}) => {
  return (
    <div className='override-model-min-width'>
      <DDSModal
        open={modalOpen}
        setOpen={setModalOpen}
        defaultFooter={false}
        onIconClose={() => setModalOpen(false)}
        hideCloseIcon={true}
      >
        <div>
          {showHeader && (
            <div
              className='flex justify-between items-center px-[24px] py-[16px] border-b'
              style={{ borderBottomColor: pallete.colors.stroke2 }}
            >
              <DDSTypography.Title
                variant='bold'
                type='h4'
                className='!leading-6'
                color={pallete.colors.textDark4}
              >
                {title}
              </DDSTypography.Title>
            </div>
          )}
          <div
            className='flex flex-col overflow-y-scroll border-b'
            style={{ borderBottomColor: pallete.colors.stroke2 }}
          >
            {content}
          </div>
        </div>
      </DDSModal>
    </div>
  )
}
