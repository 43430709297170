import React, { useEffect, useState } from 'react'

import { AddFilled } from '@carbon/icons-react'
import Chip from '../../../components/New/Chip/Chip'
import { DDSTypography } from 'den-design-system'
import DropdownMenu from '../../../components/New/DropdownMenu/DropdownMenu'
import Search from '../../../components/New/Search/Search'
import UserAvatar from '../../../components/common/UserAvatar/UserAvatar'
import emptyAvatar from '../../../assets/icons/emptyAvatar.svg'
import pallete from '../../../global/newPallete'

interface PriorityDropDownProps {
  id?: string
  label?: string
  editable?: boolean
  disabled?: boolean
  userOptions?: any[]
  menuPosition?: string
  arrowPosition?: 'left' | 'right' | 'center'
  selectedUsers?: any
  setSelectedUsers?: any
  tableHeight?: any
  left?: boolean
  disableNoSubscription?: any
}

const toTitleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
const IncidentMenuList: React.FC<PriorityDropDownProps> = ({
  id,

  editable = true,
  userOptions = [],
  selectedUsers = [],
  disabled = false,
  disableNoSubscription = false,
  setSelectedUsers,
  tableHeight,
  menuPosition = 'right',
  arrowPosition = 'center',
  left
}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [allUsers, setAllUsers] = useState<any>([])

  useEffect(() => {
    setAllUsers(userOptions)
  }, [userOptions])

  const [menuPositionList, setMenuPositionList] = useState<'above' | 'below'>(
    'below'
  )

  React.useEffect(() => {
    if (dropDownRef.current) {
      const dropdownRect = dropDownRef.current.getBoundingClientRect()
      const windowHeight = window.innerHeight
      if (dropdownRect.bottom + 250 > windowHeight) {
        setMenuPositionList('above')
      } else {
        setMenuPositionList('below')
      }
    }
  }, [tableHeight])

  const getMenuPosition = () => {
    if (menuPosition === 'left') {
      return '-translate-x-[85%]'
    } else if (menuPosition === 'center') {
      return '-translate-x-[40%]'
    } else if (menuPosition === 'right') {
      return ''
    }
  }

  const avatarObjects: { name: string; avatar: string }[] = []
  if (selectedUsers && selectedUsers.length > 0) {
    selectedUsers.forEach((assignedMember: any) => {
      const avatarObject = {
        name: assignedMember.name,
        avatar: assignedMember.imgUrl
      }
      avatarObjects.push(avatarObject)
    })
  }

  const removeSelectedUser = (itemId: string) => {
    const selectedUsers_ = selectedUsers.filter(
      (user: any) => user.id !== itemId
    )
    setSelectedUsers([...selectedUsers_])
  }

  const dropDownRef = React.useRef<HTMLDivElement>(null)
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropDownRef.current &&
      !dropDownRef.current.contains(event.target as Node)
    ) {
      setMenuOpen(false)
    }
  }

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('click', handleClickOutside)
    } else {
      document.removeEventListener('click', handleClickOutside)
    }

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [menuOpen])

  console.log('User Image URL:', selectedUsers)

  return (
    <div
      className={`${disabled && 'cursor-not-allowed opacity-50'} h-full w-full flex items-center justify-center`}
      ref={dropDownRef}
    >
      <div
        id={`menu-list-btn${id && '-' + id}`}
        className={`flex ${disabled && 'pointer-events-none opacity-50'} items-center ${selectedUsers.length === 0 ? 'gap-x-[4px]' : 'gap-x-[8px]'}  justify-start cursor-pointer`}
      >
        {selectedUsers.length === 0 && (
          <div>
            <img
              id={`menu-list-btn${id && '-' + id}`}
              src={emptyAvatar}
              onClick={() => setMenuOpen(!menuOpen)}
              className='h-[24px] flex  w-[24px]'
            />
          </div>
        )}
        <div>
          <UserAvatar
            id={`menu-list-btn${id && '-' + id}`}
            avatarType='Multiple'
            imageObjectGroup={selectedUsers.map((item: any, index: any) => {
              return {
                id: item?.user?.id,
                name: item?.user?.firstName + ' ' + item?.user?.lastName,
                firstName: item?.user?.firstName,
                lastName: item?.user?.lastName,
                avatar: item?.user?.avatar
              }
            })}
          />
        </div>

        <div>
          {editable && (
            <AddFilled
              id={`menu-list-btn${id && '-' + id}`}
              size={16}
              onClick={() => setMenuOpen(!menuOpen)}
              color={pallete.colors.textDark2}
            />
          )}
        </div>
      </div>

      <div
        className={` ${disableNoSubscription && 'cursor-not-allowed pointer-events-none'}  absolute z-50 `}
      >
        <DropdownMenu
          id='uc-menu-list'
          open={menuOpen}
          parentId='menu-list-btn'
          arrowPosition={arrowPosition}
          onClose={() => {
            setMenuOpen(false)
            setAllUsers([...userOptions])
          }}
          className={`${getMenuPosition} absolute z-[9999] mt-[5px] ${left ? 'left-[-286px]' : 'left-[-167px]'} top-[16px] !min-w-[112px] max-h-[380px] override-padding ${
            menuPositionList === 'above' ? 'translate-y-[-215px]' : ''
          }`}
        >
          <div className='flex flex-col gap-[8px] '>
            {selectedUsers?.length !== 0 && (
              <div className='flex flex-col max-h-[85px] gap-[6px]  overflow-y-scroll'>
                {selectedUsers?.map((item: any, index: any) => (
                  <Chip
                    id='chip-menu-list'
                    key={index}
                    label={item?.user?.firstName + ' ' + item?.user?.lastName}
                    startIcon={
                      <div className='rounded-full h-[16px] w-[16px]'>
                        <img
                          src={item?.user?.avatar}
                          className='w-full h-full rounded-full object-fill'
                        />
                      </div>
                    }
                    onClose={() => removeSelectedUser(item.id)}
                  />
                ))}
              </div>
            )}
            <Search
              id='search'
              name='Search'
              placeholder='Search'
              onChange={(e) => {
                const filteredUsers = userOptions.filter((user: any) =>
                  user.name.toLowerCase().includes(e.target.value.toLowerCase())
                )
                setAllUsers([...filteredUsers])
              }}
            />
            <div className='flex flex-col gap-[8px] max-h-[168px] overflow-y-scroll'>
              {allUsers?.map((item: any, index: any) => (
                <div
                  key={item.id}
                  className={` ${disableNoSubscription && 'cursor-not-allowed opacity-50'} flex rounded-md menu-list-hover w-full cursor-pointer items-center gap-[8px] p-[8px]`}
                  onClick={() => {
                    const present = selectedUsers.filter(
                      (user: any) => item.id == user.id
                    )
                    if (present?.length === 0) {
                      selectedUsers.push(item)
                      setSelectedUsers([...selectedUsers])
                    }
                  }}
                >
                  <img
                    src={item.imgUrl}
                    className='w-[20px] h-[20px] rounded-full object-fill'
                  />
                  <div className='flex justify-start gap-[8px] items-center'>
                    <DDSTypography.Paragraph
                      className='whitespace-nowrap'
                      variant='medium'
                      size='para'
                      color={pallete.colors.textDark4}
                    >
                      {item.name}
                    </DDSTypography.Paragraph>
                    <DDSTypography.Paragraph
                      variant='regular'
                      size='caption'
                      className='flex items-center'
                      color={pallete.colors.textDark9}
                    >
                      ({item.role})
                    </DDSTypography.Paragraph>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </DropdownMenu>
      </div>
    </div>
  )
}

export default IncidentMenuList
