import { Grid, Stack } from '@mui/material'

import React from 'react'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import UsageCard from './common/UsageCard'
import arrowRightIcon from '../../assets/icons/arrowRight.svg'
import { replaceUrl } from '../../utils/UrlUtils'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../store/StoreContext'

interface AbsoluteUsageWithTitle {
  title: string
  value: number
}
interface MetadataCardProps {
  title: string
  link?: string
  total: AbsoluteUsageWithTitle
  limit?: AbsoluteUsageWithTitle
}
const MetadataCard: React.FC<MetadataCardProps> = ({
  title,
  link,
  total,
  limit
}) => {
  const navigate = useNavigate()
  const { scopeStore } = useStoreContext()
  const handleNavigation = (link: string) => {
    navigate(
      replaceUrl(
        link,
        scopeStore.getScope()['organization-id'] as string,
        scopeStore.getScope()['project-id'] as string,
        scopeStore.getScope()['environment-id'] as string
      )
    )
  }
  return (
    <UsageCard>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12}>
          <Stack
            direction='row'
            alignItems='center'
            spacing={1}
            style={{ cursor: link ? 'pointer' : 'auto' }}
            onClick={() => {
              link && handleNavigation(link)
            }}
          >
            <TypographyDDS.Title type='h5'>{title}</TypographyDDS.Title>
            {link && <img src={arrowRightIcon} style={{ height: '16px' }} />}
          </Stack>
        </Grid>
        <Grid item xs={limit ? 6 : 12}>
          <Stack>
            <TypographyDDS.Paragraph
              size='para'
              variant='semiBold'
              color='darkGrey'
            >
              {total.title}
            </TypographyDDS.Paragraph>

            <TypographyDDS.Title type='h4' variant='bold'>
              {total.value}
            </TypographyDDS.Title>
          </Stack>
        </Grid>
        {limit && (
          <Grid item xs={6}>
            <Stack>
              <TypographyDDS.Paragraph
                size='para'
                variant='semiBold'
                color='darkGrey'
              >
                {limit.title}
              </TypographyDDS.Paragraph>

              <TypographyDDS.Title type='h4' variant='bold'>
                {limit.value}
              </TypographyDDS.Title>
            </Stack>
          </Grid>
        )}
      </Grid>
    </UsageCard>
  )
}

export default MetadataCard
