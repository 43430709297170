import { Navigate, Route, Routes } from 'react-router-dom'

import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import ApexClassDetails from './ApexClassDetails'
import ApexClassesOverview from './ApexClassesOverview'
import ApexTriggerDetails from './ApexTriggerDetails'
import ApexTriggersOverview from './ApexTriggersOverview'
import LicenseDetails from './LicenseDetails'
import LicensesOverview from './LicensesOverview'
import MetaDataOverview from './MetaDataOverview'
import PermissionSetDetails from './PermissionSetDetails'
import PermissionSetsOverview from './PermissionSetsOverview'
import ProfileDetails from './ProfileDetails'
import ProfilesOverview from './ProfilesOverview'
import { ProjectType } from '../../enum/ProjectType.enum'
import React from 'react'
import RoleDetails from './RoleDetails'
import RolesOverview from './RolesOverview'
import SalesforceAuditDashboard from './SalesforceAuditDashboard'
import SalesforceAuditLayout from './SalesforceAuditLayout'
import SecureComponent from '../../components/Layout/SecureComponent'
import UserDetails from './UserDetails'
import UsersOverview from './UsersOverview'
import { observer } from 'mobx-react-lite'

const SalesforceAudit: React.FC = () => {
  return (
    <Routes>
      <Route element={<SalesforceAuditLayout />}>
        <Route index element={<Navigate to='dashboard' />} />
        <Route path='dashboard' element={<SalesforceAuditDashboard />} />
        <Route path='metadata' element={<MetaDataOverview />} />
        <Route path='metadata/apex-classes' element={<ApexClassesOverview />} />
        <Route
          path='metadata/apex-classes/:id'
          element={<ApexClassDetails />}
        />
        <Route
          path='metadata/apex-triggers'
          element={<ApexTriggersOverview />}
        />
        <Route
          path='metadata/apex-triggers/:id'
          element={<ApexTriggerDetails />}
        />
        <Route path='metadata/roles' element={<RolesOverview />} />
        <Route path='metadata/roles/:id' element={<RoleDetails />} />
        <Route path='metadata/profiles' element={<ProfilesOverview />} />
        <Route path='metadata/profiles/:id' element={<ProfileDetails />} />
        <Route
          path='metadata/permission-sets'
          element={<PermissionSetsOverview />}
        />
        <Route
          path='metadata/permission-sets/:id'
          element={<PermissionSetDetails />}
        />
        <Route path='users' element={<UsersOverview />} />
        <Route path='users/:id' element={<UserDetails />} />
        <Route path='licenses' element={<LicensesOverview />} />
        <Route path='licenses/:id' element={<LicenseDetails />} />
      </Route>
    </Routes>
  )
}

export default SecureComponent(
  observer(SalesforceAudit),
  AccessRoleConstants.MEMBER_LEVEL,
  ProjectType.FULL_STACK_ONLY
)
