import { useEffect } from 'react'

const useRefeshOnToken = (): void => {
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent): void => {
      if (event.key === 'accessToken') {
        window.location.reload()
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])
}

export default useRefeshOnToken
