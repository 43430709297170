import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'

import { BsChevronLeft } from 'react-icons/bs'
import Button from '../../common/Button/Button'
import React from 'react'
import { BiRightArrowAlt as RightArrow } from 'react-icons/bi'
import { TypographyDDS } from '../../common/Typography/TypographyDDS'
import breakpoints from '../../../global/breakpoints'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import vigilIcon from '../../../assets/icons/vigilTMLogo.svg'

interface CreatedOrgProps {
  organizationName?: string
  onClick?: () => void
  handleTabChange?: () => void
  handleOrganizationCreate?: () => void
}

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  padding: 3.125em 0 3.125em 12em;
  width: 71.625em;
  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.85rem;
  }

  @media (max-width: ${breakpoints.md}) {
    font-size: 0.7rem;
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    width: 100%;
    padding: 3.125em 5em;
  }
`
const TopContainer = styled.div``

const MainContainer = styled.div`
  width: 100%;
  padding-top: 2.5em;
`

const BottomContainer = styled.div``

const ButtonTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CreatedOrg: React.FC<CreatedOrgProps> = ({
  organizationName,
  onClick,
  handleTabChange,
  handleOrganizationCreate
}) => {
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.CREATED_ORGANIZATION
  )

  return (
    <Container>
      <TopContainer>
        <img src={vigilIcon} />
      </TopContainer>
      <MainContainer>
        <TypographyDDS.Title
          type='h1'
          variant='medium'
          style={{
            paddingBottom: '10px',
            textAlign: 'left'
          }}
        >
          Your organization
          <br />
          <span
            style={{
              fontWeight: palette.fontWeight.bold,
              color: palette.colors.primary
            }}
          >
            {organizationName}
          </span>
          &nbsp;will be created
        </TypographyDDS.Title>
        <Button
          variant='contained'
          color='gradientPrimary'
          margin='2.1875em 0 1.25em 0'
          fullWidth
          fullWidthChild
          size='large'
          onClick={() => {
            handleTabChange
            gaEventTracker(gaEventConstants.ORGANIZATION_CREATED)
          }}
        >
          <ButtonTextContainer onClick={handleOrganizationCreate}>
            <TypographyDDS.Title type='h4' variant='bold' color='white'>
              Create {organizationName}
            </TypographyDDS.Title>

            <RightArrow style={{ fontSize: '2em' }} />
          </ButtonTextContainer>
        </Button>
      </MainContainer>
      <BottomContainer>
        <Button
          variant='outlined'
          startIcon={<BsChevronLeft />}
          margin='1.25em 0'
          onClick={onClick}
        >
          Go Back
        </Button>
      </BottomContainer>
    </Container>
  )
}

export default CreatedOrg
