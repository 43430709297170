import Highcharts, { defaultOptions } from 'highcharts/highstock'

import HCMore from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'
import palette from '../../global/pallete'

HCMore(Highcharts)

const UCStockChart: React.FC<{
  data: any
  startDate: any
  single?: boolean
  value?: any
  marker?: boolean
}> = ({ data, startDate, single, value, marker = true }) => {
  const options = {
    rangeSelector: {
      enabled: false
    },

    navigator: {
      enabled: false
    },
    chart: {
      zoomType: 'x', // Enable zooming along the x-axis
      height: 302,
      width: window.innerWidth >= 1580 ? 600 : 800
    },
    title: {
      text: null
    },

    credits: {
      enabled: false
    },
    scrollbar: {
      enabled: false
    },
    tooltip: {
      formatter: function (): any {
        const formattedDate = Highcharts.dateFormat('%Y-%m-%d', (this as any).x)
        return (
          'Date: ' +
          formattedDate +
          '<br/>' +
          'Avg. Response Time: ' +
          (this as any).y
        )
      }
    },
    plotOptions: {
      series: {
        marker: {
          enabled: marker, // Enable/disable markers based on the prop
          symbol: 'circle', // Set marker symbol shape (e.g., circle, square, diamond)
          radius: 4, // Set marker size
          fillColor: 'rgb(6,108,255)', // Set marker fill color
          lineColor: '#ffffff', // Set marker border color
          lineWidth: 2 // Set marker border width
        },
        lineWidth: 2, // Line width
        borderColor: palette.colors.primary, // Line border color
        fillOpacity: 1, // Ensure the gradient fills the entire area
        color: palette.colors.primary // Line color to match the border color
      }
    },
    yAxis: {
      title: {
        text: null
      }
    },
    xAxis: {
      type: 'datetime',
      labels: {
        rotation: -90,
        formatter: function (): any {
          return Highcharts.dateFormat('%d/%m/%Y', (this as any).value) // Format the x-axis labels as 'dd/mm/yyyy'
        }
      }
    },
    series: [
      {
        type: 'area', // Explicitly set the series type to 'area'
        data: single
          ? [
              {
                x: startDate, // Single data point x-value
                y: value // Single data point y-value
              }
            ]
          : data,
        name: '',
        showInLegend: false,
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 }, // Vertical gradient for the area
          stops: [
            [0, palette.colors.primary4], // Start color
            [1, palette.colors.white] // End color
          ]
        }
      }
    ]
  }

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default UCStockChart
