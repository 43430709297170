import ApiManager from '../Api.manager'
import { FilterTypes } from '../../enum/Filter.enum'
import HttpConstants from '../../constants/HttpConstants'
import UrlConstants from '../../constants/UrlConstants'
import { getFiltersForApi } from '../../utils/FilterUtils'

export const getAllNotifications = (payload?: any) => {
  const filters = getFiltersForApi(FilterTypes.NOTIFICATIONS, payload)
  const usecase = UrlConstants.GET_ALL_NOTIFICATIONS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      filters
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else throw response
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const getNotificationFilters = () => {
  const usecase = UrlConstants.GET_NOTIFICATION_FILTERS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      {}
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else throw response
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const markNotificationAsRead = (payload: { $id: string }) => {
  const usecase = UrlConstants.MARK_NOTIFICATION_AS_READ.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else throw response
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

// function to mark all in-app-Notifications as read
export const markAllNotificationsAsRead = () => {
  const usecase = UrlConstants.MARK_ALL_NOTIFICATIONS_AS_READ.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      {}
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else throw response
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}
