import React, { useState } from 'react'

import { Copy } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import { copyJsonToClipboard } from '../../../utils/CommonUtils/CopyUtils'
import pallete from '../../../global/newPallete'

interface Data {
  id: string
  heading: string
  content: string
}

interface TableData {
  id: string
  tableData: Data[]
}

const JMHorizontalTable: React.FC<TableData> = ({ tableData }) => {
  const [copy, setCopy] = useState<string | null>(null)

  return (
    <div className='w-full mx-auto overflow-hidden'>
      <div className='bg-white overflow-hidden override-custom-table'>
        <table className='w-full'>
          <tbody>
            {tableData.map((data, index) => {
              const isFirstRow = index === 0
              const isLastRow = index === tableData.length - 1

              return (
                <tr
                  key={index}
                  className={`border-b `}
                  style={{
                    borderColor: pallete.colors.stroke2
                  }}
                >
                  <td
                    className={`border-r w-[11%] ${isFirstRow ? 'rounded-tl-[6px]' : ''} ${isLastRow ? 'rounded-bl-[6px]' : ''}`}
                    style={{ backgroundColor: pallete.colors.surface6 }}
                  >
                    <div
                      className={`${isFirstRow ? 'rounded-tl-[6px]' : ''} ${isLastRow ? 'rounded-bl-[6px]' : ''}`}
                      style={{ backgroundColor: pallete.colors.surface6 }}
                    >
                      <DDSTypography.Paragraph
                        size='caption'
                        variant='semiBold'
                        className='whitespace-nowrap'
                        color={pallete.colors.textDark6}
                      >
                        {data.heading}
                      </DDSTypography.Paragraph>
                    </div>
                  </td>
                  <td
                    className={`${isFirstRow ? 'rounded-tr-[6px]' : ''} ${isLastRow ? 'rounded-br-[6px]' : ''}`}
                  >
                    <div
                      className={`${isFirstRow ? 'rounded-tr-[6px]' : ''} ${isLastRow ? 'rounded-br-[6px]' : ''} flex justify-start items-center`}
                    >
                      <DDSTypography.Paragraph
                        size='para'
                        variant='medium'
                        className='max-w-fit'
                        color={pallete.colors.textDark4}
                      >
                        {data.content}
                      </DDSTypography.Paragraph>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default JMHorizontalTable
