import '../../../styles.css'

import { DDSChips, DDSTypography } from 'den-design-system'

import { ChipsProps } from './Chip.types'
import { Close } from '@carbon/icons-react'
import React from 'react'
import pallete from '../../../global/newPallete'

const Chip: React.FC<ChipsProps> = ({
  id,
  label,
  startIcon,
  onClose,
  className,
  type = 'filled'
}) => {
  const Label: React.FC<{ label?: string }> = ({ label }) => {
    return (
      <DDSTypography.Paragraph
        variant='medium'
        size='caption'
        className='whitespace-nowrap'
        style={{ color: pallete.colors.textDark4 }}
      >
        {label}
      </DDSTypography.Paragraph>
    )
  }
  return (
    <div className='override-chip'>
      <DDSChips
        id={id}
        type='filled'
        shape='rounded'
        size='medium'
        label={<Label label={label} />}
        endIcon={
          <Close
            color={pallete.colors.textDark4}
            className='cursor-pointer'
            onClick={onClose}
          />
        }
        startIcon={startIcon}
        style={{
          border: 'none',
          backgroundColor: pallete.colors.surface4
        }}
      />
    </div>
  )
}

export default Chip
