import { makeAutoObservable, observable } from 'mobx'

import { RootStore } from './RootStore'
import { isUndefined } from 'lodash'
import routeConstants from '../constants/RouteConstants'

type breadcrumbOption = {
  label: any
  link: string
}
export class BreadcrumbsStore {
  breadcrumbsOptions: breadcrumbOption[]
  goBackLink: string
  constructor(private rootStore: RootStore) {
    this.breadcrumbsOptions = []
    this.goBackLink = ''
    makeAutoObservable(this, { breadcrumbsOptions: observable.struct })
  }

  getBreadcrumbsOptions() {
    return this.breadcrumbsOptions
  }
  setBreadcrumbsOptions(option: breadcrumbOption) {
    this.breadcrumbsOptions = [option]
  }
  sliceBreadcrumbOptions(fromIndex: number, toIndex?: number) {
    if (!isUndefined(fromIndex) && !isUndefined(toIndex)) {
      this.breadcrumbsOptions = [
        ...this.breadcrumbsOptions.slice(fromIndex, toIndex)
      ]
    } else
      this.breadcrumbsOptions = [...this.breadcrumbsOptions.slice(fromIndex)]
  }
  appendBreadCrumbOptions(option: breadcrumbOption) {
    this.breadcrumbsOptions = [...this.breadcrumbsOptions, option]
  }
  addMultipleBreadCrumbOptions(options: breadcrumbOption[]) {
    this.breadcrumbsOptions = [...this.breadcrumbsOptions, ...options]
  }
  setMultipleBreadcrumbsOptions(options: breadcrumbOption[]) {
    this.breadcrumbsOptions = [...options]
  }

  getGoBackLink() {
    return this.goBackLink
  }
  setGoBackLink(link: string) {
    this.goBackLink = link
  }
  reset() {
    this.breadcrumbsOptions = []
  }
}
