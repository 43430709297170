import { makeAutoObservable, observable } from 'mobx'

import { RootStore } from './RootStore'

export interface Project {
  [key: string]: string | boolean
}

export class ProjectStore {
  projects: Project[]
  loading: boolean
  totalCount: number
  currentPage: number
  projectName: string

  constructor(private rootStore: RootStore) {
    this.projects = []
    this.loading = false
    this.totalCount = 0
    this.currentPage = 1
    this.projectName = ''
    makeAutoObservable(this, {
      projects: observable.struct,
      loading: observable.struct,
      totalCount: observable.struct,
      currentPage: observable.struct,
      projectName: observable.struct
    })
  }

  setProjects(projects: Project[]) {
    this.projects = projects
  }

  getProjects() {
    return this.rootStore.projectStore.projects
  }

  setProjectName(projectName: string) {
    this.projectName = projectName
  }

  getProjectName() {
    return this.rootStore.projectStore.projectName
  }

  setLoading(loading: boolean) {
    this.loading = loading
  }

  getLoading(): boolean {
    return this.rootStore.projectStore.loading
  }

  setTotalCount(totalCount: number) {
    this.totalCount = totalCount
  }

  getTotalCount(): number {
    return this.rootStore.projectStore.totalCount
  }

  getCurrentPage(): number {
    return this.rootStore.projectStore.currentPage
  }

  setCurrentPage(currentPage: number) {
    this.currentPage = currentPage
  }

  reset() {
    this.projects = []
    this.loading = false
    this.totalCount = 0
    this.currentPage = 1
  }
}
