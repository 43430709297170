export const TextContentConstants = {
  CREATE_A_NEW_ORGANIZATION: 'Create your Organization effortlessly!',
  DELETE_PROJECT: 'Project will be permanently deleted',
  RESEND_LINK: 'Resend Link',
  PROJECT_NAME: 'Customize each project with a unique Name',
  PERMANENT_DELETE: ' will be permanently Deleted',
  UNIQUE_NAME_FOR_EACH_ENV: 'Customize each environment with a unique name',
  MODIFY_ENV: 'Modify the environment type',
  DELETE_ENV: 'Delete Environment',
  PERMANANTLY_DELTE_ENV: ' Environment will be permanently deleted',
  USER_NOT_FOUND_IN_FILTERS: 'No matching user found',
  AHC_DATE_RANGE_ERROR: ' *Please choose a range below 45 days',
  MAX_TOOLTIP_LENGTH: 12,
  MAX_CLOSE_ICON_TOOLTIP_LENGTH: 7
}
