import { Grid, Stack } from '@mui/material'

import React from 'react'
import Table from '../../components/common/Table/Table'
import { TypographyDDS } from '../../components/common/Typography/TypographyDDS'
import UsageCard from '../../components/SalesforceAudit/common/UsageCard'
import arrowLeftIcon from '../../assets/icons/leftArrow.svg'
import arrowRightIcon from '../../assets/icons/arrowRight.svg'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import palette from '../../global/pallete'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../store/StoreContext'

const metadataPathMap: Record<string, string> = {
  'Apex Classes': 'apex-classes',
  'Apex Triggers': 'apex-triggers',
  Roles: 'roles',
  Profiles: 'profiles',
  'Permission Sets': 'permission-sets'
  // 'Custom Permission': 'custom-permissions',
  // 'Email Alerts': 'email-alerts'
}

const TitleWrapper = styled.div`
  color: ${palette.colors.lightGrey2};
`
const MetaDataOverview: React.FC = () => {
  const navigate = useNavigate()
  const handleGoBack = () => {
    const segments = location.pathname.split('/').filter(Boolean)
    segments.pop()
    const newPath = '/' + segments.join('/')
    navigate(newPath)
  }
  const columns = [
    {
      title: <TitleWrapper>Metadata</TitleWrapper>,
      label: 'name',
      render: (item: any) => item
    },
    {
      title: <TitleWrapper>Total</TitleWrapper>,
      label: 'total',
      render: (item: any) => item
    },
    {
      title: '',
      label: 'name',
      render: (item: any) => (
        <Stack
          direction='row'
          alignItems='center'
          style={{ cursor: 'pointer' }}
          spacing={1}
          onClick={() => navigate(metadataPathMap[item])}
        >
          <span>View</span>
          <img src={arrowRightIcon} style={{ height: '16px' }} />
        </Stack>
      )
    }
  ]
  const { salesforceAuditStore } = useStoreContext()
  const auditData = salesforceAuditStore.getAuditData()
  if (isEmpty(auditData)) return <></>
  const data = salesforceAuditStore.getMetadataOverviewData()
  return (
    <Grid container width='80%' paddingTop='16px' spacing={2}>
      <Grid item xs={12}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          style={{ cursor: 'pointer' }}
          onClick={() => handleGoBack()}
        >
          <img src={arrowLeftIcon} style={{ height: '16px' }} />
          <TypographyDDS.Paragraph size='para' color='textDark'>
            Go Back
          </TypographyDDS.Paragraph>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <TypographyDDS.Title type='h4' variant='bold'>
          Metadata(s)
        </TypographyDDS.Title>
      </Grid>
      <Grid item xs={12}>
        <UsageCard>
          <Table columns={columns} data={data} showPagination={false} />
        </UsageCard>
      </Grid>
    </Grid>
  )
}

export default observer(MetaDataOverview)
