import { DDSButton, DDSMenu } from 'den-design-system'
import React, { useState } from 'react'

import { ChevronDown } from '@carbon/icons-react'
import { SeverityTypes } from '../../../constants/SeverityPriorityConstants'
import pallete from '../../../global/newPallete'

interface PriorityDropDownProps {
  label: string
  onSelect: any
  insideTable?: boolean
  tableHeight?: any
  disabled?: boolean
  disableNoSubscription?: boolean
}

const SeverityDropDown: React.FC<PriorityDropDownProps> = ({
  label,
  onSelect,
  insideTable = true,
  tableHeight,
  disabled,
  disableNoSubscription
}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [menuPosition, setMenuPosition] = useState<'above' | 'below'>('below')
  const dropDownRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (dropDownRef.current) {
      const dropdownRect = dropDownRef.current.getBoundingClientRect()
      const windowHeight = window.innerHeight
      if (dropdownRect.bottom + 220 > windowHeight) {
        setMenuPosition('above')
      } else {
        setMenuPosition('below')
      }
    }
  }, [tableHeight])

  React.useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        menuOpen &&
        dropDownRef.current &&
        !dropDownRef.current.contains(e.target)
      ) {
        setMenuOpen(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [menuOpen])

  return (
    <div className='' ref={dropDownRef}>
      <div className='override-min-width override-padding'>
        <DDSButton
          onClick={() => {
            setMenuOpen(!menuOpen)
          }}
          disabled={disabled}
          type='outline'
          shape='roundedRectangle'
          className={`justify-between ${insideTable ? '!px-[12px] !py-[4px]' : '!px-[16px] !py-[8px]'} !min-w-[112px] !text-[14px]`}
          style={{
            height: insideTable ? '26px' : '32px',
            fontSize: '14px',
            borderColor: pallete.colors.stroke2,
            ...SeverityTypes.find((severity: any) => severity.label === label)
              ?.style,
            minWidth: '120px'
          }}
          label={label}
          endIcon={
            <ChevronDown
              style={{
                fontSize: 16,
                color: pallete.colors.textDark3
              }}
            />
          }
          buttonHoverTextColor={
            SeverityTypes.find((severity: any) => severity.label === label)
              ?.style.color
          }
        />
      </div>
      <DDSMenu
        open={menuOpen}
        shape='roundedRectangle'
        className={`absolute z-[9999] mt-[5px] !min-w-[112px] border-2 shadow-sm max-h-[380px] override-padding ${
          menuPosition === 'above' ? 'translate-y-[-215px]' : ''
        }`}
      >
        <div
          className={`flex flex-col gap-y-[8px] ${disableNoSubscription && 'cursor-not-allowed pointer-events-none opacity-50'} `}
        >
          {SeverityTypes.map((severity, index) => (
            <DDSButton
              type='outline'
              shape='roundedRectangle'
              key={index}
              label={severity.label}
              className={`w-full h-[24px] !min-w-0 ${disableNoSubscription && 'cursor-not-allowed pointer-events-none'} !p-[12px] flex !text-[14px] !justify-start`}
              style={{
                borderColor: pallete.colors.white,
                fontSize: '14px !important',
                ...severity.style
              }}
              onClick={() => {
                onSelect(severity.label)
                setMenuOpen(false)
              }}
              disabled={disabled}
              buttonHoverTextColor={severity.style.color}
              buttonHoverBackgroundColor={pallete.colors.neuButtonHover}
            />
          ))}
        </div>
      </DDSMenu>
    </div>
  )
}

export default SeverityDropDown
