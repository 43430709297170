import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'

import { AccessRoleConstants } from '../../../constants/AccessRoleConstants'
import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import EscalationSetDetail from './EscalationSetDetail'
import Escalations from '../Escalations/EscalationPage'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import { ProjectType } from '../../../enum/ProjectType.enum'
import SecureComponent from '../../../components/Layout/SecureComponent'
import { getUserDetails } from '../../../api/user/User.service'
import { observer } from 'mobx-react-lite'
import routeConstants from '../../../constants/RouteConstants'
import { useStoreContext } from '../../../store/StoreContext'

const EscalationsRouter = () => {
  const storeContext = useStoreContext()
  const navigate = useNavigate()
  useEffect(() => {
    getUserDetails().then((userResponse: any) => {
      const organizationIndex = userResponse.data.organizations.findIndex(
        (org: any) => {
          return org.id === storeContext.userStore.getSelectedOrganization().id
        }
      )

      if (organizationIndex === -1) {
        navigate(routeConstants.UNAUTHORIZED_PAGE)
      }
    })
  }, [])
  return (
    <>
      <DocumentHeader
        header={
          storeContext.scopeStore.getSelectedProject().type ===
          ProjectType.FULL_STACK_ONLY
            ? HeaderConstants.ESCALATIONS
            : HeaderConstants.ESCALATIONS
        }
      />
      <Routes>
        <Route index element={<Escalations />} />
        <Route path=':escalationSetId' element={<EscalationSetDetail />} />
      </Routes>
    </>
  )
}

export default SecureComponent(
  observer(EscalationsRouter),
  AccessRoleConstants.MEMBER_LEVEL
)
