import { Box, Grid, Stack } from '@mui/material'

import React from 'react'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import UsageBar from './UsageBar'
import UsageCard from './common/UsageCard'
import palette from '../../global/pallete'
import styled from '@emotion/styled'

interface AbsoluteUsageWithTitle {
  title: string
  value: number
}
interface APIUsageCardProps {
  title: string
  usage: number
  requests: AbsoluteUsageWithTitle
  limit: AbsoluteUsageWithTitle
}

const APIUsageCard: React.FC<APIUsageCardProps> = ({
  title,
  usage,
  requests,
  limit
}) => {
  return (
    <UsageCard>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12}>
          <TypographyDDS.Title type='h5'>{title}</TypographyDDS.Title>
        </Grid>

        <Grid item xs={6}>
          <Stack>
            <TypographyDDS.Paragraph
              size='para'
              variant='semiBold'
              color='textDark'
            >
              Total Usage
            </TypographyDDS.Paragraph>
            <TypographyDDS.Title type='h5' variant='bold' color='primary'>
              {usage}%
            </TypographyDDS.Title>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <UsageBar usage={usage} />
        </Grid>
        <Grid item xs={4}>
          <Stack>
            <TypographyDDS.Paragraph
              size='para'
              variant='semiBold'
              color='textDark'
            >
              {requests.title}
            </TypographyDDS.Paragraph>
            <TypographyDDS.Title type='h4' variant='bold'>
              {requests.value}
            </TypographyDDS.Title>
          </Stack>
        </Grid>
        <Grid item>
          <Stack>
            <TypographyDDS.Paragraph
              size='para'
              variant='semiBold'
              color='textDark'
            >
              {limit.title}
            </TypographyDDS.Paragraph>
            <TypographyDDS.Title type='h4' variant='bold'>
              {limit.value}
            </TypographyDDS.Title>
          </Stack>
        </Grid>
      </Grid>
    </UsageCard>
  )
}

export default APIUsageCard
