import { DDSTooltip, DDSTypography } from 'den-design-system'
import React, { useEffect, useRef, useState } from 'react'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../../constants/GAConstants'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../../utils/UrlUtils'
import { msToTimeUnits, timeZone } from '../../../../utils/DateTimeUtils'
import { useNavigate, useSearchParams } from 'react-router-dom'

import ApiGroupingStringConstants from '../../../../constants/ApiGroupingStringConstants'
import { ChevronRight } from '@carbon/icons-react'
import { Link } from '@mui/material'
import NoFilterImage from '../../../../assets/images/folder-not-found 1.svg'
import NoRecord from '../../../common/NoContent/NoRecord'
import NoRecordImage from '../../../../assets/images/setting.svg'
import Spinner from '../../../common/Spinner/Spinner'
import StatusCodeGraph from '../../../common/StatusCodeGraph/StatusCodeGraph'
import StringConstants from '../../../../constants/StringConstants'
import Table from '../../../common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../common/DDS/Toast/Toast'
import Tooltip from '../../../New/Tooltip/Tooltip'
import { color } from '../../../../utils/ApiStatusColorCode'
import { fetchGroupedApiRecords } from '../../../../api/apiMonitoring/ApiMonitoring.service'
import { isNull } from 'lodash'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../global/newPallete'
import routeConstants from '../../../../constants/RouteConstants'
import { timeFormats } from '../../../../enum/TIME'
import { useAnalyticsEventTracker } from '../../../../utils/GAUtils'
import { useStoreContext } from '../../../../store/StoreContext'

interface URLViewProps {
  loading: boolean
  setLoading: (loading: boolean) => void
  getGroupedFilter: () => void
}

const URLView: React.FC<URLViewProps> = ({
  loading,
  setLoading,
  getGroupedFilter
}) => {
  const [groupedApiTableData, setGroupedApiTableData] = useState<any>()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate()
  const store = useStoreContext()
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.API_MONITORING_TABLE
  )
  const tableRef = useRef<HTMLDivElement>(null)
  const [tableHeight, setTableHeight] = useState<number | null>(null)

  const organizations = store.userStore.getOrganizations()
  const foundOrganization = organizations.find(
    (organization: any) =>
      organization.id === store.scopeStore.getOrganizationId()
  )

  useEffect(() => {
    if (tableRef.current) {
      setTableHeight(tableRef.current.clientHeight)
    }
  }, [groupedApiTableData, loading])

  useEffect(() => {
    if (store.filterStore.getFilterDisabled()) {
      store.filterStore.setFilterDisabled(false)
    }
  }, [store.filterStore.getFilterDisabled()])

  const routeGroupedDetails = (id: number) => {
    const route =
      replaceUrl(
        routeConstants.VIEW_GROUPED_API,
        store.scopeStore.getScope()['organization-id'] as string,
        store.scopeStore.getScope()['project-id'] as string,
        store.scopeStore.getScope()['environment-id'] as string
      ).replace(':id', String(id)) + '?page=1&tab=1'

    navigate(route)
  }

  const groupedApiData = (queryParams: any) => {
    store.uiStore.setGlobalLoader(true)
    setLoading(true)

    fetchGroupedApiRecords(queryParams)
      .then((response: any) => {
        setGroupedApiTableData(response.data)
        store.apiDetailsStore.setRefresh(false)
        setTotalCount(response.totalCount)
        store.filterStore.setFilters(response.filters)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  useEffect(() => {
    const pageFromQueryParams = searchParams.get('page')
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (!isNull(pageFromQueryParams)) {
      setCurrentPage(Number(pageFromQueryParams))
      groupedApiData({
        ...allQueryParams,
        size: StringConstants.DEFAULT_TABLE_SIZE
      })
    } else {
      setSearchParams({ ...allQueryParams, page: '1' })
      setCurrentPage(1)
    }
  }, [searchParams, store.scopeStore.getScope()])

  useEffect(() => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)

    if (store.apiDetailsStore.getRefresh() === true) {
      setLoading(true)
      store.uiStore.setGlobalLoader(true)
      groupedApiData({
        ...allQueryParams,
        size: StringConstants.DEFAULT_TABLE_SIZE
      })

      getGroupedFilter()
    }
  }, [store.apiDetailsStore.getRefresh()])

  const Columns = [
    {
      columnHeaderStyle: { width: '280px' },
      columnDataStyle: { width: '280px' },
      heading: (
        <div className='flex justify-start text-start max-w-[280px] row items-start'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.URL_TEMPLATE}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'urlTemplate',
      render: (text: any) => (
        <div className='flex justify-start max-w-[280px] text-start row items-start '>
          <div>
            <Tooltip
              id={`tooltip-name-${text}`}
              label={text}
              className={`override-tooltip-arrow ${
                typeof text === 'string' && text.length > 25 ? text : '!hidden'
              }`}
            >
              <div>
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  color={pallete.colors.textDark4}
                  className='tooltip-container sm:block overflow-hidden truncate max-w-[280px]'
                >
                  {text || text === 0 ? text : '-'}
                </DDSTypography.Paragraph>
              </div>
            </Tooltip>
          </div>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center  text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.HTTP_METHOD}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'httpMethod',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text || text === 0 ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center  text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.API_COUNT}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'apiCount',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text || text === 0 ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    ...(foundOrganization.subscription.trackApiUsage === true
      ? [
          {
            columnHeaderStyle: {},
            columnDataStyle: {},
            heading: (
              <div className='flex row justify-center text-center items-center'>
                <DDSTypography.Paragraph
                  size='caption'
                  variant='semiBold'
                  color={pallete.colors.textDark6}
                >
                  {ApiGroupingStringConstants.NO_USER}
                </DDSTypography.Paragraph>
              </div>
            ),
            dataLabel: 'userCount',
            render: (text: any) => (
              <div className='flex justify-center text-center row items-center'>
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  color={pallete.colors.textDark4}
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                  className='tooltip-container sm:block overflow-hidden truncate'
                >
                  {text || text === 0 ? text : '-'}
                </DDSTypography.Paragraph>
              </div>
            )
          }
        ]
      : []),
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.AVERAGE_RESPONSE_TIME}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'averageResponseTimeInMilliSeconds',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
            className='tooltip-container sm:block overflow-hidden'
          >
            {text ? msToTimeUnits(text) : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: { width: '120px' },
      heading: (
        <div className='flex row justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.LAST_CALL_DATE}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'lastCallDate',
      render: (text: any) => (
        <DDSTooltip
          id={`${text}`}
          label={
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark4}
            >
              {timeZone(
                store.userStore.getUserPreference().timeZone,
                text,
                timeFormats.fullMonthDateYearTimeWithSeconds
              )}
            </DDSTypography.Paragraph>
          }
          shape='roundedRectangle'
          position='top'
          className={`override-tooltip-arrow ${
            typeof text === 'string' && text.length > 20 ? text : '!hidden'
          }`}
          style={{
            backgroundColor: pallete.colors.surface1,
            zIndex: 999999
          }}
        >
          <div className='flex justify-center text-center row items-center'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container sm:block overflow-hidden max-w-[120px] truncate'
            >
              {timeZone(
                store.userStore.getUserPreference().timeZone,
                text,
                timeFormats.fullMonthDateYearTimeWithSeconds
              )}
            </DDSTypography.Paragraph>
          </div>
        </DDSTooltip>
      )
    },
    {
      heading: (
        <div className='flex row justify-center  text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.STATUS_CODE_GRAPH}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'statusCodeGraph',
      render: (text: any, fullItem: any) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <StatusCodeGraph
            width='144px'
            tableHeight={tableHeight}
            totalCount={totalCount}
            data={fullItem['statusCodeGraph']}
            color={color}
          />
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '20px' },
      columnDataStyle: { width: '20px' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='cursor-pointer'>
          <ChevronRight
            size={16}
            onClick={() => {
              gaEventTracker(gaEventConstants.VIEW_SINGLE_API_RECORD)
              routeGroupedDetails(fullItem.id)
            }}
            color={pallete.colors.FillButton}
          />
        </div>
      )
    }
  ]

  return (
    <>
      {loading || store.uiStore.getGlobalLoader() || !groupedApiTableData ? (
        <Spinner />
      ) : totalCount > 0 ? (
        <div className='py-[24px]' ref={tableRef}>
          <Table
            tableStyle={{
              borderColor: pallete.colors.stroke2
            }}
            data={groupedApiTableData}
            columns={Columns}
            totalCount={totalCount}
            rowsPerPage={10}
            currentPage={currentPage}
            onPageChange={(page: number) => {
              const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                location.search
              )
              setSearchParams({ ...allQueryParams, page: page.toString() })
              setCurrentPage(page)
            }}
            loading={loading}
          />
        </div>
      ) : (
        <>
          <NoRecord
            imageSrc={
              totalCount === 0 && store.filterStore.getFiltersApplied()
                ? NoFilterImage
                : NoRecordImage
            }
            style={{ height: '80vh' }}
            text={
              totalCount === 0 && store.filterStore.getFiltersApplied() ? (
                <>No results found for applied filter.</>
              ) : (
                <>
                  Configure API Monitoring.
                  <Link
                    href={StringConstants.FULLSTACK_URL}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ marginLeft: '4px' }}
                  >
                    Learn More
                  </Link>
                </>
              )
            }
          />
        </>
      )}
    </>
  )
}

export default observer(URLView)
