import ApiManager from '../Api.manager'
import HttpConstants from '../../constants/HttpConstants'
import { OrganizationUserRoles } from '../../enum/OrganizationUserRoles.enum'
import UrlConstants from '../../constants/UrlConstants'

export const sandboxUserEmailCapture = async (payload: {
  email: string
  role: OrganizationUserRoles
  projectType: any
  utm_source?: string | null
  utm_medium?: string | null
  utm_campaign?: string | null
  utm_content?: string | null
  utm_term?: string | null
}) => {
  const usecase = UrlConstants.SANDBOX_LEAD_EMAIL_CAPTURE.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCall(usecase, HttpConstants.POST_METHOD, payload)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        if (error.status === 400) {
          reject(error.data.error.message)
        } else if (error.status === 401) {
          reject(error.data.message)
        } else {
          reject(error.data)
        }
      })
  })
}
