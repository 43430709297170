import { AtoB, replaceUrl } from '../../utils/UrlUtils'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import PageLoadingOverlay from '../../components/common/PageLoadingOverlay/PageLoadingOverlay'
import SecureComponent from '../../components/Layout/SecureComponent'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../../components/common/DDS/Toast/Toast'
import { connectToSalesforceInstance } from '../../api/salesforceAudit/salesforceAudit.service'
import { observer } from 'mobx-react-lite'
import routeConstants from '../../constants/RouteConstants'
import { useStoreContext } from '../../store/StoreContext'

const SalesforceConnect = () => {
  const location = useLocation()
  const [loading, setLoading] = React.useState(false)
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)

  const { salesforceAuditStore } = useStoreContext()

  const [code] = React.useState(searchParams.get('code'))
  const [state] = React.useState(searchParams.get('state'))
  const connectSalesforce = () => {
    const headers = state ? JSON.parse(AtoB(state)) : {}
    setLoading(true)
    code &&
      connectToSalesforceInstance(
        decodeURIComponent(code),
        headers.instanceUrl,
        headers
      )
        .then((response: any) => {
          ToastNotification({
            type: 'success',
            message:
              ToastMessageConstants.SALESFORCE_AUDIT.CONNECT_TO_SALESFORCE
                .SUCCESS
          })
          salesforceAuditStore.setAuditData(response.data.auditData)
          navigate(
            replaceUrl(
              routeConstants.SALESFORCE_AUDIT_DASHBOARD,
              headers.organizationId as string,
              headers.projectId as string,
              headers.environmentId as string
            )
          )
        })
        .catch((err: string) => {
          ToastNotification({
            type: 'error',
            message:
              ToastMessageConstants.SALESFORCE_AUDIT.CONNECT_TO_SALESFORCE.ERROR
          })
          navigate(
            replaceUrl(
              routeConstants.SALESFORCE_AUDIT_DASHBOARD,
              headers.organizationId as string,
              headers.projectId as string,
              headers.environmentId as string
            )
          )
        })
        .finally(() => setLoading(false))
  }

  useEffect(() => {
    connectSalesforce()
  }, [])

  return (
    <PageLoadingOverlay loadingTitle='Please do not hit refresh button while we fetch data from Salesforce' />
  )
}

export default SecureComponent(
  observer(SalesforceConnect),
  AccessRoleConstants.MEMBER_LEVEL
)
