import { createSearchParams, useNavigate, useParams } from 'react-router-dom'
import { formatMonth, timeZone } from '../../utils/DateTimeUtils'

import { DDSTypography } from 'den-design-system'
import DocumentHeader from '../Helmet/DocumentHeader'
import { HeaderConstants } from '../../constants/HeaderConstants'
import LinkCard from './LinkCard'
import { ProjectType } from '../../enum/ProjectType.enum'
import React from 'react'
import Spinner from '../common/Spinner/Spinner'
import StringConstants from '../../constants/StringConstants'
import moment from 'moment'
import pallete from '../../global/newPallete'
import { replaceUrl } from '../../utils/UrlUtils'
import routeConstants from '../../constants/RouteConstants'
import { timeFormats } from '../../enum/TIME'
import { useStoreContext } from '../../store/StoreContext'

interface DetailsProps {
  details: {
    priority: string
    identifiedOn: string
    longDescription: string
    exceptionLogId: any
    links: string[]
    exceptionType?: string
  }
  loading: boolean
  deleteLink: any
}

const priorityColorMap: Record<string, string> = {
  P0: pallete.colors.warningRed1,
  P1: pallete.colors.warningRed2,
  P2: pallete.colors.warningRed3,
  P3: pallete.colors.warningBlue
}
const Details: React.FC<DetailsProps> = ({ details, deleteLink, loading }) => {
  const store = useStoreContext()
  const navigate = useNavigate()
  const { id } = useParams()

  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY

  const routeExceptionLog = (id: number, type: string) => {
    store.exceptionStore.setId(id.toString())
    store.exceptionStore.setType(type)
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.EXCEPTION
    )
    const route = replaceUrl(
      routeConstants.ERROR_DETAILS,
      store.scopeStore.getScope()['organization-id'] as string,
      store.scopeStore.getScope()['project-id'] as string,
      store.scopeStore.getScope()['environment-id'] as string
    ).replace(':id', String(id))
    navigate(route)
  }

  return (
    <>
      {loading || store.uiStore.getGlobalLoader() ? (
        <Spinner />
      ) : (
        <div className='flex flex-col w-full h-[100%] py-[24px] gap-y-[32px]'>
          <div className='flex flex-row justify-between w-full'>
            <div className='flex flex-row gap-x-[8px] items-center custom-label'>
              <DDSTypography.Title
                type='h5'
                variant='semiBold'
                className='custom-label'
                color={pallete.colors.textDark3}
              >
                Priority :
              </DDSTypography.Title>
              <div
                className='rounded-[100px] py-[4px] px-[8px] custom-label w-[60px]'
                style={{ backgroundColor: priorityColorMap[details.priority] }}
              >
                <DDSTypography.Paragraph
                  variant='medium'
                  size='caption'
                  className='text-center whitespace-nowrap flex justify-center'
                  color={pallete.colors.white}
                >
                  {details?.priority}
                </DDSTypography.Paragraph>
              </div>
            </div>
            <div className='flex flex-row justify-center items-baseline'>
              <DDSTypography.Title
                type='h5'
                variant='semiBold'
                className='custom-label whitespace-nowrap'
                color={pallete.colors.textDark3}
              >
                Identified on :
              </DDSTypography.Title>
              <DDSTypography.Paragraph
                size='para'
                className='custom-label whitespace-nowrap pl-[4px]'
                color={pallete.colors.textDark5}
              >
                {moment(details?.identifiedOn)
                  .tz(store.userStore.getUserPreference().timeZone)
                  .format(timeFormats.fullMonthDateYear)
                  .replace(/^(\w+)/, (match) => match.slice(0, 3))}
              </DDSTypography.Paragraph>
            </div>
          </div>
          {details?.exceptionLogId && (
            <div className='flex flex-col gap-y-[8px]'>
              <DDSTypography.Title
                type='h5'
                variant='semiBold'
                color={pallete.colors.textDark3}
                className='custom-label'
              >
                {isFullStackProject ? 'Exception' : 'Error'}
              </DDSTypography.Title>
              <a
                onClick={() => {
                  routeExceptionLog(
                    details.exceptionLogId,
                    details.exceptionLogId as string
                  )
                }}
                style={{ color: pallete.colors.primary2 }}
              >
                <div className='cursor-pointer'>
                  <DDSTypography.Paragraph
                    variant='regular'
                    size='para'
                    className='underline'
                    color={pallete.colors.primary2}
                  >
                    {details?.exceptionType}
                  </DDSTypography.Paragraph>
                </div>
              </a>
            </div>
          )}
          <div className='flex flex-col gap-y-[8px]'>
            <DDSTypography.Title
              type='h5'
              variant='semiBold'
              color={pallete.colors.textDark3}
              className='custom-label'
            >
              Long Description
            </DDSTypography.Title>
            <DDSTypography.Paragraph
              variant='regular'
              size='para'
              color={pallete.colors.textDark5}
              style={{
                overflowWrap: 'break-word'
              }}
            >
              {details?.longDescription}
            </DDSTypography.Paragraph>
          </div>
          {details.links?.length !== 0 && (
            <div className='flex flex-col gap-y-[8px]'>
              <DDSTypography.Title
                type='h5'
                variant='semiBold'
                color={pallete.colors.textDark3}
                className='custom-label'
              >
                Reference Link
              </DDSTypography.Title>
              {details?.links.map((link, index) => (
                <LinkCard key={index} link={link} onClose={deleteLink} />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default Details
