import { BiCaretDown as DownArrow, BiCaretUp as UpArrow } from 'react-icons/bi'
import React, {
  KeyboardEvent,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'

import LinearProgressBar from '../ProgressBar/ProgressBar'
import { RefObject } from '../InputField/Interface/Interface'
import SearchField from '../InputField/SearchField'
import { TypographyDDS } from '../Typography/TypographyDDS'
import breakpoints from '../../../global/breakpoints'
import { css } from '@emotion/react'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

interface DropdownProps extends ContainerProps, WrapperProps {
  title: string
  secondaryTitleText?: string
  selectedOptionFontSize?: string
  dropdownOptions: {
    id: string | number
    secondaryText?: string
    value?: string | number
    onClickFunction?: () => void
    name?: string
    //this prop can be added, if we want to add icons in dropdown options
    icon?: JSX.Element | string
  }[]
  buttonOnClick?: () => void
  button?: boolean
  handleChange?: (value: any) => void
  onBlur?: () => void
  defaultSelected?: any
  disabled?: boolean
  minWidth?: string
  DownArrowIcon?: JSX.Element
  UpArrowIcon?: JSX.Element
  error?: boolean
  helperText?: string
  isSearchable?: boolean
  ignoreTopNavBar?: boolean
  organizationTypeDropdown?: boolean
  hideTooltip?: boolean
  id?: string
  destroy?: boolean
}

interface ContainerProps {
  width?: string
  padding?: string
  borderRadius?: string
  zIndex?: number
  variant?: 'default' | 'secondary'
  open?: boolean
  maxWidth?: string
  minWidth?: string
}

interface WrapperProps {
  height?: string
}

const Container = styled.div<ContainerProps>`
  position: relative;
  padding: ${(props) => (props.padding ? props.padding : '8px 12px 8px 12px')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '8px'};
  z-index: ${({ zIndex }) => zIndex};
  ${(props) => {
    switch (props.variant) {
      case 'secondary':
        return css`
          min-width: ${props.minWidth ? props.minWidth : '120px'};
          width: ${props.width ? props.width : '100%'};
          max-width: ${props.maxWidth};
          background-color: ${props.open ? palette.colors.lightBack3 : ''};

          border: ${props.open
            ? `1px solid ${palette.colors.borderColor}`
            : `1px solid ${palette.colors.white}`};
          border-bottom: 0px;
          border-radius: ${props.open ? '8px 8px 0px 0px' : '8px'};
        `

      default:
        return css`
          min-width: ${props.minWidth ? props.minWidth : '200px'};
          width: ${props.width ? props.width : '100%'};
          background-color: ${props.open
            ? palette.colors.backgroundLight
            : palette.colors.lightBack};
          &: hover {
            background-color: ${palette.colors.backgroundLight};
          }
          border: 1px solid ${palette.colors.borderColor};
          border-bottom: ${props.open
            ? '0px'
            : `1px solid ${palette.colors.borderColor}`};
          border-radius: ${props.open ? '8px 8px 0px 0px' : '8px'};
        `
    }
  }};
`

const styles = {
  IconStyles: {
    color: `${palette.colors.primary4}`,
    minWidth: '20px'
  },
  TextStyles: {
    fontSize: '14px',
    fontWeight: '500',
    color: `${palette.colors.textDark2}`,
    marginBottom: '4px'
  }
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 4px;
  margin-bottom: 0px;
  height: ${(props) => props.height};
  cursor: pointer;
`
const TextContainer = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 40px;
  overflow: scroll;
`

const DropDownItem = styled.div`
  border-top: 1px solid ${palette.colors.borderColor};
  margin: 0px 0px;
  padding: 12px 8px;
  cursor: pointer;
  &:hover {
    background-color: ${palette.colors.borderColor};
    border-radius: 8px;
  }
`

const DropdownMenu = styled.div<ContainerProps>`
  position: absolute;
  width: calc(100% + 2px);
  z-index: 1;
  padding: 0px 12px 8px 12px;
  left: -1px;
  margin-top: 8px;
  max-height: 260px;
  overflow-x: auto;
  border-radius: ${(props) => (props.open ? '0px 0px 8px 8px' : 'null')};
  ${(props) => {
    switch (props.variant) {
      case 'secondary':
        return css`
          background-color: ${props.open
            ? `${palette.colors.lightBack3}`
            : `${palette.colors.white}`};
          border: ${props.open
            ? `1px solid ${palette.colors.borderColor}`
            : `1px solid ${palette.colors.white}`};
          border-top: 1px solid ${palette.colors.white};
        `

      default:
        return css`
          background-color: ${palette.colors.lightBack3};
          border: 1px solid ${palette.colors.borderColor};
          border-top: 0px;
          box-shadow: 0px 4px 25px rgba(71, 78, 93, 0.05);
        `
    }
  }};
  .button {
    display: flex;
    justify-content: center;
  }
`

const SelectedWrapper = styled.div`
  overflow: hidden;
`
const HelperTextWrapper = styled.div`
  padding: 0px 14px;
  margin-top: 3px;
  margin-bottom: 0px;
  @media screen and (max-width: ${breakpoints.xmd}) {
    padding: 0px;
  }
`
const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
`

const Dropdown = React.forwardRef<RefObject, DropdownProps>(
  (
    {
      width,
      maxWidth,
      padding,
      height,
      borderRadius,
      zIndex = 10,
      dropdownOptions,
      title,
      secondaryTitleText,
      variant,
      button,
      handleChange,
      selectedOptionFontSize = '14px',
      defaultSelected,
      disabled,
      minWidth,
      DownArrowIcon,
      UpArrowIcon,
      error,
      helperText,
      isSearchable,
      organizationTypeDropdown = false,
      hideTooltip = false,
      ignoreTopNavBar = false,
      id,
      destroy
    },
    ref
  ) => {
    const [toggle, setToggle] = React.useState(false)
    const [selectedOption, setSelectedOption] = React.useState<{
      option?: string
      icon?: JSX.Element | string
    }>({
      option: defaultSelected
        ? defaultSelected?.name
        : secondaryTitleText
          ? secondaryTitleText
          : undefined,
      icon: defaultSelected ? defaultSelected?.icon : undefined
    })
    const [loading, setLoading] = useState<boolean>(false)
    const [searchData, setSearchData] = useState('')

    useImperativeHandle(ref, () => ({ startLoading, stopLoading }))
    const startLoading = () => {
      setLoading(true)
    }
    const stopLoading = () => {
      setLoading(false)
    }
    const dropdownOptionChangeHandler = (selectedOption: any) => {
      setSelectedOption({
        ...selectedOption,
        option: selectedOption?.name,
        icon: selectedOption?.icon
      })
    }
    useEffect(() => {
      setSelectedOption({
        ...selectedOption,
        option: defaultSelected?.name,
        icon: defaultSelected?.icon
      })
    }, [defaultSelected])

    useEffect(() => {
      setSearchData('')
    }, [toggle])

    useEffect(() => {
      if (destroy) setSelectedOption({})
    }, [destroy])

    const getDropdownValue = (item: any) => {
      return item?.name ?? ''
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Container
          width={width}
          maxWidth={maxWidth}
          padding={padding}
          borderRadius={borderRadius}
          zIndex={zIndex}
          open={toggle}
          variant={variant}
          tabIndex={0}
          onBlur={(e) => {
            if (ignoreTopNavBar) {
              setToggle(false)
            } else {
              !e.relatedTarget && setToggle(false)
            }
          }}
          minWidth={minWidth}
          id={id}
        >
          <Wrapper
            height={height}
            onClick={() =>
              dropdownOptions.length > 0 &&
              !(disabled || loading) &&
              setToggle(!toggle)
            }
            style={{ cursor: disabled || loading ? 'not-allowed' : 'pointer' }}
          >
            <TextContainer>
              <TypographyDDS.Paragraph
                size='caption'
                color={error ? 'error' : 'textDark2'}
                style={{
                  maxWidth: 'calc(133% - 24px)',
                  position: 'absolute',
                  top: '9px'
                  // transform: 'translate(12px, 7px) scale(0.75)'
                }}
              >
                {title}
              </TypographyDDS.Paragraph>
              <SelectedWrapper>
                <p
                  style={{
                    ...styles.TextStyles,
                    color:
                      selectedOption.option !== secondaryTitleText
                        ? palette.colors.textDark2
                        : palette.colors.textDark4,
                    fontSize:
                      selectedOption.option !== secondaryTitleText
                        ? selectedOptionFontSize
                        : styles.TextStyles.fontSize,
                    fontWeight:
                      selectedOption.option !== secondaryTitleText
                        ? '600'
                        : styles.TextStyles.fontWeight,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginTop: '17px',
                    marginBottom: 0
                  }}
                >
                  <OptionWrapper>
                    {selectedOption.option}
                    {selectedOption?.icon}
                  </OptionWrapper>
                </p>
              </SelectedWrapper>
            </TextContainer>

            <span
              style={{
                cursor: disabled || loading ? 'not-allowed' : 'pointer',
                visibility: dropdownOptions?.length > 0 ? 'visible' : 'hidden'
              }}
            >
              {toggle ? (
                UpArrowIcon ? (
                  <div style={styles.IconStyles}>{UpArrowIcon}</div>
                ) : (
                  <UpArrow style={styles.IconStyles} />
                )
              ) : DownArrowIcon ? (
                <div style={styles.IconStyles}>{DownArrowIcon}</div>
              ) : (
                <DownArrow style={styles.IconStyles} />
              )}
            </span>
          </Wrapper>

          {toggle && (
            <DropdownMenu
              padding={padding}
              borderRadius={borderRadius}
              zIndex={zIndex}
              variant={variant}
              open={toggle}
              style={isSearchable ? { display: 'inline-block' } : {}}
            >
              {isSearchable && (
                <SearchField
                  onChange={(e: any) => {
                    e.stopPropagation()
                    setSearchData(e.target.value.toLowerCase())
                  }}
                  onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    e.stopPropagation()
                  }}
                  style={{
                    position: 'sticky',
                    display: 'block',
                    top: 0,
                    marginTop: 0,
                    width: '100%'
                  }}
                />
              )}
              <div>
                {dropdownOptions
                  .filter((search) => {
                    if (searchData == '') {
                      return search
                    } else if (
                      search.name?.toLowerCase().includes(searchData)
                    ) {
                      return search
                    }
                  })
                  .map((item: any, index: any) => {
                    return (
                      <DropDownItem
                        key={index + 1}
                        onClick={() => {
                          setToggle(false)
                          dropdownOptionChangeHandler(item)
                          item.onClickFunction && item.onClickFunction()
                          handleChange && handleChange(item.id as string)
                        }}
                      >
                        {(item?.name || item?.icon) && (
                          <p style={styles.TextStyles}>
                            <OptionWrapper>
                              {getDropdownValue(item)} {item?.icon}{' '}
                            </OptionWrapper>
                          </p>
                        )}

                        {/* {item.name && (
                            <p style={styles.TextStyles}>{item.name}</p>
                          )} */}
                      </DropDownItem>
                    )
                  })}
                {/* {variant === 'secondary' && button && (
                  <div className='button'>
                    <Button textColor={palette.colors.checkBoxColor}>
                      + Environment
                    </Button>
                  </div>
                )} */}
              </div>
            </DropdownMenu>
          )}
        </Container>
        <HelperTextWrapper>
          <TypographyDDS.Paragraph
            size='caption'
            color={error ? 'error' : 'textDark'}
          >
            {helperText}
          </TypographyDDS.Paragraph>
        </HelperTextWrapper>
        {loading && <LinearProgressBar />}
      </div>
    )
  }
)
Dropdown.displayName = 'Dropdown'
export default Dropdown
