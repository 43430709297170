import React, { useEffect, useState } from 'react'
import {
  addHealthCheck,
  getApiHealth,
  updateHealthCheck
} from '../../../api/appHealthCheck/AHC.service'
import { isEmpty, isNumber } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'

import AlertSettingsStep from './AlertSettingsStep'
import AuthenticationStep from './AuthenticationStep'
import BasicInfoStep from './BasicInfoStep'
import CustomErrorToast from '../../common/Toast/CustomErrorToast'
import CustomHeaderStep from './CustomHeaderStep'
import { DDSToastNotification } from 'den-design-system'
import EditIcon from '../../../assets/icons/Edit.svg'
import { EventBus } from '../../../eventBus/EventBus'
import Popup from '../../common/Popup/Popup'
import { ProjectType } from '../../../enum/ProjectType.enum'
import Spinner from '../../common/Spinner/Spinner'
import TabGroup from '../../common/TabGroup/TabGroup'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import breakpoints from '../../../global/breakpoints'
import { getOrganizationProjectsThingsToDo } from '../../../api/organization/Organization.service'
import { observer } from 'mobx-react-lite'
import palette from '../../../global/pallete'
import plusIcon from '../../../../src/assets/icons/plus.svg'
import { projectMemberLookUp } from '../../../api/project/ProjectMember.service'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import { useStoreContext } from '../../../store/StoreContext'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.9rem;
  }
`
const AddCheckPopup: React.FC<{
  handleClose: () => void
  handleSave?: (data: any) => void
  open: boolean
  fromThingsToDo?: boolean
  onComplete?: () => void
}> = ({
  handleClose,
  open,
  handleSave = (data: any) => console.log(data),
  onComplete,
  fromThingsToDo
}) => {
  const { addCheckStore, ahcReportsStore, ahcLogsStore } = useStoreContext()
  const tabLabels = [
    { label: 'Primary Info' },
    { label: 'Alert Settings' },
    { label: 'Authentication' },
    { label: 'Http Headers' }
    // { label: 'Location & APDEX' },
  ]
  const [popupHeight, setPopupHeight] = useState('auto')
  const [currentStep, setCurrentStep] = useState<boolean | number>(false)
  const [formState, setFormState] = useState({
    url: '',
    elementId: '',
    checkName: '',
    checkInterval: 15,
    checkContent: false
  })
  const initialFormState = {
    url: '',
    elementId: '',
    checkName: '',
    checkInterval: 15,
    checkContent: false
  }

  const { pathname } = useLocation()
  const navigate = useNavigate()
  const goBack = () => {
    ahcLogsStore.setPage(1)
    const segments = pathname.split('/').filter((s) => s.length !== 0)
    segments.pop()
    const path = '/' + segments.join('/')
    ahcReportsStore.setActiveReport(null)
    navigate(path)
  }

  const handleNextStep = async (data: any) => {
    if (isNumber(currentStep) && currentStep < 3)
      setCurrentStep((currentStep as number) + 1)
    else {
      addCheckStore.setLoading(true)

      const $ahcId = addCheckStore.getData().id
      try {
        if (addCheckStore.editMode && $ahcId) {
          await updateHealthCheck({
            ...addCheckStore.getData(),
            $ahcId,
            alertCandidates: addCheckStore
              .getData()
              .alertCandidates?.map((candidate: any) =>
                candidate.projectMemberId
                  ? candidate.projectMemberId
                  : candidate.id
              )
          })

          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.AHC.UPDATE_CHECKS.SUCCESS
          })
          EventBus.getInstance().dispatch<string>('fetch-health-check-reports')
        } else {
          await addHealthCheck({
            ...addCheckStore.getData(),
            alertCandidates: addCheckStore
              .getData()
              .alertCandidates.map((candidate: any) => candidate.id)
          })
          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.AHC.CREATE_AHC.SUCCESS
          })
          await getOrganizationProjectsThingsToDo()
            .then((response: any) => {
              store.thingsToDoStore.setProgressData(response.data)
              if (
                (response?.data?.progress == 3 &&
                  (store.scopeStore.getSelectedProject().type ==
                    ProjectType.SALESFORCE_ONLY ||
                    store.scopeStore.getSelectedProject().type ==
                      ProjectType.MULESOFT_ONLY)) ||
                (response?.data?.progress == 5 &&
                  store.scopeStore.getSelectedProject().type ==
                    ProjectType.FULL_STACK_ONLY)
              ) {
                store.thingsToDoStore.setShowThingsToDo(false)
              } else {
                store.thingsToDoStore.setShowThingsToDo(true)
              }
            })
            .catch((error) => console.log(error))
            .finally(() => {
              store.uiStore.setGlobalLoader(false)
            })
        }
        onComplete ? onComplete() : goBack()
      } catch (e: any) {
        if (addCheckStore.editMode)
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.AHC.UPDATE_CHECKS.ERROR
          })
        else
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.AHC.CREATE_AHC.ERROR
          })
      }

      addCheckStore.setLoading(false)
      addCheckStore.resetData()
      setFormState(initialFormState)
      addCheckStore.setEditMode(false)
      setCurrentStep(0)
      // EventBus.getInstance().dispatch<string>('fetch-health-check-reports')
      handleClose()
    }
  }

  const handleBack = () => {
    setCurrentStep((currentStep as number) - 1)
  }

  const renderHeaderIcon = () =>
    addCheckStore.editMode ? (
      <img
        src={EditIcon}
        height='18px'
        width='18px'
        style={{ filter: palette.colors.primarySvgFilter }}
      />
    ) : (
      <img
        src={plusIcon}
        height='12px'
        width='12px'
        style={{ filter: palette.colors.primarySvgFilter }}
      />
    )

  const renderStep = () => {
    switch (currentStep) {
      case -1:
        return <Spinner />
      case 0:
        return (
          <BasicInfoStep
            handleNext={handleNextStep}
            formState={formState}
            setFormState={setFormState}
            fromThingsToDo={fromThingsToDo}
          />
        )
      case 1:
        return (
          <AlertSettingsStep
            handleNext={handleNextStep}
            handleBack={handleBack}
          />
        )
      case 2:
        return (
          <AuthenticationStep
            handleNext={handleNextStep}
            handleBack={handleBack}
          />
        )
      case 3:
        return (
          <CustomHeaderStep
            handleNext={handleNextStep}
            handleBack={handleBack}
          />
        )
      // case 4:
      //   return (
      //     <LocationAndApdexStep
      //       handleNext={(data: any) => {
      //         handleNextStep(data)
      //         handleSave(checksData)
      //       }}
      //       handleBack={handleBack}
      //     />
      //   )
    }
  }

  useEffect(() => {
    if (open) {
      ahcReportsStore.setLoading(true)
      if (addCheckStore.editMode) {
        setCurrentStep(-1)
        getApiHealth({
          $ahcId: addCheckStore.getData()?.id as string
        })
          .then((response: any) => {
            addCheckStore.setData(response.data)
            setCurrentStep(0)
          })
          .catch((err: any) => {
            addCheckStore.resetData()
            addCheckStore.setEditMode(false)
            handleClose()
            setFormState(initialFormState)
            setCurrentStep(false)
            ToastNotification({
              type: 'error',
              message: ToastMessageConstants.AHC.FETCH_REPORT_DETAILS.ERROR
            })
          })
          .finally(() => {
            ahcReportsStore.setLoading(false)
          })
      } else {
        setCurrentStep(0)
        projectMemberLookUp({
          'name-or-email': store.userStore.getEmail(),
          self: true
        })
          .then((response: any) => {
            if (!isEmpty(response.data)) {
              store.addCheckStore.setUserCandidate({
                id: response.data[0].id as string,
                name: `${response.data[0].user.firstName} ${response.data[0].user.lastName}` as string,
                role: response.data[0].role as string,
                email: response.data[0].user.email as string
              })
              store.addCheckStore.setData({
                alertCandidates: [
                  {
                    id: response.data[0].id as string,
                    role: response.data[0].role as string,
                    user: {
                      firstName: response.data[0].user.firstName,
                      lastName: response.data[0].user.lastName,
                      email: response.data[0].user.email as string
                    }
                  }
                ]
              })
            }
          })
          .catch((error: any) => {
            ToastNotification({
              type: 'error',
              message: ToastMessageConstants.AHC.FETCH_USER_LOOKUP.ERROR
            })
          })
          .finally(() => {
            ahcReportsStore.setLoading(false)
          })
      }
      if (popupHeight !== 'auto') {
        setPopupHeight('auto')
      }
    }
  }, [open])

  const store = useStoreContext()

  return (
    <Popup
      open={open}
      handleClose={() => {
        addCheckStore.resetData()
        addCheckStore.setEditMode(false)
        handleClose()
        setFormState(initialFormState)
        setCurrentStep(false)
        store.projectAndEnvironmentStore.setEnvironmentName('')
      }}
      headerText={addCheckStore.getEditMode() ? 'Update Check' : 'Add Check'}
      headerIcon={renderHeaderIcon()}
      height={popupHeight}
      destroyOnClose
      disabled={addCheckStore.loading}
    >
      <Container>
        <TabGroup
          currentTab={currentStep as number}
          onChange={(e, tab) => null}
          tabLabels={tabLabels}
          $disableSwitch
        />
        {renderStep()}
      </Container>
    </Popup>
  )
}

export default observer(AddCheckPopup)
