import { Grid, Stack, Tooltip } from '@mui/material'
import { groupBy, isEmpty } from 'lodash'

import APIUsageCard from '../../components/SalesforceAudit/APIUsageCard'
import FileStorageCard from '../../components/SalesforceAudit/FileStorageCard'
import LicenseDistributionCard from '../../components/SalesforceAudit/LicenseDistributionCard'
import MetadataCard from '../../components/SalesforceAudit/MetadataCard'
import React from 'react'
import Spinner from '../../components/common/Spinner/Spinner'
import { TypographyDDS } from '../../components/common/Typography/TypographyDDS'
import arrowRightIcon from '../../assets/icons/arrowRight.svg'
import { ellipsis } from '../../utils/CommonUtils/Ellipsis'
import { observer } from 'mobx-react-lite'
import { replaceUrl } from '../../utils/UrlUtils'
import routeConstants from '../../constants/RouteConstants'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../store/StoreContext'

interface SectionTitleProps {
  title: string
  link?: string
  info?: string
  alignSelf?: string
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  link,
  info,
  alignSelf = 'auto'
}) => {
  const navigate = useNavigate()
  const { scopeStore } = useStoreContext()
  const handleNavigation = (link: string) => {
    navigate(
      replaceUrl(
        link,
        scopeStore.getScope()['organization-id'] as string,
        scopeStore.getScope()['project-id'] as string,
        scopeStore.getScope()['environment-id'] as string
      )
    )
  }
  return (
    <Stack>
      <Stack
        direction='row'
        alignItems='center'
        alignSelf={alignSelf}
        style={{ cursor: link ? 'pointer' : 'auto' }}
        onClick={() => {
          link && handleNavigation(link)
        }}
      >
        <TypographyDDS.Title type='h3' variant='bold'>
          {title}
        </TypographyDDS.Title>
        {link && (
          <img
            src={arrowRightIcon}
            style={{ height: '1.5em', marginLeft: '8px' }}
          />
        )}
      </Stack>
      {info && info.length > 45 ? (
        <Tooltip title={info || ''} placement='left' arrow>
          <div>
            <TypographyDDS.Paragraph size='para' color='lightGrey2'>
              {info ? ellipsis(info, 45) : info}
            </TypographyDDS.Paragraph>
          </div>
        </Tooltip>
      ) : (
        <TypographyDDS.Paragraph size='para' color='lightGrey2'>
          {info}
        </TypographyDDS.Paragraph>
      )}
    </Stack>
  )
}
const SalesforceAuditDashboard: React.FC = () => {
  const { salesforceAuditStore, scopeStore } = useStoreContext()
  const auditData = salesforceAuditStore.getAuditData()
  const totalLicenses = salesforceAuditStore.getTotalLicenses()
  const usedLicenses = salesforceAuditStore.getUsedLicenses()
  const mostCommonLicense = salesforceAuditStore.getMostCommonLicense()
  const distributions = salesforceAuditStore.getLicenseDistribution()
  const limits = salesforceAuditStore.getLimitsData()
  const users = salesforceAuditStore.getUsersData()
  const loading = salesforceAuditStore.getLoading()
  const groupedLimits: any = groupBy(limits, 'name')

  return loading || isEmpty(auditData) ? (
    <Spinner />
  ) : (
    <Grid
      container
      width='80%'
      spacing={2}
      alignItems={'center'}
      paddingTop='16px'
    >
      {/* APIs */}

      <Grid item xs={4}>
        <SectionTitle title='APIs' info='API usage resets every 24hrs' />
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={4} container justifyContent='flex-end'>
        <SectionTitle
          title='Salesforce Instance URL'
          info={auditData.instanceUrl}
          alignSelf='flex-end'
        />
      </Grid>
      <Grid item xs={6}>
        <APIUsageCard
          title={'API'}
          usage={
            +(
              (1 -
                groupedLimits['DailyApiRequests'][0].remaining /
                  groupedLimits['DailyApiRequests'][0].maximum) *
              100
            ).toFixed(2)
          }
          requests={{
            title: 'Total Requests',
            value:
              groupedLimits['DailyApiRequests'][0].maximum -
              groupedLimits['DailyApiRequests'][0].remaining
          }}
          limit={{
            title: 'Daily Limit',
            value: groupedLimits['DailyApiRequests'][0].maximum
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <APIUsageCard
          title={'Streaming API Events'}
          usage={
            +(
              (1 -
                groupedLimits['DailyStreamingApiEvents'][0].remaining /
                  groupedLimits['DailyStreamingApiEvents'][0].maximum) *
              100
            ).toFixed(2)
          }
          requests={{
            title: 'Event Request',
            value:
              groupedLimits['DailyStreamingApiEvents'][0].maximum -
              groupedLimits['DailyStreamingApiEvents'][0].remaining
          }}
          limit={{
            title: 'Daily Limit',
            value: groupedLimits['DailyStreamingApiEvents'][0].maximum
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <APIUsageCard
          title={'Bulk API batches'}
          usage={
            +(
              (1 -
                groupedLimits['DailyBulkApiBatches'][0].remaining /
                  groupedLimits['DailyBulkApiBatches'][0].maximum) *
              100
            ).toFixed(2)
          }
          requests={{
            title: 'Batches Requested',
            value:
              groupedLimits['DailyBulkApiBatches'][0].maximum -
              groupedLimits['DailyBulkApiBatches'][0].remaining
          }}
          limit={{
            title: 'Daily Limit',
            value: groupedLimits['DailyBulkApiBatches'][0].maximum
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <APIUsageCard
          title={'Functions API Calls'}
          usage={
            +(
              (1 -
                groupedLimits['DailyFunctionsApiCallLimit'][0].remaining /
                  groupedLimits['DailyFunctionsApiCallLimit'][0].maximum) *
              100
            ).toFixed(2)
          }
          requests={{
            title: 'Total Calls',
            value:
              groupedLimits['DailyFunctionsApiCallLimit'][0].maximum -
              groupedLimits['DailyFunctionsApiCallLimit'][0].remaining
          }}
          limit={{
            title: 'Daily Limit',
            value: groupedLimits['DailyFunctionsApiCallLimit'][0].maximum
          }}
        />
      </Grid>
      {/* Metadata */}
      <Grid item xs={12}>
        <SectionTitle
          title='Metadata'
          link={routeConstants.SALESFORCE_AUDIT_METADATA_OVERVIEW}
        />
      </Grid>
      <Grid item xs={3}>
        <MetadataCard
          title='Apex Classes'
          total={{
            title: 'Total Classes',
            value: auditData.apexClasses.length
          }}
          link={
            routeConstants.SALESFORCE_AUDIT_METADATA_OVERVIEW + '/apex-classes'
          }
        />
      </Grid>
      <Grid item xs={3}>
        <MetadataCard
          title='Apex Triggers'
          total={{
            title: 'Total Triggers',
            value: auditData.apexTriggers.length
          }}
          link={
            routeConstants.SALESFORCE_AUDIT_METADATA_OVERVIEW + '/apex-triggers'
          }
        />
      </Grid>
      <Grid item xs={3}>
        <MetadataCard
          title='Permission Sets'
          total={{
            title: 'Total Sets',
            value: auditData.permissionSets.length
          }}
          link={
            routeConstants.SALESFORCE_AUDIT_METADATA_OVERVIEW +
            '/permission-sets'
          }
        />
      </Grid>
      <Grid item xs={3}>
        <MetadataCard
          title='Profiles'
          total={{
            title: 'Total Profiles',
            value: auditData.profiles.length
          }}
          link={routeConstants.SALESFORCE_AUDIT_METADATA_OVERVIEW + '/profiles'}
        />
      </Grid>
      {/* Licenses */}
      <Grid item xs={12}>
        <SectionTitle
          title='Licenses'
          link={routeConstants.SALESFORCE_AUDIT_LICENSES_OVERVIEW}
        />
      </Grid>
      <Grid item xs={12}>
        <LicenseDistributionCard
          usage={(usedLicenses / totalLicenses) * 100}
          mostCommonLicense={mostCommonLicense?.Name || ''}
          totalLicenses={totalLicenses}
          usedLicenses={usedLicenses}
          distributions={distributions}
        />
      </Grid>
      {/* Users */}
      <Grid item xs={6}>
        <SectionTitle
          title='Users'
          link={routeConstants.SALESFORCE_AUDIT_USERS_OVERVIEW}
        />
      </Grid>
      <Grid item xs={6}>
        <SectionTitle title='File Storage' />
      </Grid>
      <Grid item xs={6}>
        <MetadataCard
          title='Users'
          total={{ title: 'Total Users', value: users.length }}
          limit={{
            title: 'Active',
            value: salesforceAuditStore.getActiveUsersCount()
          }}
        />
      </Grid>
      {/* File Storage */}

      <Grid item xs={6}>
        <FileStorageCard
          usage={
            +(
              (1 -
                (groupedLimits['FileStorageMB'][0].maximum -
                  +salesforceAuditStore.getUsedFileStorage().toFixed(2)) /
                  groupedLimits['FileStorageMB'][0].maximum) *
              100
            ).toFixed(2)
          }
          totalStorageInMB={groupedLimits['FileStorageMB'][0].maximum}
          availableStorageInMB={
            groupedLimits['FileStorageMB'][0].maximum -
            +salesforceAuditStore.getUsedFileStorage().toFixed(2)
          }
          totalFiles={auditData.files.length}
        />
      </Grid>
    </Grid>
  )
}

export default observer(SalesforceAuditDashboard)
