import { Buffer } from 'buffer'

export const getAddToSlackUrl = (organizationId: string, projectId: string) => {
  let url =
    process.env.REACT_APP_SLACK_INTEGRATION_URL +
    Buffer.from(
      JSON.stringify({
        pid: projectId,
        oid: organizationId
      })
    ).toString('base64')

  if (process.env.REACT_APP_SLACK_REDIRECT_URI)
    url += '&redirect_uri=' + process.env.REACT_APP_SLACK_REDIRECT_URI

  return url
}
