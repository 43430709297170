import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import HttpConstants from '../../constants/HttpConstants'
import { addQueryParamsToUrl } from '../../utils/UrlGenerator'
import { isAuthenticatedUser } from '../../utils/AuthUtils'
import { replaceBadgeUrl } from '../../utils/UrlUtils'
import routeConstants from '../../constants/RouteConstants'

export default (Page: React.ComponentType<any>) => {
  const UnsecureComponent = (props: any) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    useEffect(() => {
      const isUserLoggedIn: boolean = isAuthenticatedUser()
      if (isUserLoggedIn) {
        let url = routeConstants.REDIRECT
        // Add referrer when user redirects from sign in, sign up and home pages
        if (
          [
            routeConstants.SIGN_IN,
            routeConstants.SIGN_UP,
            routeConstants.HOME,
          ].includes(pathname)
        ) {
          url = addQueryParamsToUrl(
            url,
            {
              referrer: pathname,
            },
            HttpConstants.GET_METHOD
          )
        } else {
          const segments = pathname.split('/').filter((s) => s.length !== 0)
          const id: any = segments.pop()
          const replacedUrl = replaceBadgeUrl(routeConstants.AHC_BADGE, id)
          if (replacedUrl === pathname) url = pathname
        }
        navigate(url)
      }
    }, [])
    return <Page />
  }
  return UnsecureComponent
}
