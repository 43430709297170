import { Add, Information, TrashCan } from '@carbon/icons-react'
import { DDSTable, DDSTooltip, DDSTypography } from 'den-design-system'
import Joi, { ValidationResult } from 'joi'
import React, { useEffect, useState } from 'react'
import {
  findEnvironment,
  updateEnvironment
} from '../../../../../api/environment/Environment.service'
import { isEmpty, isUndefined } from 'lodash'
import { useParams, useSearchParams } from 'react-router-dom'

import { Button } from '../../../../../components/New/Button/Button'
import { EnvironmentType } from '../../../../../enum/environment.enum'
import Input from '../../../../../components/New/Input/Input'
import { OrganizationUserRoles } from '../../../../../enum/OrganizationUserRoles.enum'
import RegexConstants from '../../../../../constants/RegexConstants'
import { SettingAddPopup } from '../../SettingPopups/SettingAddPopup'
import SettingConstants from '../../../../../constants/SettingConstants'
import { SettingDeletePopup } from '../../SettingPopups/SettingDeletePopup'
import Spinner from '../../../../../components/common/Spinner/Spinner'
import StringConstants from '../../../../../constants/StringConstants'
import Table from '../../../../../components/common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../../components/common/DDS/Toast/Toast'
import ValidationConstants from '../../../../../constants/ValidationConstants'
import { getAllQueryParamsAsObjectFromUrl } from '../../../../../utils/UrlUtils'
import { observer } from 'mobx-react-lite'
import { paginate } from '../../../../../utils/CommonUtils'
import pallete from '../../../../../global/newPallete'
import routeConstants from '../../../../../constants/RouteConstants'
import { useStoreContext } from '../../../../../store/StoreContext'

const TabLabels = [
  { id: 0, label: 'Development', type: EnvironmentType.DEVELOPMENT },
  { id: 1, label: 'Staging', type: EnvironmentType.STAGING },
  { id: 2, label: 'Production', type: EnvironmentType.PRODUCTION }
]

const AddHost = () => {
  const [addHostPopupOpen, setAddHostPopupOpen] = React.useState<boolean>(false)
  const [deleteHostPopupOpen, setDeleteHostPopupOpen] =
    React.useState<boolean>(false)
  const [addButtonDisabled, setAddButtonDisabled] = useState(false)

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchParams, setSearchParams] = useSearchParams()

  const [domain, setDomain] = React.useState<string>('')
  const [validationErrorResult, setValidationErrorResult] = React.useState<
    any[]
  >([])

  const [fieldStates, setFieldStates] = useState({
    domain: { isFocused: false, isBlurred: false }
  })

  const [environmentName, setEnvironmentName] = React.useState<string>('')
  const [name, setName] = React.useState<string>('')

  const [currentTab, setCurrentTab] = React.useState<number>(0)

  const [loading, setLoading] = React.useState<boolean>(true)
  const { pathIds } = useParams()
  const { environmentId, projectId } = useParams()

  const store = useStoreContext()

  const [deleteDomainDetails, setDeleteDomainDetails] = React.useState<any>({})
  const [whiteListedHost, setWhiteListedHost] = React.useState<any[]>([])
  const [addedHosts, setAddedHosts] = React.useState<any>([])
  const handleFieldInteraction = (field: string, eventType: string) => {
    setFieldStates((prev: any) => ({
      ...prev,
      [field]: {
        ...prev[field],
        isFocused: eventType === 'focus',
        isBlurred: eventType === 'blur'
      }
    }))
  }
  const setEnvironmentData = () => {
    setLoading(true)

    findEnvironment(projectId as string, environmentId as string)
      .then((environment: any) => {
        setWhiteListedHost(environment.whitelistedHosts)

        setEnvironmentName(
          environment.type === StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
            ? StringConstants.ENVIRONMENT_NAMES.NON_PROD
            : StringConstants.ENVIRONMENT_NAMES.PROD
        )

        setName(environment.type)
        setCurrentTab(
          TabLabels.findIndex(
            (tabLabel: any) => tabLabel.type === environment.type
          ) || 0
        )
      })
      .catch((error) => {
        console.log('Error:', error)
        ToastNotification({
          type: 'error',
          message: error
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  useEffect(() => {
    setEnvironmentData()
  }, [projectId, environmentId])

  const handleAddHostsEnv = (tableData: any[]) => {
    setLoading(true)
    updateEnvironment({
      whitelistedHosts: tableData,
      projectId,
      environmentId
    })
      .then((updatedEnvironment: any) => {
        setLoading(false)
        setAddHostPopupOpen(false)
        setWhiteListedHost(updatedEnvironment.whitelistedHosts)
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.ENVIRONMENT.CREATE.WHITELIST_HOSTS
        })
      })
      .catch((error) => {
        setLoading(false)
        setAddHostPopupOpen(false)
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.ENVIRONMENT.CREATE.WHITELIST_HOSTS_ERROR
        })
      })
  }

  const deleteDomainEnv = (data: any) => {
    setLoading(true)
    if (whiteListedHost.length == 1) whiteListedHost.push('*')
    const indexToDelete = whiteListedHost.findIndex(
      (item) => item === data?.host
    )
    whiteListedHost.splice(indexToDelete, 1)
    updateEnvironment({
      whitelistedHosts: whiteListedHost,
      projectId,
      environmentId
    })
      .then((updatedEnvironment: any) => {
        setLoading(false)
        setDeleteHostPopupOpen(false)
        setWhiteListedHost(updatedEnvironment.whitelistedHosts)
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.ENVIRONMENT.DELETE.WHITELIST_HOSTS
        })
      })
      .catch((error) => {
        ToastNotification({
          type: 'success',
          message:
            ToastMessageConstants.ENVIRONMENT.DELETE.WHITELIST_HOSTS_ERROR
        })
      })
  }

  const [tableDataPopup, setTableDataPopup] = React.useState<any[]>(
    whiteListedHost?.length !== 0
      ? whiteListedHost.map((host) => {
          return { host }
        })
      : [{ host: '*' }]
  )

  const tableDataEnv = whiteListedHost.map((host) => {
    return { host }
  })

  const schema = Joi.object({
    url: Joi.alternatives(
      Joi.string()
        .ip({ version: ['ipv4', 'ipv6'] })
        .pattern(RegexConstants.IP_ADDRESS_REGEX),
      Joi.string().pattern(RegexConstants.HOST_ADDRESS_REGEX),
      Joi.string().valid('*')
    )
      .required()
      .empty('')
      .messages({
        'alternatives.match': ValidationConstants.SETTINGS.INVALID_HOST,
        'string.ip': ValidationConstants.SETTINGS.INVALID_HOST,
        'string.pattern.base': ValidationConstants.SETTINGS.INVALID_HOST,
        'any.required': 'Host cannot be empty',
        'string.empty': 'Host cannot be empty'
      })
  })

  useEffect(() => {
    const domainValue = domain && !isEmpty(domain) ? domain.trim() : ''
    const isWhiteListed = whiteListedHost.includes(domainValue)

    if (isWhiteListed) {
      setAddButtonDisabled(false)
      setValidationErrorResult([{ message: 'Link already exists' }])
    } else {
      const projectValidationResult: ValidationResult = schema.validate(
        { url: domainValue },
        { abortEarly: false }
      )

      if (!isUndefined(projectValidationResult.error)) {
        setAddButtonDisabled(true)
        setValidationErrorResult(projectValidationResult.error.details)
      } else {
        setAddButtonDisabled(false)
        setValidationErrorResult([])
      }
    }
  }, [domain, whiteListedHost])

  const addDomainToTablePopup = () => {
    const projectValidationResult: ValidationResult = schema.validate(
      {
        url: domain && !isEmpty(domain) ? domain.trim() : ''
      },
      { abortEarly: false }
    )

    if (projectValidationResult.error) {
      setValidationErrorResult(projectValidationResult.error.details)
    } else {
      setValidationErrorResult([])
      const isDuplicate = tableDataPopup.some(
        (item) => item.host === domain.trim()
      )
      if (!isDuplicate) {
        if (tableDataPopup[0].host === '*' && tableDataPopup.length == 1) {
          tableDataPopup.shift()
        }
        setValidationErrorResult([])
        setFieldStates((prev: any) => ({
          ...prev,
          ['domain']: {
            ...prev['domain'],
            isFocused: false,
            isBlurred: false
          }
        }))
        addedHosts.unshift({ host: domain.trim() })
        setAddedHosts([...addedHosts])
        tableDataPopup.unshift({ host: domain.trim() })
        setTableDataPopup([...tableDataPopup])
        setDomain('')
      } else {
        setValidationErrorResult([
          {
            message: 'The host address you entered already exists',
            type: 'alternatives.match',
            context: {
              label: 'url',
              value: 'host',
              key: 'url'
            }
          }
        ])
      }
    }
  }

  const handleAddHostsPopup = () => {
    const data =
      tableDataPopup.length === 0
        ? ['*']
        : tableDataPopup.map((data) => {
            const { host } = data
            return host
          })
    if (handleAddHostsEnv) handleAddHostsEnv(data)
  }

  const checkHostsPopup = () => {
    const isArrayEqual =
      whiteListedHost.length === tableDataPopup.length &&
      whiteListedHost.every(
        (value, index) => value === tableDataPopup[index].host
      )
    return isArrayEqual
  }

  useEffect(() => {
    const selectedProject = store.scopeStore
      .getProjects()
      .filter((project) => project.id === projectId)[0]

    if (store.breadcrumbStore.getBreadcrumbsOptions().length === 0) {
      store.breadcrumbStore.addMultipleBreadCrumbOptions([
        { label: 'Project Settings', link: routeConstants.PROJECT_SETTING },
        {
          label: selectedProject?.name,
          link:
            routeConstants.VIEW_PROJECT.replace(
              ':projectId',
              projectId as string
            ) + '?tab=1'
        },
        {
          label: 'Environments',
          link: `${routeConstants.VIEW_PROJECT.replace(
            ':projectId',
            projectId as string
          )}?tab=3`
        },
        {
          label: environmentName,
          link: '/'
        }
      ])
    }
    return () => {
      store.breadcrumbStore.reset()
    }
  }, [
    projectId,
    store.breadcrumbStore.getBreadcrumbsOptions(),
    store.scopeStore.getProjects(),
    store.scopeStore.getSelectedProject(),
    store.scopeStore.getEnvironments(),
    environmentName
  ])

  const hostPopup = [
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {SettingConstants.ALLOWED_HOST}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'host',
      render: (text: any) => {
        return (
          <div className='flex flex-row items-center gap-[12px] justify-start'>
            <span>
              {tableDataPopup.length === 0 ? (
                <div className='underline'>*</div>
              ) : (
                text
              )}
            </span>

            {text === '*' && (
              <div>
                <DDSTooltip
                  id={`${text}1`}
                  label={
                    <DDSTypography.Paragraph
                      size='para'
                      variant='regular'
                      color={pallete.colors.textDark4}
                    >
                      (*) indicates that all hosts will be allowed
                    </DDSTypography.Paragraph>
                  }
                  shape='roundedRectangle'
                  position='top'
                  className={'opacity-1'}
                  style={{
                    backgroundColor: pallete.colors.white,
                    zIndex: 999999
                  }}
                >
                  <div className='flex justify-start text-right items-start'>
                    <Information size={16} color={pallete.colors.textDark4} />
                  </div>
                </DDSTooltip>
              </div>
            )}
          </div>
        )
      }
    },
    {
      columnHeaderStyle: { width: '5%' },
      columnDataStyle: { width: '5%' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='flex justify-center cursor-pointer'>
          {(fullItem?.host !== '*' || tableDataPopup.length !== 1) && (
            <Button
              id='right-btn'
              type='ghost'
              startIcon={
                <TrashCan size={16} color={pallete.colors.warningRed2} />
              }
              onClick={() => {
                if (tableDataPopup.length == 1)
                  tableDataPopup.push({ host: '*' })
                addedHosts.splice(fullItem.index, 1)
                setAddedHosts([...addedHosts])
                tableDataPopup.splice(fullItem.index, 1)
                setTableDataPopup([...tableDataPopup])
              }}
            />
          )}
        </div>
      )
    }
  ]

  const hostEnv = [
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {SettingConstants.ALLOWED_HOST}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'host',
      render: (text: any) => {
        return (
          <div className='flex flex-row items-center gap-[12px] justify-start'>
            <span>
              {tableDataEnv.length === 0 ? (
                <div className='underline'>*</div>
              ) : (
                text
              )}
            </span>

            {text === '*' && (
              <div>
                <DDSTooltip
                  id={`${text}2`}
                  label={
                    <DDSTypography.Paragraph
                      size='para'
                      variant='regular'
                      color={pallete.colors.textDark4}
                    >
                      (*) indicates that all hosts will be allowed
                    </DDSTypography.Paragraph>
                  }
                  shape='roundedRectangle'
                  position='top'
                  className={'opacity-1'}
                  style={{
                    backgroundColor: pallete.colors.white,
                    zIndex: 999999
                  }}
                >
                  <div className='flex justify-start text-right items-start'>
                    <Information size={16} color={pallete.colors.textDark4} />
                  </div>
                </DDSTooltip>
              </div>
            )}
          </div>
        )
      }
    },
    {
      columnHeaderStyle: { width: '5%' },
      columnDataStyle: { width: '5%' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='flex justify-center cursor-pointer'>
          {(fullItem?.host !== '*' || tableDataEnv.length !== 1) && (
            <Button
              id='right-btn'
              type='ghost'
              startIcon={
                <TrashCan size={16} color={pallete.colors.warningRed2} />
              }
              onClick={() => {
                console.log(fullItem, 'fullitemm')
                setDeleteHostPopupOpen(true)
                setDeleteDomainDetails(fullItem)
              }}
            />
          )}
        </div>
      )
    }
  ]

  return (
    <div>
      {loading ||
      store.uiStore.getGlobalLoader() ||
      store.environmentStore.getLoading() ? (
        <Spinner />
      ) : (
        <div>
          <div className='flex justify-between items-center py-[24px]'>
            <DDSTypography.Title
              type='h3'
              variant='semiBold'
              color={pallete.colors.textDark3}
            >
              {environmentName}
            </DDSTypography.Title>
            <Button
              id='add-host'
              type='filled'
              size='small'
              label='Add Host'
              onClick={() => {
                setAddHostPopupOpen(true)
                setFieldStates((prev: any) => ({
                  ...prev,
                  ['domain']: {
                    ...prev['domain'],
                    isFocused: false,
                    isBlurred: false
                  }
                }))
              }}
              disabled={
                loading ||
                store.uiStore.getGlobalLoader() ||
                store.userStore.getUserRole() ===
                  OrganizationUserRoles.BILLING ||
                store.userStore.getNoActiveSubscription()
              }
            />
          </div>
          <div>
            <Table
              tableStyle={{
                borderColor: pallete.colors.stroke2
              }}
              data={tableDataEnv}
              columns={hostEnv}
              totalCount={tableDataEnv.length}
              rowsPerPage={5}
              currentPage={currentPage}
              onPageChange={(page) => {
                const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                  location.search
                )
                setSearchParams({ ...allQueryParams, page: page.toString() })
                setCurrentPage(page)
              }}
            />
          </div>
        </div>
      )}
      <SettingAddPopup
        showHeader
        title='Add Host'
        modalOpen={addHostPopupOpen}
        setModalOpen={setAddHostPopupOpen}
        primaryButtonText='Save Host'
        primaryButtonClick={handleAddHostsPopup}
        primaryButtonDisabled={
          store.environmentStore.getLoading() ||
          loading ||
          addedHosts?.length === 0 ||
          store.uiStore.getGlobalLoader() ||
          checkHostsPopup()
        }
        loading={
          store.environmentStore.getLoading() ||
          loading ||
          store.uiStore.getGlobalLoader()
        }
        secondaryButtonText='Cancel'
        secondaryButtonClick={() => {
          setAddHostPopupOpen(false)
          setDomain('')
          setFieldStates((prev: any) => ({
            ...prev,
            ['domain']: {
              ...prev['domain'],
              isFocused: false,
              isBlurred: false
            }
          }))
        }}
        secondaryButtonDisabled={
          store.environmentStore.getLoading() ||
          loading ||
          store.uiStore.getGlobalLoader()
        }
        showFooter={true}
        content={
          <div className='flex flex-col p-[24px] gap-[24px]'>
            <div className='flex items-end gap-[12px]'>
              <div className='relative w-full'>
                <div className='flex-grow'>
                  <Input
                    width='100%'
                    value={domain}
                    onFocus={() => handleFieldInteraction('domain', 'focus')}
                    onBlur={() => handleFieldInteraction('domain', 'blur')}
                    onChange={(e) => {
                      setDomain(e.target.value)
                    }}
                    labelPosition='top'
                    label={
                      <DDSTypography.Paragraph
                        size='para'
                        variant='regular'
                        color={pallete.colors.textDark6}
                      >
                        {SettingConstants.EXAMPLE_LINK}
                      </DDSTypography.Paragraph>
                    }
                    id='input'
                    name='Input'
                    placeholder='Allowed hosts*'
                  />
                </div>
                {!fieldStates.domain.isBlurred ||
                  (!fieldStates.domain.isFocused &&
                    validationErrorResult.length !== 0 && (
                      <div>
                        <DDSTypography.Paragraph
                          size='caption'
                          variant='regular'
                          className='absolute -bottom-[20px]'
                          color={pallete.colors.warningRed2}
                        >
                          {validationErrorResult[0].message}
                        </DDSTypography.Paragraph>
                      </div>
                    ))}
              </div>

              <Button
                id='add-host'
                type='filled'
                size='small'
                label='Add Host'
                onClick={() => {
                  addDomainToTablePopup()
                }}
                disabled={
                  loading || domain.trim().length == 0 || addButtonDisabled
                }
              />
            </div>
            <DDSTable
              data={tableDataPopup}
              columns={hostPopup.map((column) => ({
                ...column
              }))}
            />
          </div>
        }
      />

      <SettingDeletePopup
        showHeader
        title='Delete Host'
        modalOpen={deleteHostPopupOpen}
        setModalOpen={setDeleteHostPopupOpen}
        primaryButtonText='Yes'
        loading={
          store.environmentStore.getLoading() ||
          loading ||
          store.uiStore.getGlobalLoader()
        }
        primaryButtonDisabled={
          store.environmentStore.getLoading() ||
          loading ||
          store.uiStore.getGlobalLoader()
        }
        secondaryButtonDisabled={
          store.environmentStore.getLoading() ||
          loading ||
          store.uiStore.getGlobalLoader()
        }
        secondaryButtonText='No'
        primaryButtonClick={() => deleteDomainEnv(deleteDomainDetails)}
        secondaryButtonClick={() => {
          setDeleteHostPopupOpen(false)
        }}
        showFooter={true}
        content={
          <div className='flex flex-col p-[24px] gap-[24px]'>
            <DDSTypography.Title
              type='h3'
              variant='semiBold'
              color={pallete.colors.textDark3}
              style={{ whiteSpace: 'nowrap' }}
            >
              {SettingConstants.DELETE_HOSTS}
              <span
                style={{
                  display: 'inline',
                  fontSize: '18px',
                  fontWeight: 700
                }}
              >
                {''} {deleteDomainDetails?.host}?
              </span>
            </DDSTypography.Title>
          </div>
        }
      />
    </div>
  )
}

export default observer(AddHost)
