import * as React from 'react'

import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { TextField } from '@mui/material'

interface DatePickerProps {
  date?: Date | null
  onChange?: (date: Date | null) => void
}
const DatePicker: React.FC<DatePickerProps> = ({
  date = null,
  onChange = () => null,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        displayStaticWrapperAs='desktop'
        openTo='day'
        value={date}
        onChange={(newDate) => onChange(newDate)}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  )
}

export default DatePicker
