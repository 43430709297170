export const GettingStartedConstants = {
  WELCOME_TO_VIGIL: ' Welcome to Vigil 🎉',
  GET_FAMILIAR_WITH: `Get familiar with the product, Here are a few resources to help
              you get started:`,
  READY_TO_GO: 'Ready to Go?',
  REMOVE_TEXT: 'Would you like to remove',
  FROM_SIDEBAR: ' from the sidebar?',
  CONFIGURE_ERROR:
    'Instantly catch every error, dive into the detailed root cause, and fix it like a pro',
  CONFIGURE_EXCEPTION:
    'Instantly catch every exception, dive into the detailed root cause, and fix it like a pro',
  HEALTH_CHECK: `Receive immediate alerts for site downtime and don't let your users suffer`,
  JOB_MONITORING:
    'Stay ahead of job failures and resolve them before users are affected',
  API_MONITORING:
    'Vigilantly monitor your APIs to catch and fix failures instantly',
  INVITE_MEMBERS: 'Invite your team to collaborate and resolve issues together',
  SLACK_INTEGRATION:
    'Get real-time error alerts in Slack, keeping you always in the loop',
  SLACK_EXCEPTIONS:
    'Get real-time exception alerts in Slack, keeping you always in the loop'
}
