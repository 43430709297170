import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { ArrowLeft } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import Invites from '../Page/Invites'
import Notifications from '../Page/Notifications'
import StringConstants from '../../../constants/StringConstants'
import Tabs from '../../../components/common/DDS/Tabs/Tabs'
import { getAllQueryParamsAsObjectFromUrl } from '../../../utils/UrlUtils'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { useStoreContext } from '../../../store/StoreContext'

const NotificationInvite: React.FC = () => {
  const [tabIndex, setTabIndex] = useState<string>('1')
  const [searchParams, setSearchParams] = useSearchParams()
  const store = useStoreContext()
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.removeItem(StringConstants.ACTIVE_TAB)
    const innerTab = searchParams.get('innerTab') || '1'
    setTabIndex(innerTab)
  }, [searchParams, store.userStore.getOrganizations()])

  const tab = [
    {
      label:
        tabIndex === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            Notifications
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            Notifications
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <Notifications />
        </div>
      )
    },
    {
      label:
        tabIndex === '2' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            Invites
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            Invites
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <Invites />
        </div>
      )
    }
  ]

  return (
    <>
      <DocumentHeader
        header={
          tabIndex === '1'
            ? HeaderConstants.NOTIFICATIONS
            : HeaderConstants.INVITATIONS
        }
      />
      {store.userStore.getOrganizations().length === 0 && (
        <div
          className='flex cursor-pointer flex-row space-x-[12px] pb-[24px] pt-[8px]'
          onClick={() => {
            navigate(routeConstants.NO_ORGANIZATION)
          }}
        >
          <ArrowLeft
            size={20}
            color={pallete.colors.textDark3}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate(routeConstants.NO_ORGANIZATION)
            }}
          />
          <DDSTypography.Paragraph
            size='para'
            variant='semiBold'
            color={pallete.colors.textDark3}
          >
            Back
          </DDSTypography.Paragraph>
        </div>
      )}
      <div className='flex flex-col'>
        <div className='flex flex-row w-full'>
          <div className='w-full'>
            <Tabs
              tabOptionsStyle={{
                minWidth: 'fit-content',
                display: 'flex',
                padding: '8px 12px ',
                justifyContent: 'center',
                alignItems: 'center',
                userSelect: 'none',
                width: 'auto',
                marginRight: '8px'
              }}
              disabled={store.uiStore.getGlobalLoader()}
              tabOptions={tab}
              id='tabs-grouped-api'
              onChange={(index: any) => {
                setTabIndex(index.toString())
                const queryParams = getAllQueryParamsAsObjectFromUrl(
                  location.search
                )
                if (queryParams['contextSwitcherTab']) {
                  delete queryParams['contextSwitcherTab']
                }
                setSearchParams({
                  ...queryParams,
                  page: '1',
                  innerTab: index.toString()
                })
              }}
              activeTab={tabIndex}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(NotificationInvite)
