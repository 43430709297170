import {
  Code,
  Debug,
  GroupSecurity,
  IbmCloudSecurityComplianceCenter,
  InProgressError,
  NetworkOverlay,
  PillsAdd
} from '@carbon/icons-react'

import CheckCard from './CheckCard'
import { DDSTypography } from 'den-design-system'
import IconCard from './IconCard'
import React from 'react'
import { SignInSignUpLeftPanelConstants } from '../../constants/signup-signin/SignInSignupLeftPanelConstants'
import SigninSignupStringConstants from '../../constants/SigninSignupStringConstants'
import pallete from '../../global/newPallete'
import radialObject from '../../assets/images/sigin-radial-object.png'
import { useSearchParams } from 'react-router-dom'
import vigilTMLogo from '../../assets/icons/vigilTMLogoWhite.svg'

const BottomIcons = (iconName: string) => {
  switch (iconName) {
    case 'ApiMonitoring':
      return <NetworkOverlay color={pallete.colors.textDark1} size={28} />
    case 'Audit':
      return (
        <IbmCloudSecurityComplianceCenter
          color={pallete.colors.textDark1}
          size={28}
        />
      )
    case 'Incidents':
      return <InProgressError color={pallete.colors.textDark1} size={28} />
    case 'Jobs':
      return <Code color={pallete.colors.textDark1} size={28} />
    case 'Escalations':
      return <GroupSecurity color={pallete.colors.textDark1} size={28} />
    case 'Exceptions':
    case 'Errors':
      return <Debug color={pallete.colors.textDark1} size={28} />
    case 'Uptime':
      return <PillsAdd color={pallete.colors.textDark1} size={28} />
  }
}

const LeftContent: React.FC<{ signIn: boolean }> = ({ signIn }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const utmMedium = searchParams.get('utm_medium')

  let tickCardData, iconCardData, titleData

  switch (utmMedium) {
    case 'fullstack':
      titleData = SigninSignupStringConstants.PRODUCT_FS_HEADING
      tickCardData = SignInSignUpLeftPanelConstants.left_panel.product_fullstack
      iconCardData =
        SignInSignUpLeftPanelConstants.icon_cards.product_fullstack_icon
      break
    case 'salesforce':
      titleData = SigninSignupStringConstants.PRODUCT_SF_HEADING
      tickCardData =
        SignInSignUpLeftPanelConstants.left_panel.product_salesforce
      iconCardData =
        SignInSignUpLeftPanelConstants.icon_cards.product_salesforce_icon
      break
    case 'mulesoft':
      titleData = SigninSignupStringConstants.PRODUCT_MS_HEADING
      tickCardData = SignInSignUpLeftPanelConstants.left_panel.product_mulesoft
      iconCardData =
        SignInSignUpLeftPanelConstants.icon_cards.product_mulesoft_icon
      break
    case 'architect':
      titleData = SigninSignupStringConstants.SOLUTION_SA_HEADING
      tickCardData =
        SignInSignUpLeftPanelConstants.left_panel.solutions_solution_architect
      iconCardData =
        SignInSignUpLeftPanelConstants.icon_cards
          .solutions_solution_architect_icon
      break
    case 'partners':
      titleData = SigninSignupStringConstants.SOLUTION_SPC_HEADING
      tickCardData =
        SignInSignUpLeftPanelConstants.left_panel.solutions_salesforce_partners
      iconCardData =
        SignInSignUpLeftPanelConstants.icon_cards
          .solutions_salesforce_partners_icon
      break
    case 'leaders':
      titleData = SigninSignupStringConstants.SOLUTION_EL_HEADING
      tickCardData =
        SignInSignUpLeftPanelConstants.left_panel.solutions_executive_leaders
      iconCardData =
        SignInSignUpLeftPanelConstants.icon_cards
          .solutions_executive_leaders_icon
      break
    case 'pricing':
      titleData = SigninSignupStringConstants.MONITOR_MANAGE
      tickCardData =
        SignInSignUpLeftPanelConstants.left_panel.left_panel_default
      iconCardData =
        SignInSignUpLeftPanelConstants.icon_cards.default_icon_card_data
      break
    default:
      titleData = SigninSignupStringConstants.MONITOR_MANAGE
      tickCardData =
        SignInSignUpLeftPanelConstants.left_panel.left_panel_default
      iconCardData =
        SignInSignUpLeftPanelConstants.icon_cards.default_icon_card_data
      break
  }

  return (
    <div
      className='w-[40%] px-[44px] py-[64px] flex flex-col items-start justify-center gap-[32px]  h-[100vh]'
      style={{
        background: `url(${radialObject}) no-repeat top left`,
        backgroundSize: 'cover',
        backgroundColor: pallete.colors.surface3
      }}
    >
      <div>
        <img src={vigilTMLogo} />
      </div>
      <div className='flex flex-col items-start gap-[48px] h-full w-full'>
        {signIn ? (
          <DDSTypography.Title
            type='h1'
            variant='bold'
            color={pallete.colors.textDark1}
            style={{ fontSize: '32px', lineHeight: '40px' }}
          >
            {SigninSignupStringConstants.WELCOME_BACK}
          </DDSTypography.Title>
        ) : (
          <div className='flex flex-col items-start gap-[8px] self-stretch'>
            <DDSTypography.Title
              type='h1'
              variant='bold'
              color={pallete.colors.textDark1}
              style={{ fontSize: '32px', lineHeight: '40px' }}
            >
              {SigninSignupStringConstants.SIGNUP_FREE}
            </DDSTypography.Title>
            <div>
              <DDSTypography.Title
                type='h5'
                variant='medium'
                color={pallete.colors.textDark7}
              >
                {SigninSignupStringConstants.NO_CREDIT_CARD}
              </DDSTypography.Title>
            </div>
          </div>
        )}

        <div className='flex flex-col py-[24px] gap-[24px]'>
          <div>
            <DDSTypography.Title
              type='h4'
              variant='bold'
              color={pallete.colors.textDark1}
            >
              {titleData}
            </DDSTypography.Title>
          </div>
          <div className='flex flex-col items-start gap-[24px] self-stretch'>
            {tickCardData.map((item, index) => {
              return <CheckCard key={index + item[0]} text={item} />
            })}
          </div>
        </div>
        <div className='mt-auto flex flex-col items-start gap-[24px] self-stretch'>
          <div>
            <DDSTypography.Title
              type='h4'
              variant='bold'
              color={pallete.colors.textDark1}
            >
              {SigninSignupStringConstants.ACCESS_EVERYTHING}
            </DDSTypography.Title>
          </div>
          <div className='flex w-full items-start gap-[24px] self-stretch'>
            {iconCardData.map(({ icon, text }, index) => {
              return (
                <div className='w-full' key={index + text[0]}>
                  <IconCard icon={BottomIcons(icon)} text={text} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeftContent
