import { DDSButton, DDSModal, DDSTypography } from 'den-design-system'

import { Button } from '../../New/Button/Button'
import React from 'react'
import { SetStateAction } from 'react'
import pallete from '../../../global/newPallete'

interface ModalFooterProps {
  primaryButtonText?: string
  secondaryButtonText?: string
  onYes?: () => any
  onNo?: () => any
  primaryButtonDisabled?: boolean
  secondaryButtonDisabled?: boolean
  deleteLoading?: boolean
  editLoading?: boolean
}
const ModalFooter: React.FC<ModalFooterProps> = ({
  onYes,
  onNo,
  primaryButtonText = '',
  secondaryButtonText = '',
  primaryButtonDisabled,
  secondaryButtonDisabled,
  deleteLoading,
  editLoading
}) => {
  return (
    <div className='flex flex-row gap-x-[8px] px-[24px] py-[16px] override-width justify-end'>
      {secondaryButtonText !== '' && (
        <DDSButton
          label={
            <DDSTypography.Paragraph
              variant='semiBold'
              size='para'
              className='text-center whitespace-nowrap flex justify-center !min-w-0'
              color={pallete.colors.neuButtonText}
            >
              {secondaryButtonText}
            </DDSTypography.Paragraph>
          }
          type='outline'
          shape='roundedRectangle'
          buttonHoverBackgroundColor={pallete.colors.neuButtonHover}
          size='small'
          disabled={editLoading || deleteLoading}
          style={{
            borderColor: secondaryButtonDisabled
              ? pallete.colors.neuButtontextDis
              : pallete.colors.buttonOutlineGrey1,
            color: secondaryButtonDisabled
              ? pallete.colors.neuButtontextDis
              : pallete.colors.textDark10,
            width: '120px',
            height: '36px'
          }}
          buttonHoverTextColor={
            secondaryButtonDisabled
              ? pallete.colors.neuButtontextDis
              : pallete.colors.textDark10
          }
          className={`!min-w-0 flex ${
            secondaryButtonDisabled ? 'cursor-not-allowed' : ''
          }`}
          onClick={() => (onNo ? onNo() : undefined)}
        />
      )}
      {primaryButtonText !== '' && (
        <div className='override-btn-min-width'>
          <Button
            id='primary-button'
            disabled={deleteLoading || editLoading}
            loading={deleteLoading || editLoading}
            type={`dangerFilled`}
            label={
              <DDSTypography.Paragraph
                variant='semiBold'
                size='para'
                className='text-center whitespace-nowrap flex justify-center !min-w-0'
                color={pallete.colors.white}
              >
                {primaryButtonText}
              </DDSTypography.Paragraph>
            }
            onClick={() => (onYes ? onYes() : undefined)}
          />
        </div>
      )}
    </div>
  )
}

interface DeleteIncidentPopupProp {
  modalOpen: boolean
  setModalOpen: React.Dispatch<SetStateAction<boolean>>

  content: React.ReactNode
  showFooter?: boolean
  primaryButtonClick?: any
  secondaryButtonClick?: any
  primaryButtonText?: string
  secondaryButtonText?: string
  primaryButtonDisabled?: boolean
  secondaryButtonDisabled?: boolean
  deleteLoading?: boolean
  editLoading?: boolean
}
export const DeleteIncidentPopup: React.FC<DeleteIncidentPopupProp> = ({
  modalOpen,
  setModalOpen,
  content,
  primaryButtonClick,
  secondaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  primaryButtonDisabled,
  secondaryButtonDisabled,
  showFooter = false,
  deleteLoading,
  editLoading
}) => {
  return (
    <DDSModal
      open={modalOpen}
      setOpen={setModalOpen}
      defaultFooter={false}
      onIconClose={() => setModalOpen(false)}
      hideCloseIcon={deleteLoading || editLoading}
    >
      <div>
        <div
          className='flex items-start flex-row py-[16px] px-[24px]  border-b'
          style={{ borderBottomColor: pallete.colors.stroke2 }}
        >
          <DDSTypography.Title
            variant='bold'
            type='h4'
            color={pallete.colors.textDark4}
            className='text-center flex items-start'
          >
            Delete Incident
          </DDSTypography.Title>
        </div>
        {content}
      </div>
      {showFooter && (
        <ModalFooter
          onYes={primaryButtonClick}
          onNo={secondaryButtonClick}
          deleteLoading={deleteLoading}
          editLoading={editLoading}
          primaryButtonText={primaryButtonText}
          secondaryButtonText={secondaryButtonText}
          primaryButtonDisabled={primaryButtonDisabled}
          secondaryButtonDisabled={secondaryButtonDisabled}
        />
      )}
    </DDSModal>
  )
}
