import React from 'react'
import routeConstants from '../../../../../constants/RouteConstants'
import vigilTMLogo from '../../../../../assets/icons/vigilTMLogo.svg'

const MiscHeader = () => {
  return (
    <div className='flex w-full h-[70.58px] bg-white items-center justify-center px-[32px] py-[16px] shadow-custom'>
      <a href={routeConstants.SIGN_IN}>
        <img src={vigilTMLogo} />
      </a>
    </div>
  )
}

export default MiscHeader
