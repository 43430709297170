export default {
  DELETE_POPUP_TITLE: 'Delete Uptime Check',
  SHARE_POPUP_TITLE: 'Share Status',
  SHARE_POPUP_DESCRIPTION:
    'Anyone with the link can view the application status.',
  COPY_LINK_BUTTON: 'Copy Link',
  COPY_LINK_BUTTON_COPIED: 'Copied',
  UPDATE_CHECK_TITLE: 'Update Check',
  UPTIME_CHECKS: 'Uptime Checks',
  OVERVIEW: 'Overview',
  LOG_TABLE: 'Log Table',
  ADD_CHECK_TITLE: 'Add Check',
  ADD_CHECK_TO_GET_STARTED: 'Add a new Uptime Check to get started!',
  CANCEL: 'Cancel',
  DELETE: 'Delete',
  NO_RECORD: 'No records found'
}
