import { AnySchema, ValidationErrorItem, ValidationResult } from 'joi'

export const getValidationStatus = (
  validationResult: ValidationErrorItem[],
  label: string
): boolean => {
  let validationErrorResult = false
  validationResult.forEach((error: ValidationErrorItem) => {
    if (error.context?.label?.includes(label)) {
      validationErrorResult = true
    }
  })
  return validationErrorResult
}

export const validateField = (field: any, validationSchema: AnySchema) => {
  let validationError = false

  const validationResult: ValidationResult = validationSchema.validate(field, {
    abortEarly: false,
  })

  if (validationResult.error) validationError = true

  return validationError
}

/**
 * Util function to get validation error message
 * @param {ValidationErrorItem[]} validationResult - Joi validation error item
 * @param {string} label - input field label
 * @returns {string} error message if any else empty string
 */
export const getValidationError = (
  validationResult: ValidationErrorItem[],
  label: string
): string => {
  let validationErrorMessage = ''
  validationResult.forEach((error: ValidationErrorItem) => {
    if (error.context?.label?.includes(label)) {
      validationErrorMessage = error.message
    }
  })
  return validationErrorMessage
}
