import { BsChevronRight } from 'react-icons/bs'
import Button from '../../components/common/Button/Button'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../constants/HeaderConstants'
import React from 'react'
import Subtract from '../../assets/icons/Subtract.png'
import Typography from '../../components/common/Typography/Typography'
import { TypographyDDS } from '../../components/common/Typography/TypographyDDS'
import palette from '../../global/pallete'
import routeConstants from '../../constants/RouteConstants'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import vigilIcon from '../../assets/icons/vigilTMLogo.svg'
import watermark from '../../assets/icons/watermark.png'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
`
const Container = styled.div`
  height: 100vh;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${palette.colors.white};
  gap: 15px;
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const WatermarkWrapper = styled.div`
  width: 45rem;
  position: absolute;
  bottom: -10%;
  right: -20%;
  @media screen and (max-width: 900px) {
    position: fixed;
  }
`
const ButtonWrapper = styled.div`
  width: 40%;
`
const ErrorPage = () => {
  const navigate = useNavigate()
  // const storeContext = useStoreContext()
  return (
    <>
      <DocumentHeader header={HeaderConstants.NO_RECORD} />
      <WatermarkWrapper>
        <img
          src={watermark}
          style={{ height: '100%', width: '100%', objectFit: 'contain' }}
        />
      </WatermarkWrapper>
      <Container>
        <ImageWrapper>
          <img
            src={vigilIcon}
            style={{
              width: '155px',
              margin: '32px'
            }}
          />
        </ImageWrapper>
        <Wrapper>
          <ImageWrapper>
            <img
              src={Subtract}
              style={{
                width: '100px'
              }}
            />
          </ImageWrapper>

          <TypographyDDS.ExtraLarge
            variant='bold'
            color='primary'
            style={{
              background: `linear-gradient(270deg, ${palette.colors.primary} 50.66%, ${palette.colors.primary2} 100%)`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            404
          </TypographyDDS.ExtraLarge>

          <TypographyDDS.Title type='h1' variant='semiBold'>
            The record you are looking for is either deleted or invalid.
          </TypographyDDS.Title>
        </Wrapper>
        <ButtonWrapper>
          <Button
            onClick={() => {
              navigate(routeConstants.REDIRECT, { replace: true })
            }}
            endIcon={<BsChevronRight />}
            variant='contained'
            color='gradientPrimary'
            margin='20px 0 0 0'
            size='large'
            fullWidth
          >
            Return To Homepage
          </Button>
        </ButtonWrapper>
      </Container>
    </>
  )
}
export default ErrorPage
