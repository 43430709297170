import { RootStore } from './RootStore'
import StringConstants from '../constants/StringConstants'
import { makeAutoObservable } from 'mobx'

export class AhcLogsStore {
  logs: any[]

  page: number
  size: number
  count: number
  eventStartTime: string | null
  eventEndTime: string | null
  loading: boolean
  filters: Record<string, string> | null
  constructor(private rootStore: RootStore) {
    this.logs = []
    this.page = 1
    this.size = StringConstants.DEFAULT_TABLE_SIZE
    this.count = 0
    this.eventStartTime = null
    this.eventEndTime = null
    this.loading = false
    this.filters = null
    makeAutoObservable(this)
  }

  setLogs(logs: any[]) {
    this.rootStore.ahcLogsStore.logs = logs
  }

  getLogs() {
    if (
      this.rootStore.ahcLogsStore.logs.length > 0 ||
      this.rootStore.filterStore.getFiltersApplied()
    ) {
      this.rootStore.filterStore.setFilterDisabled(false)
    } else {
      this.rootStore.filterStore.setFilterDisabled(true)
    }
    this.rootStore.uiStore.setGlobalLoader(false)
    return this.rootStore.ahcLogsStore.logs
  }

  setPage(page: number) {
    this.rootStore.ahcLogsStore.page = page
  }
  getPage() {
    return this.rootStore.ahcLogsStore.page
  }
  getCount() {
    return this.rootStore.ahcLogsStore.count
  }
  setCount(count: number) {
    this.rootStore.ahcLogsStore.count = count
  }
  setEventStartTime(eventStartTime: string) {
    this.rootStore.ahcLogsStore.eventStartTime = eventStartTime
  }
  setEventEndTime(eventEndTime: string) {
    this.rootStore.ahcLogsStore.eventEndTime = eventEndTime
  }
  getEventStartTime() {
    return this.rootStore.ahcLogsStore.eventStartTime
  }
  getEventEndTime() {
    return this.rootStore.ahcLogsStore.eventEndTime
  }
  setLoading(loading: boolean) {
    this.rootStore.ahcLogsStore.loading = loading
  }
  getLoading() {
    return this.rootStore.ahcLogsStore.loading
  }
  setFilters(filter: Record<string, string> | null) {
    this.rootStore.ahcLogsStore.filters = {
      ...this.rootStore.ahcLogsStore.filters,
      ...filter
    }
  }
  getFilters() {
    return this.rootStore.ahcLogsStore.filters
  }
  resetFilters() {
    this.rootStore.ahcLogsStore.filters = null
  }

  reset() {
    this.logs = []
    this.page = 1
    this.size = StringConstants.DEFAULT_TABLE_SIZE
    this.count = 0
    this.loading = false
    this.filters = null
  }
}
