/* eslint-disable no-prototype-builtins */
/* eslint-disable array-callback-return */

import UrlConstants from '../constants/UrlConstants'
import { getEntriesFromUrl } from './UrlUtils'
import { isEmpty } from 'lodash'

const generateUrl = (usecase: string) => {
  let baseUrl = process.env.REACT_APP_API_ENDPOINT
  if (UrlConstants[usecase] && usecase === UrlConstants[usecase]['USECASE'])
    baseUrl += UrlConstants[usecase].URL
  return baseUrl
}

const updatePathParamsForApiUrl = (url: string, params: any) => {
  const updatedUrl = new URL(url)
  if (params instanceof FormData) {
    for (const pair of params.entries()) {
      const key = pair[0]
      if (key.charAt(0) === '$' && updatedUrl.pathname.includes(key)) {
        updatedUrl.pathname = updatedUrl.pathname.replace(
          key,
          pair[1] as string
        )
        params.delete(key)
      }
    }
  } else {
    params &&
      Object.keys(params).map((key) => {
        if (key.charAt(0) === '$' && updatedUrl.pathname.includes(key)) {
          updatedUrl.pathname = updatedUrl.pathname.replace(key, params[key])
          delete params[key]
        }
      })
  }
  return { url: updatedUrl.toString(), params }
}

/**
 * Repalce Path params in a UI Route.
 * Method returns complete url for the given route.
 * Ensure route has path param differentiated with colon (:) infront of the variable
 * Note : ":" automatically added to the keys in param
 * @param {RouteConstants} route
 * @param {object} params
 */
const getUiUrlWithPathParams = (
  route: string,
  params: Record<string, string>
) => {
  Object.keys(params).map((key) => {
    if (route.includes(':' + key)) {
      route = route.replace(':' + key, params[key])
    }
  })
  const url = window.location.origin + route
  return url
}

/**
 * Add Query params to a UI Route
 * Method returns a complete url for the give UI route.
 * (Best used for window.history.replace)
 * @param {RouteConstants} route
 * @param {Object} params
 */
const getUiUrlWithQueryParams = (
  route: string,
  params: Record<string, string>
) => {
  const url = new URL(window.location.origin + route)
  Object.keys(params).map((key) => {
    url.searchParams.set(key, params[key])
  })

  return url.toString()
}

/**
 * Add Query params to any URI.
 * Does not convert the route param to URL
 * @param {*} route
 * @param {*} params
 */
const addQueryParamsToUrl = (
  route: string,
  params: Record<string, string>,
  httpMethod = ''
) => {
  let pathname = route
  if (isEmpty(params)) return route
  if (httpMethod !== 'GET') {
    const updatedUrl = new URL(pathname)

    Object.keys(params).map((key) => {
      if (key.charAt(0) === '_') {
        updatedUrl.searchParams.set(key.split('_')[1], params[key])
        delete params[key]
      }
    })
    return updatedUrl.toString()
  }

  Object.keys(params).map((key, index) => {
    if (index === 0) pathname += '?'
    else pathname += '&'
    const value = params[key]
    if (Array.isArray(value)) {
      value.map((each, i) =>
        i < value.length - 1
          ? (pathname += key + '=' + encodeURIComponent(each) + '&')
          : (pathname += key + '=' + encodeURIComponent(each))
      )
    } else pathname += key + '=' + encodeURIComponent(value)
  })
  return pathname
}

const getUpdatedUrl = (route: string, params: Record<string, string>) => {
  const oldParams: any = {}
  const entries = getEntriesFromUrl(window.location.search)
  Array.from(entries).forEach((each) =>
    oldParams.hasOwnProperty(each[0])
      ? oldParams[each[0] as any].push(each[1])
      : (oldParams[each[0] as any] = Array.of(each[1]))
  )
  const newParams = {
    ...oldParams,
    ...params
  }
  Object.keys(newParams).map(
    (key) =>
      (isEmpty(newParams[key]) || !newParams[key]) && delete newParams[key]
  )
  return addQueryParamsToUrl(route, newParams)
}

export {
  generateUrl,
  updatePathParamsForApiUrl,
  getUiUrlWithPathParams,
  getUiUrlWithQueryParams,
  addQueryParamsToUrl,
  getUpdatedUrl
}
