/* eslint-disable quotes */
export default {
  LOGIN_TITLE: 'Log in to your account',
  SIGNUP_TITLE: "Let's get you setup",
  LOGIN_ONBOARD: 'Onboard and Manage your Applications',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  FORGOT_PASSWORD: 'Forgot Password?',
  LOGIN: 'Sign in',
  GET_STARTED: 'Get Started',
  OR: 'or',
  LOGIN_SF: 'Sign in with Salesforce',
  SIGNUP_SF: 'Sign up with Salesforce',
  ACKNOWLEDGE: 'By signing up, you are agreeing to our',
  TERMS_OF_SERVICE: 'Terms of Service',
  AND: '&',
  PRIVACY_POLICY: 'Privacy Policy',
  FIRST_TIME: "Don't have an account?",
  ALREADY_HAVE_ACC: 'Already have an account?',
  CREATE_ACCOUNT: 'Sign up',
  WELCOME_BACK: 'Welcome Back!',
  SIGNUP_FREE: 'Create your account for free',
  NO_CREDIT_CARD: '(No credit card required)',

  // Default text for left panel
  MONITOR_MANAGE: 'Monitor & Manage',
  SALESFORCE_ORGS: 'Salesforce Orgs',
  MULESOFT_APPS: 'Mulesoft Applications',
  FULL_STACK_APPS: 'Full-stack Applications',

  // From Product Pages
  // Full-Stack Applications
  PRODUCT_FS_HEADING: 'Track & Optimize',
  PRODUCT_FS_SCHEDULE_JOB: 'Scheduled jobs',
  PRODUCT_FS_REAL_TIME_API: 'Real-time API performance monitoring',
  PRODUCT_FS_UPTIME: 'Uptime insights for critical workflows',

  // Salesforce Organization
  PRODUCT_SF_HEADING: 'Track & Optimize',
  PRODUCT_SF_EXCEPTION: 'Salesforce exceptions and Apex jobs',
  PRODUCT_SF_ALERTS: 'Alerts for usage limits',
  PRODUCT_SF_AUDITS: 'Comprehensive audits',

  // Mulesoft Applications
  PRODUCT_MS_HEADING: 'Get insights into',
  PRODUCT_MS_SCHEDULE_JOB: 'Real-time error detection',
  PRODUCT_MS_REAL_TIME_API: '24/7 uptime checks',
  PRODUCT_MS_UPTIME: 'Recurring escalations',

  // From Solutions Pages
  // Solution Architects
  SOLUTION_SA_HEADING: 'Detect & Optimize',
  SOLUTION_SA_EXCEPTIONS: 'Exceptions with deep insights',
  SOLUTION_SA_UPTIME: '24/7 uptime checks',
  SOLUTION_SA_ERROR_PERFORMANCE: 'Recurring error performance',

  // Salesforce Partners and Consultants
  SOLUTION_SPC_HEADING: 'Monitor & Manage',
  SOLUTION_SPC_ERROR_INSIGHTS: 'Actionable error insights',
  SOLUTION_SPC_WORKFLOW: 'Workflow monitoring and audits',
  SOLUTION_SPC_UPTIME: 'Uptime for critical systems',

  // Executive Leaders
  SOLUTION_EL_HEADING: 'Monitor & Manage',
  SOLUTION_EL_METRICS: 'System-wide performance metrics',
  SOLUTION_EL_UPTINE: 'Uptime for business operations',
  SOLUTION_EL_MONITORING: 'Exception monitoring and audit alerts',

  // Dynamic Content End
  ACCESS_EVERYTHING: 'Access to Everything',
  EXCEPTIONS: 'Exceptions',
  ESCALATIONS: 'Escalations',
  JOBS: 'Jobs',
  AUDIT: 'Audit',
  UPTIME: 'Uptime',
  API_MONITORING: 'API Monitoring',
  INCIDENTS: 'Incidents',
  ERRORS: 'Errors',

  BOTH: 'Both',
  ORGANIZATION: 'Organization Name',
  ORGANIZATION_TYPE: 'What are you looking to monitor?'
}
