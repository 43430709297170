import { CheckmarkFilled } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import React from 'react'
import pallete from '../../global/newPallete'

const CheckCard: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div className='flex items-center self-stretch gap-[8px]'>
      <div>
        <CheckmarkFilled color={pallete.colors.textDark2} size={16} />
      </div>
      <div>
        <DDSTypography.Title
          type='h5'
          variant='medium'
          color={pallete.colors.textDark1}
        >
          {text}
        </DDSTypography.Title>
      </div>
    </div>
  )
}
export default CheckCard
