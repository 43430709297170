import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp
} from 'react-icons/md'

import Filter from '../../Filter/Filter'
import MenuDropdown from '../Dropdown/MenuDropdown'
import React from 'react'
import { TypographyDDS } from '../Typography/TypographyDDS'
import breakpoints from '../../../global/breakpoints'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

interface DashboardSectionContainerProps {
  children?: React.ReactNode
  headerText?: React.ReactNode
  headerFilter?: React.ReactNode
  rightHeaderItem?: React.ReactNode
  customHeader?: React.ReactNode
  width?: string
  height?: string
  padding?: string
  minHeight?: string
  noHeader?: boolean
  noDividerPadding?: boolean
  accordion?: boolean
  onAccordionChange?: () => void
  accordionOpen?: boolean
  $loading?: boolean
  noDivider?: boolean
  paddingBottom?: boolean
  paddingTop?: string
  noGutters?: boolean
  childHeight?: string
  menuDropDown?: boolean
  exceptionsPresent?: boolean
  exceptions?: boolean
  menuActions?: (() => void)[]
  filterOptions?: any
  showFilters?: boolean
}

const Container = styled.div<{
  margin: string
  width?: string
  height?: string
  padding?: string
  paddingBottom?: boolean
  minHeight?: string
  noHeader?: boolean
  accordion?: boolean
}>`
  border-radius: 0.75em;
  background-color: ${palette.colors.lightBack4};

  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0px 4px 25px rgba(71, 78, 93, 0.05);
    transition: box-shadow 0.2s;
  }
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-height: ${({ minHeight }) => minHeight};
  border: 1.5px solid ${palette.colors.borderColor};
  @media screen and (max-width: ${breakpoints.md}) {
    padding: ${({ accordion }) => !accordion && '1.25em'};
  }
  ${({ paddingBottom }) =>
    paddingBottom && `margin-bottom:${palette.padding.size}`}
  ${({ noHeader }) => noHeader && 'padding-top: 0'}
`

const HeaderWrapper = styled.div<{ accordion?: boolean; noDivider?: boolean }>`
  padding-bottom: ${({ noDivider }) => (noDivider ? '1.25em' : 'none')};
  padding-bottom: 1.25em;
  @media screen and (max-width: ${breakpoints.md}) {
    padding-bottom: ${({ accordion }) => !accordion && '1em'};
    font-size: 12px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: scroll;
  ${({ accordion }) => accordion && 'cursor: pointer;'}
  flex-direction:column
`
const Divider = styled.div`
  border-bottom: 1px solid ${palette.colors.borderColor};
`
const ContentWrapper = styled.div<{
  noDividerPadding: boolean
  height?: string
}>`
  padding-top: ${({ noDividerPadding }) =>
    noDividerPadding ? '0px' : '1.25em'};
  height: ${({ height }) => height};
  @media screen and (max-width: ${breakpoints.md}) {
    padding-top: ${({ noDividerPadding }) =>
      noDividerPadding ? '0px' : '1em'};
  }
`
const AccordionIconWrapper = styled.div`
  cursor: pointer;
`

const DashboardSectionContainer: React.FC<DashboardSectionContainerProps> = ({
  children,
  headerText,
  headerFilter,
  rightHeaderItem,
  customHeader,
  padding = '20px',
  width = '100%',
  height,
  minHeight,
  noHeader,
  noDividerPadding = false,
  accordion = false,
  onAccordionChange = () => null,
  accordionOpen = true,
  $loading = false,
  noDivider,
  paddingBottom,
  paddingTop,
  noGutters,
  childHeight,
  menuDropDown = false,
  exceptions = false,
  exceptionsPresent = false,
  menuActions,
  filterOptions,
  showFilters = false
}) => {
  const getAccordionIcon = () => (
    <AccordionIconWrapper onClick={onAccordionChange}>
      {accordionOpen ? (
        <MdOutlineKeyboardArrowUp color={palette.colors.primary} size='1.5em' />
      ) : (
        <MdOutlineKeyboardArrowDown
          color={palette.colors.primary}
          size='1.5em'
        />
      )}
    </AccordionIconWrapper>
  )

  return (
    <Container
      margin={noGutters ? '0' : palette.padding.default}
      padding={padding}
      width={width}
      height={height}
      minHeight={minHeight}
      noHeader={noHeader}
      accordion={accordion}
      paddingBottom={paddingBottom}
    >
      {!noHeader &&
        (customHeader || (
          <HeaderWrapper
            accordion={accordion}
            onClick={accordion ? onAccordionChange : () => null}
            style={{ paddingBottom: rightHeaderItem ? '0.45em' : '' }}
          >
            <div className='flex w-[100%]'>
              <TypographyDDS.Title
                type='h4'
                variant='bold'
                style={{
                  width: '100%',
                  display: 'flex',
                  gap: '5px',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                {headerText}
                {headerFilter}
              </TypographyDDS.Title>

              {accordion ? getAccordionIcon() : rightHeaderItem}
              {menuDropDown && (
                <MenuDropdown
                  actions={menuActions}
                  exceptions={exceptions}
                  exceptionsPresent={exceptionsPresent}
                />
              )}
            </div>
            {showFilters && (
              <div className='w-[100%] relative flex top-[0px] right-[0px]'>
                <Filter dropdownLabel='Filter' options={filterOptions} />
              </div>
            )}
          </HeaderWrapper>
        ))}
      {!(noHeader || noDivider) && (!accordion || accordionOpen) && <Divider />}
      {(!accordion || accordionOpen) && (
        <ContentWrapper
          noDividerPadding={noDividerPadding}
          height={childHeight}
          style={{ paddingTop: paddingTop ? paddingTop : '' }}
        >
          {children}
        </ContentWrapper>
      )}
    </Container>
  )
}

export default DashboardSectionContainer
