import { Card } from '@mui/material'
import React, { Children, ReactNode } from 'react'
import palette from '../../../global/pallete'

interface UsageCardProps {
  children?: ReactNode
  width?: string
  noGutter?: boolean
}
const UsageCard: React.FC<UsageCardProps> = ({ children, width, noGutter }) => {
  return (
    <Card
      elevation={0}
      style={{
        borderColor: palette.colors.borderColor2,
        borderWidth: '1px',
        borderStyle: 'solid',
        padding: noGutter ? 0 : '24px',
        display: 'inline-block',
        borderRadius: '10px',
        maxWidth: width,
        width: '100%'
      }}
    >
      {children}
    </Card>
  )
}

export default UsageCard
