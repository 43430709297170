import styled from '@emotion/styled'
import React from 'react'

interface IconProps extends IconContainerStyledProps {
  children?: React.ReactNode
}

interface IconContainerStyledProps {
  color?: string
  fontSize?: string
  padding?: string
}

const IconContainer = styled.span<IconContainerStyledProps>`
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.padding};
`

const Icon: React.FC<IconProps> = ({
  children,
  color,
  fontSize = '16px',
  padding = '0px 0px',
}) => {
  return (
    <IconContainer color={color} fontSize={fontSize} padding={padding}>
      {children}
    </IconContainer>
  )
}

export default Icon
