import { DDSPagination, DDSTable, DDSTypography } from 'den-design-system'
import React, { CSSProperties, useEffect } from 'react'

import Spinner from '../../Spinner/Spinner'
import StringConstants from '../../../../constants/StringConstants'
import pallete from '../../../../global/newPallete'

interface TableProps {
  id?: string
  data: any
  columns: {
    heading: React.ReactNode | string
    dataLabel: string
    render: (item: any, fullItem?: any, index?: number) => React.ReactNode
    columnHeaderStyle?: React.CSSProperties
    columnDataStyle?: React.CSSProperties
  }[]
  selectable?: boolean
  onSelect?: (selectedRow: any) => void
  tableStyle?: CSSProperties
  className?: string
  loading?: boolean
  paginationStyle?: CSSProperties
  onPageChange?: (pageNumber: number) => void
  totalCount: number
  currentPage?: number
  rowsPerPage?: number
  paginationCount?: number
  disabled?: boolean
  showPagination?: boolean
  selectionType?: 'checkbox' | 'radio'
  overrideHeight?: boolean
}

const Table: React.FC<TableProps> = ({
  id,
  data,
  columns,
  selectable = false,
  onSelect = () => null,
  tableStyle,
  className,
  loading = false,
  paginationStyle,
  onPageChange = () => null,
  totalCount,
  currentPage = 1,
  rowsPerPage = StringConstants.DEFAULT_TABLE_SIZE,
  paginationCount,
  disabled = false,
  showPagination = true,
  selectionType = 'radio',
  overrideHeight = false
}) => {
  return (
    <>
      {data?.length > 0 ? (
        <div
          className={`flex flex-col z-[9] relative  w-full override-overflow table-non-interactive border rounded-lg `}
        >
          <div
            className={`loader-time  ${overrideHeight ? '2xl:h-[50vh] h-[47vh] ' : ''}`}
          >
            <DDSTable
              id={id}
              columns={columns}
              data={data}
              style={{ ...tableStyle, overflow: 'auto' }}
              selectable={selectable}
              onSelect={(selectedRow) => {
                onSelect(selectedRow)
              }}
              className={`${className} table-non-interactive`}
              loading={loading}
              shape='roundedRectangle'
              selectionType={selectionType}
            />
          </div>
          {!loading && showPagination && (
            <div className='flex justify-between px-[16px] py-[12px] w-full table-width'>
              <div
                className='flex justify-start items-center text-center'
                style={{ borderColor: pallete.colors.stroke2 }}
              >
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  color={pallete.colors.textDark6}
                >
                  {Math.min(
                    (currentPage - 1) * rowsPerPage + 1,
                    totalCount as number
                  )}{' '}
                  - {Math.min(currentPage * rowsPerPage, totalCount as number)}{' '}
                  of {totalCount}
                </DDSTypography.Paragraph>
              </div>
              <div className='flex justify-end'>
                <DDSPagination
                  buttonVariant='icon'
                  shape='roundedRectangle'
                  style={paginationStyle}
                  currentPage={currentPage}
                  totalCount={totalCount}
                  paginationCount={paginationCount}
                  rowsPerPage={rowsPerPage}
                  onChange={(pageNumber: number) => {
                    onPageChange(pageNumber)
                  }}
                  disabled={disabled}
                  pageNumberButtonType='outline'
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </>
  )
}

export default Table
