import React, { useEffect } from 'react'

import { CheckmarkOutline } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import Table from '../../../../../components/common/DDS/Table/Table'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../../global/newPallete'
import { replaceUrl } from '../../../../../utils/UrlUtils'
import routeConstants from '../../../../../constants/RouteConstants'
import { useStoreContext } from '../../../../../store/StoreContext'

const RolesAction = () => {
  const store = useStoreContext()
  const rolesAndActionsData = [
    {
      actions: 'Can see / edit billing information and subscription details',
      //billing: true,
      owner: true
    },
    {
      actions: 'Can see / edit legal and compliance details',
      //billing: true,
      owner: true
    },
    {
      actions:
        'Can view and act on issues, such as assigning / resolving / etc.',
      member: true,
      owner: true
    },
    {
      actions: 'Can change project settings',
      owner: true
    },
    {
      actions: 'Can add / remove projects',
      owner: true
    },
    {
      actions: 'Can add / remove / change members',
      owner: true
    },
    {
      actions: 'Can add / remove / change project members',
      owner: true
    },
    {
      actions: 'Can change organization settings',
      owner: true
    }
  ]

  useEffect(() => {
    store.breadcrumbStore.setMultipleBreadcrumbsOptions([
      {
        label: (
          <div className='pointer-events-none cursor-not-allowed'>
            {' '}
            Manage Organization
          </div>
        ),
        link: `${replaceUrl(
          routeConstants.MANAGE_ORGANIZATION,
          store.scopeStore.getScope()['organization-id'] as string
        )}?tab=1`
      },
      {
        label: 'General',
        link: `${replaceUrl(
          routeConstants.MANAGE_ORGANIZATION,
          store.scopeStore.getScope()['organization-id'] as string
        )}?tab=1`
      },
      {
        label: 'Roles & Actions',
        link: `${replaceUrl(
          routeConstants.MANAGE_ORGANIZATION,
          store.scopeStore.getScope()['organization-id'] as string
        )}?tab=1`
      }
    ])
  }, [store.breadcrumbStore, store.scopeStore])

  const tableColumns = [
    {
      columnHeaderStyle: { width: '700px' },
      columnDataStyle: { width: '700px' },
      dataLabel: 'actions',
      heading: (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Action
          </DDSTypography.Paragraph>
        </div>
      ),

      render: (item: any) => (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            className='max-w-[700px]'
          >
            {item}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    // {
    //   title: 'Billing',
    //   label: 'billing',

    //   customStyles: {
    //     width: 200,
    //   },
    //   render: (item: any) => (item ? <img src={successIcon} /> : <></>),
    // },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Member
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'member',

      render: (item: any) => (
        <div className='flex justify-center text-center row items-center'>
          {item ? (
            <CheckmarkOutline size={20} color={pallete.colors.warningGreen} />
          ) : (
            <>-</>
          )}
        </div>
      )
    },
    {
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Owner
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'owner',

      render: (item: any) => (
        <div className='flex justify-center text-center row items-center'>
          {item ? (
            <CheckmarkOutline size={20} color={pallete.colors.warningGreen} />
          ) : (
            <>-</>
          )}
        </div>
      )
    }
  ]
  const { uiStore } = useStoreContext()
  useEffect(() => {
    uiStore.setGlobalLoader(false)
  }, [])
  return (
    <>
      <div className='flex flex-col pt-[24px]'>
        <DDSTypography.Title
          type='h3'
          color={pallete.colors.textDark3}
          variant='semiBold'
        >
          {' '}
          Roles and Actions
        </DDSTypography.Title>
      </div>
      <div className='pt-[32px]'>
        <Table
          data={rolesAndActionsData}
          columns={tableColumns}
          currentPage={1}
          totalCount={10}
          showPagination={false}
        />
      </div>
    </>
  )
}

export default observer(RolesAction)
