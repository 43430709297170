import { DDSTooltip, DDSTypography } from 'den-design-system'
import React, { useEffect, useState } from 'react'
import {
  getAPIGroupUserFilters,
  getApiUserById
} from '../../../api/apiMonitoring/ApiMonitoring.service'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import APIUserGraphUsage from '../APIUserGraphUsage'
import ApiGroupingStringConstants from '../../../constants/ApiGroupingStringConstants'
import { Button } from '../../New/Button/Button'
import Filter from '../../Filter/Filter'
import GroupedAPILogUserTable from '../../../pages/NewVersion/APIMonitoring/GroupedAPILogUserTable'
import { Renew } from '@carbon/icons-react'
import StringConstants from '../../../constants/StringConstants'
import Tabs from '../../common/DDS/Tabs/Tabs'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import Tooltip from '../../New/Tooltip/Tooltip'
import { isUndefined } from 'lodash'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import { replaceUrl } from '../../../utils/UrlUtils'
import routeConstants from '../../../constants/RouteConstants'
import { useStoreContext } from '../../../store/StoreContext'

const GroupedUserViewDetails = () => {
  const store = useStoreContext()
  const { id } = useParams()

  const [userData, setUserData] = useState<any>()

  const [searchParams, setSearchParams] = useSearchParams()
  const [options, setOptions] = useState<any>([])
  const [userLoading, setUserLoading] = useState<boolean>(true)
  const initialPage = searchParams.get('page') ?? '1'
  const [currentPage, setCurrentPage] = useState<number>(parseInt(initialPage))

  const navigate = useNavigate()

  const [tabIndex, setTabIndex] = React.useState<string>(
    searchParams.get('tab') ?? '1'
  )

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.API_MONITORING
    )
  }, [])

  useEffect(() => {
    if (store.filterStore.getFilterDisabled()) {
      store.filterStore.setFilterDisabled(false)
    }
  }, [store.filterStore.getFilterDisabled()])

  const allAPIUserFilter = () => {
    getAPIGroupUserFilters({ $id: id as string })
      .then((response: any) => {
        store.apiDetailsStore.setRefresh(false)
        const filteredOptions = response.data.filter(
          (item: any) =>
            item.heading !== 'Response Status' &&
            item.heading !== 'Users' &&
            item.heading !== 'Client Version'
        )
        setOptions(filteredOptions)
      })
      .catch((err) => {
        return ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.API_MONITORING.API_MONITORING_FILTERS.ERROR
        })
      })
  }

  const viewApiUserById = async () => {
    setUserLoading(true)
    store.uiStore.setGlobalLoader(true)
    getApiUserById({ $id: id as string })
      .then((response: any) => {
        setUserData(response)
        store.apiDetailsStore.setRefresh(false)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        setUserLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  useEffect(() => {
    if (store.filterStore.getFilterDisabled()) {
      store.filterStore.setFilterDisabled(false)
    }
  }, [store.filterStore.getFilterDisabled()])

  useEffect(() => {
    viewApiUserById()
    allAPIUserFilter()
  }, [store.scopeStore.getScope()])

  useEffect(() => {
    if (store.apiDetailsStore.getRefresh() === true) {
      viewApiUserById()
      allAPIUserFilter()
    }
  }, [store.apiDetailsStore.getRefresh()])

  useEffect(() => {
    if (!isUndefined(userData?.userName)) {
      store.breadcrumbStore.setMultipleBreadcrumbsOptions([
        {
          label: ApiGroupingStringConstants.GROUPED_API,
          link:
            replaceUrl(
              routeConstants.API_MONITORING,
              store.scopeStore.getScope()['organization-id'] as string,
              store.scopeStore.getScope()['project-id'] as string,
              store.scopeStore.getScope()['environment-id'] as string
            ) + '?page=1&contextSwitcherTab=2'
        },
        {
          label: (
            <div className='cursor-not-allowed'>
              <div className='pointer-events-none'>{userData?.userName}</div>
            </div>
          ),
          link:
            replaceUrl(
              routeConstants.GROUPED_USER_DETAILS,
              store.scopeStore.getScope()['organization-id'] as string,
              store.scopeStore.getScope()['project-id'] as string,
              store.scopeStore.getScope()['environment-id'] as string
            ).replace(':id', id as string) + '?tab=1'
        },
        {
          label: <div>{tabIndex === '1' ? 'API Logs' : 'API Overview'}</div>,
          link: `${routeConstants.GROUPED_USER_DETAILS.replace(':id', id as string)}?tab=1`
        }
      ])
    }
    return () => {
      if (!isUndefined(userData?.userName)) {
        store.breadcrumbStore.reset()
      }
    }
    // Dependencies updated for stability
  }, [userData?.userName, tabIndex, id, store.breadcrumbStore])

  const tabs = [
    {
      label:
        tabIndex === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            API Logs
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            API Logs
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <GroupedAPILogUserTable />
        </div>
      )
    },
    {
      label:
        tabIndex === '2' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            API Overview
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            API Overview
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <APIUserGraphUsage
            UserData={userData}
            viewApiUserById={viewApiUserById}
          />
        </div>
      )
    }
  ]

  return (
    <div className='flex flex-col w-full'>
      <div className='flex justify-between items-center pt-[24px]'>
        <div>
          <DDSTooltip
            id='overview-tooltip'
            position='top'
            className={`override-tooltip-arrow ${
              typeof userData?.userName === 'string' &&
              userData?.userName.length > 30
                ? userData?.userName
                : '!hidden'
            }`}
            shape='roundedRectangle'
            label={
              <DDSTypography.Paragraph
                size='para'
                variant='regular'
                color={pallete.colors.textDark4}
              >
                {userData?.userName}
              </DDSTypography.Paragraph>
            }
            style={{
              backgroundColor: pallete.colors.surface1,
              zIndex: 999999
            }}
          >
            <DDSTypography.Title
              type='h3'
              variant='semiBold'
              color={pallete.colors.textDark3}
              className='truncate max-w-[600px] override-tooltip-arrow'
            >
              {userData?.userName}
            </DDSTypography.Title>
          </DDSTooltip>
        </div>
        <div className='flex justify-end items-center'>
          <Tooltip id='refresh-tooltip' label='Refresh' position='top'>
            <Button
              id='outline-button'
              type='neutral'
              onClick={() => store.apiDetailsStore.setRefresh(true)}
              startIcon={<Renew color={pallete.colors.neuButtonText} />}
            />
          </Tooltip>
        </div>
      </div>
      <div className='pt-[24px] w-full justify-between relative'>
        <div>
          <Tabs
            tabOptionsStyle={{
              minWidth: 'fit-content',
              width: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '8px 12px',
              marginRight: '8px'
            }}
            tabOptions={tabs}
            id='tabs-grouped-api'
            onChange={(index: any) => {
              if (tabIndex != index) {
                setTabIndex(index.toString())
                setSearchParams({
                  ...searchParams,
                  tab: index.toString()
                })
              }
            }}
            activeTab={tabIndex}
          />
          {tabIndex === '1' && (
            <div className='flex custom-filter flex-row gap-x-[8px] absolute top-[24px] right-0'>
              <Filter
                options={options}
                isScrollable={true}
                disabled={userLoading || store.uiStore.getGlobalLoader()}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default observer(GroupedUserViewDetails)
