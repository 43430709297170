import {
  Alarm,
  CheckmarkOutline,
  Laptop,
  UpdateNow,
  WarningAlt
} from '@carbon/icons-react'
import { DDSTypography, DDSVerticalStepper } from 'den-design-system'
import React, { useEffect, useState } from 'react'
import { formatMonth, timeZone } from '../../../utils/DateTimeUtils'
import { useNavigate, useParams } from 'react-router-dom'

import Copyright from '../../../assets/images/copyright.png'
import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import Spinner from '../../../components/common/Spinner/Spinner'
import VigilGreyOut from '../../../assets/icons/Vigil Logo Greyed out.svg'
import VigilLogo from '../../../assets/icons/vigilTMLogo.svg'
import dayjs from 'dayjs'
import { getAnIncidentByToken } from '../../../api/incident/incident.service'
import pallete from '../../../global/newPallete'
import relativeTime from 'dayjs/plugin/relativeTime'
import routeConstants from '../../../constants/RouteConstants'
import { timeFormats } from '../../../enum/TIME'
import updateLocale from 'dayjs/plugin/updateLocale'
import { useStoreContext } from '../../../store/StoreContext'

const IncidentPublicShare: React.FC = () => {
  const [incidentDetails, setIncidentDetails] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  const { token } = useParams()
  const store = useStoreContext()

  const navigate = useNavigate()

  dayjs.extend(relativeTime)
  dayjs.extend(updateLocale)

  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'a few seconds',
      m: 'a minute',
      mm: '%d minutes',
      h: 'an hour',
      hh: '%d hours',
      d: 'a day',
      dd: '%d days',
      w: 'a week',
      ww: '%d weeks',
      M: 'a month',
      MM: '%d months',
      y: 'a year',
      yy: '%d years'
    }
  })

  const convertToRelativeTime = (dateString: string): string => {
    return dayjs(dateString).fromNow()
  }

  const fetchAnIncidentDetails = () => {
    setLoading(true)
    getAnIncidentByToken({ $token: token as string })
      .then((response: any) => {
        if (response.data) {
          setIncidentDetails(response.data)
          setLoading(false)
        } else {
          navigate(routeConstants.ERROR_PAGE)
        }
      })
      .catch((error) => {
        navigate(routeConstants.ERROR_PAGE)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchAnIncidentDetails()
  }, [])

  const renderIcons = (status: string) => {
    switch (status) {
      case 'In Progress':
        return <Alarm size={24} color={pallete.colors.yellow200} />
      case 'Monitoring':
        return <Laptop size={24} color={pallete.colors.yellow200} />
      case 'Resolved':
        return <CheckmarkOutline size={24} color={pallete.colors.green200} />
    }
  }

  return (
    <>
      <DocumentHeader header={HeaderConstants.INCIDENT} />
      {loading || store.uiStore.getGlobalLoader() ? (
        <Spinner />
      ) : (
        <div className='flex flex-col h-full'>
          <div className='flex flex-col flex-grow'>
            <div className='bg-white relative py h-full'>
              <div className='flex w-full justify-center px-[32px] py-[16px] shadow-[rgba(0,0,0,0.04)_0px_4px_8px_0px]'>
                <img src={VigilLogo} alt='icon' />
              </div>
              <div className='px-[32px] gap-[24px] flex flex-col justify-center '>
                <div className='flex flex-col w-full pt-[32px]'>
                  <div className='flex flex-col justify-center gap-y-[8px] pb-[8px]'>
                    <DDSTypography.Title
                      type='h1'
                      style={{ fontSize: '32px', lineHeight: '40px' }}
                      variant='bold'
                      color={pallete.colors.yellow}
                      className='flex justify-center text-center align-middle'
                    >
                      {incidentDetails?.briefDescription}
                    </DDSTypography.Title>

                    <DDSTypography.Title
                      type='h3'
                      variant='semiBold'
                      color={pallete.colors.textDark10}
                      className='flex justify-center'
                    >
                      Incident Reported for {incidentDetails.projectName} in{' '}
                      {incidentDetails.organizationName}
                    </DDSTypography.Title>
                  </div>
                </div>
                <div className='flex flex-col justify-center gap-y-[8px]'>
                  <div className='flex flex-row custom-text gap-x-[4px] justify-center'>
                    <DDSTypography.Title
                      type='h5'
                      color={pallete.colors.textDark4}
                      variant='medium'
                    >
                      Current Status:
                    </DDSTypography.Title>
                    <DDSTypography.Title
                      type='h5'
                      color={pallete.colors.textDark4}
                      variant='bold'
                    >
                      {incidentDetails?.status}
                    </DDSTypography.Title>
                    <span
                      className='w-[1px] mx-[4px]'
                      style={{ backgroundColor: pallete.colors.textDark3 }}
                    />
                    <DDSTypography.Title
                      type='h5'
                      color={pallete.colors.textDark4}
                      variant='semiBold'
                    >
                      Last updated at
                    </DDSTypography.Title>
                    <DDSTypography.Title
                      type='h5'
                      color={pallete.colors.textDark4}
                      variant='semiBold'
                    >
                      {formatMonth(
                        timeZone(
                          store.userStore.getUserPreference().timeZone,
                          incidentDetails?.updatedAt,
                          timeFormats.fullMonthDateYear
                        )
                      )}
                    </DDSTypography.Title>
                  </div>
                  <div className='flex flex-row justify-center gap-x-[4px] items-center'>
                    <div>
                      <DDSTypography.Title
                        type='h4'
                        color={pallete.colors.textDark4}
                        variant='medium'
                      >
                        Affected Environments:
                      </DDSTypography.Title>
                    </div>
                    <div>
                      <DDSTypography.Title
                        type='h4'
                        color={pallete.colors.textDark4}
                        variant='bold'
                      >
                        {incidentDetails?.environmentType}
                      </DDSTypography.Title>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex items-center'>
                {
                  <div className='flex px-[20%] flex-col gap-y-[32px] w-[100%]'>
                    <DDSTypography.Title
                      type='h3'
                      variant='bold'
                      color={pallete.colors.textDark3}
                      className='pt-[48px]'
                    >
                      History of Incident
                    </DDSTypography.Title>
                    <DDSVerticalStepper
                      className='override-stepper-title'
                      stepItems={incidentDetails?.incidentActivity.map(
                        (item: any) => {
                          if (item.type == 'IDENTIFIED') {
                            return {
                              icon: (
                                <WarningAlt
                                  size={24}
                                  color={pallete.colors.red200}
                                />
                              ),
                              description: (
                                <div>
                                  <DDSTypography.Paragraph
                                    size='para'
                                    variant='regular'
                                    color={pallete.colors.textDark10}
                                    style={{
                                      wordBreak: 'break-all',
                                      whiteSpace: 'normal'
                                    }}
                                  >
                                    {item.message}
                                  </DDSTypography.Paragraph>
                                </div>
                              ),
                              descriptionTitle: (
                                <DDSTypography.Title
                                  type='h4'
                                  variant='bold'
                                  color={pallete.colors.textDark3}
                                >
                                  Identified
                                </DDSTypography.Title>
                              ),
                              title: (
                                <div className='flex flex-col'>
                                  <DDSTypography.Paragraph
                                    size='para'
                                    variant='medium'
                                    color={pallete.colors.textDark6}
                                  >
                                    Posted{' '}
                                    {convertToRelativeTime(item.createdAt)}
                                  </DDSTypography.Paragraph>
                                  <DDSTypography.Paragraph
                                    size='para'
                                    variant='medium'
                                    color={pallete.colors.textDark6}
                                    className='whitespace-nowrap'
                                  >
                                    {formatMonth(
                                      timeZone(
                                        store.userStore.getUserPreference()
                                          .timeZone,
                                        item?.createdAt,
                                        timeFormats.fullMonthDateYearTime
                                      )
                                    )}
                                  </DDSTypography.Paragraph>
                                </div>
                              )
                            }
                          } else if (
                            item.type !== 'STATUS_CHANGED' &&
                            item.type !== 'IDENTIFIED'
                          ) {
                            return {
                              icon: (
                                <UpdateNow
                                  size={24}
                                  color={pallete.colors.warningOrange}
                                />
                              ),
                              description: (
                                <DDSTypography.Paragraph
                                  size='para'
                                  variant='regular'
                                  color={pallete.colors.textDark10}
                                  style={{
                                    wordBreak: 'break-all',
                                    whiteSpace: 'normal'
                                  }}
                                >
                                  {item.message}
                                </DDSTypography.Paragraph>
                              ),
                              descriptionTitle: (
                                <DDSTypography.Title
                                  type='h4'
                                  variant='bold'
                                  color={pallete.colors.textDark3}
                                >
                                  Update
                                </DDSTypography.Title>
                              ),
                              title: (
                                <div className='flex flex-col'>
                                  <DDSTypography.Paragraph
                                    size='para'
                                    variant='medium'
                                    color={pallete.colors.textDark6}
                                  >
                                    Posted{' '}
                                    {convertToRelativeTime(item.createdAt)}
                                  </DDSTypography.Paragraph>
                                  <DDSTypography.Paragraph
                                    size='para'
                                    variant='medium'
                                    color={pallete.colors.textDark6}
                                    className='whitespace-nowrap'
                                  >
                                    {formatMonth(
                                      timeZone(
                                        store.userStore.getUserPreference()
                                          .timeZone,
                                        item?.createdAt,
                                        timeFormats.fullMonthDateYearTime
                                      )
                                    )}
                                  </DDSTypography.Paragraph>
                                </div>
                              )
                            }
                          } else if (item.type == 'STATUS_CHANGED') {
                            return {
                              icon: renderIcons(item?.data?.current),
                              description: (
                                <DDSTypography.Paragraph
                                  size='para'
                                  variant='regular'
                                  color={pallete.colors.textDark10}
                                  style={{
                                    wordBreak: 'break-all',
                                    whiteSpace: 'normal'
                                  }}
                                >
                                  {item.message}
                                </DDSTypography.Paragraph>
                              ),
                              descriptionTitle: (
                                <DDSTypography.Title
                                  type='h4'
                                  variant='bold'
                                  color={pallete.colors.textDark3}
                                >
                                  {item?.data?.current}
                                </DDSTypography.Title>
                              ),
                              title: (
                                <div className='flex flex-col'>
                                  <DDSTypography.Paragraph
                                    size='para'
                                    variant='medium'
                                    color={pallete.colors.textDark6}
                                  >
                                    Posted{' '}
                                    {convertToRelativeTime(item.createdAt)}
                                  </DDSTypography.Paragraph>
                                  <DDSTypography.Paragraph
                                    size='para'
                                    variant='medium'
                                    color={pallete.colors.textDark6}
                                    className='whitespace-nowrap'
                                  >
                                    {formatMonth(
                                      timeZone(
                                        store.userStore.getUserPreference()
                                          .timeZone,
                                        item?.createdAt,
                                        timeFormats.fullMonthDateYearTime
                                      )
                                    )}
                                  </DDSTypography.Paragraph>
                                </div>
                              )
                            }
                          }
                        }
                      )}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
          <div className='w-full flex flex-row items-center justify-center override-copyright gap-x-[4px] bg-white h-[40px] pb-[20px]'>
            <img
              src={Copyright}
              alt='Copyright'
              style={{ width: '20px', height: '20px' }}
            />
            <DDSTypography.Paragraph
              size='para'
              color={pallete.colors.textDark4}
            >
              2024
            </DDSTypography.Paragraph>
            <img src={VigilGreyOut} alt='Vigil Grey Out' />
          </div>
        </div>
      )}
    </>
  )
}
export default IncidentPublicShare
