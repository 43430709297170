export const gaEventConstants = {
  //AHC
  ADDED_TEAMMATES: 'Added teammates to be alerted',
  ADDED_AUTHENTICATION:
    'Added Authentication details to login and perform the check',
  ADD_CHECK: 'Add Check',
  UPDATED_CHECK: 'Updated Check',
  ADDED_CHECK: 'Added Check',
  INITIATE_ADD_CHECK: 'Initiating Add Check',
  TOTAL_CHECKS: 'Total Checks',
  TOTAL_UP_CHECKS: 'Total Checks Up',
  TOTAL_DOWN_CHECKS: 'Total Checks Down',
  EDIT_CHECK: 'Edit Check',
  DELETE_CHECK: 'Delete Check',
  VIEW_SINGLE_CHECK: 'View Single Check',

  //PROJECT
  INITIATE_CREATE_PROJECT: 'Initiating Create Project',
  NEW_PROJECT_NAME_CONFIRMED: 'Name of new project confirmed',
  CREATED_PROJECT: 'Created Project',
  SWITCH_PROJECT: 'Project Switch',
  VIEW_PROJECT: 'View Project',
  INITIATING_DELETE_PROJECT: 'Initiating Delete Project',
  DELETE_PROJECT: 'Delete Project',
  INITIATE_ADDING_PROJECT_MEMBERS: 'Initiate Adding Project Members',
  ADDED_PROJECT_MEMBERS: 'Added Project Members',
  SWITCH_BETWEEN_PROJECT_COMPONENTS: 'Switch between Project Components',

  //ENVIRONMENT
  ADDED_ENVIRONMENT: 'Added Environment',
  INITIATE_ADD_ENVIRONMENT: 'Initiating Add an Environment',
  SWITCH_ENVIRONMENT: 'Environment Switch',
  VIEW_ENVIRONMENTS: 'View Environments',
  SWITCH_ENVIRONMENT_TABS_ENVIRONMENT_CARD:
    'Switch between environments in Environment Card',

  //ORGANIZATION
  ORGANIZATION_CREATED: 'New Organization created',
  NEW_ORGANIZATION_NAME_CONFIRMED: 'Name of new organization confirmed',
  INITIATE_CREATE_ORGANIZATION: 'Initiating Create an Organization',
  SWITCH_ORGANIZATION: 'Organization Switch',

  //JOBS
  UPDATED_JOB: 'Updated Job',
  CREATED_JOB: 'Created Job',
  INITIATE_CREATE_JOB: 'Initiating Create Job',
  CLOSE_CREATE_JOB_POP: 'Close Create Job Popup',
  EDIT_JOB_POPUP: 'Open Edit Job Popup',
  VIEW_SINGLE_JOB: 'View Single Job',
  TOGGLE_MONITOR: 'Toggle Monitoring Option',
  DOWNLOAD_JOB_LOGS: 'Download Job Logs',
  RETURN_TO_ALL_JOBS: 'Return to all Jobs',
  RETURN_TO_SELECTED_JOB_LOG: 'Return to a selected Job Log',
  DELETE_JOB: 'Delete Job',

  //PASSWORD RESET
  RESEND_LINK: 'Resend Link',

  //SIGN IN
  SIGN_IN: 'Sign In',
  RESET_PASSWORD: 'Reset Password',
  TERMS_OF_SERVICE: 'Terms of Service',
  PRIVACY_POLICY: 'Privacy Policy',

  //SIGN_UP
  CREATE_ACCOUNT: 'Create Account',

  //API MONITORING
  VIEW_SINGLE_API_RECORD: 'View Single API Record',
  DELETE_API_RECORD: 'Delete API Record',
  EXPAND_REQUEST_BODY: 'Expand Request Body',
  EXPAND_RESPONSE_BODY: 'Expand Response Body',
  SWITCH_BETWEEN_PARAMS: 'View API Param Switch in API Details Page',
  OPEN_PARAMS_POPUP: 'Open API Params Popup',

  //INCIDENT
  VIEW_SINGLE_INCIDENT_RECORD: 'View Single Incident Record',
  INCIDENT_TABLE: 'Incident Table',

  //EXCEPTION
  VIEW_EXCEPTION_CONTEXT: 'View Exception Context',
  RAW_EXCEPTION_STACK: 'Raw Exception Stack',
  FULL_EXCEPTION_STACK: 'Full Exception Stack',
  BOOKMARK_EXCEPTION: 'Toggled Bookmark Exception',
  EXCEPTION_ACTIVITY_REPLY: 'Exception Activity Reply',
  EXCEPTION_ACTIVITY_EDIT: 'Exception Activity Edit',
  EXCEPTION_ACTIVITY_DELETE: 'Exception Activity Delete',
  EXCEPTION_ACTIVITY_VIEW_REPLIES: 'Exception Activity View Replies',

  //VERIFY
  VERIFY: 'Verify',
  IGNORE: 'Ignore',

  //NOTIFICATION DROPDOWN
  SWITCH_TAB_NOTIFICATION_DROPDOWN:
    'Switch tabs between Notifications and Invites',
  OPEN_NOTIFICATION_DROPDOWN: 'Open Notification Dropdown',
  VIEW_ALL_NOTIFICATIONS: 'View all Notifications',
  VIEW_ALL_INVITES: 'View all Invites',

  //ACCOUNT SETTINGS
  RESEND_VERIFICATION: 'Resend Verification',
  UPDATE_PASSWORD: 'Update Password',

  //GENERAL SETTINGS
  OPEN_TRANSFER_OWNERSHIP_POPUP: 'Open Transfer Ownership Popup',
  SELECT_MEMBER_TO_TRANSFER: 'Select Member to Transfer Ownership',
  TRANSFERRED_OWNERSHIP: 'Transferred Ownership',

  //FILTERS
  OPEN_FILTER_DROPDOWN: 'Open Filter Dropdown',
  APPLY_FILTERS: 'Apply Filters',
  CLEAR_FILTERS: 'Clear Filters',
  CLEAR_EACH_FILTER: 'Clear each filter one by one',

  //COPY ON CLICK
  COPY_ON_CLICK: 'Copy on Click',

  //SIDE NAVBAR
  COLLAPSE: 'Collapse Side Navbar',
  EXPAND: 'Expand Side Navbar',

  //PAGE CHANGE
  API_MONITORING_TABLE_PAGE_CHANGE: 'Page Change - API Monitoring table',
  CREATED_JOBS_PAGE_CHANGE: 'Page Change - Created Jobs table',
  AHC_DASHBOARD_PAGE_CHANGE: 'Page Change - AHC Dashboard',
  ERROR_MONITORING_PAGE_CHANGE: 'Page Change - Error Monitoring',

  //BREADCRUMB
  GO_BACK_BREADCRUMB: 'Go Back - Breadcrumb'
}

export const gaPageConstants = {
  //AHC
  ALERT_SETTINGS_STEP: 'Alert Settings Step',
  AUTHENTICATION_STEP: 'Authentication Step',
  BASIC_INFO: 'Basic Info',
  CUSTOM_HEADER_STEP: 'Custom Header Step',
  APP_HEALTH_CHECK: 'App Health Check',
  AHC_SUMMARY: 'AHC Summary',
  AHC_DASHBOARD: 'AHC Dashboard',

  //INCIDENT
  INCIDENT_TABLE: 'Incident Table',

  //PROJECT
  SETTINGS: 'Settings',
  PROJECT_NAME_STEP: 'Project Name Step',
  ENVIRONMENTS_STEP: 'Environments Step',

  //ENVIRONMENT
  CREATE_ENVIRONMENT: 'Create Environment',
  PROJECT_ENVIRONMENT_HEADER: 'Project Environment Header',

  //ORGANIZATION
  CREATED_ORGANIZATION: 'Created Organization',
  CREATE_ORGANIZATION: 'Create Organization',
  NO_ORGANIZATION: 'No Organization',

  //JOBS
  JOB_MONITORING: 'Job Monitoring',
  JOBS_POPUP: 'Jobs Popup',
  JOB_LOGS_PAGE: 'Job Logs',
  CREATED_JOBS: 'Created Jobs',

  //PASSWORD RESET
  PASSWORD_RESET: 'Password Reset',

  //SIGN IN
  SIGN_IN: 'Sign In',

  //SIGN UP
  SIGN_UP: 'Sign Up',

  //API MONITORING
  API_MONITORING_TABLE: 'API Monitoring Table',
  API_DETAIL_PAGE: 'API Details Page',

  //EXCEPTION
  ERROR_MONITORING_PAGE: 'Error Monitoring Page',
  EXCEPTION_LOG_PAGE: 'Exception Log Page',
  EXCEPTION_STACK: 'Exception Stack',
  EXCEPTION_COMMENT_SECTION: 'Exception Comment Section',

  //SETTINGS
  ACCOUNT_SETTINGS: 'Account Settings',
  GENERAL_SETTINGS: 'General Settings',
  TRANSFER_OWNERSHIP: 'Transfer Ownership',
  PROJECT_SETTINGS: 'Project Settings',
  GENERAL_SETTINGS_CARD_PROJECT: 'General Settings Card in Project',
  PROJECT_ENVIRONMENTS_CARD: 'Project Environments Card',
  MANAGE_PROJECT_MEMBERS: 'Manage Project Members',
  ASSIGN_MEMBERS: 'Assign Members',
  PROJECT_PAGE_NAVIGATION: 'Project Page Navigation',

  //NOTIFICATION
  NOTIFICATION_DROPDOWN: 'Notification Dropdown',

  //SIDE NAVBAR
  SIDE_NAVBAR: 'Side Navbar',

  //TOP NAVBAR
  TOP_NAVBAR: 'Top Navbar',

  //VERIFY
  VERIFY: 'Verify Account',

  //FILTERS
  FILTERS: 'Filters',

  //COPY ON CLICK
  COPY_ON_CLICK: 'Copy On Click',

  //DELETE POPUP
  DELETE_POPUP: 'Delete Popup',

  //BREADCRUMBS
  BREADCRUMBS: 'Breadcrumbs'
}
