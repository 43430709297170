import { ChevronRight } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import OverviewStringConstants from '../../constants/OverviewStringConstants'
import React from 'react'
import { observer } from 'mobx-react-lite'
import pallete from '../../global/newPallete'

interface viewCardProps {
  title: string
  onClick: any
}
const ViewCard: React.FC<viewCardProps> = ({ title, onClick }) => {
  return (
    <div className='flex flex-col items-start gap-[8px] w-full'>
      <DDSTypography.Title
        type='h5'
        variant='semiBold'
        className='whitespace-nowrap'
        color={pallete.colors.textDark3}
      >
        {title}
      </DDSTypography.Title>
      <div
        onClick={onClick}
        className='cursor-pointer flex items-center gap-[4px]'
      >
        <DDSTypography.Paragraph
          size='para'
          variant='regular'
          color={pallete.colors.textDark2}
        >
          {OverviewStringConstants.VIEW}
        </DDSTypography.Paragraph>
        <ChevronRight size={16} color={pallete.colors.textDark2} />
      </div>
    </div>
  )
}

export default observer(ViewCard)
