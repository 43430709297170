import {
  Bookmark,
  BookmarkFilled,
  CaretDown,
  CaretUp,
  ChevronRight
} from '@carbon/icons-react'
import { DDSTooltip, DDSTypography } from 'den-design-system'
import {
  ExceptionStatusTypes,
  SeverityTypes
} from '../../../../api/exception/Exception.types'
import React, { useEffect, useRef } from 'react'
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import { formatMonth, timeZone } from '../../../../utils/DateTimeUtils'
import {
  getAllExceptions,
  getExceptionFilters,
  updateAnException,
  updateSelectedExceptions
} from '../../../../api/exception/Exception.service'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../../utils/UrlUtils'
import { isEmpty, isNull } from 'lodash'

import { AccessRoleConstants } from '../../../../constants/AccessRoleConstants'
import ConfirmationPopup from '../../../../components/common/Exceptions/ConfirmationPopup'
import DocumentHeader from '../../../../components/Helmet/DocumentHeader'
import Filter from '../../../../components/Filter/Filter'
import { Link } from '@mui/material'
import NoFilterImage from '../../../../assets/images/folder-not-found 1.svg'
import NoRecord from '../../../../components/common/NoContent/NoRecord'
import NoRecordImage from '../../../../assets/images/setting.svg'
import { ProjectType } from '../../../../enum/ProjectType.enum'
import SecureComponent from '../../../../components/Layout/SecureComponent'
import SeverityDropDown from '../../../../components/common/Exceptions/SeverityDropdown'
import Spinner from '../../../../components/common/Spinner/Spinner'
import StatusDropDown from '../../../../components/common/Exceptions/StatusDropdown'
import StringConstants from '../../../../constants/StringConstants'
import Table from '../../../../components/common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../components/common/DDS/Toast/Toast'
import emptyAvatar from '../../../../assets/images/EmptyAvatar.svg'
import { generateRandomId } from '../../../../utils/CommonUtils'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../global/newPallete'
import routeConstants from '../../../../constants/RouteConstants'
import { severityColorMap } from '../../../../constants/ExceptionsPageConstants'
import { timeFormats } from '../../../../enum/TIME'
import { useState } from 'react'
import { useStoreContext } from '../../../../store/StoreContext'

type SortOrder = 'ASC' | 'DESC' | 'CLEAR'
type SortState = null | SortOrder | 'CLEAR'

export const searchContextsLookupforExceptions: Record<
  string,
  {
    context: string
    label: string
    query: string
  }
> = {
  exception_type: {
    context: 'exception_type',
    label: 'Exception Type',
    query: 'exception-type-like'
  },
  exception_message: {
    context: 'exception_message',
    label: 'Exception Message',
    query: 'exception-message-like'
  }
}

export const SearchContextMapForExceptions = {
  'exception-type-like': 'exception_type',
  'exception-message-like': 'exception_message'
}

const AllExceptions = () => {
  const store = useStoreContext()
  const navigate = useNavigate()
  const { pathIds } = useParams()
  const [selectedRows, setSelectedRows] = useState<any>([])
  const [options, setOptions] = React.useState<any>([])
  const [activeTab, setActiveTab] = React.useState<string>('1')
  const [tableData, setTableData] = useState<any>()
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const [currentGroupId, setCurrentGroupId] = useState<any>('')
  const [preference, setPreference] = React.useState<string>('')

  const [loading, setLoading] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const initialPage = searchParams.get('page') ?? '1'
  const [currentPage, setCurrentPage] = useState<number>(parseInt(initialPage))
  const [allExceptionsCount, setAllExceptionsCount] = React.useState(0)
  const [filterOptions, setFilterOptions] = useState<any>([])
  const [status, setStatus] = useState<ExceptionStatusTypes>()

  const tableRef = useRef<HTMLDivElement>(null)
  const [tableHeight, setTableHeight] = useState<number | null>(null)

  const [activeSortOrder, setActiveSortOrder] = useState<{
    field: string | null
    order: SortOrder | null
  }>({
    field: null,
    order: null
  })

  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY
  const isSalesforceProject = projectType === ProjectType.SALESFORCE_ONLY

  useEffect(() => {
    if (tableRef.current) {
      setTableHeight(tableRef.current.clientHeight)
    }
  }, [tableData, loading])

  const updateStatus = (id: string, status: ExceptionStatusTypes) => {
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (selectedRows.length === 0) {
      const isSameStatus =
        tableData.filter((data: any) => data.id === id)[0].exceptionStatus ===
        status
      if (!isSameStatus) {
        setLoading(true)

        store.uiStore.setGlobalLoader(true)
        updateAnException({
          exceptionStatus: status,
          $exceptionLogId: id
        })
          .then(() => {
            ToastNotification({
              type: 'success',
              message: isFullStackProject
                ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.SUCCESS
                : ToastMessageConstants.ERROR.UPDATE_ERROR.SUCCESS
            })
            fetchExceptionRecords({
              ...queryParams,
              ...searchParams,
              size: StringConstants.DEFAULT_TABLE_SIZE,
              page: currentPage,
              'expand-activity': true
            })
            setLoading(false)
          })
          .catch(() => {
            ToastNotification({
              type: 'error',
              message: isFullStackProject
                ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.ERROR
                : ToastMessageConstants.ERROR.UPDATE_ERROR.ERROR
            })
            setLoading(false)
          })
          .finally(() => store.uiStore.setGlobalLoader(false))
      }
    } else {
      const exceptionIds: string[] = []
      selectedRows.forEach((exception: any) => exceptionIds.push(exception.id))
      setLoading(true)
      updateSelectedExceptions({
        ids: exceptionIds,
        exceptionStatus: status
      })
        .then(() => {
          ToastNotification({
            type: 'success',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.SUCCESS
              : ToastMessageConstants.ERROR.UPDATE_ERROR.SUCCESS
          })
          setSelectedRows([])
          fetchExceptionRecords({
            ...queryParams,
            ...searchParams,
            page: currentPage,
            size: StringConstants.DEFAULT_TABLE_SIZE,
            'expand-activity': true
          })
          setLoading(false)
        })
        .catch((err: any) => {
          ToastNotification({
            type: 'error',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.ERROR
              : ToastMessageConstants.ERROR.UPDATE_ERROR.ERROR
          })
          setLoading(false)
        })
    }
  }

  const updateSeverity = (id: string, severity: string) => {
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (selectedRows.length === 0) {
      setLoading(true)
      updateAnException({
        severity: severity as SeverityTypes,
        $exceptionLogId: id
      })
        .then(() => {
          ToastNotification({
            type: 'success',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.SUCCESS
              : ToastMessageConstants.ERROR.UPDATE_ERROR.SUCCESS
          })
          fetchExceptionRecords({
            ...queryParams,
            ...searchParams,
            size: StringConstants.DEFAULT_TABLE_SIZE,
            page: currentPage,
            'expand-activity': true
          })
          setLoading(false)
          setPreference('')
          setModalOpen(false)
        })
        .catch(() => {
          ToastNotification({
            type: 'error',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.ERROR
              : ToastMessageConstants.ERROR.UPDATE_ERROR.ERROR
          })
          setLoading(false)
          setModalOpen(false)
        })
    } else {
      const exceptionIds: string[] = []
      selectedRows.forEach((exception: any) => exceptionIds.push(exception.id))
      setLoading(true)
      updateSelectedExceptions({
        ids: exceptionIds,
        severity: severity
      })
        .then(() => {
          ToastNotification({
            type: 'success',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.SUCCESS
              : ToastMessageConstants.ERROR.UPDATE_ERROR.SUCCESS
          })
          setModalOpen(false)
          setSelectedRows([])

          fetchExceptionRecords({
            ...queryParams,
            ...searchParams,
            page: currentPage,
            size: StringConstants.DEFAULT_TABLE_SIZE,
            'expand-activity': true
          })
          setLoading(false)
        })
        .catch((err: any) => {
          ToastNotification({
            type: 'error',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.ERROR
              : ToastMessageConstants.ERROR.UPDATE_ERROR.ERROR
          })
          setLoading(false)
        })
    }
  }

  const updateBookmark = (id: string, bookmarkState: boolean) => {
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    setLoading(true)
    if (selectedRows.length === 0) {
      const isSameBookmarkState =
        tableData.filter((data: any) => data.id === id)[0].isBookmarked ===
        bookmarkState
      if (!isSameBookmarkState) {
        updateAnException({
          isBookmarked: bookmarkState,
          $exceptionLogId: id
        })
          .then(() => {
            ToastNotification({
              type: 'success',
              message: isFullStackProject
                ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.SUCCESS
                : ToastMessageConstants.ERROR.UPDATE_ERROR.SUCCESS
            })
            fetchExceptionRecords({
              ...queryParams,
              ...searchParams,
              size: StringConstants.DEFAULT_TABLE_SIZE,
              page: currentPage,
              'expand-activity': true
            })
            setLoading(false)
          })
          .catch(() => {
            ToastNotification({
              type: 'error',
              message: isFullStackProject
                ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.ERROR
                : ToastMessageConstants.ERROR.UPDATE_ERROR.ERROR
            })
            setLoading(false)
          })
      }
    } else {
      const exceptionIds: string[] = []
      selectedRows.forEach((exception: any) => exceptionIds.push(exception.id))
      setLoading(true)
      updateSelectedExceptions({
        ids: exceptionIds,
        isBookmarked: bookmarkState
      })
        .then(() => {
          ToastNotification({
            type: 'success',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.SUCCESS
              : ToastMessageConstants.ERROR.UPDATE_ERROR.SUCCESS
          })
          setSelectedRows([])
          fetchExceptionRecords({
            ...queryParams,
            ...searchParams,
            page: currentPage,
            size: StringConstants.DEFAULT_TABLE_SIZE,
            'expand-activity': true
          })
          setLoading(false)
        })
        .catch((err: any) => {
          ToastNotification({
            type: 'error',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.ERROR
              : ToastMessageConstants.ERROR.UPDATE_ERROR.ERROR
          })
          setLoading(false)
        })
    }
  }

  const fetchExceptionRecords = (queryParams: any) => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    store.uiStore.setGlobalLoader(true)
    const searchParams = new URLSearchParams(location.search)

    // Retrieve sort parameters from URL
    const urlSortField = searchParams.get('sortField')
    const urlSortOrder = searchParams.get('sortOrder')
    setLoading(true)
    const params: any = {
      ...queryParams
    }
    if (urlSortField && urlSortOrder && urlSortOrder !== 'CLEAR') {
      params[urlSortField] = urlSortOrder
    }
    getAllExceptions(params)
      .then((response: any) => {
        if (response.data.length > 0 || store.filterStore.getFiltersApplied()) {
          store.filterStore.setFilterDisabled(false)
        } else {
          store.filterStore.setFilterDisabled(true)
        }
        setTableData(response.data)
        setCurrentPage(response.page)
        store.exceptionsStoreV2.setAllExceptions(response.data)
        setAllExceptionsCount(response.totalCount)
        store.filterStore.setFilters(response.filters)
        store.exceptionStore.setIsRefreshed(false)

        // Update active sort order based on URL params
        if (urlSortField && urlSortOrder) {
          setActiveSortOrder({
            field: urlSortField,
            order: urlSortOrder as SortOrder
          })
        }
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: isFullStackProject
            ? ToastMessageConstants.EXCEPTIONS.GET_ALL_EXCEPTIONS.ERROR
            : ToastMessageConstants.ERROR.GET_ALL_ERROR.ERROR
        })
      })
      .finally(() => {
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })
  }

  const sortData = (sortField: string, sortOrder: SortOrder) => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    store.uiStore.setGlobalLoader(true)
    setLoading(true)

    const params: any = {
      ...allQueryParams,
      ...searchParams,
      size: StringConstants.DEFAULT_TABLE_SIZE,
      page: currentPage,
      'expand-activity': true
    }

    // Handle "CLEAR" by removing sort-related params
    if (sortOrder !== 'CLEAR') {
      params[sortField] = sortOrder
      // Persist sort information in URL
      const searchParams = new URLSearchParams(location.search)
      searchParams.set('sortField', sortField)
      searchParams.set('sortOrder', sortOrder)
      window.history.replaceState({}, '', `?${searchParams.toString()}`)
    } else {
      delete params[sortField]
      // Remove sort params from URL
      const searchParams = new URLSearchParams(location.search)
      searchParams.delete('sortField')
      searchParams.delete('sortOrder')
      window.history.replaceState({}, '', `?${searchParams.toString()}`)
    }
    getAllExceptions(params)
      .then((response: any) => {
        if (response.data.length > 0 || store.filterStore.getFiltersApplied()) {
          store.filterStore.setFilterDisabled(false)
        } else {
          store.filterStore.setFilterDisabled(true)
        }
        setTableData(response.data)
        setCurrentPage(response.page)
        store.exceptionsStoreV2.setAllExceptions(response.data)
        setAllExceptionsCount(response.totalCount)
        store.filterStore.setFilters(response.filters)
        store.exceptionStore.setIsRefreshed(false)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: isFullStackProject
            ? ToastMessageConstants.EXCEPTIONS.GET_ALL_EXCEPTIONS.ERROR
            : ToastMessageConstants.ERROR.GET_ALL_ERROR.ERROR
        })
      })
      .finally(() => {
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })
  }

  const handleSortCycle = (sortField: string) => {
    setActiveSortOrder((prev) => {
      if (prev.field === sortField) {
        // Cycle through ASC, DESC, and CLEAR
        const nextOrder =
          prev.order === 'ASC'
            ? 'DESC'
            : prev.order === 'DESC'
              ? 'CLEAR'
              : 'ASC'

        // Always call sortData, even for "CLEAR"
        sortData(sortField, nextOrder)

        return {
          field: nextOrder !== 'CLEAR' ? sortField : null,
          order: nextOrder
        }
      } else {
        // Activate new field with ASC
        sortData(sortField, 'ASC')
        return { field: sortField, order: 'ASC' }
      }
    })
  }

  // Initialize sorting from URL on component mount
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const urlSortField = searchParams.get('sortField')
    const urlSortOrder = searchParams.get('sortOrder')

    if (urlSortField && urlSortOrder) {
      // Initialize the sort state from URL
      setActiveSortOrder({
        field: urlSortField,
        order: urlSortOrder as SortOrder
      })
    }
  }, [location.search])

  const fetchExceptionFilters = () => {
    store.uiStore.setGlobalLoader(true)
    getExceptionFilters()
      .then((response: any) => {
        const statusFilters = response.data[2]
        statusFilters.values = statusFilters.values.filter(
          (value: any) =>
            value.displayText !== ExceptionStatusTypes.SNOOZED &&
            value.displayText !== ExceptionStatusTypes.IGNORED
        )
        response.data[2] = statusFilters
        setFilterOptions(response.data)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: isFullStackProject
            ? ToastMessageConstants.EXCEPTIONS.EXCEPTION_FILTERS.ERROR
            : ToastMessageConstants.ERROR.ERROR_FILTERS.ERROR
        })
      })
      .finally(() => {
        store.uiStore.setGlobalLoader(false)
      })
  }
  useEffect(() => {
    const pageFromQueryParams = searchParams.get('page')
    if (!isNull(pageFromQueryParams)) {
      setCurrentPage(Number(pageFromQueryParams))
    } else {
      const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
      setSearchParams({ ...allQueryParams, page: '1' })
      setCurrentPage(1)
    }
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (!isEmpty(store.scopeStore.getScope()['environment-id'])) {
      fetchExceptionRecords({
        ...allQueryParams,
        size: StringConstants.DEFAULT_TABLE_SIZE
      })
      fetchExceptionFilters()
    }
  }, [searchParams, store.scopeStore.getScope()])

  const routeExceptionLog = (id: number, type: string) => {
    store.exceptionStore.setId(id.toString())
    store.exceptionStore.setType(type)

    const route = replaceUrl(
      routeConstants.ERROR_DETAILS,
      store.scopeStore.getScope()['organization-id'] as string,
      store.scopeStore.getScope()['project-id'] as string,
      store.scopeStore.getScope()['environment-id'] as string
    ).replace(':id', String(id))
    const queryParams = { tab: '2' }
    navigate({ pathname: route, search: `?${createSearchParams(queryParams)}` })
  }

  useEffect(() => {
    setActiveTab(searchParams.get('tab') as string)
  }, [])

  useEffect(() => {
    if (store.exceptionStore.getIsRefreshed()) {
      const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
      fetchExceptionRecords({
        ...allQueryParams,
        size: StringConstants.DEFAULT_TABLE_SIZE
      })
    }
  }, [store.exceptionStore.getIsRefreshed()])

  const AllExceptionsColumns = [
    {
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => {
        return (
          <DDSTooltip
            id={generateRandomId()}
            label={fullItem['isBookmarked'] ? 'Bookmarked' : 'Bookmark'}
            position='top'
            shape='roundedRectangle'
            theme='light'
            className={`override-tooltip-arrow border `}
            style={{ zIndex: 99999 }}
          >
            <div
              onClick={() =>
                updateBookmark(fullItem['id'], !fullItem['isBookmarked'])
              }
              className='cursor-pointer'
              style={{ width: '12px' }}
            >
              {fullItem['isBookmarked'] ? (
                <BookmarkFilled color={pallete.colors.primary2} />
              ) : (
                <Bookmark color={pallete.colors.stroke4} />
              )}
            </div>
          </DDSTooltip>
        )
      }
    },
    {
      columnDataStyle: { width: '130px' },
      heading: (
        <div className='flex whitespace-nowrap space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              variant='semiBold'
              size='caption'
              className='text-start flex whitespace-nowrap'
              color={pallete.colors.textDark6}
            >
              {isFullStackProject ? 'Exception Type' : 'Error Type'}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-all-exception-type')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-all-exception-type' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-all-exception-type' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-all-exception-type' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-all-exception-type' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'exceptionType',
      render: (text: any, fullItem: any) => (
        <DDSTooltip
          id={generateRandomId()}
          label={text}
          shape='roundedRectangle'
          theme='light'
          className={`border override-tooltip-arrow max-w-[90%] break-all ${
            typeof text === 'string' && text.length > 15 ? text : '!hidden'
          }`}
          style={{
            borderColor: pallete.colors.stroke2,
            zIndex: 99999
          }}
        >
          <div className='flex justify-start'>
            <DDSTypography.Paragraph
              variant='medium'
              size='para'
              className='text-start truncate  max-w-[130px] cursor-default'
            >
              {text}
            </DDSTypography.Paragraph>
          </div>
        </DDSTooltip>
      )
    },
    {
      columnDataStyle: { width: '120px' },
      heading: (
        <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              variant='semiBold'
              size='caption'
              className='text-center flex whitespace-nowrap'
              color={pallete.colors.textDark6}
            >
              {isFullStackProject ? 'Exception Message' : 'Error Message'}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-all-exception-message')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-all-exception-message' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-all-exception-message' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-all-exception-message' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-all-exception-message' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'exceptionMessage',
      render: (text: any) => (
        <DDSTooltip
          id={generateRandomId()}
          label={text}
          shape='roundedRectangle'
          theme='light'
          className={`border override-tooltip-arrow max-w-[90%] break-all ${
            typeof text === 'string' && text.length > 15 ? text : '!hidden'
          }`}
          style={{
            borderColor: pallete.colors.stroke2,
            zIndex: 99999
          }}
        >
          <div className='flex justify-center text-center row items-center'>
            <DDSTypography.Paragraph
              variant='medium'
              size='para'
              className='text-center truncate  w-[120px] cursor-default'
            >
              {text}
            </DDSTypography.Paragraph>
          </div>
        </DDSTooltip>
      )
    },
    {
      heading: (
        <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              variant='semiBold'
              size='caption'
              className='text-center flex whitespace-nowrap'
              color={pallete.colors.textDark6}
            >
              Client Version
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-all-exception-client-version')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field ===
                    'sort-all-exception-client-version' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field ===
                          'sort-all-exception-client-version' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field ===
                    'sort-all-exception-client-version' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field ===
                          'sort-all-exception-client-version' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'clientVersion',
      render: (text: any) => (
        <div className='flex justify-center'>
          <DDSTypography.Paragraph
            variant='medium'
            size='para'
            className='text-center whitespace-nowrap'
          >
            {text ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnDataStyle: { width: '120px' },
      heading: (
        <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              variant='semiBold'
              size='caption'
              className='text-center flex whitespace-nowrap'
              color={pallete.colors.textDark6}
            >
              Reported at
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-all-reported-time')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-all-reported-time' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-all-reported-time' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-all-reported-time' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-all-reported-time' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'reportedAt',
      render: (text: any) => (
        <DDSTooltip
          id={generateRandomId()}
          label={formatMonth(
            timeZone(
              store.userStore.getUserPreference().timeZone,
              text,
              timeFormats.fullMonthDateYearTime
            )
          )}
          theme='light'
          className={`border override-tooltip-arrow ${
            typeof text === 'string' && text.length > 15 ? text : '!hidden'
          }`}
          style={{ borderColor: pallete.colors.stroke2, zIndex: 99999 }}
          position='top'
          shape='roundedRectangle'
        >
          <div className='flex justify-center text-center row items-center'>
            <DDSTypography.Paragraph
              variant='medium'
              size='para'
              className='text-center  truncate max-w-[120px] cursor-default'
            >
              {formatMonth(
                timeZone(
                  store.userStore.getUserPreference().timeZone,
                  text,
                  timeFormats.fullMonthDateYearTime
                )
              )}
            </DDSTypography.Paragraph>
          </div>
        </DDSTooltip>
      )
    },
    {
      heading: (
        <DDSTypography.Paragraph
          variant='semiBold'
          size='caption'
          className='text-center whitespace-nowrap flex justify-center'
          color={pallete.colors.textDark6}
        >
          Assigned to
        </DDSTypography.Paragraph>
      ),
      dataLabel: 'assignedTo',
      render: (text: any, fullItem: any) => {
        return (
          <div className='flex flex-row justify-center w-full'>
            {fullItem.assignedTo?.user?.avatar ? (
              <DDSTooltip
                label={
                  fullItem.assignedTo?.user?.firstName +
                  ' ' +
                  fullItem.assignedTo?.user?.lastName
                }
                position='top'
                shape='roundedRectangle'
                id={generateRandomId()}
                theme='light'
                className={`border override-tooltip-arrow `}
                style={{ borderColor: pallete.colors.stroke2, zIndex: 99999 }}
              >
                <div className='flex justify-center w-full'>
                  <img
                    src={fullItem.assignedTo?.user?.avatar}
                    className='rounded-full h-[24px] w-[24px]'
                  />
                </div>
              </DDSTooltip>
            ) : (
              <img
                src={emptyAvatar}
                className='rounded-full h-[24px] w-[24px]'
              />
            )}
          </div>
        )
      }
    },
    {
      heading: (
        <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              variant='semiBold'
              size='caption'
              className='text-center flex whitespace-nowrap'
              color={pallete.colors.textDark6}
            >
              Severity
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-all-preferred-severity')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-all-preferred-severity' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-all-preferred-severity' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-all-preferred-severity' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-all-preferred-severity' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'severity',
      render: (text: any, fullItem: any) => {
        const setSeverity = (severity: SeverityTypes) => {
          setCurrentGroupId(fullItem['id'])
          setPreference(severity)
          if (selectedRows.length === 0 && fullItem['severity'] !== severity) {
            setModalOpen(true)
          } else if (selectedRows.length > 0) {
            setModalOpen(true)
          }
        }
        return (
          <div className=' flex justify-center'>
            <SeverityDropDown
              label={text}
              onSelect={setSeverity}
              disableNoSubscription={store.userStore.getNoActiveSubscription()}
              disabled={fullItem.exceptionStatus === 'Resolved'}
            />
          </div>
        )
      }
    },
    {
      heading: (
        <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              variant='semiBold'
              size='caption'
              className='text-center flex whitespace-nowrap'
              color={pallete.colors.textDark6}
            >
              Status
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-exception-status')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-exception-status' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-exception-status' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-exception-status' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-exception-status' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'exceptionStatus',
      render: (text: any, fullItem: any) => {
        const setStatus = (status: ExceptionStatusTypes) => {
          updateStatus(fullItem['id'], status)
        }
        return (
          <div className=' flex justify-center'>
            <StatusDropDown
              label={text}
              onSelect={setStatus}
              disabled={store.uiStore.getGlobalLoader()}
            />
          </div>
        )
      }
    },
    {
      columnHeaderStyle: { width: '20px' },
      columnDataStyle: { width: '20px' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div>
          <ChevronRight
            size={16}
            color={pallete.colors.primary2}
            className='cursor-pointer'
            onClick={() => {
              routeExceptionLog(fullItem['id'], fullItem['exceptionType'])
            }}
          />
        </div>
      )
    }
  ]

  return (
    <div className='mt-[20px] mb-[20px]'>
      <div className='mb-[20px] flex flex-row custom-filter'>
        <Filter
          options={filterOptions}
          primaryText='Add Filters'
          disabled={
            (allExceptionsCount === 0 &&
              !store.filterStore.getFiltersApplied()) ||
            loading
          }
        />
      </div>

      {store.uiStore.getGlobalLoader() ||
      loading ||
      !tableData ||
      store.uiStore.getprojectEnvironmentLoader() ? (
        <Spinner />
      ) : tableData.length > 0 ? (
        <div ref={tableRef}>
          <Table
            data={tableData}
            columns={AllExceptionsColumns}
            totalCount={allExceptionsCount}
            rowsPerPage={10}
            currentPage={currentPage}
            onPageChange={(page: number) => {
              setCurrentPage(page)
              const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                location.search
              )
              setSearchParams({ ...allQueryParams, page: page.toString() })
            }}
            selectable
            tableStyle={{
              overflowX: 'scroll'
            }}
            onSelect={(row: any) => setSelectedRows(row)}
            loading={
              loading ||
              store.uiStore.getGlobalLoader() ||
              store.uiStore.getprojectEnvironmentLoader()
            }
            selectionType='checkbox'
          />
        </div>
      ) : (
        <NoRecord
          imageSrc={
            store.filterStore.getFiltersApplied()
              ? NoFilterImage
              : NoRecordImage
          }
          style={{ height: '74vh' }}
          text={
            store.filterStore.getFiltersApplied() ? (
              StringConstants.NO_RECORDS_FILTER
            ) : (
              <>
                Configure {isFullStackProject ? 'Exception' : 'Error'}
                {''} Monitoring.
                <Link
                  href={
                    isFullStackProject
                      ? StringConstants.FULLSTACK_URL
                      : isSalesforceProject
                        ? StringConstants.SALESFORCE_URL
                        : StringConstants.MULESOFT
                  }
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ marginLeft: '4px' }}
                >
                  Learn more
                </Link>
              </>
            )
          }
        />
      )}

      <ConfirmationPopup
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title='Severity Type'
        primaryButtonClick={() => updateSeverity(currentGroupId, preference)}
        secondaryButtonClick={() => {
          setModalOpen(false)
        }}
        primaryButtonText='Yes'
        secondaryButtonText='No'
        primaryButtonDisabled={loading}
        secondaryButtonDisabled={loading}
        loading={loading}
        content={
          <div className='flex flex-col w-full gap-y-[24px] items-center justify-center '>
            <div>
              <DDSTypography.Title
                variant='semiBold'
                type='h4'
                className='text-center flex flex-row whitespace-nowrap gap-x-[4px]'
              >
                {isFullStackProject
                  ? 'This will change severity type for selected exceptions to'
                  : 'This will change severity type for selected errors to'}
                <DDSTypography.Title
                  type='h4'
                  variant='semiBold'
                  color={severityColorMap[preference]}
                >
                  {preference}
                </DDSTypography.Title>
              </DDSTypography.Title>
            </div>
          </div>
        }
      />
    </div>
  )
}

export default SecureComponent(
  observer(AllExceptions),
  AccessRoleConstants.MEMBER_LEVEL
)
