import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import PageLoadingOverlay from '../../../components/common/PageLoadingOverlay/PageLoadingOverlay'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import routeConstants from '../../../constants/RouteConstants'
import { verifyEmail } from '../../../api/user/User.service'

const VerifyUserRedirect: React.FC = () => {
  const navigate = useNavigate()
  const { token } = useParams()

  useEffect(() => {
    verifyEmail({ $token: token as string })
      .then((response: any) => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.USER.VERIFY_USER.SUCCESS
        })
      })
      .catch((err: any) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.USER.VERIFY_USER.ERROR
        })
      })
      .finally(() => navigate(routeConstants.SIGN_IN))
  }, [])

  return <PageLoadingOverlay />
}

export default VerifyUserRedirect
