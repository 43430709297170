import { IoIosArrowBack } from 'react-icons/io'
import React from 'react'
import { TypographyDDS } from '../../common/Typography/TypographyDDS'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3em;
`
const IconWrapper = styled.div`
  padding-top: 4px;
`
const GoBack: React.FC = () => {
  return (
    <Container>
      <IconWrapper>
        <IoIosArrowBack
          style={{
            color: palette.colors.primary
          }}
        />
      </IconWrapper>

      <TypographyDDS.Paragraph size='para' variant='medium'>
        Back
      </TypographyDDS.Paragraph>
    </Container>
  )
}

export default GoBack
