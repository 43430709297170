import { Button } from '../New/Button/Button'
import React from 'react'
import { getAddLabelForTitle } from '../../utils/OverviewUtils'
import { useStoreContext } from '../../store/StoreContext'
interface addButtonProps {
  title: string
  onClick: any
}
const AddButton: React.FC<addButtonProps> = ({ title, onClick }) => {
  const store = useStoreContext()
  return (
    <Button
      size='small'
      id='refresh-btn'
      type='outline'
      label={getAddLabelForTitle(title)}
      onClick={onClick}
      disabled={store.userStore.getNoActiveSubscription()}
    />
  )
}

export default AddButton
