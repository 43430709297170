import React, { useEffect, useRef, useState } from 'react'

import LinearProgressBar from '../ProgressBar/ProgressBar'
import MuiButton from '@mui/material/Button'
import { Tooltip } from '@mui/material'
import { TypographyDDS } from '../Typography/TypographyDDS'
import breakpoints from '../../../global/breakpoints'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

type CustomColors = 'gradientPrimary' | 'lightRed'
interface ButtonProps {
  size?: 'small' | 'medium' | 'large' | 'x-small'
  variant?: 'text' | 'outlined' | 'contained' | undefined
  textTransform?: 'capitalize' | 'uppercase' | 'lowercase' | undefined | 'none'
  color?: 'primary' | 'secondary' | 'error' | 'warning' | CustomColors
  textColor?: string
  background?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  children?: React.ReactNode | string | undefined
  bold?: boolean
  type?: 'button' | 'reset' | 'submit' | undefined
  fontWeight?: number
  disabled?: boolean
  width?: string
  fontSize?: string
  height?: string
  borderColor?: string
  borderRadius?: string
  justifyContent?: 'flex-start' | 'flex-end' | 'center'
  margin?: string
  textDecoration?: string
  value?: string
  href?: string
  endIcon?: JSX.Element
  startIcon?: JSX.Element
  boxShadow?: boolean
  padding?: string
  disableRipple?: boolean
  loading?: boolean
  tabIndex?: number
  onBlur?: () => void
  className?: string
  maxWidth?: string
  maxHeight?: string
  fullWidth?: boolean
  fullWidthChild?: boolean
  //added $ to mention it as a transient props, in order to prevent it to passing through the react dom and get an unknown prop error in browser
  $setIconToEnd?: boolean
  alignItems?: string
  id?: string
  iconSize?: string
}

const ColorVariantMapping = (color: string) => {
  switch (color) {
    case 'error':
      return palette.colors.error
    case 'warning':
      return palette.colors.warning
    case 'primary':
      return palette.colors.primary
    case 'secondary':
      return palette.colors.secondary
    default:
      return color
  }
}

const ButtonContainer = styled.div<{
  alignItems: string
  fullWidth: boolean
  margin: string
  size: string
}>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  alignitems: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  width: 100%;
  max-width: ${(props) =>
    props.fullWidth ? '100%' : getMaxContainerWidth(props.size)};
  margin: ${(props) => props.margin};

  @media and (max-width: ${breakpoints.md}) {
    max-width: ${(props) =>
      props.fullWidth ? '100%' : getMaxContainerWidth(props.size)};
  }
`

const CustomButton = styled(MuiButton, {
  shouldForwardProp: (prop: string) => !prop.startsWith('$')
})(({ ...props }: ButtonProps) => ({
  '&.MuiButtonBase-root': {
    '&.Mui-disabled': {
      color:
        props.variant !== 'outlined'
          ? palette.colors.white
          : ColorVariantMapping(props.color as string),
      backgroundColor:
        props.variant !== 'outlined'
          ? ColorVariantMapping(props.color as string)
          : '',
      borderColor:
        props.variant === 'outlined'
          ? ColorVariantMapping(props.color as string)
          : '',
      cursor: 'not-allowed'
    }
  },
  '& .MuiButton-endIcon': {
    position: (props.$setIconToEnd as boolean) ? 'absolute' : 'inherit',
    right: (props.$setIconToEnd as boolean) ? '20px' : '0px'
  },
  '.MuiButton-endIcon.MuiButton-iconSizeMedium>*:nth-of-type(1)': {
    height: props.iconSize ? props.iconSize : '16px',
    width: props.iconSize ? props.iconSize : '16px'
  },
  '& .MuiButton-startIcon': {
    height: props.iconSize ? props.iconSize : '16px',
    width: props.iconSize ? props.iconSize : '16px'
  }
}))

const isEllipsisActive = (e: HTMLSpanElement | null) => {
  if (e) return e.offsetWidth < e.scrollWidth
  return false
}

const getButtonDimensionProps = (size: string) => {
  switch (size) {
    case 'x-small': {
      return {
        padding: '0px 16px 0px 16px',
        fontSize: '12px',
        height: '37px'
      }
    }
    case 'small':
      return {
        padding: '0px 16px 0px 16px',
        fontSize: '12px',
        height: '37px'
      }
    case 'medium':
      return {
        padding: '10px 16px 10px 16px',
        fontSize: '14px',
        height: '44.5px'
      }
    case 'large':
      return {
        padding: '10px 18px 10px 18px',
        fontSize: '16px'
      }
  }
}

const getMaxContainerWidth = (size: string) => {
  switch (size) {
    case 'x-small':
      return '120px'
    default:
      if (window.innerWidth < 900) return '150px'
      else {
        return '200px'
      }
  }
}

const Button: React.FC<ButtonProps> = ({
  variant,
  color = 'primary',
  borderColor,
  size = 'medium',
  textColor,
  onClick,
  children,
  bold = false,
  type = 'button',
  textDecoration = 'none',
  disabled = false,
  textTransform = 'capitalize',
  fontSize = '0.875em',
  background,
  borderRadius = '6px',
  justifyContent = 'center',
  value,
  href,
  endIcon,
  startIcon,
  boxShadow,
  disableRipple = false,
  loading,
  tabIndex = 0,
  onBlur,
  maxHeight,
  margin = '0',
  fullWidth,
  fullWidthChild,
  $setIconToEnd = false,
  alignItems,
  width = '100%',
  iconSize,
  id = ''
}) => {
  const buttonTextRef = useRef<HTMLSpanElement>(null)
  const [isEllipsis, setIsEllipsis] = useState(false)
  useEffect(() => {
    setIsEllipsis(isEllipsisActive(buttonTextRef.current))
  }, [buttonTextRef])
  const colorMap = {
    gradientPrimary: {
      base: 'primary' as 'primary' | 'error',
      bg: palette.colors.gradientBlue
    },
    lightRed: {
      base: 'error' as 'primary' | 'error',
      bg: palette.colors.lightRed
    }
  }
  return (
    <Tooltip title={isEllipsis ? (children as string) : ''}>
      <ButtonContainer
        alignItems={alignItems as string}
        fullWidth={fullWidth as boolean}
        size={size}
        margin={margin}
        id={id}
      >
        <CustomButton
          iconSize={iconSize}
          variant={variant}
          disableElevation
          onClick={onClick}
          type={type}
          disabled={disabled || loading}
          value={value}
          href={href}
          endIcon={endIcon}
          startIcon={startIcon}
          onBlur={onBlur}
          color={
            color !== 'gradientPrimary' && color !== 'lightRed'
              ? color
              : colorMap[color].base
          }
          disableRipple={disableRipple}
          tabIndex={tabIndex}
          sx={{
            fontWeight: bold
              ? palette.fontWeight.bold
              : palette.fontWeight.semiBold,
            width,

            borderRadius,
            textTransform,
            fontSize,
            margin: '0px',

            color: textColor,
            borderColor,
            background:
              color !== 'gradientPrimary' && color !== 'lightRed'
                ? background
                : colorMap[color].bg,
            justifyContent,
            textDecoration,
            boxShadow: boxShadow
              ? '0px 15px 25px rgba(71, 78, 93, 0.15)'
              : 'none',
            opacity: disabled || loading ? 0.5 : 1,
            maxHeight,
            ...getButtonDimensionProps(size)
          }}
          $setIconToEnd={$setIconToEnd}
        >
          <span
            style={{
              whiteSpace: 'nowrap',
              width: fullWidthChild ? '100%' : '',
              display: 'flex',
              justifyContent: 'center'
            }}
            ref={buttonTextRef}
          >
            {typeof children === 'string' ? (
              <TypographyDDS.Paragraph
                style={{ color: textColor }}
                size='para'
                variant='semiBold'
              >
                {children}
              </TypographyDDS.Paragraph>
            ) : (
              <>{children}</>
            )}
          </span>
        </CustomButton>

        {loading && <LinearProgressBar />}
      </ButtonContainer>
    </Tooltip>
  )
}

export default Button
