import { Information, TrashCan } from '@carbon/icons-react'
import React, { useEffect, useState } from 'react'

import AssignedToMenuList from '../../AssignedToMenuList/AssignedToMenuList'
import { Button } from '../../../New/Button/Button'
import Counter from '../Counter/Counter'
import { DDSTypography } from 'den-design-system'
import Input from '../../../New/Input/Input'
import Modal from '../Modal/Modal'
import Select from '../../../New/Select/Select'
import Spinner from '../../Spinner/Spinner'
import Switch from '../../../New/Switch/Switch'
import { ToastMessageConstants } from '../../../../constants/ToastMessageConstants'
import ToastNotification from '../Toast/Toast'
import Tooltip from '../../../New/Tooltip/Tooltip'
import UserAvatar from '../../UserAvatar/UserAvatar'
import emptyAvatar from '../../../../assets/icons/emptyAvatar.svg'
import { getAllProjectMembers } from '../../../../api/project/ProjectMember.service'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../global/newPallete'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../../../store/StoreContext'

interface LevelComponentProps {
  editable: boolean
  data: any
  id: number
  setId?: string
  isNew?: boolean
  escalationName: string
  onChangeFrequency?: (value: string) => any
  onChangeThreshold?: (value: number) => any
  onChangeAssigned?: (value: string) => any
  onChangeActive?: (value: boolean) => any
  setEscalationLevels?: any
  levelCount: number
  onDelete: () => any
}

const LevelComponent: React.FC<LevelComponentProps> = ({
  id,
  editable,
  data,
  escalationName,
  setId,
  isNew,
  onDelete,
  setEscalationLevels,
  levelCount
}) => {
  const store = useStoreContext()
  const [deletePopUpOpen, setDeletePopUpOpen] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<any>([])
  const [levelData, setLevelData] = useState<any>({})
  const [userOptions, setUserOptions] = useState<any>([])
  const [avatarOptions, setAvatarOptions] = useState<any>([])
  const [levelId, setLevelId] = useState<string>('')
  const navigate = useNavigate()
  const freqDropdownOptions = [
    {
      label: 'Daily',
      value: 'Day'
    },
    {
      label: 'Weekly',
      value: 'Week'
    },
    {
      label: 'Monthly',
      value: 'Month'
    }
  ]

  useEffect(() => {
    setLevelData(data)
  }, [data, id, setId])

  useEffect(() => {
    const avatarObjects: { name: string; avatar: string }[] = []
    if (userOptions && userOptions.length > 0) {
      selectedUsers.forEach((assignedMember: any) => {
        const avatarObject = {
          name: assignedMember.name,
          avatar: assignedMember.imgUrl
        }
        avatarObjects.push(avatarObject)
      })
    }
    setAvatarOptions(avatarObjects)
  }, [userOptions, selectedUsers])

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    onDelete()
    setDeletePopUpOpen(false)
    e.stopPropagation()
  }

  const handleLevelDataChange = (changes: Partial<any>) => {
    const updatedLevelData = { ...levelData, ...changes }
    setLevelData(updatedLevelData)
    setEscalationLevels((prevData: any) => {
      const newData = [...prevData]
      newData[id] = updatedLevelData // Update the specific level data
      return newData
    })
  }

  useEffect(() => {
    if (levelData?.alertCandidates) {
      const selectedOptions = userOptions.filter((user: any) =>
        levelData.alertCandidates.includes(user.id)
      )
      setSelectedUsers(selectedOptions)
    }
  }, [levelData, userOptions])

  useEffect(() => {
    fetchProjectMembers()
  }, [])

  const fetchProjectMembers = () => {
    const projectId = store.scopeStore.getSelectedProject().id
    getAllProjectMembers({ projectId: projectId as string })
      .then((response: any) => {
        const allMembers = response.data.map((member: any) => {
          return {
            id: member.id,
            name: member.user.firstName + ' ' + member.user.lastName,
            imgUrl: member.user.avatar,
            role: member.role
          }
        })
        setUserOptions(allMembers)
      })
      .catch((err: any) => {
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.PROJECT_MEMBER.FIND_ALL_PROJECT_MEMBERS.ERROR
        })
      })
      .finally(() => store.uiStore.setGlobalLoader(false))
  }

  const Footer: React.FC = () => {
    return (
      <div className='flex override-btn-min-width justify-end py-[16px] px-[24px] gap-[10px]'>
        <Button
          id='no-button'
          label='No'
          onClick={() => setDeletePopUpOpen(false)}
          type='neutral'
        />
        <Button
          id='yes-button'
          label='Yes'
          disabled={store.userStore.getNoActiveSubscription()}
          onClick={(e) => handleDelete(e)}
          type='dangerFilled'
        />
      </div>
    )
  }
  return (
    <div className={`flex flex-col gap-[16px] `}>
      <div
        className={`flex items-center ${
          !levelData?.isActive
            ? 'opacity-50 pointer-events-none'
            : ' pointer-events-auto opacity-100'
        }`}
      >
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark3}
        >
          Level {id + 1}
        </DDSTypography.Title>
      </div>
      <div
        className={`rounded-lg ${
          editable ? 'h-[100px]' : 'h-[96px]'
        } border p-[20px] flex items-center justify-between`}
        style={{
          borderColor: pallete.colors.stroke2
        }}
      >
        <div className='flex gap-[40px] items-center'>
          <div
            className={`flex items-center gap-[40px] ${
              !levelData?.isActive ? 'opacity-50 pointer-events-none' : ''
            }`}
          >
            <div className='flex items-center flex-col gap-[8px] min-w-[190px] text-center'>
              <DDSTypography.Title
                type='h5'
                variant='semiBold'
                color={pallete.colors.textDark5}
              >
                Threshold
              </DDSTypography.Title>
              <div className='justify-center grid relative'>
                {editable ? (
                  <Counter
                    disableNoSubscription={store.userStore.getNoActiveSubscription()}
                    value={levelData?.threshold}
                    onChange={(value) =>
                      handleLevelDataChange({ threshold: value })
                    }
                    minValue={1}
                    onConfirm={(value) =>
                      handleLevelDataChange({ threshold: value })
                    }
                  />
                ) : (
                  <DDSTypography.Title
                    type='h4'
                    variant='semiBold'
                    color={pallete.colors.textDark3}
                  >
                    {levelData?.threshold}
                  </DDSTypography.Title>
                )}
              </div>
            </div>
            <span
              className='h-[56px] w-px'
              style={{ background: pallete.colors.stroke2 }}
            ></span>
            <div className='flex items-center flex-col gap-[8px] text-center'>
              <div className='flex items-center gap-[8px]'>
                <DDSTypography.Title
                  type='h5'
                  variant='semiBold'
                  color={pallete.colors.textDark5}
                >
                  Frequency
                </DDSTypography.Title>
                <Tooltip
                  id='info-tooltip'
                  label={
                    <div className='flex flex-col items-start gap-[4px]'>
                      <DDSTypography.Paragraph
                        size='para'
                        variant='regular'
                        color={pallete.colors.textDark4}
                      >
                        Daily - 12:00 AM to 11:59 PM
                      </DDSTypography.Paragraph>
                      <DDSTypography.Paragraph
                        size='para'
                        variant='regular'
                        color={pallete.colors.textDark4}
                      >
                        Weekly - Sun to Sat
                      </DDSTypography.Paragraph>
                      <DDSTypography.Paragraph
                        size='para'
                        variant='regular'
                        color={pallete.colors.textDark4}
                      >
                        Monthly - 1 to 31 of every month
                      </DDSTypography.Paragraph>
                    </div>
                  }
                >
                  <Information size={16} color={pallete.colors.textDark5} />
                </Tooltip>
              </div>
              <div className='justify-center flex relative'>
                {editable ? (
                  <div className='override-freq-select'>
                    <Select
                      id='frequency-select'
                      options={freqDropdownOptions}
                      searchable={false}
                      name='Frequency'
                      disabled={store.userStore.getNoActiveSubscription()}
                      value={levelData?.frequencyInterval}
                      onSelect={(value: string) =>
                        handleLevelDataChange({ frequencyInterval: value })
                      }
                    />
                  </div>
                ) : (
                  <div className='w-[154px]'>
                    <DDSTypography.Title
                      type='h4'
                      variant='semiBold'
                      color={pallete.colors.textDark3}
                    >
                      {levelData?.frequencyInterval}
                    </DDSTypography.Title>
                  </div>
                )}
              </div>
            </div>
            <span
              className='h-[56px] w-px'
              style={{ background: pallete.colors.stroke2 }}
            ></span>
            <div className='flex items-center flex-col gap-[8px] min-w-[200px]'>
              <DDSTypography.Title
                type='h5'
                variant='semiBold'
                color={pallete.colors.textDark5}
              >
                Notification Preferences
              </DDSTypography.Title>
              <div className='justify-center grid relative items-center override-input disabled'>
                {editable ? (
                  <Input
                    id='alert-channel-input'
                    validateInput={false}
                    width='179px'
                    disabled
                    name='notificationPreference'
                    value={levelData.alertChannel}
                    onChange={(e) =>
                      handleLevelDataChange({
                        alertChannel: e.target.value
                      })
                    }
                  />
                ) : (
                  <DDSTypography.Title
                    type='h4'
                    variant='semiBold'
                    className='truncate max-w-[200px]'
                    color={pallete.colors.textDark3}
                  >
                    {levelData?.alertChannel}
                  </DDSTypography.Title>
                )}
              </div>
            </div>
            <span
              className='h-[56px] w-px'
              style={{ background: pallete.colors.stroke2 }}
            ></span>
            <div className='flex items-center flex-col gap-[8px]'>
              <DDSTypography.Title
                type='h5'
                variant='semiBold'
                className='whitespace-nowrap'
                color={pallete.colors.textDark5}
              >
                Notify User
              </DDSTypography.Title>
              {editable ? (
                <div
                  className={`justify-center flex flex-row relative items-center`}
                >
                  <AssignedToMenuList
                    id={id.toString()}
                    label='notifyUserAdd'
                    editable={editable}
                    arrowPosition='center'
                    menuPosition='center'
                    disableNoSubscription={store.userStore.getNoActiveSubscription()}
                    userOptions={userOptions}
                    setSelectedUsers={(users: any) => {
                      setSelectedUsers(users)
                      const userIds = users.map((user: any) => user.id)
                      handleLevelDataChange({ alertCandidates: userIds })
                    }}
                    selectedUsers={selectedUsers}
                  />
                </div>
              ) : avatarOptions.length > 0 ? (
                <UserAvatar
                  avatarType='Multiple'
                  imageObjectGroup={avatarOptions}
                />
              ) : (
                <div>
                  <img
                    id={`menu-list-btn${id && '-' + id}`}
                    src={emptyAvatar}
                    className='h-[27.44px] w-[27.44px]'
                  />
                </div>
              )}
            </div>
            <span
              className='h-[56px] w-px'
              style={{ background: pallete.colors.stroke2 }}
            ></span>
          </div>
          <div className='flex items-center flex-col gap-[8px]'>
            <DDSTypography.Title
              type='h5'
              variant='semiBold'
              color={pallete.colors.textDark5}
            >
              Active
            </DDSTypography.Title>
            <div
              className={`${
                !editable ? 'pointer-events-none' : 'cursor-pointer'
              }`}
            >
              <Switch
                disabled={store.userStore.getNoActiveSubscription()}
                id='switch-level'
                className={`${!editable && 'pointer-events-none'}`}
                defaultChecked={levelData.isActive}
                value={levelData.isActive}
                onChange={(value) => handleLevelDataChange({ isActive: value })}
              />
            </div>
          </div>
        </div>
        <div className='w-[20px] h-[20px]'>
          {levelCount > 1 && (
            <div>
              {editable && (
                <TrashCan
                  className='cursor-pointer'
                  style={{ color: pallete.colors.warningRed2 }}
                  onClick={() => {
                    setDeletePopUpOpen(true)
                  }}
                  size='20'
                />
              )}

              <Modal
                open={deletePopUpOpen}
                setOpen={() => {
                  setDeletePopUpOpen(true)
                }}
                onClosePress={() => {
                  setDeletePopUpOpen(false)
                }}
                className='!p-0'
                showHeader
                footer={<Footer />}
                title='Delete Escalation Level'
              >
                <div className='flex flex-col'>
                  <div className='flex flex-col p-[24px] overflow-y-scroll'>
                    <div className='flex flex-col w-full gap-y-[24px] items-center justify-center'>
                      <div>
                        <DDSTypography.Title
                          variant='semiBold'
                          type='h4'
                          className='text-center flex flex-row gap-x-[4px]'
                        >
                          Are you sure you want to delete{' '}
                          <div>
                            <DDSTypography.Title
                              variant='bold'
                              type='h4'
                              className='text-center'
                            >
                              Level {id + 1}
                            </DDSTypography.Title>
                          </div>
                          of
                          <div>
                            <DDSTypography.Title
                              variant='bold'
                              type='h4'
                              className='text-center'
                            >
                              {escalationName}?
                            </DDSTypography.Title>
                          </div>
                        </DDSTypography.Title>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default observer(LevelComponent)
