import { groupBy, maxBy } from 'lodash'
import { makeAutoObservable, toJS } from 'mobx'

import { RootStore } from './RootStore'
import { getSalesforceAuditDataAPI } from '../api/salesforceAudit/salesforceAudit.service'

const initialAuditData: Record<string, any> = {}
export class SalesforceAuditStore {
  auditData: Record<string, any>
  loading: boolean
  static pageSize = 5
  constructor(private rootStore: RootStore) {
    this.auditData = initialAuditData
    this.loading = false
    makeAutoObservable(this)
  }
  setLoading(loading: boolean) {
    this.rootStore.salesforceAuditStore.loading = loading
  }
  getLoading() {
    return this.rootStore.salesforceAuditStore.loading
  }
  setAuditData(data: any) {
    this.rootStore.salesforceAuditStore.auditData = data
  }
  getNameById(id: string) {
    const user = this.getUsersData().find((user: any) => user.Id === id)
    if (user) return user.Name
  }
  getAuditData() {
    return this.rootStore.salesforceAuditStore.auditData
  }

  getLimitsData() {
    return this.getAuditData().limits
  }

  getUsersData() {
    return this.rootStore.salesforceAuditStore.auditData.users.map(
      (item: any) => item.record
    )
  }
  getUserById(id: string) {
    return this.getUsersData().find((item: any) => item.Id === id)
  }
  getActiveUsersCount() {
    return this.getUsersData().filter((item: any) => item.IsActive).length
  }
  getMetadataOverviewData() {
    const data = this.getAuditData()
    const metadataOverviewData = [
      {
        name: 'Apex Classes',
        total: data.apexClasses.length
      },
      {
        name: 'Apex Triggers',
        total: data.apexTriggers.length
      },
      {
        name: 'Roles',
        total: data.roles.length
      },
      {
        name: 'Profiles',
        total: data.profiles.length
      },
      {
        name: 'Permission Sets',
        total: data.permissionSets.length
      }
      //   {
      //     name: 'Custom Permissions',
      //     total: data.customPermissions.length
      //   },
      //   {
      //     name: 'Email Alerts',
      //     total: data.emailAlerts.length
      //   }
    ]
    return metadataOverviewData
  }

  getUsedFileStorage() {
    const files = this.getAuditData().files.map((item: any) => item.record)
    let totalSizeUsed = 0
    if (files.length > 0) {
      totalSizeUsed = files.reduce(
        (total: any, currentFile: any) => total + currentFile['ContentSize'],
        0
      )
    }
    //totalSizeUsed will be in bytes. Convert into mB (1 mB = 1048576 bytes)
    return totalSizeUsed / 1048576
  }

  getApexClassesData() {
    return this.getAuditData().apexClasses.map((item: any) => item.record)
  }

  getApexClassById(id: string) {
    return this.getApexClassesData().find((item: any) => item.Id === id)
  }

  getApexTriggersData() {
    return this.getAuditData().apexTriggers.map((item: any) => item.record)
  }

  getApexTriggerById(id: string) {
    return this.getApexTriggersData().find((item: any) => item.Id === id)
  }

  getApexTriggerEventString(id: string) {
    const data = this.getApexTriggerById(id)
    let eventString = ''
    Object.keys(data).map((key) => {
      if (key.startsWith('Usage') && data[key]) {
        if (eventString.length > 0) eventString += ', '
        const eventWithoutPrefix = key.replace(/^Usage/, '')
        const event = eventWithoutPrefix
          .split(/(?=[A-Z])/)
          .map((word) => word.toLowerCase())
        eventString += event.join(' ')
      }
    })
    return eventString
  }

  getRolesData() {
    return this.getAuditData().roles.map((item: any) => item.record)
  }

  getRoleById(id: string) {
    return this.getRolesData().find((item: any) => item.Id === id)
  }

  getUsersAssignedForRoleById(id: string) {
    const users = this.getUsersData()
    return users.filter((user: any) => user.UserRole?.Id === id)
  }

  getProfilesData() {
    return this.getAuditData().profiles.map((item: any) => item.record)
  }

  getProfileById(id: string) {
    return this.getProfilesData().find((item: any) => item.Id === id)
  }

  getProfilePermissionsData(id: string) {
    const profile = this.getProfileById(id)
    const result: any[] = []

    for (const key in profile) {
      if (key.startsWith('Permissions')) {
        const newKey = key.replace(/^Permissions/, '')
        result.push({ name: newKey, access: profile[key] ? 'Yes' : 'No' })
      }
    }

    return result
  }

  getUsersAssignedForProfileById(id: string) {
    const users = this.getUsersData()
    return users.filter((user: any) => user.Profile?.Id === id)
  }

  getPermissionSetsData() {
    return this.getAuditData().permissionSets.map((item: any) => item.record)
  }
  getPermissionSetById(id: string) {
    return this.getPermissionSetsData().find((item: any) => item.Id === id)
  }
  getUsersAssignedForPermissionSetLengthById(id: string) {
    const groupedAssignmentData = groupBy(
      this.getAuditData().permissionSetAssignments,
      'record.PermissionSetId'
    )
    return groupedAssignmentData[id]?.length || 0
  }
  getPermissionSetPermissionsData(id: string) {
    const profile = this.getPermissionSetById(id)
    const result: any[] = []

    for (const key in profile) {
      if (key.startsWith('Permissions')) {
        const newKey = key.replace(/^Permissions/, '')
        result.push({ name: newKey, access: profile[key] ? 'Yes' : 'No' })
      }
    }

    return result
  }
  getLicenses() {
    return this.getAuditData().userLicenses.map((item: any) => item.record)
  }
  getLicenseById(id: string) {
    return this.getLicenses().find((item: any) => item.Id === id)
  }
  getTotalLicenses() {
    const licenses = this.getLicenses()
    let count = 0
    for (const license of licenses) {
      count += license.TotalLicenses
    }
    return count
  }
  getUsedLicenses() {
    const licenses = this.getLicenses()
    let count = 0
    for (const license of licenses) {
      count += license.UsedLicenses
    }
    return count
  }

  getMostCommonLicense(): any {
    const licenses = this.getLicenses()
    const maxUsedLicense = maxBy(licenses, 'UsedLicenses')
    return maxUsedLicense
  }

  getLicenseDistribution(): any {
    const distribution = []
    const licenses = this.getLicenses()
    for (const license of licenses) {
      distribution.push({
        name: license.Name,
        usage: (license.UsedLicenses / this.getUsedLicenses()) * 100
      })
    }
    return distribution
  }
  getUsersWithLicenseById(id: string) {
    const users = this.getUsersData()
    return users.filter((user: any) => user.Profile?.UserLicense?.Id === id)
  }
  async getSalesforceAuditFromAPI() {
    this.setLoading(true)
    try {
      const { data } = await getSalesforceAuditDataAPI()
      this.setAuditData(data)
    } finally {
      this.setLoading(false)
    }
  }
  async reset() {
    this.loading = false
    this.auditData = initialAuditData
  }
}
