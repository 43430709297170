import { clearPersistedStore, isHydrated } from 'mobx-persist-store'
import { makeAutoObservable, observable } from 'mobx'

import { RootStore } from './RootStore'
import { ScopeTypes } from '../constants/UrlConstants'
import { isEmpty } from 'lodash'

export interface Scope {
  name: ScopeTypes | null
  'organization-id': string | null
  'project-id': string | null
  'environment-id': string | null
}

const initialValue: Scope = {
  name: null,
  'organization-id': '',
  'project-id': '',
  'environment-id': ''
}

export interface Projects {
  [key: string]: string
}
export class ScopeStore {
  scope: Scope
  project: Record<string, string>
  environment: Record<string, string>
  projects: any[]
  environments: any[]
  constructor(private rootStore: RootStore) {
    this.scope = initialValue
    this.project = {}
    this.environment = {}
    this.projects = []
    this.environments = []
    makeAutoObservable(this, {
      scope: observable.struct,
      project: observable.struct,
      environment: observable.struct,
      projects: observable.struct,
      environments: observable.struct
    })
  }

  async clearStoredData() {
    await clearPersistedStore(this)
  }

  get isHydrated(): boolean {
    return isHydrated(this)
  }

  setOrganizationId = (id: any) => {
    this.scope = { ...this.scope, ...{ ['organization-id']: id } }
  }

  getOrganizationId = () => {
    return this.rootStore.scopeStore.scope['organization-id']
  }
  // Might need this in things to do dropdowns
  getProjectId = () => {
    return this.rootStore.scopeStore.scope['project-id']
  }

  getEnvironmentId = () => {
    return this.rootStore.scopeStore.scope['environment-id']
  }

  getScope(): Scope {
    return this.rootStore.scopeStore.scope
  }

  setScope(scope: Scope) {
    this.rootStore.scopeStore.scope = scope
  }

  setProjectId = (id: string) => {
    this.scope = { ...this.scope, ...{ ['project-id']: id } }
  }

  setEnvironmentId = (id: string) => {
    this.scope = { ...this.scope, ...{ ['environment-id']: id } }
  }

  setSelectedProject = (project: Record<string, string>) => {
    this.project = project
  }

  getSelectedProject = () => {
    return this.project
  }

  setSelectedEnvironment = (environment: Record<string, string>) => {
    this.environment = environment
  }

  getSelectedEnvironment = () => {
    return this.environment
  }

  setProjects = (projects: any[]) => {
    this.projects = projects
  }

  getProjects = () => {
    return this.projects
  }

  getProjectName = (projectId: any) => {
    const projects =
      this.rootStore.projectAndEnvironmentStore.getProjectsAndEnvironments()
    let name = ''
    for (let i = 0; i < projects.length; i++) {
      if (projectId == projects[i].id) {
        name = projects[i].name as string
      }
    }
    return name
  }
  getEnvironmentName = (environmentId: any) => {
    const project =
      this.rootStore.projectAndEnvironmentStore.getSelectedProject()
    let environments = []
    if (!isEmpty(project)) {
      environments = project.environments
    }
    for (let i = 0; i < environments.length; i++) {
      if (environmentId == environments[i].id) {
        return environments[i].name
      }
    }
  }
  setEnvironments = (environments: any[]) => {
    this.environments = environments
  }
  getEnvironments = () => {
    return this.environments
  }

  reset() {
    this.scope = initialValue
    this.project = {}
    this.environment = {}
    this.projects = []
    this.environments = []
  }
}
