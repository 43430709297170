import '../../../global/global.css'

import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import React from 'react'
import Stack from '@mui/material/Stack'
import themePallete from '../../../global/pallete'

interface ChecboxGroupProps {
  id?: any
  width?: string
  spacing?: number
  boxSize?: string
  children?: React.ReactNode
  handleChange?: (data: any) => void
  error?: boolean
  disabled?: boolean
  checked?: boolean
  value?: any
}

const CheckboxGroup: React.FC<ChecboxGroupProps> = ({
  id,
  width,
  spacing = 1,
  boxSize = '20px',
  children,
  handleChange,
  error,
  disabled,
  checked,
  value
}) => {
  return (
    <FormControl sx={{ width: `${width}`, cursor: 'auto' }}>
      <Stack direction='row' spacing={spacing} className='commonDisplay'>
        <FormGroup
          className='commonDisplay'
          style={{
            flexWrap: 'nowrap'
          }}
        >
          <Checkbox
            id={id}
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: boxSize,
                color: error
                  ? themePallete.colors.borderRed
                  : checked
                  ? themePallete.colors.primary
                  : themePallete.colors.borderColor
              }
            }}
            onChange={handleChange}
            disabled={disabled}
            checked={checked}
            value={value}
          />
        </FormGroup>
        <div style={{ display: 'flex', alignSelf: 'center' }}>{children}</div>
      </Stack>
    </FormControl>
  )
}

export default CheckboxGroup
