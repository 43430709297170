import { BsFolderPlus } from 'react-icons/bs'
import ConfirmationPopup from './ConfirmationPopup'
import Icon from '../Icon/Icon'
import IconButton from '../IconButton/IconButton'
import React from 'react'
import { Tooltip } from '@mui/material'
import { TypographyDDS } from '../Typography/TypographyDDS'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

//snippet that shows the usecase
/*
const [popupOpen, setPopupOpen] = React.useState(false)
return (
  <div>
    <Button onClick={() => setPopupOpen(!popupOpen)}>click me</Button>
    <Popup
      open={popupOpen}
      handleClose={() => setPopupOpen(!popupOpen)}
      confirmToClose={false}
    >[child component goes here]</Popup>
  </div>
*/

interface PopupProps {
  children?: React.ReactNode
  open: boolean
  handleClose: () => void
  headerIcon?: React.ReactNode
  headerText?: string
  confirmToClose?: boolean
  zIndex?: number
  width?: string
  height?: string
  closeOnAwayClick?: boolean
  customHeader?: React.ReactNode
  headerPadding?: string
  destroyOnClose?: boolean
  paddingTop?: string
  disabled?: boolean
  loading?: boolean
}

const Container = styled.div<{ zIndex: number }>`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99999;
  background-color: red;
  width: 100%;
  height: 100vh;
  background: rgba(71, 78, 93, 0.2);
  box-shadow: inset 0px 8px 25px rgba(71, 78, 93, 0.04);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
`
const Wrapper = styled.div<{ width: string }>`
  padding: 20px;
  min-width: ${({ width }) => width};
  @media screen and (max-width: 850px) {
    min-width: 100%;
  }
`

const ModalWrapper = styled.div<{ height: string }>`
  background-color: ${palette.colors.white};
  border-radius: 16px;
  min-height: ${({ height }) => height};
  border: 1px solid ${palette.colors.borderColor};
  box-shadow: 0px 8px 25px rgba(71, 78, 93, 0.04);
  padding: 30px;
  @media screen and (max-width: 850px) {
    border-radius: 12px;
  }
`

const ModalHeader = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid ${palette.colors.borderColor};
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
`

const CloseIconWrapper = styled.div<{ customHeader?: boolean }>`
  ${({ customHeader }) =>
    customHeader &&
    `
    display: flex;
    align-items: center
  `}
`

const Popup: React.FC<PopupProps> = ({
  children,
  open,
  handleClose,
  loading,
  headerIcon = <BsFolderPlus />,
  headerText,
  confirmToClose = false,
  zIndex = 999,
  width = '700px',
  height = '400px',
  closeOnAwayClick,
  customHeader,
  headerPadding,
  destroyOnClose = false,
  paddingTop = '30px',
  disabled = false
}) => {
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false)
  const handleCloseWithDialog = () => {
    setOpenConfirmDialog(!openConfirmDialog)
  }
  const handleMainPopupClose = () => {
    setOpenConfirmDialog(false)
    handleClose()
  }
  const getOnClick = () => {
    if (closeOnAwayClick) return
    return confirmToClose ? handleCloseWithDialog : handleClose
  }

  const renderPopupContents = () => {
    return (
      <>
        <ConfirmationPopup
          open={openConfirmDialog}
          handleMainPopupClose={handleMainPopupClose}
          handleConfirmDialogClose={() =>
            setOpenConfirmDialog(!openConfirmDialog)
          }
        />
        <Wrapper
          onClick={(e) => {
            e.stopPropagation()
          }}
          width={width}
        >
          <ModalWrapper
            height={height}
            style={{
              paddingTop: paddingTop,
              maxHeight: height >= '700px' ? '700px' : 'none',
              overflowY: 'scroll'
              // marginLeft: '260px'
            }}
          >
            <ModalHeader style={{ padding: headerPadding }}>
              {customHeader ? (
                customHeader
              ) : (
                <TypographyDDS.Title
                  type='h4'
                  variant='bold'
                  style={{ display: 'flex', gap: '8px', alignItems: 'center' }}
                >
                  <Icon
                    padding='0px 5px 0px 0px'
                    fontSize='18px'
                    color={palette.colors.primary}
                  >
                    {headerIcon}
                  </Icon>
                  {headerText}
                </TypographyDDS.Title>
              )}
              <Tooltip title={'Close'}>
                <CloseIconWrapper customHeader={Boolean(customHeader)}>
                  <IconButton
                    onClick={
                      confirmToClose ? handleCloseWithDialog : handleClose
                    }
                    disabled={loading || disabled}
                  />
                </CloseIconWrapper>
              </Tooltip>
            </ModalHeader>
            {children}
          </ModalWrapper>
        </Wrapper>
      </>
    )
  }

  return (
    <>
      <Container
        style={{ display: open ? 'flex' : 'none' }}
        onClick={getOnClick}
        zIndex={zIndex}
      >
        {(open || !destroyOnClose) && renderPopupContents()}
      </Container>
    </>
  )
}
export default Popup
