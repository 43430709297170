import { DDSTooltip, DDSTypography } from 'den-design-system'

import React from 'react'
import pallete from '../../../global/newPallete'

interface DisplayCardProps {
  id: string
  heading: string
  content: string | React.ReactNode
}

const DisplayCard: React.FC<DisplayCardProps> = ({ id, heading, content }) => {
  return (
    <div
      id={id}
      className='relative flex flex-col rounded-md border border-gray-300 bg-white px-[20px] py-[16px]'
    >
      <DDSTypography.Paragraph
        size='para'
        variant='regular'
        color={pallete.colors.textDark5}
      >
        {heading}
      </DDSTypography.Paragraph>
      <div className='pt-[8px]'>
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          className='break-all'
          color={pallete.colors.textDark3}
        >
          {content}
        </DDSTypography.Title>
      </div>
    </div>
  )
}

export default DisplayCard
