import DistributionBar, { Distribution } from './common/DistributionBar'
import { Grid, Stack } from '@mui/material'

import React from 'react'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import UsageBar from './UsageBar'
import UsageCard from './common/UsageCard'
import { generateColors } from '../../utils/CommonUtils'
import palette from '../../global/pallete'

interface LicenseDistributionCardProps {
  usage: number //In percentage
  mostCommonLicense: string
  totalLicenses: number
  usedLicenses: number
  distributions: any[]
  link?: string
}
const LicenseDistributionCard: React.FC<LicenseDistributionCardProps> = ({
  usage,
  mostCommonLicense,
  totalLicenses,
  usedLicenses,
  distributions,
  link
}) => {
  const distributionColors = generateColors(distributions.length)
  return (
    <UsageCard>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid item xs={2.4}>
          <Stack spacing={1}>
            <TypographyDDS.Title type='h5' variant='bold'>
              Usage
            </TypographyDDS.Title>
            <TypographyDDS.Title type='h4' variant='bold' color='primary'>
              {+usage.toFixed(2)}%
            </TypographyDDS.Title>
            <UsageBar usage={usage} />
          </Stack>
        </Grid>
        <Grid item xs='auto'>
          <Stack>
            <TypographyDDS.Paragraph
              size='para'
              variant='semiBold'
              color='darkGrey'
            >
              Most Common License
            </TypographyDDS.Paragraph>
            <TypographyDDS.Title
              type='h4'
              variant='bold'
              style={{ whiteSpace: 'nowrap' }}
            >
              {mostCommonLicense}
            </TypographyDDS.Title>
          </Stack>
        </Grid>
        <Grid item xs='auto'>
          <Stack>
            <TypographyDDS.Paragraph
              size='para'
              variant='semiBold'
              color='darkGrey'
            >
              Total License
            </TypographyDDS.Paragraph>
            <TypographyDDS.Title type='h4' variant='bold'>
              {totalLicenses}
            </TypographyDDS.Title>
          </Stack>
        </Grid>
        <Grid item xs='auto'>
          <Stack>
            <TypographyDDS.Paragraph
              size='para'
              variant='semiBold'
              color='textDark'
            >
              Used Licenses
            </TypographyDDS.Paragraph>
            <TypographyDDS.Title type='h4' variant='bold'>
              {usedLicenses}
            </TypographyDDS.Title>
          </Stack>
        </Grid>
        <Grid item xs='auto'>
          <Stack>
            <TypographyDDS.Paragraph
              size='para'
              variant='semiBold'
              color='darkGrey'
            >
              Available
            </TypographyDDS.Paragraph>
            <TypographyDDS.Title type='h4' variant='bold'>
              {totalLicenses - usedLicenses}
            </TypographyDDS.Title>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack>
            <TypographyDDS.Paragraph
              size='para'
              variant='semiBold'
              color='darkGrey'
            >
              License Type Distribution
            </TypographyDDS.Paragraph>
            <DistributionBar
              distributions={distributions.map((distribution, index) => ({
                name: distribution.name,
                value: distribution.usage,
                color: distributionColors[index]
              }))}
            />
          </Stack>
        </Grid>
      </Grid>
    </UsageCard>
  )
}

export default LicenseDistributionCard
