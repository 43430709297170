import {
  APIOverviewData,
  ExceptionOverviewData,
  HealthOverviewData,
  JobOverviewData,
  ProjectSummaryData
} from '../types/store.types'
import {
  getAHCOverviewAPI,
  getAPIMonitoringOverviewAPI,
  getExceptionOverviewAPI,
  getJobOverviewAPI,
  getProjectSummaryAPI
} from '../api/overview/Overview.service'
import { makeAutoObservable, observable, toJS } from 'mobx'

import { ProjectType } from '../enum/ProjectType.enum'
import { RootStore } from './RootStore'
import moment from 'moment'

//Initial Data
const initialExceptionData: ExceptionOverviewData = {
  totalExceptions: 0,
  mostCommonException: '',
  mostCriticalException: '',
  recentExceptions: [],
  exceptionTypePercentages: {},
  exceptionsCountBySeverity: {
    criticalCount: 0,
    majorCount: 0,
    minorCount: 0,
    moderateCount: 0
  },
  exceptionsCountByStatus: {
    ignoredExceptions: 0,
    resolvedExceptions: 0,
    snoozedExceptions: 0,
    unresolvedExceptions: 0
  },
  zeroExceptionsCount: true
}
const initialJobData: JobOverviewData = {
  totalJobs: 0,
  successfulJobs: 0,
  failedJobs: 0,
  mostExecutedJob: '',
  mostFailedJob: '',
  slowestJob: '',
  recentSuccessfulJobs: [],
  recentFailureJobs: [],
  responsesByInterval: {},
  topJobs: {
    commonJobs: [],
    failedJobs: [],
    jobsWithLowestRuntime: []
  },
  zeroJobsCount: true
}
const initialAPIData: APIOverviewData = {
  totalLogs: 0,
  successfulLogs: 0,
  successStatusCodes: [],
  failedLogs: 0,
  failedStatusCodes: [],
  logCountByStatusCode: '',
  mostCommonApi: '',
  mostFailedApi: '',
  recentlyFailedLogs: [],
  logWithLowestResponseTime: '',
  topApis: {
    commonApis: [],
    failedApis: [],
    lowestResponsiveApis: []
  },
  zeroLogsCount: true
}
const initialHealthData: HealthOverviewData = {
  totalApplications: 0,
  totalUp: 0,
  totalDown: 0,
  highestAvailability: '',
  highestDowntime: '',
  slowestResponseTime: '',
  recentApplicationsUp: [],
  recentApplicationsDown: [],
  responsesByInterval: {},
  topAhcs: {
    checksWithHighestAvailability: [],
    checksWithHighestDowntime: [],
    checksWithSlowestResponseTime: []
  },
  zeroApplicationsCount: true
}

const initialProjectSummaryData: ProjectSummaryData = {
  projectID: '',
  projectName: '',
  projectType: ProjectType.FULL_STACK_ONLY,
  totalEnvironments: 0,
  totalMembers: 0
}

//Popup tabs
const healthPopupHeaderData = {
  tabs: [
    { id: 0, label: 'Availability' },
    { id: 1, label: 'Downtime' },
    { id: 2, label: 'Response Time' }
  ],
  titles: ['Highest Availability', 'Highest Downtime', 'Slowest Response Time'],
  descriptions: [
    'Top 10 checks with highest availability',
    'Top 10 checks with highest downtime',
    'Top 10 checks with slowest response time'
  ]
}
const jobPopupHeaderData = {
  tabs: [
    { id: 0, label: 'Executions' },
    { id: 1, label: 'Failed' },
    { id: 2, label: 'Run Time' }
  ],
  titles: ['Most Executed Job', 'Most Failed Job', 'Slowest Run Time'],
  descriptions: [
    'Top 10 most executed job',
    'Top 10 most failed job',
    'Top 10 jobs with slowest run time'
  ]
}
const apiPopupHeaderData = {
  tabs: [
    { id: 0, label: 'Total' },
    { id: 1, label: 'Failed' },
    { id: 2, label: 'Response Time' }
  ],
  titles: ['Most Requested APIs', 'Most Failed APIs', 'Slowest APIs'],
  descriptions: [
    'Top 10 most requested APIs',
    'Top 10 most failed APIs',
    'Top 10 slowest APIs'
  ]
}
export class OverviewStore {
  projectSummary: ProjectSummaryData
  healthCheckData: HealthOverviewData
  jobData: JobOverviewData
  apiData: APIOverviewData
  exceptionData: ExceptionOverviewData
  activeModule: 'health' | 'job' | 'api'
  activeTab: number
  healthChartKey: number
  jobChartKey: number
  apiChartKey: number
  exceptionChartKey: number
  healthLoading: boolean
  jobLoading: boolean
  apiLoading: boolean
  exceptionLoading: boolean
  projectSummaryLoading: boolean
  constructor(private rootStore: RootStore) {
    this.jobData = initialJobData
    this.healthCheckData = initialHealthData
    this.apiData = initialAPIData
    this.activeModule = 'health'
    this.exceptionData = initialExceptionData
    this.activeTab = 0
    this.healthChartKey = 0
    this.jobChartKey = 0
    this.apiChartKey = 0
    this.exceptionChartKey = 0
    this.healthLoading = false
    this.jobLoading = false
    this.apiLoading = false
    this.exceptionLoading = false
    this.projectSummaryLoading = false
    this.projectSummary = initialProjectSummaryData
    makeAutoObservable(this)
  }
  //setters
  setJobData(jobData: JobOverviewData) {
    this.rootStore.overviewStore.jobData = jobData
    this.rootStore.overviewStore.jobChartKey += 1
  }
  setAPIData(apiData: APIOverviewData) {
    const apis = Object.keys(apiData.topApis).reduce(
      (obj, key) => {
        obj[key] = apiData.topApis[key as keyof typeof apiData.topApis].map(
          (record, index) => ({ id: index, ...record })
        )
        return obj
      },

      {} as any
    )
    apiData.topApis = apis
    this.rootStore.overviewStore.apiData = apiData

    this.rootStore.overviewStore.apiChartKey += 1
  }
  setExceptionData(data: ExceptionOverviewData) {
    this.rootStore.overviewStore.exceptionData = data
    this.rootStore.overviewStore.exceptionData.totalExceptions =
      this.rootStore.overviewStore.exceptionData.totalExceptions || 0
    this.rootStore.overviewStore.exceptionChartKey += 1
  }

  setHealthData(data: HealthOverviewData) {
    this.rootStore.overviewStore.healthCheckData = data
    this.rootStore.overviewStore.healthChartKey += 1
  }

  setActiveTab(tab: number) {
    this.rootStore.overviewStore.activeTab = tab
  }
  setActiveModule(module: 'health' | 'job' | 'api') {
    this.rootStore.overviewStore.activeModule = module
  }

  setHealthLoading(loading: boolean) {
    this.rootStore.overviewStore.healthLoading = loading
  }
  setJobLoading(loading: boolean) {
    this.rootStore.overviewStore.jobLoading = loading
  }
  setAPILoading(loading: boolean) {
    this.rootStore.overviewStore.apiLoading = loading
  }
  setExceptionLoading(loading: boolean) {
    this.rootStore.overviewStore.exceptionLoading = loading
  }

  setProjectSummaryLoading(loading: boolean) {
    this.rootStore.overviewStore.projectSummaryLoading = loading
  }

  setLoading(loading: boolean) {
    this.healthLoading = loading
    this.jobLoading = loading
    this.apiLoading = loading
    this.exceptionLoading = loading
  }

  setProjectSummary(summary: ProjectSummaryData) {
    this.rootStore.overviewStore.projectSummary = summary
  }

  //Getters
  getJobChartKey() {
    return this.rootStore.overviewStore.jobChartKey
  }
  getAPIChartKey() {
    return this.rootStore.overviewStore.apiChartKey
  }
  getExceptionChartKey() {
    return this.rootStore.overviewStore.exceptionChartKey
  }
  getHealthChartKey() {
    return this.rootStore.overviewStore.healthChartKey
  }
  getProjectSummary() {
    return this.rootStore.overviewStore.projectSummary
  }
  getHealthData() {
    return this.rootStore.overviewStore.healthCheckData
  }
  getJobData() {
    return this.rootStore.overviewStore.jobData
  }

  getAPIData() {
    return this.rootStore.overviewStore.apiData
  }

  getExceptionOverview() {
    return this.rootStore.overviewStore.exceptionData
  }

  getActiveData() {
    switch (this.rootStore.overviewStore.activeModule) {
      case 'health':
        switch (this.getActiveTab()) {
          case 0:
            return this.getHealthData().topAhcs.checksWithHighestAvailability
          case 1:
            return this.getHealthData().topAhcs.checksWithHighestDowntime
          default:
            return this.getHealthData().topAhcs.checksWithSlowestResponseTime
        }
      case 'job':
        switch (this.getActiveTab()) {
          case 0:
            return this.getJobData().topJobs.commonJobs
          case 1:
            return this.getJobData().topJobs.failedJobs
          default:
            return this.getJobData().topJobs.jobsWithLowestRuntime
        }
      default:
        switch (this.getActiveTab()) {
          case 0:
            return this.getAPIData().topApis.commonApis
          case 1:
            return this.getAPIData().topApis.failedApis
          default:
            return this.getAPIData().topApis.lowestResponsiveApis
        }
    }
  }
  getPopupHeaderData() {
    switch (this.rootStore.overviewStore.activeModule) {
      case 'health':
        return healthPopupHeaderData
      case 'job':
        return jobPopupHeaderData
      case 'api':
        return apiPopupHeaderData
      default:
        return jobPopupHeaderData
    }
  }
  getPopupTitle() {
    return this.getPopupHeaderData().titles[this.getActiveTab()]
  }
  getPopupDescription() {
    return this.getPopupHeaderData().descriptions[this.getActiveTab()]
  }

  getActiveTab() {
    return this.rootStore.overviewStore.activeTab
  }
  getActiveModule() {
    return this.rootStore.overviewStore.activeModule
  }

  getGroupedStatusCount() {
    const statusCount: Record<string, number> = {
      '2xx': 0,
      '3xx': 0,
      '4xx': 0,
      '5xx': 0
    }
    const logs = this.getAPIData().logCountByStatusCode
    if (typeof logs !== 'string') {
      Object.keys(logs).reduce((obj, key) => {
        obj[`${key[0]}xx`] = obj[`${key[0]}xx`] + logs[key]
        return obj
      }, statusCount)
    }
    return statusCount
  }

  getTopAPIStatusCode() {
    const logs = this.getAPIData().logCountByStatusCode
    let statusCode = ''
    if (typeof logs !== 'string') {
      Object.keys(logs).reduce((maxRequests, key) => {
        if (logs[key] > maxRequests) {
          statusCode = key
          return logs[key]
        }
        return maxRequests
      }, 0)
    }
    return statusCode
  }

  getHealthLoading() {
    return this.rootStore.overviewStore.healthLoading
  }
  getJobLoading() {
    return this.rootStore.overviewStore.jobLoading
  }
  getAPILoading() {
    return this.rootStore.overviewStore.apiLoading
  }
  getExceptionLoading() {
    return this.rootStore.overviewStore.exceptionLoading
  }
  getProjectSummaryLoading() {
    return this.rootStore.overviewStore.projectSummaryLoading
  }

  getExceptionDateFiltersTimestamps() {
    const filters: Record<string, any> =
      this.rootStore.overviewStore.exceptionData.filters || {}
    const createdFrom = filters['created-from']
    const createdTo = filters['created-to']
    if (createdFrom && createdTo) {
      filters['created-from'] = moment(createdFrom).unix()*1000
      filters['created-to'] = moment(createdTo).unix()*1000
      return filters
    }
    return null
  }

  //API calls
  async getExceptionOverviewFromAPI() {
    this.setExceptionLoading(true)
    try {
      const { data, filters } = await getExceptionOverviewAPI()
      this.setExceptionData({ ...data, filters })
    } finally {
      this.setExceptionLoading(false)
    }
  }
  async getJobOverviewFromAPI() {
    this.setJobLoading(true)
    try {
      const { data } = await getJobOverviewAPI()
      this.setJobData(data)
    } finally {
      this.setJobLoading(false)
    }
  }

  async getAPIOverviewFromAPI() {
    this.setAPILoading(true)
    try {
      const { data } = await getAPIMonitoringOverviewAPI()
      this.setAPIData(data)
    } finally {
      this.setAPILoading(false)
    }
  }
  async getAHCOverviewFromAPI() {
    this.setHealthLoading(true)
    try {
      const { data } = await getAHCOverviewAPI()
      this.setHealthData(data)
    } finally {
      this.setHealthLoading(false)
    }
  }

  async getProjectSummaryFromAPI() {
    this.setProjectSummaryLoading(true)
    try {
      const { data } = await getProjectSummaryAPI()
      this.setProjectSummary(data)
    } finally {
      this.setProjectSummaryLoading(false)
    }
  }

  //Obligatory Reset function
  reset() {
    this.exceptionData = initialExceptionData
    this.jobData = initialJobData
    this.healthCheckData = initialHealthData
    this.apiData = initialAPIData
  }
}
