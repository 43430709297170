import { makeAutoObservable, observable } from 'mobx'

import { OnBoardingType } from '../api/user/User.types'
import { RootStore } from './RootStore'
import { cloneDeep } from 'lodash'

export class UserStore {
  id: string
  email: string
  userRole: string
  firstName: string
  lastName: string
  userPreference: any
  organizations: any[]
  avatar: string
  isVerified: boolean
  selectedOrganization: any
  notificationCount: number
  invitationCount: number
  noActiveSubscription: boolean
  loggedIn = false
  onBoardedVia: OnBoardingType

  constructor(private rootStore: RootStore) {
    this.id = ''
    this.email = ''
    this.userRole = ''
    this.firstName = ''
    this.lastName = ''
    this.avatar = ''
    this.userPreference = {}
    this.organizations = []
    this.isVerified = false
    this.selectedOrganization = {}
    this.isVerified = false
    this.loggedIn = false
    this.notificationCount = 0
    this.invitationCount = 0
    this.noActiveSubscription = false
    this.onBoardedVia = OnBoardingType.EMAIL
    makeAutoObservable(this, {
      id: observable.struct,
      email: observable.struct,
      userRole: observable.struct,
      selectedOrganization: observable.struct,
      organizations: observable.struct,
      isVerified: observable.struct,
      notificationCount: observable.struct,
      invitationCount: observable.struct,
      noActiveSubscription: observable.struct
    })
  }
  setId(id: string) {
    this.id = id
  }

  getId() {
    return this.id
  }

  setUserPreference(userPreference: any) {
    this.userPreference = userPreference
  }

  getUserPreference() {
    return this.userPreference
  }

  setIsVerified(isVerified: boolean) {
    this.isVerified = isVerified
  }

  getIsVerified() {
    return this.isVerified
  }

  setVerification(isVerified: boolean) {
    this.isVerified = isVerified
  }

  getVerification() {
    return this.isVerified
  }

  setAvatar(avatar: string) {
    this.avatar = avatar
  }

  getAvatar() {
    return this.avatar
  }

  setEmail(email: string) {
    this.email = email
  }

  getEmail() {
    return this.email
  }

  setUserRole(userRole: string) {
    this.userRole = userRole
  }

  getUserRole() {
    return this.userRole
  }

  setFirstName(firstName: string) {
    this.firstName = firstName
  }

  setLastName(lastName: string) {
    this.lastName = lastName
  }

  getFirstName() {
    return this.firstName
  }
  getLastName() {
    return this.lastName
  }

  setOrganizations(organizations: any[]) {
    this.organizations = organizations
  }
  setSelectedOrganization(selectedOrganization: any) {
    this.rootStore.userStore.selectedOrganization = { ...selectedOrganization }
  }
  getSelectedOrganization() {
    return this.rootStore.userStore.selectedOrganization
  }

  getOrganizations() {
    return this.organizations
  }
  getNotificationCount() {
    return this.notificationCount
  }
  setNotificationCount(notificationCount: number) {
    this.notificationCount = notificationCount
  }
  getInvitationCount() {
    return this.invitationCount
  }
  setInvitationCount(invitationCount: number) {
    this.invitationCount = invitationCount
  }

  getIsLoggedIn() {
    return this.loggedIn
  }

  setIsLoggedIn(isLoggedIn: boolean) {
    this.loggedIn = isLoggedIn
  }

  setAvatarById(id: string, avatar: string) {
    const newOrgs = cloneDeep(this.organizations)
    const orgIndex = this.organizations.findIndex((org) => org.id === id)
    newOrgs[orgIndex].avatar = avatar
    this.setOrganizations(newOrgs)
  }

  getNoActiveSubscription() {
    return this.noActiveSubscription
  }

  setNoActiveSubscription(noActiveSubscription: boolean) {
    this.noActiveSubscription = noActiveSubscription
  }

  setOnBoardedVia(onBoardedVia: OnBoardingType) {
    this.onBoardedVia = onBoardedVia
  }

  getOnBoardedVia() {
    return this.onBoardedVia
  }

  reset() {
    this.id = ''
    this.email = ''
    this.userRole = ''
    this.firstName = ''
    this.lastName = ''
    this.avatar = ''
    this.userPreference = {}
    this.organizations = []
    this.isVerified = false
    this.selectedOrganization = {}
    this.isVerified = false
    this.notificationCount = 0
    this.invitationCount = 0
    this.noActiveSubscription = false
    this.onBoardedVia = OnBoardingType.EMAIL
  }
}
