import { DDSTooltip } from 'den-design-system'
import React from 'react'
import { Tooltip } from '@mui/material'
import pallete from '../../../global/newPallete'
interface AvatarProps {
  id?: string
  avatarType: 'Single' | 'Multiple'
  imageUrl?: string
  imageObjectGroup?: { name: string; avatar: string }[]
  isBorder?: boolean
  name?: string
}

const UserAvatar: React.FC<AvatarProps> = ({
  id,
  avatarType,
  imageUrl,
  imageObjectGroup,
  isBorder = true,
  name
}) => {
  const renderUserAvatar = (imageUrl: any, index: number, name: string) => (
    <DDSTooltip
      id={name as string}
      label={name}
      shape='roundedRectangle'
      position='top'
      theme='light'
      className={`border override-tooltip-arrow `}
      style={{ borderColor: pallete.colors.stroke2, zIndex: 99999 }}
    >
      <div className='w-full flex justify-center'>
        <img
          id={id}
          key={index}
          className={`w-[24px] h-[24px] border  border-white rounded-full ${
            isBorder ? 'dark:border-white' : 'dark:border-white'
          }`}
          src={imageUrl}
          alt=''
        />
      </div>
    </DDSTooltip>
  )
  const remainingCount = imageObjectGroup ? imageObjectGroup.length - 2 : 0

  return (
    <>
      {avatarType == 'Multiple' ? (
        <div className='flex -space-x-[10px] rtl:space-x-reverse'>
          {imageObjectGroup &&
            imageObjectGroup
              .slice(0, 2)
              .map((item: any, index: number) =>
                renderUserAvatar(item.avatar, index, item.name)
              )}
          {remainingCount > 0 && (
            <div
              id={id}
              style={{ backgroundColor: pallete.colors.primary1 }}
              className={`flex items-center justify-center w-[24px] h-[24px] text-xs font-medium text-white border border-white dark:border-white rounded-full px-[6px]`}
            >
              +{remainingCount}
            </div>
          )}
        </div>
      ) : (
        <DDSTooltip
          id={name as string}
          label={name}
          shape='roundedRectangle'
          className={`border override-tooltip-arrow px-[8px] py-[16px]`}
          position='top'
          theme='light'
          style={{ borderColor: pallete.colors.stroke2, zIndex: 99999 }}
        >
          <div className='w-full flex justify-center'>
            <img
              id={id}
              className='w-[24px] h-[24px] border border-white rounded-full dark:border-white'
              src={imageUrl}
              alt=''
            />
          </div>
        </DDSTooltip>
      )}
    </>
  )
}

export default UserAvatar
