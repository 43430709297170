import pallete from '../global/newPallete'

export const IncidentPriority = [
  {
    label: 'P0',
    value: 'P0',
    styles: {
      backgroundColor: pallete.colors.warningRed1
    }
  },
  {
    label: 'P1',
    value: 'P1',
    styles: {
      backgroundColor: pallete.colors.warningRed2
    }
  },
  {
    label: 'P2',
    value: 'P2',
    styles: {
      backgroundColor: pallete.colors.warningRed3
    }
  },
  {
    label: 'P3',
    value: 'P3',
    styles: {
      backgroundColor: pallete.colors.warningBlue
    }
  }
]
