import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import {
  getValidationError,
  getValidationStatus
} from '../../../utils/ValidationUtils'

import { AiOutlineCheck } from 'react-icons/ai'
import Button from '../../common/Button/Button'
import Dropdown from '../../common/Dropdown/Dropdown'
import InputField from '../../common/InputField/InputField'
import Joi from 'joi'
import { OrganizationUserRoles } from '../../../enum/OrganizationUserRoles.enum'
import React from 'react'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import { TypographyDDS } from '../../common/Typography/TypographyDDS'
import { UrlWithOnlyOrgId } from '../../../utils/UrlUtils'
import ValidationConstants from '../../../constants/ValidationConstants'
import breakpoints from '../../../global/breakpoints'
import { createUserInvite } from '../../../api/userInvites/UserInvites.service'
import { isUndefined } from 'lodash'
import palette from '../../../global/pallete'
import plusIcon from '../../../assets/icons/plus.svg'
import routeConstants from '../../../constants/RouteConstants'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media screen and (max-width: ${breakpoints.md}) {
    padding: 0 2.5em;
  }
  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.85rem;
  }

  @media (max-width: ${breakpoints.md}) {
    font-size: 0.7rem;
  }
`

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1.25em 0;
  flex-grow: 5;
  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
`

const DescriptionSection = styled.div`
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`

const FormSection = styled.div`
  width: 50%;
  padding-top: 20px;

  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`

const BottomContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0;
  flex-grow: 1;
`

const BottomLeftContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0;
  flex-grow: 1;
`

const InviteMembers: React.FC<{ inviteMembers: boolean }> = ({
  inviteMembers = false
}) => {
  const roleDropdownOptions = [
    {
      id: '0',
      name: 'Billing',
      value: OrganizationUserRoles.BILLING,
      secondaryText: 'Manages Accounts and Subscriptions for the Organization'
    },
    {
      id: '1',
      name: 'Member',
      value: OrganizationUserRoles.MEMBER,
      secondaryText: 'Part of the Organization added by Owner'
    }
  ]
  const [email, setEmail] = React.useState<string | null>('')
  const [loading, setLoading] = React.useState(false)
  const [role, setUserRole] = React.useState<string | null>('')
  const [validationErrorResult, setValidationErrorResult] = React.useState<
    any[]
  >([])
  const handleEmail = (e: any) => {
    setEmail(e.target.value)
  }
  const navigate = useNavigate()
  const store = useStoreContext()

  const handleDropdownChange = (role: string) => {
    setUserRole(roleDropdownOptions[parseInt(role)].value)
  }
  const [invitationSent, setInvitationSent] = React.useState(false)

  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        'string.empty': ValidationConstants.GENERIC.REQUIRED_VALUE,
        'string.email': ValidationConstants.GENERIC.INVALID_EMAIL
      }),
    role: Joi.string().required().messages({
      'any.required': ValidationConstants.NO_ROLE_ADDED,
      'string.empty': ValidationConstants.NO_ROLE_ADDED
    })
  })

  const handleInviteCreation = () => {
    const validationResult = schema.validate(
      {
        email,
        role
      },
      { abortEarly: false }
    )
    if (isUndefined(validationResult.error)) {
      setLoading(true)
      createUserInvite({ invitee: email as string, role: role as string })
        .then((response: any) => {
          ToastNotification({ type: 'success', message: response.message })
          setEmail('')
          setUserRole('')
          setInvitationSent(true)
          inviteMembers
            ? navigate(
                UrlWithOnlyOrgId(
                  routeConstants.MEMBER_SETTINGS + '?tab=1',
                  store
                )
              )
            : navigate(routeConstants.REDIRECT)
        })
        .catch((err) => {
          ToastNotification({
            type: 'error',
            message: err
          })
        })
        .finally(() => setLoading(false))
    } else {
      setValidationErrorResult(validationResult.error.details)
    }
  }

  return (
    <Container>
      {/* <TopContainer> */}
      <MainContainer>
        <DescriptionSection>
          <TypographyDDS.Heading
            variant='bold'
            style={{
              paddingBottom: '10px'
            }}
          >
            Invite members to
            <br />
            <span
              style={{
                fontWeight: palette.fontWeight.bold,
                color: palette.colors.primary
              }}
            >
              {store.userStore.getSelectedOrganization().name}
            </span>
          </TypographyDDS.Heading>
          <TypographyDDS.Title type='h3' color='textDark2'>
            Let{`'`}s Collaborate!
          </TypographyDDS.Title>
        </DescriptionSection>
        <FormSection>
          <InputField
            label="Member's Email"
            size='medium'
            width='100%'
            margin='10px 0 16px'
            onChange={handleEmail}
            error={getValidationStatus(validationErrorResult, 'email')}
            helperText={getValidationError(validationErrorResult, 'email')}
          />
          <Dropdown
            title={`Member's Role`}
            secondaryTitleText='Admin, Manager, etc'
            variant='default'
            handleChange={handleDropdownChange}
            width='100%'
            hideTooltip
            dropdownOptions={roleDropdownOptions}
            helperText={getValidationError(validationErrorResult, 'role')}
            error={getValidationStatus(validationErrorResult, 'role')}
          />
          <Button
            margin='30px 0 24px'
            variant='contained'
            fullWidth
            size='large'
            iconSize={invitationSent ? '16px' : '12px'}
            startIcon={
              invitationSent ? <AiOutlineCheck /> : <img src={plusIcon} />
            }
            background={
              invitationSent
                ? palette.colors.gradientGreen
                : palette.colors.gradientBlue
            }
            loading={loading}
            onClick={handleInviteCreation}
          >
            {!invitationSent ? 'Send Invite' : 'Invitation Sent'}
          </Button>
        </FormSection>
      </MainContainer>

      {/* <SentInvitesSection>
          <SentInviteHeader>
            <Typography variant='body2' style={{ minWidth: '6.25em' }}>
              Sent Invites
            </Typography>
            <DividerLine />
          </SentInviteHeader>
          <ChipsContainer>
            {invited.map((item, index) => (
              <Chip
                key={index + 1}
                label={
                  <>
                    <span>{item.email}, </span>
                    <span style={{ fontWeight: 600 }}>{item.role}</span>
                  </>
                }
                onDelete={() => {
                  console.log('Write delete function')
                }}
              />
            ))}
          </ChipsContainer>
        </SentInvitesSection> */}
      {/* </TopContainer> */}
      {/* </MiddleSection> */}

      {!inviteMembers ? (
        <BottomContainer>
          <Button
            variant='outlined'
            endIcon={<IoIosArrowForward size={16} />}
            height='3.125em'
            boxShadow={false}
            onClick={() => {
              navigate(routeConstants.REDIRECT)
            }}
          >
            Skip
          </Button>
        </BottomContainer>
      ) : (
        <BottomLeftContainer>
          <Button
            variant='outlined'
            startIcon={<IoIosArrowBack size={16} />}
            height='3.125em'
            boxShadow={false}
            onClick={() => {
              navigate(UrlWithOnlyOrgId(routeConstants.MEMBER_SETTINGS, store))
            }}
          >
            Back
          </Button>
        </BottomLeftContainer>
      )}
    </Container>
  )
}

export default InviteMembers
