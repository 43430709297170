//Route constants are organized alphabetically
//org = organization_Id for Settings || organization_Id + project_Id + environment
const routeConstants = {
  ARCHIVED_API_RECORDS: ':pathIds/apiMonitoring/archived',
  ACCOUNT_SETTINGS: '/settings/account',
  AHC: ':pathIds/healthChecks',
  AHC_BADGE: '/healthChecks/badge/:encryptedId',
  AHC_PROJECTS_DASHBOARD: ':pathIds/healthChecks/dashboard',
  AHC_DASHBOARD: ':pathIds/healthChecks/dashboard/:id',
  AHC_ADD_CHECK: ':pathIds/healthChecks/dashboard/add-check',
  AHC_UPDATE_CHECK: ':pathIds/healthChecks/dashboard/update-check/:id',
  AHC_HOUR_LOGS: ':pathIds/healthChecks/dashboard/:id/logs/:eventId',
  AHC_STATUS: ':pathIds/healthChecks/status',

  ALL_PAGES: '/allPages',
  CREATE_ORGANIZATION: '/organization/create',
  COMING_SOON: '/comingSoon',

  PUBLIC_ERROR_MONITORING: '/shares/:token',
  INCIDENT_PUBLIC_SHARE: '/incident-shares/:token',
  CHECKS_PUBLIC_SHARE: '/check-shares/:id',

  GROUPED_ERROR_MONITORING: ':pathIds/exceptions',
  ERROR_MONITORING: ':pathIds/exceptions/group/:id',
  SINGLE_ERROR_MONITORING: ':pathIds/exceptions/grouped',

  ESCALATION_SETS: ':pathIds/escalations',
  ESCALATION_SETS_OVERVIEW: ':pathIds/escalations',
  ESCALATION_SET_DETAILS: ':pathIds/escalations/:escalationSetId',
  ESCALATION_SET_DETAIL: '/escalationSetDetail',
  ERROR_DETAILS: ':pathIds/exceptions/grouped/:id',
  EXCEPTIONS: ':pathIds/exceptions',
  NO_RECORD: '/noRecord',
  ERROR_PAGE: '/errorPage',
  UNAUTHORIZED_PAGE: '/unauthorizedPage',
  UNDERMAINTENANCE_PAGE: '/under-maintenance',
  STYLESHEET: '/stylesheet',

  FORGOT_PASSWORD: '/forgotPassword',
  HEALTH_STATUS: ':pathIds/healthStatus',
  HOME: '/',

  INVITES: '/allInvites',
  INVITE_MEMBERS: '/organization/inviteMembers',
  INVITE_POPUP: '/invitePopup',

  PARTNER_INVITE_POPUP: '/partnerInvitePopup',
  INVITE_REDIRECT: '/inviteRedirect',
  PARTNER_REDIRECT: '/partnerRedirect',
  ISSUES: ':pathIds/issues',

  JOB_MONITORING: ':pathIds/jobMonitoring',
  JOB_LOGS: ':pathIds/jobMonitoring/:id',

  THINGS_TO_DO: ':pathIds/things-to-do',
  MANAGE_INCIDENTS: ':pathIds/incident/:incidentId',
  MANAGE_INCIDENTS_FROM_EXCEPTION:
    ':pathIds/incident/:incidentId/:exceptionLogId',
  UPDATE_INCIDENT: ':pathIds/incident/:incidentId/edit',
  MEMBER_SETTINGS: ':pathIds/settings/members',
  NOTIFICATIONS: '/allNotifications',
  NO_ORGANIZATION: '/organization/none',
  NO_PROJECT: ':pathIds/noProject',
  SANDBOX_SCREEN: '/sandbox',

  ORGANIZATION_SETTINGS: ':pathIds/settings/organization',
  OVERVIEW: ':pathIds/overview',

  PLANS: ':pathIds/plans',

  RESET_PASSWORD: '/resetPassword/:token',

  SETTINGS: ':pathIds/settings',
  SIGN_UP: '/sign-up',
  SIGN_UP_ALTERNATE: '/signUp',
  ONBOARD_USING_SSO: '/oauth2/callback/sso',
  SIGN_UP_INVITED: '/signUp/invited',
  SIGN_IN: '/sign-in',
  SIGN_IN_ALTERNATE: '/signIn',
  LAYOUT: '/layout',
  INCIDENTS: ':pathIds/incidents',

  REPORT_INCIDENT: ':pathIds/incident/reportIncident',

  SUBSCRIPTION_SETTINGS: ':pathIds/settings/billing',
  SINGLE_EXCEPTION: '/single-exception',
  SINGLE_LOG: '/single-log',
  REDIRECT: '/redirect',

  ENVIRONMENTS: '/environments',

  ROLES_AND_ACTIONS: '/roles-actions',

  //email redirects
  VERIFY_USER_REDIRECT: '/account/verify/:token',

  // Exception Incident management
  VIEW_AN_INCIDENT:
    ':pathIds/exceptions/grouped/:exceptionLogId/incident/:incidentId',
  INCIDENT: ':pathIds/incident',
  INCIDENT_OVERVIEW: ':pathIds/incident/overview/:incidentId',
  SALESFORCE_AUDIT: ':pathIds/salesforce-audit',
  SALESFORCE_AUDIT_DASHBOARD: ':pathIds/salesforce-audit/dashboard',
  SALESFORCE_AUDIT_METADATA_OVERVIEW: ':pathIds/salesforce-audit/metadata',
  SALESFORCE_AUDIT_LICENSES_OVERVIEW: ':pathIds/salesforce-audit/licenses',
  SALESFORCE_AUDIT_USERS_OVERVIEW: ':pathIds/salesforce-audit/users',
  PUBLIC_INCIDENT_PAGE: '/incident/shares/:token',

  SLACK_OAUTH_REDIRECT: '/slack/connect',

  CONNECT_TO_SALESFORCE: '/oauth2/callback/connect',

  //API Grouping
  API_MONITORING: ':pathIds/apiMonitoring',
  VIEW_GROUPED_API: ':pathIds/apiMonitoring/viewGroupedDetails/:id',
  VIEW_ALL_API: ':pathIds/apiMonitoring/viewAllDetails/:id',
  GROUPED_USER_DETAILS: ':pathIds/apiMonitoring/groupedUserDetails/:id',
  GROUPED_USER_API_LOGS_DETAILS:
    ':pathIds/apiMonitoring/apiLogsDetails/:apiGroupId/:apiUserId',

  //Setting
  SETTING: '/settings',
  PROJECT_SETTING: ':pathIds/settings/projects',
  VIEW_PROJECT: ':pathIds/settings/projects/:projectId',
  ACCOUNT_SETTING: '/settings/accountSettings',
  MANAGE_ORGANIZATION: ':pathIds/settings/manageOrganization',

  ADDHOST: ':pathIds/settings/projects/:projectId/addHost/:environmentId',

  PUBLIC_SHARE: '/public-share',
  PREFERENCES_SETTINGS: '/settings/preferences',
  PROJECT_SETTINGS: ':pathIds/settings/projects',
  PROJECT_SETTINGS_GENERAL: ':pathIds/settings/projects/:projectId/',
  PROJECT_SETTINGS_MEMBERS: ':pathIds/settings/projects/:projectId/members',
  PROJECT_SETTINGS_ENV: ':pathIds/settings/projects/:projectId/environments',
  PROJECT_SETTINGS_ENV_DEV:
    ':pathIds/settings/projects/:projectId/env/development',
  PROJECT_SETTINGS_ENV_STG: ':pathIds/settings/projects/:projectId/env/staging',
  PROJECT_SETTINGS_ENV_PROD:
    ':pathIds/settings/projects/:projectId/env/production'
}

export default routeConstants
