import { DDSTypography } from 'den-design-system'
import React from 'react'
import { observer } from 'mobx-react-lite'
import pallete from '../../global/newPallete'

interface TableRow {
  [key: string]: React.ReactNode
}

interface TableProps {
  title: string
  data: TableRow[]
  columns: Array<{
    columnDataStyle?: React.CSSProperties
    dataLabel: string
    render: (text: any) => React.ReactNode
  }>
}

const OverviewTable: React.FC<TableProps> = ({ title, data, columns }) => {
  return (
    <div className='flex flex-col gap-[12px] overflow-x-auto'>
      <DDSTypography.Title
        type='h5'
        variant='semiBold'
        color={pallete.colors.textDark3}
      >
        {title}
      </DDSTypography.Title>
      <table className='override-overview-table border-separate border-spacing-0 border-6  w-full'>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((col, colIndex) => (
                <td
                  key={colIndex}
                  className='py-[12px] px-[16px]'
                  style={col.columnDataStyle}
                >
                  {col.render(row[col.dataLabel])}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default observer(OverviewTable)
