import React, { useEffect, useState } from 'react'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Button } from '../../../components/New/Button/Button'
import { ChevronRight } from '@carbon/icons-react'
import CreateEscalation from '../../../assets/images/add.svg'
import { DDSTypography } from 'den-design-system'
import NoRecord from '../../../components/common/NoContent/NoRecord'
import { ProjectType } from '../../../enum/ProjectType.enum'
import { SeverityTypes } from '../../../api/exception/Exception.types'
import Spinner from '../../../components/common/Spinner/Spinner'
import Switch from '../../../components/New/Switch/Switch'
import Table from '../../../components/common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import { isNull } from 'lodash'
import { observer } from 'mobx-react-lite'
import { paginate } from '../../../utils/CommonUtils'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { timeFormats } from '../../../enum/TIME'
import { timeZone } from '../../../utils/DateTimeUtils'
import { updateAnExceptionEscalationSet } from '../../../api/exception/Exception.service'
import { useStoreContext } from '../../../store/StoreContext'

interface SetsProps {
  tableData: any
  setTableData: any
}

const EscalationSets: React.FC<SetsProps> = ({ tableData, setTableData }) => {
  const store = useStoreContext()
  const [loading, setLoading] = useState<boolean>(true)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(store.uiStore.getGlobalLoader())
  }, [store.uiStore.getGlobalLoader()])

  const fetchSets = () => {
    store.uiStore.setGlobalLoader(true)
    store.exceptionStore
      .getEscalationSetsFromAPI()
      .then(() => {
        setTableData(store.exceptionStore.getEscalationSets())
        store.exceptionStore.setRefresh(false)
      })
      .finally(() => {
        store.uiStore.setGlobalLoader(false)
      })
  }
  useEffect(() => {
    store.exceptionStore.reset()
    const pageFromQueryParams = searchParams.get('page')
    if (!isNull(pageFromQueryParams)) {
      setCurrentPage(Number(pageFromQueryParams))
    } else {
      setCurrentPage(1)
    }
    if (store.scopeStore.getEnvironmentId()?.length) {
      fetchSets()
    }
  }, [store.scopeStore.getScope()])

  const routeToEscalationSet = (id: string) => {
    const route = replaceUrl(
      routeConstants.ESCALATION_SET_DETAILS,
      store.scopeStore.getScope()['organization-id'] as string,
      store.scopeStore.getScope()['project-id'] as string,
      store.scopeStore.getScope()['environment-id'] as string
    ).replace(':escalationSetId', id)
    navigate(route)
  }

  const updateEscalationSetActive = (active: boolean, id: string) => {
    setLoading(true)
    const updatePayload = {
      $escalationSetId: id,
      isActive: active
    }
    updateAnExceptionEscalationSet(updatePayload)
      .then(() => {
        ToastNotification({
          type: 'success',
          message:
            ToastMessageConstants.EXCEPTION_ESCALATIONS.UPDATE_SET.SUCCESS
        })
        fetchSets()
      })
      .catch(() =>
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.EXCEPTION_ESCALATIONS.UPDATE_SET.ERROR
        })
      )
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (store.exceptionStore.getRefresh() === true) {
      fetchSets()
    }
  }, [store.exceptionStore.getRefresh(), location.search])

  const severityColorMap: Record<string, string> = {
    [SeverityTypes.CRITICAL]: pallete.colors.criticalSeverity,
    [SeverityTypes.MAJOR]: pallete.colors.majorSeverity,
    [SeverityTypes.MODERATE]: pallete.colors.moderateSeverity,
    [SeverityTypes.MINOR]: pallete.colors.minorSeverity
  }
  const columns = [
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-start text-start items-center gap-[4px]'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Escalation Name
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'escalationSetName',
      render: (text: any) => (
        <div className='flex justify-start max-w-[280px]'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            className='truncate max-w-[280px]'
            color={pallete.colors.textDark4}
          >
            {text ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center gap-[4px]'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            No of Escalation Levels
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'exceptionEscalationRules',
      render: (item: any) => (
        <div className='flex justify-center text-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark6}
          >
            {item.length}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center gap-[4px]'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {store.scopeStore.getSelectedProject().type ===
            ProjectType.FULL_STACK_ONLY
              ? 'Exception Severity'
              : 'Error Severity'}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'severity',
      render: (text: any) => (
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          className='flex items-center justify-center'
        >
          <div
            style={{
              backgroundColor: severityColorMap[text],
              color: pallete.colors.white
            }}
            className='py-[4px] px-[12px] w-[135px] flex h-[26px] items-center justify-center rounded-[4px]'
          >
            {text ? text : '-'}
          </div>
        </DDSTypography.Paragraph>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center gap-[4px]'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Last Reported
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'latestReportedAt',
      render: (text: any) => (
        <div className='flex justify-center text-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark6}
          >
            {text
              ? timeZone(
                  store.userStore.getUserPreference().timeZone,
                  text,
                  timeFormats.fullMonthDateYearTime
                )
              : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Active
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'isActive',
      render: (active: boolean, fullItem: any) => (
        <Switch
          id='active-btn'
          value={active}
          onChange={(value) => {
            updateEscalationSetActive(value, fullItem['id'])
          }}
          disabled={store.userStore.getNoActiveSubscription()}
        />
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {
        height: '54px',
        width: '60px'
      },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='flex justify-center items-center'>
          <ChevronRight
            size={16}
            className='cursor-pointer'
            color={pallete.colors.primary2}
            onClick={() => {
              routeToEscalationSet(fullItem['id'])
            }}
          />
        </div>
      )
    }
  ]

  return loading ||
    store.uiStore.getGlobalLoader() ||
    store.uiStore.getprojectEnvironmentLoader() ||
    !tableData ? (
    <Spinner />
  ) : (
    <>
      <div className='py-[24px] flex flex-col'>
        {tableData.length > 0 ? (
          <Table
            data={paginate(tableData, currentPage, 10)}
            columns={columns}
            totalCount={tableData?.length}
            rowsPerPage={10}
            tableStyle={{}}
            currentPage={currentPage}
            onPageChange={(page: number) => {
              const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                location.search
              )
              setSearchParams({ ...allQueryParams, page: page.toString() })
              setCurrentPage(page)
            }}
            loading={
              store.uiStore.getGlobalLoader() ||
              store.uiStore.getprojectEnvironmentLoader()
            }
          />
        ) : (
          <NoRecord
            imageSrc={CreateEscalation}
            text='No Escalation created yet.'
            style={{ height: '70vh' }}
            button={
              <Button
                id='createEsc'
                size='small'
                type='filled'
                label='Create Escalations'
                disabled={store.userStore.getNoActiveSubscription()}
                onClick={() => routeToEscalationSet('$')}
              />
            }
          />
        )}
      </div>
    </>
  )
}

export default observer(EscalationSets)
