import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'

import { ChevronDown } from '@carbon/icons-react'
import { IoIosClose } from 'react-icons/io'
import Spinner from '../Spinner/Spinner'
import pallete from '../../../global/newPallete'

interface Option {
  id: string
  email: string
  role: string
  [key: string]: any
}

interface MultiSelectProps {
  options: Option[]
  value: Option | Option[]
  optionsLabelAccessor: (option: Option) => string
  onChange: (value: Option | Option[]) => void
  onDeleteSelection?: (option: Option) => void
  placeholder?: string
  label?: string
  onTextChange?: (value: string) => void
  validationError?: string | false
  openDropdown?: boolean
  setOpenDropdown: (isOpen: boolean) => void
  loadingOption?: boolean
  disabled?: boolean
  multiSelect?: boolean
}

export const MultiSelect = forwardRef<any, MultiSelectProps>(
  (
    {
      options,
      value,
      optionsLabelAccessor,
      onChange,
      onDeleteSelection,
      placeholder = '',
      label = '',
      onTextChange,
      validationError,
      openDropdown = false,
      setOpenDropdown,
      loadingOption = false,
      disabled = false,
      multiSelect = true
    },
    ref
  ) => {
    const [searchTerm, setSearchTerm] = useState('')
    const wrapperRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(ref, () => ({
      // Add any methods you want to expose here
    }))

    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (
          wrapperRef.current &&
          !wrapperRef.current.contains(event.target as Node)
        ) {
          setOpenDropdown(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [setOpenDropdown])

    const handleSelect = (option: Option) => {
      if (multiSelect) {
        const newValue = Array.isArray(value) ? [...value, option] : [option]
        onChange(newValue)
      } else {
        onChange(option)
      }
      setSearchTerm('')
      setOpenDropdown(false)
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }

    const handleDelete = (optionToDelete: Option) => {
      if (onDeleteSelection) {
        onDeleteSelection(optionToDelete)
      } else if (multiSelect && Array.isArray(value)) {
        onChange(value.filter((option) => option.id !== optionToDelete.id))
      } else {
        onChange([] as Option[])
      }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value
      setSearchTerm(newValue)
      if (onTextChange) {
        onTextChange(newValue)
      }
      setOpenDropdown(newValue.length >= 3)
    }

    const renderSelectedOptions = () => {
      if (multiSelect && Array.isArray(value)) {
        return value.map((option) => (
          <span
            key={option.id}
            style={{
              color: pallete.colors.surface4,
              backgroundColor: pallete.colors.surface4
            }}
            className='text-[12px] font-semibold mr-[8px] px-[8px] py-[4px] rounded-[9999px] inline-flex items-center max-w-[150px] overflow-hidden'
          >
            <span
              className='truncate'
              style={{ color: pallete.colors.textDark4 }}
            >
              {optionsLabelAccessor(option)}
            </span>
            <button
              onClick={(e) => {
                e.stopPropagation()
                handleDelete(option)
              }}
              className='ml-[4px] flex-shrink-0'
              disabled={disabled}
            >
              <IoIosClose
                className='h-[12px] w-[12px]'
                color={pallete.colors.textDark4}
              />
            </button>
          </span>
        ))
      } else if (!multiSelect && !Array.isArray(value) && value) {
        return (
          <span
            style={{
              color: pallete.colors.surface4,
              backgroundColor: pallete.colors.surface4
            }}
            className='text-[12px] font-semibold mr-[8px] px-[8px] py-[4px] rounded-[9999px] inline-flex items-center max-w-[150px] overflow-hidden'
          >
            <span
              className='truncate'
              style={{ color: pallete.colors.textDark4 }}
            >
              {optionsLabelAccessor(value)}
            </span>
            <button
              onClick={(e) => {
                e.stopPropagation()
                handleDelete(value)
              }}
              className='ml-[4px] flex-shrink-0'
              disabled={disabled}
            >
              <IoIosClose
                className='h-[12px] w-[12px]'
                color={pallete.colors.textDark4}
              />
            </button>
          </span>
        )
      }
      return null
    }

    const isDropdownDisabled =
      disabled || (!multiSelect && value && !Array.isArray(value))

    return (
      <div ref={wrapperRef} className='relative w-full'>
        {label && (
          <label className='block text-[14px] font-medium text-gray-700 mb-[4px]'>
            {label}
          </label>
        )}
        <div
          className={`border rounded-[6px] py-[8px] px-[16px] flex flex-wrap items-center ${
            isDropdownDisabled ? '' : 'cursor-pointer'
          } ${validationError ? 'border-red-500' : 'border-gray-300'}`}
          onClick={() => !isDropdownDisabled && inputRef?.current?.focus()}
        >
          {renderSelectedOptions()}
          <input
            ref={inputRef}
            type='text'
            className='flex-grow outline-none bg-transparent'
            placeholder={
              (!multiSelect && !value) ||
              (multiSelect &&
                (!value || (Array.isArray(value) && value.length === 0)))
                ? placeholder
                : ''
            }
            value={searchTerm}
            onChange={handleInputChange}
            disabled={isDropdownDisabled}
          />
          <ChevronDown
            className={`h-[16px] w-[16px] text-gray-400 ${
              openDropdown ? 'transform rotate-180' : ''
            } ${isDropdownDisabled ? 'opacity-50' : ''}`}
          />
        </div>
        {validationError && (
          <p className='mt-[4px] text-[14px] text-red-600'>{validationError}</p>
        )}
        {openDropdown && searchTerm.length >= 3 && !isDropdownDisabled && (
          <ul className='absolute z-[9999] mt-[4px] w-full bg-white shadow-lg max-h-[240px] rounded-[6px] py-[4px] text-[16px] ring-[1px] ring-black ring-opacity-5 overflow-y-auto focus:outline-none sm:text-[14px]'>
            {loadingOption ? (
              <li className='text-gray-900 cursor-default select-none relative py-[8px] px-[12px]'>
                <Spinner />
              </li>
            ) : options.length === 0 ? (
              <li className='text-gray-900 cursor-default select-none relative py-[8px] px-[12px]'>
                No matching record found
              </li>
            ) : (
              options?.map((option) => (
                <li
                  key={option?.id}
                  className='text-gray-900 cursor-default select-none relative py-[8px] px-[12px] hover:bg-gray-100'
                  onClick={() => handleSelect(option)}
                >
                  <div>
                    <span className='font-semibold'>
                      {optionsLabelAccessor(option)}
                    </span>
                  </div>
                  {option.position && (
                    <div className='text-gray-500'>{option.position}</div>
                  )}
                  <div className='text-gray-500'>{option.email} </div>
                </li>
              ))
            )}
          </ul>
        )}
      </div>
    )
  }
)

MultiSelect.displayName = 'MultiSelect'

export default MultiSelect
