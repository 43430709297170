import { makeAutoObservable, observable } from 'mobx'

import { RootStore } from './RootStore'

export interface thingsToDoTypes {
  progress: number
  slack?: boolean
  applicationHealthCheck?: boolean
  jobMonitoring?: boolean
  apiMonitoring?: boolean
  exception?: boolean
  userInvite?: boolean
}

export class ThingsToDoStore {
  progressData: thingsToDoTypes
  showThingsToDo: boolean
  totalProgress: number

  constructor(private rootStore: RootStore) {
    this.showThingsToDo = false
    this.totalProgress = 0
    this.progressData = {
      progress: 0
    }
    makeAutoObservable(this, {
      progressData: observable.struct
    })
  }
  setTotalProgressCount(value: number) {
    this.rootStore.thingsToDoStore.totalProgress = value
  }
  getTotalProgressCount() {
    return this.rootStore.thingsToDoStore.totalProgress
  }
  setShowThingsToDo(value: boolean) {
    this.rootStore.thingsToDoStore.showThingsToDo = value
  }
  getShowThingsToDo() {
    return this.rootStore.thingsToDoStore.showThingsToDo
  }
  setProgressData(data: any) {
    this.rootStore.thingsToDoStore.progressData = data
  }
  getProgressData() {
    return this.rootStore.thingsToDoStore.progressData
  }
  reset() {
    this.showThingsToDo = true
    this.progressData = {
      progress: 0
    }
  }
}
