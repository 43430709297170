import { Add, Edit, TrashCan } from '@carbon/icons-react'
import { AtoB, replaceUrl } from '../../../utils/UrlUtils'
import React, { useEffect, useState } from 'react'
import { cloneDeep, isUndefined } from 'lodash'
import {
  createExceptionEscalationSet,
  deleteAnExceptionEscalationSet,
  updateAnExceptionEscalationSet
} from '../../../api/exception/Exception.service'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '../../../components/New/Button/Button'
import { DDSTypography } from 'den-design-system'
import Input from '../../../components/New/Input/Input'
import LevelComponent from '../../../components/common/DDS/LevelComponent/LevelComponent'
import Modal from '../../../components/common/DDS/Modal/Modal'
import { ProjectType } from '../../../enum/ProjectType.enum'
import Select from '../../../components/New/Select/Select'
import SeverityType from '../../../constants/SeverityPriorityDropdown'
import { SeverityTypes } from '../../../api/exception/Exception.types'
import Spinner from '../../../components/common/Spinner/Spinner'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { severityColorMap } from '../../../constants/ExceptionsPageConstants'
import { timeFormats } from '../../../enum/TIME'
import { timeZone } from '../../../utils/DateTimeUtils'
import { useStoreContext } from '../../../store/StoreContext'

const EscalationSetDetails: React.FC = () => {
  const { exceptionStore, uiStore, userStore, scopeStore } = useStoreContext()
  const store = useStoreContext()
  const { escalationSetId: escSetId } = useParams<{ escalationSetId: string }>()
  const { pathIds } = useParams()
  const [id, setId] = useState<string | undefined>(undefined)
  const data = exceptionStore.getActiveEscalationSet()
  const [escalationName, setEscalationName] = useState<string>('')
  const [deletePopUpOpen, setDeletePopUpOpen] = useState(false)
  const [editEscalation, setEditEscalation] = useState(false)
  const [severity, setSeverity] = useState<string>('')
  const [existingSeverity, setExistingSeverity] = useState<string>('')
  const [escalationLevels, setEscalationLevels] = useState<any>([])
  const [existingName, setExistingName] = useState<string>('')
  const [existingLevels, setExistingLevels] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY
  const maxLevels = 3
  const defaultEscalationLevel = {
    frequencyInterval: 'Day',
    threshold: 10,
    alertChannel: 'Email',
    alertCandidates: [],
    isActive: true
  }
  const handleCreateEscalationSet = () => {
    setLoading(true)
    const payload = {
      escalationSetName: escalationName,
      severity: severity,
      escalationRules: escalationLevels
    }
    createExceptionEscalationSet(payload)
      .then((createdEscalationSet: any) => {
        setId(createdEscalationSet.data.id)
        ToastNotification({
          type: 'success',
          message:
            ToastMessageConstants.EXCEPTION_ESCALATIONS.CREATE_SET.SUCCESS
        })
        navigate(
          replaceUrl(
            routeConstants.ESCALATION_SETS,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          )
        )
      })
      .catch((err: string) => {
        if (err === 'Name already exists') {
          ToastNotification({
            type: 'error',
            message: err
          })
        } else if (escalationName.length == 0) {
          ToastNotification({
            type: 'error',
            message:
              ToastMessageConstants.EXCEPTION_ESCALATIONS.CREATE_SET.ERROR
                .EMPTY_NAME
          })
        } else {
          ToastNotification({
            type: 'error',
            message:
              ToastMessageConstants.EXCEPTION_ESCALATIONS.CREATE_SET.ERROR
                .DEFAULT
          })
        }
      })
      .finally(() => setLoading(false))
  }

  function mapSeverity(severity: string): SeverityTypes {
    switch (severity.toLowerCase()) {
      case 'critical':
        return SeverityTypes.CRITICAL
      case 'moderate':
        return SeverityTypes.MODERATE
      case 'major':
        return SeverityTypes.MAJOR
      case 'minor':
        return SeverityTypes.MINOR
      default:
        return SeverityTypes.CRITICAL
    }
  }
  const fetchEscalationSetData = () => {
    setLoading(true)
    if (id && id !== '$') {
      exceptionStore.getEscalationSetFromAPI(id).then(() => {
        const levels = exceptionStore
          .getActiveEscalationSet()
          .exceptionEscalationRules.map((item: any) => ({
            id: item.id,
            threshold: item.threshold,
            isActive: item.isActive,
            severity: item.severity,
            frequencyInterval: item.frequencyInterval,
            alertChannel: item.alertChannel,
            alertCandidates: item.escalationAlertCandidate.map(
              (item: any) => item.projectMemberId
            )
          }))
        setEscalationLevels(levels)
        setExistingLevels(levels)
        setEscalationName(store.exceptionStore.getCurrentEscalationName())
        setExistingSeverity(
          store.exceptionStore.getActiveEscalationSet().severity
        )
        setSeverity(store.exceptionStore.getActiveEscalationSet().severity)
        setExistingName(store.exceptionStore.getCurrentEscalationName())
      })
    } else if (id === '$') {
      setEscalationLevels([defaultEscalationLevel])
      setEditEscalation(true)
      setSeverity('Critical')
    }
    setLoading(false)
  }
  const handleUpdateEscalationSet = () => {
    if (id != '$') {
      setLoading(true)
      const getPayload = () => {
        if (existingName != escalationName) {
          return {
            severity: mapSeverity(severity),
            escalationSetName: escalationName as string,
            escalationRules: escalationLevels,
            $escalationSetId: id as string
          }
        } else {
          return {
            severity: mapSeverity(severity),
            escalationRules: escalationLevels,
            $escalationSetId: id as string
          }
        }
      }
      updateAnExceptionEscalationSet(getPayload())
        .then(() => {
          ToastNotification({
            type: 'success',
            message:
              ToastMessageConstants.EXCEPTION_ESCALATIONS.UPDATE_SET.SUCCESS
          })
          fetchEscalationSetData()
          setExistingName(escalationName)
          setExistingSeverity(severity)
        })
        .catch(() => {
          ToastNotification({
            type: 'error',
            message:
              ToastMessageConstants.EXCEPTION_ESCALATIONS.UPDATE_SET.ERROR
          })
          setEscalationName(existingName)
          setSeverity(existingSeverity)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }
  useEffect(() => {
    if (id != '$') {
      setSeverity(store.exceptionStore.getActiveEscalationSet().severity)
      setEscalationName(store.exceptionStore.getCurrentEscalationName())
    }
  }, [store.exceptionStore.getCurrentEscalationName()])

  const severityDropdownOptions = SeverityType.map((severity) => ({
    label: severity.label,
    value: severity.value
  }))

  useEffect(() => {
    const decodedPathIds = AtoB(pathIds as string)
    const [organizationId, projectId, environmentId] = decodedPathIds.split('/')
    if (
      !isUndefined(organizationId) &&
      !isUndefined(projectId) &&
      !isUndefined(environmentId)
    ) {
      if (scopeStore.getScope()['organization-id'] !== organizationId) {
        scopeStore.setOrganizationId(organizationId as string)
        scopeStore.setProjectId(projectId as string)
        scopeStore.setEnvironmentId(environmentId as string)
      }
    }
    setId(escSetId)
  }, [escSetId, pathIds, scopeStore])

  useEffect(() => {
    if (
      store.breadcrumbStore.getBreadcrumbsOptions().length === 1 ||
      store.breadcrumbStore.getBreadcrumbsOptions().length === 2
    ) {
      store.breadcrumbStore.appendBreadCrumbOptions({
        label: escalationName,
        link: '/'
      })
    }
    return () => {
      store.breadcrumbStore.sliceBreadcrumbOptions(0, 1)
    }
  }, [existingName])

  useEffect(() => {
    setId(escSetId)
    if (id === '$') {
      setEscalationName('')
    }
  }, [id])

  useEffect(() => {
    setLoading(true)

    fetchEscalationSetData()
    setLoading(false)
  }, [id, scopeStore.getEnvironmentId(), scopeStore.getProjectId()])

  const handleDeleteLevel = (id: number) => {
    const updatedLevels = escalationLevels.filter(
      (_: any, index: number) => index !== id
    )
    setEscalationLevels(updatedLevels)
  }

  const handleAddLevel = () => {
    if (escalationLevels.length < maxLevels) {
      setEscalationLevels([
        ...escalationLevels,
        cloneDeep(defaultEscalationLevel)
      ])
    }
  }
  const handleDeleteEscalationSet = () => {
    uiStore.setGlobalLoader(true)
    if (id != '$') {
      deleteAnExceptionEscalationSet(id as string)
        .then(() => {
          ToastNotification({
            type: 'success',
            message:
              ToastMessageConstants.EXCEPTION_ESCALATIONS.DELETE_SET.SUCCESS
          })
          setDeletePopUpOpen(false)
          navigate(
            replaceUrl(
              routeConstants.ESCALATION_SETS,
              store.scopeStore.getScope()['organization-id'] as string,
              store.scopeStore.getScope()['project-id'] as string,
              store.scopeStore.getScope()['environment-id'] as string
            )
          )
        })
        .catch((err: any) => {
          ToastNotification({
            type: 'error',
            message:
              ToastMessageConstants.EXCEPTION_ESCALATIONS.UPDATE_SET.ERROR
          })
          setDeletePopUpOpen(false)
          setEditEscalation(true)
        })
      uiStore.setGlobalLoader(true)
    }
  }

  const Footer: React.FC = () => {
    return (
      <div className='flex override-btn-min-width justify-end py-[16px] px-[24px] gap-[10px]'>
        <Button
          id='no-button'
          label='No'
          onClick={() => setDeletePopUpOpen(false)}
          type='neutral'
        />
        <Button
          id='yes-button'
          label='Yes'
          onClick={handleDeleteEscalationSet}
          type='dangerFilled'
        />
      </div>
    )
  }

  if (loading) return <Spinner />

  return (
    <>
      <div className='flex flex-col gap-y-[24px] py-[24px]'>
        {id != '$' ? (
          existingName ? (
            <div
              className='flex justify-between pb-[8px] border-b'
              style={{ borderBottomColor: pallete.colors.stroke2 }}
            >
              <div className='flex w-1/2 flex-col gap-[8px]'>
                <div className='p-[4px] override-esc-name'>
                  {editEscalation ? (
                    <Input
                      id='escalation-set-name-input'
                      type='text'
                      autoComplete='on'
                      size='small'
                      width='170px'
                      disabled={store.userStore.getNoActiveSubscription()}
                      placeholder='Escalation Set Name'
                      maxLength={30}
                      validateInput={false}
                      name='Escalation Name'
                      value={escalationName}
                      onChange={(e) => {
                        setEscalationName(e.target.value)
                      }}
                    />
                  ) : (
                    <DDSTypography.Title
                      type='h3'
                      variant='semiBold'
                      color={pallete.colors.textDark3}
                    >
                      {existingName}
                    </DDSTypography.Title>
                  )}
                </div>
                <div className='flex gap-[12px] items-center'>
                  <div className='pl-[4px] flex flex-row items-center gap-[4px] whitespace-nowrap'>
                    <DDSTypography.Paragraph
                      size='caption'
                      variant='medium'
                      className='flex items-center'
                      color={pallete.colors.textDark6}
                    >
                      Created On :
                    </DDSTypography.Paragraph>

                    <DDSTypography.Paragraph
                      size='para'
                      variant='semiBold'
                      color={pallete.colors.textDark3}
                    >
                      {timeZone(
                        userStore.getUserPreference().timeZone,
                        data.createdAt,
                        timeFormats.monthDateYear
                      )}
                    </DDSTypography.Paragraph>
                  </div>
                  <div
                    className='border-r h-[20px]'
                    style={{ borderColor: pallete.colors.stroke2 }}
                  />
                  <div className='flex flex-row gap-x-[4px] items-baseline whitespace-nowrap'>
                    <DDSTypography.Paragraph
                      size='caption'
                      variant='medium'
                      className='flex items-center'
                      color={pallete.colors.textDark6}
                    >
                      No of Escalation Levels :
                    </DDSTypography.Paragraph>

                    <DDSTypography.Paragraph
                      size='para'
                      variant='semiBold'
                      color={pallete.colors.textDark3}
                    >
                      {escalationLevels.length > 0
                        ? escalationLevels.length
                        : '-'}
                    </DDSTypography.Paragraph>
                  </div>
                </div>
              </div>
              <div className='flex flex-col items-start'>
                <div className='flex items-center gap-[8px] right-0'>
                  <div className=''>
                    <DDSTypography.Title
                      type='h5'
                      variant='semiBold'
                      className='whitespace-nowrap'
                      color={pallete.colors.textDark4}
                    >
                      {isFullStackProject
                        ? 'Exception Severity :'
                        : 'Error Severity :'}
                    </DDSTypography.Title>
                  </div>
                  <div
                    className={`severity-min-width ${!editEscalation ? 'pointer-events-none' : ''}`}
                  >
                    {editEscalation ? (
                      <Select
                        id='severity-select'
                        disabled={store.userStore.getNoActiveSubscription()}
                        options={severityDropdownOptions}
                        searchable={false}
                        name='Severity'
                        value={severity}
                        onSelect={(value) => setSeverity(value)}
                      />
                    ) : (
                      <div
                        style={{
                          backgroundColor: severityColorMap[severity],
                          color: pallete.colors.white
                        }}
                        className='py-[4px] px-[12px] w-[135px] flex h-[32px] items-center justify-center rounded-[4px]'
                      >
                        <DDSTypography.Paragraph
                          variant='medium'
                          size='para'
                          color={pallete.colors.surface1}
                        >
                          {existingSeverity ? existingSeverity : '-'}
                        </DDSTypography.Paragraph>
                      </div>
                    )}
                  </div>
                  {editEscalation && (
                    <Button
                      id='cancel'
                      label='Cancel'
                      type='neutral'
                      disabled={store.userStore.getNoActiveSubscription()}
                      size='small'
                      onClick={() => {
                        if (id === '$')
                          navigate(
                            replaceUrl(
                              routeConstants.ESCALATION_SETS,
                              store.scopeStore.getScope()[
                                'organization-id'
                              ] as string,
                              store.scopeStore.getScope()[
                                'project-id'
                              ] as string,
                              store.scopeStore.getScope()[
                                'environment-id'
                              ] as string
                            )
                          )
                        else {
                          setEditEscalation(false)
                          setEscalationLevels(existingLevels)
                          setEscalationName(existingName)
                          setSeverity(existingSeverity)
                        }
                      }}
                    />
                  )}{' '}
                  {!editEscalation && (
                    <Button
                      id='delete'
                      disabled={store.userStore.getNoActiveSubscription()}
                      startIcon={<TrashCan size={16} />}
                      label='Delete'
                      size='small'
                      onClick={() => {
                        setDeletePopUpOpen(true)
                      }}
                      type='danger'
                    />
                  )}
                  {editEscalation ? (
                    <Button
                      id='saveEsc'
                      onClick={() => {
                        if (id != '$') {
                          handleUpdateEscalationSet()
                          setEditEscalation(false)
                        } else {
                          handleCreateEscalationSet()
                        }
                      }}
                      disabled={
                        uiStore.getGlobalLoader() ||
                        store.userStore.getNoActiveSubscription()
                      }
                      label='Save Escalations'
                      size='small'
                      type='filled'
                    />
                  ) : (
                    <Button
                      id='editEsc'
                      onClick={() => {
                        setEditEscalation(true)
                      }}
                      size='small'
                      startIcon={<Edit size={16} />}
                      label='Edit Escalations'
                      disabled={store.userStore.getNoActiveSubscription()}
                      type='filled'
                    />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <Spinner />
          )
        ) : (
          <div
            className='flex justify-between pb-[8px] border-b'
            style={{ borderBottomColor: pallete.colors.stroke2 }}
          >
            <div className='flex w-1/2 flex-col gap-[8px]'>
              <div className='p-[4px] override-esc-name'>
                {editEscalation ? (
                  <Input
                    id='escalation-set-name-input'
                    type='text'
                    size='small'
                    width='170px'
                    maxLength={30}
                    disabled={store.userStore.getNoActiveSubscription()}
                    autoComplete={'on'}
                    placeholder='Escalation Set Name'
                    validateInput={false}
                    name='Escalation Name'
                    value={escalationName}
                    onChange={(e) => {
                      setEscalationName(e.target.value)
                    }}
                  />
                ) : (
                  <DDSTypography.Title
                    type='h3'
                    variant='semiBold'
                    color={pallete.colors.textDark3}
                  >
                    {existingName}
                  </DDSTypography.Title>
                )}
              </div>
              <div className='flex gap-[12px] items-center'>
                <div className='pl-[4px] flex flex-row items-center gap-[4px] whitespace-nowrap'>
                  <DDSTypography.Paragraph
                    size='caption'
                    variant='medium'
                    className='flex items-center'
                    color={pallete.colors.textDark6}
                  >
                    Created On :
                  </DDSTypography.Paragraph>

                  <DDSTypography.Paragraph
                    size='para'
                    variant='semiBold'
                    color={pallete.colors.textDark3}
                  >
                    {timeZone(
                      userStore.getUserPreference().timeZone,
                      data.createdAt,
                      timeFormats.monthDateYear
                    )}
                  </DDSTypography.Paragraph>
                </div>
                <div
                  className='border-r h-[20px]'
                  style={{ borderColor: pallete.colors.stroke2 }}
                />
                <div className='flex flex-row gap-x-[4px] items-baseline whitespace-nowrap'>
                  <DDSTypography.Paragraph
                    size='caption'
                    variant='medium'
                    className='flex items-center'
                    color={pallete.colors.textDark6}
                  >
                    No of Escalation Levels :
                  </DDSTypography.Paragraph>

                  <DDSTypography.Paragraph
                    size='para'
                    variant='semiBold'
                    color={pallete.colors.textDark3}
                  >
                    {escalationLevels.length > 0
                      ? escalationLevels.length
                      : '-'}
                  </DDSTypography.Paragraph>
                </div>
              </div>
            </div>
            <div className='flex flex-col items-start'>
              <div className='flex items-center gap-[8px] right-0'>
                <div className=''>
                  <DDSTypography.Title
                    type='h5'
                    variant='semiBold'
                    className='whitespace-nowrap'
                    color={pallete.colors.textDark4}
                  >
                    {isFullStackProject
                      ? 'Exception Severity'
                      : 'Error Severity'}
                    :
                  </DDSTypography.Title>
                </div>
                <div
                  className={`severity-min-width ${!editEscalation ? 'pointer-events-none' : ''}`}
                >
                  {editEscalation ? (
                    <Select
                      id='severity-select'
                      options={severityDropdownOptions}
                      disabled={store.userStore.getNoActiveSubscription()}
                      searchable={false}
                      name='Severity'
                      value={severity}
                      onSelect={(value) => setSeverity(value)}
                    />
                  ) : (
                    <div
                      style={{
                        backgroundColor: severityColorMap[severity],
                        color: pallete.colors.white
                      }}
                      className='py-[4px] px-[12px] w-[135px] flex h-[32px] items-center justify-center rounded-[4px]'
                    >
                      {severity ? severity : '-'}
                    </div>
                  )}
                </div>
                {editEscalation && (
                  <Button
                    id='cancel'
                    label='Cancel'
                    type='neutral'
                    size='small'
                    disabled={
                      loading || store.userStore.getNoActiveSubscription()
                    }
                    onClick={() => {
                      if (id === '$')
                        navigate(
                          replaceUrl(
                            routeConstants.ESCALATION_SETS,
                            store.scopeStore.getScope()[
                              'organization-id'
                            ] as string,
                            store.scopeStore.getScope()['project-id'] as string,
                            store.scopeStore.getScope()[
                              'environment-id'
                            ] as string
                          )
                        )
                      else {
                        setEditEscalation(false)
                        setEscalationLevels(existingLevels)
                        setEscalationName(existingName)
                        setExistingSeverity(severity)
                      }
                    }}
                  />
                )}{' '}
                {!editEscalation && (
                  <Button
                    id='delete'
                    startIcon={<TrashCan size={16} />}
                    label='Delete'
                    size='small'
                    disabled={
                      loading || store.userStore.getNoActiveSubscription()
                    }
                    onClick={() => {
                      setDeletePopUpOpen(true)
                    }}
                    type='danger'
                  />
                )}
                {editEscalation ? (
                  <Button
                    id='saveEsc'
                    onClick={() => {
                      if (id != '$') {
                        handleUpdateEscalationSet()
                        setEditEscalation(false)
                      } else {
                        handleCreateEscalationSet()
                      }
                    }}
                    disabled={
                      loading || store.userStore.getNoActiveSubscription()
                    }
                    label='Save Escalations'
                    size='small'
                    type='filled'
                  />
                ) : (
                  <Button
                    id='editEsc'
                    onClick={() => {
                      setEditEscalation(true)
                    }}
                    size='small'
                    disabled={
                      loading || store.userStore.getNoActiveSubscription()
                    }
                    startIcon={<Edit size={16} />}
                    label='Edit Escalations'
                    type='filled'
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <div className='flex flex-col gap-[24px]'>
          {escalationLevels.map((item: any, index: any) => (
            <LevelComponent
              isNew={true}
              onDelete={() => handleDeleteLevel(index)}
              data={item}
              escalationName={escalationName}
              setEscalationLevels={setEscalationLevels}
              id={index}
              setId={id}
              editable={editEscalation}
              key={index}
              levelCount={escalationLevels.length}
            />
          ))}
          {editEscalation && escalationLevels.length < maxLevels && (
            <div className='flex flex-row-reverse override-false'>
              <Button
                id='addLevel'
                onClick={handleAddLevel}
                startIcon={<Add size={16} />}
                label='Add Another Level'
                disabled={store.userStore.getNoActiveSubscription()}
                type='filled'
                size='small'
              />
            </div>
          )}
        </div>
        <Modal
          open={deletePopUpOpen}
          setOpen={() => {
            setDeletePopUpOpen(true)
          }}
          onClosePress={() => {
            setDeletePopUpOpen(false)
          }}
          primaryButtonText='Yes'
          secondaryButtonText='No'
          className=''
          showHeader
          footer={<Footer />}
          title='Delete Escalation Set'
        >
          <div className='flex flex-col'>
            <div className='flex flex-col p-[24px] overflow-y-scroll'>
              <div className='flex flex-row w-full items-center justify-center gap-x-[4px]'>
                <div>
                  <DDSTypography.Title
                    variant='semiBold'
                    type='h4'
                    className='text-center'
                  >
                    Are you sure you want to delete
                  </DDSTypography.Title>
                </div>
                <div>
                  <DDSTypography.Title
                    variant='bold'
                    type='h4'
                    className='text-center'
                  >
                    {escalationName}?
                  </DDSTypography.Title>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default observer(EscalationSetDetails)
