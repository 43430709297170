const palette = {
  padding: {
    default: '16px 0px 0px 0px',
    size: '16px',
    halfSize: '8px'
  },
  colors: {
    borderColor: '#E0EDFF',
    borderColor2: '#E5EFFE',
    textDark: '#222222',
    textDark2: '#595959',
    textDark3: '#757575',
    textDark4: '#A3A8BF',
    textDark5: '#586679',
    textLight: '#FDFFFF',
    textLight2: '#A3A8BF',
    textLight3: '#828699',
    background: '#EFF3Fa',
    backgroundColor: '#F2F8FF',
    backgroundLight: '#F0F6FF',
    backgroundFilledInput: '#E8F0FF',
    primary: '#066CFF',
    primary2: '#05A0FA',
    primary3: '#C0E7FE',
    primary4: '#A2C8FF',
    primary5: '#e6e6e6',
    primary6: '#91d5ff',
    primary7: '#e6f7ff',
    primary8: '#D5E1F2',
    primary9: '#C1D2E9',
    secondary: '#82D0FD',
    white: '#ffffff',
    lightBack: '#FAFCFF',
    lightBack2: '#F7FAFF',
    lightBack3: '#F2F7FF',
    lightBack4: '#FDFFFF',
    lightBack5: '#9AD9FD',
    lightBack6: '#9AD9FD50',
    success: '#AEF456',
    success2: '#79CF0D',
    success3: '#008000',
    success4: '#f6ffed',
    success5: '#a0d911',
    error: '#E92828',
    error2: '#fff1f0',
    error3: '#ffa39e',
    warning: '#FF5F00',
    warning2: '#F79E1B',
    checkBoxColor: '#066CFF',
    userLabelColor: '#FBFBFB',
    borderRed: '#E92828BF',
    green: '#008000',
    red: '#FF0000',
    red2: '#E92828',
    black: '#000000',
    blue: '#0000FF',
    gradientWhite: 'linear-gradient(170.55deg, #FAFCFF 3.85%, #EAF0F9 92.67%)',
    gradientBlack: 'linear-gradient(180deg, #222222 0%, #031D42 116.48%)',
    gradientBlue: 'linear-gradient(270deg, #066CFF 0%, #05A0FA 100%)',
    gradientGreen: 'linear-gradient(#79CF0D 100%, #AEF456 100%)',
    gradientBlackText: 'linear-gradient(270deg, #001B41 50.66%, #757575 100%)',
    signUpBackgroundGradient:
      'linear-gradient(180deg, #011129 0%, #002F74 100%)',
    signUpLightBackground: '#F9FCFF',
    dateRangeBackground: '#9AD9FD',
    buttonDark: '#252D41',
    lightGrey: '#E9E9EA',
    darkGrey: '#595959',
    overlayBackground: '#FDFFFF80',
    lightGrey2: '#828699',
    lightGrey3: '#d9d9d9',
    lightRed: '#E92828BF',
    wineRed: '#820014',
    orange: '#FFA500',
    orange2: '#fff7e6',
    orange3: '#ffd591',
    sidebarOverlay: 'rgba(0, 0, 0, 0.5)',
    progressColor: '#55B71D',
    primarySvgFilter:
      'brightness(0) saturate(100%) invert(35%) sepia(100%) saturate(4624%) hue-rotate(207deg) brightness(100%) contrast(108%)',
    whiteSvgFilter:
      'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7485%) hue-rotate(110deg) brightness(110%) contrast(100%)',
    disabledInputBackground: 'rgba(0, 0, 0, 0.09)',
    redSvgFilter:
      'brightness(0) saturate(100%) invert(29%) sepia(41%) saturate(5065%) hue-rotate(346deg) brightness(93%) contrast(96%)',
    errorSvgFilter:
      'brightness(0) saturate(100%) invert(29%) sepia(38%) saturate(5473%) hue-rotate(347deg) brightness(93%) contrast(95%)',
    lightBack5SvgFilter:
      'invert(78%) sepia(7%) saturate(2291%) hue-rotate(173deg) brightness(104%) contrast(98%);',
    lightBlueSvgFilter:
      'invert(85%) sepia(13%) saturate(6382%) hue-rotate(182deg) brightness(98%) contrast(99%)',
    blueSvgFilter:
      'brightness(0) saturate(100%) invert(28%) sepia(77%) saturate(2061%) hue-rotate(212deg) brightness(92%) contrast(94%)',
    tooltipShadow: '#C7CACE',
    percentGreen: '#4ECB71',
    doughnutAPIStatus: ['#066CFF', '#05A0FA', '#FF5F00', '#E92828'],
    doughnutExceptionManagement: ['#066CFF', '#E0EDFF'],
    doughnutStatisticsExceptionMessage: ['#E92828', '#FFD371', '#066CFF'],
    doughnutExceptionStatus: ['#E92828', '#00B828', '#066CFF', '#FFD371'],
    doughnutExceptionSeverity: ['#E92828', '#FF9071', '#066CFF', '#00B828'],
    distributions: [
      '#4ECB71',
      '#FF5F00',
      '#05A0FA',
      '#066CFF',
      '#891D1D',
      '#E92828'
    ]
  },
  fontWeight: {
    bold: 700,
    semiBold: 600,
    medium: 500,
    light: 400,
    lighter: 300
  },
  font: {
    variant: {
      h1: {
        fontSize: '24px',
        lineHeight: '30px'
      },
      h2: {
        fontSize: '22px',
        lineHeight: '28px'
      },
      h3: {
        fontSize: '20px',
        lineHeight: '26px'
      },
      h4: {
        fontSize: '18px',
        lineHeight: '24px'
      },
      h5: {
        fontSize: '16px',
        lineHeight: '20px'
      },
      para: {
        fontSize: '14px',
        lineHeight: '20px'
      },
      caption: {
        fontSize: '12px',
        lineHeight: '16px'
      },
      heading: {
        fontSize: '32px',
        lineHeight: '38px'
      },
      extraLarge: {
        fontSize: '40px',
        lineHeight: '46px'
      }
    },
    fontWeight: {
      bold: 700,
      semiBold: 600,
      medium: 500,
      regular: 400
    }
  }
}

export default palette
