import { DDSMenu, DDSTypography } from 'den-design-system'
import React, { useEffect, useRef, useState } from 'react'
import { isNull, isUndefined } from 'lodash'

import Arrow from '../../../assets/images/arrow.png'
import { MenuProps } from 'den-design-system/components/Menu/Menu.type'
import pallete from '../../../global/newPallete'

interface StatusCodeGraphProps {
  totalCount: number
  data: {
    [key: string]: {
      count: number
      totalCount: number
      value: number
      code: number
    }
  }
  width: string
  color: {
    [key: string]: string
  }
  tableHeight?: any
}

type extendedMenuProps = StatusCodeGraphProps & {
  arrowPosition?: 'left' | 'right' | 'center'
}

const StatusCodeGraph: React.FC<extendedMenuProps> = ({
  data,
  totalCount,
  color,
  width,
  arrowPosition = 'center',
  tableHeight
}) => {
  const [selectedColorData, setSelectedColorData] = useState<any>(null)
  const [selectedColor, setSelectedColor] = React.useState<any>('')
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [menuPosition, setMenuPosition] = useState<'top' | 'bottom'>('bottom')
  const buttonRef = useRef<HTMLDivElement>(null)

  const getTotalCount = () => {
    const totalCountArray = Object.entries(data).map(([key, value], index) => {
      return value.totalCount
    })
    let totalCount = 0
    totalCountArray.forEach((element) => {
      totalCount += element
    })
    return totalCount
  }
  const getPercentage = (value: number) => {
    const totalCountArray = Object.entries(data).map(([key, value], index) => {
      return value.totalCount
    })
    let totalCount = 0
    totalCountArray.forEach((element) => {
      totalCount += element
    })
    return (value / totalCount) * 100
  }

  const handleColorClick = (key: string, value: any) => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect()
      const spaceBelow = window.innerHeight - rect.bottom
      const spaceAbove = rect.top

      if (spaceBelow < tableHeight - 80 && spaceAbove > tableHeight - 80) {
        setMenuPosition('top')
      } else {
        setMenuPosition('bottom')
      }
    }
    setSelectedColor(color[key])

    const { totalCount, ...rest } = value
    setSelectedColorData({ series: key, value: rest })
  }

  const getBorderRadiusIndices = () => {
    const nonZeroIndices = Object.entries(data)
      .map(([key, value], index) => (value.totalCount !== 0 ? index : -1))
      .filter((index) => index !== -1)

    if (nonZeroIndices.length === 0) {
      return [-1, -1]
    }

    return [nonZeroIndices[0], nonZeroIndices[nonZeroIndices.length - 1]]
  }
  getBorderRadiusIndices()

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      const statusCodeGraph = document.getElementById('status-code-graph')
      if (statusCodeGraph && !statusCodeGraph.contains(target)) {
        setSelectedColorData(null)
        setSelectedColor(null)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const borderRadiusIndices = getBorderRadiusIndices()

  const selectedTotalCount = (obj: any) => {
    let val = 0
    Object.entries(obj.value).forEach(
      ([key, value]: any, index) => (val = parseInt(value) + val)
    )
    return val
  }
  const getArrowPosition = () => {
    return arrowPosition === 'right'
      ? 'translate-x-[5%]'
      : arrowPosition === 'left'
        ? 'translate-x-[5%]'
        : 'justify-center'
  }

  return (
    <div
      id='status-code-graph'
      style={{ width: width }}
      className='flex h-[26px] rounded-md  items-center'
      ref={buttonRef}
    >
      {Object.entries(data).map(([key, value], index) => (
        <div
          key={index}
          style={{
            height: '26px',
            width: `${Math.ceil(getPercentage(value.totalCount))}%`,
            cursor: 'pointer'
          }}
          id={`color-div`}
          onMouseEnter={() => handleColorClick(key, value)}
          onMouseLeave={() => {
            setSelectedColorData(null)
            setSelectedColor(null)
          }}
        >
          <div
            className={`${borderRadiusIndices[0] === index && borderRadiusIndices[1] !== index ? 'rounded-l-[4px]' : borderRadiusIndices[1] === index && borderRadiusIndices[0] !== index ? 'rounded-r-[4px]' : borderRadiusIndices[0] === index && borderRadiusIndices[1] === index ? 'rounded-[4px]' : ''} h-full flex items-center justify-between`}
            style={{
              backgroundColor: color[key],
              color: color[key]
            }}
          ></div>
          {selectedColorData && selectedColorData.series === key && (
            <div className=' absolute z-50 override-status-code'>
              {menuPosition === 'bottom' && (
                <div className={`flex w-full ${getArrowPosition()}`}>
                  <img
                    src={Arrow}
                    className='object-cover relative top-[-5px]'
                  />
                </div>
              )}
              <DDSMenu
                open={selectedColor}
                onClose={() => setSelectedColor(false)}
                id='overflow-status'
                parentId={`color-div`}
                className={`absolute ${menuPosition === 'top' ? 'bottom-[30px]' : 'top-[7px]'}  shadow-selectMenu max-h-[160px]`}
                style={{
                  minWidth: '140px',
                  width: '140px',
                  maxHeight: '240px',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  borderRadius: '10px',
                  left: '-55px',
                  border: pallete.colors.surface6
                }}
              >
                <div className='mb-[12px] flex flex-col gap-y-[8px]'>
                  <div className='font-bold flex justify-center text-center items-center'>
                    <DDSTypography.Paragraph
                      size='para'
                      variant='bold'
                      color={pallete.colors.textDark4}
                    >
                      {selectedColorData.series.slice(0, 3) +
                        ' ' +
                        selectedColorData.series.slice(3)}
                    </DDSTypography.Paragraph>
                  </div>
                  <div className='flex justify-between items-center flex-col w-full gap-y-[8px]'>
                    <div className='w-full flex flex-row items-center justify-between'>
                      <DDSTypography.Paragraph
                        size='para'
                        variant='medium'
                        color={pallete.colors.textDark4}
                      >
                        Total
                      </DDSTypography.Paragraph>
                      <div>
                        <DDSTypography.Paragraph
                          key={index}
                          size='para'
                          variant='medium'
                          color={pallete.colors.textDark4}
                        >
                          {selectedTotalCount(selectedColorData)}
                        </DDSTypography.Paragraph>
                      </div>
                    </div>
                    <div className='flex flex-col items-center w-full gap-y-[8px]'>
                      {Object.entries(selectedColorData.value)
                        .slice(0)
                        .map(([key, value]: any, index) => (
                          <div
                            key={index}
                            className='flex flex-row items-center justify-between w-full'
                          >
                            <div className='flex flex-row items-center'>
                              <div
                                key={index}
                                className='rounded-full w-[4px] h-[4px] mr-[4px]'
                                style={{ backgroundColor: selectedColor }}
                              ></div>
                              <DDSTypography.Paragraph
                                size='para'
                                variant='medium'
                                color={pallete.colors.textDark4}
                              >
                                {key}
                              </DDSTypography.Paragraph>
                            </div>
                            <div>
                              <DDSTypography.Paragraph
                                size='para'
                                variant='medium'
                                color={pallete.colors.textDark4}
                              >
                                {value}
                              </DDSTypography.Paragraph>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                {menuPosition === 'top' && (
                  <div
                    className={`flex absolute w-full ${getArrowPosition()} bottom-[-10px] rotate-180`}
                  >
                    <img src={Arrow} className='object-cover relative' />
                  </div>
                )}
              </DDSMenu>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default StatusCodeGraph
