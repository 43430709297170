export enum FilterTypes {
  JOB = 'JOB',
  JOB_LOG = 'JOB_LOG',
  API_MONITORING = 'API_MONITORING',
  PROJECT = 'PROJECT',
  ENVIRONMENT = 'ENVIRONMENT',
  EXISTING_MEMBERS = 'EXISTING_MEMBERS',
  ERROR_MONITORING = 'ERROR_MONITORING',
  INVITED_MEMBERS = 'INVITED_MEMBERS',
  INCIDENT_MANAGEMENT = 'INCIDENT_MANAGEMENT',
  AHC = 'AHC',
  AHC_LOGS = 'AHC_LOGS',
  NOTIFICATIONS = 'NOTIFICATIONS'
}
