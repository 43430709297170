import { DDSButton, DDSModal, DDSTypography } from 'den-design-system'
import React, { SetStateAction } from 'react'

import { Button } from '../../../../components/New/Button/Button'
import pallete from '../../../../global/newPallete'

interface ModalFooterProps {
  primaryButtonText?: string
  secondaryButtonText?: string
  onYes?: () => any
  onNo?: () => any
  primaryButtonDisabled?: boolean
  secondaryButtonDisabled?: boolean
  loading?: boolean
}
const ModalFooter: React.FC<ModalFooterProps> = ({
  onYes,
  onNo,
  primaryButtonText = '',
  secondaryButtonText = '',
  primaryButtonDisabled,
  secondaryButtonDisabled,
  loading
}) => {
  return (
    <div className='flex flex-row gap-x-[8px] px-[24px] py-[16px] override-btn-min-width justify-end'>
      {secondaryButtonText !== '' && (
        <Button
          disabled={loading}
          id='secondary-btn'
          label={
            <DDSTypography.Paragraph
              variant='semiBold'
              size='para'
              className='text-center whitespace-nowrap flex justify-center !min-w-0'
              color={pallete.colors.neuButtonText}
            >
              {secondaryButtonText}
            </DDSTypography.Paragraph>
          }
          type='neutral'
          size='large'
          onClick={() => (onNo ? onNo() : undefined)}
        />
      )}
      {primaryButtonText !== '' && (
        <Button
          id='primary-btn'
          disabled={loading || primaryButtonDisabled}
          loading={loading}
          type='filled'
          size='large'
          label={
            <DDSTypography.Paragraph
              variant='semiBold'
              size='para'
              className='text-center whitespace-nowrap flex justify-center !min-w-0'
              color={pallete.colors.white}
            >
              {primaryButtonText}
            </DDSTypography.Paragraph>
          }
          onClick={() => (onYes ? onYes() : undefined)}
        />
      )}
    </div>
  )
}

interface PopupProps {
  modalOpen: boolean
  setModalOpen: React.Dispatch<SetStateAction<boolean>>
  title?: string
  content?: React.ReactNode
  primaryButtonClick?: any
  secondaryButtonClick?: any
  primaryButtonText?: string
  secondaryButtonText?: string
  primaryButtonDisabled?: boolean
  secondaryButtonDisabled?: boolean
  showFooter?: boolean
  loading?: boolean
}

const PriorityChangePopup: React.FC<PopupProps> = ({
  modalOpen,
  setModalOpen,
  title,
  content,
  primaryButtonClick,
  secondaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  primaryButtonDisabled,
  secondaryButtonDisabled,
  showFooter = true,
  loading
}) => {
  return (
    <DDSModal
      open={modalOpen}
      setOpen={setModalOpen}
      onIconClose={() => setModalOpen(false)}
      primaryButtonText='Yes'
      defaultFooter={false}
      secondaryButtonText='No'
      className='border-b border-black'
      hideCloseIcon={loading}
    >
      <div className='flex flex-col'>
        <div
          className='flex items-start flex-row py-[16px] px-[24px]  border-b'
          style={{ borderBottomColor: pallete.colors.stroke2 }}
        >
          <DDSTypography.Title
            variant='bold'
            type='h4'
            color={pallete.colors.textDark4}
            className='text-center flex items-start'
          >
            {title}
          </DDSTypography.Title>
        </div>

        <div
          className='flex flex-col px-[24px] py-[24px] overflow-y-scroll border-b'
          style={{ borderBottomColor: pallete.colors.stroke2 }}
        >
          {content}
        </div>

        {showFooter && (
          <ModalFooter
            onYes={primaryButtonClick}
            onNo={secondaryButtonClick}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            primaryButtonDisabled={primaryButtonDisabled}
            secondaryButtonDisabled={secondaryButtonDisabled}
            loading={loading}
          />
        )}
      </div>
    </DDSModal>
  )
}

export default PriorityChangePopup
