import { DDSButton, DDSChips, DDSModal, DDSTypography } from 'den-design-system'
import React, { SetStateAction } from 'react'

import { Button } from '../../New/Button/Button'
import LinearProgressBar from '../ProgressBar/ProgressBar'
import pallete from '../../../global/newPallete'
import { severityColorMap } from '../../../constants/ExceptionsPageConstants'
import { useStoreContext } from '../../../store/StoreContext'

interface ModalFooterProps {
  primaryButtonText?: string
  secondaryButtonText?: string
  onYes?: () => any
  onNo?: () => any
  primaryButtonDisabled?: boolean
  secondaryButtonDisabled?: boolean
  loading?: boolean
}
const ModalFooter: React.FC<ModalFooterProps> = ({
  onYes,
  onNo,
  primaryButtonText = '',
  secondaryButtonText = '',
  loading
}) => {
  const store = useStoreContext()
  return (
    <div className='flex flex-row gap-x-[8px] px-[24px] py-[16px] override-width justify-end'>
      {secondaryButtonText !== '' && (
        <Button
          id='cancel-button'
          label={secondaryButtonText}
          disabled={loading}
          type='neutral'
          onClick={() => (onNo ? onNo() : undefined)}
        />
      )}
      {primaryButtonText !== '' && (
        <Button
          id='copy-btn'
          type='filled'
          label={primaryButtonText}
          disabled={loading || store.userStore.getNoActiveSubscription()}
          onClick={() => (onYes ? onYes() : undefined)}
        />
      )}
    </div>
  )
}
interface PopupProps {
  modalOpen: boolean
  setModalOpen: React.Dispatch<SetStateAction<boolean>>
  title?: string
  content?: React.ReactNode
  primaryButtonClick?: any
  secondaryButtonClick?: any
  primaryButtonText?: string
  secondaryButtonText?: string
  primaryButtonDisabled?: boolean
  secondaryButtonDisabled?: boolean
  showFooter?: boolean
  loading?: boolean
}
const ConfirmationPopup: React.FC<PopupProps> = ({
  modalOpen,
  setModalOpen,
  title,
  content,
  primaryButtonClick,
  secondaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  primaryButtonDisabled,
  secondaryButtonDisabled,
  showFooter = true,
  loading
}) => {
  return (
    <DDSModal
      open={modalOpen}
      setOpen={setModalOpen}
      onIconClose={() => setModalOpen(false)}
      primaryButtonText='Yes'
      defaultFooter={false}
      secondaryButtonText='No'
      className='border-b border-black'
      hideCloseIcon={loading}
    >
      <div className='flex flex-col'>
        <div
          className='flex items-start flex-row py-[16px] px-[24px]  border-b'
          style={{ borderBottomColor: pallete.colors.stroke2 }}
        >
          <DDSTypography.Title
            variant='bold'
            type='h4'
            color={pallete.colors.textDark4}
            className='text-center flex items-start'
          >
            {title}
          </DDSTypography.Title>
        </div>
        {loading && <LinearProgressBar />}
        <div
          className='flex flex-col px-[24px] py-[32px] overflow-y-scroll border-b'
          style={{ borderBottomColor: pallete.colors.stroke2 }}
        >
          {content}
        </div>

        {showFooter && (
          <ModalFooter
            onYes={primaryButtonClick}
            onNo={secondaryButtonClick}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            primaryButtonDisabled={primaryButtonDisabled}
            secondaryButtonDisabled={secondaryButtonDisabled}
            loading={loading}
          />
        )}
      </div>
    </DDSModal>
  )
}
export default ConfirmationPopup
