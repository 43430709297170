import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import { Buffer } from 'buffer'
import HttpConstants from '../../constants/HttpConstants'
import PageLoadingOverlay from '../../components/common/PageLoadingOverlay/PageLoadingOverlay'
import SecureComponent from '../../components/Layout/SecureComponent'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../../components/common/DDS/Toast/Toast'
import { UrlWithOnlyOrgId } from '../../utils/UrlUtils'
import { addQueryParamsToUrl } from '../../utils/UrlGenerator'
import { connectSlackToProject } from '../../api/project/Project.service'
import routeConstants from '../../constants/RouteConstants'
import { useStoreContext } from '../../store/StoreContext'

const SlackOauthRedirect = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const storeContext = useStoreContext()

  useEffect(() => {
    const code = searchParams.get('code')
    const state = searchParams.get('state')
    if (state && code) {
      const decodedState = JSON.parse(
        Buffer.from(state as string, 'base64').toString('ascii')
      )
      storeContext.scopeStore.setOrganizationId(decodedState.oid)
      storeContext.scopeStore.setProjectId(decodedState.pid)
      connectSlackToProject(code as string, state as string)
        .then(() => {
          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.PROJECT.SLACK_CONNECTION.SUCCESS
          })
        })
        .catch((err) => {
          console.log(err)
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.SOMETHING_WENT_WRONG
          })
        })
        .finally(() => {
          navigate(
            addQueryParamsToUrl(
              routeConstants.REDIRECT,
              {
                redirect: UrlWithOnlyOrgId(
                  routeConstants.PROJECT_SETTINGS_GENERAL,
                  storeContext
                ).replace(
                  ':projectId',
                  storeContext.scopeStore.getScope()['project-id'] as string
                )
              },
              HttpConstants.GET_METHOD
            )
          )
        })
    } else {
      navigate(
        addQueryParamsToUrl(
          routeConstants.REDIRECT,
          {
            redirect: UrlWithOnlyOrgId(
              routeConstants.PROJECT_SETTINGS_GENERAL,
              storeContext
            ).replace(
              ':projectId',
              storeContext.scopeStore.getScope()['project-id'] as string
            )
          },
          HttpConstants.GET_METHOD
        )
      )
    }
  }, [])

  return <PageLoadingOverlay loadingTitle='Connecting Vigil to your Slack...' />
}

export default SecureComponent(SlackOauthRedirect, AccessRoleConstants.ALL)
