import { DDSTypography } from 'den-design-system'
import React from 'react'
import pallete from '../../../global/newPallete'

interface GraphDisplayCardProps {
  heading: string
  content: React.ReactNode
}
const GraphDisplayCard: React.FC<GraphDisplayCardProps> = ({
  heading,
  content
}) => {
  return (
    <div className='h-full flex flex-col  px-[20px] py-[16px] border border-gray-300 rounded-md overflow-hidden'>
      <DDSTypography.Paragraph
        size='para'
        variant='regular'
        color={pallete.colors.textDark5}
      >
        {heading}
      </DDSTypography.Paragraph>
      <div className='h-full flex justify-center flex-col'>{content}</div>
    </div>
  )
}
export default GraphDisplayCard
