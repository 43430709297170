import { DDSRadio, DDSTypography } from 'den-design-system'
import React, { useCallback, useEffect, useState } from 'react'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../../../../constants/GAConstants'
import { isEmpty, isUndefined } from 'lodash'

import { Button } from '../../../../../../components/New/Button/Button'
import MultiSelect from '../../../../../../components/common/MultiSelectTailwind/MultiSelect'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../../../global/newPallete'
import { useAnalyticsEventTracker } from '../../../../../../utils/GAUtils'

const SelectMemberStep: React.FC<{
  handleNext: () => void
  setData: (data: any) => void
  selectedUser: any
  handleSearch: (data: any) => void
  userOptions: any[]
  setSelectedUser: (data: any) => void
  memberSelectInternalStep: number
  openMultiSelect: boolean
  setOpenMultiSelect: (data: any) => void
  loadOptions: boolean
  setClose: any
}> = ({
  handleNext,
  setData,
  selectedUser,
  userOptions,
  handleSearch,
  setSelectedUser,
  memberSelectInternalStep,
  openMultiSelect,
  setOpenMultiSelect,
  loadOptions,
  setClose
}) => {
  const [internalStep, setInternalStep] = useState<number>(
    memberSelectInternalStep
  )

  const [error, setError] = useState<boolean>(false)
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.TRANSFER_OWNERSHIP
  )
  const [selectedValue, setSelectedValue] = useState<string>('')

  const handleUserChange = useCallback(
    (user: any) => {
      setSelectedUser(user)
      setData(user)
      setError(false)
      setOpenMultiSelect(false)
    },
    [setSelectedUser, setData, setOpenMultiSelect]
  )

  const handleKeepMember = useCallback(() => {
    setSelectedValue('keepMember')
    setData({ retainMembership: true })
  }, [setData])

  const handleRemoveMember = useCallback(() => {
    setSelectedValue('removeMember')
    setData({ retainMembership: false })
  }, [setData])

  useEffect(() => {
    setIsFormValid(!isEmpty(selectedUser) && selectedValue !== '')
  }, [selectedUser, selectedValue])

  const handleReset = useCallback(() => {
    setSelectedUser(null)
    setSelectedValue('')
    setData({})
    setError(false)
  }, [setSelectedUser, setData])

  return (
    <div>
      {internalStep === 0 && (
        <>
          <div className='pb-[24px]'>
            <DDSTypography.Title
              type='h3'
              variant='medium'
              className='pb-[24px]'
              color={pallete.colors.textDark3}
            >
              Select new owner for your{' '}
              <span style={{ color: pallete.colors.textDark3 }}>
                Organization
              </span>
            </DDSTypography.Title>
            <MultiSelect
              options={userOptions}
              multiSelect={false}
              value={selectedUser}
              optionsLabelAccessor={(user: any) => user.name}
              onChange={handleUserChange}
              label='Transfer ownership to'
              placeholder="Enter member's name/email from your organization"
              onTextChange={handleSearch}
              openDropdown={openMultiSelect}
              setOpenDropdown={setOpenMultiSelect}
              loadingOption={loadOptions}
              validationError={error && 'Select a member to transfer ownership'}
            />
          </div>
          <div>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='pb-[12px]'
              color={pallete.colors.textDark3}
            >
              Choose Ownership Type
            </DDSTypography.Paragraph>
            <div className='flex justify-between items-center space-x-4'>
              <div
                className='flex items-center py-[6px] px-[12px] justify-between cursor-pointer w-full'
                style={{
                  border: '1px solid',
                  borderColor: pallete.colors.stroke5,
                  borderRadius: '6px'
                }}
                onClick={handleKeepMember}
              >
                <DDSRadio
                  label={
                    <DDSTypography.Paragraph
                      size='para'
                      variant='medium'
                      className='pl-[8px]'
                      color={pallete.colors.textDark3}
                    >
                      Keep me as a member
                    </DDSTypography.Paragraph>
                  }
                  value='keepMember'
                  name='membership'
                  id='radio-keepMember'
                  checked={selectedValue === 'keepMember'}
                  onChange={handleKeepMember}
                />
              </div>

              <div
                className='flex items-center py-[6px] px-[12px] justify-between cursor-pointer w-full'
                onClick={handleRemoveMember}
                style={{
                  border: '1px solid',
                  borderColor: pallete.colors.stroke5,
                  borderRadius: '6px'
                }}
              >
                <DDSRadio
                  label={
                    <DDSTypography.Paragraph
                      size='para'
                      variant='medium'
                      className='pl-[8px]'
                      color={pallete.colors.textDark3}
                    >
                      Remove me from organization
                    </DDSTypography.Paragraph>
                  }
                  value='removeMember'
                  name='membership'
                  id='radio-removeMember'
                  checked={selectedValue === 'removeMember'}
                  onChange={handleRemoveMember}
                />
              </div>
            </div>
            <DDSTypography.Paragraph
              size='para'
              variant='semiBold'
              color={pallete.colors.textDark7}
              className='pt-[8px]'
            >
              You will no longer be the owner of this organization
            </DDSTypography.Paragraph>
          </div>

          <hr className='w-full mt-[24px] pb-[16px] border-t border-gray-300' />
          <div className='flex gap-[10px] justify-end pb-[16px]'>
            <Button
              id='cancel-transfer'
              type='neutral'
              width='120px'
              size='small'
              label='Back'
              onClick={() => {
                handleReset()
                setClose()
              }}
            />
            <Button
              id='next-transfer'
              type='filled'
              width='120px'
              size='small'
              label='Next'
              disabled={!isFormValid}
              onClick={() => {
                if (isFormValid) {
                  handleNext()
                  gaEventTracker(gaEventConstants.SELECT_MEMBER_TO_TRANSFER)
                } else setError(true)
              }}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default observer(SelectMemberStep)
