import {
  ChevronDown,
  ChevronUp,
  Edit,
  Reply,
  TrashCan
} from '@carbon/icons-react'
import React, { MutableRefObject, useEffect, useRef } from 'react'
import {
  addComment,
  deleteComment,
  getAllCommentForException,
  updateComment
} from '../../../api/exception/Exception.service'

import { DDSTypography } from 'den-design-system'
import { DeletePopup } from './DeletePopup'
import { ProjectType } from '../../../enum/ProjectType.enum'
import Spinner from '../Spinner/Spinner'
import TextArea from '../DDS/TextArea/TextArea'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../DDS/Toast/Toast'
import { getTimeForActivity } from '../../../utils/DateTimeUtils'
import { isEmpty } from 'lodash'
import pallete from '../../../global/newPallete'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { useStoreContext } from '../../../store/StoreContext'

const Activity = () => {
  const { id } = useParams()
  const [activityMessages, setActivityMessages] = React.useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  const store = useStoreContext()
  const updateMessages = (value: string) => {
    setText('')
    setLoading(true)

    addComment({ $exceptionLogId: id as string, comment: value }).then(
      (response: any) => {
        return fetchAllActivities()
      }
    )
  }
  //Edit changes popup
  const [editLoading, setEditLoading] = useState<boolean>(true)
  const [editChangesPopup, setEditChangesPopup] = useState<boolean>(false)

  //Hovering attributes for activity div
  const [hoveringDiv, setHoveringDiv] = useState<string>('')
  const [text, setText] = React.useState<string>('')

  //Editing messages
  const [editableMessageId, setEditableMessageId] = React.useState<string>('')
  const [changedEditId, setChangedEditId] = React.useState<string>('')
  const handleEditMessage = (id: string) => {
    setEditableMessageId('')
    setEditableMessageId(id)
    setCurrentEditableMessage(
      activityMessages.find((message: any, index: number) => message.id === id)
        .message
    )

    setTimeout(() => {
      const textAreaField = document.getElementById(id)
      if (textAreaField) {
        textAreaField.focus()
      }
    }, 0)
  }
  //Replying messages
  const [showReply, setShowReply] = useState<boolean>(false)
  const [replyMessageId, setReplyMessageId] = React.useState<string>('')
  const [showReplyId, setShowReplyId] = React.useState<string>('')
  const [currentReplyMessage, setCurrentReplyMessage] =
    React.useState<string>('')
  const [currentEditableReplyMessage, setCurrentEditableReplyMessage] =
    React.useState<string>('')
  const [editableReplyId, setEditableReplyId] = useState<string>('')
  const [changeReplyId, setChangeReplyId] = useState<string>('')

  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY
  const replySectionRef = useRef<{
    [key: string]: MutableRefObject<HTMLDivElement | null>
  }>({})

  const getReplySectionRef = (
    id: string
  ): MutableRefObject<HTMLDivElement | null> => {
    if (!replySectionRef.current[id]) {
      replySectionRef.current[id] = React.createRef()
    }
    return replySectionRef.current[id]
  }
  useEffect(() => {
    if (showReplyId) {
      const ref = getReplySectionRef(showReplyId)
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    }
  }, [showReplyId])
  const handleReplyMessage = (id: string) => {
    if (!isEmpty(replyMessageId) && !isEmpty(currentReplyMessage)) {
      setEditChangesPopup(true)
      setChangeReplyId(id)
    } else if (
      !isEmpty(editableMessageId) &&
      !isEmpty(currentEditableMessage)
    ) {
      setEditChangesPopup(true)
      setEditChangesPopup(true)
    } else {
      setReplyMessageId(id)
    }
  }
  const handleContinueUnsavedChanges = (id: string) => {
    setReplyMessageId(id)
    if (!isEmpty(editableMessageId)) {
      setEditableMessageId('')
    }
    setCurrentReplyMessage('')
    setEditChangesPopup(false)
  }
  const handleShowReply = (id: string) => {
    const ref = getReplySectionRef(id)
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }, 100)
    if (showReplyId === '') {
      setShowReplyId(id)
    } else {
      setShowReplyId('')
    }
  }
  const handleEditReplyMessage = (id: string, value: string) => {
    setEditableReplyId(id)
    setCurrentEditableReplyMessage(value)
    setTimeout(() => {
      const textAreaField = document.getElementById(id)
      if (textAreaField) {
        textAreaField.focus()
      }
    }, 0)
  }
  const handleCancelEditableReplyMessage = () => {
    setEditableReplyId('')
    setCurrentEditableReplyMessage('')
  }
  const saveMessage = (commentId: string, type: 'Parent' | 'Reply') => {
    setLoading(true)
    updateComment({
      comment:
        type === 'Parent'
          ? (currentEditableMessage as string)
          : currentEditableReplyMessage,
      $exceptionLogId: id as string,
      $exceptionCommentId: commentId
    })
      .then((response: any) => {
        setCurrentEditableMessage('')
        setEditableMessageId('')
        setEditableReplyId('')

        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.EXCEPTIONS.UPDATE_A_COMMENT.SUCCESS
        })
        fetchAllActivities()
        setLoading(false)
      })
      .catch((err: any) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.EXCEPTIONS.UPDATE_A_COMMENT.ERROR
        })
        setLoading(false)
      })
  }
  const sendReply = () => {
    setLoading(true)
    addComment({
      $exceptionLogId: id as string,
      comment: currentReplyMessage,
      threadId: replyMessageId
    })
      .then((response: any) => {
        fetchAllActivities()
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.EXCEPTIONS.ADD_A_COMMENT.SUCCESS
        })
        setCurrentReplyMessage('')
        setReplyMessageId('')
        setLoading(false)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.EXCEPTIONS.ADD_A_COMMENT.ERROR
        })
        setLoading(false)
      })
  }

  const scrollToTop = () => {
    if (messageSectionRef.current) {
      messageSectionRef.current.scrollTop = 0
    }
  }
  const handleReplyMessageScroll = (id: string) => {
    const ref = getReplySectionRef(id)
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }
  // Effect to scroll to top whenever activityMessages change
  useEffect(() => {
    scrollToTop()
  }, [activityMessages])

  const cancelReplyingMessage = () => {
    setReplyMessageId('')
    setCurrentReplyMessage('')
  }
  const cancelEditingMessage = () => {
    setEditableMessageId('')
    setCurrentEditableMessage('')
    setActivityMessages(activityMessages)
  }
  const fetchAllActivities = () => {
    setLoading(true)
    getAllCommentForException({ $exceptionLogId: id as string }).then(
      (response: any) => {
        setActivityMessages(response.data.data)
        setLoading(false)
      }
    )
  }

  //Delete messages
  const [deletePopupOpen, setDeletePopUpOpen] = useState<boolean>(false)
  const [deleteCommentContent, setDeleteCommentContent] = useState<any>({})
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
  const messageSectionRef: MutableRefObject<HTMLDivElement | null> =
    useRef<HTMLDivElement>(null)
  const handleDeleteComment = (message: any) => {
    setDeletePopUpOpen(true)
    setDeleteCommentContent(message)
  }

  useEffect(() => {
    fetchAllActivities()
  }, [])

  const bannerDetails = { ...store.uiStore.getBannerDetails() }
  const isVisibility = store.uiStore.getIsAlertVisible()

  const [currentEditableMessage, setCurrentEditableMessage] =
    React.useState<string>('')
  return (
    <>
      <DeletePopup
        modalOpen={deletePopupOpen}
        setModalOpen={setDeletePopUpOpen}
        deleteLoading={deleteLoading}
        secondaryButtonClick={() => setDeletePopUpOpen(false)}
        primaryButtonClick={() => {
          setDeleteLoading(true)
          deleteComment({
            $exceptionLogId: id as string,
            $exceptionCommentId: deleteCommentContent.id
          })
            .then(() => {
              fetchAllActivities()
              setDeletePopUpOpen(false)
              setDeleteLoading(false)
              ToastNotification({
                type: 'success',
                message: ToastMessageConstants.EXCEPTIONS.DELETE_COMMENT.SUCCESS
              })
            })
            .catch((err) => {
              ToastNotification({
                type: 'error',
                message: ToastMessageConstants.EXCEPTIONS.DELETE_COMMENT.ERROR
              })
            })
        }}
        showFooter={true}
        primaryButtonText='Delete'
        secondaryButtonText='Cancel'
        primaryButtonDisabled={deleteLoading}
        secondaryButtonDisabled={deleteLoading}
        content={
          <div
            className='p-[24px] flex flex-col gap-y-[24px] border-b'
            style={{ borderColor: pallete.colors.stroke2 }}
          >
            <DDSTypography.Title
              type='h4'
              color={pallete.colors.textDark3}
              className='line-clamp-2 w-[600px]'
            >
              Are you sure you want to delete this message? This cannot be
              undone.
            </DDSTypography.Title>

            <div
              className='flex flex-row p-[12px] gap-x-[12px] border rounded'
              style={{ borderColor: pallete.colors.stroke2 }}
            >
              <img
                src={deleteCommentContent.actionedByUser?.avatar}
                alt='icon'
                className='h-[32px] w-[32px] rounded-full'
              />

              <div className='flex flex-col'>
                <div className='flex flex-row gap-x-[12px] items-center custom-text'>
                  <DDSTypography.Title type='h4' variant='semiBold'>
                    {deleteCommentContent.actionedByUser?.firstName +
                      ' ' +
                      deleteCommentContent.actionedByUser?.lastName}
                  </DDSTypography.Title>
                  <DDSTypography.Paragraph
                    size='caption'
                    variant='medium'
                    style={{ color: pallete.colors.textDark7 }}
                    className='whitespace-nowrap'
                  >
                    {getTimeForActivity(deleteCommentContent.createdAt)}
                  </DDSTypography.Paragraph>
                </div>
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  style={{
                    color: pallete.colors.textDark6,
                    overflowWrap: 'anywhere'
                  }}
                >
                  {deleteCommentContent.message}
                </DDSTypography.Paragraph>
              </div>
            </div>
          </div>
        }
      />
      <DeletePopup
        modalOpen={editChangesPopup}
        setModalOpen={setEditChangesPopup}
        primaryButtonText='Continue'
        editLoading={editLoading}
        primaryButtonDisabled={editLoading}
        secondaryButtonDisabled={editLoading}
        secondaryButtonText='Keep Editing'
        primaryButtonClick={() => handleContinueUnsavedChanges(changeReplyId)}
        secondaryButtonClick={() => {
          setEditChangesPopup(false)
        }}
        showFooter={true}
        content={
          <div
            className='p-[24px] flex flex-col gap-y-[24px] border-b'
            style={{ borderColor: pallete.colors.stroke2 }}
          >
            <DDSTypography.Title
              type='h4'
              color={pallete.colors.textDark3}
              className='line-clamp-2 w-[600px]'
            >
              You will lose any unsaved changes to your message.
            </DDSTypography.Title>
          </div>
        }
      />
      <div
        className={`flex flex-col justify-between ${bannerDetails && isVisibility ? 'h-[68.5vh]' : 'h-[72vh]'}  py-[24px] ${bannerDetails && isVisibility ? '2xl:h-[72.5vh]' : '2xl:h-[75vh]'} overflow-auto`}
      >
        {loading || store.uiStore.getGlobalLoader() ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <div
            ref={messageSectionRef}
            className={`flex flex-col overflow-y-auto ${bannerDetails && isVisibility ? 'h-[50vh] 2xl:h-[57vh]' : 'h-[54vh] 2xl:h-[58vh]'} `}
          >
            {activityMessages.map((message: any, index: number) => (
              <div
                key={index}
                className=' flex flex-col py-[12px] px-[12px] rounded-md'
                onMouseEnter={() => {
                  setHoveringDiv(message.id)
                }}
                onMouseLeave={() => {
                  setHoveringDiv('')
                }}
                style={{
                  backgroundColor:
                    hoveringDiv === message.id
                      ? pallete.colors.surface5
                      : 'white'
                }}
              >
                <div className={` flex flex-row gap-x-[12px] `}>
                  {message.actionedByUser ? (
                    <img
                      src={message.actionedByUser?.avatar}
                      alt='icon'
                      className='h-[32px] w-[32px] rounded-full'
                    />
                  ) : null}

                  <div className='flex flex-col gap-y-[4px] justify-between w-full'>
                    {editableMessageId !== message.id ? (
                      <div className='flex flex-row justify-between w-full'>
                        <div className='flex flex-col gap-y-[4px] justify-between w-full'>
                          <div className='flex flex-row w-full items-center gap-x-[8px] justify-between'>
                            <div className='flex flex-row gap-x-[8px]'>
                              {message.actionedByUser ? (
                                <DDSTypography.Title
                                  type='h5'
                                  variant='semiBold'
                                  className='whitespace-nowrap'
                                >
                                  {message.actionedByUser?.firstName +
                                    ' ' +
                                    message.actionedByUser?.lastName}
                                </DDSTypography.Title>
                              ) : null}
                              {index === activityMessages.length - 1 ? null : (
                                <DDSTypography.Paragraph
                                  size='caption'
                                  variant='medium'
                                  style={{ color: pallete.colors.textDark7 }}
                                  className='whitespace-nowrap'
                                >
                                  {getTimeForActivity(message.createdAt)}
                                </DDSTypography.Paragraph>
                              )}
                            </div>
                            {hoveringDiv === message.id &&
                            message.type === 'COMMENT' &&
                            message.isActive ? (
                              <div className='flex flex-row gap-x-[8px]'>
                                <Reply
                                  color={pallete.colors.textDark10}
                                  className='cursor-pointer'
                                  onClick={() => {
                                    handleReplyMessage(message.id)
                                    handleReplyMessageScroll(message.id)
                                    setShowReply(true)
                                  }}
                                />
                                <Edit
                                  color={pallete.colors.textDark10}
                                  className='cursor-pointer'
                                  onClick={() => handleEditMessage(message.id)}
                                />
                                <TrashCan
                                  color={pallete.colors.textDark10}
                                  className='cursor-pointer'
                                  onClick={() => handleDeleteComment(message)}
                                />
                              </div>
                            ) : null}
                          </div>
                          {index === activityMessages.length - 1 ? null : (
                            <DDSTypography.Paragraph
                              size='para'
                              variant='regular'
                              color={pallete.colors.textDark5}
                              style={{
                                overflowWrap: 'anywhere'
                              }}
                            >
                              {message.message
                                .split(/\b(exception|error)\b/i)
                                .map((part: any, partIndex: any) => {
                                  const lowerPart = part.toLowerCase()
                                  if (
                                    lowerPart === 'exception' ||
                                    lowerPart === 'error'
                                  ) {
                                    const replacement = isFullStackProject
                                      ? 'Exception'
                                      : 'Error'
                                    return (
                                      <React.Fragment key={partIndex}>
                                        {part === part.toUpperCase()
                                          ? replacement.toUpperCase()
                                          : part[0] === part[0].toUpperCase()
                                            ? replacement
                                            : replacement.toLowerCase()}
                                      </React.Fragment>
                                    )
                                  }
                                  return part
                                })}
                            </DDSTypography.Paragraph>
                          )}
                          {index === activityMessages.length - 1 ? (
                            <div className='flex flex-row items-center gap-x-[8px]'>
                              <div>
                                <DDSTypography.Paragraph
                                  size='caption'
                                  variant='medium'
                                  style={{ color: pallete.colors.textDark7 }}
                                  className='whitespace-nowrap'
                                >
                                  {getTimeForActivity(message.createdAt)}
                                </DDSTypography.Paragraph>
                              </div>
                              <DDSTypography.Paragraph
                                size='para'
                                variant='regular'
                                color={pallete.colors.textDark5}
                                style={{
                                  overflowWrap: 'anywhere'
                                }}
                              >
                                {message.message
                                  .split(/\b(exception|error)\b/i)
                                  .map((part: any, partIndex: any) => {
                                    const lowerPart = part.toLowerCase()
                                    if (
                                      lowerPart === 'exception' ||
                                      lowerPart === 'error'
                                    ) {
                                      const replacement = isFullStackProject
                                        ? 'Exception'
                                        : 'Error'
                                      return (
                                        <React.Fragment key={partIndex}>
                                          {part === part.toUpperCase()
                                            ? replacement.toUpperCase()
                                            : part[0] === part[0].toUpperCase()
                                              ? replacement
                                              : replacement.toLowerCase()}
                                        </React.Fragment>
                                      )
                                    }
                                    return part
                                  })}
                              </DDSTypography.Paragraph>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <TextArea
                        className={`text-[14px] font-semibold text-[#586679] resize-none ${
                          editableMessageId === message.id ? 'p-[12px]' : ''
                        } `}
                        id={message.id}
                        style={{
                          paddingRight: '156px'
                        }}
                        type='secondary'
                        value={currentEditableMessage}
                        loading={loading || store.uiStore.getGlobalLoader()}
                        disabled={loading}
                        onChange={(value: string) =>
                          setCurrentEditableMessage(value)
                        }
                        onCancel={cancelEditingMessage}
                        PrimaryButtonText='Save'
                        primaryButtonOnClick={() =>
                          saveMessage(message.id, 'Parent')
                        }
                      />
                    )}
                    {message.type === 'COMMENT' &&
                      message.isActive &&
                      message.threads.length > 0 && (
                        <div
                          onClick={() => {
                            handleShowReply(message.id)
                          }}
                        >
                          <DDSTypography.Paragraph
                            size='caption'
                            variant='medium'
                            style={{ color: pallete.colors.textDark2 }}
                            className='whitespace-nowrap flex flex-row items-center cursor-pointer'
                          >
                            <div className='flex items-center'>
                              {showReplyId === '' ? (
                                <ChevronDown color={pallete.colors.textDark2} />
                              ) : (
                                <ChevronUp color={pallete.colors.textDark2} />
                              )}
                            </div>

                            {message.threads.length > 1
                              ? message.threads.length + ' Replies'
                              : message.threads.length + ' Reply'}
                          </DDSTypography.Paragraph>
                        </div>
                      )}
                  </div>
                </div>
                <div className='flex flex-col gap-[8px]'>
                  <div>
                    {message.id === showReplyId &&
                      message.type === 'COMMENT' &&
                      message.isActive &&
                      message.threads.length > 0 &&
                      message.threads.map((thread: any, index: any) => {
                        return (
                          <div key={index} className=' px-[28px]  w-full'>
                            <div
                              className={` flex flex-row gap-x-[12px]  py-[12px] px-[12px] rounded-md`}
                              onMouseEnter={() => {
                                setHoveringDiv(thread.id)
                              }}
                              onMouseLeave={() => {
                                setHoveringDiv('')
                              }}
                              style={{
                                backgroundColor:
                                  hoveringDiv === thread.id
                                    ? pallete.colors.surface5
                                    : 'white'
                              }}
                            >
                              {message.actionedByUser ? (
                                <img
                                  src={message.actionedByUser?.avatar}
                                  alt='icon'
                                  className='h-[32px] w-[32px] rounded-full'
                                />
                              ) : null}

                              {editableReplyId !== thread.id ? (
                                <div className='flex flex-row justify-between w-full'>
                                  <div className='flex flex-col gap-y-[4px] justify-between w-full'>
                                    <div className='flex flex-row w-full justify-between items-center gap-x-[8px]'>
                                      <div className='flex justify-start gap-[8px]'>
                                        {message.actionedByUser ? (
                                          <DDSTypography.Title
                                            type='h5'
                                            variant='semiBold'
                                          >
                                            {thread.actionedByUser?.firstName +
                                              ' ' +
                                              thread.actionedByUser?.lastName}
                                          </DDSTypography.Title>
                                        ) : null}
                                        <DDSTypography.Paragraph
                                          size='caption'
                                          variant='medium'
                                          style={{
                                            color: pallete.colors.textDark7
                                          }}
                                          className='whitespace-nowrap'
                                        >
                                          {getTimeForActivity(thread.createdAt)}
                                        </DDSTypography.Paragraph>
                                      </div>
                                      {hoveringDiv === thread.id &&
                                      thread.type === 'COMMENT' &&
                                      thread.isActive ? (
                                        <div className='flex flex-row gap-x-[10px] items-center '>
                                          <Edit
                                            color={pallete.colors.textDark10}
                                            className='cursor-pointer'
                                            onClick={() =>
                                              handleEditReplyMessage(
                                                thread.id,
                                                thread.message
                                              )
                                            }
                                          />
                                          <TrashCan
                                            color={pallete.colors.textDark10}
                                            className='cursor-pointer'
                                            onClick={() =>
                                              handleDeleteComment(thread)
                                            }
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                    <DDSTypography.Paragraph
                                      size='para'
                                      variant='medium'
                                      style={{
                                        color: pallete.colors.textDark6,
                                        overflowWrap: 'anywhere'
                                      }}
                                    >
                                      {thread.message}
                                    </DDSTypography.Paragraph>
                                  </div>
                                </div>
                              ) : (
                                <TextArea
                                  type='secondary'
                                  PrimaryButtonText='Save'
                                  className={`text-[14px] font-semibold text-[#586679] resize-none ${
                                    editableMessageId === thread.id
                                      ? 'p-[12px]'
                                      : ''
                                  } `}
                                  loading={
                                    loading || store.uiStore.getGlobalLoader()
                                  }
                                  disabled={loading}
                                  id={thread.id}
                                  value={currentEditableReplyMessage}
                                  onChange={(value: string) =>
                                    setCurrentEditableReplyMessage(value)
                                  }
                                  onCancel={handleCancelEditableReplyMessage}
                                  primaryButtonOnClick={() =>
                                    saveMessage(thread.id, 'Reply')
                                  }
                                />
                              )}
                            </div>
                          </div>
                        )
                      })}
                  </div>
                  {replyMessageId === message.id && (
                    <div ref={getReplySectionRef(message.id)}>
                      <TextArea
                        type='secondary'
                        PrimaryButtonText='Reply'
                        onCancel={() => cancelReplyingMessage()}
                        onChange={(value: string) =>
                          setCurrentReplyMessage(value)
                        }
                        primaryButtonOnClick={sendReply}
                        loading={loading || store.uiStore.getGlobalLoader()}
                        disabled={loading}
                        style={{
                          paddingRight: '156px'
                        }}
                        value={currentReplyMessage}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        <div>
          <TextArea
            style={{ paddingRight: '120px' }}
            placeholder='Write an update'
            onChange={setText}
            onSend={() => {
              updateMessages(text)
              scrollToTop()
            }}
            value={text}
            type='primary'
            loading={loading || store.uiStore.getGlobalLoader()}
            disabled={loading}
          />
        </div>
      </div>
    </>
  )
}
export default Activity
