import ContextSwitcherTabs from '../common/DDS/ContextSwitcherTab/ContextSwitcherTab'
import { DDSTypography } from 'den-design-system'
import LineChart from './LineChart'
import React from 'react'
import pallete from '../../global/newPallete'

interface OverviewGraphProps {
  title: string
  tab: string
  setTab: any
  data: any
  tabOptions?: any
}
const OverviewGraph: React.FC<OverviewGraphProps> = ({
  title,
  tab,
  tabOptions,
  data,
  setTab
}) => {
  return (
    <div className='flex flex-col items-start gap-[24px] w-full'>
      <div className='flex items-center gap-[12px] self-stretch w-full'>
        <div className='w-full'>
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.textDark3}
          >
            {title}
          </DDSTypography.Title>
        </div>
        <ContextSwitcherTabs
          shape='roundedRectangle'
          activeTab={tab}
          tabOptions={tabOptions}
          tabStyle={{
            border: '1px solid',
            borderColor: pallete.colors.stroke2,
            userSelect: 'none',
            width: '172px'
          }}
          onChange={(activeTab) => {
            setTab(activeTab)
          }}
          className='override-overview-graph-tabs'
        />
      </div>
      <div className='w-full h-full'>
        <LineChart data={data} />
      </div>
    </div>
  )
}

export default OverviewGraph
