import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  addProjectMember,
  getAllProjectMembers,
  removeProjectMember,
  updateProjectMember
} from '../../../../../api/project/ProjectMember.service'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../../../constants/GAConstants'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../../../utils/UrlUtils'
import { isEmpty, isUndefined } from 'lodash'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { Button } from '../../../../../components/New/Button/Button'
import { DDSTypography } from 'den-design-system'
import InviteMemberPopup from '../../../../../components/OverviewPage/InviteMemberPopup'
import MultiSelect from '../../../../../components/common/MultiSelectTailwind/MultiSelect'
import { OrganizationUserRoles } from '../../../../../enum/OrganizationUserRoles.enum'
import Select from '../../../../../components/New/Select/Select'
import { SettingAddPopup } from '../../SettingPopups/SettingAddPopup'
import SettingConstants from '../../../../../constants/SettingConstants'
import { SettingDeletePopup } from '../../SettingPopups/SettingDeletePopup'
import Spinner from '../../../../../components/common/Spinner/Spinner'
import StringConstants from '../../../../../constants/StringConstants'
import Table from '../../../../../components/common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../../../components/New/Tooltip/Tooltip'
import { TrashCan } from '@carbon/icons-react'
import ValidationConstants from '../../../../../constants/ValidationConstants'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../../global/newPallete'
import routeConstants from '../../../../../constants/RouteConstants'
import { unassignedOrganizationMemberLookUpForProject } from '../../../../../api/organization/Organization.service'
import { useAnalyticsEventTracker } from '../../../../../utils/GAUtils'
import { useStoreContext } from '../../../../../store/StoreContext'

interface Project {
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const ProjectManageMember: React.FC<Project> = ({
  loading = false,
  setLoading
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedUsers, setSelectedUsers] = React.useState<any>([])
  const [assignPopupOpen, setAssignPopupOpen] = React.useState<boolean>(false)
  const [invitePopupOpen, setInvitePopupOpen] = React.useState<boolean>(false)
  const [deleteMemberPopupOpen, setDeleteMemberPopupOpen] =
    React.useState<boolean>(false)
  const [loadOptions, setLoadOptions] = React.useState<boolean>(false)
  const [options, setOptions] = React.useState<any>([])
  const [selectedRole, setSelectedRole] = useState(null)

  const [memberTobeRemoved, setMemberTobeRemoved] = React.useState<any>({})
  const [totalCount, setTotalCount] = React.useState<number>(0)
  const store = useStoreContext()
  const [memberData, setMemberData] = React.useState<any>([])
  const [role, setRole] = React.useState<string | null>()

  const [noMembersAdded, setNoMembersAdded] = React.useState<boolean>(false)
  const [openMultiSelect, setOpenMultiSelect] = React.useState<boolean>(false)
  const [noRoleAdded, setNoRoleAdded] = React.useState<boolean>(false)
  const [page, setPage] = React.useState<number>(
    parseInt(searchParams.get('page') as string) || StringConstants.DEFAULT_PAGE
  )
  const [validationErrorResult, setValidationErrorResult] = React.useState<
    any[]
  >([])

  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.MANAGE_PROJECT_MEMBERS
  )

  React.useEffect(() => {
    if (selectedUsers.length !== 0) setNoMembersAdded(false)
    if (!isEmpty(role)) setNoRoleAdded(false)
  }, [selectedUsers, role])

  const selectOption = [
    {
      id: 1,
      label: 'MEMBER',
      value: 'MEMBER'
    }
  ]

  // const handleDropdown = (id: string) => {
  //   const Role = selectOption.find((item: any) => item.id === id)?.label
  //   setRole(Role || '')
  // }

  const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
  const { projectId } = useParams()
  const navigate = useNavigate()

  const getProjectMembers = () => {
    setLoading(true)
    getAllProjectMembers({
      ...queryParams,
      projectId
    })
      .then((response: any) => {
        console.log(response.data, 'response')
        setLoading(false)
        setMemberData(response.data)

        store.projectMemberStore.setProjectMembersDetails(response.data)
        setPage(response.page)
        setTotalCount(response.totalCount)
        store.filterStore.setFilters(response.filters)
      })
      .catch((err) => {
        setLoading(false)
        if (err !== 'Forbidden Exception') {
          ToastNotification({
            type: 'error',
            message:
              ToastMessageConstants.PROJECT_MEMBER.FIND_ALL_PROJECT_MEMBERS
                .ERROR
          })
        }
        navigate(
          replaceUrl(
            routeConstants.PROJECT_SETTING,
            store.scopeStore.getScope()['organization-id'] as string,
            '',
            ''
          )
        )
      })
      .finally(() => store.uiStore.setGlobalLoader(false))
  }

  useEffect(() => {
    getProjectMembers()
  }, [location.search])

  const handleDropdownRole = (role: string, _userId: string) => {
    role = role.toUpperCase()
    setLoading(true)
    updateProjectMember(role, _userId, projectId as string)
      .then(() => {
        ToastNotification({
          type: 'success',
          message:
            ToastMessageConstants.PROJECT_MEMBER.UPDATE_PROJECT_MEMBER.SUCCESS
        })
      })

      .catch((err: any) => {
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.PROJECT_MEMBER.UPDATE_PROJECT_MEMBER.ERROR
        })
        setLoading(false)
      })
  }

  const handleAddMembers = () => {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    const projectMemberDetails = {
      addProjectMemberDetails: selectedUsers.map(({ id }: any) => ({
        organizationMemberId: id,
        projectUserRole: role?.toUpperCase()
      }))
    }
    if (
      projectMemberDetails.addProjectMemberDetails.length > 0 &&
      !isEmpty(role)
    ) {
      addProjectMember({ ...projectMemberDetails, projectId })
        .then(() => {
          gaEventTracker(gaEventConstants.ADDED_PROJECT_MEMBERS)
          setSelectedUsers([])
          setAssignPopupOpen(false)
          setRole('')
          ToastNotification({
            type: 'success',
            message:
              ToastMessageConstants.PROJECT_MEMBER.ADD_PROJECT_MEMBER.SUCCESS
          })
          getProjectMembers()
        })
        .catch((err) => {
          ToastNotification({
            type: 'error',
            message:
              ToastMessageConstants.PROJECT_MEMBER.ADD_PROJECT_MEMBER.ERROR
          })
          setLoading(false)
          store.uiStore.setGlobalLoader(false)
          setAssignPopupOpen(false)
          setSelectedUsers([])
        })
    } else {
      if (
        isEmpty(role) &&
        projectMemberDetails.addProjectMemberDetails.length === 0
      ) {
        setNoMembersAdded(true)
        setNoRoleAdded(true)
      } else if (!isEmpty(role)) {
        setNoMembersAdded(true)
      } else setNoRoleAdded(true)
      setLoading(false)
    }
  }

  const handleDelete = () => {
    setLoading(true)
    removeProjectMember(memberTobeRemoved.user.id, projectId as string)
      .then(() => {
        setPage(StringConstants.DEFAULT_PAGE)
        setSearchParams({
          ...queryParams,
          page: StringConstants.DEFAULT_PAGE.toString()
        })
        getProjectMembers()
        ToastNotification({
          type: 'success',
          message:
            ToastMessageConstants.PROJECT_MEMBER.REMOVE_PROJECT_MEMBER.SUCCESS
        })
        setDeleteMemberPopupOpen(false)
      })
      .catch((err) => {
        const memberRole =
          memberTobeRemoved.role?.charAt(0).toUpperCase() +
          memberTobeRemoved.role?.slice(1).toLowerCase()
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.PROJECT_MEMBER.REMOVE_PROJECT_MEMBER.ERROR.replace(
              '{RoleName}',
              memberRole
            )
        })
        setDeleteMemberPopupOpen(false)
        setLoading(false)
      })
  }

  useEffect(() => {
    const breadcrumbsOptions = store.breadcrumbStore.getBreadcrumbsOptions()
    const selectedProject = store.scopeStore.getSelectedProject()
    const projects = store.scopeStore.getProjects()

    if (breadcrumbsOptions.length === 0) {
      const projectName = projects.find(
        (project) => project.id === projectId
      )?.name

      store.breadcrumbStore.addMultipleBreadCrumbOptions([
        { label: 'Project Settings', link: routeConstants.PROJECT_SETTING },
        {
          label: projectName || 'Unknown Project',
          link:
            routeConstants.VIEW_PROJECT.replace(
              ':projectId',
              projectId as string
            ) + '?tab=1'
        },
        { label: 'Members', link: '/' }
      ])
    }

    return () => {
      store.breadcrumbStore.reset()
    }
  }, [projectId, store.scopeStore, store.breadcrumbStore])

  const columns = [
    {
      columnHeaderStyle: { width: '300px' },
      columnDataStyle: {},
      heading: (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {SettingConstants.USER}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'user',
      render: (text: any) => {
        return (
          <div className='flex justify-start text-start row items-start'>
            <div>
              <Tooltip
                id={`tooltip-name-${text?.firstName + ' ' + text?.lastName}`}
                label={text?.firstName + ' ' + text?.lastName}
                className={`${
                  typeof text?.firstName === 'string' &&
                  typeof text?.lastName === 'string' &&
                  text?.firstName.length + text?.lastName.length > 20
                    ? text?.firstName + ' ' + text?.lastName
                    : '!hidden'
                }`}
              >
                <div className=''>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark4}
                    className='tooltip-container sm:block overflow-hidden truncate max-w-[300px]'
                  >
                    {text?.firstName + ' ' + text?.lastName}
                  </DDSTypography.Paragraph>
                </div>
              </Tooltip>
            </div>
          </div>
        )
      }
    },
    {
      columnHeaderStyle: { width: '200px' },
      columnDataStyle: { width: '200px' },
      heading: (
        <div className='flex justify-center text-center row max-w-[200px] items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {SettingConstants.ROLE}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: '',
      render: (fullItem: any, text: any) => {
        return (
          <div className='flex justify-center max-w-[200px] text-center row items-center '>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container sm:block overflow-hidden truncate'
            >
              {text.role}
            </DDSTypography.Paragraph>
          </div>
        )
      }
    },
    {
      columnHeaderStyle: { width: '200px' },
      columnDataStyle: { width: '200px' },
      heading: (
        <div className='flex justify-center max-w-[200px] text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {SettingConstants.EMAIL}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'user',
      render: (text: any) => {
        return (
          <Tooltip
            id={`tooltip-name-${text?.email}`}
            label={text.email ? text?.email : '-'}
            className={`${
              typeof text?.email === 'string' && text.email.length > 10
                ? text.email
                : '!hidden'
            }`}
          >
            <div className='flex justify-center text-center row max-w-[200px] items-center'>
              <DDSTypography.Paragraph
                size='para'
                variant='medium'
                color={pallete.colors.textDark4}
                className='tooltip-container sm:block overflow-hidden truncate max-w-[200px]'
              >
                {text?.email}
              </DDSTypography.Paragraph>
            </div>
          </Tooltip>
        )
      }
    },
    {
      columnHeaderStyle: { width: '5%' },
      columnDataStyle: { width: '5%' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='flex justify-end items-end'>
          <Button
            id='right-btn'
            type='ghost'
            startIcon={
              <TrashCan
                size={16}
                color={pallete.colors.warningRed2}
                style={{
                  opacity:
                    loading ||
                    store.uiStore.getGlobalLoader() ||
                    store.userStore.getUserRole() !==
                      OrganizationUserRoles.OWNER ||
                    store.userStore.getNoActiveSubscription()
                      ? '0.5'
                      : '1'
                }}
              />
            }
            onClick={() => {
              setMemberTobeRemoved(fullItem)
              setDeleteMemberPopupOpen(true)
            }}
            disabled={
              loading ||
              store.uiStore.getGlobalLoader() ||
              store.userStore.getUserRole() !== OrganizationUserRoles.OWNER ||
              store.userStore.getNoActiveSubscription()
            }
          />
        </div>
      )
    }
  ]

  const memberLookUpFunction = (value: any) => {
    setOptions([])
    if (value.length >= 3) {
      setOpenMultiSelect(true)
      setLoadOptions(true)
      unassignedOrganizationMemberLookUpForProject({
        'name-or-email': value,
        self: true,
        projectId
      })
        .then((response: any) => {
          let data = response.data

          data = data.filter(
            (item: any) =>
              !selectedUsers.map((user: any) => user.id).includes(item.id)
          )
          if (data.length === 0) setOpenMultiSelect(false)
          setOptions(
            data.map(({ id, user }: any) => {
              return {
                id: id,
                name: `${user.firstName}`,
                email: `${user.email}`
              }
            })
          )
        })
        .finally(() => setLoadOptions(false))
    } else setOpenMultiSelect(false)
  }

  const handleDropdown = (e: string | ChangeEvent<HTMLInputElement>) => {
    let selectedRole = ''

    if (typeof e === 'string') {
      const selectedOption = CustomDropdownOptions.find(
        (item: any) => item.value === e
      )
      selectedRole = selectedOption?.label || ''
    }

    setRole(selectedRole)
  }

  const CustomDropdownOptions = [
    {
      label: 'Member',
      value: 'MEMBER'
    }
    // {
    //   id: 1,
    //   name: 'Manager',
    //   secondaryText: 'Manages all Members in the Project',
    // },
  ]

  return (
    <>
      {store.uiStore.getGlobalLoader() || loading ? (
        <Spinner />
      ) : (
        <div className='py-[24px]'>
          <div className='flex justify-between items-center mb-[32px]'>
            <div>
              <DDSTypography.Title
                type='h3'
                variant='semiBold'
                color={pallete.colors.textDark3}
              >
                {SettingConstants.MANAGE_USERS}
              </DDSTypography.Title>
            </div>
            <div className='flex gap-[12px]'>
              <Button
                id='invite-member'
                type='outline'
                size='small'
                label='Invite Member'
                onClick={() => setInvitePopupOpen(true)}
                disabled={
                  loading ||
                  store.uiStore.getGlobalLoader() ||
                  store.userStore.getUserRole() !==
                    OrganizationUserRoles.OWNER ||
                  store.userStore.getNoActiveSubscription()
                }
              />
              <Button
                id='assign-member'
                type='filled'
                size='small'
                label='Assign Member'
                onClick={() => setAssignPopupOpen(true)}
                disabled={
                  loading ||
                  store.uiStore.getGlobalLoader() ||
                  store.userStore.getUserRole() !==
                    OrganizationUserRoles.OWNER ||
                  store.userStore.getNoActiveSubscription()
                }
              />
            </div>
          </div>
          <div>
            <Table
              tableStyle={{
                borderColor: pallete.colors.stroke2
              }}
              data={memberData}
              columns={columns}
              currentPage={page}
              totalCount={totalCount}
              rowsPerPage={StringConstants.DEFAULT_TABLE_SIZE}
              onPageChange={(newPage) => {
                const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                  location.search
                )
                setSearchParams({ ...allQueryParams, page: newPage.toString() })
                setPage(newPage)
              }}
              loading={loading || store.uiStore.getGlobalLoader()}
            />
          </div>
        </div>
      )}
      <SettingAddPopup
        showHeader
        title='Assign Member'
        modalOpen={assignPopupOpen}
        setModalOpen={setAssignPopupOpen}
        primaryButtonText='Assign Member'
        primaryButtonDisabled={
          isUndefined(role) || loading || selectedUsers.length === 0
        }
        secondaryButtonDisabled={loading}
        loading={loading}
        secondaryButtonText='Cancel'
        primaryButtonClick={handleAddMembers}
        secondaryButtonClick={() => {
          setAssignPopupOpen(false)
          setSelectedUsers([])
        }}
        showFooter={true}
        content={
          <div className='flex flex-col p-[24px]'>
            <div>
              <DDSTypography.Title
                variant='semiBold'
                type='h4'
                color={pallete.colors.textDark3}
              >
                Assign Members to {''}
                <span
                  style={{ fontWeight: 700, color: pallete.colors.textDark3 }}
                >
                  {store.scopeStore.getProjectName(projectId)}
                </span>
              </DDSTypography.Title>
              <DDSTypography.Title
                variant='medium'
                type='h5'
                color={pallete.colors.textDark4}
                className='mt-[8px] mb-[24px]'
              >
                {SettingConstants.ASSIGN_MEMBER_FROM_ORGANIZATION}
              </DDSTypography.Title>
            </div>
            <DDSTypography.Paragraph
              variant='medium'
              size='para'
              color={pallete.colors.textDark3}
              className=' mb-[8px]'
            >
              Add Member
            </DDSTypography.Paragraph>
            <div className='mb-[24px]'>
              <MultiSelect
                options={options}
                value={selectedUsers}
                optionsLabelAccessor={(user: any) => user.name}
                onChange={(users) => {
                  setSelectedUsers(users)
                  setOpenMultiSelect(false)
                  setOptions([])
                }}
                onDeleteSelection={({ id }) =>
                  !loading
                    ? setSelectedUsers(
                        selectedUsers.filter((option: any) => option.id !== id)
                      )
                    : ''
                }
                validationError={
                  noMembersAdded &&
                  ValidationConstants.NO_MEMBERS_ADDED.PROJECT_MEMBERS
                }
                placeholder='Enter members name/email'
                onTextChange={(value) => {
                  if (value === '') return
                  memberLookUpFunction(value)
                }}
                openDropdown={openMultiSelect}
                setOpenDropdown={setOpenMultiSelect}
                loadingOption={loadOptions}
                disabled={loading}
              />
            </div>
            <div className='override-member-role-select '>
              <Select
                id='member-role-select'
                name='select'
                size='large'
                label='Member Role'
                placeholder='Select role'
                options={CustomDropdownOptions}
                searchable={false}
                onChange={handleDropdown}
              />
            </div>
          </div>
        }
      />

      <InviteMemberPopup
        open={invitePopupOpen}
        setOpen={setInvitePopupOpen}
        project={projectId}
        loading={store.uiStore.getGlobalLoader()}
      />

      <SettingDeletePopup
        showHeader
        title='Remove Member'
        modalOpen={deleteMemberPopupOpen}
        setModalOpen={setDeleteMemberPopupOpen}
        primaryButtonText='Yes'
        primaryButtonDisabled={loading}
        secondaryButtonDisabled={loading}
        loading={loading}
        secondaryButtonText='No'
        primaryButtonClick={handleDelete}
        secondaryButtonClick={() => {
          setDeleteMemberPopupOpen(false)
        }}
        showFooter={true}
        content={
          <div className='flex flex-col p-[24px] gap-[24px]'>
            <div>
              <DDSTypography.Title
                variant='semiBold'
                type='h4'
                color={pallete.colors.textDark3}
              >
                Do you want to remove {''}
                <span
                  style={{ color: pallete.colors.textDark3, fontWeight: 700 }}
                >
                  {memberTobeRemoved?.user?.firstName +
                    ' ' +
                    memberTobeRemoved?.user?.lastName}
                </span>
                {''} from the Project
              </DDSTypography.Title>
            </div>
          </div>
        }
      />
    </>
  )
}

export default observer(ProjectManageMember)
