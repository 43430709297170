export enum timeFormats {
  dateWithHoursAndMinutes = 'YYYY-MM-DD_HH-mm',
  monthDateYear = 'MMMM DD, YYYY',
  monthDayYear = 'MMMM Do YYYY',
  dateMonYear = 'DD MMM YYYY',
  dateSlashMonSlashYear = 'DD/MM/YYYY',
  dateMonthYear = 'DD MMMM YYYY',
  fullDateAndTime = 'MMM DD, YYYY HH:mm:ss:SSS',
  fullMonthDateYearTime = 'MMMM DD, YYYY LT',
  fullMonthDateYear = 'MMMM DD, YYYY ',
  fullMonthDateYearTimeWithSeconds = 'MMMM DD, YYYY LTS',
  monthDateTimeWithMeridiem = 'lll',
  monthDateCommaYear = 'LL',
  dateMonth = 'DD MMM',
  dateAndTimeWithMeridiem = 'MMMM D, YYYY hh:mm A'
}
