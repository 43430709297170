import React, { useEffect, useState } from 'react'
import {
  availabilityToColor,
  dateRange,
  statusToIcon
} from '../../utils/OverviewUtils'
import { convertToFormat, reportFilterOptions } from '../../utils/UCUtils'
import {
  deleteHealthCheck,
  getHealthCheckReports,
  getHealthSummary
} from '../../api/appHealthCheck/AHC.service'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../utils/UrlUtils'
import { isEmpty, isNull, isUndefined } from 'lodash'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Button } from '../New/Button/Button'
import { ChevronRight } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import Filter from '../Filter/Filter'
import { FilterTypes } from '../../enum/Filter.enum'
import NoFilterImage from '../../assets/images/folder-not-found 1.svg'
import NoRecord from '../common/NoContent/NoRecord'
import NoRecordImage from '../../assets/images/UCNoRecord.svg'
import SideChip from '../New/SideChip/SideChip'
import Spinner from '../common/Spinner/Spinner'
import StringConstants from '../../constants/StringConstants'
import Table from '../common/DDS/Table/Table'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../common/DDS/Toast/Toast'
import Tooltip from '../New/Tooltip/Tooltip'
import UCDeleteCheckPopup from './UCDeleteCheckPopup'
import UCResponseTimeGraph from './UCResponseTimeGraph'
import UCSummaryCard from './UCCard'
import UCSummaryDropdown from './UCSummaryDropdown'
import UptimeStringConstants from '../../constants/UptimeStringConstants'
import { getFiltersForApi } from '../../utils/FilterUtils'
import moment from 'moment'
import pallete from '../../global/newPallete'
import routeConstants from '../../constants/RouteConstants'
import { timeMetric } from '../../utils/DateTimeUtils'
import { useStoreContext } from '../../store/StoreContext'

const UCSummary: React.FC<{
  setFetchHealthSummary: any
  summaryLoading: boolean
  setSummaryLoading: any
  loading: boolean
  setLoading: any
}> = ({
  setFetchHealthSummary,
  summaryLoading,
  setSummaryLoading,
  loading,
  setLoading
}) => {
  const store = useStoreContext()
  const [uptimeData, setUptimeData] = useState<any>(null)
  const [summaryData, setSummaryData] = useState<any>(null)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const [selectedCheck, setSelectedCheck] = useState<any>({})

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchParams, setSearchParams] = useSearchParams()
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false)
  const getInitialSelectedCardId = () => {
    const status = searchParams.get('previous-check-status')
    switch (status) {
      case 'Up':
        return '2'
      case 'Down':
        return '3'
      case 'Paused':
        return '4'
      default:
        return '1'
    }
  }
  const [selectedCardId, setSelectedCardId] = useState<any>(
    getInitialSelectedCardId()
  )

  const [dateFilter, setDateFilter] = useState<any>({})
  const navigate = useNavigate()
  const fetchUCSummary = () => {
    setSummaryLoading(true)
    getHealthSummary()
      .then((response: any) => setSummaryData(response.data))
      .catch(() =>
        ToastNotification({
          type: 'error',
          message: 'Failed to fetch uptime checks summary'
        })
      )
      .finally(() => {
        setSummaryLoading(false)
      })
  }

  const fetchUCData = () => {
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const filter = getAllQueryParamsAsObjectFromUrl(location.search)
    const filters = !isUndefined(
      store.ahcReportsStore.getStatusFilter()?.['previous-check-status']
    )
      ? {
          ...filter,
          'previous-check-status':
            store.ahcReportsStore.getStatusFilter()?.['previous-check-status']
        }
      : filter
    const apiFilters = getFiltersForApi(FilterTypes.AHC, filters)
    getHealthCheckReports({
      page: StringConstants.DEFAULT_PAGE,
      size: 5,
      ...apiFilters
    })
      .then((response: any) => {
        if (response.data.length > 0 || store.filterStore.getFiltersApplied()) {
          store.filterStore.setFilterDisabled(false)
        }
        setUptimeData(response)
        if (
          response.data.length !== 0 &&
          window.location.pathname.split('/').slice(-1)[0] === 'dashboard'
        ) {
          if (
            response.filters['created-to'] &&
            moment(response.filters['created-to']).isAfter(new Date())
          ) {
            const currentDay = new Date()
            currentDay.setSeconds(0)
            response.filters['created-to'] = currentDay.toISOString()
          }
          const filteredDateFilters = {
            'created-from': response.filters['created-from'],
            'created-to': response.filters['created-to'],
            'time-zone': response.filters['time-zone']
          }

          store.filterStore.setFilters(response.filters)
          setDateFilter(filteredDateFilters)
        }
      })
      .catch((error) =>
        ToastNotification({
          type: 'error',
          message: error
        })
      )
      .finally(() => {
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })
  }

  const handleDeleteCheck = () => {
    setButtonLoading(true)
    deleteHealthCheck({ $ahcId: selectedCheck?.id })
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.AHC.DELETE_AHC.SUCCESS
        })
        handleRefreshClick()
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: err
        })
      })
      .finally(() => {
        setButtonLoading(false)
        setDeleteOpen(false)
      })
  }
  const handleRefreshClick = () => {
    fetchUCSummary()
    fetchUCData()
  }

  useEffect(() => {
    setFetchHealthSummary(handleRefreshClick)
  }, [])

  useEffect(() => {
    const pageFromQueryParams = searchParams.get('page')
    if (!isEmpty(store.scopeStore.getScope()['environment-id']))
      if (!isNull(pageFromQueryParams)) {
        setCurrentPage(Number(pageFromQueryParams))
      } else {
        const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
        setSearchParams({ ...allQueryParams, page: '1' })
        setCurrentPage(1)
      }
    handleRefreshClick()
  }, [store.scopeStore.getScope(), searchParams])

  const cardData = [
    {
      id: '1',
      heading: 'Total Checks',
      content: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark3}
        >
          {summaryData?.totalChecks?.toString().padStart(2, '0')}
        </DDSTypography.Title>
      ),
      onClick: () => {
        // Only allow clicking if this is not the initially selected card
        if (selectedCardId !== '1' && summaryData?.totalChecks > 0) {
          setSearchParams({})
          setSelectedCardId('1')
        }
      },
      disableChevron: summaryData?.totalChecks === 0,
      selected: true
    },
    {
      id: '2',
      heading: 'Total Up',
      content: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.warningGreen}
        >
          {summaryData?.totalUp?.toString().padStart(2, '0')}
        </DDSTypography.Title>
      ),
      onClick: () => {
        if (summaryData?.totalChecks > 0) {
          const allQueryParams = getAllQueryParamsAsObjectFromUrl(
            location.search
          )
          setSearchParams({
            ...allQueryParams,
            page: '1',
            ['previous-check-status']: 'Up'
          })
        }
        setSelectedCardId('2')
      },
      disableChevron: summaryData?.totalChecks === 0,
      selected: false
    },
    {
      id: '3',
      heading: 'Total Down',
      content: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.warningRed2}
        >
          {summaryData?.totalDown?.toString().padStart(2, '0')}
        </DDSTypography.Title>
      ),
      onClick: () => {
        if (summaryData?.totalChecks > 0) {
          const allQueryParams = getAllQueryParamsAsObjectFromUrl(
            location.search
          )
          setSearchParams({
            ...allQueryParams,
            page: '1',
            ['previous-check-status']: 'Down'
          })
        }
        setSelectedCardId('3')
      },
      disableChevron: summaryData?.totalChecks === 0,
      selected: false
    },
    {
      id: '4',
      heading: 'Total Paused',
      content: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.warningOrange}
        >
          {summaryData?.totalPaused?.toString().padStart(2, '0')}
        </DDSTypography.Title>
      ),
      onClick: () => {
        if (summaryData?.totalChecks > 0) {
          const allQueryParams = getAllQueryParamsAsObjectFromUrl(
            location.search
          )
          setSearchParams({
            ...allQueryParams,
            page: '1',
            ['previous-check-status']: 'Paused'
          })
        }
        setSelectedCardId('4')
      },
      disableChevron: summaryData?.totalChecks === 0,
      selected: false
    }
  ]

  const Columns = [
    {
      columnHeaderStyle: { width: '110px' },
      columnDataStyle: { width: '110px' },
      heading: (
        <div className='flex justify-center text-center whitespace-nowrap row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Application Name
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'checkName',
      render: (text: any) => (
        <div className='flex justify-start max-w-[110px]'>
          <Tooltip
            id={`tooltip-url-${text}`}
            label={text}
            className={`${
              typeof text === 'string' && text.length > 10 ? text : '!hidden'
            }`}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate max-w-[100px]'
              color={pallete.colors.textDark4}
            >
              {text ?? '-'}
            </DDSTypography.Paragraph>
          </Tooltip>
        </div>
      )
    },
    {
      columnHeaderStyle: {
        width: '200px'
      },
      columnDataStyle: {
        width: '200px'
      },
      heading: (
        <div className='flex justify-center text-center max-w-[200px] items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            URL
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'url',
      render: (text: any) => (
        <div className='flex justify-center max-w-[200px] text-center '>
          <Tooltip
            id={`tooltip-url-${text}`}
            label={text}
            className={`max-w-[300px] break-all ${
              typeof text === 'string' && store.uiStore.getSidebarExpanded()
                ? text.length > 10
                  ? text
                  : '!hidden'
                : text.length > 15
                  ? text
                  : '!hidden'
            }`}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className={`truncate max-w-[200px]`}
              color={pallete.colors.textDark4}
            >
              {text ?? '-'}
            </DDSTypography.Paragraph>
          </Tooltip>
        </div>
      )
    },

    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center  text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Status
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'status',
      render: (status: any, fullItem: any) => {
        let updatedStatus = status
        if (!fullItem?.monitorAppHealthCheck) updatedStatus = 'Paused'
        return (
          <div className='flex justify-center'>
            {statusToIcon(updatedStatus)}
          </div>
        )
      }
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Availability
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'availability',
      render: (text: any, fullItem: any) => (
        <div className='flex justify-center text-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={availabilityToColor(
              text ? (text !== '-' ? text : '-') : '0'
            )}
          >
            {fullItem.status !== 'Paused' && text && text != 'Not enough data'
              ? text !== '-'
                ? text + '%'
                : '0%'
              : fullItem.status !== 'Paused'
                ? '0%'
                : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center whitespace-nowrap text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Avg. Response Time
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'averageResponseTime',
      render: (avgResponse: any) => {
        const response = avgResponse
          ? `${timeMetric(avgResponse.duration, avgResponse.unit) || '-'}`
          : '-'
        return (
          <div className='flex justify-center text-center'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
            >
              {response}
            </DDSTypography.Paragraph>
          </div>
        )
      }
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Downtime
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'downTime',
      render: (downTime: any, fullItem: any) => (
        <div className='flex justify-center text-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            className='whitespace-nowrap'
            color={pallete.colors.textDark4}
          >
            {timeMetric(downTime?.duration, downTime?.unit)
              ? `${timeMetric(downTime?.duration, downTime?.unit)} (${
                  downTime?.percentage
                }%)`
              : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '20%' },
      columnDataStyle: {
        width: '20%'
      },
      heading: (
        <div className='flex row justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Response Time Graph
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'responsesByDate',
      render: (responsesByDate: any) => {
        const responseTimeData = Object.keys(responsesByDate).map(
          (key, index) => ({
            x: index,
            y: responsesByDate[key].averageResponseTimeInMilliSeconds
          })
        )
        return (
          <div className='flex justify-center'>
            {isEmpty(responsesByDate) || responseTimeData.length === 1 ? (
              <DDSTypography.Paragraph
                size='para'
                variant='medium'
                className='text-center whitespace-nowrap'
                color={pallete.colors.textDark4}
              >
                {' '}
                -{' '}
              </DDSTypography.Paragraph>
            ) : (
              <UCResponseTimeGraph data={responseTimeData} />
            )}
          </div>
        )
      }
    },
    {
      columnHeaderStyle: { width: '5%' },
      columnDataStyle: { width: '5%' },
      heading: '',
      dataLabel: 'id',
      render: (id: any) => (
        <div className='cursor-pointer'>
          <Button
            id='right-btn'
            type='ghost'
            startIcon={
              <ChevronRight size={16} color={pallete.colors.primary2} />
            }
            onClick={() => {
              navigate(
                replaceUrl(
                  routeConstants.AHC_DASHBOARD,
                  store.scopeStore.getScope()['organization-id'] as string,
                  store.scopeStore.getScope()['project-id'] as string,
                  store.scopeStore.getScope()['environment-id'] as string
                ).replace(':id', id as string)
              )
            }}
          />
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '5%' },
      columnDataStyle: { width: '5%' },
      heading: '',
      dataLabel: 'key',
      render: (text: any, fullItem: any) => (
        <div className={`flex justify-center`}>
          <UCSummaryDropdown
            itemKey={text}
            id={fullItem.id}
            item={fullItem}
            setSelectedCheck={setSelectedCheck}
            setDeleteOpen={setDeleteOpen}
          />
        </div>
      )
    }
  ]

  return loading || summaryLoading || !summaryData || !uptimeData ? (
    <div className='flex flex-col h-[70vh]  self-stretch justify-center'>
      <Spinner />
    </div>
  ) : (
    <div className='flex flex-col gap-[24px] py-[24px]'>
      <div className='grid grid-cols-4 gap-[16px]'>
        {cardData.map((item) => (
          <div key={item.id}>
            <UCSummaryCard
              id={item.id}
              heading={item.heading}
              content={item.content}
              onClick={item.onClick}
              summary={true}
              disableChevron={item.disableChevron}
              selectedCardId={selectedCardId}
              selected={item.selected}
            />
          </div>
        ))}
      </div>
      <div
        style={{ backgroundColor: pallete.colors.stroke2 }}
        className={`h-[1px] w-full self-stretch`}
      />
      <div className='flex justify-end gap-[8px] items-center'>
        <div>
          <SideChip
            id='date-range-chip'
            label='Date Range'
            value={convertToFormat(dateFilter)}
          />
        </div>
        <div className='flex custom-filter override-filter-margin'>
          <Filter
            options={reportFilterOptions}
            hideFilterChips
            disabled={summaryData?.totalChecks === 0}
          />
        </div>
      </div>
      {uptimeData?.data?.length > 0 ? (
        <Table
          data={uptimeData?.data}
          columns={Columns}
          totalCount={uptimeData?.totalCount}
          rowsPerPage={5}
          currentPage={currentPage}
          onPageChange={(page) => {
            const allQueryParams = getAllQueryParamsAsObjectFromUrl(
              location.search
            )
            setCurrentPage(page)
            setSearchParams({ ...allQueryParams, page: page.toString() })
          }}
          loading={loading}
        />
      ) : (
        <NoRecord
          imageSrc={
            uptimeData?.totalCount === 0 &&
            store.filterStore.getFiltersApplied()
              ? NoFilterImage
              : NoRecordImage
          }
          style={{ height: '50vh' }}
          text={
            uptimeData?.totalCount === 0 &&
            store.filterStore.getFiltersApplied()
              ? StringConstants.NO_RECORDS_FILTER
              : UptimeStringConstants.ADD_CHECK_TO_GET_STARTED
          }
        />
      )}
      <UCDeleteCheckPopup
        open={deleteOpen}
        check={selectedCheck}
        buttonLoading={buttonLoading}
        setOpen={setDeleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onDelete={handleDeleteCheck}
      />
    </div>
  )
}

export default UCSummary
