import { CategoryScale, ChartArea, ChartData } from 'chart.js'
import React, { useEffect, useRef, useState } from 'react'

import { Chart } from 'react-chartjs-2'
import ChartJS from 'chart.js/auto'
import annotationPlugin from 'chartjs-plugin-annotation'
import palette from '../../global/pallete'

ChartJS.register(CategoryScale)
ChartJS.register(annotationPlugin)

const LineChart: React.FC<{ data: { x: string; y: number }[] }> = ({
  data = []
}) => {
  const createGradient = (ctx: CanvasRenderingContext2D, area: ChartArea) => {
    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top)
    gradient.addColorStop(0, palette.colors.white)
    gradient.addColorStop(1, palette.colors.primary4)
    return gradient
  }

  const chartRef = useRef<ChartJS>(null)
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    datasets: []
  })

  useEffect(() => {
    const chart = chartRef.current
    if (!chart) {
      return
    }

    // Keep all data points
    const chartData = {
      labels: data.map((item) => item.x),
      datasets: [
        {
          data: data.map((item) => item.y),
          tension: 0.7,
          borderColor: palette.colors.primary,
          pointBackgroundColor: palette.colors.white,
          pointRadius: 0,
          borderWidth: 1,
          fill: 'origin',
          backgroundColor: createGradient(chart.ctx, chart.chartArea)
        }
      ]
    }

    setChartData(chartData)
  }, [data])

  const graphOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          display: true,
          autoSkip: false,
          callback: (value: any, index: any) => {
            return data[index]?.x.trim() === '' ? '' : data[index]?.x
          },
          maxRotation: 0,
          minRotation: 0
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          display: true,
          autoSkip: true,
          maxTicksLimit: 5,
          precision: 0
        },
        grid: {
          display: false,
          drawBorder: false,
          color: palette.colors.borderColor,
          borderDash: [25, 25]
        }
      }
    },
    plugins: {
      tooltip: {
        enabled: true
      },
      legend: {
        display: false
      }
    }
  }

  return (
    <div style={{ maxHeight: '100%', width: '100%', height: '160px' }}>
      <Chart
        type='line'
        data={chartData}
        options={graphOptions}
        ref={chartRef}
      />
    </div>
  )
}

export default LineChart
