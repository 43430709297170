import { makeAutoObservable, observable } from 'mobx'

import { FilterTypes } from '../../enum/Filter.enum'
import { RootStore } from '../RootStore'
import StringConstants from '../../constants/StringConstants'
import { cloneDeep } from 'lodash'
import { getAllJobLogs } from '../../api/jobs/Jobs.service'
import { getFiltersForApi } from '../../utils/FilterUtils'

export interface JobLog {
  [key: string]: string | boolean
}

export class JobLogStore {
  jobLogs: JobLog[]
  totalCount: number
  currentPage: number
  initialJobLogCount: number
  loading: boolean
  constructor(private rootStore: RootStore) {
    this.jobLogs = []
    this.totalCount = 0
    this.currentPage = 1
    this.initialJobLogCount = 0
    this.loading = false
    makeAutoObservable(this, {
      jobLogs: observable.struct,
      loading: observable.struct
    })
  }

  setJobLogs(jobId: string, filters?: any) {
    this.setLoading(true)
    return new Promise((resolve, reject) => {
      const filter = getFiltersForApi(FilterTypes.JOB_LOG, cloneDeep(filters))
      getAllJobLogs({
        $jobId: jobId,
        size: StringConstants.DEFAULT_TABLE_SIZE,
        ...filter
      })
        .then((response: any) => {
          this.rootStore.jobLogStore.setAllJobLogs(response.data)
          this.rootStore.jobLogStore.setTotalCount(response.totalCount)
          this.rootStore.jobLogStore.setCurrentPage(response.page)
          this.rootStore.filterStore.setFilters(response.filters)
          if (response.totalCount === 0) this.currentPage = 1
        })

        .catch((err) => {
          console.error('Error in get all logs', err)
          reject(err)
        })
        .finally(() => this.setLoading(false))
    })
  }

  setInitialJobLogCount(initialJobLogCount: number) {
    this.rootStore.jobLogStore.initialJobLogCount = initialJobLogCount
  }

  getInitialJobLogCount() {
    return this.rootStore.jobLogStore.initialJobLogCount
  }

  setTotalCount(totalCount: number) {
    this.rootStore.jobLogStore.totalCount = totalCount
  }

  setAllJobLogs(jobLogs: JobLog[]) {
    this.rootStore.jobLogStore.jobLogs = jobLogs
  }

  getJobLogs(): JobLog[] {
    if (
      this.rootStore.jobLogStore.jobLogs.length > 0 ||
      this.rootStore.filterStore.getFiltersApplied()
    ) {
      this.rootStore.filterStore.setFilterDisabled(false)
    } else {
      this.rootStore.filterStore.setFilterDisabled(true)
    }
    return this.rootStore.jobLogStore.jobLogs
  }

  getTotalCount(): number {
    return this.rootStore.jobLogStore.totalCount
  }

  setCurrentPage(currentPage: number) {
    this.currentPage = currentPage
  }
  getCurrentPage() {
    return this.rootStore.jobLogStore.currentPage
  }

  setLoading(loading: boolean) {
    this.loading = loading
  }

  getLoading() {
    return this.rootStore.jobLogStore.loading
  }

  reset() {
    this.jobLogs = []
    this.totalCount = 0
    this.currentPage = 1
    this.loading = false
  }
}
