import React, { useState } from 'react'
import { isEmpty, isNull } from 'lodash'
import { toTitleCase, validateCronExpression } from '../../../utils/JobUtils'

import { Button } from '../../New/Button/Button'
import { DDSTypography } from 'den-design-system'
import { Information } from '@carbon/icons-react'
import Input from '../../New/Input/Input'
import JMStringConstants from '../../../constants/JMStringConstants'
import Modal from '../../common/DDS/Modal/Modal'
import MonitorTabSwitch from './MonitorTabSwitch'
import RegexConstants from '../../../constants/RegexConstants'
import Select from '../../New/Select/Select'
import StringConstants from '../../../constants/StringConstants'
import TextArea from '../../common/DDS/TextArea/TextArea'
import Tooltip from '../../New/Tooltip/Tooltip'
import ValidationConstants from '../../../constants/ValidationConstants'
import { describeCronExpression } from '../../../utils/CronDescribeUtils'
import palette from '../../../global/pallete'
import pallete from '../../../global/newPallete'
import { useStoreContext } from '../../../store/StoreContext'

interface addEditJobProps {
  open: boolean
  isAdd: boolean
  setOpen: any
  loading: boolean
  onSubmit: any
  onCancel: any
  buttonLoading: boolean
  buttonDisabled: boolean
  values: any
  isInvalidJobName: boolean
  isInvalidJobSlug: boolean
  fromThingsToDo?: boolean
  handleInputChange: (field: string, value: string) => void
  handleMonitorStart?: any
  handleMonitorStop?: any
}
const AddEditJobPopUp: React.FC<addEditJobProps> = ({
  open,
  isAdd,
  setOpen,
  loading,
  buttonLoading,
  buttonDisabled,
  onSubmit,
  onCancel,
  values,
  isInvalidJobName,
  isInvalidJobSlug,
  fromThingsToDo = false,
  handleInputChange,
  handleMonitorStart,
  handleMonitorStop
}) => {
  const [envId, setEnvId] = useState<boolean>(false)
  const store = useStoreContext()
  const jobNameHelperText =
    ValidationConstants.CREATE_JOB.JOB_NAME_NOT_AVAILABLE
  const jobSlugHelperText =
    ValidationConstants.CREATE_JOB.JOB_SLUG_NOT_AVAILABLE

  const getEnvironments = () => {
    const environments = store.scopeStore.getEnvironments()
    return environments.map((env) => ({
      label: (
        <DDSTypography.Paragraph
          variant='medium'
          size='caption'
          className='truncate flex items-start'
          color={pallete.colors.textDark4}
        >
          {env.type == StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
            ? StringConstants.ENVIRONMENT_NAMES.NON_PROD
            : StringConstants.ENVIRONMENT_NAMES.PROD}
        </DDSTypography.Paragraph>
      ),
      value: env.id
    }))
  }
  const AddEditFooter: React.FC = () => {
    return (
      <div className='flex override-btn-min-width justify-end px-[24px] py-[16px] gap-[10px]'>
        <Button
          id='cancel-modal-btn'
          type='neutral'
          label={JMStringConstants.CANCEL_BUTTON}
          disabled={buttonLoading}
          onClick={() => {
            setEnvId(false)
            onCancel()
          }}
        />
        <Button
          id='update-add-modal-btn'
          type='filled'
          disabled={
            (fromThingsToDo && !envId) ||
            buttonDisabled ||
            buttonLoading ||
            store.userStore.getNoActiveSubscription()
          }
          loading={buttonLoading}
          label={
            isAdd
              ? JMStringConstants.ADD_MONITOR_TITLE
              : JMStringConstants.EDIT_JOB_TITLE
          }
          onClick={onSubmit}
        />
      </div>
    )
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className={`${(loading || buttonLoading) && 'cursor-not-allowed'}`}
      showHeader
      title={
        isAdd
          ? JMStringConstants.ADD_MONITOR_TITLE
          : JMStringConstants.EDIT_JOB_TITLE
      }
      footer={<AddEditFooter />}
      onClosePress={() => null}
    >
      <div
        className={`${(loading || buttonLoading) && 'pointer-events-none'} flex flex-col gap-[24px] p-[24px]`}
      >
        <div className='flex gap-[24px] items-center'>
          {fromThingsToDo ? (
            <>
              <Select
                id='project-select'
                variant='top'
                label={JMStringConstants.ENVIRONMENT_LABEL}
                value={store.scopeStore.getSelectedProject().name}
                name='projects'
                options={[]}
                searchable={false}
                disabled={true}
                placeholder='Choose'
                onSelect={(value) => {
                  null
                }}
              />
              <Select
                id='environment-select'
                variant='top'
                label={JMStringConstants.ENVIRONMENT_LABEL}
                value={store.scopeStore.getEnvironmentName(
                  store.scopeStore.getEnvironmentId()
                )}
                name='environments'
                options={getEnvironments()}
                searchable={false}
                disabled={store.uiStore.getGlobalLoader()}
                placeholder='Choose'
                onSelect={(value) => {
                  setEnvId(true)
                  const environment = store.scopeStore
                    .getEnvironments()
                    .filter((record: any) => record.id == value)
                  store.scopeStore.setSelectedEnvironment(environment[0])
                  store.scopeStore.setEnvironmentId(value)
                }}
              />
            </>
          ) : (
            <>
              <div className='opacity-50 cursor-not-allowed'>
                <div className='pointer-events-none'>
                  <Input
                    id='project-input'
                    labelPosition='top'
                    name='Project'
                    label={JMStringConstants.PROJECT_LABEL}
                    value={store.scopeStore.getSelectedProject()?.name}
                    onChange={() => null}
                  />
                </div>
              </div>
              <div className='opacity-50 cursor-not-allowed'>
                <div className='pointer-events-none'>
                  <Input
                    id='environment-input'
                    labelPosition='top'
                    name='Environment'
                    label={JMStringConstants.ENVIRONMENT_LABEL}
                    value={
                      store.scopeStore.getSelectedEnvironment()?.type ==
                      StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
                        ? StringConstants.ENVIRONMENT_NAMES.NON_PROD
                        : StringConstants.ENVIRONMENT_NAMES.PROD
                    }
                    onChange={() => null}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className={`flex gap-[24px] ${!isInvalidJobName && !isInvalidJobSlug && 'mb-[4px]'} items-stretch`}
        >
          <div
            className={`flex flex-col max-w-[290px] justify-between gap-[4px]`}
          >
            <Input
              id='job-name-input'
              labelPosition='top'
              name='Job Name'
              maxLength={30}
              validateInput
              validationRegex={new RegExp(RegexConstants.NAME_REGEX)}
              errorMessage={`${values.name === '' ? ValidationConstants.CREATE_JOB.JOB_NAME : 'Please enter a valid ' + JMStringConstants.JOB_NAME_LABEL.toLowerCase()}`}
              label={JMStringConstants.JOB_NAME_LABEL}
              value={values.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
            />
            {isInvalidJobName && jobNameHelperText && (
              <DDSTypography.Paragraph
                variant='regular'
                size='caption'
                color={pallete.colors.warningRed2}
              >
                {jobNameHelperText}
              </DDSTypography.Paragraph>
            )}
          </div>
          <div className={`flex flex-col max-w-[290px] justify-between`}>
            <Input
              id='job-slug-input'
              labelPosition='top'
              name='Job Slug'
              maxLength={30}
              validationRegex={new RegExp(RegexConstants.SLUG_REGEX)}
              validateInput
              errorMessage={`${values.slug === '' ? ValidationConstants.CREATE_JOB.JOB_SLUG : ValidationConstants.CREATE_JOB.JOB_SLUG_INVALID}`}
              label={JMStringConstants.JOB_SLUG_LABEL}
              value={values.slug}
              onChange={(e) => handleInputChange('slug', e.target.value)}
            />
            {isInvalidJobSlug && jobSlugHelperText && (
              <DDSTypography.Paragraph
                variant='regular'
                size='caption'
                color={pallete.colors.warningRed2}
              >
                {jobSlugHelperText}
              </DDSTypography.Paragraph>
            )}
          </div>
        </div>

        <Input
          id='cron-input'
          labelPosition='top'
          width='605px'
          name='Cron Expression'
          label={
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='flex items-center mr-[4px]'
              color={pallete.colors.textDark3}
            >
              {JMStringConstants.CRON_LABEL}{' '}
            </DDSTypography.Paragraph>
          }
          validateInput
          validationRegex={new RegExp(RegexConstants.CRON_EXPRESSION)}
          errorMessage={`${values.cronExpression === '' ? toTitleCase(JMStringConstants.CRON_LABEL) + ' should not be empty' : 'Please enter a valid ' + JMStringConstants.CRON_LABEL.toLowerCase()}`}
          value={values.cronExpression}
          labelIcon={
            <Tooltip
              id='cron-describe-tooltip'
              className={`max-w-[300px] ${typeof values.cronExpression === 'string' && values.cronExpression.length > 0 && validateCronExpression(values.cronExpression) ? values.cronExpression : '!hidden'}`}
              label={describeCronExpression(values.cronExpression)}
            >
              <Information color={pallete.colors.textDark4} />
            </Tooltip>
          }
          showLabelIcon
          onChange={(e) => handleInputChange('cronExpression', e.target.value)}
        />
        <div className='flex flex-col gap-[8px]'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark3}
          >
            {JMStringConstants.DESCRIPTION_LABEL}
          </DDSTypography.Paragraph>
          <TextArea
            id='description-input'
            type='secondary'
            maxLength={150}
            style={{
              height: '72px',
              maxHeight: '72px',
              color: pallete.colors.textDark3,
              fontSize: '14px',
              fontWeight: 400
            }}
            showButtons={false}
            value={values.description}
            onChange={(value) => handleInputChange('description', value)}
          />
        </div>
        {!isAdd && (
          <div className='flex justify-start items-start'>
            <div className='flex flex-col gap-[12px]'>
              <DDSTypography.Paragraph
                size='para'
                variant='medium'
                color={pallete.colors.textDark3}
              >
                {JMStringConstants.MONITORING_LABEL}
              </DDSTypography.Paragraph>
              <MonitorTabSwitch
                isStart={values.monitorJob}
                onStopClick={handleMonitorStop}
                onStartClick={handleMonitorStart}
                disabled={buttonLoading}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default AddEditJobPopUp
