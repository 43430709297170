import { isArray, isEmpty, isUndefined } from 'lodash'

import UrlConstants from '../constants/UrlConstants'

const getParamsFromUrl = (urlSearchParams: string, param: string) => {
  return new URLSearchParams(urlSearchParams).get(param)
}

const getAllParamsFromUrl = (urlSearchParams: string, param: string) => {
  return new URLSearchParams(urlSearchParams).getAll(param)
}

const getAllParamsAsStringFromUrl = (
  urlSearchParams: any,
  omitList: string[]
) => {
  const entries = new URLSearchParams(urlSearchParams).entries()
  let result = ''
  let index = 0
  for (const entry of entries) {
    if (omitList.includes(entry[0])) continue
    if (index === 0) result += '?'
    else result += '&'
    result += entry[0] + '=' + entry[1]
    index = index + 1
  }
  return result
}

const getAllParamsAsObjectsFromUrl = (
  urlSearchParams: Record<string, string>,
  omitList: string[]
) => {
  const entries = new URLSearchParams(urlSearchParams).entries()
  let result = ''
  let index = 0

  let solution: any = {}
  for (const entry of entries) {
    if (omitList.includes(entry[0])) continue
    if (index === 0) result += '?'
    else result += '&'
    result += entry[0] + '=' + entry[1]
    if (Object.keys(solution).includes(entry[0])) {
      solution = { ...solution, [entry[0]]: [...solution[entry[0]], entry[1]] }
    }
    solution = { ...solution, [entry[0]]: entry[1] }
    index = index + 1
  }
  return solution
}

const getEntriesFromUrl = (urlSearchParams: string) => {
  return new URLSearchParams(urlSearchParams).entries()
}

const getAllQueryParamsAsObjectFromUrl = (
  urlSearchParams: any,
  omitList = ['']
) => {
  const entries = new URLSearchParams(urlSearchParams).entries()
  const queryObject: any = {}
  for (const entry of entries) {
    if (omitList.includes(entry[0])) continue
    if (isUndefined(queryObject[entry[0]])) {
      queryObject[entry[0]] = entry[1]
    } else if (isArray(queryObject[entry[0]])) {
      queryObject[entry[0]].push(entry[1])
    } else {
      const array = []

      array.push(queryObject[entry[0]])
      array.push(entry[1])
      queryObject[entry[0]] = array
    }
  }
  return queryObject
}

const replaceUrl = (
  route: string,
  orgId: string,
  projId?: string,
  envId?: string
) => {
  if (!isEmpty(projId) && !isEmpty(envId)) {
    return route.replace(
      ':pathIds',
      '/' + BtoA(orgId + '/' + projId + '/' + envId)
    )
  } else {
    return route.replace(':pathIds', '/' + BtoA(orgId))
  }
}

const replaceBadgeUrl = (route: string, encryptedId: string) => {
  return route.replace(':encryptedId', encryptedId)
}

const UrlWithOrgProjEnvIds = (route: string, store: any) => {
  return replaceUrl(
    route,
    store.scopeStore.getOrganizationId() as string,
    store.scopeStore.getScope()['project-id'] as string,
    store.scopeStore.getScope()['environment-id'] as string
  )
}

const UrlWithOrgProjIds = (route: string, store: any) => {
  return route
    .replace(
      ':pathIds',
      ('/' + BtoA(store.scopeStore.getOrganizationId())) as string
    )
    .replace(':projectId', store.scopeStore.getScope()['project-id'] as string)
}

const UrlWithOnlyOrgId = (route: string, store: any) => {
  return replaceUrl(
    route,
    store.scopeStore.getOrganizationId() as string,
    '',
    ''
  )
}

const UrlForSalesforceLogin = (isLogin: boolean, token?: string) => {
  return `https://login.salesforce.com/services/oauth2/authorize?client_id=${
    process.env.REACT_APP_CLIENT_ID
  }&redirect_uri=${window.location.origin}${
    UrlConstants.REDIRECT_URI_SALESFORCE_SSO.URL
  }&response_type=code&state=${JSON.stringify({
    token: token,
    isLogin: isLogin
  })}`
}

const UrlForSalesforceConnect = (
  instanceUrl: string,
  orgId: string,
  projId: string,
  envId: string
) => {
  const headerObj = {
    instanceUrl: instanceUrl,
    organizationId: orgId,
    projectId: projId,
    environmentId: envId
  }
  return `${instanceUrl}/services/oauth2/authorize?client_id=${
    process.env.REACT_APP_CLIENT_ID
  }&redirect_uri=${window.location.origin}${
    UrlConstants.REDIRECT_URI_SALESFORCE_CONNECT.URL
  }&response_type=code&state=${BtoA(JSON.stringify(headerObj))}`
}

const AtoB = (route: string) => {
  return window.atob(route)
}

const BtoA = (route: string) => {
  return window.btoa(route)
}
export {
  AtoB,
  BtoA,
  replaceUrl,
  replaceBadgeUrl,
  UrlWithOrgProjEnvIds,
  UrlWithOnlyOrgId,
  UrlWithOrgProjIds,
  UrlForSalesforceLogin,
  UrlForSalesforceConnect,
  getParamsFromUrl,
  getAllParamsAsStringFromUrl,
  getAllParamsAsObjectsFromUrl,
  getAllParamsFromUrl,
  getEntriesFromUrl,
  getAllQueryParamsAsObjectFromUrl
}
