import {
  debouncedExceptionSearchContextsAPI,
  getAllExceptionEscalationSets,
  getExceptionEscalationSetById
} from '../api/exception/Exception.service'
import { makeAutoObservable, observable } from 'mobx'

import { RootStore } from './RootStore'

export const searchContextsLookupforExceptions: Record<
  string,
  {
    context: string
    label: string
    query: string
  }
> = {
  exception_type: {
    context: 'exception_type',
    label: 'Exception Type',
    query: 'exception-type-like'
  },
  exception_message: {
    context: 'exception_message',
    label: 'Exception Message',
    query: 'exception-message-like'
  }
}

export const SearchContextMapForExceptions = {
  'exception-type-like': 'exception_type',
  'exception-message-like': 'exception_message'
}
export class ExceptionStore {
  id: string
  type: string
  bookmark: boolean
  message: string
  severity: string
  status: string
  logData: any
  activityLogs: any
  loading: boolean
  activeTab: number
  tabSwitch: boolean
  statisticsData: any
  stockChartData: any
  isPublic: boolean
  isRefresh: boolean
  searchContexts: string[]
  searchContextsLoading: boolean
  searchQuery: string
  callExceptionFilter: boolean
  selectedContexts: any[]
  selectedExceptionGroup: any
  escalationSets: any[]
  activeEscalationSet: any
  escalationLoading: boolean
  isRefreshed: boolean
  isDownloadAsCsv: boolean
  currentEscalationName: string
  constructor(private rootStore: RootStore) {
    this.id = ''
    this.type = ''
    this.bookmark = false
    this.message = ''
    this.severity = ''
    this.status = ''
    this.logData = {}
    this.activityLogs = {}
    this.loading = false
    this.activeTab = 0
    this.tabSwitch = true
    this.statisticsData = {}
    this.stockChartData = []
    this.isPublic = false
    this.isRefresh = false
    this.searchContexts = []
    this.searchContextsLoading = false
    this.searchQuery = ''
    this.callExceptionFilter = false
    this.selectedContexts = []
    this.selectedExceptionGroup = {}
    this.escalationSets = []
    this.escalationLoading = false
    this.activeEscalationSet = {}
    this.isRefreshed = false
    this.isDownloadAsCsv = false
    this.currentEscalationName = ''
    makeAutoObservable(this, {
      logData: observable.struct,
      loading: observable.struct,
      statisticsData: observable.struct,
      stockChartData: observable.struct,
      isRefreshed: observable.struct,
      isDownloadAsCsv: observable.struct,
      currentEscalationName: observable.struct,
      activeEscalationSet: observable.struct
    })
  }
  setActivityLogs(data: any) {
    this.activityLogs = data
    this.loading = false
  }
  getActivityLogs() {
    return this.activityLogs
  }
  getLogData() {
    return this.rootStore.exceptionStore.logData
  }
  setLogData(data: any) {
    this.logData = data
  }

  getId() {
    return this.id
  }
  setId(id: string) {
    this.id = id
  }
  getType() {
    return this.type
  }
  setType(type: string) {
    this.type = type
  }
  setLoading(load: boolean) {
    this.loading = load
  }
  getLoading() {
    return this.loading
  }
  setActiveTab(tab: number) {
    this.rootStore.exceptionStore.activeTab = tab
  }
  getActiveTab() {
    return this.rootStore.exceptionStore.activeTab
  }
  getTitle() {
    return this.getHeaderData().titles[this.getActiveTab()]
  }

  setRefresh(isRefresh: boolean) {
    this.rootStore.apiDetailsStore.isRefresh = isRefresh
  }
  getRefresh() {
    return this.rootStore.apiDetailsStore.isRefresh
  }

  getHeaderData() {
    return this.exceptionHeaderData
  }

  setTabSwitch(tabSwitch: boolean) {
    this.rootStore.exceptionStore.tabSwitch = tabSwitch
  }
  getTabSwitch() {
    return this.tabSwitch
  }

  getIsPublic() {
    return this.isPublic
  }
  setIsPublic(isPublic: boolean) {
    this.isPublic = isPublic
  }

  async getSearchContextsFromAPI(query: string, queryParams: any) {
    this.setSearchContexts([])
    if (query.trim().length < 3) return
    this.setSearchContextsLoading(true)
    try {
      const { data } = await debouncedExceptionSearchContextsAPI(
        query,
        queryParams
      )
      this.setSearchContexts(data)
      this.rootStore.exceptionStore.searchQuery = query
    } catch (e) {
      console.log(e)
    } finally {
      this.setSearchContextsLoading(false)
    }
  }
  getSearchQuery() {
    return this.rootStore.exceptionStore.searchQuery
  }
  setSearchContexts(contexts: string[]) {
    this.rootStore.exceptionStore.searchContexts = contexts
  }
  getSearchContexts() {
    return this.rootStore.exceptionStore.searchContexts.map((context) => ({
      id: searchContextsLookupforExceptions[context].query,
      label: `${
        searchContextsLookupforExceptions[context].label
      } : ${this.getSearchQuery()}`
    }))
  }
  setSearchContextsLoading(loading: boolean) {
    this.rootStore.exceptionStore.searchContextsLoading = loading
  }
  getSearchContextsLoading() {
    return this.rootStore.exceptionStore.searchContextsLoading
  }
  setStockChartData(stockChartData: any) {
    this.stockChartData = stockChartData
  }
  getStockChartData() {
    return this.stockChartData
  }
  setStatisticsData(statisticsData: any) {
    this.statisticsData = statisticsData
  }
  getStatisticsData() {
    return this.statisticsData
  }

  setCallExceptionFilter(callExceptionFilter: boolean) {
    this.callExceptionFilter = callExceptionFilter
  }
  getCallExceptionFilter() {
    return this.callExceptionFilter
  }
  setSelectedContexts(contexts: any[]) {
    this.rootStore.exceptionStore.selectedContexts = contexts
  }

  getSelectedContexts() {
    return this.rootStore.exceptionStore.selectedContexts
  }

  setSelectedExceptionGroup(selectedexceptionGroup: any) {
    this.rootStore.exceptionStore.selectedExceptionGroup =
      selectedexceptionGroup
  }

  getSelectedExceptionGroup() {
    return this.rootStore.exceptionStore.selectedExceptionGroup
  }

  setEscalationSets(escalationSets: any[]) {
    this.rootStore.exceptionStore.escalationSets = escalationSets
  }
  setActiveEscalationSet(escalationSet: any) {
    this.rootStore.exceptionStore.activeEscalationSet = escalationSet
  }
  getActiveEscalationSet() {
    return this.rootStore.exceptionStore.activeEscalationSet
  }
  getEscalationLoading() {
    return this.rootStore.exceptionStore.escalationLoading
  }
  setEscalationLoading(loading: boolean) {
    this.rootStore.exceptionStore.escalationLoading = loading
  }
  getEscalationSets() {
    return this.rootStore.exceptionStore.escalationSets
  }
  getCurrentEscalationName() {
    return this.currentEscalationName
  }
  setCurrentEscalationName(value: string) {
    this.currentEscalationName = value
  }
  async getEscalationSetsFromAPI() {
    this.setEscalationLoading(true)
    try {
      const { data } = await getAllExceptionEscalationSets()
      this.setEscalationSets(data)
    } finally {
      this.setTabSwitch(false)
      this.setEscalationLoading(false)
    }
  }
  async getEscalationSetFromAPI(id = '') {
    this.setEscalationLoading(true)
    try {
      const { data } = await getExceptionEscalationSetById(id)
      this.setActiveEscalationSet(data)
      this.setCurrentEscalationName(data.escalationSetName)
    } finally {
      this.setEscalationLoading(false)
    }
  }

  reset() {
    this.id = ''
    this.type = ''
    this.bookmark = false
    this.message = ''
    this.severity = ''
    this.status = ''
    this.logData = {}
    this.activityLogs = {}
    this.loading = false
    this.statisticsData = {}
    this.stockChartData = []
    this.isPublic = false
    this.callExceptionFilter = false
    this.escalationSets = []
    this.escalationLoading = false
    this.activeEscalationSet = {}
  }

  getIsRefreshed() {
    return this.isRefreshed
  }
  setIsRefreshed(value: boolean) {
    this.isRefreshed = value
  }

  getIsDownloadAsCsv() {
    return this.isDownloadAsCsv
  }
  setIsDownloadCsv(value: boolean) {
    this.isDownloadAsCsv = value
  }
  //tabs
  exceptionHeaderData = {
    tabs: [
      { id: 0, label: 'Grouped' },
      { id: 1, label: 'All' }
    ],
    titles: ['Grouped Exceptions', 'All Exceptions']
  }
}
