import React, { useState } from 'react'

import { Copy } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import { copyJsonToClipboard } from '../../../utils/CommonUtils/CopyUtils'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import { useStoreContext } from '../../../store/StoreContext'

interface data {
  id: string
  heading: string
  content: string
}
interface APIOverviewTableData {
  tableData: data[]
}

const APIOverviewTable: React.FC<APIOverviewTableData> = ({ tableData }) => {
  const [copy, setCopy] = useState<string | null>(null)
  const store = useStoreContext()

  return (
    <div className='w-full mx-auto'>
      <div className='bg-white override-custom-table'>
        <table className='w-full'>
          {tableData.map((data, index) => (
            <tr key={index}>
              <td
                style={{ backgroundColor: pallete.colors.surface6 }}
                className='w-[15%]'
              >
                <div style={{ backgroundColor: pallete.colors.surface6 }}>
                  <DDSTypography.Paragraph
                    size='caption'
                    variant='semiBold'
                    color={pallete.colors.textDark6}
                  >
                    {data.heading}
                  </DDSTypography.Paragraph>
                </div>
              </td>
              <td className='w-[85%]'>
                <div className='flex justify-center items-center'>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark4}
                    style={{ alignSelf: 'center' }}
                  >
                    {data.content}
                  </DDSTypography.Paragraph>
                  <div
                    className={`${store.userStore.getNoActiveSubscription() && 'cursor-not-allowed pointer-events-none opacity-50'} ml-auto cursor-pointer`}
                    onClick={() => {
                      copyJsonToClipboard(data.content, data.id, 2000, setCopy)
                    }}
                  >
                    {copy === data.id ? (
                      <span role='img' aria-label='copied'>
                        ✅
                      </span>
                    ) : (
                      <Copy size={16} color={pallete.colors.textDark4} />
                    )}
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  )
}

export default observer(APIOverviewTable)
