import { DDSButton, DDSTypography } from 'den-design-system'
import React, { useState } from 'react'

import { ChevronDown } from '@carbon/icons-react'
import DropdownMenu from '../../New/DropdownMenu/DropdownMenu'
import { IncidentPriority } from '../../../constants/IncidentPriorityConstants'
import palette from '../../../global/pallete'
import { useStoreContext } from '../../../store/StoreContext'

interface PriorityDropDownProps {
  label: string
  onSelect: any
  tableHeight?: any
  arrowPosition?: 'left' | 'right' | 'center'
  disabled?: boolean
}
const IncidentPriorityDropDown: React.FC<PriorityDropDownProps> = ({
  label,
  onSelect,
  tableHeight,
  arrowPosition = 'center',
  disabled
}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [menuPosition, setMenuPosition] = useState<'above' | 'below'>('below')
  const dropDownRef = React.useRef<HTMLDivElement>(null)
  const store = useStoreContext()
  React.useEffect(() => {
    if (dropDownRef.current) {
      const dropdownRect = dropDownRef.current.getBoundingClientRect()
      const windowHeight = window.innerHeight
      if (dropdownRect.bottom + 200 > windowHeight) {
        setMenuPosition('above')
      } else {
        setMenuPosition('below')
      }
    }
  }, [])

  React.useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        menuOpen &&
        dropDownRef.current &&
        !dropDownRef.current.contains(e.target)
      ) {
        setMenuOpen(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [menuOpen])

  const getArrowPosition = () => {
    return arrowPosition === 'right'
      ? 'translate-x-[5%]'
      : arrowPosition === 'left'
        ? 'translate-x-[5%]'
        : 'justify-center'
  }

  return (
    <div
      ref={dropDownRef}
      className={`flex flex-col justify-center  ${disabled ? 'cursor-not-allowed' : 'pointer'} `}
    >
      <DDSButton
        id='menu-list-btn'
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
        type='filled'
        shape='roundedRectangle'
        className='!justify-between w-[108px] !h-[26px] !py-[4px] !px-[12px]'
        style={{
          ...IncidentPriority.find((priority: any) => priority.label === label)
            ?.styles
        }}
        disabled={disabled}
        label={
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={palette.colors.white}
          >
            {label}
          </DDSTypography.Paragraph>
        }
        endIcon={
          <ChevronDown
            id='menu-list-btn'
            style={{
              fontSize: 16
            }}
          />
        }
      />

      <div
        className={`absolute ${disabled ? 'cursor-not-allowed' : 'pointer'} `}
      >
        <DropdownMenu
          id='dropdown'
          open={menuOpen}
          setOpen={setMenuOpen}
          arrowPosition='center'
          parentId='menu-list-btn'
          className={`absolute z-50  top-[28px] override-width-dropdown -translate-x-[12px] -translate-y-[10px] ${
            menuPosition === 'above' ? 'translate-y-[-188px]' : ''
          }`}
          onClose={() => setMenuOpen(false)}
        >
          <div
            className={`flex flex-col gap-y-[12px] ${store.userStore.getNoActiveSubscription() && 'pointer-events-none opacity-50'}`}
          >
            {IncidentPriority.map((priority, index) => (
              <DDSButton
                type='filled'
                key={index}
                label={
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={palette.colors.white}
                  >
                    {priority.label}
                  </DDSTypography.Paragraph>
                }
                className='w-[108px] !h-[24px] !rounded-md'
                style={{
                  ...priority.styles
                }}
                onClick={() => {
                  onSelect(priority.value)
                  setMenuOpen(false)
                }}
              />
            ))}
          </div>
        </DropdownMenu>
      </div>
    </div>
  )
}

export default IncidentPriorityDropDown
