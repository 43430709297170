import React, { useEffect } from 'react'

import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'

//TODO will change to location.search if necessary

const GAComponent = () => {
  const location = useLocation()
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [location.pathname])
  return <></>
}
export default GAComponent
