import { DDSButton, DDSModal, DDSTypography } from 'den-design-system'

import { Button } from '../../../components/New/Button/Button'
import React from 'react'
import { SetStateAction } from 'react'
import pallete from '../../../global/newPallete'

interface ModalFooterProps {
  primaryButtonText?: string
  secondaryButtonText?: string
  onYes?: () => any
  onNo?: () => any
  primaryButtonDisabled?: boolean
  secondaryButtonDisabled?: boolean
  acceptLoading?: boolean
  rejectLoading?: boolean
}
const ModalFooter: React.FC<ModalFooterProps> = ({
  onYes,
  onNo,
  primaryButtonText = '',
  secondaryButtonText = '',
  primaryButtonDisabled,
  secondaryButtonDisabled,
  rejectLoading,
  acceptLoading
}) => {
  return (
    <div className='flex flex-row gap-x-[10px] px-[24px] py-[16px] override-btn-min-width justify-end'>
      {secondaryButtonText !== '' && (
        <Button
          id='secondary-btn'
          label={secondaryButtonText}
          type='neutral'
          size='small'
          disabled={rejectLoading || secondaryButtonDisabled}
          loading={rejectLoading}
          onClick={() => (onNo ? onNo() : undefined)}
        />
      )}
      {primaryButtonText !== '' && (
        <div className='override-btn-min-width'>
          <Button
            id='primary-button'
            size='small'
            disabled={acceptLoading || primaryButtonDisabled}
            loading={acceptLoading}
            type='filled'
            label={primaryButtonText}
            onClick={() => (onYes ? onYes() : undefined)}
          />
        </div>
      )}
    </div>
  )
}

interface InvitePopupProp {
  modalOpen: boolean
  setModalOpen: React.Dispatch<SetStateAction<boolean>>

  content: React.ReactNode
  showFooter?: boolean
  primaryButtonClick?: any
  secondaryButtonClick?: any
  primaryButtonText?: string
  secondaryButtonText?: string
  primaryButtonDisabled?: boolean
  secondaryButtonDisabled?: boolean
  loading?: boolean
  title?: string
  acceptLoading?: boolean
  rejectLoading?: boolean
  showHeader: boolean
}
export const InvitePopup: React.FC<InvitePopupProp> = ({
  modalOpen,
  setModalOpen,
  content,
  primaryButtonClick,
  secondaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  primaryButtonDisabled,
  secondaryButtonDisabled,
  showFooter = true,
  showHeader,
  title = '',
  loading,
  rejectLoading,
  acceptLoading
}) => {
  return (
    <DDSModal
      open={modalOpen}
      setOpen={setModalOpen}
      defaultFooter={false}
      onIconClose={() => setModalOpen(false)}
      hideCloseIcon={false}
    >
      <div>
        {showHeader && (
          <div
            className='flex justify-between items-center px-[24px] py-[16px] border-b'
            style={{ borderBottomColor: pallete.colors.stroke2 }}
          >
            <DDSTypography.Title
              variant='bold'
              type='h4'
              className='!leading-6'
              color={pallete.colors.textDark4}
            >
              {title}
            </DDSTypography.Title>
          </div>
        )}
        <div
          className='flex flex-col overflow-y-scroll border-b'
          style={{ borderBottomColor: pallete.colors.stroke2 }}
        >
          {content}
        </div>

        {showFooter && (
          <ModalFooter
            onYes={primaryButtonClick}
            onNo={secondaryButtonClick}
            acceptLoading={acceptLoading}
            rejectLoading={rejectLoading}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            primaryButtonDisabled={primaryButtonDisabled}
            secondaryButtonDisabled={secondaryButtonDisabled}
          />
        )}
      </div>
    </DDSModal>
  )
}
