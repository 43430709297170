import ApiManager from '../Api.manager'
import { CreateProjectPayload } from './Project.types'
import { FilterTypes } from '../../enum/Filter.enum'
import HttpConstants from '../../constants/HttpConstants'
import { UpdateProject } from './Project.types'
import UrlConstants from '../../constants/UrlConstants'
import { getFiltersForApi } from '../../utils/FilterUtils'

export const getProjectAndEnvironment = () => {
  const usecase = UrlConstants.GET_PROJECT_AND_ENVIRONMENT.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      {}
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in $(usecase):`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const getProjectById = (projectId: string) => {
  const usecase: string = UrlConstants.FIND_PROJECT.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      { projectId }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase} :: `, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const updateProject = (payload: UpdateProject) => {
  const usecase: string = UrlConstants.UPDATE_PROJECT.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const deleteProject = (projectId: string) => {
  const usecase: string = UrlConstants.DELETE_PROJECT.USECASE

  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.DELETE_METHOD,
      { projectId }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}
/**
 * Service function to create project
 * @param {CreateProjectPayload} payload
 */
export const CreateProject = (payload: CreateProjectPayload) => {
  const usecase = UrlConstants.CREATE_PROJECT.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201) resolve(response.data)
        else reject(response)
      })
      .catch((error) => {
        console.log(`Error in ${usecase} :: `, error)
        reject(error.data.message)
      })
  })
}

export const getAllProjects = (queryParams: any) => {
  const usecase = UrlConstants.FIND_PROJECTS.USECASE
  const filters = getFiltersForApi(FilterTypes.PROJECT, queryParams)
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      filters
    )
      .then((response: any) => {
        if (response.status === 200) resolve(response.data)
        else reject(response)
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const getProjectFilters = () => {
  const usecase = UrlConstants.GET_PROJECT_FILTER.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      {}
    )
      .then((response: any) => {
        if (response.status === 200) resolve(response.data)
        else reject(response)
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const connectSlackToProject = (code: string, state: string) => {
  const url: string = UrlConstants.CONNECT_PROJECT_TO_SLACK.USECASE
  const params: { code: string; state: string; redirect_uri?: string } = {
    code,
    state
  }
  if (process.env.REACT_APP_SLACK_REDIRECT_URI)
    params['redirect_uri'] = process.env.REACT_APP_SLACK_REDIRECT_URI
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      url,
      HttpConstants.GET_METHOD,
      params
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${url}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}
export const disconnectSlackFromProject = (projectId: string) => {
  const url: string = UrlConstants.DISCONNECT_PROJECT_TO_SLACK.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(url, HttpConstants.DELETE_METHOD, {
      projectId
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${url}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const getAllProjectMembers = (projectId: string) => {
  const usecase: string = UrlConstants.FIND_ALL_MEMBERS_OF_PROJECT.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      { projectId }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase} :: `, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}
