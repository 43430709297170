import React, { useState } from 'react'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'
import { isEmpty, isUndefined } from 'lodash'

import Button from '../../common/Button/Button'
import CreatedOrg from '../CreatedOrg/CreatedOrg'
import InputField from '../../common/InputField/InputField'
import { IoIosArrowForward } from 'react-icons/io'
import Joi from 'joi'
import RegexConstants from '../../../constants/RegexConstants'
import StringConstants from '../../../constants/StringConstants'
import { TextContentConstants } from '../../../constants/TextContentConstants'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import { TypographyDDS } from '../../common/Typography/TypographyDDS'
import UrlConstants from '../../../constants/UrlConstants'
import ValidationConstants from '../../../constants/ValidationConstants'
import breakpoints from '../../../global/breakpoints'
import { checkNameAvailability } from '../../../api/common/Common.service'
import { createOrganization } from '../../../api/organization/Organization.service'
import { getUserDetails } from '../../../api/user/User.service'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useSearchParams } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

interface CreateOrgProps {
  handleTabChange: () => void
}

interface ItemContainerProps {
  width?: string
  height?: string
  padding?: string
  margin?: string
}

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.9rem;
  }

  @media (max-width: ${breakpoints.md}) {
    font-size: 0.8rem;
  }
  @media (max-width: ${breakpoints.md}) {
    & > * {
      flex: 1 100%;
    }
    justify-content: center;
    align-content: center;
  }
  text-align: center;
  padding: 0 1.25em 3.4em 1.25em;
  grid-gap: 2.5em;
`

const DescriptionSection = styled.div`
  @media screen and (min-width: ${breakpoints.md}) {
    flex: 1;
  }
`

const FormSection = styled.div`
  @media screen and (min-width: ${breakpoints.md}) {
    flex: 1;
  }
`

const PopUpWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: ${palette.colors.white};
`

const ItemContainer = styled.div<ItemContainerProps>`
  width: ${(props) => (props.width ? props.width : '22.875em')};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};

  @media screen and (max-width: ${breakpoints.lg}) {
    width: 100%;
  }
`

const CreateOrg: React.FC<CreateOrgProps> = ({ handleTabChange }) => {
  const [conformationPromptOpen, setConformationPromptOpen] =
    React.useState(false)
  const [name, setOrganizationName] = React.useState('')
  const schema = Joi.string().pattern(RegexConstants.NAME_REGEX).required()
  const storeContext = useStoreContext()

  const [loading, setLoading] = React.useState(false)
  // const navigate = useNavigate()
  const [organizationErrorText, setOrganizationErrorText] = useState('')

  const [searchParams, setSearchParams] = useSearchParams()

  const handleCheckNameAvailability = () => {
    setLoading(true)
    const validationResult = schema.validate(name)

    if (isUndefined(validationResult.error)) {
      setOrganizationErrorText('')
      checkNameAvailability(UrlConstants.CHECK_ORGANIZATION_NAME.USECASE, name)
        .then(() => {
          setOrganizationErrorText('')
          setConformationPromptOpen(true)
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          setOrganizationErrorText(
            ValidationConstants.ORGANIZATION.ORGANIZATION_NAME_ERROR
          )
          console.log(err)
        })
    } else {
      if (validationResult.error.details[0].type === 'string.pattern.base')
        setOrganizationErrorText(
          ValidationConstants.ORGANIZATION.ORGANIZATION_NAME_INVALID
        )
      else
        setOrganizationErrorText(
          ValidationConstants.ORGANIZATION.ORGANIZATION_NAME_EMPTY
        )

      setLoading(false)
    }
  }
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.CREATE_ORGANIZATION
  )

  const handleOrganizationCreate = () => {
    createOrganization({
      name,
      chargebeePlanId: (searchParams.get('plan-id') ||
        process.env.REACT_APP_DEFAULT_SUBSCRIPTION_PLAN_CHARGEBEE_ID) as string
    })
      .then((response: any) => {
        storeContext.scopeStore.setOrganizationId(response.data.id)

        storeContext.scopeStore.setProjectId('')
        storeContext.scopeStore.setEnvironmentId('')
        storeContext.userStore.setSelectedOrganization(response.data)
        ToastNotification({
          type: 'success',
          message:
            ToastMessageConstants.ORGANIZATION.CREATE_ORGANIZATION.SUCCESS
        })
        getUserDetails()
          .then((userResponse: any) => {
            storeContext.userStore.setOrganizations(
              userResponse.data.organizations
            )
            handleTabChange()
          })
          .catch((err) =>
            ToastNotification({
              type: 'error',
              message: ToastMessageConstants.SOMETHING_WENT_WRONG
            })
          )
      })
      .catch((err) => {
        console.log(err)
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.ORGANIZATION.CREATE_ORGANIZATION.ERROR
        })
      })
  }

  return (
    <Container>
      <DescriptionSection>
        <TypographyDDS.Title
          type='h1'
          variant='medium'
          style={{
            paddingBottom: '10px',
            maxWidth: '22.875em',
            textAlign: 'left'
          }}
        >
          Create a New
          <br />
          <span
            style={{
              fontWeight: palette.fontWeight.bold,
              color: palette.colors.primary
            }}
          >
            Organization
          </span>
        </TypographyDDS.Title>
        <TypographyDDS.Title
          type='h5'
          color='textDark2'
          style={{
            maxWidth: '22.875em',
            textAlign: 'left'
          }}
        >
          {TextContentConstants.CREATE_A_NEW_ORGANIZATION}
        </TypographyDDS.Title>
      </DescriptionSection>
      <FormSection>
        <ItemContainer width='46.875em'>
          <InputField
            label='Organization Name'
            size='medium'
            width='100%'
            onChange={(e) => setOrganizationName(e.target.value)}
            value={name}
            error={!isEmpty(organizationErrorText)}
            helperText={organizationErrorText}
            maxLength={StringConstants.NAME_FIELD_MAX_LENGTH}
          />
        </ItemContainer>
        <ItemContainer width='46.875em'>
          <Button
            variant='contained'
            width='100%'
            endIcon={<IoIosArrowForward />}
            background={palette.colors.primary}
            margin='1.25em 0'
            height='56px'
            loading={loading}
            onClick={() => {
              handleCheckNameAvailability()
              gaEventTracker(gaEventConstants.NEW_ORGANIZATION_NAME_CONFIRMED)
            }}
            fullWidth
          >
            Next
          </Button>
        </ItemContainer>
      </FormSection>
      {conformationPromptOpen && (
        <PopUpWrapper>
          <CreatedOrg
            organizationName={name}
            onClick={() => setConformationPromptOpen(false)}
            //handleTabChange={handleTabChange}
            handleOrganizationCreate={handleOrganizationCreate}
          />
        </PopUpWrapper>
      )}
    </Container>
  )
}

export default CreateOrg
