import ReactGA from 'react-ga4'

export const useAnalyticsEventTracker = (category = 'Blog category') => {
  if (process.env.REACT_APP_TRACK_GA === 'TRUE') {
    const eventTracker = (action = 'test action', label = 'test label') => {
      ReactGA.event({ category, action, label })
    }
    return eventTracker
  } else {
    return () => {
      console.log('GA not tracked')
    }
  }
}
