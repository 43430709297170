import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { DDSTypography } from 'den-design-system'
import { Typography } from '../../../components/common/DDS/Typography/Typography'
import checkMarkWave from '../../../assets/icons/CheckMarkWave.svg'
import { getHealthReportDetails } from '../../../api/appHealthCheck/AHC.service'
import pallete from '../../../global/newPallete'
import setUpIcon from '../../../assets/icons/setUpIcon.svg'
import vigilLogo from '../../../assets/icons/Vigil Logo Greyed out.svg'
import vigilLogoTM from '../../../assets/icons/vigilLogoTM.svg'
import warningWave from '../../../assets/icons/WarningWave.svg'

interface PublicViewProps {
  status?: string
  title?: string
  availability?: string
  secondarytext?: string
}
const UCPublicViewPage: React.FC<PublicViewProps> = () => {
  const { id } = useParams()
  const [status, setStatus] = useState<string>('')
  const [availability, setAvailability] = useState<string>('')
  const fetchHealthDetails = () => {
    getHealthReportDetails({ $ahcId: id as string }).then((response: any) => {
      setStatus(response.data.status)
    })
  }

  useEffect(() => {
    fetchHealthDetails()
  }, [])
  const getIconForPublicView = (status: string) => {
    switch (status) {
      case 'Maintenance':
        return <img src={setUpIcon} alt='Setup Icon' />
      case 'Running':
        return <img src={checkMarkWave} alt='Running Icon' />
      case 'Down':
        return <img src={warningWave} alt='Down Icon' />
    }
  }
  const getColorForStatus = (status: string) => {
    switch (status) {
      case 'Running':
        return pallete.colors.warningGreen
      case 'Down':
        return pallete.colors.warningRed2
    }
  }
  return (
    <div className='flex flex-col w-full h-screen bg-white'>
      <div className='flex w-full h-[70.58px] bg-white items-center justify-center px-[32px] py-[16px] shadow-custom'>
        <img src={vigilLogoTM} alt='Vigil Logo' />
      </div>
      <div className='flex flex-col items-center justify-center flex-grow gap-[20px] p-[32px] w-full'>
        <div className='flex flex-col justify-center items-center flex-grow gap-[48px] p-[32px] w-full'>
          <div>{getIconForPublicView(status)}</div>
          <div className='flex flex-col justify-center items-center gap-[12px]'>
            <div>
              <Typography.Heading
                variant='bold'
                className='text-center'
                color='textDark3'
              >
                Vigil is Under Maintenance
              </Typography.Heading>
            </div>

            {availability && status !== 'Maintenance' && (
              <div>
                <DDSTypography.Title
                  type='h2'
                  variant='semiBold'
                  color={getColorForStatus(status)}
                >
                  Availability - {availability}%
                </DDSTypography.Title>
              </div>
            )}
            <div>
              <DDSTypography.Paragraph
                variant='regular'
                size='para'
                className='text-center max-w-[479px]'
                color={pallete.colors.textDark10}
              >
                {`The increased error rate has resolved and operations have
                returned to normal, we're investigating the root cause and
                monitoring the situation.`}
              </DDSTypography.Paragraph>
            </div>
          </div>
        </div>
        <div className='flex justify-center items-center gap-[4px]'>
          <div>
            <Typography.Paragraph
              size='caption'
              variant='medium'
              color='textDark10'
            >
              @ 2024
            </Typography.Paragraph>
          </div>
          <div>
            <img
              src={vigilLogo}
              width={73.193}
              height={16.7}
              alt='Vigil Logo'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UCPublicViewPage
