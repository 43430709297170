import { DDSButton, DDSMenu } from 'den-design-system'
import React, { useState } from 'react'

import { ChevronDown } from '@carbon/icons-react'
import { StatusTypes } from '../../../constants/StatusConstants'
import pallete from '../../../global/newPallete'
import { useStoreContext } from '../../../store/StoreContext'

interface StatusDropDownProps {
  label: string
  onSelect: any
  disabled?: boolean
  tableHeight?: any
  style?: React.CSSProperties
}
const StatusDropDown: React.FC<StatusDropDownProps> = ({
  label,
  onSelect,
  disabled,
  tableHeight,
  style
}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const store = useStoreContext()
  const [menuPosition, setMenuPosition] = useState<'above' | 'below'>('below')
  const dropDownRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (dropDownRef.current) {
      const dropdownRect = dropDownRef.current.getBoundingClientRect()
      const windowHeight = window.innerHeight
      if (dropdownRect.bottom + 150 > windowHeight) {
        setMenuPosition('above')
      } else {
        setMenuPosition('below')
      }
    }
    const checkIfClickedOutside = (e: any) => {
      if (
        menuOpen &&
        dropDownRef.current &&
        !dropDownRef.current.contains(e.target)
      ) {
        setMenuOpen(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  })

  return (
    <div ref={dropDownRef}>
      <div className='override-min-width-status'>
        <DDSButton
          onClick={() => {
            setMenuOpen(!menuOpen)
          }}
          type='outline'
          shape='roundedRectangle'
          className='justify-between whitespace-nowrap !min-w-[130px] !p-[12px] !text-[14px]'
          style={{
            height: '26px',
            fontSize: '14px',
            borderColor: pallete.colors.stroke2,
            ...StatusTypes.find((severity: any) => severity.label === label)
              ?.style,
            ...style
          }}
          disabled={disabled}
          label={label}
          endIcon={
            <ChevronDown
              style={{
                fontSize: 16,
                color: '#222222'
              }}
            />
          }
          buttonHoverTextColor={
            StatusTypes.find((status: any) => status.label === label)?.style
              .color
          }
        />
      </div>
      <DDSMenu
        open={menuOpen}
        shape='roundedRectangle'
        className={`absolute z-[9999] mt-[5px] !min-w-[130px] border-2 shadow-sm max-h-[380px] override-padding ${
          menuPosition === 'above' ? 'translate-y-[-130px]' : ''
        }`}
      >
        {StatusTypes.map((status, index) => (
          <DDSButton
            type='outline'
            shape='roundedRectangle'
            key={index}
            label={status.label}
            className='w-full mt-[5px]  h-[24px] !min-w-0 !text-[14px] !justify-start'
            style={{
              borderColor: pallete.colors.white,
              fontSize: '14px',
              ...status.style,
              whiteSpace: 'nowrap'
            }}
            onClick={() => {
              onSelect(status.label)
              setMenuOpen(false)
            }}
            disabled={store.userStore.getNoActiveSubscription()}
            buttonHoverTextColor={status.style.color}
          />
        ))}
      </DDSMenu>
    </div>
  )
}

export default StatusDropDown
