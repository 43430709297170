import React, { useEffect, useState } from 'react'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'

import Button from '../../common/Button/Button'
import GoBack from './GoBack'
import InputField from '../../common/InputField/InputField'
import { IoIosArrowForward } from 'react-icons/io'
import SkipStep from './SkipStep'
import { TypographyDDS } from '../../common/Typography/TypographyDDS'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useStoreContext } from '../../../store/StoreContext'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.875em;
  align-items: center;
  gap: 1em;
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ColorText = styled.div`
  color: ${palette.colors.primary};
  display: inline;
`

const ActionWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const AuthenticationStep: React.FC<{
  handleNext: (data: any) => void
  handleBack: () => void
}> = ({ handleNext, handleBack }) => {
  const store = useStoreContext()
  const [formState, setFormState] = useState({
    authUsername: '',
    authPassword: ''
  })
  useEffect(() => {
    const { authUsername, authPassword } = store.addCheckStore.getData()
    setFormState({
      authUsername,
      authPassword
    })
  }, [])
  const handleChange = (name: string, value: string) => {
    if (name === 'password')
      return setFormState({ ...formState, authPassword: value })
    return setFormState({ ...formState, [name]: value })
  }
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.AUTHENTICATION_STEP
  )
  return (
    <Container>
      <TitleContainer>
        <TypographyDDS.Title type='h3' variant='medium'>
          Add <ColorText>authentication credentials</ColorText> required
        </TypographyDDS.Title>

        <TypographyDDS.Title type='h3' variant='medium'>
          to log in and perform the check
        </TypographyDDS.Title>
      </TitleContainer>
      <InputField
        label='Username'
        width='100%'
        name='authUsername'
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        value={formState.authUsername}
      />
      <InputField
        label='Password'
        width='100%'
        name='password'
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        value={formState.authPassword}
        type='password'
      />
      <Button
        onClick={() => {
          store.addCheckStore.setData(formState)
          handleNext(null)
          gaEventTracker(gaEventConstants.ADDED_AUTHENTICATION)
        }}
        variant='contained'
        endIcon={
          <IoIosArrowForward
            style={{
              fontSize: 16
            }}
          />
        }
        fullWidth
        disabled={
          isEmpty(formState.authUsername) || isEmpty(formState.authPassword)
        }
      >
        Next
      </Button>
      <ActionContainer>
        <ActionWrapper
          onClick={() => {
            store.addCheckStore.setData(formState)
            handleBack()
          }}
        >
          <GoBack />
        </ActionWrapper>
        <ActionWrapper
          onClick={() => {
            handleNext(null)
          }}
        >
          <SkipStep />
        </ActionWrapper>
      </ActionContainer>
    </Container>
  )
}

export default observer(AuthenticationStep)
