import { DDSTooltip } from 'den-design-system'
import { LinearProgress } from '@material-ui/core'
import React from 'react'
import pallete from '../../../../global/newPallete'
import styled from '@emotion/styled'

interface StackedProgressBarProps {
  totalCount: number
  data: {
    [key: string]: {
      count: number
      percentage: number
    }
  }
  color: {
    [key: string]: string
  }
}

interface ProgressBarProps {
  backgroundColor: string
}

const ProgressBar = styled(LinearProgress)(
  ({ ...props }: ProgressBarProps) => ({
    '.MuiLinearProgress-barColorPrimary': {
      backgroundColor: `${props.backgroundColor as string} !important`
    }
  })
)

const HorizontalStackedProgressBar: React.FC<StackedProgressBarProps> = ({
  totalCount,
  data,
  color
}) => {
  const generateRandomId = () => {
    return (Math.random() + 1).toString(36).substring(7)
  }

  const keys = Object.keys(data).filter((key) => data[key].count > 0)

  return (
    <div className='flex flex-row w-full rounded-[4px] overflow-hidden'>
      {keys.map((key, index) => (
        <div
          key={index}
          style={{
            height: '26px',
            width: `${data[key].percentage}%`,
            borderTopLeftRadius: `${index === 0 ? '4px' : '0'}`,
            borderBottomLeftRadius: `${index === 0 ? '4px' : '0'}`,
            borderTopRightRadius: `${index === keys.length - 1 ? '4px' : '0'}`,
            borderBottomRightRadius: `${index === keys.length - 1 ? '4px' : '0'}`,
            backgroundColor: color[key],
            flexShrink: 0
          }}
        >
          <DDSTooltip
            label={`${key} ${data[key].count}/${totalCount} (${data[key].percentage}%)`}
            position='top'
            id={generateRandomId()}
            shape='roundedRectangle'
            theme='light'
            className={`border override-tooltip-arrow`}
            style={{ borderColor: pallete.colors.stroke2, zIndex: 99999 }}
          >
            <div style={{ height: '26px', backgroundColor: color[key] }}></div>
          </DDSTooltip>
        </div>
      ))}
    </div>
  )
}

export default HorizontalStackedProgressBar
