import { Box, Tooltip } from '@mui/material'

import React from 'react'
import { TypographyDDS } from '../../common/Typography/TypographyDDS'

export interface Distribution {
  name?: string
  value: number
  color: string
}
export interface DistributionBarProps {
  distributions: Distribution[]
  rounded?: boolean
  height?: number
}
const DistributionBar: React.FC<DistributionBarProps> = ({
  distributions,
  rounded,
  height = 24
}) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      width='100%'
      height={height}
      borderRadius={rounded ? 1 : 0}
      overflow='hidden'
    >
      {distributions.map((distribution, index) => (
        <Tooltip
          key={index}
          arrow
          title={
            distribution.name ? (
              <React.Fragment>
                <TypographyDDS.Paragraph size='para' color='white'>
                  {distribution.name}
                </TypographyDDS.Paragraph>
                <TypographyDDS.Paragraph size='para' color='white'>
                  {distribution.value.toFixed(2)}%
                </TypographyDDS.Paragraph>
              </React.Fragment>
            ) : (
              ''
            )
          }
        >
          <Box
            flexBasis={`${distribution.value}%`}
            bgcolor={distribution.color}
            height='100%'
          />
        </Tooltip>
      ))}
    </Box>
  )
}

export default DistributionBar
