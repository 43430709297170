import React from 'react'

interface StatusProps {
  count: number
  label: string
  textColor: string
  bgColor: string
  textAlignment?: 'left' | 'right' | 'center' | 'justify'
  borderTopLeftRadius?: string
  borderBottomLeftRadius?: string
  borderTopRightRadius?: string
  borderBottomRightRadius?: string
  paddingTop?: string
  paddingBottom?: string
  paddingLeft?: string
  paddingRight?: string
}

const Status: React.FC<StatusProps> = ({
  count,
  label,
  textColor,
  bgColor,
  borderTopLeftRadius = '0px',
  borderBottomLeftRadius = '0px',
  borderTopRightRadius = '0px',
  borderBottomRightRadius = '0px',
  textAlignment = 'center',
  paddingTop = '10px',
  paddingBottom = '10px',
  paddingLeft = '10px',
  paddingRight = '10px'
}) => {
  return (
    <div
      className='flex  items-center w-full h-[24px]'
      style={{
        backgroundColor: bgColor,
        borderTopLeftRadius: borderTopLeftRadius,
        borderBottomLeftRadius: borderBottomLeftRadius,
        borderTopRightRadius: borderTopRightRadius,
        borderBottomRightRadius: borderBottomRightRadius,
        paddingTop: paddingTop,
        paddingBottom: paddingBottom,
        paddingLeft: paddingLeft,
        paddingRight: paddingRight
      }}
    >
      <span
        className='w-full'
        style={{
          color: textColor,
          textAlign: textAlignment,
          whiteSpace: 'nowrap',
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '18px'
        }}
      >
        {count} {label}
      </span>
    </div>
  )
}

export default Status
