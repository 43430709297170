export const flattenObject = (obj: any) => {
  const flattenKeys: any = {}
  for (const i in obj) {
    if (!Object.getOwnPropertyDescriptor(obj, i)) continue
    if (typeof obj[i] == 'object') {
      const flatObject = flattenObject(obj[i])
      for (const j in flatObject) {
        if (!Object.getOwnPropertyDescriptor(flatObject, j)) continue
        flattenKeys[i + '.' + j] = flatObject[j]
      }
    } else {
      flattenKeys[i] = obj[i]
    }
  }
  return flattenKeys as Record<string, any>
}
