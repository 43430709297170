import React, { useState } from 'react'

import Button from '../common/Button/Button'
import HttpConstants from '../../constants/HttpConstants'
import Popup from '../common/Popup/Popup'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../common/DDS/Toast/Toast'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import { addQueryParamsToUrl } from '../../utils/UrlGenerator'
import closeIcon from '../../assets/icons/close.svg'
import inviteMembersIcon from '../../assets/icons/inviteMembersIcon.svg'
import { partnerOrganization } from '../../api/partner/Partner.service'
import routeConstants from '../../constants/RouteConstants'
import styled from '@emotion/styled'
import successIcon from '../../assets/icons/successIcon.svg'
import themePalette from '../../global/pallete'
import { useNavigate } from 'react-router-dom'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
`
const ButtonContainer = styled.div`
  text-align: center;
  display: flex;
  margin-top: 30px;
`

const PartnerInvitePopUp: React.FC<{
  open: boolean
  handleClose: () => void
  organization: string
  email: string
  token: string
}> = ({ open, handleClose, organization, email, token }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)

  const updatePartnerToken = () => {
    setLoading(true)
    partnerOrganization({
      email: email,
      name: organization,
      chargebeePlanId: process.env
        .REACT_APP_DEFAULT_SUBSCRIPTION_PLAN_CHARGEBEE_ID as string,
      isPartner: true
    })
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.PARTNER.PARTNER_ORGANIZATION.SUCCESS
        })
        navigate(
          addQueryParamsToUrl(
            routeConstants.SIGN_IN,
            {
              token: token,
              email: email
            },
            HttpConstants.GET_METHOD
          )
        )
      })
      .catch((err) =>
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.PARTNER.PARTNER_ORGANIZATION.ERROR
        })
      )
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <Popup
      open={open}
      handleClose={handleClose}
      headerText='Partner Organization Invite'
      headerIcon={
        <img
          src={inviteMembersIcon}
          style={{ filter: themePalette.colors.primarySvgFilter }}
        />
      }
      height='300px'
      disabled={loading}
    >
      <Container>
        <div style={{ textAlign: 'justify', paddingRight: '70px' }}>
          <TypographyDDS.Title type='h4' variant='medium'>
            A new organization will be created for this email address.
          </TypographyDDS.Title>
          <TypographyDDS.Title type='h4' variant='medium'>
            Select yes to continue, no to reject invite
          </TypographyDDS.Title>
        </div>
        <ButtonContainer>
          <Button
            size='medium'
            variant='contained'
            borderColor='red'
            type='button'
            bold={false}
            margin-top='20px'
            color='error'
            height='50px'
            startIcon={
              <img
                src={closeIcon}
                alt='closeIcon'
                style={{ filter: themePalette.colors.whiteSvgFilter }}
              />
            }
            disabled={loading}
          >
            No
          </Button>
          <Button
            margin='0px 0px 0px 20px'
            variant='contained'
            type='button'
            bold={false}
            height='50px'
            background={themePalette.colors.primary}
            startIcon={
              <img
                src={successIcon}
                alt='successIcon'
                style={{
                  filter: themePalette.colors.whiteSvgFilter,
                  height: '16px'
                }}
              />
            }
            onClick={() => {
              updatePartnerToken()
            }}
            loading={loading}
          >
            Yes
          </Button>
        </ButtonContainer>
      </Container>
    </Popup>
  )
}

export default PartnerInvitePopUp
