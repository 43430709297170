import { Button } from '../New/Button/Button'
import OverviewStringConstants from '../../constants/OverviewStringConstants'
import React from 'react'
import { Renew } from '@carbon/icons-react'
import Tooltip from '../New/Tooltip/Tooltip'
import pallete from '../../global/newPallete'
interface refreshProps {
  title: string
  onClick: any
}
const RefreshButton: React.FC<refreshProps> = ({ title, onClick }) => {
  return (
    <Tooltip id='refresh-tooltip' label={OverviewStringConstants.REFRESH_LABEL}>
      <Button
        size='small'
        id='refresh-btn'
        type='neutral'
        onClick={onClick}
        startIcon={<Renew size={16} color={pallete.colors.neuButtonText} />}
      />
    </Tooltip>
  )
}

export default RefreshButton
