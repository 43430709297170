import MiscContent from '../Page/MiscContent/MiscContent'
import React from 'react'
import setUpIcon from '../../../../assets/images/error 2.svg'
import vigilLogo from '../../../../assets/icons/Vigil Logo Greyed out.svg'

const UnderMaintenancePage = () => {
  return (
    <div className='flex flex-col min-h-screen'>
      <MiscContent
        imgSrc={setUpIcon}
        text='Vigil Under Maintenance'
        buttonLabel='Return to Homepage'
        vigilLogoSrc={vigilLogo}
      />
    </div>
  )
}

export default UnderMaintenancePage
