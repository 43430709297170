import * as React from 'react'

interface SalesforceIconProps {
  width?: number
  height?: number
  marginLeft?: number | string
  color?: string
}

const SalesforceIcon: React.FC<SalesforceIconProps> = ({
  width = 25,
  height = 25,
  marginLeft = '4px',
  color
}) => {
  return (
    <img
      src={require('../../../assets/icons/salesforce.png')}
      style={{
        width: width,
        height: height,
        color: color,
        marginLeft: marginLeft
      }}
    />
  )
}

export default SalesforceIcon
