import React, { useEffect, useState } from 'react'

import ContextSwitcherTabs from '../common/DDS/ContextSwitcherTab/ContextSwitcherTab'
import { DDSTypography } from 'den-design-system'
import Modal from '../common/DDS/Modal/Modal'
import OverviewStringConstants from '../../constants/OverviewStringConstants'
import OverviewViewMoreTable from './OverviewViewMoreTable'
import { observer } from 'mobx-react-lite'
import pallete from '../../global/newPallete'
import { useStoreContext } from '../../store/StoreContext'

interface ViewMoreProps {
  open: boolean
  setOpen: any
}
const ViewMorePopup: React.FC<ViewMoreProps> = ({ open, setOpen }) => {
  const store = useStoreContext()
  const [activeTab, setActiveTab] = useState<string>(
    (store.overviewStore.getActiveTab() + 1).toString()
  )
  useEffect(() => {
    setActiveTab((store.overviewStore.getActiveTab() + 1).toString())
  }, [store.overviewStore.getActiveTab()])
  const activeModule = store.overviewStore.getActiveModule()
  const tabOptions = [
    {
      label:
        activeModule === 'job' ? (
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={
              activeTab === '1'
                ? pallete.colors.textDark1
                : pallete.colors.textDark5
            }
          >
            {OverviewStringConstants.EXECUTIONS}
          </DDSTypography.Paragraph>
        ) : (
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={
              activeTab === '1'
                ? pallete.colors.textDark1
                : pallete.colors.textDark5
            }
          >
            {OverviewStringConstants.AVAILABILITY}
          </DDSTypography.Paragraph>
        )
    },
    {
      label:
        activeModule === 'job' ? (
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={
              activeTab === '2'
                ? pallete.colors.textDark1
                : pallete.colors.textDark5
            }
          >
            {OverviewStringConstants.FAILED}
          </DDSTypography.Paragraph>
        ) : (
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={
              activeTab === '2'
                ? pallete.colors.textDark1
                : pallete.colors.textDark5
            }
          >
            {OverviewStringConstants.DOWN_TIME}
          </DDSTypography.Paragraph>
        )
    },
    {
      label:
        activeModule === 'job' ? (
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={
              activeTab === '3'
                ? pallete.colors.textDark1
                : pallete.colors.textDark5
            }
          >
            {OverviewStringConstants.RUN_TIME}
          </DDSTypography.Paragraph>
        ) : (
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={
              activeTab === '3'
                ? pallete.colors.textDark1
                : pallete.colors.textDark5
            }
          >
            {OverviewStringConstants.RESPONSE}
          </DDSTypography.Paragraph>
        )
    }
  ]
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      showHeader
      hideCloseIcon={false}
      title={store.overviewStore.getPopupTitle()}
      onClosePress={() => {
        setOpen(false)
        setActiveTab('1')
        store.overviewStore.setActiveTab(0)
      }}
    >
      <div className='min-w-[844px] h-[482px] flex flex-col items-start gap-[24px] p-[24px] overflow-y-auto'>
        <ContextSwitcherTabs
          shape='roundedRectangle'
          tabStyle={{
            border: '1px solid',
            borderColor: pallete.colors.stroke2,
            userSelect: 'none',
            width: '309px'
          }}
          className='override-view-more-tabs'
          tabOptions={tabOptions}
          activeTab={activeTab}
          onChange={(activeTab) => {
            setActiveTab(activeTab)
            store.overviewStore.setActiveTab(parseInt(activeTab) - 1)
          }}
        />
        <OverviewViewMoreTable />
      </div>
    </Modal>
  )
}

export default observer(ViewMorePopup)
