import { Button } from '../../../../New/Button/Button'
import { ChevronRight } from '@carbon/icons-react'
import MiscHeader from '../MiscHeader/MiscHeader'
import React from 'react'
import { Typography } from '../../../../common/DDS/Typography/Typography'
import pallete from '../../../../../global/newPallete'
import routeConstants from '../../../../../constants/RouteConstants'
import { useNavigate } from 'react-router-dom'

interface MiscContenteProps {
  imgSrc: string
  text: string
  buttonLabel: string
  vigilLogoSrc: string
}

const MiscContent: React.FC<MiscContenteProps> = ({
  imgSrc,
  text,
  buttonLabel,
  vigilLogoSrc
}) => {
  const navigate = useNavigate()

  return (
    <div className='flex flex-col min-h-screen'>
      <MiscHeader />
      <div className='flex-grow flex flex-col items-center justify-center bg-white p-[32px]'>
        <img src={imgSrc} alt='Error' />
        <div className='flex flex-col justify-center items-center'>
          <p
            style={{
              color: pallete.colors.textDark3,
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '40px',
              paddingBottom: '24px'
            }}
          >
            {text}
          </p>
          <Button
            label={buttonLabel}
            type='filled'
            onClick={() => {
              navigate(routeConstants.REDIRECT)
            }}
            size='small'
            endIcon={<ChevronRight color={pallete.colors.white} />}
            id='home-screen-button'
          />
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '32px',
          left: '50%',
          transform: 'translateX(-50%)'
        }}
      >
        <Typography.Paragraph
          size='caption'
          variant='medium'
          color='textDark10'
          style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
        >
          © 2024
          <span>
            <img src={vigilLogoSrc} alt='Vigil Icon' />
          </span>
        </Typography.Paragraph>
      </div>
    </div>
  )
}

export default MiscContent
