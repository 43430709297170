import React, { useEffect, useState } from 'react'
import { getIconForStatus, getTimeDiff } from '../../utils/UCUtils'
import { isEmpty, isNull } from 'lodash'
import { timeMetric, timeZone } from '../../utils/DateTimeUtils'

import { DDSTypography } from 'den-design-system'
import NoFilterImage from '../../assets/images/folder-not-found 1.svg'
import NoRecord from '../common/NoContent/NoRecord'
import Spinner from '../common/Spinner/Spinner'
import Table from '../common/DDS/Table/Table'
import UptimeStringConstants from '../../constants/UptimeStringConstants'
import { getAllQueryParamsAsObjectFromUrl } from '../../utils/UrlUtils'
import pallete from '../../global/newPallete'
import { timeFormats } from '../../enum/TIME'
import { useSearchParams } from 'react-router-dom'
import { useStoreContext } from '../../store/StoreContext'

interface LogDetailsProps {
  loading: boolean
  logsData: any
  totalCount: number
  fetchHoursLogsForEvent: (page: number, filters?: any) => void
}
const UCLogDetailsTable: React.FC<LogDetailsProps> = ({
  loading,
  logsData,
  totalCount,
  fetchHoursLogsForEvent
}) => {
  const store = useStoreContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(searchParams.get('page') ?? '1', 10)
  )

  useEffect(() => {
    const filters: any = getAllQueryParamsAsObjectFromUrl(location.search)
    const pageFromQueryParams = searchParams.get('page')
    if (!isEmpty(store.scopeStore.getScope()['environment-id']))
      if (!isNull(pageFromQueryParams)) {
        setCurrentPage(Number(pageFromQueryParams))
        fetchHoursLogsForEvent(currentPage, filters)
      } else {
        const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
        setSearchParams({ ...allQueryParams, page: '1' })
        setCurrentPage(1)
      }
  }, [searchParams, store.scopeStore.getScope()])

  const columns = [
    {
      columnHeaderStyle: { width: '5%' },
      columnDataStyle: { width: '5%' },
      heading: '',
      dataLabel: 'eventStatus',
      render: (text: any) => (
        <div className='flex justify-center'>{getIconForStatus(text)}</div>
      )
    },
    {
      columnHeaderStyle: {
        width: store.uiStore.getSidebarExpanded() ? '17%' : '15%'
      },
      columnDataStyle: {
        width: store.uiStore.getSidebarExpanded() ? '17%' : '15%'
      },
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Time
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'date',
      render: (text: any) => (
        <div className='flex justify-center text-center'>
          <DDSTypography.Paragraph
            variant='medium'
            size='para'
            className='whitespace-nowrap'
            color={pallete.colors.textDark4}
          >
            {text
              ? timeZone(
                  store.userStore.getUserPreference().timeZone,
                  text,
                  timeFormats.fullMonthDateYearTime
                )
              : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '22.5%' },
      columnDataStyle: { width: '22.5%' },
      heading: (
        <div className='flex row justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Time (Relative)
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'date',
      render: (date: any) => (
        <div className='flex justify-center text-center'>
          <DDSTypography.Paragraph
            variant='medium'
            size='para'
            className='whitespace-nowrap'
            color={pallete.colors.textDark4}
          >
            {getTimeDiff(
              date,
              new Date(),
              store.userStore.getUserPreference().timeZone
            ) + ' ago'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: { width: '22.5%' },
      columnDataStyle: { width: '22.5%' },
      heading: (
        <div className='flex row justify-center  text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Response Time
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'responseTime',
      render: (responseTime: any) => {
        const response =
          responseTime && responseTime != '-'
            ? `${timeMetric(responseTime, 'ms') || '-'}`
            : '-'
        return (
          <div className='flex justify-center text-center'>
            <DDSTypography.Paragraph
              variant='medium'
              size='para'
              className='whitespace-nowrap'
              color={pallete.colors.textDark4}
            >
              {response}
            </DDSTypography.Paragraph>
          </div>
        )
      }
    },
    {
      columnHeaderStyle: { width: '22.5%' },
      columnDataStyle: { width: '22.5%' },
      heading: '',
      dataLabel: '',
      render: (text: any) => <div className='cursor-pointer'>{text}</div>
    },
    {
      columnHeaderStyle: { width: '5%' },
      columnDataStyle: { width: '5%' },
      heading: '',
      dataLabel: '',
      render: (text: any) => <div className='cursor-pointer'>{text}</div>
    }
  ]

  return loading || !logsData ? (
    <div className='flex flex-col h-[70vh] self-stretch justify-center'>
      <Spinner />
    </div>
  ) : logsData.logs.length > 0 ? (
    <div className='flex flex-col gap-[24px]'>
      <Table
        tableStyle={{
          borderColor: pallete.colors.stroke2
        }}
        data={logsData.logs}
        columns={columns}
        totalCount={totalCount}
        rowsPerPage={10}
        currentPage={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page)
          const allQueryParams = getAllQueryParamsAsObjectFromUrl(
            location.search
          )
          setSearchParams({ ...allQueryParams, page: page.toString() })
        }}
      />
    </div>
  ) : (
    <NoRecord
      style={{ height: '70vh' }}
      imageSrc={NoFilterImage}
      text={UptimeStringConstants.NO_RECORD}
    />
  )
}

export default UCLogDetailsTable
