import { DDSTooltip, DDSTypography } from 'den-design-system'

import { Information } from '@carbon/icons-react'
import React from 'react'
import pallete from '../../../global/newPallete'

interface APIOverviewCardData {
  id: any
  heading: string
  content: string | React.ReactNode
}

interface CardProps {
  heading: string
  content: string | React.ReactNode
}

export const Card: React.FC<CardProps> = ({ heading, content }) => {
  return (
    <div className='flex flex-col gap-y-[4px] py-[8px] px-[10px]'>
      <DDSTypography.Paragraph
        size='para'
        variant='bold'
        color={pallete.colors.textDark4}
        className='text-left'
      >
        {heading}
      </DDSTypography.Paragraph>
      {typeof content === 'string' ? (
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark4}
        >
          {content}
        </DDSTypography.Paragraph>
      ) : (
        content
      )}
    </div>
  )
}

const APICustomCardTooltip: React.FC<APIOverviewCardData> = ({
  id,
  heading,
  content
}) => {
  const showInfoIcon =
    heading === 'Total Failure APIs' || heading === 'Total Success APIs'

  const getTooltipContent = (isFailure: boolean) => (
    <div
      style={{
        fontSize: '14px',
        color: pallete.colors.textDark3,
        fontWeight: 500,
        lineHeight: '20px'
      }}
    >
      Status codes {isFailure ? 'outside' : 'within'}{' '}
      {isFailure ? '100, 200, or 300 mean ' : '200, 300 '}
      <span
        style={{
          color: isFailure
            ? pallete.colors.warningRed1
            : pallete.colors.warningGreen,
          fontSize: '14px',

          fontWeight: 500,
          lineHeight: '20px'
        }}
      >
        {isFailure ? 'Failure' : 'Success'}
      </span>
      .
    </div>
  )

  return (
    <div
      id={id}
      className='relative flex flex-col rounded-md border px-[20px] py-[16px]'
      style={{ borderColor: pallete.colors.stroke2 }}
    >
      <DDSTypography.Paragraph
        size='para'
        variant='regular'
        color={pallete.colors.textDark5}
        className='whitespace-nowrap'
      >
        {heading}
      </DDSTypography.Paragraph>
      <div className='pt-[8px] relative'>
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark3}
          className='truncate w-[96%] whitespace-nowrap'
        >
          {content}
        </DDSTypography.Title>

        {showInfoIcon && (
          <div className='absolute bottom-0 right-0'>
            <DDSTooltip
              id={`info-${id}`}
              position='left'
              shape='roundedRectangle'
              label={
                <Card
                  heading={
                    heading === 'Total Failure APIs'
                      ? 'Failure APIs'
                      : 'Success APIs'
                  }
                  content={getTooltipContent(heading === 'Total Failure APIs')}
                />
              }
              className={`override-tooltip-arrow
              `}
              style={{
                backgroundColor: pallete.colors.surface1,
                zIndex: 999999
              }}
            >
              <Information
                size={16}
                color={pallete.colors.textDark6}
                className=' cursor-pointer'
              />
            </DDSTooltip>
          </div>
        )}
      </div>
    </div>
  )
}

export default APICustomCardTooltip
