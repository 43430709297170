import { Checkmark, CheckmarkFilled, Copy } from '@carbon/icons-react'
import React, { useContext, useEffect, useState } from 'react'

import CopyToClipboard from 'react-copy-to-clipboard'
import JMStringConstants from '../../../constants/JMStringConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import Tooltip from '../../New/Tooltip/Tooltip'
import pallete from '../../../global/newPallete'
import { useStoreContext } from '../../../store/StoreContext'

interface CopyProps {
  copyText: string
}
const CopyButton: React.FC<CopyProps> = ({ copyText }) => {
  const store = useStoreContext()
  const [copy, setCopy] = useState<boolean>(false)
  const handleCopyClick = () => {
    setCopy(true)
    copyToClipboard()
  }
  useEffect(() => {
    if (copy) {
      setTimeout(() => setCopy(false), 2000)
    }
  }, [copy])
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(copyText)
    } catch (err) {
      ToastNotification({
        type: 'error',
        message: JMStringConstants.FAILED_TO_COPY
      })
    }
  }
  return (
    <div
      onClick={handleCopyClick}
      className={`${store.userStore.getNoActiveSubscription() && 'cursor-not-allowed pointer-events-none opacity-50'}`}
    >
      {!copy ? (
        <Copy
          className='cursor-pointer'
          color={pallete.colors.textDark4}
          onClick={handleCopyClick}
        />
      ) : (
        <Tooltip
          id='copy-tooltip'
          label={JMStringConstants.COPIED_TOOLTIP_LABEL}
        >
          <CheckmarkFilled color={pallete.colors.primary2} />
        </Tooltip>
      )}
    </div>
  )
}

export default CopyButton
