import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'
import {
  getValidationError,
  getValidationStatus
} from '../../../utils/ValidationUtils'

import { Button } from '../../../components/New/Button/Button'
import { DDSTypography } from 'den-design-system'
import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import HttpConstants from '../../../constants/HttpConstants'
import Input from '../../../components/New/Input/Input'
import LeftContent from '../../../components/SignIn/LeftContent'
import SigninSignupStringConstants from '../../../constants/SigninSignupStringConstants'
import StringConstants from '../../../constants/StringConstants'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import UnsecureComponent from '../../../components/Layout/UnsecureComponent'
import { UrlForSalesforceLogin } from '../../../utils/UrlUtils'
import { addQueryParamsToUrl } from '../../../utils/UrlGenerator'
import { getIconForProjectType } from '../../../utils/OverviewUtils'
import { isAuthenticatedUser } from '../../../utils/AuthUtils'
import { isUndefined } from 'lodash'
import { joinUserToRoom } from '../../../gateway'
import { login } from '../../../api/Auth.service'
import { loginSchema } from '../../../utils/SigninSignupUtils'
import { observer } from 'mobx-react-lite'
import palette from '../../../global/pallete'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { updatePartnerInvite } from '../../../api/partner/Partner.service'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

interface LoginForm {
  email: string
  password: string
}
interface RefObject {
  resetCaptcha: () => void
}

const SignIn: React.FC<{ showRecaptcha?: boolean }> = ({ showRecaptcha }) => {
  const [searchParams] = useSearchParams()
  const partnerToken = searchParams.get('token')
  const organizationPartner = partnerToken ? true : false
  const partnerEmail = searchParams.get('email')
  const defaultForm = {
    email: (organizationPartner && partnerEmail) || '',
    password: ''
  }
  const [loginForm, setLoginForm] = useState<LoginForm>(defaultForm)
  const [captcha, setCaptcha] = React.useState<string | null>('')
  const [loading, setLoading] = useState(false)
  const [clearedPasswordOnError, setClearedPasswordOnError] =
    useState<boolean>(false)
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true)
  const navigate = useNavigate()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [touched, setTouched] = useState({
    email: false,
    password: false,
    captcha: false
  })
  const [focus, setFocus] = useState({
    email: false,
    password: false,
    captcha: false
  })

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    const token = await executeRecaptcha('signIn')
    setCaptcha(token)
  }, [executeRecaptcha])

  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  const gaEventTracker = useAnalyticsEventTracker(gaPageConstants.SIGN_IN)
  const [validationErrorResult, setValidationErrorResult] = useState<any[]>([])

  useEffect(() => {
    if (isAuthenticatedUser()) {
      navigate(routeConstants.REDIRECT)
    } else {
      localStorage.removeItem(
        StringConstants.LOCAL_STORAGE.CURRENT_PAGE_DETAILS
      )
    }
  }, [])

  useEffect(() => {
    const validateForm = () => {
      const validationResult = loginSchema.validate(
        { email: loginForm.email, password: loginForm.password, captcha },
        {
          abortEarly: false
        }
      )
      if (isUndefined(validationResult.error)) {
        setValidationErrorResult([])
        setButtonDisabled(false)
      } else {
        setButtonDisabled(true)
        setValidationErrorResult(validationResult.error.details)
      }
    }

    validateForm()
  }, [loginForm, captcha])

  const handleFormChange = (field: keyof LoginForm, value: string) => {
    setLoginForm((prevForm) => ({
      ...prevForm,
      [field]: value
    }))
    setValidationErrorResult((prevErrors) =>
      prevErrors.filter((error) => error.context.key !== field)
    )
  }

  const handleFormSubmit = () => {
    setLoading(true)
    const validationResult = loginSchema.validate(
      { email: loginForm.email, password: loginForm.password, captcha },
      {
        abortEarly: false
      }
    )

    if (isUndefined(validationResult.error)) {
      setValidationErrorResult([])
      const payload = {
        email: loginForm.email,
        password: loginForm.password,
        captcha
      }
      login(payload)
        .then((response: any) => {
          if (partnerToken) {
            updatePartnerInvite({
              $token: partnerToken,
              signedIn: true
            }).catch((err) => {
              ToastNotification({
                type: 'error',
                message: ToastMessageConstants.PARTNER.PARTNER_INVITE.ERROR
              })
            })
          }
          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.AUTH.SIGN_IN.SUCCESS
          })
          setLoading(false)
          joinUserToRoom(response.data.id)
          localStorage.setItem('accessToken', response.data.accessToken)
          const url = addQueryParamsToUrl(
            routeConstants.REDIRECT,
            {
              token: response.data.accessToken
            },
            HttpConstants.GET_METHOD
          )
          gaEventTracker(gaEventConstants.SIGN_IN)
          navigate(url)
        })
        .catch((err: string) => {
          setCaptcha('')
          handleReCaptchaVerify()
          setClearedPasswordOnError(true)
          setLoginForm({ ...loginForm, ['password']: '' })
          setLoading(false)
          ToastNotification({
            type: 'error',
            message: err
          })
        })
    } else {
      handleReCaptchaVerify()
      setValidationErrorResult(validationResult.error.details)
      setCaptcha('')
      setLoginForm(defaultForm)
      setLoading(false)
    }
  }

  const handleFocus = (field: keyof LoginForm | 'captcha') => {
    setFocus((prev) => ({ ...prev, [field]: true }))
    if (field === 'password') setClearedPasswordOnError(false)
  }

  const handleBlur = (field: keyof LoginForm | 'captcha') => {
    setTouched((prev) => ({ ...prev, [field]: true }))
    setFocus((prev) => ({ ...prev, [field]: false }))
    if (field === 'password') setClearedPasswordOnError(false)
  }

  const shouldShowError = (field: keyof LoginForm | 'captcha') => {
    return (
      touched[field] &&
      !focus[field] &&
      getValidationStatus(validationErrorResult, field)
    )
  }
  return (
    <>
      <DocumentHeader header={HeaderConstants.SIGN_IN} />
      <div className='flex justify-center items-start h-[100vh]'>
        <LeftContent signIn />
        <div className='w-[60%] bg-white flex flex-col justify-center items-center px-[144px] py-[44px] gap-[24px] self-stretch h-[100vh]'>
          <div className='flex w-[602px] flex-col items-center gap-[32px] self-stretch'>
            <div className='flex flex-col items-center gap-[4px]'>
              <div className='flex items-center'>
                <DDSTypography.Title
                  type='h1'
                  style={{ fontSize: '32px', lineHeight: '40px' }}
                  color={pallete.colors.textDark3}
                >
                  {SigninSignupStringConstants.LOGIN_TITLE}
                </DDSTypography.Title>
              </div>
            </div>
            <div className='flex flex-col items-start gap-[24px] self-stretch'>
              <div className='relative'>
                <Input
                  id='email-input'
                  name={SigninSignupStringConstants.EMAIL}
                  label={SigninSignupStringConstants.EMAIL}
                  value={loginForm.email}
                  type='email'
                  required
                  disabled={organizationPartner || loading}
                  labelPosition='top'
                  width='602px'
                  onFocus={() => handleFocus('email')}
                  onBlur={() => handleBlur('email')}
                  onChange={(e) => handleFormChange('email', e.target.value)}
                />
                {shouldShowError('email') &&
                  getValidationStatus(validationErrorResult, 'email') && (
                    <DDSTypography.Paragraph
                      size='caption'
                      variant='regular'
                      className='absolute -bottom-5'
                      color={pallete.colors.warningRed2}
                    >
                      {getValidationError(validationErrorResult, 'email')}
                    </DDSTypography.Paragraph>
                  )}
              </div>
              <div className='relative'>
                <Input
                  id='password-input'
                  type='password'
                  required
                  name={SigninSignupStringConstants.PASSWORD}
                  label={SigninSignupStringConstants.PASSWORD}
                  value={loginForm.password}
                  labelPosition='top'
                  disabled={loading}
                  width='602px'
                  onFocus={() => handleFocus('password')}
                  onBlur={() => handleBlur('password')}
                  onChange={(e) => handleFormChange('password', e.target.value)}
                />
                <div className='flex flex-row self-stretch items-center justify-between'>
                  <div>
                    {!clearedPasswordOnError &&
                      shouldShowError('password') &&
                      getValidationStatus(
                        validationErrorResult,
                        'password'
                      ) && (
                        <DDSTypography.Paragraph
                          size='caption'
                          variant='regular'
                          className='-bottom-5'
                          color={pallete.colors.warningRed2}
                        >
                          {getValidationError(
                            validationErrorResult,
                            'password'
                          )}
                        </DDSTypography.Paragraph>
                      )}
                  </div>
                  <div>
                    <button
                      onClick={() => navigate(routeConstants.FORGOT_PASSWORD)}
                    >
                      <DDSTypography.Paragraph
                        size='para'
                        variant='medium'
                        className='cursor-pointer'
                        color={pallete.colors.textDark2}
                      >
                        {SigninSignupStringConstants.FORGOT_PASSWORD}
                      </DDSTypography.Paragraph>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-col items-center gap-[8px] self-stretch'>
              <div className='override-login-btn-width'>
                <Button
                  label={SigninSignupStringConstants.LOGIN}
                  size='large'
                  disabled={buttonDisabled}
                  id='login-btn'
                  type='filled'
                  loading={loading}
                  onClick={handleFormSubmit}
                />
              </div>
              <div>
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  color={pallete.colors.textDark3}
                >
                  {SigninSignupStringConstants.OR}
                </DDSTypography.Paragraph>
              </div>
              <div className='override-login-btn-width'>
                <Button
                  id='login-sf-btn'
                  type='outline'
                  size='large'
                  disabled={loading}
                  startIcon={getIconForProjectType('SALESFORCE_ONLY')}
                  label={SigninSignupStringConstants.LOGIN_SF}
                  onClick={() => {
                    window.location.href = UrlForSalesforceLogin(true)
                  }}
                />
              </div>
            </div>
            <div className='flex flex-col items-start gap-[12px] self-stretch'>
              <div className='flex items-center gap-[4px]'>
                <div>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.primary}
                  >
                    {SigninSignupStringConstants.FIRST_TIME}
                  </DDSTypography.Paragraph>
                </div>
                <div
                  className={`${loading && 'pointer-events-none'} `}
                  onClick={() => navigate(routeConstants.SIGN_UP)}
                >
                  <DDSTypography.Paragraph
                    size='para'
                    variant='bold'
                    color={pallete.colors.textDark2}
                    className='underline cursor-pointer'
                  >
                    {SigninSignupStringConstants.CREATE_ACCOUNT}
                  </DDSTypography.Paragraph>
                </div>
              </div>
              <div className='flex items-center gap-[4px]'>
                <div>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='regular'
                    color={pallete.colors.textDark6}
                  >
                    {SigninSignupStringConstants.ACKNOWLEDGE}
                  </DDSTypography.Paragraph>
                </div>
                <div className={`${loading && 'pointer-events-none'} `}>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='regular'
                    color={pallete.colors.darkGrey}
                    className='cursor-pointer'
                  >
                    <Link
                      to={
                        StringConstants.VIGIL_WEBSITE_LINK +
                        StringConstants.URL_PATH_TERMS_OF_SERVICE
                      }
                      onClick={() =>
                        gaEventTracker(gaEventConstants.TERMS_OF_SERVICE)
                      }
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ color: `${palette.colors.darkGrey}` }}
                    >
                      {SigninSignupStringConstants.TERMS_OF_SERVICE}
                    </Link>
                  </DDSTypography.Paragraph>
                </div>
                <div>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='regular'
                    color={pallete.colors.textDark6}
                  >
                    {SigninSignupStringConstants.AND}
                  </DDSTypography.Paragraph>
                </div>
                <div className={`${loading && 'pointer-events-none'} `}>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='regular'
                    color={pallete.colors.darkGrey}
                    className='cursor-pointer'
                  >
                    <Link
                      to={
                        StringConstants.VIGIL_WEBSITE_LINK +
                        StringConstants.URL_PATH_PRIVACY_POLICY
                      }
                      onClick={() =>
                        gaEventTracker(gaEventConstants.PRIVACY_POLICY)
                      }
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ color: `${palette.colors.darkGrey}` }}
                    >
                      {SigninSignupStringConstants.PRIVACY_POLICY}
                    </Link>
                  </DDSTypography.Paragraph>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default UnsecureComponent(observer(SignIn))
