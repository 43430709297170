import { Copy, PauseOutline } from '@carbon/icons-react'
import { DDSButton, DDSTypography } from 'den-design-system'
import React, { useEffect, useState } from 'react'
import {
  deleteAnIncident,
  getAnIncident,
  updateAnIncident
} from '../../../api/incident/incident.service'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import ConfirmationPopup from '../../../components/common/Exceptions/ConfirmationPopup'
import { DeleteIncidentPopup } from '../../../components/IncidentsManagement/IncidentPopup/DeleteIncidentPopup'
import Details from '../../../components/IncidentsManagement/Details'
import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import IncidentActivity from '../../../components/IncidentsManagement/IncidentActivity'
import IncidentMenuList from './IncidentMenuList'
import Input from '../../../components/New/Input/Input'
import Spinner from '../../../components/common/Spinner/Spinner'
import { StatusIncidentPopup } from '../../../components/IncidentsManagement/IncidentPopup/StatusIncidentPopup'
import StringConstants from '../../../constants/StringConstants'
import Switch from '../../../components/New/Switch/Switch'
import Tabs from '../../../components/common/DDS/Tabs/Tabs'
import ThreeDotMenu from '../../../components/common/DDS/ThreeDotMenu/ThreeDotMenu'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../components/New/Tooltip/Tooltip'
import copy from 'copy-to-clipboard'
import { getAllProjectMembers } from '../../../api/project/Project.service'
import { isUndefined } from 'lodash'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { timeFormats } from '../../../enum/TIME'
import { timeZone } from '../../../utils/DateTimeUtils'
import { useStoreContext } from '../../../store/StoreContext'

const ManageIncident: React.FC = () => {
  const { incidentId } = useParams()
  const [incidentDetails, setIncidentDetails] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const currTab = searchParams.get('subTab')
  const [tabIndex, setTabIndex] = React.useState<string>(currTab ?? '1')
  const [linkCopied, SetLinkCopied] = useState<boolean>(false)
  const store = useStoreContext()
  const [resolved, setResolved] = useState(false)
  const [monitoring, setMonitoring] = useState(false)
  const [assignMemberOptions, setAssignMemberOptions] = useState<any>([])

  const navigate = useNavigate()
  const [deletePopupOpen, setDeletePopUpOpen] = useState<boolean>(false)
  const [monitorPopupOpen, setMonitorPopUpOpen] = useState<boolean>(false)
  const [resolvedPopupOpen, setResolvedPopUpOpen] = useState<boolean>(false)
  const [shareModalOpen, setShareModalOpen] = React.useState<boolean>(false)

  const deleteLinks = (link: string) => {
    setLoading(true)

    const updatedLinks = incidentDetails.savedLinks.filter(
      (item: any) => item !== link
    )

    updateAnIncident({
      $incidentId: incidentId,
      savedLinks: updatedLinks
    })
      .then((response: any) => {
        const updatedIncident = {
          ...incidentDetails,
          savedLinks: response.data.savedLinks
        }

        setIncidentDetails(updatedIncident)
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.INCIDENT.DELETE_AN_LINK.SUCCESS
        })
      })
      .catch((error: any) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.INCIDENT.DELETE_AN_LINK.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCopyLink = (link: string) => {
    copy(link)
    SetLinkCopied(true)
    setTimeout(() => {
      SetLinkCopied(false)
    }, 3000)
  }

  const handlePublicShare = () => {
    setLoading(true)

    updateAnIncident({
      $incidentId: incidentId,
      isPublic: !incidentDetails.isPublic
    })
      .then((response: any) => {
        setIncidentDetails(response.data)
        return getAnIncident({ $incidentId: incidentId })
      })
      .then((incidentResponse: any) => {
        setIncidentDetails(incidentResponse.data)
      })
      .catch((error: any) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.INCIDENT.UPDATE_AN_INCIDENT.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const deleteIncident = () => {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    deleteAnIncident({
      $incidentId: incidentId
    })
      .then(() => {
        store.filterStore.setFiltersApplied(false)
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.INCIDENT.DELETE_AN_INCIDENT.SUCCESS
        })
        const route = replaceUrl(
          routeConstants.INCIDENT,
          store.scopeStore.getScope()['organization-id'] as string,
          store.scopeStore.getScope()['project-id'] as string,
          store.scopeStore.getScope()['environment-id'] as string
        )

        navigate(route)
      })
      .catch((err: any) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.INCIDENT.DELETE_AN_INCIDENT.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  useEffect(() => {
    const tabFromParams = searchParams.get('subTab')
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)

    if (tabFromParams) {
      setTabIndex(tabFromParams.toString())
    } else {
      setSearchParams({ ...allQueryParams, subTab: '1' })
    }

    if (!isUndefined(incidentDetails.incidentNumber)) {
      store.breadcrumbStore.setMultipleBreadcrumbsOptions([
        {
          label: 'Incidents',
          link:
            replaceUrl(
              routeConstants.INCIDENT,
              store.scopeStore.getScope()['organization-id'] as string,
              store.scopeStore.getScope()['project-id'] as string,
              store.scopeStore.getScope()['environment-id'] as string
            ) + '?page=1&tab=1'
        },
        {
          label: incidentDetails?.incidentNumber,
          link: routeConstants.MANAGE_INCIDENTS.replace(
            ':id',
            incidentId as string
          )
        }
      ])
    }
  }, [store.breadcrumbStore.getBreadcrumbsOptions(), incidentDetails])
  const fetchAnIncident = () => {
    setLoading(true)
    getAnIncident({ $incidentId: incidentId })
      .then((response: any) => {
        setIncidentDetails(response.data)
        setResolved(response.data.status === 'Resolved')
        setMonitoring(response.data.status === 'Monitoring')
      })
      .catch((error: any) => {
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const tabOptions = [
    {
      label:
        tabIndex === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            Details
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            Details
          </DDSTypography.Title>
        ),
      children: (
        <Details
          loading={loading}
          details={{
            priority: incidentDetails.priority as string,
            links: incidentDetails.savedLinks as string[],
            identifiedOn: incidentDetails.identifiedOn as string,
            longDescription: incidentDetails.longDescription as string,
            exceptionLogId: incidentDetails.exceptionLogId,
            exceptionType: incidentDetails.exceptionType
          }}
          deleteLink={(link: string) => {
            deleteLinks(link)
          }}
        />
      )
    },
    {
      label: (
        <div className='flex items-center justify-center gap-x-[12px]'>
          <DDSTypography.Title
            type='h5'
            variant={tabIndex === '2' ? 'semiBold' : 'medium'}
            color={
              tabIndex === '2'
                ? pallete.colors.primary2
                : pallete.colors.textDark5
            }
          >
            Activity
          </DDSTypography.Title>
          <div>
            {incidentDetails &&
            tabIndex === '1' &&
            incidentDetails.activityCount &&
            incidentDetails.activityCount.length > 0 ? (
              <span
                className='flex px-[6px] items-center justify-center w-[34px] h-[20px] rounded-full'
                style={{
                  color: pallete.colors.textDark5,
                  backgroundColor: pallete.colors.surface5
                }}
              >
                {incidentDetails?.activityCount}
              </span>
            ) : incidentDetails &&
              tabIndex === '2' &&
              incidentDetails.activityCount &&
              incidentDetails.activityCount.length > 0 ? (
              <span
                className='flex px-[6px] items-center justify-center w-[34px] h-[20px] rounded-full'
                style={{
                  color: pallete.colors.textDark2,
                  backgroundColor: pallete.colors.surface4
                }}
              >
                {incidentDetails?.activityCount}
              </span>
            ) : null}
          </div>
        </div>
      ),
      children: <IncidentActivity fetchAnIncident={fetchAnIncident} />
    }
  ]

  const markAsResolved = () => {
    setLoading(true)

    updateAnIncident({ $incidentId: incidentId, status: 'Resolved' })
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.INCIDENT.UPDATE_AN_INCIDENT.SUCCESS
        })

        fetchAnIncident()
      })
      .catch((error) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.INCIDENT.UPDATE_AN_INCIDENT.ERROR
        })
        setLoading(false)
      })
  }

  const markAsInProgress = () => {
    setLoading(true)
    updateAnIncident({ $incidentId: incidentId, status: 'In Progress' })
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.INCIDENT.UPDATE_AN_INCIDENT.SUCCESS
        })

        fetchAnIncident()
      })
      .catch((error) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.INCIDENT.UPDATE_AN_INCIDENT.ERROR
        })
        setLoading(false)
      })
  }

  const markAsMonitoring = () => {
    setLoading(true)
    updateAnIncident({ $incidentId: incidentId, status: 'Monitoring' })
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.INCIDENT.UPDATE_AN_INCIDENT.SUCCESS
        })
        setMonitorPopUpOpen(false)
        fetchAnIncident()
      })
      .catch((error) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.INCIDENT.UPDATE_AN_INCIDENT.ERROR
        })
        setLoading(false)
      })
  }

  const fetchProjectMembers = () => {
    const projectId = store.scopeStore.getSelectedProject().id
    setLoading(true)
    getAllProjectMembers(projectId as string)
      .then((response: any) => {
        const allMembers = response.data.map((member: any) => {
          return {
            id: member.id,
            name: member.user.firstName + ' ' + member.user.lastName,
            imgUrl: member.user.avatar,
            role: member.role
          }
        })
        setAssignMemberOptions(allMembers)
      })
      .catch(() => {
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.PROJECT_MEMBER.FIND_ALL_PROJECT_MEMBERS.ERROR
        })
        setLoading(false)
      })
  }

  const handleAssignMembers = (incidentId: string, selectedUser: any) => {
    const selectedUserId = selectedUser.map((item: any, index: any) => {
      return item.id
    })

    setLoading(true)
    updateAnIncident({
      $incidentId: incidentId,
      assignee: selectedUserId
    })
      .then((response: any) => {
        fetchAnIncident()
        fetchProjectMembers()
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.INCIDENT.UPDATE_INCIDENTS.SUCCESS
        })
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.INCIDENT.UPDATE_AN_INCIDENT.ERROR
        })
        setLoading(false)
      })
  }

  const status = incidentDetails.status

  React.useEffect(() => {
    setLoading(true)
    fetchAnIncident()
    fetchProjectMembers()
  }, [])
  return (
    <>
      <DocumentHeader header={HeaderConstants.INCIDENT} />
      {store.uiStore.getGlobalLoader() || loading ? (
        <Spinner />
      ) : (
        <div className='relative py-[24px]'>
          <div className='flex flex-col gap-[8px]'>
            <div className='flex flex-row h-[20px] items-center justify-between'>
              <Tooltip
                id='brief-description'
                label={incidentDetails?.briefDescription}
                position='bottom'
                className={`max-w-fit override-tooltip-arrow  ${
                  typeof incidentDetails?.briefDescription === 'string' &&
                  incidentDetails?.briefDescription.length > 60
                    ? incidentDetails?.briefDescription
                    : '!hidden'
                }`}
              >
                <DDSTypography.Title
                  type='h3'
                  variant='semiBold'
                  color={pallete.colors.textDark3}
                  className='tooltip-container sm:block overflow-hidden truncate w-[700px]'
                >
                  {incidentDetails?.briefDescription
                    ? incidentDetails?.briefDescription
                    : '-'}
                </DDSTypography.Title>
              </Tooltip>
              <div className='flex flex-row gap-x-[12px]'>
                {monitoring ? (
                  <DDSButton
                    label={
                      <DDSTypography.Paragraph
                        variant='semiBold'
                        size='para'
                        className='text-center whitespace-nowrap flex justify-center'
                        color={pallete.colors.neuButtonText}
                      >
                        Stop Monitoring
                      </DDSTypography.Paragraph>
                    }
                    onClick={markAsInProgress}
                    type='outline'
                    shape='roundedRectangle'
                    startIcon={
                      <PauseOutline color={pallete.colors.neuButtonText} />
                    }
                    buttonHoverTextColor={pallete.colors.textDark10}
                    buttonHoverBackgroundColor={pallete.colors.neuButtonHover}
                    size='small'
                    style={{
                      borderColor: pallete.colors.buttonOutlineGrey1,
                      color: pallete.colors.textDark10
                    }}
                    className='whitespace-nowrap !min-w-0'
                  />
                ) : (
                  <DDSButton
                    disabled={
                      status === 'Identified' ||
                      store.userStore.getNoActiveSubscription()
                    }
                    label={
                      <DDSTypography.Paragraph
                        variant='semiBold'
                        size='para'
                        className='text-center whitespace-nowrap flex justify-center'
                        color={pallete.colors.neuButtonText}
                      >
                        {monitoring ? 'Monitoring' : 'Monitor'}
                      </DDSTypography.Paragraph>
                    }
                    onClick={() => setMonitorPopUpOpen(true)}
                    type='outline'
                    shape='roundedRectangle'
                    buttonHoverTextColor={pallete.colors.textDark10}
                    buttonHoverBackgroundColor={pallete.colors.neuButtonHover}
                    size='small'
                    style={{
                      borderColor: pallete.colors.buttonOutlineGrey1,
                      color: pallete.colors.textDark10
                    }}
                    className='whitespace-nowrap !min-w-0'
                  />
                )}

                <DDSButton
                  label={
                    <DDSTypography.Paragraph
                      variant='semiBold'
                      size='para'
                      className='text-center whitespace-nowrap flex justify-center'
                      color={pallete.colors.textDark1}
                    >
                      {resolved ? 'Resolved' : 'Mark as Resolved'}
                    </DDSTypography.Paragraph>
                  }
                  disabled={
                    status === 'Identified' ||
                    status === 'In Progress' ||
                    resolved ||
                    store.userStore.getNoActiveSubscription()
                  }
                  type='filled'
                  shape='roundedRectangle'
                  buttonHoverTextColor={pallete.colors.textDark10}
                  size='small'
                  style={{
                    color: resolved
                      ? pallete.colors.white
                      : pallete.colors.white,
                    backgroundColor: resolved
                      ? pallete.colors.warningGreen
                      : pallete.colors.FillButton
                  }}
                  className='whitespace-nowrap !min-w-0'
                  buttonHoverBackgroundColor={pallete.colors.fillButtonhover}
                  onClick={markAsResolved}
                />
                <ThreeDotMenu
                  onShareClick={() => {
                    setShareModalOpen(true)
                  }}
                  onDeleteClick={() => {
                    setDeletePopUpOpen(true)
                  }}
                  disableNoSubscription={store.userStore.getNoActiveSubscription()}
                  disableEdit={resolved}
                  onEditClick={() => {
                    const data = {
                      incidentId: incidentDetails.id,
                      priorityStatus: incidentDetails.priority,
                      identifiedOn: incidentDetails.identifiedOn,
                      shortDescription: incidentDetails.briefDescription,
                      longDescription: incidentDetails.longDescription,
                      referenceLinks: incidentDetails.savedLinks as string[],
                      incidentNumber: incidentDetails.incidentNumber
                    }
                    store.incidentStore.setIncidentData(data)
                    const route = replaceUrl(
                      routeConstants.UPDATE_INCIDENT,
                      store.scopeStore.getScope()['organization-id'] as string,
                      store.scopeStore.getScope()['project-id'] as string,
                      store.scopeStore.getScope()['environment-id'] as string
                    ).replace(':incidentId', incidentId as string)
                    navigate(route)
                  }}
                />
              </div>
            </div>
            <div className='flex flex-row items-center gap-x-[12px] flex-wrap'>
              <div className='flex flex-row items-baseline gap-x-[4px]'>
                <DDSTypography.Paragraph
                  size='caption'
                  color={pallete.colors.textDark6}
                  variant='medium'
                  className='whitespace-nowrap'
                >
                  Incident no:
                </DDSTypography.Paragraph>
                <DDSTypography.Paragraph
                  size='para'
                  variant='semiBold'
                  color={pallete.colors.textDark3}
                >
                  {incidentDetails.incidentNumber}
                </DDSTypography.Paragraph>
              </div>
              <div className='flex items-center'>
                <div className='w-[1px] h-[20px] bg-gray-300'></div>
              </div>
              <div className='flex flex-row items-baseline gap-x-[4px]'>
                <DDSTypography.Paragraph
                  size='caption'
                  color={pallete.colors.textDark6}
                  variant='medium'
                  className='whitespace-nowrap'
                >
                  Created by:
                </DDSTypography.Paragraph>
                <DDSTypography.Paragraph
                  size='para'
                  variant='semiBold'
                  className='whitespace-nowrap'
                  color={pallete.colors.textDark3}
                >
                  {incidentDetails.createdBy}
                </DDSTypography.Paragraph>
              </div>
              <div className='flex items-center'>
                <div className='w-[1px] h-[20px] bg-gray-300'></div>
              </div>
              <div className='flex flex-row items-baseline gap-x-[4px]'>
                <DDSTypography.Paragraph
                  size='caption'
                  color={pallete.colors.textDark6}
                  variant='medium'
                  className='whitespace-nowrap'
                >
                  Created on:
                </DDSTypography.Paragraph>
                <DDSTypography.Paragraph
                  size='para'
                  variant='semiBold'
                  className='whitespace-nowrap'
                  color={pallete.colors.textDark3}
                >
                  {timeZone(
                    store.userStore.getUserPreference().timeZone,
                    incidentDetails.createdAt,
                    timeFormats.fullMonthDateYear
                  )}
                </DDSTypography.Paragraph>
              </div>
            </div>
          </div>
          <div className='relative pt-[16px]'>
            <Tabs
              tabOptions={tabOptions}
              tabOptionsStyle={{
                minWidth: 'fit-content',
                width: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px 12px',
                marginRight: '8px'
              }}
              id='tabs-grouped-api'
              onChange={(index: any) => {
                if (tabIndex != index) {
                  setTabIndex(index.toString())
                  setSearchParams({ ...searchParams, subTab: index.toString() })
                }
              }}
              activeTab={tabIndex}
            />
            <div className='absolute right-0  top-[10px] flex flex-row gap-x-[8px] items-center'>
              <DDSTypography.Title
                type='h5'
                variant='semiBold'
                className='whitespace-nowrap'
                color={pallete.colors.textDark3}
              >
                Assignee :
              </DDSTypography.Title>

              <IncidentMenuList
                id={`${incidentId}`}
                label='Assign Members'
                menuPosition='left'
                left={true}
                disabled={resolved}
                disableNoSubscription={store.userStore.getNoActiveSubscription()}
                userOptions={assignMemberOptions}
                selectedUsers={incidentDetails['assignee']}
                setSelectedUsers={(value: any) => {
                  handleAssignMembers(incidentDetails['id'], value)
                }}
              />
            </div>
          </div>
        </div>
      )}

      <ConfirmationPopup
        modalOpen={shareModalOpen}
        setModalOpen={setShareModalOpen}
        title='Share Incident'
        primaryButtonText={
          incidentDetails.isPublic ? (linkCopied ? 'Copied' : 'Copy Link') : ''
        }
        primaryButtonClick={() =>
          handleCopyLink(
            window.location.origin +
              '/' +
              'incident-shares' +
              '/' +
              incidentDetails.publicAccessToken
          )
        }
        showFooter={incidentDetails.isPublic}
        primaryButtonDisabled={loading || linkCopied}
        loading={loading}
        content={
          <div className='flex flex-col gap-y-[24px]'>
            <div className='flex flex-row   justify-between'>
              <div className='flex flex-col w-full gap-y-[4px] items-start justify-start'>
                <DDSTypography.Title
                  variant='medium'
                  type='h5'
                  className='w-5/6'
                >
                  Anyone with the link can view the incident. To {''}
                  <span>
                    {incidentDetails.isPublic
                      ? 'unshare, disable'
                      : 'share, enable'}{' '}
                    the toggle.
                  </span>
                </DDSTypography.Title>
              </div>
              <div className='flex items-center'>
                <Switch
                  onChange={handlePublicShare}
                  id='toggle-share'
                  disabled={loading}
                  defaultChecked={incidentDetails.isPublic === true}
                />
              </div>
            </div>
            {incidentDetails?.isPublic === true ? (
              <Input
                id='input-share'
                onChange={() => {
                  null
                }}
                width='100%'
                name='input'
                value={
                  window.location.origin +
                  '/' +
                  'incident-shares' +
                  '/' +
                  incidentDetails.publicAccessToken
                }
              />
            ) : null}
          </div>
        }
      />

      <DeleteIncidentPopup
        modalOpen={deletePopupOpen}
        setModalOpen={setDeletePopUpOpen}
        deleteLoading={loading}
        secondaryButtonClick={() => setDeletePopUpOpen(false)}
        primaryButtonClick={deleteIncident}
        editLoading={loading}
        showFooter={true}
        primaryButtonText='Yes'
        secondaryButtonText='No'
        primaryButtonDisabled={loading}
        secondaryButtonDisabled={loading}
        content={
          <div
            className='p-[24px] flex flex-col justify-center text-center items-center border-b'
            style={{ borderColor: pallete.colors.stroke2 }}
          >
            <DDSTypography.Title
              type='h4'
              variant='semiBold'
              color={pallete.colors.textDark3}
              className='line-clamp-2 w-[600px]'
            >
              Are you sure you want to delete incident :
            </DDSTypography.Title>
            <DDSTypography.Title
              type='h4'
              variant='bold'
              color={pallete.colors.textDark3}
            >
              {incidentDetails?.briefDescription} ?
            </DDSTypography.Title>
          </div>
        }
      />

      <StatusIncidentPopup
        modalOpen={monitorPopupOpen}
        setModalOpen={setMonitorPopUpOpen}
        deleteLoading={loading}
        secondaryButtonClick={() => setMonitorPopUpOpen(false)}
        primaryButtonClick={markAsMonitoring}
        editLoading={loading}
        showFooter={true}
        primaryButtonText='Yes'
        secondaryButtonText='No'
        primaryButtonDisabled={loading}
        secondaryButtonDisabled={loading}
        content={
          <div
            className='p-[24px] flex flex-col justify-center text-center items-center border-b'
            style={{ borderColor: pallete.colors.stroke2 }}
          >
            <DDSTypography.Title
              type='h4'
              variant='semiBold'
              color={pallete.colors.textDark3}
              className='line-clamp-2 w-[600px]'
            >
              Would you like to monitor and update the status as
            </DDSTypography.Title>
            <DDSTypography.Title
              type='h4'
              variant='semiBold'
              color={pallete.colors.textDark3}
            >
              {`"Monitoring" for this incident?`}
            </DDSTypography.Title>
          </div>
        }
      />
    </>
  )
}

export default ManageIncident
