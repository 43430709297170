import { Grid, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import DividerLine from '../../components/common/DividerLine/DividerLine'
import KeyValueTable from '../../components/APIMonitoringPage/KeyValueTable'
import { SalesforceAuditStore } from '../../store/SalesforceAuditStore'
import Table from '../../components/common/Table/Table'
import { TypographyDDS } from '../../components/common/Typography/TypographyDDS'
import UsageCard from '../../components/SalesforceAudit/common/UsageCard'
import arrowLeftIcon from '../../assets/icons/leftArrow.svg'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import { paginate } from '../../utils/CommonUtils'
import palette from '../../global/pallete'
import styled from '@emotion/styled'
import { timeFormats } from '../../enum/TIME'
import { useStoreContext } from '../../store/StoreContext'

const TitleWrapper = styled.div`
  color: ${palette.colors.lightGrey2};
`
const RoleDetails: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const params = useParams()
  const navigate = useNavigate()
  const { salesforceAuditStore } = useStoreContext()
  const loading = salesforceAuditStore.getLoading()
  const data = salesforceAuditStore.getRoleById(params.id!)
  const tableData = {
    'Role Name': data.Name,
    'Parent Role': salesforceAuditStore.getRoleById(data.ParentRoleId!)?.Name,
    Description: data.RollupDescription,
    'Last Modified by': salesforceAuditStore.getNameById(data.LastModifiedById),
    'Modified on': moment(data.LastModifiedDate).format(
      timeFormats.fullMonthDateYearTime
    )
  }
  const assignedUsersData = salesforceAuditStore.getUsersAssignedForRoleById(
    params.id!
  )
  const handleGoBack = () => {
    const segments = location.pathname.split('/').filter(Boolean)
    segments.pop()
    const newPath = '/' + segments.join('/')
    navigate(newPath)
  }
  const columns = [
    {
      title: <TitleWrapper>{`User's Name`}</TitleWrapper>,
      label: 'Name',
      render: (item: any) => item
    },
    {
      title: <TitleWrapper>Created At</TitleWrapper>,
      label: 'CreatedAtDate',
      render: (item: any) =>
        moment(item).format(timeFormats.fullMonthDateYearTime)
    },
    {
      title: <TitleWrapper>Profile</TitleWrapper>,
      label: 'Profile',
      render: (item: any) => item?.Name
    }
    // {
    //   title: <TitleWrapper>Last Modified</TitleWrapper>,
    //   label: 'LastModifiedDate',
    //   render: (item: any) =>
    //     moment(item).format(timeFormats.fullMonthDateYearTime)
    // },
    // {
    //   title: <TitleWrapper>Modified by</TitleWrapper>,
    //   label: 'LastModifiedById',
    //   render: (item: any) => salesforceAuditStore.getNameById(item)
    // }
    // {
    //   title: '',
    //   label: 'Id',
    //   render: (item: any) => (
    //     <Stack
    //       direction='row'
    //       alignItems='center'
    //       style={{ cursor: 'pointer' }}
    //       spacing={1}
    //       onClick={() => navigate(item)}
    //     >
    //       <span>View</span>
    //       <img src={arrowRightIcon} style={{ height: '16px' }} />
    //     </Stack>
    //   )
    // }
  ]
  return (
    <Grid container width='80%' paddingTop='16px' spacing={2}>
      <Grid item xs={12}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          style={{ cursor: 'pointer' }}
          onClick={() => handleGoBack()}
        >
          <img src={arrowLeftIcon} style={{ height: '16px' }} />
          <TypographyDDS.Paragraph size='para' color='textDark'>
            Go Back
          </TypographyDDS.Paragraph>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <TypographyDDS.Title type='h3' variant='bold'>
          {data.Name}
        </TypographyDDS.Title>
      </Grid>
      <Grid item xs={12}>
        <UsageCard noGutter>
          <TypographyDDS.Title
            type='h5'
            style={{
              margin: '24px'
            }}
          >
            Basic Details
          </TypographyDDS.Title>
          <DividerLine />
          <KeyValueTable data={tableData} noHeader />
        </UsageCard>
      </Grid>
      <Grid item xs={12}>
        <UsageCard>
          <Stack>
            <TypographyDDS.Paragraph size='para' color='textDark'>
              Total Users Assigned
            </TypographyDDS.Paragraph>
            <TypographyDDS.Title type='h1' variant='bold'>
              {assignedUsersData.length}
            </TypographyDDS.Title>
          </Stack>
          <Table
            columns={columns}
            data={paginate(
              assignedUsersData,
              currentPage,
              SalesforceAuditStore.pageSize
            )}
            totalCount={assignedUsersData.length}
            currentPage={currentPage}
            rowsPerPage={SalesforceAuditStore.pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </UsageCard>
      </Grid>
    </Grid>
  )
}
export default observer(RoleDetails)
