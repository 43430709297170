import React from 'react'
import palette from '../../../global/pallete'

interface DividerLineProps {
  padding?: string
  margin?: string
  color?: string
  width?: string
  height?: string
  variant?: 'vertical' | 'horizontal'
}

const DividerLine: React.FC<DividerLineProps> = ({
  padding = '0px 0px',
  margin,
  width,
  height,
  color = palette.colors.borderColor,
  variant = 'horizontal',
}) => {
  return (
    <div
      style={{
        padding: padding,
        margin: margin,
        width: `${
          variant === 'horizontal'
            ? width
              ? width
              : '100%'
            : variant === 'vertical'
            ? '1px'
            : width
        }`,
        height: `${
          variant === 'vertical'
            ? height
              ? height
              : '100%'
            : variant === 'horizontal'
            ? '1px'
            : height
        }`,
        borderTop: `${
          variant === 'horizontal' ? `1px solid ${color}` : 'none'
        }`,
        borderLeft: `${variant === 'vertical' ? `1px solid ${color}` : 'none'}`,
      }}
    ></div>
  )
}

export default DividerLine
