import { Button } from '../../New/Button/Button'
import { DDSTypography } from 'den-design-system'
import JMStringConstants from '../../../constants/JMStringConstants'
import Modal from '../../common/DDS/Modal/Modal'
import React from 'react'
import Select from '../../New/Select/Select'
import StringConstants from '../../../constants/StringConstants'
import pallete from '../../../global/newPallete'
import { useStoreContext } from '../../../store/StoreContext'

interface copyProps {
  open: boolean
  setOpen: any
  selectedEnvironment: string
  loading: boolean
  buttonLoading: boolean
  onCancel: () => void
  onImport: () => void
  handleEnvironmentSelect: (value: string) => void
}
const ImportJobPopup: React.FC<copyProps> = ({
  open,
  setOpen,
  selectedEnvironment,
  loading,
  buttonLoading,
  onCancel,
  onImport,
  handleEnvironmentSelect
}) => {
  const store = useStoreContext()
  const CopyJobFooter: React.FC = () => {
    return (
      <div className='flex override-btn-min-width justify-end px-[24px] py-[16px] gap-[10px]'>
        <Button
          id='cancel-modal-btn'
          type='neutral'
          disabled={buttonLoading}
          label={JMStringConstants.CANCEL_BUTTON}
          onClick={onCancel}
        />
        <Button
          id='copyjob-modal-btn'
          type='filled'
          disabled={
            !selectedEnvironment ||
            buttonLoading ||
            store.userStore.getNoActiveSubscription()
          }
          loading={buttonLoading}
          label={JMStringConstants.COPY_JOB_BUTTON}
          onClick={onImport}
        />
      </div>
    )
  }
  const getEnvironments = () => {
    const environments = store.scopeStore.getEnvironments()
    const currentEnvId = store.scopeStore.getScope()['environment-id']

    return environments
      .filter((env) => env.id !== currentEnvId)
      .map((env) => ({
        label: (
          <DDSTypography.Paragraph
            variant='medium'
            size='caption'
            className='truncate flex items-start'
            color={pallete.colors.textDark4}
          >
            {env.type == StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
              ? StringConstants.ENVIRONMENT_NAMES.NON_PROD
              : StringConstants.ENVIRONMENT_NAMES.PROD}
          </DDSTypography.Paragraph>
        ),
        value: env.id
      }))
  }
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className={`${loading && 'cursor-not-allowed'}`}
      showHeader
      title={JMStringConstants.COPY_JOB_TITLE}
      footer={<CopyJobFooter />}
      onClosePress={() => null}
    >
      <div
        className={`${loading && 'pointer-events-none'} flex flex-col p-[24px] gap-[24px] override-env-select-popup`}
      >
        <div className='flex flex-col gap-[1px]'>
          <DDSTypography.Title
            type='h4'
            variant='medium'
            color={pallete.colors.darkGrey}
          >
            {JMStringConstants.COPY_JOB_DESC_TEXT}
          </DDSTypography.Title>
          <DDSTypography.Title
            type='h4'
            variant='medium'
            color={pallete.colors.darkGrey}
          >
            {JMStringConstants.COPY_JOB_CHOOSE_ENV_TEXT}
          </DDSTypography.Title>
        </div>
        <Select
          id='env-select'
          name='Environment'
          placeholder={JMStringConstants.COPY_JOB_SELECT_ENV_PLACEHOLDER}
          options={getEnvironments()}
          searchable={false}
          onSelect={handleEnvironmentSelect}
        />
      </div>
    </Modal>
  )
}

export default ImportJobPopup
