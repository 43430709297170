import MuiTab from '@mui/material/Tab'
import MuiTabs from '@mui/material/Tabs'
import React from 'react'
import breakpoints from '../../../global/breakpoints'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

interface TabGroupProps {
  onChange: (event: React.SyntheticEvent, newValue: number) => void
  currentTab: number
  tabLabels: { label: string; icon?: JSX.Element }[]
  iconPosition?: 'bottom' | 'end' | 'start' | 'top'
  tabChildren?: React.ReactNode[]
  $disableSwitch?: boolean
}

interface StyledTabsProps {
  children?: React.ReactNode
  value: number
  onChange: (event: React.SyntheticEvent, newValue: number) => void
  $disableSwitch: boolean
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

interface StyledTabProps {
  label: string
  icon?: JSX.Element
  iconPosition?: 'bottom' | 'end' | 'start' | 'top'
}

const Container = styled.div`
  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.85rem;
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: 0.7rem;
  }
`

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Tabs = styled(
  (props: StyledTabsProps) => (
    <MuiTabs
      {...props}
      TabIndicatorProps={{
        children: <span className='MuiTabs-indicatorSpan' />
      }}
    />
  ),
  {
    shouldForwardProp: (prop: string) => !prop.startsWith('$')
  }
)((props: StyledTabsProps) => ({
  '& .MuiButtonBase-root': {
    textTransform: 'capitalize',
    padding: '0 20px',
    fontSize: '16px',
    lineHeight: '20px',
    cursor: props.$disableSwitch ? 'not-allowed' : 'cursor'
  },
  '.MuiTab-iconWrapper': {
    marginRight: '8px',
    height: '20px'
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '4px'
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 20,
    width: '100%',
    backgroundColor: palette.colors.primary,
    borderRadius: '2px'
  }
}))

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      {...other}
      style={{ paddingTop: children === undefined ? '0' : '1.875em' }}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

const Tab = styled(
  (props: StyledTabProps) => <MuiTab disableRipple {...props} />,
  {
    shouldForwardProp: (prop: string) => !prop.startsWith('$')
  }
)(() => ({
  '&.Mui-selected': {
    filter: palette.colors.primarySvgFilter
  },
  '&.Mui-focusVisible': {}
}))

const TabGroup: React.FC<TabGroupProps> = ({
  onChange,
  currentTab,
  tabLabels,
  tabChildren,
  iconPosition,
  $disableSwitch = false
}) => {
  return (
    <Container>
      <TabContainer>
        <Tabs
          value={currentTab}
          onChange={onChange}
          $disableSwitch={$disableSwitch}
        >
          {tabLabels.map((tabLabel, index) => (
            <Tab
              key={index}
              label={tabLabel.label}
              icon={tabLabel?.icon}
              iconPosition={iconPosition}
            />
          ))}
        </Tabs>
      </TabContainer>
      {tabChildren?.map((childComponent, index) => (
        <TabPanel value={currentTab} index={index} key={index}>
          {childComponent}
        </TabPanel>
      ))}
    </Container>
  )
}

export default TabGroup
