import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'

import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import { ProjectType } from '../../../enum/ProjectType.enum'
import { getUserDetails } from '../../../api/user/User.service'
import { observer } from 'mobx-react-lite'
import routeConstants from '../../../constants/RouteConstants'
import { useStoreContext } from '../../../store/StoreContext'

const UCPageLayout: React.FC = () => {
  const storeContext = useStoreContext()

  const store = useStoreContext()
  return (
    <>
      <DocumentHeader
        header={
          storeContext.scopeStore.getSelectedProject().type ===
          ProjectType.FULL_STACK_ONLY
            ? HeaderConstants.UPTIME_CHECKS
            : HeaderConstants.UPTIME_CHECKS
        }
      />
      <Outlet />
    </>
  )
}

export default observer(UCPageLayout)
