import * as React from 'react'

interface MulesoftIconProps {
  width?: number
  height?: number
  marginLeft?: number | string
  color?: string
}

const MulesoftIcon: React.FC<MulesoftIconProps> = ({
  width = 25,
  height = 25,
  marginLeft = '4px',
  color
}) => {
  return (
    <img
      src={require('../../../assets/icons/mulesoft.png')}
      style={{
        width: width,
        height: height,
        color: color,
        marginLeft: marginLeft
      }}
    />
  )
}

export default MulesoftIcon
