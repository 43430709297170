import { Grid, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import LicenseUsageCard from '../../components/SalesforceAudit/LicenseUsageCard'
import { SalesforceAuditStore } from '../../store/SalesforceAuditStore'
import Table from '../../components/common/Table/Table'
import { TypographyDDS } from '../../components/common/Typography/TypographyDDS'
import UsageCard from '../../components/SalesforceAudit/common/UsageCard'
import arrowLeftIcon from '../../assets/icons/leftArrow.svg'
import { paginate } from '../../utils/CommonUtils'
import palette from '../../global/pallete'
import styled from '@emotion/styled'
import { useStoreContext } from '../../store/StoreContext'

const TitleWrapper = styled.div`
  color: ${palette.colors.lightGrey2};
`
const LicenseDetails: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const params = useParams()
  const navigate = useNavigate()
  const { salesforceAuditStore } = useStoreContext()
  const loading = salesforceAuditStore.getLoading()
  const data = salesforceAuditStore.getLicenseById(params.id!)
  const availableLicenses = data.TotalLicenses - data.UsedLicenses
  const usersWithLicense = salesforceAuditStore.getUsersWithLicenseById(
    params.id!
  )

  const usage =
    data.TotalLicenses < 0
      ? 0
      : +((data.UsedLicenses / data.TotalLicenses) * 100).toFixed(2)
  const handleGoBack = () => {
    const segments = location.pathname.split('/').filter(Boolean)
    segments.pop()
    const newPath = '/' + segments.join('/')
    navigate(newPath)
  }
  const columns = [
    {
      title: <TitleWrapper>{`User's Name`}</TitleWrapper>,
      label: 'Name',
      render: (item: any) => item
    },
    {
      title: <TitleWrapper>Email</TitleWrapper>,
      label: 'Email',
      render: (item: any) => item
    },
    {
      title: <TitleWrapper>Profile</TitleWrapper>,
      label: 'Profile',
      render: (item: any) => item?.Name
    }
  ]
  return (
    <Grid container width='80%' paddingTop='16px' spacing={2}>
      <Grid item xs={12}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          style={{ cursor: 'pointer' }}
          onClick={() => handleGoBack()}
        >
          <img src={arrowLeftIcon} style={{ height: '16px' }} />
          <TypographyDDS.Paragraph size='para' color='textDark'>
            Go Back
          </TypographyDDS.Paragraph>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <TypographyDDS.Title type='h3' variant='bold'>
          {data.Name}
        </TypographyDDS.Title>
      </Grid>
      <Grid item xs={12}>
        <LicenseUsageCard
          usage={usage}
          totalLicenses={data.TotalLicenses}
          usedLicenses={data.UsedLicenses}
          availableLicenses={availableLicenses < 0 ? 0 : availableLicenses}
        />
      </Grid>
      <Grid item xs={12}>
        <TypographyDDS.Title type='h3' variant='bold'>
          Users with this license
        </TypographyDDS.Title>
      </Grid>
      <Grid item xs={12}>
        <UsageCard>
          <Table
            columns={columns}
            data={paginate(
              usersWithLicense,
              currentPage,
              SalesforceAuditStore.pageSize
            )}
            totalCount={usersWithLicense.length}
            currentPage={currentPage}
            rowsPerPage={SalesforceAuditStore.pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </UsageCard>
      </Grid>
    </Grid>
  )
}

export default LicenseDetails
