import { DDSChips, DDSTypography } from 'den-design-system'
import React, { useEffect, useState } from 'react'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'
import { msToTimeUnits, timeZone } from '../../../utils/DateTimeUtils'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import ApiGroupingStringConstants from '../../../constants/ApiGroupingStringConstants'
import { ChevronRight } from '@carbon/icons-react'
import { Link } from '@mui/material'
import NoFilterImage from '../../../assets/images/folder-not-found 1.svg'
import NoRecord from '../../../components/common/NoContent/NoRecord'
import NoRecordImage from '../../../assets/images/setting.svg'
import Spinner from '../../../components/common/Spinner/Spinner'
import StringConstants from '../../../constants/StringConstants'
import Table from '../../../components/common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../components/New/Tooltip/Tooltip'
import { findRecords } from '../../../api/apiMonitoring/ApiMonitoring.service'
import { isNull } from 'lodash'
import { mapStatusCodeToColor } from '../../../utils/ApiStatusColorCode'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { timeFormats } from '../../../enum/TIME'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useStoreContext } from '../../../store/StoreContext'

type ApiRecord = { [key: string]: string }

interface viewHeaderGroupedApiDataProp {
  viewHeaderGroupedApiData: () => Promise<void>
  fetchAPILogFilters: () => void
  routeGroupedDetails: (id: any) => void
  setTriggerFilter?: any
}

const GroupedAPILogTable: React.FC<viewHeaderGroupedApiDataProp> = ({
  viewHeaderGroupedApiData,
  fetchAPILogFilters,
  routeGroupedDetails,
  setTriggerFilter
}) => {
  const [tableData, setTableData] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0)

  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate()
  const { id } = useParams()

  const store = useStoreContext()
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.API_MONITORING_TABLE
  )

  const organizations = store.userStore.getOrganizations()
  const foundOrganization = organizations.find(
    (organization: any) =>
      organization.id === store.scopeStore.getOrganizationId()
  )

  const fetchGroupApiRecord = async () => {
    store.apiDetailsStore.reset()
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const pageSize = StringConstants.DEFAULT_TABLE_SIZE
    if (!id) return
    try {
      const { data, totalCount, filters } = (await findRecords({
        ...queryParams,
        'api-group-id': id,
        size: pageSize
      })) as {
        data: ApiRecord[]
        totalCount: number
        filters: any
      }
      if (data.length > 0 || store.filterStore.getFiltersApplied()) {
        store.filterStore.setFilterDisabled(false)
      } else {
        store.filterStore.setFilterDisabled(true)
      }
      setTableData(data)
      setTotalCount(totalCount)
      store.filterStore.setFilters(filters)
    } catch (error) {
      store.apiDetailsStore.setIsError(true)
      const errorMessage =
        error instanceof Error ? error.message : 'An unknown error occurred'
      ToastNotification({
        type: 'error',
        message:
          ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR ||
          errorMessage
      })
      store.uiStore.setGlobalLoader(false)
      setLoading(false)
    } finally {
      setTriggerFilter(false)
      store.uiStore.setGlobalLoader(false)
      setLoading(false)
    }
  }

  const fetchGroupSubscriptionApiRecord = async () => {
    store.apiDetailsStore.reset()
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const pageSize = StringConstants.DEFAULT_TABLE_SIZE
    if (!id) return
    try {
      const { data, totalCount, filters } = (await findRecords({
        ...queryParams,
        'api-group-id': id,
        size: pageSize
      })) as {
        data: ApiRecord[]
        totalCount: number
        filters: any
      }
      if (data.length > 0 || store.filterStore.getFiltersApplied()) {
        store.filterStore.setFilterDisabled(false)
      } else {
        store.filterStore.setFilterDisabled(true)
      }
      setTableData(data)
      setTotalCount(totalCount)
      store.filterStore.setFilters(filters)
    } catch (error) {
      store.apiDetailsStore.setIsError(true)
      const errorMessage =
        error instanceof Error ? error.message : 'An unknown error occurred'
      ToastNotification({
        type: 'error',
        message:
          ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR ||
          errorMessage
      })
      store.uiStore.setGlobalLoader(false)
      setLoading(false)
    } finally {
      store.uiStore.setGlobalLoader(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    const pageFromQueryParams = searchParams.get('page')

    if (!isNull(pageFromQueryParams)) {
      setCurrentPage(Number(pageFromQueryParams))

      if (foundOrganization?.subscription?.trackApiUsage !== true) {
        fetchGroupSubscriptionApiRecord()
      } else {
        fetchGroupApiRecord()
      }
    } else {
      setSearchParams({ page: '1' })
    }
  }, [store.scopeStore.getScope(), searchParams])

  useEffect(() => {
    if (store.apiDetailsStore.getRefresh() === true) {
      setLoading(true)
      store.uiStore.setGlobalLoader(true)
      fetchGroupApiRecord()
      viewHeaderGroupedApiData()
      fetchAPILogFilters()
    }
  }, [store.apiDetailsStore.getRefresh()])

  const columns = [
    ...(foundOrganization?.subscription?.trackApiUsage === true
      ? [
          {
            columnHeaderStyle: { width: '300px' },
            columnDataStyle: { width: '300px' },
            heading: (
              <div className='flex justify-start text-start row items-start'>
                <DDSTypography.Paragraph
                  size='caption'
                  variant='semiBold'
                  color={pallete.colors.textDark6}
                >
                  {ApiGroupingStringConstants.USER}
                </DDSTypography.Paragraph>
              </div>
            ),
            dataLabel: 'apiUserName',
            render: (text: any) => (
              <div className='flex justify-start text-start row items-start max-w-[230px]'>
                <div>
                  <Tooltip
                    id={`tooltip-name-${text}`}
                    label={text}
                    className={`override-tooltip-arrow ${
                      typeof text === 'string' && text.length > 2
                        ? text
                        : '!hidden'
                    }`}
                  >
                    <div>
                      <DDSTypography.Paragraph
                        size='para'
                        variant='medium'
                        color={pallete.colors.textDark4}
                        className='tooltip-container sm:block overflow-hidden truncate max-w-[230px]'
                      >
                        {text || text === 0 ? text : '-'}
                      </DDSTypography.Paragraph>
                    </div>
                  </Tooltip>
                </div>
              </div>
            )
          }
        ]
      : []),
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row items-center justify-center text-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.CLIENT_VERSION}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'clientVersion',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text || text === 0 ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.RESPONSE_STATUS}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'responseStatusCode',
      render: (text: any) => (
        <div
          className={`flex justify-center override-statusCode-center override-status-chip`}
        >
          <DDSChips
            label={text}
            type='filled'
            shape='rounded'
            size='small'
            className='override-status-chip'
            style={{
              backgroundColor: mapStatusCodeToColor(text),
              borderColor: 'transparent',
              height: '24px',
              width: '60px',
              justifyContent: 'center'
            }}
          />
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.START_TIME}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'startTime',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{ justifyContent: 'center' }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {timeZone(
              store.userStore.getUserPreference().timeZone,
              text,
              timeFormats.fullMonthDateYearTimeWithSeconds
            )}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.RESPONSE_TIME}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'responseTimeInMilliseconds',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{ justifyContent: 'center' }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text ? msToTimeUnits(text) : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: { width: '20px' },
      columnDataStyle: { width: '20px' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='cursor-pointer'>
          <ChevronRight
            size={16}
            onClick={() => {
              gaEventTracker(gaEventConstants.VIEW_SINGLE_API_RECORD)
              routeGroupedDetails(fullItem.id)
            }}
            color={pallete.colors.FillButton}
          />
        </div>
      )
    }
  ]
  return (
    <>
      {loading || store.uiStore.getGlobalLoader() || !tableData ? (
        <Spinner />
      ) : tableData.length !== 0 ? (
        <div className='mt-[24px] flex w-full '>
          <Table
            tableStyle={{
              borderColor: pallete.colors.stroke2
            }}
            data={tableData}
            columns={columns}
            totalCount={totalCount}
            rowsPerPage={10}
            currentPage={currentPage}
            onPageChange={(page: number) => {
              const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                location.search
              )
              setCurrentPage(page)
              setSearchParams({ ...allQueryParams, page: page.toString() })
            }}
            loading={loading}
          />
        </div>
      ) : (
        <>
          <NoRecord
            imageSrc={
              totalCount === 0 && store.filterStore.getFiltersApplied()
                ? NoFilterImage
                : NoRecordImage
            }
            style={{ height: '74vh' }}
            text={
              totalCount === 0 && store.filterStore.getFiltersApplied() ? (
                <>No results found for applied filter.</>
              ) : (
                <>
                  Configure API Monitoring.
                  <Link
                    href={StringConstants.FULLSTACK_URL}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ marginLeft: '4px' }}
                  >
                    Learn More
                  </Link>
                </>
              )
            }
          />
        </>
      )}
    </>
  )
}

export default observer(GroupedAPILogTable)
