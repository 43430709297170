import { DDSTypography } from 'den-design-system'
import React from 'react'
import pallete from '../../../../global/newPallete'

export const fullStackTourSteps = [
  {
    selector: `#Overview`,
    content: () => (
      <div className='flex flex-col gap-[12px]'>
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark4}
        >
          Overview
        </DDSTypography.Title>
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark6}
        >
          Get a bird’s-eye view of exception logs, API activity, uptime checks,
          and jobs on a single dashboard.
        </DDSTypography.Paragraph>
      </div>
    )
  },
  {
    selector: `#Exceptions`,
    content: () => (
      <div className='flex flex-col gap-[12px]'>
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark4}
        >
          Exceptions
        </DDSTypography.Title>
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark6}
        >
          Spot and report exceptions in your project and assign them to your
          team for a quick fix.
        </DDSTypography.Paragraph>
      </div>
    )
  },
  {
    selector: `#Api-Monitoring`,
    content: () => (
      <div className='flex flex-col gap-[12px]'>
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark4}
        >
          API Monitoring
        </DDSTypography.Title>
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark6}
        >
          Monitor API response statuses and catch failures before they escalate.
        </DDSTypography.Paragraph>
      </div>
    )
  },
  {
    selector: `#Uptime-Checks`,
    content: () => (
      <div className='flex flex-col gap-[12px]'>
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark4}
        >
          Uptime Checks
        </DDSTypography.Title>
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark6}
        >
          Help you check your application availability and notifies when it goes
          down.
        </DDSTypography.Paragraph>
      </div>
    )
  },
  {
    selector: `#Escalations`,
    content: () => (
      <div className='flex flex-col gap-[12px]'>
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark4}
        >
          Escalations
        </DDSTypography.Title>
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark6}
        >
          Set priority levels so the right stakeholders know when things go
          wrong, fast.
        </DDSTypography.Paragraph>
      </div>
    )
  },
  {
    selector: `#Incident`,
    content: () => (
      <div className='flex flex-col gap-[12px]'>
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark4}
        >
          Incident
        </DDSTypography.Title>
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark6}
        >
          Turn exceptions into incidents, track progress, and resolve them
          easily.
        </DDSTypography.Paragraph>
      </div>
    )
  },
  {
    selector: `#Jobs`,
    content: () => (
      <div className='flex flex-col gap-[12px]'>
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark4}
        >
          Scheduled Jobs
        </DDSTypography.Title>
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark6}
        >
          Ensure your jobs run smoothly without any potential failures.
        </DDSTypography.Paragraph>
      </div>
    )
  }
]

export const salesforceTourSteps = [
  {
    selector: `#Overview`,
    content: () => (
      <div className='flex flex-col gap-[12px]'>
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark4}
        >
          Overview
        </DDSTypography.Title>
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark6}
        >
          Find all your error logs and uptime checks, right here.
        </DDSTypography.Paragraph>
      </div>
    )
  },
  {
    selector: `#Error`,
    content: () => (
      <div className='flex flex-col gap-[12px]'>
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark4}
        >
          Errors
        </DDSTypography.Title>
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark6}
        >
          See errors, report them, and delegate them to the team for action.
        </DDSTypography.Paragraph>
      </div>
    )
  },

  {
    selector: `#Uptime-Checks`,
    content: () => (
      <div className='flex flex-col gap-[12px]'>
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark4}
        >
          Uptime Checks
        </DDSTypography.Title>
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark6}
        >
          Keep tabs on app availability and get alerts for downtime.
        </DDSTypography.Paragraph>
      </div>
    )
  },
  {
    selector: `#Escalations`,
    content: () => (
      <div className='flex flex-col gap-[12px]'>
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark4}
        >
          Escalations
        </DDSTypography.Title>
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark6}
        >
          Notify stakeholders when high-priority errors need attention.
        </DDSTypography.Paragraph>
      </div>
    )
  },
  {
    selector: `#Incident`,
    content: () => (
      <div className='flex flex-col gap-[12px]'>
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark4}
        >
          Incident
        </DDSTypography.Title>
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark6}
        >
          Track and manage error incidents from start to finish.
        </DDSTypography.Paragraph>
      </div>
    )
  },
  {
    selector: `#SalesForce`,
    content: () => (
      <div className='flex flex-col gap-[12px]'>
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark4}
        >
          Salesforce Audit
        </DDSTypography.Title>
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark6}
        >
          Track usage limits and get notified before you hit the limits.
        </DDSTypography.Paragraph>
      </div>
    )
  }
]

export const mulesoftTourSteps = [
  {
    selector: `#Overview`,
    content: () => (
      <div className='flex flex-col gap-[12px]'>
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark4}
        >
          Overview
        </DDSTypography.Title>
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark6}
        >
          Comprehensive view of all error logs and uptime checks.
        </DDSTypography.Paragraph>
      </div>
    )
  },
  {
    selector: `#Error`,
    content: () => (
      <div className='flex flex-col gap-[12px]'>
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark4}
        >
          Errors
        </DDSTypography.Title>
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark6}
        >
          Catch, report, and assign Mulesoft errors for quick resolution.
        </DDSTypography.Paragraph>
      </div>
    )
  },

  {
    selector: `#Uptime-Checks`,
    content: () => (
      <div className='flex flex-col gap-[12px]'>
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark4}
        >
          Uptime Checks
        </DDSTypography.Title>
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark6}
        >
          Stay informed about application downtime and availability.
        </DDSTypography.Paragraph>
      </div>
    )
  },
  {
    selector: `#Escalations`,
    content: () => (
      <div className='flex flex-col gap-[12px]'>
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark4}
        >
          Escalations
        </DDSTypography.Title>
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark6}
        >
          Prioritise error notifications and loop in the right people.
        </DDSTypography.Paragraph>
      </div>
    )
  },
  {
    selector: `#Incident`,
    content: () => (
      <div className='flex flex-col gap-[12px]'>
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark4}
        >
          Incident
        </DDSTypography.Title>
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark6}
        >
          Create incidents from errors and follow their resolution closely.
        </DDSTypography.Paragraph>
      </div>
    )
  }
]
