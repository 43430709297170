import UnsecureComponent from '../../components/Layout/UnsecureComponent'
import { isAuthenticatedUser } from '../../utils/AuthUtils'
import routeConstants from '../../constants/RouteConstants'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const HomePage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticatedUser()) {
      navigate(routeConstants.REDIRECT)
    }
  }, [])

  return null
}

export default UnsecureComponent(HomePage)
