import { DDSButton, DDSModal, DDSTypography } from 'den-design-system'

import { Button } from '../../New/Button/Button'
import React from 'react'
import { SetStateAction } from 'react'
import pallete from '../../../global/newPallete'
import { useStoreContext } from '../../../store/StoreContext'

interface ModalFooterProps {
  primaryButtonText?: string
  secondaryButtonText?: string
  onYes?: () => any
  onNo?: () => any
  primaryButtonDisabled?: boolean
  secondaryButtonDisabled?: boolean
  deleteLoading?: boolean
  editLoading?: boolean
}
const ModalFooter: React.FC<ModalFooterProps> = ({
  onYes,
  onNo,
  primaryButtonText = '',
  secondaryButtonText = '',
  primaryButtonDisabled,
  secondaryButtonDisabled,
  deleteLoading,
  editLoading
}) => {
  const store = useStoreContext()
  return (
    <div className='flex flex-row gap-x-[8px] px-[24px] py-[16px] override-btn-min-width justify-end'>
      {secondaryButtonText !== '' && (
        <Button
          id='secondary-btn'
          label={
            <DDSTypography.Paragraph
              variant='semiBold'
              size='para'
              className='text-center whitespace-nowrap flex justify-center !min-w-0'
              color={pallete.colors.textDark10}
            >
              {secondaryButtonText}
            </DDSTypography.Paragraph>
          }
          type='neutral'
          size='small'
          disabled={editLoading || deleteLoading}
          onClick={() => (onNo ? onNo() : undefined)}
        />
      )}
      {primaryButtonText !== '' && (
        <div className='override-btn-min-width'>
          <Button
            id='primary-button'
            size='small'
            disabled={
              deleteLoading ||
              editLoading ||
              store.userStore.getNoActiveSubscription()
            }
            loading={deleteLoading || editLoading}
            type='dangerFilled'
            label={primaryButtonText}
            onClick={() => (onYes ? onYes() : undefined)}
          />
        </div>
      )}
    </div>
  )
}

interface DeletePopupProps {
  modalOpen: boolean
  setModalOpen: React.Dispatch<SetStateAction<boolean>>

  content: React.ReactNode
  showFooter?: boolean
  primaryButtonClick?: any
  secondaryButtonClick?: any
  primaryButtonText?: string
  secondaryButtonText?: string
  primaryButtonDisabled?: boolean
  secondaryButtonDisabled?: boolean
  deleteLoading?: boolean
  editLoading?: boolean
}
export const DeletePopup: React.FC<DeletePopupProps> = ({
  modalOpen,
  setModalOpen,
  content,
  primaryButtonClick,
  secondaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  primaryButtonDisabled,
  secondaryButtonDisabled,
  showFooter = false,
  deleteLoading,
  editLoading
}) => {
  return (
    <DDSModal
      open={modalOpen}
      setOpen={setModalOpen}
      defaultFooter={false}
      onIconClose={() => setModalOpen(false)}
      hideCloseIcon={deleteLoading || editLoading}
    >
      <div>
        <div
          className='flex items-start flex-row py-[16px] px-[24px]  border-b'
          style={{ borderBottomColor: pallete.colors.stroke2 }}
        >
          <DDSTypography.Title
            variant='bold'
            type='h3'
            className='text-center flex items-start'
          >
            Delete Message
          </DDSTypography.Title>
        </div>
        {content}
      </div>

      {showFooter && (
        <ModalFooter
          onYes={primaryButtonClick}
          onNo={secondaryButtonClick}
          deleteLoading={deleteLoading}
          editLoading={editLoading}
          primaryButtonText={primaryButtonText}
          secondaryButtonText={secondaryButtonText}
          primaryButtonDisabled={primaryButtonDisabled}
          secondaryButtonDisabled={secondaryButtonDisabled}
        />
      )}
    </DDSModal>
  )
}
