import React, { useEffect, useState } from 'react'

import { AddFilled } from '@carbon/icons-react'
import Chip from '../../New/Chip/Chip'
import { DDSTypography } from 'den-design-system'
import DropdownMenu from '../../New/DropdownMenu/DropdownMenu'
import Search from '../../New/Search/Search'
import UserAvatar from '../UserAvatar/UserAvatar'
import emptyAvatar from '../../../assets/icons/emptyAvatar.svg'
import pallete from '../../../global/newPallete'

interface PriorityDropDownProps {
  id?: string
  label?: string
  editable?: boolean
  maxHeight?: string
  userOptions?: any[]
  menuPosition?: string
  arrowPosition?: 'left' | 'right' | 'center'
  selectedUsers?: any
  setSelectedUsers?: any
  disableNoSubscription?: boolean
}

const toTitleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

const AssignedToMenuList: React.FC<PriorityDropDownProps> = ({
  id,
  label,
  maxHeight,
  editable = true,
  userOptions = [],
  selectedUsers = [],
  setSelectedUsers,
  menuPosition = 'right',
  arrowPosition = 'center',
  disableNoSubscription = false
}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [allUsers, setAllUsers] = useState<any>([])
  const [menuTop, setMenuTop] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    // Filter out already selected users from the dropdown options
    const filteredUsers = userOptions.filter(
      (option) =>
        !selectedUsers.some((selected: any) => selected.id === option.id)
    )
    setAllUsers(filteredUsers)
  }, [userOptions, selectedUsers])

  const getMenuPosition = () => {
    if (menuPosition === 'left') {
      return '-translate-x-[85%]'
    } else if (menuPosition === 'center') {
      return '-translate-x-[40%]'
    } else if (menuPosition === 'right') {
      return ''
    }
  }

  const avatarObjects: { name: string; avatar: string }[] = selectedUsers.map(
    (user: any) => ({
      name: user.name,
      avatar: user.imgUrl
    })
  )

  const removeSelectedUser = (itemId: string) => {
    const selectedUsers_ = selectedUsers.filter(
      (user: any) => user.id !== itemId
    )
    setSelectedUsers([...selectedUsers_])
  }

  const dropDownRef = React.useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropDownRef.current &&
      !dropDownRef.current.contains(event.target as Node)
    ) {
      setMenuOpen(false)
    }
  }

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('click', handleClickOutside)
    } else {
      document.removeEventListener('click', handleClickOutside)
    }

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [menuOpen])

  const toggleMenu = () => {
    if (dropDownRef.current) {
      const rect = dropDownRef.current.getBoundingClientRect()
      const spaceBelow = window.innerHeight - rect.bottom
      const spaceAbove = rect.top

      if (spaceBelow < 200 && spaceAbove > 200) {
        setMenuTop(true)
      } else {
        setMenuTop(false)
      }
    }
    setMenuOpen(!menuOpen)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value
    setSearchTerm(searchValue)

    const filteredUsers = userOptions
      .filter(
        (user) =>
          !selectedUsers.some((selected: any) => selected.id === user.id)
      ) // Remove selected users
      .filter((user) =>
        user.name.toLowerCase().includes(searchValue.toLowerCase())
      ) // Apply search filter

    setAllUsers(filteredUsers)
  }

  return (
    <div className='relative h-full w-full'>
      <div ref={dropDownRef}>
        <div
          id={`menu-list-btn${id && '-' + id}`}
          onClick={toggleMenu}
          className='flex w-fit items-center gap-[4px] justify-start cursor-pointer'
        >
          {selectedUsers.length === 0 && (
            <div>
              <img
                id={`menu-list-btn${id && '-' + id}`}
                src={emptyAvatar}
                onClick={() => setMenuOpen(!menuOpen)}
                className='h-[27.44px] w-[27.44px]'
              />
            </div>
          )}
          <div>
            <UserAvatar
              id={`menu-list-btn${id && '-' + id}`}
              avatarType='Multiple'
              imageObjectGroup={avatarObjects}
            />
          </div>
          <div>
            {editable && (
              <AddFilled
                id={`menu-list-btn${id && '-' + id}`}
                size={16}
                onClick={() => setMenuOpen(!menuOpen)}
                color={pallete.colors.textDark2}
              />
            )}
          </div>
        </div>
        <div>
          <DropdownMenu
            id='uc-menu-list'
            open={menuOpen}
            parentId={`menu-list-btn${id && '-' + id}`}
            arrowPosition={arrowPosition}
            showArrow
            arrowTop={menuTop ? false : true}
            className={`${
              disableNoSubscription && 'cursor-not-allowed pointer-events-none'
            } absolute z-50 ${getMenuPosition()} ${
              menuTop ? 'bottom-[27px]' : ''
            }`}
            onClose={() => setMenuOpen(false)}
          >
            <div className='flex flex-col gap-[8px]'>
              {selectedUsers.length !== 0 && (
                <div className='flex flex-col max-h-[85px] gap-[6px] overflow-y-auto'>
                  {selectedUsers.map((item: any, index: any) => (
                    <Chip
                      id='chip-menu-list'
                      key={index}
                      label={item.name}
                      startIcon={
                        <div className='rounded-full h-[16px] w-[16px]'>
                          <img
                            alt='img-url'
                            src={item.imgUrl}
                            className='w-full h-full rounded-full object-fill'
                          />
                        </div>
                      }
                      onClose={() => removeSelectedUser(item.id)}
                    />
                  ))}
                </div>
              )}
              <Search
                id='search'
                name='Search'
                placeholder='Search'
                onChange={handleSearch}
                value={searchTerm}
              />
              <div
                className={`flex flex-col gap-[8px] overflow-y-auto`}
                style={{ maxHeight: maxHeight ?? '168px' }}
              >
                {allUsers.map((item: any) => (
                  <div
                    key={item.id}
                    className='flex rounded-md menu-list-hover w-full cursor-pointer items-center gap-[8px] p-[8px]'
                    onClick={() => {
                      setSelectedUsers([item, ...selectedUsers])
                      setSearchTerm('')
                    }}
                  >
                    <img
                      alt='img'
                      src={item.imgUrl}
                      className='w-[20px] h-[20px] rounded-full object-fill'
                    />
                    <div className='flex justify-start gap-[8px] items-center'>
                      <DDSTypography.Paragraph
                        className='whitespace-nowrap'
                        variant='medium'
                        size='para'
                        color={pallete.colors.textDark4}
                      >
                        {item.name}
                      </DDSTypography.Paragraph>
                      <DDSTypography.Paragraph
                        variant='regular'
                        size='caption'
                        className='flex items-center'
                        color={pallete.colors.textDark9}
                      >
                        ({toTitleCase(item.role)})
                      </DDSTypography.Paragraph>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </DropdownMenu>
        </div>
      </div>
    </div>
  )
}

export default AssignedToMenuList
