import Button from '../Button/Button'
import React from 'react'
import Typography from '../Typography/Typography'
import { TypographyDDS } from '../Typography/TypographyDDS'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

interface PopupProps {
  open: boolean
  handleConfirmDialogClose: () => void
  handleMainPopupClose?: () => void
  confirmationText?: string
}

const Container = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(71, 78, 93, 0.2);
  box-shadow: inset 0px 8px 25px rgba(71, 78, 93, 0.04);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`
const Wrapper = styled.div`
  padding: 20px;
`

const ModalWrapper = styled.div`
  background-color: ${palette.colors.lightBack};
  border-radius: 8px;
  border: 1px solid ${palette.colors.borderColor};
  box-shadow: 0px 8px 25px rgba(71, 78, 93, 0.04);
  padding: 40px;
  max-width: 600px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  grid-gap: 40px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    grid-gap: 10px;
  }
`

const ConfirmationPopup: React.FC<PopupProps> = ({
  open,
  handleMainPopupClose = () => null,
  handleConfirmDialogClose,
  confirmationText = 'Do you really want to close?'
}) => {
  return (
    <>
      {open && (
        <Container
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <Wrapper>
            <ModalWrapper>
              <TypographyDDS.Title
                type='h5'
                variant='medium'
                style={{ paddingBottom: '20px' }}
                color='textDark2'
              >
                {confirmationText}
              </TypographyDDS.Title>

              <ButtonsWrapper>
                <Button onClick={handleMainPopupClose} variant='outlined'>
                  Yes
                </Button>
                <Button
                  onClick={handleConfirmDialogClose}
                  variant='contained'
                  color='error'
                >
                  No
                </Button>
              </ButtonsWrapper>
            </ModalWrapper>
          </Wrapper>
        </Container>
      )}
    </>
  )
}

export default ConfirmationPopup
