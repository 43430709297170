import { DDSSwitch } from 'den-design-system'
import React from 'react'
import { SwitchInputProps } from './Switch.types'

const Switch: React.FC<SwitchInputProps> = ({
  id,
  name,
  defaultChecked,
  className,
  disabled,
  value,
  onChange,
  onClick,
  onBlur
}) => {
  return (
    <div className='override-switch cursor-pointer'>
      <DDSSwitch
        id={id}
        name={name}
        shape='rounded'
        defaultChecked={defaultChecked}
        className='after:h-[24px] after:w-[24px]'
        disabled={disabled}
        value={value}
        onChange={onChange}
        onClick={onClick}
        onBlur={onBlur}
        style={{
          width: '42px',
          height: '24px',
          display: 'inline-flex',
          padding: '2px',
          alignItems: 'flex-start',
          gap: '-2px'
        }}
      />
    </div>
  )
}
export default Switch
