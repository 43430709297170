import React, { useEffect, useState } from 'react'
import { UrlWithOnlyOrgId, replaceUrl } from '../../utils/UrlUtils'
import { createSearchParams, useNavigate, useParams } from 'react-router-dom'
import {
  createUserInvite,
  createUserProjectInvite
} from '../../api/userInvites/UserInvites.service'

import { Button } from '../New/Button/Button'
import { DDSTypography } from 'den-design-system'
import Input from '../New/Input/Input'
import Modal from '../common/DDS/Modal/Modal'
import { OrganizationUserRoles } from '../../enum/OrganizationUserRoles.enum'
import OverviewStringConstants from '../../constants/OverviewStringConstants'
import RegexConstants from '../../constants/RegexConstants'
import Select from '../New/Select/Select'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../common/DDS/Toast/Toast'
import ValidationConstants from '../../constants/ValidationConstants'
import { inviteSchema } from '../../utils/OverviewUtils'
import { isUndefined } from 'lodash'
import { observer } from 'mobx-react-lite'
import pallete from '../../global/newPallete'
import routeConstants from '../../constants/RouteConstants'
import { useStoreContext } from '../../store/StoreContext'

interface InviteMemberProps {
  open: boolean
  setOpen: any
  loading: boolean
  project?: any
}

interface InviteForm {
  email: string | null
  role: string | null
}
const InviteMemberPopup: React.FC<InviteMemberProps> = ({
  open,
  setOpen,
  project,
  loading
}) => {
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)
  const defaultInviteForm: InviteForm = {
    email: '',
    role: ''
  }
  const { projectId, environmentId } = useParams()
  const store = useStoreContext()
  const [inviteForm, setInviteForm] = useState<InviteForm>(defaultInviteForm)
  const [invitationSent, setInvitationSent] = React.useState(false)
  const [validationErrorResult, setValidationErrorResult] = React.useState<
    any[]
  >([])
  const navigate = useNavigate()
  const handleInputChange = (field: string, value: any) => {
    setValidationErrorResult((prevErrors) =>
      prevErrors.filter((error) => error.context.key !== field)
    )
    setInviteForm((prevData: InviteForm) => ({
      ...prevData,
      [field]: value
    }))
  }

  useEffect(() => {
    setButtonDisabled(inviteForm.email === '' || inviteForm.role === '')
  }, [inviteForm])

  const handleInviteCreation = () => {
    setButtonLoading(true)
    const validationResult = inviteSchema.validate(
      {
        email: inviteForm?.email,
        role: inviteForm?.role
      },
      { abortEarly: false }
    )
    if (isUndefined(validationResult.error)) {
      createUserProjectInvite({
        invitee: inviteForm.email as string,
        role: inviteForm.role as string,
        projectId: project ?? store.scopeStore.getSelectedProject()?.id
      })
        .then((response: any) => {
          ToastNotification({ type: 'success', message: response.message })
          setInviteForm(defaultInviteForm)
          setInvitationSent(true)
          setOpen(false)

          const orgId = store.scopeStore.getScope()['organization-id'] as string

          const route =
            replaceUrl(routeConstants.VIEW_PROJECT, orgId).replace(
              ':projectId',
              String(projectId)
            ) + '?tab=2'

          navigate({
            pathname: route
          })
        })
        .catch((err) => {
          ToastNotification({
            type: 'error',
            message: err
          })
        })
        .finally(() => {
          setButtonLoading(false)
          setButtonDisabled(false)
        })
    } else {
      setButtonLoading(false)
      setValidationErrorResult(validationResult.error.details)
    }
  }

  const roleDropdownOptions = [
    {
      label: (
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark4}
        >
          {OverviewStringConstants.MEMBER}
        </DDSTypography.Paragraph>
      ),
      value: OrganizationUserRoles.MEMBER
    }
  ]
  const InviteMemberFooter: React.FC = () => {
    return (
      <div className='flex override-btn-min-width justify-end px-[24px] py-[16px] gap-[10px]'>
        <Button
          id='cancel-modal-btn'
          type='neutral'
          label={OverviewStringConstants.CANCEL}
          disabled={buttonLoading}
          onClick={() => {
            setOpen(false)
            setInviteForm(defaultInviteForm)
            setValidationErrorResult([])
          }}
        />
        <Button
          id='send-invite-modal-btn'
          type='filled'
          disabled={buttonDisabled || buttonLoading}
          loading={buttonLoading}
          label={OverviewStringConstants.SEND_INVITE}
          onClick={handleInviteCreation}
        />
      </div>
    )
  }
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      onClosePress={() => null}
      className={`${(loading || buttonLoading) && 'cursor-not-allowed'}`}
      showHeader
      title={OverviewStringConstants.INVITE_MEMBERS}
      footer={<InviteMemberFooter />}
    >
      <div
        className={`${
          buttonLoading && 'pointer-events-none'
        } flex flex-col items-start justify-center  p-[24px] gap-[24px] `}
      >
        <div className='flex flex-col items-start gap-[4px]'>
          <div className='w-full'>
            <DDSTypography.Title
              type='h4'
              variant='semiBold'
              color={pallete.colors.textDark3}
            >
              {OverviewStringConstants.INVITE_MEMBERS_CAPTION}
              <span style={{ color: pallete.colors.textDark2 }}>
                {project
                  ? store.scopeStore.getProjectName(project)
                  : store.scopeStore.getSelectedProject().name}
              </span>
            </DDSTypography.Title>
          </div>
          <div>
            <DDSTypography.Title
              type='h5'
              variant='medium'
              color={pallete.colors.textDark4}
            >
              {OverviewStringConstants.LETS_COLLABORATE}
            </DDSTypography.Title>
          </div>
        </div>
        <div className='flex flex-col gap-[4px]'>
          <div className='override-input-placeholder relative'>
            <Input
              id='member-email-input'
              labelPosition='top'
              width='708px'
              placeholder={OverviewStringConstants.MEMBER_EMAIL_PLACEHOLDER}
              label={OverviewStringConstants.MEMBER_EMAIL}
              onChange={(e) => handleInputChange('email', e.target.value)}
              name={OverviewStringConstants.MEMBER_EMAIL}
            />
          </div>
          {validationErrorResult.map((error, index) =>
            error.context.key === 'email' ? (
              <DDSTypography.Paragraph
                key={index}
                size='caption'
                variant='regular'
                className='absolute'
                color={pallete.colors.warningRed2}
              >
                {error.message}
              </DDSTypography.Paragraph>
            ) : null
          )}
        </div>
        <div className='flex flex-col gap-[4px]'>
          <div className='override-member-role-select relative'>
            <Select
              id='member-role-select'
              size='large'
              name={OverviewStringConstants.MEMBER_ROLE}
              label={OverviewStringConstants.MEMBER_ROLE}
              placeholder={OverviewStringConstants.MEMBER_ROLE_PLACEHOLDER}
              options={roleDropdownOptions}
              searchable={false}
              onSelect={(value) => handleInputChange('role', value)}
            />
          </div>
          {validationErrorResult.map((error, index) =>
            error.context.key === 'role' ? (
              <DDSTypography.Paragraph
                key={index}
                size='caption'
                className='absolute'
                variant='regular'
                color={pallete.colors.warningRed2}
              >
                {error.message}
              </DDSTypography.Paragraph>
            ) : null
          )}
        </div>
      </div>
    </Modal>
  )
}

export default observer(InviteMemberPopup)
