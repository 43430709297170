import { DDSTypography } from 'den-design-system'
import React from 'react'
import pallete from '../../../global/newPallete'

interface SideChipProps {
  label: string
  value: string
  id: string
}
const SideChip: React.FC<SideChipProps> = ({ id, label, value }) => {
  return (
    <div
      id={id}
      style={{ borderColor: pallete.colors.stroke2 }}
      className={`flex self-stretch border w-fit h-[32px] rounded-md`}
    >
      <div
        style={{
          borderColor: pallete.colors.stroke2,
          backgroundColor: pallete.colors.surface5
        }}
        className={`flex rounded-l-md w-fit border-r items-center self-stretch justify-center px-[16px] py-[8px]`}
      >
        <DDSTypography.Paragraph
          className='whitespace-nowrap'
          size='para'
          variant='semiBold'
          color={pallete.colors.textDark3}
        >
          {label}
        </DDSTypography.Paragraph>
      </div>
      <div className='flex items-center px-[16px] py-[8px] justify-center w-4/5 self-stretch'>
        <DDSTypography.Paragraph
          className='whitespace-nowrap'
          size='caption'
          variant='medium'
          color={pallete.colors.textDark4}
        >
          {value}
        </DDSTypography.Paragraph>
      </div>
    </div>
  )
}

export default SideChip
