import { LinearProgress } from '@material-ui/core'
import React from 'react'
import styled from '@emotion/styled'

const ProgressBar = styled(LinearProgress)({
  '&.MuiLinearProgress-root': {
    height: '2px',
    width: '100%',
  },
  '&.MuiLinearProgress-colorPrimary': {
    background: 'linear-gradient(270deg, #066CFF 0%, #05A0FA 100%);',
  },
})

const LinearProgressBar: React.FC = () => {
  return <ProgressBar />
}

export default LinearProgressBar
