import {
  ExtraLargeProps,
  HeadingProps,
  ParagraphProps,
  TitleProps
} from '../../Typography/Typography.types'

import { DDSTypography } from 'den-design-system'
import { PropsWithChildren } from 'react'
import React from 'react'
import palette from '../../../../global/newPallete'

const ExtraLarge: React.FC<PropsWithChildren<ExtraLargeProps>> = ({
  variant = 'bold',
  color,
  ellipsis = false,
  style,
  children,
  className
}) => {
  return (
    <DDSTypography.Title
      color={palette.colors?.[color as keyof typeof palette.colors].toString()}
      type='h1'
      className={className}
      variant={variant}
      ellipsis={ellipsis}
      style={{ fontSize: '40px', ...style }}
    >
      {children}
    </DDSTypography.Title>
  )
}

const Heading: React.FC<PropsWithChildren<HeadingProps>> = ({
  variant = 'bold',
  color = 'textDark',
  ellipsis = false,
  style,
  children,
  className
}) => {
  return (
    <DDSTypography.Title
      type='h1'
      color={palette.colors?.[color as keyof typeof palette.colors].toString()}
      className={className}
      ellipsis={ellipsis}
      variant={variant}
      style={{ fontSize: '32px', lineHeight: '40px', ...style }}
    >
      {children}
    </DDSTypography.Title>
  )
}

const Paragraph: React.FC<PropsWithChildren<ParagraphProps>> = ({
  size = 'para',
  variant = 'medium',
  color = 'textDark2',
  ellipsis = false,
  style,
  children,
  id,
  className
}) => {
  return (
    <DDSTypography.Paragraph
      size={size}
      color={palette.colors?.[color as keyof typeof palette.colors].toString()}
      variant={variant}
      ellipsis={ellipsis}
      className={className}
      style={style}
    >
      {children}
    </DDSTypography.Paragraph>
  )
}

export const Typography = { ExtraLarge, Heading, Paragraph }
