import React, { useCallback, useEffect, useState } from 'react'
import { isEmpty, isUndefined } from 'lodash'

import { Button } from '../../../New/Button/Button'
import { ChevronRight } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import Input from '../../../New/Input/Input'
import Joi from 'joi'
import MiscHeader from '../../MiscellaneousScreen/Page/MiscHeader/MiscHeader'
import RegexConstants from '../../../../constants/RegexConstants'
import { Typography } from '../../DDS/Typography/Typography'
import ValidationConstants from '../../../../constants/ValidationConstants'
import pallete from '../../../../global/newPallete'
import routeConstants from '../../../../constants/RouteConstants'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useStoreContext } from '../../../../store/StoreContext'
import vigilLogo from '../../../../assets/icons/Vigil Logo Greyed out.svg'

interface EnterEmailProps {
  handleNext: (email: string, captcha: string | null, captchaRef?: any) => void
  handleBack?: () => void
  loading?: boolean
  emailBack: string
}

interface RefObject {
  resetCaptcha: () => void
}

const ResetYourPassword: React.FC<EnterEmailProps> = ({
  handleNext,
  loading,
  emailBack = ''
}) => {
  const [email, setEmail] = useState(emailBack)
  const store = useStoreContext()
  const [captcha, setCaptcha] = useState<string | null>(null)
  const [validationErrorResult, setValidationErrorResult] = useState<
    string | null
  >(null)
  const [isInvalidCaptcha, setIsInvalidCaptcha] = useState<boolean>(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    const token = await executeRecaptcha('signUp')
    setCaptcha(token)
  }, [executeRecaptcha])

  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setEmail(value)

    const isValidEmail = RegexConstants.EMAIL_REGEX.test(value)
    if (!isValidEmail) {
      setIsButtonDisabled(true)
    } else {
      setIsButtonDisabled(false)
    }
  }

  const handleEmailSubmit = () => {
    handleReCaptchaVerify()

    if (isEmpty(captcha)) {
      setIsInvalidCaptcha(true)
      return
    } else {
      setIsInvalidCaptcha(false)
    }

    const isValidEmail = RegexConstants.EMAIL_REGEX.test(email)
    if (!isValidEmail) {
      setCaptcha('')
      return
    }

    setValidationErrorResult(null)
    if (!isEmpty(captcha)) {
      handleNext(email, captcha)
    }
  }

  return (
    <div className='flex flex-col min-h-screen'>
      <MiscHeader />
      <div className='flex-grow flex flex-col items-center justify-center bg-white p-[32px]'>
        <div className='flex flex-col justify-center items-center text-center'>
          <p
            style={{
              color: pallete.colors.textDark3,
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '40px',
              paddingBottom: '12px'
            }}
          >
            Reset your password
          </p>
          <div className='flex flex-col  gap-[24px] text-center'>
            <DDSTypography.Title
              type='h3'
              variant='medium'
              color={pallete.colors.textDark4}
            >
              A password reset link will be sent to your Email Id
            </DDSTypography.Title>
            <Input
              onChange={handleEmailChange}
              width='30vw'
              value={email}
              placeholder='Enter your email'
              labelPosition='top'
              label='Email Id'
              required={true}
              validationRegex={new RegExp(RegexConstants.EMAIL_REGEX)}
              size='large'
              validateInput={true}
              id='email-id'
              name='emailInput'
              validate={(value: string) => {
                if (!RegexConstants.EMAIL_REGEX.test(value)) {
                  return {
                    error: true,
                    message: 'Please enter a valid email address'
                  }
                }
                return {
                  error: false,
                  message: ''
                }
              }}
            />

            <Button
              label='Send Link'
              width='30vw'
              type='filled'
              size='small'
              endIcon={<ChevronRight color={pallete.colors.white} />}
              id='home-screen-button'
              onClick={handleEmailSubmit}
              disabled={
                isButtonDisabled || loading || store.uiStore.getGlobalLoader()
              }
            />

            <DDSTypography.Title
              type='h5'
              variant='medium'
              color={pallete.colors.textDark4}
            >
              Don’t have an account?{' '}
              <a
                className='underline'
                style={{ color: pallete.colors.textDark2 }}
                href={routeConstants.SIGN_UP}
              >
                Sign up
              </a>
            </DDSTypography.Title>
          </div>
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '32px',
          left: '50%',
          transform: 'translateX(-50%)'
        }}
      >
        <Typography.Paragraph
          size='caption'
          variant='medium'
          color='textDark10'
          style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
        >
          © 2024
          <span>
            <img src={vigilLogo} alt='Vigil Icon' />
          </span>
        </Typography.Paragraph>
      </div>
    </div>
  )
}

export default ResetYourPassword
