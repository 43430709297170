import * as React from 'react'

import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Switch from '@mui/material/Switch'
import palleteTheme from '../../../global/pallete'
import { styled } from '@mui/material/styles'

const StyledFormControl = styled(FormControlLabel)(() => ({
  '&.MuiFormControlLabel-root': {
    '&.Mui-disabled': {
      cursor: 'not-allowed'
    }
  }
}))

const CustomToggle = styled(Switch)(() => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 12,
    backgroundColor: 'white !important',
    border: '1px solid gray',
    height: '25px',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 14,
    height: 14,
    margin: 4
  },
  '.MuiSwitch-switchBase': {
    color: palleteTheme.colors.textDark4
  },
  '& .Mui-checked': {
    color: palleteTheme.colors.primary,
    marginLeft: '-2px'
  },
  '&.MuiButtonBase-root': {
    '&.MuiSwitch-switchBase': {
      '&.Mui-disabled': {
        color: palleteTheme.colors.gradientBlack,
        cursor: 'not-allowed'
      }
    }
  }
}))

function ToggleButton(props: any) {
  return (
    <FormGroup>
      <StyledFormControl
        control={
          <CustomToggle
            id='toggle'
            disabled={props.disabled}
            checked={props.checked}
            onChange={props.onChange}
          />
        }
        label=''
      />
    </FormGroup>
  )
}

export default ToggleButton
