import React, { useEffect } from 'react'
import { gaEventConstants, gaPageConstants } from '../../constants/GAConstants'
import { useLocation, useNavigate } from 'react-router-dom'

import CustomErrorToast from '../../components/common/Toast/CustomErrorToast'
import { DDSToastNotification } from 'den-design-system'
import HttpConstants from '../../constants/HttpConstants'
import PageLoadingOverlay from '../../components/common/PageLoadingOverlay/PageLoadingOverlay'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../../components/common/DDS/Toast/Toast'
import UnsecureComponent from '../../components/Layout/UnsecureComponent'
import { addQueryParamsToUrl } from '../../utils/UrlGenerator'
import { joinUserToRoom } from '../../gateway'
import { observer } from 'mobx-react-lite'
import { onBoardUserViaSalesforceSSO } from '../../api/user/User.service'
import routeConstants from '../../constants/RouteConstants'
import { toast } from 'react-toastify'
import { useAnalyticsEventTracker } from '../../utils/GAUtils'

const SalesforcePage = () => {
  const location = useLocation()
  const [loading, setLoading] = React.useState(false)
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const gaEventTracker = useAnalyticsEventTracker(gaPageConstants.SIGN_IN)

  const [code] = React.useState(searchParams.get('code'))
  const [state] = React.useState(searchParams.get('state'))

  const onBoardUser = () => {
    setLoading(true)
    code &&
      onBoardUserViaSalesforceSSO(
        decodeURIComponent(code),
        JSON.parse(state as string).isLogin,
        JSON.parse(state as string).token
      )
        .then((response: any) => {
          joinUserToRoom(response.data.id)
          const url = addQueryParamsToUrl(
            routeConstants.REDIRECT,
            {
              token: response.data.accessToken
            },
            HttpConstants.GET_METHOD
          )
          gaEventTracker(gaEventConstants.SIGN_IN)
          navigate(url)
        })
        .catch((err: string) => {
          ToastNotification({
            type: 'error',
            message: err
          })
          navigate(routeConstants.SIGN_IN)
        })
        .finally(() => setLoading(false))
  }

  useEffect(() => {
    onBoardUser()
  }, [])

  return <PageLoadingOverlay />
}

export default UnsecureComponent(observer(SalesforcePage))
