import { makeAutoObservable, observable } from 'mobx'

import { RootStore } from './RootStore'

export class ProjectAndEnvironmentStore {
  projectAndEnvironments: any
  selectedProject: any
  environmentName: string
  constructor(private rootStore: RootStore) {
    this.projectAndEnvironments = []
    this.selectedProject = {}
    this.environmentName = ''
    makeAutoObservable(this, {
      projectAndEnvironments: observable.struct,
      selectedProject: observable.struct
    })
  }

  setProjectsAndEnvironments(data: any) {
    this.projectAndEnvironments = data
  }
  getProjectsAndEnvironments() {
    return this.projectAndEnvironments
  }

  setSelectedProject(selectedProject: any) {
    this.selectedProject = selectedProject
  }

  getSelectedProject() {
    return this.selectedProject
  }

  setEnvironmentName(name: any) {
    this.environmentName = name
  }

  getEnvironmentName() {
    return this.environmentName
  }

  reset() {
    this.projectAndEnvironments = []
    this.selectedProject = []
  }
}
