import { DDSTypography } from 'den-design-system'
import React from 'react'
import { options } from 'joi'
import pallete from '../../../global/newPallete'

interface SliderProps {
  options: any[]
  selected: string
  onSelect: any
}
const Slider: React.FC<SliderProps> = ({ options, selected, onSelect }) => {
  return (
    <div className='border border-gray-300 p-[4px] flex flex-row rounded-md'>
      {options.map((option, index) => (
        <div
          key={index}
          style={{
            backgroundColor:
              option.label === selected
                ? pallete.colors.primary2
                : pallete.colors.white
          }}
          className='px-[16px] py-[2px] rounded-md cursor-pointer'
          onClick={() => onSelect && onSelect(option.label)}
        >
          <DDSTypography.Paragraph
            size='para'
            variant='regular'
            color={
              option.label === selected
                ? pallete.colors.white
                : pallete.colors.textDark5
            }
            className='cursor-pointer'
          >
            {option.label}
          </DDSTypography.Paragraph>
        </div>
      ))}
    </div>
  )
}
export default Slider
