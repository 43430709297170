import React, { useEffect, useLayoutEffect } from 'react'

import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import { DDSTypography } from 'den-design-system'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../constants/HeaderConstants'
import SecureComponent from '../../components/Layout/SecureComponent'
import StringConstants from '../../constants/StringConstants'
import { Typography } from '../../components/common/DDS/Typography/Typography'
import noOrgImage from '../../assets/images/NoOrgImage.svg'
import { observer } from 'mobx-react-lite'
import pallete from '../../global/newPallete'
import { useStoreContext } from '../../store/StoreContext'
import vigilLogo from '../../assets/icons/Vigil Logo Greyed out.svg'

const NoProjectPage = () => {
  const store = useStoreContext()
  useEffect(() => {
    if (store.uiStore.getprojectEnvironmentLoader()) {
      store.uiStore.setprojectEnvironmentLoader(false)
    }
  }, [store.uiStore.getprojectEnvironmentLoader()])

  useEffect(() => {
    if (store.uiStore.getGlobalLoader()) {
      store.uiStore.setGlobalLoader(false)
    }
  }, [store.uiStore.getGlobalLoader()])
  useLayoutEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.OVERVIEW
    )
    store.uiStore.setSidebarItem({})
    const recaptchaBadge = document.querySelector(
      '.grecaptcha-badge'
    ) as HTMLElement
    if (recaptchaBadge) {
      recaptchaBadge.style.setProperty('visibility', 'hidden')
    }
  }, [])

  useEffect(() => {
    store.breadcrumbStore.setBreadcrumbsOptions({
      label: '',
      link: '/'
    })
  }, [store.breadcrumbStore.getBreadcrumbsOptions()])

  return (
    <>
      <DocumentHeader header={HeaderConstants.NO_PROJECT} />
      <div className='flex'>
        <div
          className={`flex flex-col w-full ${store.uiStore.getIsAlertVisible() ? 'h-[85vh]' : 'h-[89vh]'} bg-white `}
        >
          <div
            className={`flex flex-col items-center justify-center flex-grow gap-[20px] p-[32px] w-full`}
          >
            <div className='flex flex-col justify-center items-center gap-[12px] h-full self-stretch'>
              <div>
                <img src={noOrgImage} />
              </div>
              <div className='flex flex-col space-between text-center items-center gap-[12px] self-stretch'>
                <DDSTypography.Title
                  type='h1'
                  style={{ fontWeight: 700, lineHeight: '40px' }}
                  color={pallete.colors.textDark3}
                >
                  {StringConstants.NO_PROJECT.NOT_IN_PROJECT}
                </DDSTypography.Title>
                <DDSTypography.Title
                  type='h4'
                  variant='medium'
                  color={pallete.colors.textDark4}
                >
                  {StringConstants.NO_PROJECT.CONTACT_OWNER}
                </DDSTypography.Title>
              </div>
            </div>
            <div className='flex justify-center items-center gap-[4px] p-[4px]'>
              <Typography.Paragraph
                size='caption'
                variant='medium'
                color='textDark10'
              >
                @ 2024
              </Typography.Paragraph>
              <img src={vigilLogo} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SecureComponent(observer(NoProjectPage), AccessRoleConstants.ALL)
