import { AddCheckStore } from './popups/AddCheckStore'
import { AhcLogsStore } from './AhcLogsStore'
import { AhcReportsStore } from './AhcReportsStore'
import { AhcStore } from './AhcStore'
import { AhcSummaryStore } from './AhcSummaryStore'
import { ApiDetailsStore } from './ApiDetailsStore'
import { BreadcrumbsStore } from './BreadcrumbStore'
import { EnvironmentStore } from './EnvironmentStore'
import { ExceptionIncidentStore } from './ExceptionIncidentStore'
import { ExceptionStore } from './ExceptionStore'
import { ExceptionStoreV2 } from './ExceptionStoreV2'
import { FilterStore } from './FilterStore'
import { IncidentStore } from './IncidentStore'
import { JobLogStore } from './Job/JobLogStore'
import { JobStore } from './Job/JobStore'
import { OverviewStore } from './OverviewStore'
import { ProjectAndEnvironmentStore } from './ProjectAndEnvironmentStore'
import { ProjectMemberStore } from './ProjectMemberStore'
import { ProjectStore } from './ProjectsStore'
import { SalesforceAuditStore } from './SalesforceAuditStore'
import { ScopeStore } from './ScopeStore'
import { ThingsToDoStore } from './ThingsToDoStore'
import { UIStore } from './UIStore'
import { UserStore } from './UserStore'
export class RootStore {
  scopeStore: ScopeStore
  jobStore: JobStore
  jobLogStore: JobLogStore
  userStore: UserStore
  uiStore: UIStore
  addCheckStore: AddCheckStore
  filterStore: FilterStore
  exceptionStore: ExceptionStore
  ahcReportsStore: AhcReportsStore
  ahcLogsStore: AhcLogsStore
  ahcStore: AhcStore
  ahcSummaryStore: AhcSummaryStore
  projectMemberStore: ProjectMemberStore
  apiDetailsStore: ApiDetailsStore
  incidentStore: IncidentStore
  projectStore: ProjectStore
  environmentStore: EnvironmentStore
  projectAndEnvironmentStore: ProjectAndEnvironmentStore
  overviewStore: OverviewStore
  exceptionIncidentStore: ExceptionIncidentStore
  salesforceAuditStore: SalesforceAuditStore
  thingsToDoStore: ThingsToDoStore
  breadcrumbStore: BreadcrumbsStore
  exceptionsStoreV2: ExceptionStoreV2
  constructor() {
    this.scopeStore = new ScopeStore(this)
    this.jobStore = new JobStore(this)
    this.incidentStore = new IncidentStore(this)
    this.jobLogStore = new JobLogStore(this)
    this.userStore = new UserStore(this)
    this.uiStore = new UIStore(this)
    this.addCheckStore = new AddCheckStore(this)
    this.filterStore = new FilterStore(this)
    this.exceptionStore = new ExceptionStore(this)
    this.ahcReportsStore = new AhcReportsStore(this)
    this.ahcLogsStore = new AhcLogsStore(this)
    this.ahcStore = new AhcStore(this)
    this.ahcSummaryStore = new AhcSummaryStore(this)
    this.projectMemberStore = new ProjectMemberStore(this)
    this.apiDetailsStore = new ApiDetailsStore(this)
    this.projectStore = new ProjectStore(this)
    this.environmentStore = new EnvironmentStore(this)
    this.projectAndEnvironmentStore = new ProjectAndEnvironmentStore(this)
    this.overviewStore = new OverviewStore(this)
    this.exceptionIncidentStore = new ExceptionIncidentStore(this)
    this.salesforceAuditStore = new SalesforceAuditStore(this)
    this.thingsToDoStore = new ThingsToDoStore(this)
    this.breadcrumbStore = new BreadcrumbsStore(this)
    this.exceptionsStoreV2 = new ExceptionStoreV2(this)
  }

  clearStore() {
    this.scopeStore.reset()
    this.jobStore.reset()
    this.jobLogStore.reset()
    this.userStore.reset()
    this.uiStore.reset()
    this.incidentStore.reset()
    this.addCheckStore.reset()
    this.filterStore.reset()
    this.exceptionStore.reset()
    this.ahcReportsStore.reset()
    this.ahcLogsStore.reset()
    this.ahcStore.reset()
    this.ahcSummaryStore.reset()
    this.projectMemberStore.reset()
    this.apiDetailsStore.reset()
    this.projectStore.reset()
    this.environmentStore.reset()
    this.projectAndEnvironmentStore.reset()
    this.overviewStore.reset()
    this.exceptionIncidentStore.reset()
    this.salesforceAuditStore.reset()
    this.thingsToDoStore.reset()
    this.breadcrumbStore.reset()
  }
}
