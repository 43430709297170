import MiscContent from '../Page/MiscContent/MiscContent'
import React from 'react'
import setUpIcon from '../../../../assets/images/error.svg'
import vigilLogo from '../../../../assets/icons/Vigil Logo Greyed out.svg'

const ErrorPage = () => {
  return (
    <div className='flex flex-col min-h-screen'>
      <MiscContent
        imgSrc={setUpIcon}
        text='Page Not found'
        buttonLabel='Return to Homepage'
        vigilLogoSrc={vigilLogo}
      />
    </div>
  )
}

export default ErrorPage
