import { PauseOutline, Play } from '@carbon/icons-react'
import React, { useState } from 'react'

import { Button } from '../../New/Button/Button'
import JMStringConstants from '../../../constants/JMStringConstants'
import pallete from '../../../global/newPallete'

interface MonitorTabProps {
  isStart: boolean
  disabled: boolean
  onStartClick?: () => void
  onStopClick?: () => void
}
const MonitorTabSwitch: React.FC<MonitorTabProps> = ({
  isStart,
  disabled,
  onStartClick,
  onStopClick
}) => {
  const [start, setStart] = useState<boolean>(isStart)
  return (
    <div
      className={`flex items-center override-monitor-btn ${disabled ? 'cursor-not-allowed' : ''}`}
    >
      <div className='override-start-btn' onClick={() => setStart(true)}>
        {start ? (
          <Button
            disabled={disabled}
            id='start-btn'
            type='outline'
            onClick={onStartClick}
            startIcon={<Play color={pallete.colors.primary2} size={16} />}
            label={JMStringConstants.START_MONITOR_SWITCH_LABEL}
          />
        ) : (
          <Button
            id='start-btn'
            disabled={disabled}
            type='neutral'
            onClick={onStartClick}
            startIcon={<Play color={pallete.colors.neuButtonText} size={16} />}
            label={JMStringConstants.START_MONITOR_SWITCH_LABEL}
          />
        )}
      </div>
      <div className='override-stop-btn' onClick={() => setStart(false)}>
        {start ? (
          <Button
            id='stop-btn'
            disabled={disabled}
            type='neutral'
            startIcon={
              <PauseOutline color={pallete.colors.neuButtonText} size={16} />
            }
            onClick={onStopClick}
            label={JMStringConstants.STOP_MONITOR_SWITCH_LABEL}
          />
        ) : (
          <Button
            id='stop-btn'
            type='outline'
            disabled={disabled}
            startIcon={
              <PauseOutline color={pallete.colors.primary2} size={16} />
            }
            onClick={onStopClick}
            label={JMStringConstants.STOP_MONITOR_SWITCH_LABEL}
          />
        )}
      </div>
    </div>
  )
}

export default MonitorTabSwitch
