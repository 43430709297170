import avatar1 from '../assets/images/avatar1.png'
import avatar2 from '../assets/images/avatar2.png'
import pallete from '../global/newPallete'

export const tableDataConstants = [
  {
    key: '1',
    exceptionType: '/app/responding',
    latestReported: 'March 8 2024, 10:31:14',
    preferences: 'Critical',
    exceptionCount: '2',
    reportedTimeGraph: 'In Progress',
    assignedTo: 'post',
    severity: '4',
    statusCodeGraph: 'P0'
  },
  {
    key: '2',
    exceptionType: '/app/responding',
    latestReported: 'March 8 2024, 10:31:14',
    preferences: 'Critical',
    exceptionCount: '2',
    reportedTimeGraph: 'In Progress',
    assignedTo: 'post',
    severity: '4',
    statusCodeGraph: 'P0'
  },
  {
    key: '3',
    exceptionType: '/app/responding',
    latestReported: 'March 8 2024, 10:31:14',
    preferences: 'Critical',
    exceptionCount: '2',
    reportedTimeGraph: 'In Progress',
    assignedTo: 'post',
    severity: '4',
    statusCodeGraph: 'P0'
  },
  {
    key: '4',
    exceptionType: '/app/responding',
    latestReported: 'March 8 2024, 10:31:14',
    preferences: 'Critical',
    exceptionCount: '2',
    reportedTimeGraph: 'In Progress',
    assignedTo: 'post',
    severity: '4',
    statusCodeGraph: 'P0'
  },
  {
    key: '5',
    exceptionType: '/app/responding',
    latestReported: 'March 8 2024, 10:31:14',
    preferences: 'Critical',
    exceptionCount: '2',
    reportedTimeGraph: 'In Progress',
    assignedTo: 'post',
    severity: '4',
    statusCodeGraph: 'P0'
  },
  {
    key: '6',
    exceptionType: '/app/responding',
    latestReported: 'March 8 2024, 10:31:14',
    preferences: 'Critical',
    exceptionCount: '2',
    reportedTimeGraph: 'In Progress',
    assignedTo: 'post',
    severity: '4',
    statusCodeGraph: 'P0'
  },
  {
    key: '7',
    exceptionType: '/app/responding',
    latestReported: 'March 8 2024, 10:31:14',
    preferences: 'Critical',
    exceptionCount: '2',
    reportedTimeGraph: 'In Progress',
    assignedTo: 'post',
    severity: '4',
    statusCodeGraph: 'P0'
  },
  {
    key: '8',
    exceptionType: '/app/responding',
    latestReported: 'March 8 2024, 10:31:14',
    preferences: 'Critical',
    exceptionCount: '2',
    reportedTimeGraph: 'In Progress',
    assignedTo: 'post',
    severity: '4',
    statusCodeGraph: 'P0'
  },
  {
    key: '9',
    exceptionType: '/app/responding',
    latestReported: 'March 8 2024, 10:31:14',
    preferences: 'Critical',
    exceptionCount: '2',
    reportedTimeGraph: 'In Progress',
    assignedTo: 'post',
    severity: '4',
    statusCodeGraph: 'P0'
  },
  {
    key: '10',
    exceptionType: '/app/responding',
    latestReported: 'March 8 2024, 10:31:14',
    preferences: 'Critical',
    exceptionCount: '2',
    reportedTimeGraph: 'In Progress',
    assignedTo: 'post',
    severity: '4',
    statusCodeGraph: 'P1'
  },
  {
    key: '11',
    exceptionType: '/app/responding',
    latestReported: 'March 8 2024, 10:31:14',
    preferences: 'Critical',
    exceptionCount: '2',
    reportedTimeGraph: 'In Progress',
    assignedTo: 'post',
    severity: '4',
    statusCodeGraph: 'P1'
  },
  {
    key: '12',
    exceptionType: '/app/responding',
    latestReported: 'March 8 2024, 10:31:14',
    preferences: 'Critical',
    exceptionCount: '2',
    reportedTimeGraph: 'In Progress',
    assignedTo: 'post',
    severity: '4',
    statusCodeGraph: 'P1'
  },
  {
    key: '13',
    exceptionType: '/app/responding',
    latestReported: 'March 8 2024, 10:31:14',
    preferences: 'Critical',
    exceptionCount: '2',
    reportedTimeGraph: 'In Progress',
    assignedTo: 'post',
    severity: '4',
    statusCodeGraph: 'P1'
  },
  {
    key: '14',
    exceptionType: '/app/responding',
    latestReported: 'March 8 2024, 10:31:14',
    preferences: 'Critical',
    exceptionCount: '2',
    reportedTimeGraph: 'In Progress',
    assignedTo: 'post',
    severity: '4',
    statusCodeGraph: 'P1'
  }
]
export const allExceptionsTableDataConstants = [
  {
    key: '1',
    exceptionType: '/app/responding',
    exceptionMessage: 'Grouped Exception',
    clientVersion: 'v2.2.8',
    save: true,
    reportedAt: 'Mar 03, 2024 12:35PM',
    severity: 'Critical',
    status: 'Resolved',
    assignedTo: 'Click me'
  },
  {
    key: '2',
    exceptionType: '/app/responding',
    exceptionMessage: 'Grouped Exception',
    clientVersion: 'v2.2.8',
    save: true,
    reportedAt: 'Mar 03, 2024 12:35PM',
    severity: 'Critical',
    status: 'Resolved',
    assignedTo: 'Click me'
  },
  {
    key: '3',
    exceptionType: '/app/responding',
    exceptionMessage: 'Grouped Exception',
    clientVersion: 'v2.2.8',
    save: true,
    reportedAt: 'Mar 03, 2024 12:35PM',
    severity: 'Critical',
    status: 'Resolved',
    assignedTo: 'Click me'
  },
  {
    key: '4',
    exceptionType: '/app/responding',
    exceptionMessage: 'Grouped Exception',
    clientVersion: 'v2.2.8',
    save: false,
    reportedAt: 'Mar 03, 2024 12:35PM',
    severity: 'Critical',
    status: 'Resolved',
    assignedTo: 'Click me'
  },
  {
    key: '5',
    exceptionType: '/app/responding',
    exceptionMessage: 'Grouped Exception',
    clientVersion: 'v2.2.8',
    save: false,
    reportedAt: 'Mar 03, 2024 12:35PM',
    severity: 'Critical',
    status: 'Resolved',
    assignedTo: 'Click me'
  },
  {
    key: '6',
    exceptionType: '/app/responding',
    exceptionMessage: 'Grouped Exception',
    clientVersion: 'v2.2.8',
    save: false,
    reportedAt: 'Mar 03, 2024 12:35PM',
    severity: 'Critical',
    status: 'Resolved',
    assignedTo: 'Click me'
  },
  {
    key: '7',
    exceptionType: '/app/responding',
    exceptionMessage: 'Grouped Exception',
    clientVersion: 'v2.2.8',
    save: false,
    reportedAt: 'Mar 03, 2024 12:35PM',
    severity: 'Critical',
    status: 'Resolved',
    assignedTo: 'Click me'
  },
  {
    key: '8',
    exceptionType: '/app/responding',
    exceptionMessage: 'Grouped Exception',
    clientVersion: 'v2.2.8',
    save: false,
    reportedAt: 'Mar 03, 2024 12:35PM',
    severity: 'Critical',
    status: 'Resolved',
    assignedTo: 'Click me'
  },
  {
    key: '9',
    exceptionType: '/app/responding',
    exceptionMessage: 'Grouped Exception',
    clientVersion: 'v2.2.8',
    save: false,
    reportedAt: 'Mar 03, 2024 12:35PM',
    severity: 'Critical',
    status: 'Resolved',
    assignedTo: 'Click me'
  },
  {
    key: '10',
    exceptionType: '/app/responding',
    exceptionMessage: 'Grouped Exception',
    clientVersion: 'v2.2.8',
    save: false,
    reportedAt: 'Mar 03, 2024 12:35PM',
    severity: 'Critical',
    status: 'Resolved',
    assignedTo: 'Click me'
  }
]

export const stockChartData = [
  12, 8, 2, 29, 43, 32, 35, 27, 8, 34, 78, 98, 47, 19, 38, 22, 11, 87, 45, 98,
  39, 17, 45, 98, 74, 65
]
export const longSentence = `TypeError: Cannot read properties of undefined (reading 'test') at Object.<anonymous> (/Users/chinmaygopal/Documents/chinmay/foxsense/foxyiti/sentry-poc/index.js:23:46) at Module._compile (node:internal/modules/cjs/loader:1103:14) at Object.Module._extensions..js (node:internal/modules/cjs/loader:1157:10) at Module.load (node:internal/modules/cjs loader:981:32) at Function.Module._load (node:internal/modules/cjs/loader:822:12) at Function.executeUserEntryPoint [as runMain] (node:internal/modules/run_main:77:12) at node:internal/main/run_main_module:17:47`
export const performanceGraphData = {
  uptimeData: [
    {
      x: '03/12/2024',
      y: 10
    },
    {
      x: '03/12/2024',
      y: 20
    },
    {
      x: '03/12/2024',
      y: 30
    },
    {
      x: '03/12/2024',
      y: 40
    },
    {
      x: '03/12/2024',
      y: 50
    }
  ],
  downTimeData: [
    {
      x: '03/12/2024',
      y: 10
    },
    {
      x: '03/12/2024',
      y: 20
    },
    {
      x: '03/12/2024',
      y: 30
    },
    {
      x: '03/12/2024',
      y: 40
    },
    {
      x: '03/12/2024',
      y: 50
    }
  ],
  responseTimeData: [
    {
      x: '03/12/2024',
      y: 10
    },
    {
      x: '03/12/2024',
      y: 20
    },
    {
      x: '03/12/2024',
      y: 30
    },
    {
      x: '03/12/2024',
      y: 40
    },
    {
      x: '03/12/2024',
      y: 50
    }
  ]
}

const generateRandomMessageId = () => {
  return (Math.random() + 1).toString(36).substring(7)
}

export const ActivityMessagesConstant = [
  {
    id: generateRandomMessageId(),
    avatar: avatar1,
    name: 'Abinath',
    message:
      'is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsumis simply dummy text of the printing and typesetting industry. Lorem Ipsumis simply dummy text of the printing and typesetting industry. Lorem Ipsum.'
  },
  {
    id: generateRandomMessageId(),
    avatar: avatar1,
    name: 'Abijith',
    message:
      'is simply dummy text of the printing and typesetting industry. Lorem Ipsum'
  },
  {
    id: generateRandomMessageId(),
    avatar: avatar1,
    name: 'Abijith',
    message:
      'is simply dummy text of the printing and typesetting industry. Lorem Ipsum'
  },
  {
    id: generateRandomMessageId(),
    avatar: avatar2,
    name: 'Abijith',
    message:
      'is simply dummy text of the printing and typesetting industry. Lorem Ipsum'
  }
]

export const severityColorMap: Record<string, string> = {
  Critical: pallete.colors.warningRed1,
  Major: pallete.colors.warningOrange,
  Moderate: pallete.colors.warningBlue,
  Minor: pallete.colors.textDark7
}
