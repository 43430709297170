import { makeAutoObservable, observable } from 'mobx'

import { RootStore } from './RootStore'

export interface IncidentData {
  incidentId: any
  priorityStatus: string
  identifiedOn: string
  shortDescription: string
  longDescription: string
  referenceLinks: string[]
  incidentNumber: any
}

export class IncidentStore {
  incidentData: IncidentData | null = null

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, { incidentData: observable.struct })
  }

  setIncidentData(incidentData: IncidentData) {
    this.rootStore.incidentStore.incidentData = incidentData
  }

  getIncidentData(): IncidentData | null {
    return this.rootStore.incidentStore.incidentData
  }

  reset() {
    this.incidentData = null
  }
}
