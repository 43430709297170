import {
  APIOverviewData,
  ExceptionOverviewData,
  HealthOverviewData,
  JobOverviewData,
  ProjectSummaryData
} from '../../types/store.types'

import ApiManager from '../Api.manager'
import HttpConstants from '../../constants/HttpConstants'
import UrlConstants from '../../constants/UrlConstants'

export const getExceptionOverviewAPI = (
  payload: void
): Promise<{ data: ExceptionOverviewData; filters: Record<string, any> }> => {
  const usecase = UrlConstants.GET_EXCEPTION_OVERVIEW.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const getJobOverviewAPI = (
  payload: void
): Promise<{ data: JobOverviewData }> => {
  const usecase = UrlConstants.GET_JOB_OVERVIEW.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const getAPIMonitoringOverviewAPI = (
  payload: void
): Promise<{ data: APIOverviewData }> => {
  const usecase = UrlConstants.GET_API_OVERVIEW.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const getAHCOverviewAPI = (
  payload: void
): Promise<{ data: HealthOverviewData }> => {
  const usecase = UrlConstants.GET_AHC_OVERVIEW.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const getProjectSummaryAPI = (
  payload: void
): Promise<{ data: ProjectSummaryData }> => {
  const usecase = UrlConstants.GET_PROJECT_SUMMARY.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}
