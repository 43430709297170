import { makeAutoObservable, observable } from 'mobx'

import { RootStore } from './RootStore'

export class ExceptionStoreV2 {
  currentTab: number
  groupedExceptions: any
  allExceptions: any
  activeGroupedException: any
  activeGroupedExceptionSeverity: string
  totalCount: number
  statisticsData: any
  stockChartData: any
  currentExceptionId: any
  constructor(private rootStore: RootStore) {
    this.currentTab = 0
    this.activeGroupedException = {}
    this.totalCount = 0
    this.activeGroupedExceptionSeverity = ''
    this.statisticsData = {}
    this.stockChartData = []
    this.groupedExceptions = []
    this.allExceptions = []
    this.currentExceptionId = ''
    makeAutoObservable(this, {
      activeGroupedException: observable.struct,
      totalCount: observable.struct,
      activeGroupedExceptionSeverity: observable.struct
    })
  }
  getCurrentTab() {
    return this.currentTab
  }
  setCurrentTab(value: number) {
    this.currentTab = value
  }
  getActiveGroupedException() {
    return this.activeGroupedException
  }
  setActiveGroupedException(value: any) {
    this.activeGroupedException = value
  }
  getActiveGroupedExceptionSeverity() {
    return this.activeGroupedExceptionSeverity
  }
  setActiveGroupedExceptionSeverity(value: string) {
    this.activeGroupedExceptionSeverity = value
  }
  getStatisticsData() {
    return this.statisticsData
  }
  setStatisticsData(value: object) {
    this.statisticsData = value
  }
  getStockChartData() {
    return this.stockChartData
  }
  setTotalCount(totalCount: number) {
    this.totalCount = totalCount
  }

  getTotalCount(): number {
    return this.totalCount
  }
  setStockChartData(value: any) {
    this.stockChartData = value
  }
  setGroupedExceptions(value: any) {
    this.groupedExceptions = value
  }
  getExceptionGroup(id: string) {
    return this.groupedExceptions.find(
      (item: any) => item.exceptionGroupId === id
    )
  }

  setAllExceptions(data: any) {
    this.allExceptions = data
  }
  getAllExceptions() {
    return this.allExceptions
  }

  getCurrentExceptionId() {
    return this.currentExceptionId
  }
  setCurrenExceptionId(id: string) {
    this.currentExceptionId = id
  }
}
