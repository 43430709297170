import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '../../../../../../components/New/Button/Button'
import { DDSTypography } from 'den-design-system'
import { OrganizationUserRoles } from '../../../../../../enum/OrganizationUserRoles.enum'
import React from 'react'
import { RefObject } from '../../../../../../components/common/InputField/Interface/Interface'
import Select from '../../../../../../components/New/Select/Select'
import Spinner from '../../../../../../components/common/Spinner/Spinner'
import StringConstants from '../../../../../../constants/StringConstants'
import Table from '../../../../../../components/common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../../../../components/New/Tooltip/Tooltip'
import { TrashCan } from '@carbon/icons-react'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../../../global/newPallete'
import routeConstants from '../../../../../../constants/RouteConstants'
import { timeFormats } from '../../../../../../enum/TIME'
import { timeZone } from '../../../../../../utils/DateTimeUtils'
import { updateOrganizationMember } from '../../../../../../api/organization/Organization.service'
import { useStoreContext } from '../../../../../../store/StoreContext'

const ExistingMember: React.FC<{
  data: []
  invitedUser?: boolean
  rowsPerPage?: number
  currentPage?: number
  loading?: boolean
  onPageChange?: (page: number) => void
  totalCount?: number
  handleRemoveMember?: (member: any) => void
  handleResendInvite?: (member: any) => void
  fetchMembers: any
  updateMembersRole?: (flag: boolean) => void
}> = ({
  data,
  handleRemoveMember = () => null,

  updateMembersRole = () => null,

  rowsPerPage = StringConstants.DEFAULT_TABLE_SIZE,
  currentPage = 0,
  onPageChange = () => null,
  totalCount = 0,
  fetchMembers
}) => {
  const store = useStoreContext()
  const { projectId } = useParams()

  const CustomDropdownOption = [
    {
      label: 'Owner',
      value: 'owner'
    },
    {
      label: 'Member',
      value: 'member'
    },
    {
      label: 'Billing',
      value: 'billing'
    }
  ]

  const [loading, setLoading] = React.useState<boolean>(false)
  const [dropdownLoading, setDropdownLoading] = React.useState<boolean>(false)

  const roleDropdownReference = React.useRef<RefObject>(null)
  const navigate = useNavigate()

  const handleDropdown = (role: string, _userId: string) => {
    role = role.toUpperCase()
    setLoading(true)
    updateMembersRole(true)
    roleDropdownReference.current?.startLoading()
    setDropdownLoading(true)
    updateOrganizationMember(role, _userId)
      .then(() => {
        fetchMembers()
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.SETTINGS.MEMBERS.UPDATE_MEMBERS.SUCCESS
        })

        if (store.userStore.id === _userId) {
          store.userStore.setUserRole(role)
          navigate(routeConstants.REDIRECT)
        }
        setLoading(false)
        updateMembersRole(false)
        roleDropdownReference.current?.stopLoading()
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: err
        })
        setLoading(false)
        updateMembersRole(false)
        roleDropdownReference.current?.stopLoading()
        fetchMembers()
      })
  }

  const column = [
    {
      columnHeaderStyle: { width: '25%' },
      columnDataStyle: { width: '25%' },
      heading: (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            User
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'user',
      render: (text: any) => (
        <div className='flex justify-start text-start row items-start'>
          <div>
            <Tooltip
              id={`tooltip-name-${text?.firstName + ' ' + text?.lastName}`}
              label={text?.firstName + ' ' + text?.lastName}
              className={`${
                typeof text?.firstName === 'string' &&
                typeof text?.lastName === 'string' &&
                text?.firstName.length + text?.lastName.length > 15
                  ? text?.firstName + ' ' + text?.lastName
                  : '!hidden'
              }`}
            >
              <div>
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  color={pallete.colors.textDark4}
                  className='tooltip-container sm:block overflow-hidden truncate w-[160px]'
                >
                  {text?.firstName + ' ' + text?.lastName}
                </DDSTypography.Paragraph>
              </div>
            </Tooltip>
          </div>
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '15%' },
      columnDataStyle: { width: '15%' },
      heading: (
        <div className='flex justify-center max-w-[160px] text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Role
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'role',
      render: (role: any) => (
        <div className='flex max-w-[160px] text-center items-center justify-center '>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            className='tooltip-container sm:block overflow-hidden truncate max-w-[160px]'
          >
            {role}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Email
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'user',
      render: (text: any) => (
        <Tooltip
          id={`tooltip-name-${text?.email}`}
          label={text?.email}
          className={`${
            typeof text?.email === 'string' && text?.email.length > 10
              ? text?.email
              : '!hidden'
          }`}
        >
          <div className='flex justify-center text-center row items-center'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container sm:block overflow-hidden truncate'
            >
              {text?.email}
            </DDSTypography.Paragraph>
          </div>
        </Tooltip>
      )
    },

    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Member Since
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'createdAt',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {timeZone(
              store.userStore.getUserPreference().timeZone,
              text,
              timeFormats.monthDateYear
            )}
          </DDSTypography.Paragraph>
        </div>
      )
    }
  ]

  const ownerColumn = [
    {
      columnHeaderStyle: { width: '300px' },
      columnDataStyle: { width: '300px' },
      heading: (
        <div className='flex justify-start text-left row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            User
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'user',
      render: (text: any) => (
        <div className='flex justify-start text-start row items-start max-w-[230px]'>
          <div>
            <Tooltip
              id={`tooltip-name-${text?.firstName + ' ' + text?.lastName}`}
              label={text?.firstName + ' ' + text?.lastName}
              className={`${
                typeof text?.firstName === 'string' &&
                typeof text?.lastName === 'string' &&
                text?.firstName.length + text?.lastName.length > 10
                  ? text?.firstName + ' ' + text?.lastName
                  : '!hidden'
              }`}
            >
              <div>
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  color={pallete.colors.textDark4}
                  className='tooltip-container sm:block overflow-hidden truncate max-w-[230px]'
                >
                  {text?.firstName + ' ' + text?.lastName}
                </DDSTypography.Paragraph>
              </div>
            </Tooltip>
          </div>
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '120px' },
      columnDataStyle: { width: '120px' },
      heading: (
        <div className='flex justify-center text-center max-w-[120px] row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Role
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'role',
      render: (item: any, fullItem: any) => {
        return (
          <div className='flex max-w-[120px] items-center cursor-pointer justify-center override-project-select'>
            <Select
              id='frequency-select'
              options={CustomDropdownOption}
              searchable={false}
              size='small'
              name='Frequency'
              disabled={
                store.userStore.getNoActiveSubscription() || dropdownLoading
              }
              value={item}
              onSelect={(id: string) => {
                handleDropdown(id, fullItem.user.id)
              }}
            />
          </div>
        )
      }
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Email
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'user',
      render: (text: any) => (
        <Tooltip
          id={`tooltip-name-${text?.email}`}
          label={text?.email}
          className={`${
            typeof text?.email === 'string' && text?.email.length > 10
              ? text?.email
              : '!hidden'
          }`}
        >
          <div className='flex justify-center text-center row items-center'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container sm:block overflow-hidden truncate'
            >
              {text?.email}
            </DDSTypography.Paragraph>
          </div>
        </Tooltip>
      )
    },

    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Member Since
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'createdAt',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {timeZone(
              store.userStore.getUserPreference().timeZone,
              text,
              timeFormats.monthDateYear
            )}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '5%' },
      columnDataStyle: { width: '5%' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='flex justify-center'>
          <Button
            id='right-btn'
            type='ghost'
            startIcon={
              <TrashCan size={16} color={pallete.colors.warningRed2} />
            }
            onClick={() => {
              handleRemoveMember(fullItem)
            }}
            disabled={store.userStore.getNoActiveSubscription() || loading}
          />
        </div>
      )
    }
  ]

  return (
    <>
      <div className='flex pt-[32px] w-full table-non-interactive-member'>
        {loading || store.uiStore.getGlobalLoader() ? (
          <Spinner />
        ) : (
          <Table
            tableStyle={{
              borderColor: pallete.colors.stroke2
            }}
            data={data}
            columns={
              store.userStore.getUserRole() === OrganizationUserRoles.OWNER
                ? ownerColumn
                : column
            }
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </>
  )
}

export default observer(ExistingMember)
