import StringConstants from '../constants/StringConstants'
import cookie from 'react-cookies'
import { isUndefined } from 'lodash'
import jwtDecode from 'jwt-decode'
import { useStoreContext } from '../store/StoreContext'

export const saveCookie = (key: string, value: string) => {
  cookie.save(key, value, { path: '/' })
}
interface JWTToken {
  email: string
  userName: string
  sub: string
  iat: number
  exp: number
}

export const isAuthenticatedUser = (): boolean => {
  try {
    const token = cookie.load(StringConstants.COOKIE_TOKEN)
    if (!isUndefined(token)) {
      const decodedToken: JWTToken = jwtDecode(token)
      const currentTime: number = Date.now() / 1000
      if (currentTime < decodedToken.exp) return true
    }
    const store = useStoreContext()
    store.clearStore()
    return false
  } catch (err) {
    return false
  }
}

export const getDataFromCookie = () => {
  const token = cookie.load(StringConstants.COOKIE_TOKEN)
  if (token) {
    const decoded: JWTToken = jwtDecode(token)
    return decoded
  } else return {}
}

export const deleteToken = async () => {
  await cookie.remove(StringConstants.COOKIE_TOKEN, { path: '/' })
}
