import { makeAutoObservable, observable } from 'mobx'

import { ExceptionIncidentPriority } from '../enum/exception-incident-priority.enum'
import { RootStore } from './RootStore'

export class ExceptionIncidentStore {
  id: string
  assigneeName: string
  description: string
  exceptionLog: any
  incidentNumber: string
  priority: ExceptionIncidentPriority | null
  savedLinks: string[]
  createdAt: string
  loading: boolean
  activityLogs: any
  isPublic: boolean

  incidentDetails: any

  constructor(private rootStore: RootStore) {
    this.id = ''
    this.assigneeName = ''
    this.description = ''
    this.exceptionLog = {}
    this.incidentNumber = ''
    this.priority = null
    this.savedLinks = []
    this.loading = false
    this.createdAt = ''
    this.activityLogs = {}
    this.isPublic = false
    this.incidentDetails = {}

    makeAutoObservable(this)
  }

  reset() {
    this.id = ''
    this.assigneeName = ''
    this.description = ''
    this.exceptionLog = ''
    this.incidentNumber = ''
    this.priority = null
    this.savedLinks = []
    this.loading = false
    this.incidentDetails = {}
    this.activityLogs = []
    this.isPublic = false
  }

  setIncidentDetails(incidentDetails: any) {
    this.rootStore.exceptionIncidentStore.incidentDetails = incidentDetails
    this.setId(incidentDetails.id)
    this.setAssigneeName(incidentDetails.assigneeName)
    this.setDescription(incidentDetails.description)
    this.setExceptionLog(incidentDetails.exceptionLog)
    this.setPriority(incidentDetails.priority)
    this.setSavedLinks(incidentDetails.savedLinks)
    this.setIncidentNumber(incidentDetails.incidentNumber)
    this.setCreatedAt(incidentDetails.createdAt)
  }

  getIncidentDetails() {
    return this.rootStore.exceptionIncidentStore.incidentDetails
  }

  setActivityLogs(data: any) {
    this.activityLogs = data
    this.loading = false
  }
  getActivityLogs() {
    return this.activityLogs
  }

  setCreatedAt(createdAt: string) {
    this.rootStore.exceptionIncidentStore.createdAt = createdAt
  }

  getCreatedAt() {
    return this.rootStore.exceptionIncidentStore.createdAt
  }

  getId() {
    return this.rootStore.exceptionIncidentStore.id
  }

  setId(id: string) {
    this.rootStore.exceptionIncidentStore.id = id
  }

  getAssigneeName() {
    return this.rootStore.exceptionIncidentStore.assigneeName
  }

  setAssigneeName(assigneeName: string) {
    this.rootStore.exceptionIncidentStore.assigneeName = assigneeName
  }

  getDescription() {
    return this.rootStore.exceptionIncidentStore.description
  }

  setDescription(description: string) {
    this.rootStore.exceptionIncidentStore.description = description
  }

  setExceptionLog(exceptiongLog: any) {
    this.rootStore.exceptionIncidentStore.exceptionLog = exceptiongLog
  }

  getExceptionLog() {
    return this.rootStore.exceptionIncidentStore.exceptionLog
  }

  setLoading(loading: boolean) {
    this.rootStore.exceptionIncidentStore.loading = loading
  }

  getLoading() {
    return this.rootStore.exceptionIncidentStore.loading
  }

  setIncidentNumber(incidentNumber: string) {
    this.rootStore.exceptionIncidentStore.incidentNumber = incidentNumber
  }
  getIncidentNumber() {
    return this.rootStore.exceptionIncidentStore.incidentNumber
  }

  setSavedLinks(savedLinks: string[]) {
    this.rootStore.exceptionIncidentStore.savedLinks = savedLinks
  }

  getSavedLinks() {
    return this.rootStore.exceptionIncidentStore.savedLinks
  }

  setPriority(priority: ExceptionIncidentPriority | null) {
    this.rootStore.exceptionIncidentStore.priority = priority
  }

  getPriority() {
    return this.rootStore.exceptionIncidentStore.priority
  }

  getIsPublic() {
    return this.isPublic
  }
  setIsPublic(isPublic: boolean) {
    this.isPublic = isPublic
  }
}
