import React, { useEffect, useRef, useState } from 'react'

import { FiCopy } from 'react-icons/fi'
import copy from 'copy-to-clipboard'
import successIcon from '../../../assets/icons/successIcon.svg'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useStoreContext } from '../../../store/StoreContext'

const CopyOnClick: React.FC<{
  value: string
  label?: string
  color?: string
  event?: boolean
}> = ({ value, label = 'Copy to clipboard', color = '#000', event }) => {
  const store = useStoreContext()
  const gaEventTracker = useAnalyticsEventTracker('COPY_ON_CLICK')
  const [copied, setCopied] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  // Handle the copying logic
  const handleCopy = () => {
    copy(value)
    setCopied(true)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => setCopied(false), 500)
  }

  // Clean up timeout on component unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return (
    <button
      onClick={() => {
        if (event) gaEventTracker('COPY_ON_CLICK')
        handleCopy()
      }}
      disabled={store.userStore.getNoActiveSubscription()}
      aria-label={label}
      role='button'
      className={`flex items-center ${
        store.userStore.getNoActiveSubscription()
          ? 'cursor-not-allowed opacity-50'
          : 'cursor-pointer'
      }`}
    >
      {copied ? (
        <img
          src={successIcon}
          alt='Copied'
          className='h-[16px] w-[16px]' // Tailwind for consistent sizing
        />
      ) : (
        <FiCopy
          size={16}
          style={{
            cursor: store.userStore.getNoActiveSubscription()
              ? 'not-allowed'
              : 'pointer',
            pointerEvents: store.userStore.getNoActiveSubscription()
              ? 'none'
              : 'auto'
          }}
          color={color}
          className='transition-colors duration-200 hover:text-blue-500'
        />
      )}
    </button>
  )
}

export default CopyOnClick
