import {
  CheckmarkOutline,
  PauseOutline,
  PauseOutlineFilled,
  PreviousFilled,
  Warning
} from '@carbon/icons-react'

import React from 'react'
import dayjs from 'dayjs'
import moment from 'moment-timezone'
import pallete from '../global/newPallete'

export const getIconForStatus = (status: string) => {
  switch (status) {
    case 'Up':
      return (
        <PreviousFilled
          className='rotate-90'
          size={20}
          color={pallete.colors.warningGreen}
        />
      )
    case 'Down':
      return (
        <PreviousFilled
          className='-rotate-90'
          size={20}
          color={pallete.colors.warningRed2}
        />
      )
    case 'Paused':
      return (
        <PauseOutlineFilled size={20} color={pallete.colors.warningOrange} />
      )
  }
}

export const convertToFormat = (date: any) => {
  const startDate = dayjs(date['created-from'])
  const endDate = dayjs(date['created-to'])
  const formattedStartDate = startDate.format('MMM DD, YYYY')
  const formattedEndDate = endDate.format('MMM DD, YYYY')
  return `${formattedStartDate} - ${formattedEndDate}`
}

export const reportFilterOptions = [
  {
    id: 1,
    heading: 'Date Range',
    isExpandable: true,
    filterAttribute: {
      start: 'created-from',
      end: 'created-to'
    },
    values: [],
    type: 'DATE_RANGE',
    variant: 'DATE_ONLY',
    displayOrder: 1,
    searchable: false
  }
]

export const statusToIcon = (status: string) => {
  switch (status) {
    case 'Checked':
      return (
        <CheckmarkOutline
          width='20px'
          height='20px'
          color={pallete.colors.warningGreen}
        />
      )
    case 'Paused':
      return (
        <PauseOutline
          width='20px'
          height='20px'
          color={pallete.colors.warningOrange}
        />
      )
    case 'Warning':
      return (
        <Warning
          width='20px'
          height='20px'
          color={pallete.colors.warningRed2}
        />
      )
  }
}

export const availabilityToColor = (availability: string) => {
  if (availability === '0%') {
    return pallete.colors.warningRed2
  } else if (availability === '-') {
    return pallete.colors.textDark4
  } else {
    return pallete.colors.warningGreen
  }
}

export function getEventDuration(
  startTime: string | Date,
  endTime: string | Date
): string {
  const start: Date = new Date(startTime)
  const end: Date = new Date(endTime)

  // Calculate the difference in milliseconds
  const durationMs: number = end.getTime() - start.getTime()

  // Convert to human-readable units
  const totalSeconds: number = Math.floor(durationMs / 1000)
  const seconds: number = totalSeconds % 60
  const totalMinutes: number = Math.floor(totalSeconds / 60)
  const minutes: number = totalMinutes % 60
  const totalHours: number = Math.floor(totalMinutes / 60)
  const hours: number = totalHours % 24
  const days: number = Math.floor(totalHours / 24)

  // Build the duration string, showing only the highest two units
  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ${hours} hour${
      hours > 1 ? 's' : ''
    }`
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} min${
      minutes > 1 ? 's' : ''
    }`
  } else if (minutes > 0) {
    return `${minutes} min${minutes > 1 ? 's' : ''} ${seconds} sec${
      seconds > 1 ? 's' : ''
    }`
  } else {
    return `${seconds} sec${seconds > 1 ? 's' : ''}`
  }
}

export function getTimeDiff(
  startTime: string | Date,
  endTime: string | Date,
  timeZone: string
): string {
  const start = moment.tz(startTime, timeZone)
  const end = moment.tz(endTime, timeZone)

  if (end.isBefore(start)) {
    return '0s'
  }
  const durationMs = end.diff(start)

  const totalSeconds = Math.floor(durationMs / 1000)
  const seconds = totalSeconds % 60
  const totalMinutes = Math.floor(totalSeconds / 60)
  const minutes = totalMinutes % 60
  const totalHours = Math.floor(totalMinutes / 60)
  const hours = totalHours % 24
  const days = Math.floor(totalHours / 24)

  if (days > 0) {
    return `${days}d ${hours}h`
  } else if (hours > 0) {
    return `${hours}h ${minutes}m`
  } else if (minutes > 0) {
    return `${minutes}m ${seconds}s`
  } else {
    return `${seconds}s`
  }
}

export const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer')
}
