import * as AHCTypes from './AHC.types'

import ApiManager from '../Api.manager'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { DebounceConstants } from '../../constants/DebounceConstants'
import HttpConstants from '../../constants/HttpConstants'
import UrlConstants from '../../constants/UrlConstants'

/**
 *
 * @param {void} payload
 */
export const getAllHealthChecks = (payload: any) => {
  const usecase = UrlConstants.GET_ALL_HEALTH_CHECKS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}
/**
 *
 * @param {AddAppHealthCheckPayload} payload
 */
export const addHealthCheck = (payload: AHCTypes.AddAppHealthCheckPayload) => {
  const usecase = UrlConstants.ADD_HEALTH_CHECK.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}
/**
 *
 * @param {void} payload
 */
export const getHealthSummary = (payload: void) => {
  const usecase = UrlConstants.GET_HEALTH_SUMMARY.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}
/**
 *
 * @param  {{ $ahcId: string }} payload
 */
export const getApiHealth = (payload: { $ahcId: string }) => {
  const usecase = UrlConstants.GET_API_HEALTH.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const getHealthCheckBadge = (payload: { $encryptedId: string }) => {
  const usecase = UrlConstants.GET_API_HEALTH_BADGE.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCall(usecase, HttpConstants.GET_METHOD, payload)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}: `, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

/**
 *
 * @param  {{ $ahcId: string }} payload
 */
export const getHealthReportDetails = (payload: { $ahcId: string }) => {
  const usecase = UrlConstants.GET_HEALTH_CHECK_REPORT_DETAILS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error
        )
      })
  })
}

/**
 *
 * @param {{ $ahcId: string }} payload
 */
export const deleteHealthCheck = (payload: { $ahcId: string }) => {
  const usecase = UrlConstants.DELETE_HEALTH_CHECK.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.DELETE_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

/**
 *
 * @param {UpdateHealthCheckPayload} payload
 */
export const updateHealthCheck = (
  payload: AHCTypes.UpdateHealthCheckPayload
) => {
  const usecase = UrlConstants.UPDATE_HEALTH_CHECK.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

/**
 *
 * @param  {{ ahcId: string }} payload
 */
export const archiveHealthCheck = (payload: { ahcId: string }) => {
  const usecase = UrlConstants.ARCHIVE_HEALTH_CHECK.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.DELETE_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

/**
 *
 * @param {{ $ahcId: string }} payload
 */
export const restoreHealthCheck = (payload: { $ahcId: string }) => {
  const usecase = UrlConstants.RESTORE_HEALTH_CHECK.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

//query
export const getHealthCheckReports = (payload: {
  page: number
  size: number
  'created-from'?: string
  'created-to'?: string
}) => {
  const usecase = UrlConstants.GET_HEALTH_CHECK_REPORTS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const getAllAhcLogs = (payload: void) => {
  const usecase = UrlConstants.GET_ALL_AHC_LOGS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const deleteAllAhcLogs = (payload: void) => {
  const usecase = UrlConstants.DELETE_ALL_AHC_LOGS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.DELETE_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const getAhcEventLogs = (payload: any) => {
  const usecase = UrlConstants.GET_AHC_EVENT_LOGS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error
        )
      })
  })
}

export const getAhcLogsForEvent = (payload: any) => {
  const usecase = UrlConstants.GET_AHC_LOGS_FOR_EVENT.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error
        )
      })
  })
}

export const getAhcEventLogsFilters = () => {
  const usecase = UrlConstants.GET_AHC_EVENT_LOGS_FILTERS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      {}
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error
        )
      })
  })
}

export const getAhcLog = (payload: {
  $ahcId: string
  page: number
  size: number
  'created-from'?: string
  'created-to'?: string
}) => {
  const usecase = UrlConstants.GET_AHC_LOG.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error
        )
      })
  })
}

export const deleteAhcLog = (payload: { $id: string }) => {
  const usecase = UrlConstants.DELETE_AHC_LOG.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.DELETE_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}
export const getAllUrlAhcLogs = (payload: { $urlId: string }) => {
  const usecase = UrlConstants.GET_ALL_URL_AHC_LOGS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const getAhcFilters = () => {
  const usecase = UrlConstants.GET_HEALTH_FILTERS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      {}
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else throw response
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const getAhcLogFilters = () => {
  const usecase = UrlConstants.GET_AHC_LOG_FILTERS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      {}
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else throw response
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const deleteAllUrlAhcLogs = (payload: { $urlId: string }) => {
  const usecase = UrlConstants.DELETE_ALL_URL_AHC_LOGS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const archiveAhcLog = (payload: { $ahcLogId: string }) => {
  const usecase = UrlConstants.ARCHIVE_AHC_LOG.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.DELETE_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const restoreAhcLogs = (payload: { $ahcLogId: string }) => {
  const usecase = UrlConstants.ARCHIVE_AHC_LOG.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}
const getAHCSearchContextsAPI = (
  query: string,
  queryPrams?: any
): Promise<{ data: string[] }> => {
  const usecase = UrlConstants.GET_HEALTH_SEARCH_CONTEXTS.USECASE

  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      {
        query,
        ...queryPrams,
        includeCancelToken: true
      }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const debouncedAHCSearchContextsAPI = AwesomeDebouncePromise(
  getAHCSearchContextsAPI,
  DebounceConstants.LOOK_UP.DELAY,
  DebounceConstants.LOOK_UP.OPTIONS
)
