import { DDSInput, DDSTextArea, DDSTypography } from 'den-design-system'
import React, { useEffect } from 'react'
import _, { isNull } from 'lodash'
import { createSearchParams, useNavigate, useParams } from 'react-router-dom'
import {
  getAnIncident,
  updateAnIncident
} from '../../api/incident/incident.service'

import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import { Button } from '../../components/New/Button/Button'
import { Close } from '@carbon/icons-react'
import DatePicker from '../../components/New/Datepicker/Datepicker'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../constants/HeaderConstants'
import IncidentConstants from '../../constants/IncidentConstants'
import Joi from 'joi'
import RegexConstants from '../../constants/RegexConstants'
import SecureComponent from '../../components/Layout/SecureComponent'
import Select from '../../components/New/Select/Select'
import Spinner from '../../components/common/Spinner/Spinner'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../../components/common/DDS/Toast/Toast'
import dayjs from 'dayjs'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import pallete from '../../global/newPallete'
import { replaceUrl } from '../../utils/UrlUtils'
import routeConstants from '../../constants/RouteConstants'
import { useState } from 'react'
import { useStoreContext } from '../../store/StoreContext'

const UpdateIncident = () => {
  const store = useStoreContext()
  const [addButtonDisabled, setAddButtonDisabled] = useState(false)

  const [reference, setReference] = useState<string>('')

  const [loading, setLoading] = useState<boolean>(false)
  const [incidentDetails, setIncidentDetails] = useState<any>(null)
  const [createButtonDisabled, setCreateButtonDisabled] = useState(false)
  const [priorityValue, setPriorityValue] = useState<string>('P1')

  const { incidentId } = useParams()
  const [isFormValid, setIsFormValid] = useState(false)
  const [originalData, setOriginalData] = useState<any>(null)
  const [hasFormChanged, setHasFormChanged] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    fetchAnIncident()
  }, [incidentId])

  useEffect(() => {
    if (incidentDetails?.priorityStatus)
      setPriorityValue(incidentDetails?.priorityStatus)
  }, [incidentDetails?.priorityStatus])

  const projects = [
    {
      label: 'P0',
      value: 'P0'
    },
    {
      label: 'P1',
      value: 'P1'
    },
    {
      label: 'P2',
      value: 'P2'
    },
    {
      label: 'P3',
      value: 'P3'
    }
  ]

  const schema = Joi.object({
    priorityStatus: Joi.string().required().label('Priority Status'),
    identifiedOn: Joi.date().required().label('Identified On'),
    shortDescription: Joi.string()
      .max(150)
      .required()
      .label('Short Description'),
    longDescription: Joi.string().required().label('Long Description')
  })

  useEffect(() => {
    if (incidentDetails && originalData) {
      // Validate the current form values
      const { error } = schema.validate(
        {
          priorityStatus: incidentDetails.priorityStatus,
          identifiedOn: incidentDetails.identifiedOn,
          shortDescription: incidentDetails.shortDescription,
          longDescription: incidentDetails.longDescription
        },
        { abortEarly: false }
      )

      // Determine if the form has changed compared to the original data
      const hasFormChanged =
        originalData.priorityStatus !== incidentDetails.priorityStatus ||
        originalData.identifiedOn !== incidentDetails.identifiedOn ||
        originalData.shortDescription !== incidentDetails.shortDescription ||
        originalData.longDescription !== incidentDetails.longDescription ||
        !_.isEqual(originalData.referenceLinks, incidentDetails.referenceLinks)

      // Disable the button if there are validation errors or if the form has not changed
      if (error || !hasFormChanged) {
        setIsButtonDisabled(true)
      } else {
        setIsButtonDisabled(false)
      }
    } else {
      setIsButtonDisabled(true)
    }
  }, [incidentDetails, originalData])

  const fetchAnIncident = () => {
    setLoading(true)

    getAnIncident({ $incidentId: incidentId })
      .then((response: any) => {
        const incidentData = response.data

        const data = {
          incidentId: incidentData.id,
          priorityStatus: incidentData.priority,
          identifiedOn: incidentData.identifiedOn,
          shortDescription: incidentData.briefDescription,
          longDescription: incidentData.longDescription,
          referenceLinks: incidentData.savedLinks as string[],
          incidentNumber: incidentData.incidentNumber
        }

        setOriginalData(data)
        setIncidentDetails(data)
      })
      .catch((error) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.INCIDENT.GET_ALL_INCIDENTS.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleUpdateIncident = () => {
    if (!isButtonDisabled) {
      setLoading(true)

      const updateIncidentDTO = {
        priority: incidentDetails.priorityStatus,
        identifiedOn: incidentDetails.identifiedOn,
        briefDescription: incidentDetails.shortDescription,
        longDescription: incidentDetails.longDescription,
        savedLinks: incidentDetails.referenceLinks as string[]
      }

      updateAnIncident({ $incidentId: incidentId, ...updateIncidentDTO })
        .then((response) => {
          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.INCIDENT.UPDATE_AN_INCIDENT.SUCCESS
          })
          const route = replaceUrl(
            routeConstants.MANAGE_INCIDENTS,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ).replace(':incidentId', String(incidentId))

          const queryParams = { tab: '1' }
          navigate({
            pathname: route,
            search: `?${createSearchParams(queryParams)}`
          })
        })
        .catch((error) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.INCIDENT.UPDATE_AN_INCIDENT.ERROR
          })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      ToastNotification({
        type: 'error',
        message: ToastMessageConstants.INCIDENT.REQUIRED_FIELD.ERROR
      })
      store.uiStore.setGlobalLoader(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isNull(store.incidentStore.getIncidentData())) {
      fetchAnIncident()
    } else {
      setIncidentDetails(store.incidentStore.getIncidentData())
    }
  }, [store.incidentStore.getIncidentData()])

  useEffect(() => {
    store.breadcrumbStore.setMultipleBreadcrumbsOptions([
      {
        label: 'Incidents',
        link:
          replaceUrl(
            routeConstants.INCIDENT,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) + '?page=1&tab=1'
      },
      {
        label: incidentDetails?.incidentNumber,
        link: replaceUrl(
          routeConstants.MANAGE_INCIDENTS,
          store.scopeStore.getScope()['organization-id'] as string,
          store.scopeStore.getScope()['project-id'] as string,
          store.scopeStore.getScope()['environment-id'] as string
        ).replace(':incidentId', String(incidentId))
      },
      {
        label: 'Update an Incident',
        link: replaceUrl(
          routeConstants.UPDATE_INCIDENT,
          store.scopeStore.getScope()['organization-id'] as string,
          store.scopeStore.getScope()['project-id'] as string,
          store.scopeStore.getScope()['environment-id'] as string
        )
      }
    ])
  }, [store.breadcrumbStore.getBreadcrumbsOptions(), incidentDetails])

  return (
    <>
      <DocumentHeader header={HeaderConstants.INCIDENT} />
      {loading || store.uiStore.getGlobalLoader() ? (
        <Spinner />
      ) : (
        <div className='gap-[4px] flex flex-col  justify-between h-full'>
          <div className='py-[24px]'>
            <DDSTypography.Title
              variant='semiBold'
              type='h3'
              color={pallete.colors.textDark3}
              className='gap-[32px]'
            >
              {IncidentConstants.REPORT_AN_INCIDENT}
            </DDSTypography.Title>
            <div className='pt-[32px] gap-x-[52px] flex flex-row'>
              <div className='w-[65%]'>
                <div className='flex flex-row gap-[24px] w-[100%] incident-select '>
                  <Select
                    size='large'
                    label='Priority'
                    id='update-priority'
                    required
                    value={priorityValue}
                    name='projects'
                    options={projects}
                    searchable={false}
                    placeholder='Choose'
                    onSelect={(value) => {
                      setIncidentDetails({
                        ...incidentDetails,
                        priorityStatus: value
                      })
                    }}
                  />
                  <DatePicker
                    id='update-incident-datepicker'
                    value={dayjs(incidentDetails?.identifiedOn)}
                    name='Identified on'
                    required
                    format='MM/dd/YYYY'
                    disabled={true}
                    inputWidth={`${store.uiStore.getSidebarExpanded() ? '370px' : '490px'}`}
                    inputHeight='32px'
                    label='Identified on'
                    maxDate={dayjs()}
                    shape='roundedRectangle'
                    placeholder='Select Date'
                    onChange={(date: any) => {
                      setIncidentDetails({
                        ...incidentDetails,
                        identifiedOn: moment(date).toISOString()
                      })
                    }}
                  />
                </div>
                <div className='pt-[32px]'>
                  <DDSInput
                    name='Short Description'
                    label='Short Description'
                    required
                    maxLength={150}
                    height='32px'
                    shape='roundedRectangle'
                    type='text'
                    onChange={(e) => {
                      setIncidentDetails({
                        ...incidentDetails,
                        shortDescription: e.target.value
                      })
                    }}
                    style={{
                      borderRadius: 4,
                      width: '100%'
                    }}
                    value={incidentDetails?.shortDescription}
                    validateInput={true}
                    variant='plain'
                    onError={(error: any) => {
                      setCreateButtonDisabled(error)
                    }}
                  />
                </div>
                <div className='pt-[32px] override-textarea-border-radius'>
                  <DDSTextArea
                    name='Long Description'
                    label='Long Description'
                    minRow={5}
                    maxLength={500}
                    required={true}
                    style={{
                      borderRadius: '6px',
                      width: '100%'
                    }}
                    value={incidentDetails?.longDescription}
                    onChange={(e: any) => {
                      setIncidentDetails({
                        ...incidentDetails,
                        longDescription: e.target.value
                      })
                    }}
                  />
                </div>
                <div className='!w-[100%] flex pt-[32px] flex-row align-center gap-[24px]'>
                  <div className='!w-[100%] reference-link'>
                    <DDSInput
                      label='Reference Links'
                      shape='roundedRectangle'
                      type='text'
                      height='32px'
                      onChange={(e) => {
                        setReference(e.target.value)
                        if (
                          incidentDetails.referenceLinks.includes(
                            e.target.value
                          ) ||
                          !RegexConstants.REFERENCE_REGEX.test(e.target.value)
                        ) {
                          setAddButtonDisabled(true)
                        } else {
                          setAddButtonDisabled(false)
                        }
                      }}
                      validationRegex={
                        new RegExp(RegexConstants.REFERENCE_REGEX)
                      }
                      validate={(value: string) => {
                        if (
                          RegexConstants.REFERENCE_REGEX.test(value) &&
                          incidentDetails?.referenceLinks?.includes(value)
                        ) {
                          return {
                            error: true,
                            message: 'Reference link already exists'
                          }
                        } else if (value === '') {
                          return {
                            error: false,
                            message: ''
                          }
                        } else if (
                          !RegexConstants.REFERENCE_REGEX.test(value)
                        ) {
                          return {
                            error: true,
                            message: 'Please enter a valid reference link'
                          }
                        }
                        return {
                          error: false,
                          message: ''
                        }
                      }}
                      value={reference}
                      name='referenceLinks'
                      validateInput={true}
                      variant='plain'
                    />
                  </div>
                  <div className='incident-add-button my-[28px]'>
                    <Button
                      id='add-links'
                      disabled={reference.length == 0 || addButtonDisabled}
                      size='medium'
                      label='Add Link'
                      onClick={() => {
                        if (
                          !incidentDetails?.referenceLinks.includes(reference)
                        ) {
                          setIncidentDetails({
                            ...incidentDetails,
                            referenceLinks: [
                              reference,
                              ...incidentDetails.referenceLinks
                            ]
                          })
                          setReference('')
                        } else {
                          setAddButtonDisabled(true)
                        }
                      }}
                    />
                  </div>
                </div>
                <div className='flex flex-col gap-[8px]'>
                  {incidentDetails?.referenceLinks.map(
                    (item: any, index: any) => (
                      <div
                        className='flex w-[100%] h-[44px] items-center justify-between p-[12px] border-[1px] rounded-md'
                        key={index}
                      >
                        <div
                          onClick={(e: any) => {
                            e.preventDefault()
                            window.open(item, '_blank', 'noopener,noreferrer')
                          }}
                        >
                          <DDSTypography.Paragraph
                            variant='medium'
                            size='para'
                            color={pallete.colors.textDark2}
                            link={true}
                            className={`flex text-ellipsis truncate ${store.uiStore.getSidebarExpanded() ? 'max-w-[670px] 2xl:max-w-[950px]' : 'max-w-[790px] 2xl:max-w-[1060px]'}`}
                          >
                            {item}
                          </DDSTypography.Paragraph>
                        </div>
                        <div className='flex w-[20px]'>
                          <Close
                            size={20}
                            color={pallete.colors.textButtonText}
                            onClick={() => {
                              const updatedLinks = [
                                ...incidentDetails.referenceLinks.slice(
                                  0,
                                  index
                                ),
                                ...incidentDetails.referenceLinks.slice(
                                  index + 1
                                )
                              ]

                              setIncidentDetails({
                                ...incidentDetails,
                                referenceLinks: [...updatedLinks]
                              })
                              if (updatedLinks.includes(reference)) {
                                setAddButtonDisabled(true)
                              } else {
                                setAddButtonDisabled(false)
                              }
                            }}
                            className='cursor-pointer'
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className='w-[35%]'>
                <div
                  style={{
                    backgroundColor: pallete.colors.surface6,
                    padding: 24,
                    borderRadius: 10
                  }}
                >
                  <DDSTypography.Title
                    variant='bold'
                    type='h5'
                    style={{ marginBottom: 12 }}
                    color={pallete.colors.textDark4}
                  >
                    <span>{IncidentConstants.WHATS_GOING_ON}</span>
                  </DDSTypography.Title>
                  <DDSTypography.Paragraph
                    variant='medium'
                    size='para'
                    color={pallete.colors.textDark6}
                  >
                    {IncidentConstants.WHATS_GOING_ON_DESC}
                  </DDSTypography.Paragraph>
                </div>
              </div>
            </div>
          </div>
          <div className='flex items-end justify-end relative'>
            <hr className='w-full h-[1px] flex absolute top-0' />
            <div className='flex items-end justify-end px-[20px] py-[20px] gap-x-[12px]'>
              <Button
                id='cancel-incident'
                type='outline'
                size='medium'
                label='Cancel'
                onClick={() => {
                  const route = replaceUrl(
                    routeConstants.MANAGE_INCIDENTS,
                    store.scopeStore.getScope()['organization-id'] as string,
                    store.scopeStore.getScope()['project-id'] as string,
                    store.scopeStore.getScope()['environment-id'] as string
                  ).replace(':incidentId', String(incidentId))

                  const queryParams = { tab: '1' }
                  navigate({
                    pathname: route,
                    search: `?${createSearchParams(queryParams)}`
                  })
                }}
              />
              <Button
                id='update-incident'
                size='medium'
                label='Update Incident'
                onClick={handleUpdateIncident}
                disabled={
                  loading ||
                  store.uiStore.getGlobalLoader() ||
                  isButtonDisabled ||
                  store.userStore.getNoActiveSubscription()
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SecureComponent(
  observer(UpdateIncident),
  AccessRoleConstants.MEMBER_LEVEL
)
