import ApiManager from '../Api.manager'
import HttpConstants from '../../constants/HttpConstants'
import UrlConstants from '../../constants/UrlConstants'

export const makeApiCallToInstance = (instanceUrl: string): any => {
  return new Promise((resolve, reject) => {
    fetch(instanceUrl, { mode: 'no-cors' })
      .then(() => resolve(true))
      .catch((err) => reject(err))
  })

  return true
}

export const connectToSalesforceInstance = (
  code: string,
  instanceUrl: string,
  headers: object
): any => {
  return new Promise((resolve, reject) => {
    const usecase = UrlConstants.CONNECT_TO_SALESFORCE.USECASE
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.POST_METHOD,
      {
        code,
        headers,
        instanceUrl
      }
    )
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const checkInstanceAvailability = (instanceUrl: string): any => {
  const usecase = UrlConstants.CHECK_INSTANCE_AVAILABILITY.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      { instanceUrl }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const getSalesforceAuditDataAPI = (): any => {
  const usecase = UrlConstants.GET_SALESFORCE_AUDIT_DATA.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      {}
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}
