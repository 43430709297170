import { makeAutoObservable, observable } from 'mobx'

import { RootStore } from './RootStore'

export class FilterStore {
  filter: Record<string, string>
  filterDisabled: boolean
  filtersApplied: boolean
  constructor(private rootStore: RootStore) {
    this.filter = {}
    this.filterDisabled = false
    this.filtersApplied = false
    makeAutoObservable(this, {
      filter: observable.struct,
      filterDisabled: observable.struct,
      filtersApplied: observable.struct
    })
  }
  getFilterDisabled() {
    return this.filterDisabled
  }
  setFilterDisabled(filterDisabled: boolean) {
    this.filterDisabled = filterDisabled
  }

  setFiltersApplied(filtersApplied: boolean) {
    this.filtersApplied = filtersApplied
  }
  getFiltersApplied() {
    return this.filtersApplied
  }
  setFilters(filter: Record<string, string>) {
    this.filter = filter
  }

  getFilters(): Record<string, string> {
    return this.filter
  }

  reset() {
    this.filter = {}
    this.filterDisabled = false
    this.filtersApplied = false
  }
}
