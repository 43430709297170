import React, { useEffect, useState } from 'react'
import {
  statusToIcon,
  transformIntervalData
} from '../../../../utils/OverviewUtils'

import { AtoB } from '../../../../utils/UrlUtils'
import { ChevronRight } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import LinkCard from '../../../../components/OverviewPage/LinkCard'
import NoFilterImage from '../../../../assets/images/folder-not-found 1.svg'
import NoRecord from '../../../../components/common/NoContent/NoRecord'
import NoRecordImage from '../../../../assets/images/add.svg'
import OverviewGraph from '../../../../components/OverviewPage/OverviewGraph'
import OverviewStringConstants from '../../../../constants/OverviewStringConstants'
import OverviewTable from '../../../../components/OverviewPage/OverviewTable'
import Spinner from '../../../../components/common/Spinner/Spinner'
import { ToastMessageConstants } from '../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../../components/New/Tooltip/Tooltip'
import ViewCard from '../../../../components/OverviewPage/ViewCard'
import { getAHCOverviewAPI } from '../../../../api/overview/Overview.service'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../global/newPallete'
import { timeMetric } from '../../../../utils/DateTimeUtils'
import { useParams } from 'react-router-dom'
import { useStoreContext } from '../../../../store/StoreContext'

interface uptimeProps {
  setFindUptimeOverview: any
  navigateToAHC: any
  handleViewMore: any
}
const UptimeOverview: React.FC<uptimeProps> = ({
  setFindUptimeOverview,
  navigateToAHC,
  handleViewMore
}) => {
  const store = useStoreContext()
  const [overviewData, setOverviewData] = useState<any>()
  const [graphTab, setGraphTab] = useState<string>('1')
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    findUptimeOverview()
  }, [store.scopeStore.getScope()])

  useEffect(() => {
    if (setFindUptimeOverview) {
      setFindUptimeOverview(findUptimeOverview)
    }
  }, [setFindUptimeOverview])

  const findUptimeOverview = () => {
    if (!isEmpty(store.scopeStore.getScope()['environment-id'])) {
      setLoading(true)
      store.uiStore.setGlobalLoader(true)
      getAHCOverviewAPI()
        .then((response) => {
          setOverviewData(response.data)
          store.overviewStore.setHealthData(response.data)
        })
        .catch((e) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.OVERVIEW.FETCH_AHC.ERROR
          })
        })
        .finally(() => {
          setLoading(false)
          store.uiStore.setGlobalLoader(false)
        })
    }
  }

  const topCardData = [
    {
      id: 1,
      title: OverviewStringConstants.HIGHEST_AVAILABILITY,
      content: (
        <Tooltip
          id='highest-availability-tooltip'
          label={overviewData?.highestAvailability?.name}
          className={`max-w-fit ${
            typeof overviewData?.highestAvailability?.name === 'string' &&
            overviewData?.highestAvailability?.name.length > 28
              ? overviewData?.highestAvailability?.name
              : '!hidden'
          }`}
        >
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            className='truncate max-w-[240px]'
            color={pallete.colors.textDark3}
          >
            {overviewData?.highestAvailability?.name ?? '-'}
          </DDSTypography.Title>
        </Tooltip>
      ),
      secondaryContent: overviewData?.highestAvailability?.name ? (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.warningGreen}
        >
          {overviewData?.highestAvailability?.availability &&
          !isNaN(overviewData?.highestAvailability?.availability)
            ? '(' +
              overviewData?.highestAvailability?.availability.toFixed(2) +
              '%)'
            : '-'}
        </DDSTypography.Title>
      ) : null,
      onClick: () => {
        typeof overviewData?.highestAvailability !== 'string' &&
        overviewData?.highestAvailability?.id &&
        overviewData?.highestAvailability?.id != '-'
          ? navigateToAHC({
              id: overviewData?.highestAvailability?.id
            })
          : null
      }
    },
    {
      id: 2,
      title: OverviewStringConstants.HIGHEST_DOWNTIME,
      content: (
        <Tooltip
          id='highest-downtime-tooltip'
          label={overviewData?.highestDowntime?.name}
          className={`max-w-fit ${
            typeof overviewData?.highestDowntime?.name === 'string' &&
            overviewData?.highestDowntime?.name.length > 28
              ? overviewData?.highestDowntime?.name
              : '!hidden'
          }`}
        >
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            className='truncate max-w-[240px]'
            color={pallete.colors.textDark3}
          >
            {overviewData?.highestDowntime?.name ?? '-'}
          </DDSTypography.Title>
        </Tooltip>
      ),
      secondaryContent: overviewData?.highestDowntime?.name ? (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.warningRed2}
        >
          {overviewData?.highestDowntime?.duration &&
          !isNaN(overviewData?.highestDowntime?.duration)
            ? '(' +
              timeMetric(
                overviewData?.highestDowntime?.duration,
                overviewData?.highestDowntime?.unit
              ) +
              ')'
            : '-'}
        </DDSTypography.Title>
      ) : null,
      onClick: () => {
        typeof overviewData?.highestDowntime !== 'string' &&
        overviewData?.highestDowntime?.id &&
        overviewData?.highestDowntime?.id != '-'
          ? navigateToAHC({
              id: overviewData?.highestDowntime?.id
            })
          : null
      }
    },
    {
      id: 3,
      title: OverviewStringConstants.SLOWEST_RESPONSE_TIME,
      content: (
        <Tooltip
          id='slowest-response-time-uptime-tooltip'
          label={overviewData?.slowestResponseTime?.name}
          className={`max-w-fit ${
            typeof overviewData?.slowestResponseTime?.name === 'string' &&
            overviewData?.slowestResponseTime?.name.length > 28
              ? overviewData?.slowestResponseTime?.name
              : '!hidden'
          }`}
        >
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            className='truncate max-w-[240px]'
            color={pallete.colors.textDark3}
          >
            {overviewData?.slowestResponseTime?.name
              ? overviewData?.slowestResponseTime?.name
              : '-'}
          </DDSTypography.Title>
        </Tooltip>
      ),
      secondaryContent: overviewData?.slowestResponseTime?.name ? (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark2}
        >
          {overviewData?.slowestResponseTime?.duration &&
          !isNaN(overviewData?.slowestResponseTime?.duration)
            ? '(' +
              timeMetric(
                overviewData?.slowestResponseTime?.duration,
                overviewData?.slowestResponseTime?.unit
              ) +
              ')'
            : '-'}
        </DDSTypography.Title>
      ) : null,
      onClick: () => {
        typeof overviewData?.slowestResponseTime !== 'string' &&
        overviewData?.slowestResponseTime?.id &&
        overviewData?.slowestResponseTime?.id != '-'
          ? navigateToAHC({
              id: overviewData?.slowestResponseTime?.id
            })
          : null
      }
    }
  ]

  const sideCardData = [
    {
      id: 1,
      title: OverviewStringConstants.TOTAL_CHECKS,
      content: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark3}
        >
          {overviewData?.totalApplications ?? '-'}
        </DDSTypography.Title>
      ),
      onClick: () => {
        navigateToAHC()
      }
    },
    {
      id: 2,
      title: OverviewStringConstants.APPLICATIONS_UP,
      content: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.warningGreen}
        >
          {overviewData?.totalUp ?? '-'}
        </DDSTypography.Title>
      ),
      onClick:
        overviewData?.totalUp && overviewData?.totalUp !== '-'
          ? () => {
              navigateToAHC({ status: 'Up' })
            }
          : null
    },
    {
      id: 3,
      title: OverviewStringConstants.APPLICATIONS_DOWN,
      content: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.warningRed2}
        >
          {overviewData?.totalDown ?? '-'}
        </DDSTypography.Title>
      ),
      onClick:
        overviewData?.totalDown && overviewData?.totalDown !== '-'
          ? () => {
              navigateToAHC({ status: 'Down' })
            }
          : null
    }
  ]

  const tabOptions = [
    {
      label: (
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={
            graphTab === '1'
              ? pallete.colors.textDark1
              : pallete.colors.textDark5
          }
        >
          {OverviewStringConstants.DOWN}
        </DDSTypography.Paragraph>
      )
    },
    {
      label: (
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={
            graphTab === '2'
              ? pallete.colors.textDark1
              : pallete.colors.textDark5
          }
        >
          {OverviewStringConstants.UP}
        </DDSTypography.Paragraph>
      )
    }
  ]
  const columns = [
    {
      columnDataStyle: {},
      dataLabel: 'name',
      render: (text: any) => (
        <div className='flex justify-start text-left'>
          <Tooltip
            id={`${text}-tooltip`}
            label={text}
            className={`max-w-fit ${
              typeof text === 'string' && text.length > 120 ? text : '!hidden'
            }`}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate max-w-[810px]'
              color={pallete.colors.textDark4}
            >
              {text ?? '-'}
            </DDSTypography.Paragraph>
          </Tooltip>
        </div>
      )
    },
    {
      columnDataStyle: { width: '187px' },
      dataLabel: 'status',
      render: (text: any) => (
        <div className='flex justify-center text-center'>
          {statusToIcon(text)}
        </div>
      )
    },
    {
      columnDataStyle: { width: '28px' },
      dataLabel: 'id',
      render: (id: any) => (
        <div className='flex justify-center items-center'>
          <ChevronRight
            className='cursor-pointer'
            size={16}
            color={pallete.colors.textDark2}
            onClick={() => {
              navigateToAHC({ id })
            }}
          />
        </div>
      )
    }
  ]

  return loading || !overviewData ? (
    <Spinner />
  ) : overviewData.totalApplications !== 0 ? (
    <div className='flex flex-col gap-[40px]'>
      <div className='flex items-start gap-[32px]'>
        {topCardData.map((item) => (
          <LinkCard
            key={item.id}
            linkText={item.title}
            content={item.content}
            secondaryContent={item.secondaryContent}
            onClick={item.onClick}
          />
        ))}
        <ViewCard
          title={OverviewStringConstants.TOP_10_CHECKS}
          onClick={() => handleViewMore('health', 0)}
        />
      </div>
      <div className='flex items-center justify-between self-stretch gap-[40px] py-[12px]'>
        <div className='flex flex-col items-start gap-[20px] w-[20%]'>
          {sideCardData.map((item) => (
            <LinkCard
              key={item.id}
              linkText={item.title}
              content={item.content}
              onClick={item.onClick}
            />
          ))}
        </div>
        <div className='w-[80%]'>
          <OverviewGraph
            data={transformIntervalData(overviewData, graphTab, 'health')}
            tab={graphTab}
            setTab={setGraphTab}
            tabOptions={tabOptions}
            title={
              graphTab === '1'
                ? OverviewStringConstants.APPLICATIONS_DOWN
                : OverviewStringConstants.APPLICATIONS_UP
            }
          />
        </div>
      </div>
      {overviewData?.recentApplications?.length > 0 && (
        <div className='w-full'>
          <OverviewTable
            title={OverviewStringConstants.RECENT_CHECKS}
            data={overviewData?.recentApplications}
            columns={columns}
          />
        </div>
      )}
    </div>
  ) : (
    <NoRecord
      imageSrc={
        !overviewData.zeroApplicationsCount ? NoFilterImage : NoRecordImage
      }
      text={
        !overviewData.zeroApplicationsCount
          ? OverviewStringConstants.NO_RECORDS_FOUND
          : ` Add a new Uptime Check to get started!`
      }
    />
  )
}

export default observer(UptimeOverview)
