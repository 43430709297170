import { Grid, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import DividerLine from '../../components/common/DividerLine/DividerLine'
import KeyValueTable from '../../components/APIMonitoringPage/KeyValueTable'
import { SalesforceAuditStore } from '../../store/SalesforceAuditStore'
import Table from '../../components/common/Table/Table'
import { TypographyDDS } from '../../components/common/Typography/TypographyDDS'
import UsageCard from '../../components/SalesforceAudit/common/UsageCard'
import arrowLeftIcon from '../../assets/icons/leftArrow.svg'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import { paginate } from '../../utils/CommonUtils'
import palette from '../../global/pallete'
import styled from '@emotion/styled'
import { timeFormats } from '../../enum/TIME'
import { useStoreContext } from '../../store/StoreContext'

const TitleWrapper = styled.div`
  color: ${palette.colors.lightGrey2};
`
const ProfileDetails: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const params = useParams()
  const navigate = useNavigate()
  const { salesforceAuditStore } = useStoreContext()
  const loading = salesforceAuditStore.getLoading()
  const data = salesforceAuditStore.getProfileById(params.id!)
  const permissionsData = salesforceAuditStore.getProfilePermissionsData(
    params.id!
  )
  const tableData = {
    'Role Name': data.Name,
    'User License': salesforceAuditStore.getLicenseById(data.UserLicenseId)
      .Name,
    Description: data.Description,
    'Created By':
      salesforceAuditStore.getNameById(data.CreatedById) || data.CreatedById,
    'Created On': moment(data.CreatedDate).format(
      timeFormats.fullMonthDateYearTime
    ),
    'Last Modified by':
      salesforceAuditStore.getNameById(data.LastModifiedById) ||
      data.LastModifiedById,
    'Modified on': moment(data.LastModifiedDate).format(
      timeFormats.fullMonthDateYearTime
    )
  }

  const handleGoBack = () => {
    const segments = location.pathname.split('/').filter(Boolean)
    segments.pop()
    const newPath = '/' + segments.join('/')
    navigate(newPath)
  }
  const columns = [
    {
      title: <TitleWrapper>{`Permission Name`}</TitleWrapper>,
      label: 'name',
      render: (item: any) => item
    },
    {
      title: <TitleWrapper>Access</TitleWrapper>,
      label: 'access',
      render: (item: any) => (
        <span
          style={{
            color:
              item === 'Yes' ? palette.colors.primary : palette.colors.warning
          }}
        >
          {item}
        </span>
      )
    }
  ]
  return (
    <Grid container width='80%' paddingTop='16px' spacing={2}>
      <Grid item xs={12}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          style={{ cursor: 'pointer' }}
          onClick={() => handleGoBack()}
        >
          <img src={arrowLeftIcon} style={{ height: '16px' }} />
          <TypographyDDS.Paragraph size='para' color='textDark'>
            Go Back
          </TypographyDDS.Paragraph>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <TypographyDDS.Title type='h3' variant='bold'>
          {data.Name}
        </TypographyDDS.Title>
      </Grid>
      <Grid item xs={12}>
        <UsageCard noGutter>
          <TypographyDDS.Title
            type='h5'
            style={{
              margin: '24px'
            }}
          >
            Basic Details
          </TypographyDDS.Title>
          <DividerLine />
          <KeyValueTable data={tableData} noHeader />
        </UsageCard>
      </Grid>
      <Grid item xs={12}>
        <UsageCard>
          <Stack>
            <TypographyDDS.Paragraph size='para' color='textDark'>
              Permissions
            </TypographyDDS.Paragraph>
            <TypographyDDS.Title type='h1' variant='bold'>
              {permissionsData.length}
            </TypographyDDS.Title>
          </Stack>
          <Table
            columns={columns}
            data={paginate(
              permissionsData,
              currentPage,
              SalesforceAuditStore.pageSize
            )}
            totalCount={permissionsData.length}
            currentPage={currentPage}
            rowsPerPage={SalesforceAuditStore.pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </UsageCard>
      </Grid>
    </Grid>
  )
}
export default observer(ProfileDetails)
