import React, { useState } from 'react'

import { ChevronDown } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import pallete from '../../../global/newPallete'

interface Option {
  value: string
  label: string
}

interface SelectDropdownProps {
  options: Option[]
  value: string
  onChange: (value: string) => void
  placeholder?: string
  className?: string
  buttonWidth?: string
  dropdownWidth?: string
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  options,
  value,
  onChange,
  placeholder = 'Select option',
  className = '',
  dropdownWidth = '162px'
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => setIsOpen(!isOpen)

  const handleSelect = (optionValue: string) => {
    onChange(optionValue)
    setIsOpen(false)
  }

  const selectedOption = options.find((option) => option.value === value)

  return (
    <div className='relative inline-block whitespace-nowrap '>
      {/* Dropdown Button */}
      <button
        type='button'
        onClick={handleToggle}
        className={`flex space-x-[8px] items-center justify-between w-full px-[12px] py-[8px] text-left rounded-md ${className}`}
      >
        <span
          style={{
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            color: pallete.colors.textDark4
          }}
        >
          {selectedOption ? selectedOption.label : placeholder}
        </span>
        <ChevronDown
          className={`w-[16px] h-[16px] text-gray-400 transition-transform duration-200 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        />
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div
          className='absolute z-10  right-0 bg-white border border-[#E1E6EE] rounded-md shadow-lg'
          style={{ width: dropdownWidth }} // Apply custom width to dropdown menu
        >
          <ul className='py-[8px]'>
            {options.map((option) => (
              <li key={option.value}>
                <button
                  type='button'
                  className='flex items-center w-full px-[12px] py-[8px] text-left '
                  onClick={() => handleSelect(option.value)}
                >
                  <div className='flex items-center'>
                    <div className='w-[16px] h-[16px] border border-gray-300 rounded-full flex items-center justify-center mr-[8px]'>
                      {value === option.value && (
                        <div className='w-[8px] h-[8px] bg-blue-500 rounded-full' />
                      )}
                    </div>
                    <span
                      style={{
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        color: pallete.colors.textDark5
                      }}
                    >
                      {option.label}
                    </span>
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default SelectDropdown
