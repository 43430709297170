import { createTheme } from '@mui/material/styles'
import palette from './pallete'

export default createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: palette.colors.primary
    },
    secondary: {
      main: palette.colors.secondary
    },
    warning: {
      main: palette.colors.warning
    },
    error: {
      main: palette.colors.error
    },
    success: {
      main: palette.colors.success
    }
  },
  typography: {
    fontFamily: ['Mulish', 'sans-serif'].join(','),
    htmlFontSize: 18
  }
})
