import {
  AiOutlineExclamationCircle as ErrorIcon,
  AiOutlineEye as EyeIcon,
  AiOutlineEyeInvisible as InvisibleEyeIcon
} from 'react-icons/ai'
import { IconButton, Tooltip } from '@mui/material'
import React, {
  CSSProperties,
  createRef,
  useImperativeHandle,
  useState
} from 'react'

import InputAdornment from '@mui/material/InputAdornment'
import LinearProgressBar from '../ProgressBar/ProgressBar'
import MuiTextField from '@mui/material/TextField'
import { BiCheckCircle as SuccessIcon } from 'react-icons/bi'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

export interface RefObject {
  startLoading: () => void
  stopLoading: () => void
  handleTypeChange?: (type: string) => void
}

interface InputFieldProps extends TextFieldProps {
  label?: string
  width?: string
  fullWidth?: boolean
  height?: string
  margin?: string
  size?: 'small' | 'medium'
  type?: string
  value?: string
  disabled?: boolean
  error?: boolean
  success?: boolean
  helperText?: string
  placeholder?: string
  border?: string
  maxLength?: number
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: any
  required?: boolean
  name?: string
  minWidth?: string
  id?: string
  style?: CSSProperties
}

interface TextFieldProps {
  paddingtop?: string
  paddingbottom?: string
  border?: string
  height?: string
}

const TextField = styled(MuiTextField)<TextFieldProps>((props) => ({
  '.MuiFilledInput-root': {
    borderRadius: '8px',
    border: props.border,
    background: `${palette.colors.lightBack} !important`,
    height: `${props.height ? props.height : '61.5px'}`,
    paddingBottom: '8px',
    paddingTop: '8px',
    '&.Mui-focused': {
      border: `2px solid ${palette.colors.primary3} `,

      backgroundColor: `${palette.colors.backgroundLight} !important`
    },

    '&.Mui-disabled': {
      background: ` ${palette.colors.disabledInputBackground} !important`,
      '&:hover': {
        backgroundColor: ` ${palette.colors.disabledInputBackground} !important`
      }
    },
    '&:hover': {
      backgroundColor: palette.colors.backgroundLight
    }
  },
  '.MuiFilledInput-input': {
    paddingTop: '1.6em',
    paddingBottom: '0.625em',

    '&.Mui-disabled': {
      WebkitTextFillColor: 'black',
      cursor: 'not-allowed'
    },
    '&:-webkit-autofill': {
      WebkitBoxShadow: `0 0 0 1000px ${palette.colors.lightBack} inset`,
      '&:focus': {
        WebkitBoxShadow: `0 0 0 1000px ${palette.colors.backgroundLight} inset`
      }
    }
  },
  '.MuiOutlinedInput-input': {},
  '.MuiInputLabel-root': {
    paddingTop: '8px',
    fontWeight: palette.fontWeight.medium,
    lineHeight: palette.font.variant.caption.lineHeight,
    paddingLeft: '12px',
    color: palette.colors.textDark2,
    fontSize: palette.font.variant.caption.fontSize,

    '&.Mui-disabled': {
      color: 'grey'
    },
    '-webkit-transform': 'none !important'
  }
}))

const PasswordHover = styled.div`
  &:hover {
    cursor: pointer;
  }
`
const InputField = React.forwardRef<RefObject, InputFieldProps>(
  (
    {
      label,
      fullWidth = false,
      size = 'small',
      width,
      height,
      error = false,
      type = 'text',
      value,
      helperText = '',
      success = false,
      placeholder,
      onBlur,
      onChange,
      margin,
      style,
      required = false,
      border = `1px solid ${palette.colors.borderColor}`,
      paddingtop = '2em',
      paddingbottom = '0px',
      name,
      maxLength,
      disabled,
      minWidth,
      onFocus,
      id = ''
    },
    ref
  ) => {
    const [inputType, setInputType] = useState(type)
    const [loading, setLoading] = useState<boolean>(false)

    const styles = {
      input: {}
    }

    const inputRef = createRef<any>()

    useImperativeHandle(ref, () => ({
      startLoading,
      stopLoading,
      handleTypeChange
    }))

    const startLoading = () => {
      setLoading(true)
    }

    const stopLoading = () => {
      setLoading(false)
    }

    const handleTypeChange = (type: string) => {
      setInputType(type)
    }

    const renderPassword = () => {
      return (
        <Tooltip
          title={inputType === 'text' ? 'Hide Password' : 'Show Password'}
          placement='top'
        >
          <PasswordHover>
            <IconButton
              style={{ padding: 0 }}
              onClick={() => {
                handleTypeChange(inputType === 'text' ? 'password' : 'text')
              }}
            >
              {inputType === 'password' ? (
                <EyeIcon
                  style={{
                    color: palette.colors.primary,
                    fontSize: '16px'
                  }}
                />
              ) : (
                <InvisibleEyeIcon
                  style={{
                    color: palette.colors.primary,
                    fontSize: '16px'
                  }}
                />
              )}
            </IconButton>
          </PasswordHover>
        </Tooltip>
      )
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          alignItems: 'center',
          width: '100%'
        }}
        id={id}
      >
        <TextField
          required={required}
          onChange={onChange}
          inputProps={{
            maxLength: maxLength
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          variant='filled'
          label={label}
          margin='none'
          size={size}
          disabled={disabled || loading}
          helperText={helperText}
          value={value}
          placeholder={placeholder}
          fullWidth={fullWidth}
          height={height}
          type={inputType}
          error={error}
          paddingtop={paddingtop}
          paddingbottom={paddingbottom}
          border={border}
          sx={{
            width,
            margin,
            height,
            minWidth
          }}
          InputProps={{
            style: {
              fontSize: '14px'
            },
            disableUnderline: true,
            endAdornment: (error === true && (
              <>
                {name === 'password' && renderPassword()}
                <InputAdornment position='end'>
                  <ErrorIcon
                    style={{ color: palette.colors.error, fontSize: '18px' }}
                  />
                </InputAdornment>
              </>
            )) ||
              (success === true && (
                <>
                  {name === 'password' && renderPassword()}
                  <InputAdornment position='end'>
                    <SuccessIcon
                      style={{
                        color: palette.colors.primary,
                        fontSize: '18px'
                      }}
                    />
                  </InputAdornment>
                </>
              )) || <>{name === 'password' && renderPassword()}</>
          }}
          InputLabelProps={{
            shrink: true
          }}
          name={name}
          ref={inputRef}
          style={style}
        />
        {loading && <LinearProgressBar />}
      </div>
    )
  }
)
InputField.displayName = 'InputField'

export default InputField
