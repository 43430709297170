import ApiManager from '../Api.manager'
import HttpConstants from '../../constants/HttpConstants'
import StringConstants from '../../constants/StringConstants'
import UrlConstants from '../../constants/UrlConstants'

/**
 * Api to get all the project members with filters
 * @param payload - holds the filters or queryParams to be added to the get api  call
 */
export const getAllProjectMembers = (payload: any) => {
  const usecase = UrlConstants.FIND_PROJECT_MEMBERS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      { size: StringConstants.DEFAULT_TABLE_SIZE, ...payload }
    )
      .then((response: any) => {
        if (response.status === 200) resolve(response.data)
        else throw response
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

/**
 * Api to get all the project filters
 */
export const getProjectMemberFilters = (projectId: string) => {
  const usecase = UrlConstants.GET_PROJECT_MEMBER_FILTERS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      { projectId }
    )
      .then((response: any) => {
        if (response.status === 200) resolve(response.data)
        else throw response
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

/**
 * Api to remove the project member
 * @param {string} userId - id of the
 */
export const removeProjectMember = (userId: string, projectId: string) => {
  const usecase = UrlConstants.REMOVE_PROJECT_MEMBER.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.DELETE_METHOD,
      { '_user-id': userId, projectId }
    )
      .then((response: any) => {
        if (response.status === 200) resolve(response.data)
        else throw response
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

/**
 * Api to update the project member
 */
export const updateProjectMember = (
  payload: string,
  _userId: string,
  projectId: string
) => {
  const usecase = UrlConstants.UPDATE_PROJECT_MEMBER.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      { role: payload, '_user-id': _userId, projectId }
    )
      .then((response: any) => {
        if (response.status === 200) resolve(response.data)
        else throw response
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

/**
 * Api to add a project member
 */
export const addProjectMember = (payload: any) => {
  const usecase = UrlConstants.ADD_PROJECT_MEMBER.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response: any) => {
        if (response.status === 201) resolve(response.data)
        else throw response
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

/**
 *
 * @param payload name or value
 * @returns Project members
 */
export const projectMemberLookUp = (payload: any) => {
  const usecase = UrlConstants.LOOK_UP_PROJECT_MEMBERS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}
