import SigninSignupStringConstants from '../SigninSignupStringConstants'

const left_panel_default = [
  SigninSignupStringConstants.FULL_STACK_APPS,
  SigninSignupStringConstants.SALESFORCE_ORGS,
  SigninSignupStringConstants.MULESOFT_APPS
]

const product_fullstack = [
  SigninSignupStringConstants.PRODUCT_FS_SCHEDULE_JOB,
  SigninSignupStringConstants.PRODUCT_FS_REAL_TIME_API,
  SigninSignupStringConstants.PRODUCT_FS_UPTIME
]

const product_salesforce = [
  SigninSignupStringConstants.PRODUCT_SF_EXCEPTION,
  SigninSignupStringConstants.PRODUCT_SF_ALERTS,
  SigninSignupStringConstants.PRODUCT_SF_AUDITS
]

const product_mulesoft = [
  SigninSignupStringConstants.PRODUCT_MS_SCHEDULE_JOB,
  SigninSignupStringConstants.PRODUCT_MS_REAL_TIME_API,
  SigninSignupStringConstants.PRODUCT_MS_UPTIME
]

const solutions_solution_architect = [
  SigninSignupStringConstants.SOLUTION_SA_EXCEPTIONS,
  SigninSignupStringConstants.SOLUTION_SA_UPTIME,
  SigninSignupStringConstants.SOLUTION_SA_ERROR_PERFORMANCE
]

const solutions_salesforce_partners = [
  SigninSignupStringConstants.SOLUTION_SPC_ERROR_INSIGHTS,
  SigninSignupStringConstants.SOLUTION_SPC_WORKFLOW,
  SigninSignupStringConstants.SOLUTION_SPC_UPTIME
]

const solutions_executive_leaders = [
  SigninSignupStringConstants.SOLUTION_EL_METRICS,
  SigninSignupStringConstants.SOLUTION_EL_UPTINE,
  SigninSignupStringConstants.SOLUTION_EL_MONITORING
]

const default_icon_card_data = [
  {
    text: SigninSignupStringConstants.EXCEPTIONS,
    icon: 'Exceptions'
  },
  {
    text: SigninSignupStringConstants.AUDIT,
    icon: 'Audit'
  },
  {
    text: SigninSignupStringConstants.JOBS,
    icon: 'Jobs'
  },
  {
    text: SigninSignupStringConstants.UPTIME,
    icon: 'Uptime'
  }
]

const product_fullstack_icon = [
  {
    text: SigninSignupStringConstants.UPTIME,
    icon: 'Uptime'
  },
  {
    text: SigninSignupStringConstants.ESCALATIONS,
    icon: 'Escalations'
  },
  {
    text: SigninSignupStringConstants.ERRORS,
    icon: 'Errors'
  },
  {
    text: SigninSignupStringConstants.API_MONITORING,
    icon: 'ApiMonitoring'
  }
]

const product_salesforce_icon = [
  {
    text: SigninSignupStringConstants.UPTIME,
    icon: 'Uptime'
  },
  {
    text: SigninSignupStringConstants.ESCALATIONS,
    icon: 'Escalations'
  },
  {
    text: SigninSignupStringConstants.AUDIT,
    icon: 'Audit'
  },
  {
    text: SigninSignupStringConstants.INCIDENTS,
    icon: 'Incident'
  }
]

const product_mulesoft_icon = [
  {
    text: SigninSignupStringConstants.UPTIME,
    icon: 'Uptime'
  },
  {
    text: SigninSignupStringConstants.ESCALATIONS,
    icon: 'Escalations'
  },
  {
    text: SigninSignupStringConstants.ERRORS,
    icon: 'Errors'
  },
  {
    text: SigninSignupStringConstants.INCIDENTS,
    icon: 'Incidents'
  }
]

const solutions_solution_architect_icon = [
  {
    text: SigninSignupStringConstants.UPTIME,
    icon: 'Uptime'
  },
  {
    text: SigninSignupStringConstants.ESCALATIONS,
    icon: 'Escalations'
  },
  {
    text: SigninSignupStringConstants.ERRORS,
    icon: 'Errors'
  },
  {
    text: SigninSignupStringConstants.API_MONITORING,
    icon: 'ApiMonitoring'
  }
]

const solutions_salesforce_partners_icon = [
  {
    text: SigninSignupStringConstants.UPTIME,
    icon: 'Uptime'
  },
  {
    text: SigninSignupStringConstants.ESCALATIONS,
    icon: 'Escalations'
  },
  {
    text: SigninSignupStringConstants.AUDIT,
    icon: 'Audit'
  },
  {
    text: SigninSignupStringConstants.INCIDENTS,
    icon: 'Incidents'
  }
]

const solutions_executive_leaders_icon = [
  {
    text: SigninSignupStringConstants.UPTIME,
    icon: 'Uptime'
  },
  {
    text: SigninSignupStringConstants.ESCALATIONS,
    icon: 'Escalations'
  },
  {
    text: SigninSignupStringConstants.ERRORS,
    icon: 'Errors'
  },
  {
    text: SigninSignupStringConstants.INCIDENTS,
    icon: 'Incidents'
  }
]

export const SignInSignUpLeftPanelConstants = {
  left_panel: {
    left_panel_default,
    product_fullstack,
    product_salesforce,
    product_mulesoft,
    solutions_solution_architect,
    solutions_salesforce_partners,
    solutions_executive_leaders
  },
  icon_cards: {
    default_icon_card_data,
    product_fullstack_icon,
    product_salesforce_icon,
    product_mulesoft_icon,
    solutions_solution_architect_icon,
    solutions_salesforce_partners_icon,
    solutions_executive_leaders_icon
  }
}
