import { ChevronRight } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import React from 'react'
import pallete from '../../global/newPallete'
interface linkCardProps {
  linkText: string
  content: React.ReactNode
  secondaryContent?: React.ReactNode
  onClick?: any
}
const LinkCard: React.FC<linkCardProps> = ({
  linkText,
  content,
  secondaryContent,
  onClick
}) => {
  return (
    <div className='flex flex-col items-start gap-[8px] w-full'>
      <div
        onClick={onClick}
        className='cursor-pointer flex items-center gap-[4px]'
      >
        <DDSTypography.Paragraph
          size='para'
          variant='regular'
          className='whitespace-nowrap'
          color={pallete.colors.textDark2}
        >
          {linkText}
        </DDSTypography.Paragraph>
        <ChevronRight size={16} color={pallete.colors.textDark2} />
      </div>
      <div>{content}</div>
      {secondaryContent && <div>{secondaryContent}</div>}
    </div>
  )
}

export default LinkCard
