import { Close } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import React from 'react'
import Tooltip from '../New/Tooltip/Tooltip'
import pallete from '../../global/newPallete'
import { useStoreContext } from '../../store/StoreContext'

interface props {
  link?: string
  onclick?: any
  onClose?: any
}
const LinkCard: React.FC<props> = ({ link, onclick, onClose }) => {
  const store = useStoreContext()
  return (
    <div className='flex flex-row justify-between border rounded-md p-[12px]'>
      <DDSTypography.Paragraph
        size='para'
        variant='medium'
        color={pallete.colors.textDark2}
        className={`flex text-ellipsis truncate ${store.uiStore.getSidebarExpanded() ? 'max-w-[1080px] 2xl:max-w-[1570px]' : 'max-w-[1260px] 2xl:max-w-[1750px]'}`}
      >
        <a
          onClick={(e: any) => {
            e.preventDefault()
            window.open(link, '_blank', 'noopener,noreferrer')
          }}
          style={{
            textDecorationLine: 'underline',
            textDecorationColor: pallete.colors.textDark2,
            color: pallete.colors.textDark2,
            cursor: 'pointer'
          }}
        >
          {link}
        </a>
      </DDSTypography.Paragraph>
    </div>
  )
}

export default LinkCard
