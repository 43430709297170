import { DDSButton, DDSTabs, DDSTypography } from 'den-design-system'
import React, { CSSProperties } from 'react'

import pallete from '../../../../global/newPallete'
import { useStoreContext } from '../../../../store/StoreContext'

interface TabsProps {
  tabOptions: any[]
  id?: string
  className?: string
  style?: CSSProperties
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  showActionButton?: boolean
  actionButtonLabel?: string
  onActionButtonPress?: () => void
  tabOptionsStyle?: CSSProperties
  childrenStyle?: CSSProperties
  onChange?: (activeTab: string) => void
  onBlur?: (e: React.FocusEvent<HTMLButtonElement>) => void
  disabled?: boolean
  activeTab?: string
}
const Tabs: React.FC<TabsProps> = ({
  tabOptions,
  id,
  className,
  style,
  showActionButton,
  actionButtonLabel,
  onActionButtonPress,
  onBlur,
  onChange,
  childrenStyle,
  tabOptionsStyle,
  disabled,
  activeTab
}) => {
  const store = useStoreContext()
  return (
    <div
      className={`relative override-tabs ${disabled && 'cursor-not-allowed'}`}
    >
      <div className={`${disabled && 'pointer-events-none'}`}>
        <DDSTabs
          tabOptions={tabOptions}
          id={id}
          className={className}
          style={style}
          onChange={onChange}
          onBlur={onBlur}
          tabOptionsStyle={tabOptionsStyle}
          childrenStyle={childrenStyle}
          activeTab={activeTab}
        />
      </div>
      {showActionButton && (
        <div className='absolute top-0 right-0'>
          <DDSButton
            label={
              <DDSTypography.Paragraph
                size='para'
                variant='semiBold'
                color={pallete.colors.textDark1}
              >
                {actionButtonLabel}
              </DDSTypography.Paragraph>
            }
            shape='roundedRectangle'
            onClick={onActionButtonPress}
            disabled={store.userStore.getNoActiveSubscription()}
          />
        </div>
      )}
      <hr className='flex w-full absolute h-[1px] top-[46.5px]' />
    </div>
  )
}
export default Tabs
