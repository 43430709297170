import { makeAutoObservable, observable } from 'mobx'

import { RootStore } from './RootStore'

export class ProjectMemberStore {
  projectMembersDetails: any[]

  constructor(private rootStore: RootStore) {
    this.projectMembersDetails = []
    makeAutoObservable(this, {
      projectMembersDetails: observable.struct,
    })
  }

  setProjectMembersDetails(projectMembersDetails: any[]) {
    this.projectMembersDetails = projectMembersDetails
  }

  getProjectMembersDetails() {
    return this.projectMembersDetails
  }
  reset() {
    this.projectMembersDetails = []
  }
}
