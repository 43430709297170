import { Attachment, Link, Send } from '@carbon/icons-react'
import { DDSCheckbox, DDSTypography } from 'den-design-system'
import React, { CSSProperties, Ref, forwardRef, useEffect, useRef } from 'react'

import { Button } from '../../../New/Button/Button'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import palette from '../../../../global/newPallete'
import pallete from '../../../../global/newPallete'
import { useStoreContext } from '../../../../store/StoreContext'

interface TextAreaProps {
  id?: string
  placeholder?: string
  onChange?: (value: string) => any
  value?: string
  onSend?: () => any
  style?: CSSProperties
  type: 'primary' | 'secondary' | 'incident'
  showButtons?: boolean
  disabled?: boolean
  onCancel?: any
  className?: string
  PrimaryButtonText?: string
  primaryButtonOnClick?: any
  onFocus?: () => any
  textareaRef?: Ref<HTMLTextAreaElement>
  loading?: boolean
  isPublic?: boolean
  setIsPublic?: any
  disableNoSubscription?: boolean
}

const IncidentTextArea: React.FC<TextAreaProps> = ({
  placeholder,
  value,
  onChange,
  onSend,
  type,
  disabled,
  onCancel,
  showButtons = true,
  style,
  PrimaryButtonText,
  primaryButtonOnClick,
  onFocus,
  textareaRef,
  loading,
  isPublic,
  setIsPublic,
  disableNoSubscription
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const store = useStoreContext()

  const handleEnterKey = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && e.shiftKey) {
      const textArea = e.currentTarget
      const value = textArea.value
      const selectionStart = textArea.selectionStart
      const selectionEnd = textArea.selectionEnd
      const newValue =
        value.substring(0, selectionStart) +
        '\n' +
        value.substring(selectionEnd)
      textArea.value = newValue
      // Update cursor position
      textArea.selectionStart = textArea.selectionEnd = selectionStart + 1
      // Prevent default behavior of Enter key
      e.preventDefault()
    } else if (e.key === 'Enter') {
      e.preventDefault()

      if (type === 'primary' || type === 'incident') {
        onSend && onSend()
      } else {
        primaryButtonOnClick()
      }
    }
  }

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e.target.value)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        onCancel && onCancel()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onCancel])

  return (
    <div
      ref={containerRef}
      className={`${disableNoSubscription && 'cursor-not-allowed pointer-events-none'}  flex flex-col w-full h-[120px] rounded-md p-[12px] bg-white border`}
      style={{ borderColor: palette.colors.stroke2 }}
    >
      <textarea
        ref={textareaRef}
        disabled={disabled}
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        placeholder={placeholder}
        style={{ borderColor: palette.colors.stroke2 }}
        onKeyDown={(e) => {
          handleEnterKey(e)
        }}
        className={`resize-none bg-white outline-none min-h-[60px]`}
        onInput={(e: any) => handleInput(e)}
      ></textarea>
      {showButtons && type === 'incident' && (
        <div className='flex flex-row items-center justify-between w-full max-w-[100%]'>
          <div className='flex-grow'>
            <DDSCheckbox
              label={
                <DDSTypography.Paragraph
                  size='caption'
                  variant='medium'
                  color={palette.colors.neuButtonText}
                >
                  Make it visible for the user
                </DDSTypography.Paragraph>
              }
              checked={isPublic}
              disabled={store.userStore.getNoActiveSubscription()}
              onChange={() => setIsPublic(!isPublic)}
            />
          </div>
          <div className='flex flex-row gap-2'>
            <Button
              label='Send'
              type='filled'
              size='medium'
              loading={loading}
              startIcon={<Send color={palette.colors.white} />}
              id='send-button'
              disabled={
                isEmpty(value?.trim()) ||
                loading ||
                store.userStore.getNoActiveSubscription()
              }
              onClick={() => {
                if (!isEmpty(value?.trim()) && onSend && !loading) {
                  onSend()
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}
export default observer(IncidentTextArea)
