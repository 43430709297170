import React from 'react'
import UCAddUpdateCheckForm from '../../../components/UptimeChecksPage/UCAddCheckForm'

const UCAddCheck = () => {
  return (
    <div className='flex flex-col h-[100vh] self-stretch items-start'>
      <UCAddUpdateCheckForm isAdd />
    </div>
  )
}

export default UCAddCheck
