import { DDSTypography } from 'den-design-system'
import HorizontalStackedProgressBar from '../common/DDS/StackedProgressBar/HorizontalStackedProgressBar'
import OverviewStringConstants from '../../constants/OverviewStringConstants'
import React from 'react'
import StatusCodeGraph from '../common/StatusCodeGraph/StatusCodeGraph'
import pallete from '../../global/newPallete'

interface graphProps {
  title: string
  totalCount: number
  color: any
  data: any
}
const BatteryGraph: React.FC<graphProps> = ({
  title,
  totalCount,
  color,
  data
}) => {
  return (
    <div className='flex flex-col gap-[12px] w-full'>
      <DDSTypography.Title
        type='h5'
        variant='semiBold'
        color={pallete.colors.textDark3}
      >
        {title}
      </DDSTypography.Title>
      {title !== OverviewStringConstants.API_RESPONSE_STATUS_CODE ? (
        <HorizontalStackedProgressBar
          totalCount={totalCount}
          color={color}
          data={data}
        />
      ) : (
        <div className='relative'>
          <StatusCodeGraph
            width='100%'
            tableHeight={'140px'}
            totalCount={totalCount}
            color={color}
            data={data}
          />
        </div>
      )}
    </div>
  )
}

export default BatteryGraph
