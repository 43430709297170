import { Attachment, Link, Send } from '@carbon/icons-react'
import { DDSCheckbox, DDSTypography } from 'den-design-system'
import React, { CSSProperties, Ref, forwardRef, useEffect, useRef } from 'react'

import { Button } from '../../../New/Button/Button'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import palette from '../../../../global/newPallete'
import pallete from '../../../../global/newPallete'
import { useStoreContext } from '../../../../store/StoreContext'

interface TextAreaProps {
  id?: string
  placeholder?: string
  onChange?: (value: string) => any
  value?: string
  onSend?: () => any
  style?: CSSProperties
  type: 'primary' | 'secondary' | 'incident'
  showButtons?: boolean
  disabled?: boolean
  onCancel?: any
  className?: string
  PrimaryButtonText?: string
  maxLength?: number
  primaryButtonOnClick?: any
  onFocus?: () => any
  textareaRef?: Ref<HTMLTextAreaElement>
  loading?: boolean
  isPublic?: boolean
  setIsPublic?: any
  disableNoSubscription?: boolean
}

const TextArea: React.FC<TextAreaProps> = ({
  placeholder,
  value,
  onChange,
  onSend,
  type,
  disabled,
  onCancel,
  showButtons = true,
  style,
  PrimaryButtonText,
  primaryButtonOnClick,
  maxLength,
  onFocus,
  textareaRef,
  loading,
  isPublic,
  setIsPublic,
  disableNoSubscription
}) => {
  const store = useStoreContext()
  const containerRef = useRef<HTMLDivElement>(null)

  const handleEnterKey = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && e.shiftKey) {
      const textArea = e.currentTarget
      const value = textArea.value
      const selectionStart = textArea.selectionStart
      const selectionEnd = textArea.selectionEnd
      const newValue =
        value.substring(0, selectionStart) +
        '\n' +
        value.substring(selectionEnd)
      textArea.value = newValue
      // Update cursor position
      textArea.selectionStart = textArea.selectionEnd = selectionStart + 1
      // Prevent default behavior of Enter key
      e.preventDefault()
    } else if (e.key === 'Enter') {
      e.preventDefault()

      if (type === 'primary' || type === 'incident') {
        onSend && onSend()
      } else {
        primaryButtonOnClick()
      }
    }
  }

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e.target.value)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        onCancel && onCancel()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onCancel])

  return (
    <div
      ref={containerRef}
      className={`relative w-full ${disableNoSubscription && 'cursor-not-allowed pointer-events-none'}  `}
    >
      <textarea
        ref={textareaRef}
        disabled={disabled}
        maxLength={maxLength}
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        placeholder={placeholder}
        style={{ borderColor: palette.colors.stroke2, ...style }}
        onKeyDown={(e) => {
          handleEnterKey(e)
        }}
        className={`resize-none bg-white border outline-none p-[12px] w-full min-h-[90px] rounded-md`}
        onInput={(e: any) => handleInput(e)}
      ></textarea>
      {showButtons && type === 'primary' && (
        <div className=' absolute right-[2%] bottom-[12px] flex flex-row gap-x-[8px] items-center '>
          <Button
            label='Post'
            type='filled'
            size='medium'
            startIcon={<Send color={palette.colors.white} />}
            id='send-button'
            disabled={
              isEmpty(value?.trim()) ||
              loading ||
              store.userStore.getNoActiveSubscription()
            }
            onClick={() => {
              if (!isEmpty(value?.trim()) && onSend && !loading) {
                onSend()
              }
            }}
          />
        </div>
      )}

      {showButtons && type === 'secondary' && (
        <div className=' absolute right-[12px] bottom-[12px] flex flex-row gap-x-[12px] items-center '>
          <Button
            label='Cancel'
            type='neutral'
            size='small'
            id='cancel-button'
            onClick={() => {
              if (onCancel && !loading) {
                onCancel()
              }
            }}
            disabled={loading}
          />
          <Button
            label={PrimaryButtonText}
            type='filled'
            size='small'
            id={PrimaryButtonText as string}
            onClick={() => {
              if (primaryButtonOnClick && !loading) {
                primaryButtonOnClick()
              }
            }}
            disabled={
              isEmpty(value?.trim()) ||
              loading ||
              store.userStore.getNoActiveSubscription()
            }
          />
        </div>
      )}
      {showButtons && type === 'incident' && (
        <div className='absolute right-[2%] bottom-[12px] flex flex-row items-center justify-between w-full max-w-[97%]'>
          <div className='flex-grow'>
            <DDSCheckbox
              label={
                <DDSTypography.Paragraph
                  size='caption'
                  variant='medium'
                  color={palette.colors.neuButtonText}
                >
                  Make it visible for the user
                </DDSTypography.Paragraph>
              }
              checked={isPublic}
              onChange={() => setIsPublic(!isPublic)}
              disabled={store.userStore.getNoActiveSubscription()}
            />
          </div>
          <div className='flex flex-row gap-2'>
            {/* <Button
              type='outline'
              size='medium'
              startIcon={<Link color={palette.colors.neuButtonText} />}
              id='link-button'
              disabled={isEmpty(value?.trim()) || loading}
              // onClick={() => {
              //   // Your link button logic here
              // }}
            /> */}
            <Button
              label='Send'
              type='filled'
              size='medium'
              startIcon={<Send color={palette.colors.white} />}
              id='send-button'
              disabled={
                isEmpty(value?.trim()) ||
                loading ||
                store.userStore.getNoActiveSubscription()
              }
              onClick={() => {
                if (!isEmpty(value?.trim()) && onSend && !loading) {
                  onSend()
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}
export default observer(TextArea)
