import {
  ChevronDown,
  ChevronUp,
  Edit,
  TrashCan,
  UserMultiple
} from '@carbon/icons-react'
import { DDSToastNotification, DDSTypography } from 'den-design-system'
import React, { MutableRefObject, useEffect, useRef } from 'react'
import {
  addComment,
  deleteComment,
  getAllCommentsOfIncident,
  updateComment
} from '../../api/incident/incident.service'

import ActivityTextArea from '../common/DDS/TextArea/ActivityTextArea'
import { DeletePopup } from '../common/Exceptions/DeletePopup'
import DocumentHeader from '../Helmet/DocumentHeader'
import { HeaderConstants } from '../../constants/HeaderConstants'
import Spinner from '../common/Spinner/Spinner'
import TextArea from '../common/DDS/TextArea/TextArea'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../common/DDS/Toast/Toast'
import { getTimeForActivity } from '../../utils/DateTimeUtils'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import pallete from '../../global/newPallete'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { useStoreContext } from '../../store/StoreContext'

const IncidentActivity: React.FC<{ fetchAnIncident: () => void }> = ({
  fetchAnIncident
}) => {
  const { incidentId } = useParams()
  const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false)
  const [activityMessages, setActivityMessages] = React.useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const store = useStoreContext()
  const [isPublic, setIsPublic] = useState<boolean>(false)
  const replySectionRef = useRef<{
    [key: string]: MutableRefObject<HTMLDivElement | null>
  }>({})
  const messageSectionRef: MutableRefObject<HTMLDivElement | null> =
    useRef<HTMLDivElement>(null)

  const getReplySectionRef = (
    id: string
  ): MutableRefObject<HTMLDivElement | null> => {
    if (!replySectionRef.current[id]) {
      replySectionRef.current[id] = React.createRef()
    }
    return replySectionRef.current[id]
  }

  const updateMessages = (value: string) => {
    setText('')
    addComment({
      $incidentId: incidentId as string,
      comment: value,
      isPublic: isPublic
    })
      .then((response: any) => {
        fetchAnIncident()
      })
      .finally(() => {
        setButtonLoading(false)
      })
  }

  //Edit changes popup
  const [editChangesPopup, setEditChangesPopup] = useState<boolean>(false)
  //Hovering attributes for activity div
  const [hoveringDiv, setHoveringDiv] = useState<string>('')
  const [text, setText] = React.useState<string>('')

  //Editing messages
  const [editableMessageId, setEditableMessageId] = React.useState<string>('')
  const [changedEditId, setChangedEditId] = React.useState<string>('')

  const handleEditMessage = (id: string) => {
    setEditableMessageId(id)
    setCurrentEditableMessage(
      activityMessages.find((message: any, index: number) => message.id === id)
        .message
    )

    setTimeout(() => {
      const textAreaField = document.getElementById(id)
      if (textAreaField) {
        textAreaField.focus()
      }
    }, 0)
  }

  //Replying messages
  const [replyMessageId, setReplyMessageId] = React.useState<string>('')
  const [showReply, setShowReply] = useState<boolean>(false)
  const [showReplyId, setShowReplyId] = React.useState<string>('')
  const [currentReplyMessage, setCurrentReplyMessage] =
    React.useState<string>('')
  const [currentEditableReplyMessage, setCurrentEditableReplyMessage] =
    React.useState<string>('')
  const [editableReplyId, setEditableReplyId] = useState<string>('')
  const [changeReplyId, setChangeReplyId] = useState<string>('')

  const handleReplyMessage = (id: string) => {
    if (!isEmpty(replyMessageId) && !isEmpty(currentReplyMessage)) {
      setEditChangesPopup(true)
      setChangeReplyId(id)
    } else if (
      !isEmpty(editableMessageId) &&
      !isEmpty(currentEditableMessage)
    ) {
      setEditChangesPopup(true)
      setEditChangesPopup(true)
    } else {
      setReplyMessageId(id)
    }
  }

  useEffect(() => {
    if (showReplyId) {
      const ref = getReplySectionRef(showReplyId)
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    }
  }, [showReplyId])

  const handleContinueUnsavedChanges = (id: string) => {
    setDeleteLoading(true)
    setReplyMessageId(id)
    if (!isEmpty(editableMessageId)) {
      setEditableMessageId('')
    }
    setCurrentReplyMessage('')
    setDeleteLoading(false)
    setEditChangesPopup(false)
  }
  const handleShowReply = (id: string) => {
    const ref = getReplySectionRef(id)
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }, 100)
    if (showReplyId === '') {
      setShowReplyId(id)
    } else {
      setShowReplyId('')
    }
  }
  const handleEditReplyMessage = (id: string, value: string) => {
    setEditableReplyId(id)
    setCurrentEditableReplyMessage(value)
    setTimeout(() => {
      const textAreaField = document.getElementById(id)
      if (textAreaField) {
        textAreaField.focus()
      }
    }, 0)
  }

  const handleCancelEditableReplyMessage = () => {
    setEditableReplyId('')
    setCurrentEditableReplyMessage('')
  }

  const saveMessage = (commentId: string, type: 'Parent' | 'Reply') => {
    setLoading(true)
    updateComment({
      message:
        type === 'Parent'
          ? (currentEditableMessage as string)
          : currentEditableReplyMessage,
      $incidentId: incidentId as string,
      $activityId: commentId
    })
      .then((response: any) => {
        setCurrentEditableMessage('')
        setEditableMessageId('')
        setEditableReplyId('')

        DDSToastNotification.success(
          ToastMessageConstants.INCIDENT.UPDATE_A_COMMENT.SUCCESS
        )
        fetchAllActivities()
      })
      .catch((err: any) => {
        DDSToastNotification.error(
          ToastMessageConstants.INCIDENT.UPDATE_A_COMMENT.ERROR
        )
        setLoading(false)
      })
  }

  const sendReply = () => {
    setLoading(true)
    addComment({
      $incidentId: incidentId as string,
      comment: currentReplyMessage,
      threadId: replyMessageId
    })
      .then((response: any) => {
        fetchAllActivities()
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.INCIDENT.ADD_A_COMMENT.SUCCESS
        })

        setCurrentReplyMessage('')
        setReplyMessageId('')
        setLoading(false)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.INCIDENT.ADD_A_COMMENT.ERROR
        })

        setLoading(false)
      })
  }

  const scrollToTop = () => {
    if (messageSectionRef.current) {
      messageSectionRef.current.scrollTop = 0
    }
  }
  const handleReplyMessageScroll = (id: string) => {
    const ref = getReplySectionRef(id)
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }

  useEffect(() => {
    scrollToTop()
  }, [activityMessages])

  const cancelReplyingMessage = () => {
    setReplyMessageId('')
    setCurrentReplyMessage('')
  }
  const cancelEditingMessage = () => {
    setEditableMessageId('')
    setCurrentEditableMessage('')
    setActivityMessages(activityMessages)
  }
  const fetchAllActivities = () => {
    setLoading(true)
    getAllCommentsOfIncident({ $incidentId: incidentId as string }).then(
      (response: any) => {
        setActivityMessages(response.data)
        setLoading(false)
      }
    )
  }

  const [deletePopupOpen, setDeletePopUpOpen] = useState<boolean>(false)
  const [deleteCommentContent, setDeleteCommentContent] = useState<any>({})

  const handleDeleteComment = (message: any) => {
    setDeletePopUpOpen(true)
    setDeleteCommentContent(message)
  }

  useEffect(() => {
    fetchAllActivities()
  }, [])

  const bannerDetails = { ...store.uiStore.getBannerDetails() }
  const isVisibility = store.uiStore.getIsAlertVisible()

  const [currentEditableMessage, setCurrentEditableMessage] =
    React.useState<string>('')
  return (
    <>
      <DocumentHeader header={HeaderConstants.INCIDENT} />
      <DeletePopup
        modalOpen={deletePopupOpen}
        setModalOpen={setDeletePopUpOpen}
        secondaryButtonClick={() => setDeletePopUpOpen(false)}
        primaryButtonClick={() => {
          setDeleteLoading(true)
          deleteComment({
            $incidentId: incidentId as string,
            $activityId: deleteCommentContent.id
          })
            .then(() => {
              fetchAllActivities()
              setDeleteLoading(false)
              setDeletePopUpOpen(false)
              ToastNotification({
                type: 'success',
                message: ToastMessageConstants.INCIDENT.DELETE_COMMENT.SUCCESS
              })
            })
            .catch((err) => {
              setDeleteLoading(false)
              ToastNotification({
                type: 'error',
                message: ToastMessageConstants.INCIDENT.DELETE_COMMENT.ERROR
              })
            })
        }}
        showFooter={true}
        primaryButtonText='Delete'
        secondaryButtonText='Cancel'
        primaryButtonDisabled={deleteLoading}
        deleteLoading={deleteLoading}
        secondaryButtonDisabled={deleteLoading}
        content={
          <div
            className='p-[24px] flex flex-col gap-y-[24px] border-b'
            style={{ borderColor: pallete.colors.stroke2 }}
          >
            <DDSTypography.Title
              type='h4'
              color={pallete.colors.textDark3}
              className='line-clamp-2 w-[600px]'
            >
              Are you sure you want to delete this message? This cannot be
              undone.
            </DDSTypography.Title>

            <div
              className='flex w-full flex-row p-[12px] gap-x-[12px] border rounded'
              style={{ borderColor: pallete.colors.stroke2 }}
            >
              <div className='h-[32px] w-[32px] rounded-full min-w-[32px] flex-shrink-0'>
                <img
                  src={deleteCommentContent.actionedByUser?.avatar}
                  alt='icon'
                  className='h-full w-full object-cover rounded-full'
                />
              </div>
              <div className='flex flex-col flex-1'>
                <div className='flex flex-row gap-x-[12px] items-center custom-text'>
                  <DDSTypography.Title type='h4' variant='semiBold'>
                    {deleteCommentContent.actionedByUser?.firstName +
                      ' ' +
                      deleteCommentContent.actionedByUser?.lastName}
                  </DDSTypography.Title>
                  <DDSTypography.Paragraph
                    size='caption'
                    variant='medium'
                    style={{ color: pallete.colors.textDark7 }}
                    className='whitespace-nowrap'
                  >
                    {getTimeForActivity(deleteCommentContent.createdAt)}
                  </DDSTypography.Paragraph>
                </div>
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  style={{
                    color: pallete.colors.textDark6,
                    overflowWrap: 'anywhere'
                  }}
                >
                  {deleteCommentContent.message}
                </DDSTypography.Paragraph>
              </div>
            </div>
          </div>
        }
      />
      <DeletePopup
        modalOpen={editChangesPopup}
        setModalOpen={setEditChangesPopup}
        primaryButtonText='Continue'
        secondaryButtonText='Keep Editing'
        primaryButtonDisabled={deleteLoading}
        deleteLoading={deleteLoading}
        secondaryButtonDisabled={deleteLoading}
        primaryButtonClick={() => handleContinueUnsavedChanges(changeReplyId)}
        secondaryButtonClick={() => {
          setEditChangesPopup(false)
        }}
        showFooter={true}
        content={
          <div
            className='p-[24px] flex flex-col gap-y-[24px] border-b'
            style={{ borderColor: pallete.colors.stroke2 }}
          >
            <DDSTypography.Title
              type='h4'
              color={pallete.colors.textDark3}
              className='line-clamp-2 w-[600px]'
            >
              You will lose any unsaved changes to your message.
            </DDSTypography.Title>
          </div>
        }
      />
      <div
        className={`flex flex-col justify-between flex-shrink-0 gap-[24px] ${bannerDetails && isVisibility ? 'h-[64.5vh]' : 'h-[68.5vh]'}  py-[24px]  ${bannerDetails && isVisibility ? '2xl:h-[69vh]' : '2xl:h-[73vh]'} `}
      >
        {loading || store.uiStore.getGlobalLoader() ? (
          <div className='h-[60vh] 2xl:h-[70vh] '>
            <Spinner />
          </div>
        ) : (
          <div
            ref={messageSectionRef}
            className='flex flex-col overflow-y-auto h-[60vh] 2xl:h-[70vh] '
          >
            {activityMessages.map((message: any, index: number) => (
              <div
                key={index}
                className=' flex flex-col gap-y-[12px] p-[12px] rounded-md'
                onMouseEnter={() => {
                  setHoveringDiv(message.id)
                }}
                onMouseLeave={() => {
                  setHoveringDiv('')
                }}
                style={{
                  backgroundColor:
                    hoveringDiv === message.id
                      ? pallete.colors.surface5
                      : 'white'
                }}
              >
                <div className={` flex flex-row gap-x-[12px] `}>
                  {message.actionedByUser ? (
                    <img
                      src={message.actionedByUser?.avatar}
                      alt='icon'
                      className='h-[32px] w-[32px] rounded-full'
                    />
                  ) : null}

                  <div className='flex flex-col gap-y-[4px] justify-between w-full'>
                    {editableMessageId !== message.id ? (
                      <div className='flex flex-row justify-between w-full'>
                        <div className='flex flex-col gap-y-[4px] justify-between w-[94%]'>
                          <div className='flex flex-row items-center gap-x-[12px]'>
                            <div>
                              {message.actionedByUser ? (
                                <DDSTypography.Title
                                  type='h5'
                                  variant='semiBold'
                                  color={pallete.colors.textDark4}
                                >
                                  {message.actionedByUser?.firstName +
                                    ' ' +
                                    message.actionedByUser?.lastName}
                                </DDSTypography.Title>
                              ) : null}
                            </div>

                            <div>
                              <DDSTypography.Paragraph
                                size='caption'
                                variant='regular'
                                style={{ color: pallete.colors.textDark7 }}
                                className='whitespace-nowrap'
                              >
                                {getTimeForActivity(message.createdAt)}
                              </DDSTypography.Paragraph>
                            </div>

                            {message.isPublic && (
                              <UserMultiple
                                color={pallete.colors.textDark3}
                                style={{
                                  height: '12px',
                                  width: '12px'
                                }}
                              />
                            )}
                          </div>
                          <DDSTypography.Paragraph
                            size='para'
                            variant='regular'
                            style={{
                              overflowWrap: 'anywhere'
                            }}
                            color={pallete.colors.textDark5}
                          >
                            {message.message}
                          </DDSTypography.Paragraph>
                        </div>
                        {(hoveringDiv === message.id &&
                          message.type === 'COMMENT') ||
                        message.isActive ? (
                          <div className='flex flex-row gap-x-[8px]'>
                            {/* <Reply
                              color={pallete.colors.textDark10}
                              className='cursor-pointer'
                              onClick={() => {
                                handleReplyMessage(message.id)
                                handleReplyMessageScroll(message.id)
                                setShowReply(true)
                              }}
                            /> */}
                            <Edit
                              color={pallete.colors.textDark10}
                              className='cursor-pointer'
                              onClick={() => handleEditMessage(message.id)}
                            />
                            <TrashCan
                              color={pallete.colors.textDark10}
                              className='cursor-pointer'
                              onClick={() => handleDeleteComment(message)}
                            />
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <TextArea
                        className={`text-[14px] font-semibold text-[#586679] resize-none ${
                          editableMessageId === message.id ? 'p-[12px]' : ''
                        } `}
                        style={{
                          paddingRight: '156px'
                        }}
                        id={message.id}
                        type='secondary'
                        value={currentEditableMessage}
                        onChange={(value: string) =>
                          setCurrentEditableMessage(value)
                        }
                        loading={loading || store.uiStore.getGlobalLoader()}
                        disabled={loading}
                        onCancel={cancelEditingMessage}
                        PrimaryButtonText='Save'
                        primaryButtonOnClick={() =>
                          saveMessage(message.id, 'Parent')
                        }
                      />
                    )}
                    {message.type === 'COMMENT' &&
                      message.isActive &&
                      message.threads.length > 0 && (
                        <div onClick={() => handleShowReply(message.id)}>
                          <DDSTypography.Paragraph
                            size='caption'
                            variant='medium'
                            style={{ color: pallete.colors.textDark2 }}
                            className='whitespace-nowrap flex flex-row items-center cursor-pointer'
                          >
                            <div className='flex items-center'>
                              {showReplyId === '' ? (
                                <ChevronDown color={pallete.colors.textDark2} />
                              ) : (
                                <ChevronUp color={pallete.colors.textDark2} />
                              )}
                            </div>

                            {message.threads.length > 1
                              ? message.threads.length + ' Replies'
                              : message.threads.length + ' Reply'}
                          </DDSTypography.Paragraph>
                        </div>
                      )}
                  </div>
                </div>
                <div>
                  {message.id === showReplyId &&
                    message.type === 'COMMENT' &&
                    message.isActive &&
                    message.threads.length > 0 &&
                    message.threads.map((thread: any, index: any) => {
                      return (
                        <div key={index} className=' px-[28px]  w-full'>
                          <div
                            className={` flex flex-row gap-x-[12px]  py-[12px] px-[12px] rounded-md`}
                            onMouseEnter={() => {
                              setHoveringDiv(thread.id)
                            }}
                            onMouseLeave={() => {
                              setHoveringDiv('')
                            }}
                            style={{
                              backgroundColor:
                                hoveringDiv === thread.id
                                  ? pallete.colors.surface5
                                  : 'white'
                            }}
                          >
                            {message.actionedByUser ? (
                              <img
                                src={message.actionedByUser?.avatar}
                                alt='icon'
                                className='h-[32px] w-[32px] rounded-full'
                              />
                            ) : null}

                            {editableReplyId !== thread.id ? (
                              <div className='flex flex-row justify-between w-full'>
                                <div className='flex flex-col gap-y-[4px] justify-between w-full'>
                                  <div className='flex flex-row w-min items-center gap-x-[8px]'>
                                    <div className='flex justify-start gap-[8px]'>
                                      {message.actionedByUser ? (
                                        <DDSTypography.Title
                                          type='h5'
                                          variant='semiBold'
                                        >
                                          {thread.actionedByUser?.firstName +
                                            ' ' +
                                            thread.actionedByUser?.lastName}
                                        </DDSTypography.Title>
                                      ) : null}

                                      <DDSTypography.Paragraph
                                        size='caption'
                                        variant='medium'
                                        style={{
                                          color: pallete.colors.textDark7
                                        }}
                                        className='whitespace-nowrap'
                                      >
                                        {getTimeForActivity(thread.createdAt)}
                                      </DDSTypography.Paragraph>
                                    </div>
                                    <DDSTypography.Paragraph
                                      size='para'
                                      variant='medium'
                                      style={{
                                        color: pallete.colors.textDark6,
                                        overflowWrap: 'anywhere'
                                      }}
                                    >
                                      {thread.message}
                                    </DDSTypography.Paragraph>
                                  </div>
                                  {hoveringDiv === thread.id &&
                                  thread.type === 'COMMENT' &&
                                  thread.isActive ? (
                                    <div className='flex flex-row gap-x-[10px]  items-center '>
                                      <Edit
                                        color={pallete.colors.textDark10}
                                        className='cursor-pointer'
                                        onClick={() =>
                                          handleEditReplyMessage(
                                            thread.id,
                                            thread.message
                                          )
                                        }
                                      />
                                      <TrashCan
                                        color={pallete.colors.textDark10}
                                        className='cursor-pointer'
                                        onClick={() =>
                                          handleDeleteComment(thread)
                                        }
                                      />
                                    </div>
                                  ) : null}
                                </div>
                                <DDSTypography.Paragraph
                                  size='para'
                                  variant='medium'
                                  style={{
                                    color: pallete.colors.textDark6,
                                    overflowWrap: 'anywhere'
                                  }}
                                >
                                  {thread.message}
                                </DDSTypography.Paragraph>
                              </div>
                            ) : (
                              <TextArea
                                type='secondary'
                                PrimaryButtonText='Save'
                                className={`text-[14px] font-semibold text-[#586679] resize-none ${
                                  editableMessageId === thread.id
                                    ? 'p-[12px]'
                                    : ''
                                } `}
                                id={thread.id}
                                value={currentEditableReplyMessage}
                                onChange={(value: string) =>
                                  setCurrentEditableReplyMessage(value)
                                }
                                onCancel={handleCancelEditableReplyMessage}
                                primaryButtonOnClick={() =>
                                  saveMessage(thread.id, 'Reply')
                                }
                              />
                            )}
                          </div>
                        </div>
                      )
                    })}
                </div>
                {replyMessageId === message.id && (
                  <TextArea
                    type='secondary'
                    PrimaryButtonText='Reply'
                    onCancel={() => cancelReplyingMessage()}
                    onChange={(value: string) => setCurrentReplyMessage(value)}
                    primaryButtonOnClick={sendReply}
                    loading={loading || store.uiStore.getGlobalLoader()}
                    disabled={loading}
                    value={currentReplyMessage}
                    style={{
                      paddingRight: '156px'
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        )}

        <div className='sticky bottom-[24px] flex w-full min-h-[90px]'>
          <ActivityTextArea
            style={{ paddingRight: '120px', paddingBottom: '0px' }}
            placeholder=' Write an update'
            onChange={setText}
            onSend={() => {
              setButtonLoading(true)
              updateMessages(text)
              scrollToTop()
            }}
            value={text}
            type='incident'
            loading={
              buttonLoading || loading || store.uiStore.getGlobalLoader()
            }
            disabled={loading}
            isPublic={isPublic}
            setIsPublic={setIsPublic}
          />
        </div>
      </div>
    </>
  )
}
export default observer(IncidentActivity)
