import React from 'react'
import VigilIcon from '../../assets/images/vigilLogo.png'
import isNotMobile from '../../assets/images/mobile.svg'
import { observer } from 'mobx-react-lite'
import pallete from '../../global/newPallete'

interface MobileTabletViewProps {
  titleFontWeight?: number | string
  titleFontSize?: string
  titleLineHeight?: string
  descriptionFontWeight?: number | string
  descriptionFontSize?: string
  descriptionLineHeight?: string
}

const MobileTabletView: React.FC<MobileTabletViewProps> = ({
  titleFontWeight = 700,
  titleFontSize = '22px',
  titleLineHeight = '28px',
  descriptionFontWeight = 500,
  descriptionFontSize = '16px',
  descriptionLineHeight = '20px'
}) => {
  const image = <img src={isNotMobile} />
  const vigilLogo = <img src={VigilIcon} />

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#FFFFFF',
        textAlign: 'center',
        position: 'relative'
      }}
    >
      <img
        src={isNotMobile}
        style={{ paddingBottom: '16px', width: '215px', height: '215px' }}
        alt='Not on mobile'
      />
      <div
        style={{
          color: pallete.colors.textDark3,
          fontWeight: titleFontWeight,
          fontSize: titleFontSize,
          lineHeight: titleLineHeight,
          paddingBottom: '12px'
        }}
      >
        <p>Not on desktop?</p>
      </div>
      <div
        style={{
          color: pallete.colors.textDark5,
          fontWeight: descriptionFontWeight,
          fontSize: descriptionFontSize,
          lineHeight: descriptionLineHeight
        }}
      >
        <p>Please open Vigil on desktop to continue.</p>
      </div>
      <img
        src={VigilIcon}
        style={{
          height: '24px',
          width: '124px',
          position: 'absolute',
          bottom: '16px',
          left: '50%',
          transform: 'translateX(-50%)'
        }}
        alt='Vigil Icon'
      />
    </div>
  )
}

export default observer(MobileTabletView)
