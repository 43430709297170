import React, { useEffect } from 'react'

import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import CreateOrg from './CreateOrg/CreateOrg'
import DocumentHeader from '../Helmet/DocumentHeader'
import { HeaderConstants } from '../../constants/HeaderConstants'
import InviteMembers from './InviteMembers/InviteMembers'
import Navbar from './Navbar/Navbar'
import SecureComponent from '../Layout/SecureComponent'
import breakpoints from '../../global/breakpoints'
import palette from '../../global/pallete'
import styled from '@emotion/styled'

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${palette.colors.white};

  @media screen and (max-width: ${breakpoints.xl}) {
    width: 100%;
    padding: 0 1.25em;
  }
  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.85rem;
  }

  @media (max-width: ${breakpoints.md}) {
    font-size: 0.7rem;
  }
`
const NavbarWrapper = styled.div`
  width: 96em;
  padding-top: 3em;
  display: flex;
  align-items: center;
  @media screen and (max-width: ${breakpoints.xl}) {
    width: 100%;
    padding: 3em 1.25em 0;
  }
`

const ChildrenWrapper = styled.div`
  width: 96em;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  @media screen and (max-width: ${breakpoints.xl}) {
    width: 100%;
    padding: 0 1.25em;
  }
`

const CreateOrgAssignMembers: React.FC<{ inviteMembers: boolean }> = ({
  inviteMembers = false,
}) => {
  const [currentTab, setCurrentTab] = React.useState(0)
  const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }
  useEffect(() => {
    if (inviteMembers) setCurrentTab(1)
  }, [])

  return (
    <Container>
      <DocumentHeader header={HeaderConstants.INVITE_MEMBERS} />
      <NavbarWrapper>
        <Navbar
          onChange={handleTabChange}
          currentTab={currentTab}
          inviteMembers={inviteMembers}
        />
      </NavbarWrapper>

      <ChildrenWrapper>
        {currentTab === 0 && (
          <CreateOrg handleTabChange={() => setCurrentTab(1)} />
        )}

        {currentTab === 1 && <InviteMembers inviteMembers={inviteMembers} />}
      </ChildrenWrapper>
    </Container>
  )
}

export default SecureComponent(CreateOrgAssignMembers, AccessRoleConstants.ALL)
