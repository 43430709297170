// src/components/Button/Button.tsx

import { DDSButton, DDSLoader } from 'den-design-system'

import { ButtonProps } from './Button.types'
import React from 'react'
import pallete from '../../../global/newPallete'

export const Button: React.FC<ButtonProps> = ({
  type = 'filled',
  size = 'medium',
  label,
  endIcon,
  startIcon,
  id,
  loading,
  disabled = false,
  text = true,
  onBlur,
  onClick,
  onMouseOver,
  onMouseOut,
  width
}) => {
  const baseStyles = {
    padding: '0px 12px !important',
    gap: '8px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    width: width ? width : ''
  }

  const typeToStyleMap = {
    filled: {
      ...baseStyles,
      color: pallete.colors.white,
      border: '',
      hoverBgColor: pallete.colors.fillButtonhover,
      bgColor: disabled
        ? pallete.colors.FillButtonDis
        : pallete.colors.FillButton
    },
    dangerFilled: {
      ...baseStyles,
      color: pallete.colors.white,
      border: '',
      hoverBgColor: pallete.colors.warningRed1,
      bgColor: disabled
        ? pallete.colors.warningRed3
        : pallete.colors.warningRed2
    },
    outline: {
      ...baseStyles,
      color: disabled ? pallete.colors.outButtonDis : pallete.colors.outButton,
      border: disabled
        ? `1px solid ${pallete.colors.neuButtonStroke}`
        : `1px solid ${pallete.colors.outButton}`,
      hoverBgColor: pallete.colors.outButtonHover,
      bgColor: pallete.colors.white
    },
    neutral: {
      ...baseStyles,
      color: disabled
        ? pallete.colors.neuButtontextDis
        : pallete.colors.neuButtonText,
      border: disabled
        ? `1px solid ${pallete.colors.neuButtonHover}`
        : `1px solid ${pallete.colors.neuButtonStroke}`,
      hoverBgColor: pallete.colors.neuButtonHover,
      bgColor: pallete.colors.white
    },
    danger: {
      ...baseStyles,
      color: disabled
        ? pallete.colors.dangerButtonDis
        : pallete.colors.warningRed2,
      border: disabled
        ? `1px solid ${pallete.colors.dangerButtonDis}`
        : `1px solid ${pallete.colors.warningRed2}`,
      hoverBgColor: pallete.colors.dangerButtonHover,
      bgColor: pallete.colors.white
    },
    ghost: {
      ...baseStyles,
      color: disabled
        ? pallete.colors.textButtonDis
        : pallete.colors.textButtonText,
      border: '',
      hoverBgColor: pallete.colors.neuButtonHover,
      bgColor: ''
    }
  }

  return type === 'ghost' ? (
    <div className='override-button'>
      <DDSButton
        type={type}
        shape='roundedRectangle'
        size={size}
        label={text ? label : ''}
        endIcon={endIcon}
        startIcon={
          loading ? (
            <DDSLoader
              loading={loading}
              size={'xs'}
              style={{ width: '16px', height: '16px', color: 'white' }}
            />
          ) : (
            startIcon
          )
        }
        disabled={disabled}
        className=' whitespace-nowrap'
        id={id}
        onBlur={onBlur}
        onClick={onClick}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
        buttonHoverBackgroundColor={typeToStyleMap[type].hoverBgColor}
        style={typeToStyleMap[type]}
      />
    </div>
  ) : (
    <div className='override-button'>
      <DDSButton
        type={
          type === 'neutral' || type === 'danger'
            ? 'outline'
            : type === 'dangerFilled'
              ? 'filled'
              : type
        }
        shape='roundedRectangle'
        size={size}
        label={text ? label : ''}
        endIcon={endIcon}
        startIcon={
          loading ? (
            <DDSLoader
              loading={loading}
              size={'xs'}
              style={{ width: '16px', height: '16px', color: 'white' }}
            />
          ) : (
            startIcon
          )
        }
        disabled={disabled}
        className=' whitespace-nowrap'
        id={id}
        onBlur={onBlur}
        onClick={onClick}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
        backgroundColor={typeToStyleMap[type].bgColor}
        buttonHoverBackgroundColor={typeToStyleMap[type].hoverBgColor}
        style={typeToStyleMap[type]}
      />
    </div>
  )
}
