import { RootStore } from './RootStore'
import { getHealthSummary } from '../api/appHealthCheck/AHC.service'
import { makeAutoObservable } from 'mobx'

export interface Summary {
  totalChecks: number
  totalUp: number
  totalDown: number
  totalPaused?: number
}
const initialState: Summary = {
  totalChecks: 0,
  totalUp: 0,
  totalDown: 0,
  totalPaused: 0
}
export class AhcSummaryStore {
  summary: Summary
  loading: boolean
  error: string | null
  constructor(private rootStore: RootStore) {
    this.summary = initialState
    this.loading = false
    this.error = null
    makeAutoObservable(this)
  }
  setSummary(summary: Summary) {
    // delete summary['totalPaused']
    this.rootStore.ahcSummaryStore.summary = { ...summary }
  }
  getSummary() {
    return this.rootStore.ahcSummaryStore.summary
  }
  setError(error: string | null) {
    this.rootStore.ahcSummaryStore.error = error
  }
  getError() {
    return this.rootStore.ahcSummaryStore.error
  }
  setLoading(loading: boolean) {
    this.rootStore.ahcSummaryStore.loading = loading
  }
  getLoading() {
    return this.rootStore.ahcSummaryStore.loading
  }
  async getSummaryFromApi() {
    this.setLoading(true)
    try {
      const { data } = (await getHealthSummary()) as { data: Summary }
      this.setSummary(data)
      this.setError(null)
    } catch (e) {
      console.log(e)
      this.setError(e as string)
    } finally {
      this.setLoading(false)
    }
  }
  reset() {
    this.summary = initialState
    this.loading = false
    this.error = null
  }
}
