import { DDSTypography } from 'den-design-system'
import React from 'react'
import pallete from '../global/newPallete'
const SeverityTypes = [
  {
    label: (
      <div>
        <DDSTypography.Paragraph
          color={pallete.colors.criticalSeverity}
          size='para'
          variant='semiBold'
        >
          Critical
        </DDSTypography.Paragraph>
      </div>
    ),
    value: 'Critical'
  },
  {
    label: (
      <div>
        <DDSTypography.Paragraph
          color={pallete.colors.moderateSeverity}
          size='para'
          variant='semiBold'
        >
          Moderate
        </DDSTypography.Paragraph>
      </div>
    ),
    value: 'Moderate'
  },
  {
    label: (
      <div>
        <DDSTypography.Paragraph
          color={pallete.colors.minorSeverity}
          size='para'
          variant='semiBold'
        >
          Minor
        </DDSTypography.Paragraph>
      </div>
    ),
    value: 'Minor'
  },
  {
    label: (
      <div>
        <DDSTypography.Paragraph
          color={pallete.colors.majorSeverity}
          size='para'
          variant='semiBold'
        >
          Major
        </DDSTypography.Paragraph>
      </div>
    ),
    value: 'Major'
  }
]
export default SeverityTypes
