import { makeAutoObservable, observable } from 'mobx'

import { RootStore } from './RootStore'

export class ApiDetailsStore {
  data: any
  apiData: any
  loading: boolean
  isError: boolean
  isRefresh: boolean
  apiLoading: boolean
  activeTab: string
  userName: string

  constructor(private rootStore: RootStore) {
    this.data = {}
    this.apiData = {}
    this.isRefresh = false
    this.loading = false
    this.apiLoading = false
    this.isError = false
    this.activeTab = ''
    this.userName = ''
    makeAutoObservable(this, { data: observable.struct })
  }
  setData(data: Record<string, string>) {
    this.rootStore.apiDetailsStore.data = data
  }
  getData() {
    return this.rootStore.apiDetailsStore.data
  }

  setRefresh(isRefresh: boolean) {
    this.rootStore.apiDetailsStore.isRefresh = isRefresh
  }
  getRefresh() {
    return this.rootStore.apiDetailsStore.isRefresh
  }

  setAPILoading(apiLoading: boolean) {
    this.rootStore.apiDetailsStore.apiLoading = apiLoading
  }
  getAPILoading() {
    return this.rootStore.apiDetailsStore.apiLoading
  }

  setUserName(userName: string) {
    this.userName = userName
  }

  getUserName() {
    return this.rootStore.apiDetailsStore.userName
  }

  setApiData(apiData: Record<string, string>) {
    this.rootStore.apiDetailsStore.apiData = apiData
  }
  getApiData() {
    return this.rootStore.apiDetailsStore.apiData
  }
  setLoading(loading: boolean) {
    this.rootStore.apiDetailsStore.loading = loading
  }
  getLoading() {
    return this.rootStore.apiDetailsStore.loading
  }
  getIsError() {
    return this.rootStore.apiDetailsStore.isError
  }
  setIsError(isError: boolean) {
    this.rootStore.apiDetailsStore.isError = isError
  }

  getActiveTab() {
    return this.rootStore.apiDetailsStore.activeTab
  }
  setActiveTab(tabIndex: string) {
    this.rootStore.apiDetailsStore.activeTab = tabIndex
  }
  reset() {
    this.data = {}
    this.apiData = {}
    this.loading = false
    this.isError = false
    this.isRefresh = false
  }
}
