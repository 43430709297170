import { DDSTypography } from 'den-design-system'
import React from 'react'
import pallete from '../../global/newPallete'

const IconCard: React.FC<{ icon: React.ReactNode; text: string }> = ({
  icon,
  text
}) => {
  return (
    <div className='flex flex-col justify-center items-center gap-[8px]'>
      <div>{icon}</div>
      <div>
        <DDSTypography.Title
          type='h5'
          variant='medium'
          color={pallete.colors.textDark1}
        >
          {text}
        </DDSTypography.Title>
      </div>
    </div>
  )
}

export default IconCard
