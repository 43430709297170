import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import React, { useEffect } from 'react'

import { AccessRoleConstants } from '../../../constants/AccessRoleConstants'
import SecureComponent from '../../../components/Layout/SecureComponent'
import StringConstants from '../../../constants/StringConstants'
import UCAddCheck from './UCAddCheck'
import UCDashboard from './UCDashboard'
import UCLogSpecificDetails from './UCLogSpecificDetails'
import UCOverviewLogTable from './UCOverviewLogTable'
import UCPageLayout from './UCPageLayout'
import UCUpdateCheck from './UCUpdateCheck'
import { getAllQueryParamsAsObjectFromUrl } from '../../../utils/UrlUtils'
import { getUserDetails } from '../../../api/user/User.service'
import { isUndefined } from 'lodash'
import routeConstants from '../../../constants/RouteConstants'
import { useStoreContext } from '../../../store/StoreContext'

const UCPageRouter: React.FC = () => {
  const store = useStoreContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  useEffect(() => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const pageFromQueryParams = searchParams.get('page')
    if (!isUndefined(pageFromQueryParams)) {
      setSearchParams({ ...allQueryParams, page: '1' })
    }

    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.UPTIME_CHECKS
    )
    getUserDetails().then((userResponse: any) => {
      const organizationIndex = userResponse.data.organizations.findIndex(
        (org: any) => {
          return org.id === store.userStore.getSelectedOrganization().id
        }
      )
      if (organizationIndex === -1) {
        navigate(routeConstants.UNAUTHORIZED_PAGE)
      }
    })
  }, [])

  return (
    <Routes>
      <Route element={<UCPageLayout />}>
        <Route index element={<Navigate to='dashboard' />} />
        <Route path='dashboard' element={<UCDashboard />} />
        <Route path='dashboard/:id' element={<UCOverviewLogTable />} />
        <Route
          path={'dashboard/:id/logs/:eventId'}
          element={<UCLogSpecificDetails />}
        />
        <Route path='dashboard/add-check' element={<UCAddCheck />} />
        <Route path='dashboard/update-check/:id' element={<UCUpdateCheck />} />
      </Route>
    </Routes>
  )
}

export default SecureComponent(UCPageRouter, AccessRoleConstants.MEMBER_LEVEL)
