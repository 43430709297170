import InputAdornment from '@mui/material/InputAdornment'
import MuiTextField from '@mui/material/TextField'
import { OutlinedInputProps } from '@mui/material/OutlinedInput'
import React from 'react'
import { AiOutlineSearch as SearchIcon } from 'react-icons/ai'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

interface SearchFieldProps {
  width?: string
  placeholder?: string
  fullWidth?: boolean
  size?: 'small' | 'medium'
  color?: 'primary' | 'secondary' | 'info' | undefined
  error?: boolean
  type?: string
  value?: string
  disabled?: boolean
  helperText?: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  margin?: string
  left?: string
  style?: any
  useMediaQuery?: boolean
}

const TextField = styled(MuiTextField)`
  && {
    .MuiOutlinedInput-root {
      border-radius: 6px;
      background: ${palette.colors.lightBack};
    }
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${palette.colors.borderColor};
    }
    ${(props: SearchFieldProps) =>
      props.useMediaQuery &&
      `
        @media (max-width: 960px) {
          margin-left: 100px;
          width: 150px;
        }
      `}
  }
`

const SearchField: React.FC<SearchFieldProps> = ({
  fullWidth = false,
  size = 'small',
  width = '100%',
  color = 'primary',
  error = false,
  type = 'text',
  value,
  disabled = false,
  helperText = '',
  placeholder = 'Search',
  onBlur,
  onChange,
  onKeyDown,
  margin,
  left,
  style = {}
}) => {
  return (
    <TextField
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      variant='outlined'
      color={color}
      margin='dense'
      size={size}
      disabled={disabled}
      value={value}
      error={error}
      helperText={helperText}
      fullWidth={fullWidth}
      type={type}
      placeholder={placeholder}
      sx={{
        width,
        margin,
        left: 0
      }}
      InputProps={
        {
          style: { fontSize: '1em', width: 'inherit' },
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon style={{ fontSize: '1.125em' }} />
            </InputAdornment>
          )
        } as Partial<OutlinedInputProps>
      }
      style={{ ...style }}
    />
  )
}

export default SearchField
