export enum SubscriptionPlanType {
  HACKER = 'HACKER',
  SOLOPRENEUR = 'SOLOPRENEUR',
  START_UP = 'START_UP',
  ENTERPRISE = 'ENTERPRISE'
}

export enum OnBoardingType {
  SSO = 'SSO',
  EMAIL = 'EMAIL'
}

export interface SignupPayload {
  firstName: string
  lastName: string
  email: string
  organizationName: string
  password: string
  phoneNumber?: string
  location?: string
  address?: string
  dob?: string
  inviteToken?: string
  isTermsAccepted: boolean
  isNewsletterSubscribed: boolean
  _utmSource?: string
  _utmMedium?: string
  _utmName?: string
  _utmContent?: string
  _utmTerms?: string
  _utmPosition?: string
  _invitedBy?: string
  _invitedAt?: string
  captcha: string | null
  chargebeePlanId?: string
}

export interface UpdatePayload {
  firstName?: string
  lastName?: string
  email?: string
  phoneNumber?: string
  location?: {
    latitude: string
    longitude: string
  }
  address?: {
    addressLine1?: string
    addressLine2?: string
    state?: string
    city?: string
    country?: string
    zipCode?: string
  }
  dob?: string
  organizationName?: string
  isVerified?: boolean
}

export interface UpdatePreferencePayload {
  language?: string
  notification?: [string]
  theme?: string
  defaultLandingPage?: string
  timeZone?: string
  hourFormat?: string
  promotionalNotifications?: boolean
  weeklyReports?: boolean
  dailyReports?: boolean
  notifyMyActivity?: boolean
  muteNotifications?: boolean
  secondaryEmail?: string
}
