import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import { BiRefresh } from 'react-icons/bi'
import Button from '../../components/common/Button/Button'
import DashboardSectionContainer from '../../components/common/DashboardSectionContainer/DashboardSectionContainer'
import DashboardTopNavbar from '../../components/common/DashboardTopNavbar/DashboardTopNavbar'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../constants/HeaderConstants'
import NoData from '../../components/NoData/NoData'
import { ProjectType } from '../../enum/ProjectType.enum'
import React from 'react'
import { RiDeleteBin6Line } from 'react-icons/ri'
import SearchField from '../../components/common/InputField/SearchField'
import SecureComponent from '../../components/Layout/SecureComponent'
import Table from '../../components/common/Table/Table'
import { TypographyDDS } from '../../components/common/Typography/TypographyDDS'
import breakpoints from '../../global/breakpoints'
import palette from '../../global/pallete'
import styled from '@emotion/styled'

const Container = styled.div`
  display: grid;
`
const TopContainer = styled.div`
  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.9rem;
  }
  font-size: 1rem;
`

const ButtonContainer = styled.div`
  display: flex;
  grid-gap: 1.5em;
  justify-content: center;
`

const data = [
  {
    url: 'http: localhost300/api1',
    deletedOn: '400',
    deletedBy: 'Aman'
  },
  {
    url: 'http: localhost300/api2',
    deletedOn: '400',
    deletedBy: 'Aman'
  },
  {
    url: 'http: localhost300/api3',
    deletedOn: '400',
    deletedBy: 'Aman'
  },
  {
    url: 'http: localhost300/api4',
    deletedOn: '400',
    deletedBy: 'Aman'
  },
  {
    url: 'http: localhost300/api5',
    deletedOn: '400',
    deletedBy: 'Aman'
  },
  {
    url: 'http: localhost300/api6',
    deletedOn: '400',
    deletedBy: 'Aman'
  }
]

const tableColumns = [
  {
    title: 'URL',
    label: 'url',
    render: (item: any) => (
      <TopContainer>
        <TypographyDDS.Title type='h4' variant='medium' color='textDark2'>
          <em>{item}</em>
        </TypographyDDS.Title>
      </TopContainer>
    )
  },
  {
    title: 'Deleted On',
    label: 'deletedOn',
    render: (item: any) => (
      <TopContainer>
        <TypographyDDS.Title type='h4' variant='medium' color='textDark2'>
          {item}
        </TypographyDDS.Title>
      </TopContainer>
    )
  },
  {
    title: 'Deleted By',
    label: 'deletedBy',
    render: (item: any) => (
      <TopContainer>
        <TypographyDDS.Title type='h4' variant='medium' color='textDark2'>
          {item}
        </TypographyDDS.Title>
      </TopContainer>
    )
  },
  {
    title: '',
    label: 'buttonGroup',
    render: (item: any) => (
      <TopContainer>
        <ButtonContainer>
          <Button
            variant='contained'
            fontSize='0.75em'
            width='8.125em'
            startIcon={<BiRefresh style={{ fontSize: '1em' }} />}
          >
            Restore
          </Button>

          <Button
            variant='contained'
            fontSize='0.75em'
            width='8.125em'
            color='error'
            startIcon={<RiDeleteBin6Line style={{ fontSize: '1em' }} />}
          >
            Delete
          </Button>
        </ButtonContainer>
      </TopContainer>
    )
  }
]

const ArchivedAPIRecords = () => {
  return (
    <TopContainer>
      <DocumentHeader header={HeaderConstants.ARCHIVED_API_RECORDS} />
      <Container>
        {/* <DashboardTopNavbar
          variant='apiMonitoring'
          breadcrumbs
          showTopDropdowns
          breadcrumbsBorder={false}
          renderFilter={true}
        /> */}
        <DashboardSectionContainer
          headerText='Archived API Records'
          paddingBottom
          rightHeaderItem={
            <div>
              <SearchField placeholder='Search' />
            </div>
          }
        >
          {!data.length && (
            <TypographyDDS.Title type='h4' color='textDark2'>
              <NoData />
            </TypographyDDS.Title>
          )}
          {data.length > 0 && (
            <>
              <Table columns={tableColumns} data={data} />
            </>
          )}
        </DashboardSectionContainer>
      </Container>
    </TopContainer>
  )
}

export default SecureComponent(
  ArchivedAPIRecords,
  AccessRoleConstants.MEMBER_LEVEL,
  ProjectType.SALESFORCE_ONLY
)
