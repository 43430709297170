import cronstrue from 'cronstrue'
import { isEmpty } from 'lodash'

/**
 *
 * @param {string} cronExpression
 * @returns {string} Returns human readable format of cron expression
 */
export const describeCronExpression = (cronExpression: string) => {
  try {
    if (!isEmpty(cronExpression)) {
      return cronstrue.toString(cronExpression)
    } else {
      return ''
    }
  } catch (err) {
    return ''
  }
}
