import { ChartStepper, PillsAdd } from '@carbon/icons-react'

import AccountSettingsIcon from '../assets/icons/accountSettings.svg'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import ApiMonitoringIcon from '../assets/icons/api-monitoring.svg'
import BillingSettingsIcon from '../assets/icons/billingSettings.svg'
import ExceptionIcon from '../assets/icons/Exception.svg'
import FilterConstants from '../constants/FilterConstants'
import GeneralSettingsIcon from '../assets/icons/generalSettingsIcon.svg'
import HealthStatusIcon from '../assets/icons/HealthStatus.svg'
import JobMonitoringIcon from '../assets/icons/JobMonitoring.svg'
import MemberSettingsIcon from '../assets/icons/membersSettings.svg'
import { OrganizationType } from '../enum/OrganizationType.enum'
import { OrganizationUserRoles } from '../enum/OrganizationUserRoles.enum'
import OverviewIcon from '../assets/icons/Overview.svg'
import ProjectSettingsIcon from '../assets/icons/projectSettings.svg'
import { ProjectType } from '../enum/ProjectType.enum'
import React from 'react'
import SFAuditIcon from '../assets/icons/SalesforceAudit.svg'
import SettingsIcon from '../assets/icons/Settings.svg'
import StringConstants from '../constants/StringConstants'
import { VscSettings } from 'react-icons/vsc'
import routeConstants from '../constants/RouteConstants'

/**
 * Util function to settings page's tab headers
 * @param {string} userRole - role of the logged in user
 * @returns {any} - tab headers
 */
export const getSettingsHeaderTabs = (userRole: string) => {
  if (userRole)
    return [
      {
        icon: <img src={GeneralSettingsIcon} style={{ maxHeight: '18px' }} />,
        label: 'General',
        to: routeConstants.ORGANIZATION_SETTINGS
      },
      ...(userRole !== OrganizationUserRoles.BILLING
        ? [
            {
              icon: (
                <img src={MemberSettingsIcon} style={{ maxHeight: '18px' }} />
              ),
              label: 'Members',
              to: routeConstants.MEMBER_SETTINGS
            },
            {
              icon: (
                <img src={ProjectSettingsIcon} style={{ maxHeight: '18px' }} />
              ),
              label: 'Projects',
              to: routeConstants.PROJECT_SETTINGS
            }
          ]
        : []),
      ...(userRole !== OrganizationUserRoles.MEMBER
        ? [
            {
              icon: (
                <img src={BillingSettingsIcon} style={{ maxHeight: '20px' }} />
              ),
              label: 'Billing',
              to: routeConstants.SUBSCRIPTION_SETTINGS
            }
          ]
        : []),
      {
        icon: <img src={AccountSettingsIcon} style={{ maxHeight: '18px' }} />,
        label: 'Account',
        to: routeConstants.ACCOUNT_SETTING
      },
      {
        icon: <VscSettings />,
        label: 'Preferences',
        to: routeConstants.PREFERENCES_SETTINGS
      }
    ]
  else
    return [
      {
        icon: <img src={AccountSettingsIcon} style={{ maxHeight: '18px' }} />,
        label: 'Account',
        to: routeConstants.ACCOUNT_SETTING
      }
    ]
}

/**
 * A function to construct to contexts from queryParams
 * @param query
 * @param contextMap
 * @param queryMap
 * @returns
 */
export const constructContextsFromQuery = (
  query: any,
  contextMap: any,
  queryMap: any
): any[] => {
  const contexts: any[] = []

  Object.keys(query).forEach((key) => {
    if (FilterConstants.SEARCH_QUERY.includes(key)) {
      const context = {
        id: contextMap[queryMap[key]].query,
        label: `${contextMap[queryMap[key]].label} : ${query[key]}`
      }
      contexts.push(context)
    }
  })
  return contexts
}

export const paginate = (data: any[], currentPage: number, maxSize: number) => {
  const startIndex = (currentPage - 1) * maxSize
  const endIndex = startIndex + maxSize
  return data.slice(startIndex, endIndex)
}

export const generateColors = (n: number) => {
  const colors = []
  for (let i = 0; i < n; i++) {
    const hue = Math.floor((360 * i) / n)
    const lightness = Math.floor(Math.random() * 20) + 40
    colors.push(`hsl(${hue}, 100%, ${lightness}%)`)
  }
  return colors
}

export const generateRandomId = () => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  const charactersLength = characters.length

  for (let i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

export function calculateStartAndEndByDateRange(
  dateRange: any,
  currentDate: Date
): { startDate: Date; endDate: Date } {
  const startDate = new Date(currentDate)
  let endDate = new Date(currentDate)
  switch (dateRange) {
    case 'THIS_WEEK':
      startDate.setDate(currentDate.getDate() - currentDate.getDay())
      endDate.setDate(startDate.getDate() + 6)
      break
    case 'LAST_WEEK':
      startDate.setDate(currentDate.getDate() - currentDate.getDay() - 7)
      endDate.setDate(startDate.getDate() + 6)
      break
    case 'THIS_MONTH':
      startDate.setDate(1)
      break
    case 'LAST_MONTH':
      startDate.setMonth(currentDate.getMonth() - 1, 1)
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)
      break
    case 'LAST_3_MONTHS':
      startDate.setMonth(currentDate.getMonth() - 3, 1)
      endDate.setDate(currentDate.getDate())
      break
    default:
      throw new Error('Invalid date range filter')
  }

  return { startDate, endDate }
}

export function calculateStartAndEndByDayWeekMonthYear({
  day,
  week,
  month,
  year,
  currentDate
}: {
  day?: number
  week?: number
  month?: number
  year?: number
  currentDate: Date
}): { startDate: Date; endDate: Date } {
  let startDate = new Date(currentDate)
  let endDate = new Date(currentDate)

  // Handle year
  if (year) {
    startDate.setUTCFullYear(year, 0, 1) // Jan 1st of the year
    endDate.setUTCFullYear(year, 11, 31) // Dec 31st of the year
    startDate.setUTCHours(0, 0, 0, 0)
    endDate.setUTCHours(23, 59, 59, 999)
  }

  // Handle month
  if (month) {
    if (year) {
      startDate.setUTCFullYear(year)
      endDate.setUTCFullYear(year)
    }
    startDate.setUTCMonth(month - 1, 1) // Start of the month
    endDate.setUTCMonth(month, 0) // Corrected to end of the month
    startDate.setUTCHours(0, 0, 0, 0)
    endDate.setUTCHours(23, 59, 59, 999)
  }

  // Handle week
  if (week) {
    const startOfYear = new Date(Date.UTC(startDate.getUTCFullYear(), 0, 1))
    const dayOfWeek = startOfYear.getUTCDay() // Day of the week for Jan 1
    const weekStartOffset = (week - 1) * 7 - (dayOfWeek ? dayOfWeek - 1 : 6) // Adjust to first Monday
    startDate = new Date(startOfYear)
    startDate.setUTCDate(startOfYear.getUTCDate() + weekStartOffset)
    startDate.setUTCHours(0, 0, 0, 0)

    endDate = new Date(startDate)
    endDate.setUTCDate(startDate.getUTCDate() + 6) // End of the week
    endDate.setUTCHours(23, 59, 59, 999)
  }

  // Handle day
  if (day !== undefined) {
    if (week || month || year) {
      const currentDayOfWeek = startDate.getUTCDay()
      const dayAdjustment = day - currentDayOfWeek // Align to the desired day
      startDate.setUTCDate(startDate.getUTCDate() + dayAdjustment)
    } else {
      const dayAdjustment = day - startDate.getUTCDay() // Align to desired day
      startDate.setUTCDate(startDate.getUTCDate() + dayAdjustment)
    }
    endDate = new Date(startDate) // Single day range
    endDate.setUTCHours(23, 59, 59, 999)
  }

  return { startDate, endDate }
}
