import { DDSToastNotification, DDSTypography } from 'den-design-system'
import React, { useEffect, useState } from 'react'
import {
  checkNameAvailabilityFromServer,
  getStatusForTab,
  schema
} from '../../../utils/JobUtils'
import {
  configureJob,
  findAllConfiguredJobs,
  importJobsFromEnvironment
} from '../../../api/jobs/Jobs.service'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'
import { useParams, useSearchParams } from 'react-router-dom'

import AddEditJobPopUp from '../../../components/JobMonitoring/JMDashboard/AddEditPopUp'
import { Button } from '../../../components/New/Button/Button'
import { FilterTypes } from '../../../enum/Filter.enum'
import ImportJobPopup from '../../../components/JobMonitoring/JMDashboard/ImportJobPopup'
import JMJobsPage from '../../../components/JobMonitoring/JMDashboard/JMJobsPage'
import JMStringConstants from '../../../constants/JMStringConstants'
import { ProjectType } from '../../../enum/ProjectType.enum'
import { Renew } from '@carbon/icons-react'
import StringConstants from '../../../constants/StringConstants'
import Tabs from '../../../components/common/DDS/Tabs/Tabs'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../components/New/Tooltip/Tooltip'
import { ValidationResult } from 'joi'
import { getFiltersForApi } from '../../../utils/FilterUtils'
import { getOrganizationProjectsThingsToDo } from '../../../api/organization/Organization.service'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { useStoreContext } from '../../../store/StoreContext'

interface JobFormData {
  name: string
  slug: string
  description: string
  cronExpression: string
  monitorJob: boolean
}

const JMDashboard: React.FC = () => {
  const [jobs, setJobs] = useState<any>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
  const [envSelect, setEnvSelect] = useState<string>('')
  const [addPopupOpen, setAddPopupOpen] = useState<boolean>(false)
  const [copyPopupOpen, setCopyPopupOpen] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [recordsPresent, setRecordsPresent] = useState<boolean>(false)
  const currTab = searchParams.get('tab')
  const [activeTab, setActiveTab] = useState<string>(currTab ? currTab : '1')
  const store = useStoreContext()
  const [loading, setLoading] = useState<boolean>(false)
  const defaultFormData: JobFormData = {
    name: '',
    slug: '',
    description: '',
    cronExpression: '',
    monitorJob: true
  }
  const [jobFormData, setJobFormData] = useState<any>(defaultFormData)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [isInvalidJobName, setIsInvalidJobName] = useState(false)
  const [isInvalidJobSlug, setIsInvalidJobSlug] = useState(false)
  const { pathIds } = useParams()

  useEffect(() => {
    const validated: ValidationResult = schema.validate(
      {
        name: jobFormData.name.trim(),
        slug: jobFormData.slug.trim(),
        cronExpression: jobFormData.cronExpression,
        description: jobFormData.description
      },
      {
        abortEarly: false
      }
    )
    const requiredFields = Object.keys(jobFormData).filter(
      (field) => field !== 'description'
    )
    const areAllRequiredFieldsFilled = requiredFields.every(
      (field) => jobFormData[field] !== '' && jobFormData[field] !== null
    )
    if (validated.error) {
      setIsButtonDisabled(true)
    } else {
      setIsButtonDisabled(!areAllRequiredFieldsFilled)
    }
  }, [jobFormData])

  const findAllJobs = (page = 1, queryFilters: any) => {
    if (!isEmpty(store.scopeStore.getScope()['environment-id'])) {
      store.uiStore.setGlobalLoader(true)
      setLoading(true)
      const size = StringConstants.DEFAULT_TABLE_SIZE
      const filter = getFiltersForApi(FilterTypes.JOB, queryFilters)
      findAllConfiguredJobs({
        'expand-created-user': true,
        size,
        page,
        ...filter
      })
        .then((response: any) => {
          setJobs(response.data)
          setTotalCount(response.totalCount)
          setRecordsPresent(response.recordsPresent)
          store.filterStore.setFilters(response.filters)
        })
        .catch((err: any) =>
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.JOB_MONITORING.FIND_JOBS.ERROR
          })
        )
        .finally(() => {
          store.filterStore.setFilterDisabled(false)
          setLoading(false)
          store.uiStore.setGlobalLoader(false)
        })
    }
  }

  const findJobsForTab = () => {
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (activeTab === '1') {
      findAllJobs(1, queryParams)
    } else {
      findAllJobs(1, {
        ...queryParams,
        ['previous-run-status']: getStatusForTab(activeTab)
      })
    }
  }

  const importJobs = () => {
    setButtonLoading(true)
    setLoading(true)
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    importJobsFromEnvironment({ sourceEnvironmentId: envSelect as string })
      .then((response: any) => {
        setActiveTab('1')

        if (response.message === 'No jobs were found to import') {
          DDSToastNotification.warning(response.message)
        } else {
          ToastNotification({
            type: 'success',
            message: response.message
          })
        }
        findAllJobs(1, queryParams)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: err
        })

        setButtonLoading(false)
        setLoading(false)
        setCopyPopupOpen(false)
      })
      .finally(() => {
        setButtonLoading(false)
        setLoading(false)
        setCopyPopupOpen(false)
      })
  }
  const tabs = [
    {
      label:
        activeTab === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {JMStringConstants.ALL_JOBS_TAB}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {JMStringConstants.ALL_JOBS_TAB}
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <JMJobsPage
            jobs={jobs}
            totalCount={totalCount}
            recordsPresent={recordsPresent}
            findAllJobs={findAllJobs}
            isLoading={loading}
          />
        </div>
      )
    },
    {
      label:
        activeTab === '2' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {JMStringConstants.SUCCESS_JOBS_TAB}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {JMStringConstants.SUCCESS_JOBS_TAB}
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <JMJobsPage
            jobs={jobs}
            totalCount={totalCount}
            recordsPresent={recordsPresent}
            status='SUCCESS'
            findAllJobs={findAllJobs}
            isLoading={loading}
          />
        </div>
      )
    },
    {
      label:
        activeTab === '3' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {JMStringConstants.RUNNING_JOBS_TAB}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {JMStringConstants.RUNNING_JOBS_TAB}
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <JMJobsPage
            jobs={jobs}
            totalCount={totalCount}
            recordsPresent={recordsPresent}
            status='RUNNING'
            findAllJobs={findAllJobs}
            isLoading={loading}
          />
        </div>
      )
    },
    {
      label:
        activeTab === '4' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {JMStringConstants.FAILED_JOBS_TAB}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {JMStringConstants.FAILED_JOBS_TAB}
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <JMJobsPage
            jobs={jobs}
            recordsPresent={recordsPresent}
            totalCount={totalCount}
            status='FAILED'
            findAllJobs={findAllJobs}
            isLoading={loading}
          />
        </div>
      )
    }
  ]

  useEffect(() => {
    if (activeTab === '1') {
      store.breadcrumbStore.setBreadcrumbsOptions({
        label: JMStringConstants.ALL_JOBS_TAB,
        link: replaceUrl(
          routeConstants.JOB_MONITORING,
          store.scopeStore.getScope()['organization-id'] as string,
          store.scopeStore.getScope()['project-id'] as string,
          store.scopeStore.getScope()['environment-id'] as string
        )
      })
    } else if (activeTab === '2') {
      store.breadcrumbStore.setBreadcrumbsOptions({
        label: JMStringConstants.SUCCESS_JOBS_TAB,
        link: replaceUrl(
          routeConstants.JOB_MONITORING,
          store.scopeStore.getScope()['organization-id'] as string,
          store.scopeStore.getScope()['project-id'] as string,
          store.scopeStore.getScope()['environment-id'] as string
        )
      })
    } else if (activeTab === '3') {
      store.breadcrumbStore.setBreadcrumbsOptions({
        label: JMStringConstants.RUNNING_JOBS_TAB,
        link: replaceUrl(
          routeConstants.JOB_MONITORING,
          store.scopeStore.getScope()['organization-id'] as string,
          store.scopeStore.getScope()['project-id'] as string,
          store.scopeStore.getScope()['environment-id'] as string
        )
      })
    } else if (activeTab === '4') {
      store.breadcrumbStore.setBreadcrumbsOptions({
        label: JMStringConstants.FAILED_JOBS_TAB,
        link: replaceUrl(
          routeConstants.JOB_MONITORING,
          store.scopeStore.getScope()['organization-id'] as string,
          store.scopeStore.getScope()['project-id'] as string,
          store.scopeStore.getScope()['environment-id'] as string
        )
      })
    }
  }, [activeTab, store.breadcrumbStore.getBreadcrumbsOptions()])

  const resetStates = () => {
    setJobFormData(defaultFormData)
  }
  const resetCheckNameValidation = () => {
    setIsInvalidJobSlug(false)
    setIsInvalidJobName(false)
  }

  const cancelAdd = () => {
    resetCheckNameValidation()
    setAddPopupOpen(false)
    resetStates()
  }

  const handleFormDataChange = (field: string, value: string) => {
    resetCheckNameValidation()
    setJobFormData((prevData: any) => {
      const updatedData = { ...prevData, [field]: value }

      if (
        field === 'name' &&
        prevData.slug ===
          prevData.name
            .toLowerCase()
            .replace(/-/g, '')
            .replace(/_/g, '-')
            .replace(/[^a-z0-9\s-]/g, '')
            .replace(/\s+/g, '-')
      ) {
        updatedData.slug = value
          .toLowerCase()
          .replace(/-/g, '')
          .replace(/_/g, '-')
          .replace(/[^a-z0-9\s-]/g, '')
          .replace(/\s+/g, '-')
      }

      return updatedData
    })
  }

  const handleAddMonitorSubmit = () => {
    setButtonLoading(true)
    const nameCheckPromises = []

    if (!isEmpty(jobFormData.name)) {
      nameCheckPromises.push(
        checkNameAvailabilityFromServer(jobFormData.name.trim())
      )
    }
    if (!isEmpty(jobFormData.slug)) {
      nameCheckPromises.push(
        checkNameAvailabilityFromServer(jobFormData.slug.trim(), true)
      )
    }
    if (nameCheckPromises.length > 0) {
      Promise.allSettled(nameCheckPromises)
        .then((results) => {
          let success = true
          results.forEach((result, index) => {
            if (result.status !== 'fulfilled') {
              success = false
              if (!isEmpty(jobFormData.name) && index === 0) {
                if (result.reason === StringConstants.NAME_ALREADY_TAKEN) {
                  setIsInvalidJobName(true)
                } else {
                  ToastNotification({
                    type: 'error',
                    message: ToastMessageConstants.SOMETHING_WENT_WRONG
                  })
                }
              } else {
                if (result.reason === StringConstants.SLUG_ALREADY_TAKEN) {
                  setIsInvalidJobSlug(true)
                } else {
                  ToastNotification({
                    type: 'error',
                    message: ToastMessageConstants.SOMETHING_WENT_WRONG
                  })
                }
              }
            }
          })
          if (success) {
            addJob()
          } else {
            setButtonLoading(false)
          }
        })
        .catch((err) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.SOMETHING_WENT_WRONG
          })
          setButtonLoading(false)
        })
    } else {
      addJob()
    }
  }

  const addJob = () => {
    setAddPopupOpen(false)
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    configureJob(jobFormData)
      .then(() => {
        setActiveTab('1')
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.JOB_MONITORING.CREATE_JOB.SUCCESS
        })
        getOrganizationProjectsThingsToDo()
          .then((response: any) => {
            store.thingsToDoStore.setProgressData(response.data)
            if (
              (response?.data?.progress == 3 &&
                (store.scopeStore.getSelectedProject().type ==
                  ProjectType.SALESFORCE_ONLY ||
                  store.scopeStore.getSelectedProject().type ==
                    ProjectType.MULESOFT_ONLY)) ||
              (response?.data?.progress == 5 &&
                store.scopeStore.getSelectedProject().type ==
                  ProjectType.FULL_STACK_ONLY)
            ) {
              store.thingsToDoStore.setShowThingsToDo(false)
            } else {
              store.thingsToDoStore.setShowThingsToDo(true)
            }
          })
          .catch((error) => console.log(error))
          .finally(() => {
            store.uiStore.setGlobalLoader(false)
          })
        resetCheckNameValidation()
        resetStates()
        findAllJobs(1, queryParams)
      })
      .catch((err) => {
        setButtonLoading(false)
        setLoading(false)
        store.uiStore.setGlobalLoader(false)

        const quotaErrorRegex =
          /Job quota in the organization \(id\)=\([0-9a-fA-F-]{36}\) is full\. Subscribe to get more jobs\./
        if (quotaErrorRegex.test(err)) {
          ToastNotification({
            type: 'error',
            message:
              ToastMessageConstants.JOB_MONITORING.CREATE_JOB.ERROR
                .LIMIT_EXCEEDED
          })
        } else {
          ToastNotification({
            type: 'error',
            message:
              ToastMessageConstants.JOB_MONITORING.CREATE_JOB.ERROR.DEFAULT
          })
        }

        resetStates()
      })
      .finally(() => {
        resetStates()
        setButtonLoading(false)
      })
  }

  const handleRefresh = () => {
    findJobsForTab()
  }

  const cancelImport = () => {
    setCopyPopupOpen(false)
  }

  const handleEnvironmentSelect = (value: string) => {
    setEnvSelect(value)
  }
  return (
    <div className='flex flex-col w-full'>
      <div className='relative w-full flex justify-between'>
        <div className='tab-child flex w-full'>
          <Tabs
            tabOptionsStyle={{
              minWidth: 'fit-content',
              width: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '8px 12px',
              marginRight: '8px'
            }}
            disabled={store.uiStore.getGlobalLoader() || loading}
            activeTab={activeTab}
            tabOptions={tabs}
            onChange={(active) => {
              if (activeTab !== active) {
                store.uiStore.setGlobalLoader(true)
                setSearchParams({
                  page: '1',
                  tab: active ?? '1'
                })
                setActiveTab(active)
                store.filterStore.setFiltersApplied(false)
              }
            }}
            id='tabs-uc-summary'
          />
        </div>
        <div className='absolute flex right-[0px] items-center justify-end gap-[8px]'>
          <Tooltip id='refresh-tooltip' label='Refresh' position='top'>
            <Button
              id='refresh'
              type='neutral'
              disabled={store.uiStore.getGlobalLoader()}
              startIcon={
                <Renew size={16} color={pallete.colors.neuButtonText} />
              }
              onClick={handleRefresh}
              size='small'
            />
          </Tooltip>
          {store.scopeStore.getSelectedProject().type ===
            ProjectType.FULL_STACK_ONLY && (
            <>
              <Button
                id='copy-job-btn'
                type='outline'
                disabled={
                  store.uiStore.getGlobalLoader() ||
                  loading ||
                  store.userStore.getNoActiveSubscription()
                }
                label={JMStringConstants.COPY_JOB_BUTTON}
                size='small'
                onClick={() => setCopyPopupOpen(true)}
              />
              <Button
                id='add-monitor-btn'
                type='filled'
                disabled={
                  store.uiStore.getGlobalLoader() ||
                  loading ||
                  store.userStore.getNoActiveSubscription()
                }
                label={JMStringConstants.ADD_MONITOR_BUTTON}
                size='small'
                onClick={() => setAddPopupOpen(true)}
              />
            </>
          )}
        </div>
      </div>
      <ImportJobPopup
        open={copyPopupOpen}
        setOpen={setCopyPopupOpen}
        selectedEnvironment={envSelect}
        loading={loading}
        buttonLoading={buttonLoading}
        onCancel={cancelImport}
        onImport={importJobs}
        handleEnvironmentSelect={handleEnvironmentSelect}
      />
      <AddEditJobPopUp
        open={addPopupOpen}
        isAdd
        setOpen={setAddPopupOpen}
        loading={loading}
        onSubmit={handleAddMonitorSubmit}
        onCancel={cancelAdd}
        buttonLoading={buttonLoading}
        buttonDisabled={isButtonDisabled}
        values={jobFormData}
        isInvalidJobName={isInvalidJobName}
        isInvalidJobSlug={isInvalidJobSlug}
        handleInputChange={handleFormDataChange}
      />
    </div>
  )
}

export default observer(JMDashboard)
