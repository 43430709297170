import { DDSModal, DDSTypography } from 'den-design-system'

import { Close } from '@carbon/icons-react'
import React from 'react'
import pallete from '../../../../global/newPallete'

interface ModalProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onClosePress: () => void
  maskClosable?: boolean
  primaryButtonText?: string
  secondaryButtonText?: string
  onPrimaryButtonPress?: () => void
  onSecondaryButtonPress?: () => void
  secondaryButtonSuffixIcon?: React.ReactNode
  primaryButtonSuffixIcon?: React.ReactNode
  secondaryButtonPrefixIcon?: React.ReactNode
  primaryButtonPrefixIcon?: React.ReactNode
  primaryButtonprops?: any
  secondaryButtonprops?: any
  style?: React.CSSProperties
  className?: string
  showHeader: boolean
  hideCloseIcon?: boolean
  title?: string
  children?: React.ReactNode
  footer?: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({
  open,
  setOpen,
  onClosePress,
  maskClosable = false,
  primaryButtonText = 'Yes',
  secondaryButtonText = 'No',
  hideCloseIcon = true,
  onPrimaryButtonPress,
  onSecondaryButtonPress,

  secondaryButtonPrefixIcon,
  secondaryButtonSuffixIcon,
  primaryButtonPrefixIcon,
  primaryButtonSuffixIcon,
  primaryButtonprops,
  secondaryButtonprops,
  style,
  className,
  children,
  showHeader,
  title = '',
  footer
}) => {
  return (
    <div className='override-modal-header-border'>
      <DDSModal
        open={open}
        setOpen={setOpen}
        footer={footer}
        hideCloseIcon={hideCloseIcon}
        onIconClose={onClosePress}
        maskClosable={maskClosable}
        primaryButtonText={primaryButtonText}
        secondaryButtonText={secondaryButtonText}
        primaryButtonProps={primaryButtonprops}
        secondaryButtonProps={secondaryButtonprops}
        onOk={onPrimaryButtonPress}
        onCancel={onSecondaryButtonPress}
        secondaryButtonPrefixIcon={secondaryButtonPrefixIcon}
        secondaryButtonSuffixIcon={secondaryButtonSuffixIcon}
        primaryButtonPrefixIcon={primaryButtonPrefixIcon}
        primaryButtonSuffixIcon={primaryButtonSuffixIcon}
        style={style}
        defaultFooter={false}
        className={className}
      >
        {/* Header Content */}
        {showHeader && (
          <div
            className='flex justify-between items-center px-[24px] py-[16px] border-b'
            style={{ borderBottomColor: pallete.colors.stroke2 }}
          >
            <DDSTypography.Title
              variant='bold'
              type='h4'
              className='!leading-6'
              color={pallete.colors.textDark4}
            >
              {title}
            </DDSTypography.Title>
            {/* <Close
            size={16}
            className='cursor-pointer'
            color={pallete.colors.textDark4}
            onClick={onClosePress}
          /> */}
          </div>
        )}
        {/*Body Content*/}
        {children}
      </DDSModal>
    </div>
  )
}

export default Modal
