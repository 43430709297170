import { Grid, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { SalesforceAuditStore } from '../../store/SalesforceAuditStore'
import Table from '../../components/common/Table/Table'
import { TypographyDDS } from '../../components/common/Typography/TypographyDDS'
import UsageCard from '../../components/SalesforceAudit/common/UsageCard'
import arrowLeftIcon from '../../assets/icons/leftArrow.svg'
import arrowRightIcon from '../../assets/icons/arrowRight.svg'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import { paginate } from '../../utils/CommonUtils'
import palette from '../../global/pallete'
import styled from '@emotion/styled'
import { timeFormats } from '../../enum/TIME'
import { useStoreContext } from '../../store/StoreContext'

const TitleWrapper = styled.div`
  color: ${palette.colors.lightGrey2};
`

const PermissionSetsOverview: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { salesforceAuditStore } = useStoreContext()
  const [currentPage, setCurrentPage] = useState(1)
  const data = salesforceAuditStore.getPermissionSetsData()
  const columns = [
    {
      title: <TitleWrapper>Permission Set Name</TitleWrapper>,
      label: 'Name',
      render: (item: any) => item
    },
    {
      title: <TitleWrapper>Users Assigned</TitleWrapper>,
      label: 'Id',
      render: (item: any) =>
        salesforceAuditStore.getUsersAssignedForPermissionSetLengthById(item)
    },
    {
      title: <TitleWrapper>Description</TitleWrapper>,
      label: 'Description',
      render: (item: any) => item
    },
    {
      title: <TitleWrapper>Last Modified</TitleWrapper>,
      label: 'LastModifiedDate',
      render: (item: any) =>
        moment(item).format(timeFormats.fullMonthDateYearTime)
    },
    {
      title: <TitleWrapper>Modified by</TitleWrapper>,
      label: 'LastModifiedById',
      render: (item: any) => salesforceAuditStore.getNameById(item) || item
    },
    {
      title: '',
      label: 'Id',
      render: (item: any) => (
        <Stack
          direction='row'
          alignItems='center'
          style={{ cursor: 'pointer' }}
          spacing={1}
          onClick={() => navigate(item)}
        >
          <span>View</span>
          <img src={arrowRightIcon} style={{ height: '16px' }} />
        </Stack>
      )
    }
  ]
  const handleGoBack = () => {
    const segments = location.pathname.split('/').filter(Boolean)
    segments.pop()
    const newPath = '/' + segments.join('/')
    navigate(newPath)
  }
  return (
    <Grid container width='80%' paddingTop='16px' spacing={2}>
      <Grid item xs={12}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          style={{ cursor: 'pointer' }}
          onClick={() => handleGoBack()}
        >
          <img src={arrowLeftIcon} style={{ height: '16px' }} />
          <TypographyDDS.Paragraph size='para' color='textDark'>
            Go Back
          </TypographyDDS.Paragraph>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <TypographyDDS.Title type='h4' variant='bold'>
          Permission Sets
        </TypographyDDS.Title>
      </Grid>
      <Grid item xs={12}>
        <UsageCard>
          <Stack>
            <TypographyDDS.Paragraph size='para' color='textDark'>
              Total Permission Sets
            </TypographyDDS.Paragraph>
            <TypographyDDS.Title type='h1' variant='bold'>
              {data.length}
            </TypographyDDS.Title>
          </Stack>
          <Table
            columns={columns}
            data={paginate(data, currentPage, SalesforceAuditStore.pageSize)}
            totalCount={data.length}
            currentPage={currentPage}
            rowsPerPage={SalesforceAuditStore.pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </UsageCard>
      </Grid>
    </Grid>
  )
}

export default observer(PermissionSetsOverview)
