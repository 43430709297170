import { Grid, Stack } from '@mui/material'

import React from 'react'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import UsageBar from './UsageBar'
import UsageCard from './common/UsageCard'
import palette from '../../global/pallete'

interface LicenseUsageCardProps {
  usage: number
  totalLicenses: number
  usedLicenses: number
  availableLicenses: number
}
const LicenseUsageCard: React.FC<LicenseUsageCardProps> = ({
  usage,
  totalLicenses,
  usedLicenses,
  availableLicenses
}) => {
  return (
    <UsageCard>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid item xs={2.4}>
          <Stack spacing={1}>
            <TypographyDDS.Title type='h5'>Usage</TypographyDDS.Title>
            <TypographyDDS.Title type='h4' variant='bold' color='primary'>
              {usage}%
            </TypographyDDS.Title>
            <UsageBar usage={usage} />
          </Stack>
        </Grid>
        <Grid item xs='auto'>
          <Stack>
            <TypographyDDS.Paragraph
              size='para'
              variant='semiBold'
              color='darkGrey'
            >
              Total Licenses
            </TypographyDDS.Paragraph>
            <TypographyDDS.Title
              type='h4'
              variant='bold'
              style={{ whiteSpace: 'nowrap' }}
            >
              {totalLicenses}
            </TypographyDDS.Title>
          </Stack>
        </Grid>
        <Grid item xs='auto'>
          <Stack>
            <TypographyDDS.Paragraph
              size='para'
              variant='semiBold'
              color='darkGrey'
            >
              Used Licenses
            </TypographyDDS.Paragraph>
            <TypographyDDS.Title
              type='h4'
              variant='bold'
              style={{ whiteSpace: 'nowrap' }}
            >
              {usedLicenses}
            </TypographyDDS.Title>
          </Stack>
        </Grid>
        <Grid item xs='auto'>
          <Stack>
            <TypographyDDS.Paragraph
              size='para'
              variant='semiBold'
              color='darkGrey'
            >
              Available Licenses
            </TypographyDDS.Paragraph>
            <TypographyDDS.Title
              type='h4'
              variant='bold'
              style={{ whiteSpace: 'nowrap' }}
            >
              {availableLicenses}
            </TypographyDDS.Title>
          </Stack>
        </Grid>
      </Grid>
    </UsageCard>
  )
}

export default LicenseUsageCard
