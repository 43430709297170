import { Feature } from '../enum/Feature.enum'

export const mapFeatureLabels = (feature: Feature) => {
  switch (feature) {
    case Feature.USER:
      return 'Number of users'
    case Feature.PROJECT:
      return 'Number of projects'
    case Feature.ENVIRONMENT:
      return 'Number of environments'
    case Feature.API_MONITORING:
      return 'API Monitoring'
    case Feature.APPLICATION_HEALTH_CHECK:
      return 'Uptime Checks'
    case Feature.JOB_MONITORING:
      return 'Scheduled Jobs'
    case Feature.EXCEPTION:
      return 'Exceptions'
    case Feature.JOB_MONITORING_LOG:
      return 'Scheduled Job Logs'
    case Feature.APPLICATION_HEALTH_CHECK_LOG:
      return 'Uptime Check Logs'
  }
}

export const getColorOfFeature = (obj: any) => {
  const percentUtilized = (obj.active / obj.maxLimit) * 100
  if (percentUtilized >= 100) {
    return 'red2'
  } else if (percentUtilized >= 80) {
    return 'warning2'
  } else {
    return 'textDark'
  }
}

export const getIsMonthlyLimit = (obj: any) => {
  const featureArray = [Feature.API_MONITORING, Feature.EXCEPTION]
  if (featureArray.includes(obj.feature)) {
    return true
  }
  return false
}
