import React, { useEffect, useState } from 'react'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Button } from '../../../components/New/Button/Button'
import { DDSTypography } from 'den-design-system'
import EscalationSets from '../EscalationsPage/EscalationSets'
import { Renew } from '@carbon/icons-react'
import StringConstants from '../../../constants/StringConstants'
import Tabs from '../../../components/common/DDS/Tabs/Tabs'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { useStoreContext } from '../../../store/StoreContext'

const Escalations = () => {
  const store = useStoreContext()
  const [tableData, setTableData] = useState<any>()
  const [tabIndex, setTabIndex] = useState<string>('1')
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  useEffect(() => {
    const tabFromParams = searchParams.get('tab')
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (tabFromParams) {
      setTabIndex(tabFromParams.toString())
    } else {
      setSearchParams({ ...allQueryParams, tab: '1' })
    }
    if (tabIndex === '1') {
      store.breadcrumbStore.setBreadcrumbsOptions({
        label: 'Escalations',
        link:
          replaceUrl(
            routeConstants.ESCALATION_SETS,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) + '?tab=1'
      })
    }
  }, [tabIndex, store.breadcrumbStore.getBreadcrumbsOptions()])
  const tabOptions = [
    {
      label: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.primary2}
        >
          Escalation Sets
        </DDSTypography.Title>
      ),
      children: (
        <EscalationSets tableData={tableData} setTableData={setTableData} />
      )
    }
  ]
  const routeToEscalationSet = (id: string) => {
    const route = replaceUrl(
      routeConstants.ESCALATION_SET_DETAILS,
      store.scopeStore.getScope()['organization-id'] as string,
      store.scopeStore.getScope()['project-id'] as string,
      store.scopeStore.getScope()['environment-id'] as string
    ).replace(':escalationSetId', id)
    navigate(route)
  }

  return (
    <>
      <div className='flex flex-col'>
        <div className='flex flex-col relative'>
          <div className='w-full'></div>
          <Tabs
            tabOptionsStyle={{
              minWidth: '150px',
              width: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '8px 12px',
              marginRight: '8px'
            }}
            onChange={(index: string) => {
              if (tabIndex != index) {
                setTabIndex(index)
              }
            }}
            tabOptions={tabOptions}
          />
          <div className='flex justify-end items-start absolute right-0 gap-[8px]'>
            <Button
              id='refresh'
              startIcon={
                <Renew size={16} color={pallete.colors.neuButtonText} />
              }
              type='neutral'
              size='small'
              onClick={() => {
                store.exceptionStore.setRefresh(true)
              }}
            />
            {tableData?.length > 0 && (
              <Button
                id='createEsc2'
                label='Create Escalations'
                type='filled'
                size='small'
                onClick={() => routeToEscalationSet('$')}
                disabled={store.userStore.getNoActiveSubscription()}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Escalations)
