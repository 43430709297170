import Chip from '../Chip/Chip'
import { IoIosClose } from 'react-icons/io'
import React from 'react'
import { TypographyDDS } from '../Typography/TypographyDDS'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

const ChipsContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`
const DeleteIconWrapper = styled.div<{ disabled?: boolean }>`
  display: ${(props) => (props.disabled ? 'none' : 'flex')};
  align-items: center;
`
const FilterChip: React.FC<{
  variant?: 'text' | 'chips'
  name?: string
  value?: any
  onDeleteMainChip?: (name: string) => void
  onDeleteSubChip?: (value: string) => void
  disableDeleteChip?: boolean
  textTransform?: 'capitalize' | 'uppercase' | 'none'
  displayOnlyValue?: boolean
}> = ({
  variant = 'text',
  value = '',
  name = '',
  onDeleteMainChip = () => null,
  onDeleteSubChip = () => null,
  disableDeleteChip = false,
  textTransform
}) => {
  const renderChips = () => {
    return (
      <ChipsContainer>
        {name}
        {(value as string[]).map((value) => (
          <Chip
            key={value}
            label={<b>{value}</b>}
            color={palette.colors.textDark2}
            variant='outlined'
            backgroundColor={palette.colors.lightBack}
            deleteIcon={
              <DeleteIconWrapper>
                <IoIosClose />
              </DeleteIconWrapper>
            }
            onDelete={() => onDeleteSubChip(value)}
            size='small'
          />
        ))}
      </ChipsContainer>
    )
  }
  const renderText = (name: string, value: string) => (
    <>
      <TypographyDDS.Paragraph size='caption' variant='semiBold'>
        {name} - <b>{value}</b>
      </TypographyDDS.Paragraph>
    </>
  )

  if (variant === 'text') {
    return (
      <Chip
        label={renderText(name, value)}
        color={palette.colors.textDark2}
        variant='outlined'
        borderRadius='8px'
        backgroundColor={palette.colors.lightBack}
        deleteIcon={
          <DeleteIconWrapper disabled={disableDeleteChip}>
            <IoIosClose />
          </DeleteIconWrapper>
        }
        onDelete={() => onDeleteMainChip(name)}
        textTransform={textTransform}
        style={{ marginRight: 5 }}
      />
    )
  }
  return (
    <Chip
      label={renderChips()}
      color={palette.colors.textDark2}
      variant='outlined'
      borderRadius='8px'
      backgroundColor={palette.colors.lightBack}
      deleteIcon={
        <DeleteIconWrapper disabled={disableDeleteChip}>
          <IoIosClose />
        </DeleteIconWrapper>
      }
      onDelete={() => onDeleteMainChip(name)}
      textTransform={textTransform}
      style={{ marginRight: 5 }}
    />
  )
}

export default FilterChip
