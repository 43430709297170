import { GatewayEventConstants } from '../constants/GatewayEventConstants'
import { io } from 'socket.io-client'

export const socket = io(process.env.REACT_APP_API_ENDPOINT as string)

/**
 * Event Listener which listens to the event- IN_APP_NOTIFICATION and logs the unread notification count
 */
socket.on(
  GatewayEventConstants.IN_APP_NOTIFICATION,
  (notificationCount: number) => {
    console.log('No. of unread notifications = ', notificationCount)
  }
)

/**
 * Function which triggers the DISCONNECTION event in the server.
 * @param userId - user id of the user who is going to leave the socket room
 */
export const leaveRoom = (userId: string) => {
  socket.emit(GatewayEventConstants.DISCONNECTION, userId)
}

/**
 * Function which triggers the CONNECTION event in the server.
 * @param userId - user id of the user who joins the room.
 */
export const joinUserToRoom = (userId: string) => {
  socket.emit(GatewayEventConstants.CONNECTION, userId)
  socket.on(GatewayEventConstants.CONNECTION, (response) => {
    console.log(response)
  })
}
