import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import UnsecureComponent from '../../components/Layout/UnsecureComponent'
import { getHealthCheckBadge } from '../../api/appHealthCheck/AHC.service'

const AHCBadgeView: React.FC = () => {
  const [badge, setBadge] = useState<any>()
  const { encryptedId } = useParams()
  useEffect(() => {
    getHealthCheckBadge({ $encryptedId: encryptedId as string })
      .then((response: any) => {
        setBadge(response)
      })
      .catch((err) => console.log(err))
  }, [])

  return <div dangerouslySetInnerHTML={{ __html: badge }}></div>
}

export default UnsecureComponent(AHCBadgeView)
