import React, { useState } from 'react'

import { Copy } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import { copyJsonToClipboard } from '../../utils/CommonUtils/CopyUtils'
import { observer } from 'mobx-react-lite'
import pallete from '../../global/newPallete'
import { useStoreContext } from '../../store/StoreContext'

export interface APIRequestResponseCardData {
  id: string
  description: any
  bodyText: string
  copyText: any
}

const APIRequestResponseCard: React.FC<APIRequestResponseCardData> = ({
  id,
  description,
  bodyText,
  copyText
}) => {
  const [copy, setCopy] = useState<string | null>(null)
  const store = useStoreContext()

  return (
    <>
      <div className='api-req-res-card  h-full w-[49%]'>
        <div className=' px-[20px] py-[16px] h-full border rounded flex flex-col overflow-hidden '>
          <div className='flex justify-between items-center mb-4'>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {bodyText}
            </DDSTypography.Paragraph>
            <div
              className={` ${store.userStore.getNoActiveSubscription() && 'cursor-not-allowed pointer-events-none opacity-50'} ml-auto cursor-pointer`}
              onClick={() => {
                copyJsonToClipboard(copyText, id, 2000, setCopy)
              }}
            >
              {copy === id ? (
                <span role='img' aria-label='copied'>
                  ✅
                </span>
              ) : (
                <Copy color={pallete.colors.neuButtonText} size={16} />
              )}
            </div>
          </div>

          <div className=' flex-1 overflow-auto '>
            <div
              className=' relative h-full'
              style={{
                overflowWrap: 'break-word',
                backgroundColor: pallete.colors.surface6,
                borderColor: pallete.colors.stroke2,
                borderRadius: '6px'
              }}
            >
              <div className='max-h-[100%] overflow-y-auto '>
                <DDSTypography.Paragraph
                  size='para'
                  variant='regular'
                  color={pallete.colors.textDark5}
                  className='py-[16px] px-[16px]'
                >
                  {description}
                </DDSTypography.Paragraph>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(APIRequestResponseCard)
