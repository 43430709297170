import { Box, Grid, Stack } from '@mui/material'
import React, { useState } from 'react'

import LicenseUsageCard from '../../components/SalesforceAudit/LicenseDistributionCard'
import { SalesforceAuditStore } from '../../store/SalesforceAuditStore'
import Table from '../../components/common/Table/Table'
import { TypographyDDS } from '../../components/common/Typography/TypographyDDS'
import UsageBar from '../../components/SalesforceAudit/UsageBar'
import UsageCard from '../../components/SalesforceAudit/common/UsageCard'
import arrowLeftIcon from '../../assets/icons/leftArrow.svg'
import arrowRightIcon from '../../assets/icons/arrowRight.svg'
import { observer } from 'mobx-react-lite'
import { paginate } from '../../utils/CommonUtils'
import palette from '../../global/pallete'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../store/StoreContext'

const TitleWrapper = styled.div`
  color: ${palette.colors.lightGrey2};
`
const LicensesOverview: React.FC = () => {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const { salesforceAuditStore } = useStoreContext()
  const totalLicenses = salesforceAuditStore.getTotalLicenses()
  const usedLicenses = salesforceAuditStore.getUsedLicenses()
  const mostCommonLicense = salesforceAuditStore.getMostCommonLicense()
  const distributions = salesforceAuditStore.getLicenseDistribution()
  const data = salesforceAuditStore.getLicenses()
  const handleGoBack = () => {
    const segments = location.pathname.split('/').filter(Boolean)
    segments.pop()
    const newPath = '/' + segments.join('/')
    navigate(newPath)
  }
  const columns = [
    {
      title: <TitleWrapper>License Type</TitleWrapper>,
      label: 'Name',
      render: (item: any) => item
    },
    {
      title: <TitleWrapper>Total</TitleWrapper>,
      label: 'TotalLicenses',
      render: (item: any) => item
    },
    {
      title: <TitleWrapper>Used</TitleWrapper>,
      label: 'UsedLicenses',
      render: (item: any) => item
    },
    {
      title: <TitleWrapper>Available</TitleWrapper>,
      label: 'Id',
      render: (item: any) => {
        const license = salesforceAuditStore.getLicenseById(item)
        const available = license.TotalLicenses - license.UsedLicenses
        return available >= 0 ? available : 0
      }
    },
    {
      title: <TitleWrapper>Usage</TitleWrapper>,
      label: 'Id',
      render: (item: any) => {
        const license = salesforceAuditStore.getLicenseById(item)
        const usage =
          license.TotalLicenses > 0
            ? +((license.UsedLicenses / license.TotalLicenses) * 100).toFixed(2)
            : 0
        return (
          <Stack
            direction='row'
            minWidth='200px'
            alignItems='center'
            justifyContent='center'
            spacing={1}
          >
            <Box width='150px'>
              <UsageBar usage={usage} />
            </Box>
            <Box width='50px'>
              <TypographyDDS.Title type='h5' variant='medium' color='primary'>
                {usage}%
              </TypographyDDS.Title>
            </Box>
          </Stack>
        )
      }
    },
    {
      title: '',
      label: 'Id',
      render: (item: any) => (
        <Stack
          direction='row'
          alignItems='center'
          style={{ cursor: 'pointer' }}
          spacing={1}
          onClick={() => navigate(item)}
        >
          <span>View</span>
          <img src={arrowRightIcon} style={{ height: '16px' }} />
        </Stack>
      )
    }
  ]
  return (
    <Grid container width='80%' paddingTop='16px' spacing={2}>
      <Grid item xs={12}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          style={{ cursor: 'pointer' }}
          onClick={() => handleGoBack()}
        >
          <img src={arrowLeftIcon} style={{ height: '16px' }} />
          <TypographyDDS.Paragraph size='para' color='textDark'>
            Go Back
          </TypographyDDS.Paragraph>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <LicenseUsageCard
          usage={(usedLicenses / totalLicenses) * 100}
          mostCommonLicense={mostCommonLicense?.Name || ''}
          totalLicenses={totalLicenses}
          usedLicenses={usedLicenses}
          distributions={distributions}
        />
      </Grid>
      <Grid item xs={12}>
        <TypographyDDS.Title type='h3'>Licenses</TypographyDDS.Title>
      </Grid>
      <Grid item xs={12}>
        <UsageCard>
          <Table
            columns={columns}
            data={paginate(data, currentPage, SalesforceAuditStore.pageSize)}
            totalCount={data.length}
            currentPage={currentPage}
            rowsPerPage={SalesforceAuditStore.pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </UsageCard>
      </Grid>
    </Grid>
  )
}

export default observer(LicensesOverview)
