import { Edit, PauseOutline, Play, TrashCan } from '@carbon/icons-react'
import React, { useEffect, useState } from 'react'
import {
  checkNameAvailabilityFromServer,
  schema,
  statusToColor,
  toTitleCase
} from '../../../utils/JobUtils'
import { deleteJob, updateConfiguredJob } from '../../../api/jobs/Jobs.service'
import { isEmpty, isEqual } from 'lodash'

import AddEditJobPopUp from '../JMDashboard/AddEditPopUp'
import { Button } from '../../New/Button/Button'
import { DDSTypography } from 'den-design-system'
import HttpConstants from '../../../constants/HttpConstants'
import JMCard from './JMCard'
import JMDeletePopup from './JMDeletePopup'
import JMHorizontalTable from './JMHorizontalTable'
import JMStringConstants from '../../../constants/JMStringConstants'
import Spinner from '../../common/Spinner/Spinner'
import StringConstants from '../../../constants/StringConstants'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import Tooltip from '../../New/Tooltip/Tooltip'
import { addQueryParamsToUrl } from '../../../utils/UrlGenerator'
import { describeCronExpression } from '../../../utils/CronDescribeUtils'
import { gaEventConstants } from '../../../constants/GAConstants'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import { replaceUrl } from '../../../utils/UrlUtils'
import routeConstants from '../../../constants/RouteConstants'
import { timeFormats } from '../../../enum/TIME'
import { timeZone } from '../../../utils/DateTimeUtils'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

interface overviewProps {
  currentJob: any
  findTheConfiguredJob: () => void
  isLoading: boolean
}
const JMOverview: React.FC<overviewProps> = ({
  currentJob,
  findTheConfiguredJob,
  isLoading
}) => {
  const store = useStoreContext()
  const [deletePopupOpen, setDeletePopupOpen] = useState<boolean>(false)
  const [editPopupOpen, setEditPopupOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(isLoading)
  const [selectedJob, setSelectedJob] = useState<any>(currentJob)
  const [isInvalidJobName, setIsInvalidJobName] = useState(false)
  const [isInvalidJobSlug, setIsInvalidJobSlug] = useState(false)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const [job, setJob] = useState({
    name: '',
    slug: '',
    cronExpression: '',
    description: '',
    monitorJob: true
  })
  const navigate = useNavigate()
  const gaEventTracker = useAnalyticsEventTracker()
  useEffect(() => {
    findTheConfiguredJob()
  }, [])

  useEffect(() => {
    setSelectedJob(currentJob)
  }, [currentJob])

  useEffect(() => {
    if (selectedJob) {
      setJob({
        name: selectedJob.name || '',
        slug: selectedJob.slug || '',
        cronExpression: selectedJob.cronExpression || '',
        description: selectedJob.description || '',
        monitorJob: selectedJob.monitorJob || false
      })
    }
  }, [selectedJob])

  useEffect(() => {
    if (job) {
      const validated = schema.validate(
        {
          name: job.name.trim(),
          slug: job.slug.trim(),
          cronExpression: job.cronExpression,
          description: job.description
        },
        {
          abortEarly: false
        }
      )

      const isFormValid = !validated.error
      const isFormUnchanged =
        selectedJob &&
        isEqual(job.name, selectedJob.name) &&
        isEqual(job.slug, selectedJob.slug) &&
        isEqual(job.cronExpression, selectedJob.cronExpression) &&
        isEqual(
          job.description,
          selectedJob.description ? selectedJob.description : ''
        ) &&
        job.monitorJob === selectedJob.monitorJob

      setDisabled(!isFormValid || isFormUnchanged)
    } else {
      setDisabled(true)
    }
  }, [selectedJob, job, schema])

  useEffect(() => {
    if (!initialLoad) {
      if (job.name === '') {
        setJob((prevJob) => ({ ...prevJob, slug: '' }))
      } else {
        setJob((prevJob) => ({
          ...prevJob,
          slug: prevJob.name
            .toLowerCase()
            .replace(/-/g, '')
            .replace(/_/g, '-')
            .replace(/[^a-z0-9\s-]/g, '')
            .replace(/\s+/g, '-')
        }))
      }
    } else {
      setInitialLoad(false)
    }
  }, [job.name])

  const handleInputChange = (field: string, value: string) => {
    resetCheckNameValidation()
    setJob((prevJob) => ({
      ...prevJob,
      [field]: value
    }))
  }

  const resetCheckNameValidation = () => {
    setIsInvalidJobSlug(false)
    setIsInvalidJobName(false)
  }

  const handleSubmitEditedJob = () => {
    setButtonLoading(true)
    const nameCheckPromises = []

    if (!isEmpty(job.name) && job.name !== selectedJob.name) {
      nameCheckPromises.push(checkNameAvailabilityFromServer(job.name.trim()))
    }
    if (!isEmpty(job.slug) && job.slug !== selectedJob.slug) {
      nameCheckPromises.push(
        checkNameAvailabilityFromServer(job.slug.trim(), true)
      )
    }
    if (nameCheckPromises.length > 0) {
      Promise.allSettled(nameCheckPromises)
        .then((results) => {
          let success = true
          results.forEach((result, index) => {
            if (result.status !== 'fulfilled') {
              success = false
              if (!isEmpty(name) && index === 0) {
                if (result.reason === StringConstants.NAME_ALREADY_TAKEN) {
                  setIsInvalidJobName(true)
                } else {
                  ToastNotification({
                    type: 'error',
                    message: ToastMessageConstants.SOMETHING_WENT_WRONG
                  })
                }
              } else {
                if (result.reason === StringConstants.SLUG_ALREADY_TAKEN) {
                  setIsInvalidJobSlug(true)
                } else {
                  ToastNotification({
                    type: 'error',
                    message: ToastMessageConstants.SOMETHING_WENT_WRONG
                  })
                }
              }
            }
          })
          if (success) {
            if (selectedJob) {
              editJob()
            }
          } else {
            setButtonLoading(false)
          }
        })
        .catch((err) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.SOMETHING_WENT_WRONG
          })
          setButtonLoading(false)
        })
    } else {
      editJob()
    }
  }

  const editJob = () => {
    setEditPopupOpen(false)
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    const payload = {
      name: job.name.trim(),
      slug: job.slug.trim(),
      description: job.description,
      cronExpression: job.cronExpression,
      monitorJob: job.monitorJob,
      $jobId: selectedJob.id as string
    }

    updateConfiguredJob(payload)
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.JOB_MONITORING.UPDATE_JOB.SUCCESS
        })
        resetCheckNameValidation()
        findTheConfiguredJob()
      })
      .catch(() => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.JOB_MONITORING.UPDATE_JOB.ERROR
        })
        setButtonLoading(false)
        setLoading(false)
      })
      .finally(() => {
        setButtonLoading(false)
        setLoading(false)
      })
  }
  const handleMonitorChange = (monitor: boolean) => {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    const payload = {
      monitorJob: monitor,
      $jobId: selectedJob.id as string
    }
    updateConfiguredJob(payload)
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.JOB_MONITORING.UPDATE_JOB.SUCCESS
        })
      })
      .catch((err) => {
        setJob((prevJob) => ({ ...prevJob, monitorJob: !prevJob.monitorJob }))
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.JOB_MONITORING.UPDATE_JOB.ERROR
        })
      })
      .finally(() => {
        findTheConfiguredJob()
        setLoading(false)
      })
  }

  const handleJobDelete = () => {
    setButtonLoading(true)
    deleteJob({
      $jobId: selectedJob.id as string
    })
      .then((response: any) => {
        const url = addQueryParamsToUrl(
          replaceUrl(
            routeConstants.JOB_MONITORING,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ),
          { tab: '1' },
          HttpConstants.GET_METHOD
        )
        store.filterStore.setFiltersApplied(false)
        navigate(url)
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.JOB_MONITORING.DELETE_JOB.SUCCESS
        })
        gaEventTracker(gaEventConstants.DELETE_JOB)
      })
      .catch((error) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.JOB_MONITORING.DELETE_JOB.ERROR
        })
      })
      .finally(() => {
        setDeletePopupOpen(false)
        setButtonLoading(false)
      })
  }

  const tableData = [
    {
      id: '1',
      heading: JMStringConstants.LATEST_CLIENT_VERSION,
      content: selectedJob?.latestClientVersion ?? '-'
    },
    {
      id: '2',
      heading: JMStringConstants.CREATED_BY,
      content: selectedJob?.createdByUser?.firstName ?? '-'
    },
    {
      id: '3',
      heading: JMStringConstants.CREATED_ON,
      content: selectedJob?.createdAt
        ? timeZone(
            store.userStore.getUserPreference().timeZone,
            selectedJob?.createdAt,
            timeFormats.fullMonthDateYearTime
          )
        : '-'
    },
    {
      id: '4',
      heading: JMStringConstants.JOB_DESCRIPTION,
      content: selectedJob?.description ? selectedJob?.description : '-'
    }
  ]

  const cardData = [
    {
      id: '1',
      heading: JMStringConstants.JOB_ID_LABEL,
      content: (
        <div className='max-w-[315px]'>
          <Tooltip id='tooltip-id' label={selectedJob?.id ?? '-'}>
            <DDSTypography.Title
              type='h5'
              variant='medium'
              className='truncate max-w-[315px]'
              color={pallete.colors.textDark3}
            >
              {selectedJob?.id ?? '-'}
            </DDSTypography.Title>
          </Tooltip>
        </div>
      ),
      copyText: selectedJob?.id ?? '-',
      showCopy: true
    },
    {
      id: '2',
      heading: JMStringConstants.JOB_SLUG_LABEL,
      content: selectedJob?.slug ?? '-',
      copyText: selectedJob?.slug ?? '-',
      showCopy: true
    },
    {
      id: '3',
      heading: JMStringConstants.CRON_LABEL,
      secondaryText: describeCronExpression(selectedJob?.cronExpression ?? '-'),
      content: selectedJob?.cronExpression ?? '-',
      showCopy: false
    }
  ]
  const cancelEdit = () => {
    resetCheckNameValidation()
    if (currentJob) {
      setJob({
        name: currentJob.name || '',
        slug: currentJob.slug || '',
        cronExpression: currentJob.cronExpression || '',
        description: currentJob.description || '',
        monitorJob: currentJob.monitorJob || false
      })
    }
    setEditPopupOpen(false)
  }

  const cancelDelete = () => {
    setDeletePopupOpen(false)
  }

  const onStopClick = () => {
    setJob((prevJob) => ({ ...prevJob, monitorJob: false }))
  }
  const onStartClick = () => {
    setJob((prevJob) => ({ ...prevJob, monitorJob: true }))
  }
  return (
    <div className='flex flex-col gap-[32px] pt-[16px] pb-[20px]'>
      {loading ||
      store.uiStore.getGlobalLoader() ||
      !selectedJob ||
      !selectedJob.name ||
      !currentJob ? (
        <Spinner />
      ) : (
        <>
          <div
            className='flex justify-between pb-[16px] border-b'
            style={{ borderColor: pallete.colors.stroke6 }}
          >
            <div className='flex flex-col gap-[8px]'>
              <DDSTypography.Title
                type='h4'
                variant='semiBold'
                color={pallete.colors.textDark3}
              >
                {currentJob.name ?? '-'}
              </DDSTypography.Title>
              <div className='flex gap-[8px] items-center'>
                <div>
                  <DDSTypography.Title
                    type='h5'
                    variant='medium'
                    color={pallete.colors.textDark3}
                  >
                    {JMStringConstants.RECENT_STATUS}:
                  </DDSTypography.Title>
                </div>
                {currentJob.previousRunStatus ? (
                  <div
                    className='flex w-[79px] justify-center rounded-[4px] text-center px-[12px] py-[4px] items-center'
                    style={{
                      backgroundColor: statusToColor(
                        currentJob.previousRunStatus.toUpperCase()
                      )
                    }}
                  >
                    <DDSTypography.Paragraph
                      size='para'
                      variant='medium'
                      className='truncate'
                      color={pallete.colors.surface1}
                    >
                      {toTitleCase(currentJob.previousRunStatus)}
                    </DDSTypography.Paragraph>
                  </div>
                ) : (
                  <div className='flex justify-center text-center px-[12px] py-[4px] items-center'>
                    <DDSTypography.Paragraph
                      size='para'
                      variant='medium'
                      className='truncate'
                      color={pallete.colors.textDark4}
                    >
                      {'-'}
                    </DDSTypography.Paragraph>
                  </div>
                )}
              </div>
            </div>
            <div className='flex items-start self-stretch gap-[8px]'>
              <div className='override-monitor-play'>
                <Button
                  id='stop-monitoring'
                  type='outline'
                  size='small'
                  disabled={store.userStore.getNoActiveSubscription()}
                  label={
                    job.monitorJob
                      ? JMStringConstants.STOP_MONITOR_BUTTON_LABEL
                      : JMStringConstants.START_MONITOR_BUTTON_LABEL
                  }
                  startIcon={job.monitorJob ? <PauseOutline /> : <Play />}
                  onClick={() => {
                    setJob((prevJob) => {
                      const newMonitorJob = !prevJob.monitorJob
                      handleMonitorChange(newMonitorJob)
                      return {
                        ...prevJob,
                        monitorJob: newMonitorJob
                      }
                    })
                  }}
                />
              </div>
              <Button
                id='jm-ovw-del-btn'
                size='small'
                disabled={store.userStore.getNoActiveSubscription()}
                startIcon={<TrashCan />}
                label={JMStringConstants.DELETE_BUTTON}
                type='danger'
                onClick={() => setDeletePopupOpen(true)}
              />
              <Button
                id='jm-ovw-edit-btn'
                size='small'
                startIcon={<Edit />}
                label={JMStringConstants.EDIT_BUTTON}
                type='filled'
                disabled={store.userStore.getNoActiveSubscription()}
                onClick={() => {
                  resetCheckNameValidation()
                  if (currentJob) {
                    setJob({
                      name: currentJob.name || '',
                      slug: currentJob.slug || '',
                      cronExpression: currentJob.cronExpression || '',
                      description: currentJob.description || '',
                      monitorJob: currentJob.monitorJob || false
                    })
                  }
                  setInitialLoad(true)
                  setEditPopupOpen(true)
                }}
              />
            </div>
          </div>
          <div className='grid grid-cols-3 gap-[16px]'>
            {cardData.map((item) => (
              <div className='h-fit' key={item.id}>
                <JMCard
                  id={item.id}
                  heading={item.heading}
                  content={item.content}
                  secondaryText={item.secondaryText}
                  showCopy={item.showCopy}
                  copyText={item.copyText}
                />
              </div>
            ))}
          </div>
          <JMHorizontalTable id='jm-overview-table' tableData={tableData} />
        </>
      )}
      <JMDeletePopup
        open={deletePopupOpen}
        setOpen={setDeletePopupOpen}
        onCancel={cancelDelete}
        onDelete={handleJobDelete}
        buttonLoading={buttonLoading}
      />
      <AddEditJobPopUp
        open={editPopupOpen}
        setOpen={setEditPopupOpen}
        isAdd={false}
        loading={loading}
        onSubmit={handleSubmitEditedJob}
        onCancel={cancelEdit}
        buttonLoading={buttonLoading}
        buttonDisabled={disabled}
        values={{
          name: job.name,
          slug: job.slug,
          cronExpression: job.cronExpression,
          description: job.description,
          monitorJob: job.monitorJob
        }}
        isInvalidJobName={isInvalidJobName}
        isInvalidJobSlug={isInvalidJobSlug}
        handleInputChange={handleInputChange}
        handleMonitorStart={onStartClick}
        handleMonitorStop={onStopClick}
      />
    </div>
  )
}

export default observer(JMOverview)
