import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import BillingTable from './BillingTable/BillingTable'
import { DDSTypography } from 'den-design-system'
import DocumentHeader from '../../../../components/Helmet/DocumentHeader'
import GeneralOrganizationSetting from './Screens/GeneralOrganizationSetting'
import { HeaderConstants } from '../../../../constants/HeaderConstants'
import MemberOrganizationSetting from './Screens/MemberOrganizationSetting/MemberOrganizationSetting'
import Spinner from '../../../../components/common/Spinner/Spinner'
import StringConstants from '../../../../constants/StringConstants'
import Tabs from '../../../../components/common/DDS/Tabs/Tabs'
import { getAllQueryParamsAsObjectFromUrl } from '../../../../utils/UrlUtils'
import { getUserDetails } from '../../../../api/user/User.service'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../global/newPallete'
import routeConstants from '../../../../constants/RouteConstants'
import { useStoreContext } from '../../../../store/StoreContext'

const OrganizationSetting = () => {
  const navigate = useNavigate()
  const store = useStoreContext()
  const [tabIndex, setTabIndex] = useState('1')
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const tabFromUrl = queryParams.get('tab')
    if (tabFromUrl) {
      setTabIndex(tabFromUrl)
    }
  }, [])

  useEffect(() => {
    localStorage.removeItem(StringConstants.ACTIVE_TAB)
  }, [])

  useEffect(() => {
    setLoading(true)
    getUserDetails()
      .then((response: any) => {
        if (response.data.organizations.length === 0) {
          navigate(routeConstants.NO_ORGANIZATION)
        }
        const organizationId = store.scopeStore.getScope()['organization-id']
        const selectedOrganization = response.data.organizations.find(
          (organization: any) => organization.id === organizationId
        )

        if (!selectedOrganization) {
          navigate(routeConstants.UNAUTHORIZED_PAGE)
        }

        store.userStore.setUserRole(selectedOrganization.membership.role)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [navigate, store.scopeStore, store.userStore])

  const handleTabChange = (index: string) => {
    if (index !== tabIndex) {
      setTabIndex(index)
      const queryParams = getAllQueryParamsAsObjectFromUrl(
        window.location.search
      )

      if (queryParams['contextSwitcherTab']) {
        delete queryParams['contextSwitcherTab']
      }

      setSearchParams({
        ...queryParams,
        page: '1',
        tab: index
      })
    }
  }

  const tabs = [
    {
      label: (
        <DDSTypography.Title
          type='h5'
          variant={tabIndex === '1' ? 'semiBold' : 'medium'}
          color={
            tabIndex === '1'
              ? pallete.colors.primary2
              : pallete.colors.textDark5
          }
        >
          General
        </DDSTypography.Title>
      ),
      children: (
        <div>
          {loading ? (
            <Spinner />
          ) : (
            !isEmpty(store.userStore.getSelectedOrganization()) && (
              <GeneralOrganizationSetting
                data={store.userStore.getSelectedOrganization()}
              />
            )
          )}
        </div>
      )
    },
    {
      label: (
        <DDSTypography.Title
          type='h5'
          variant={tabIndex === '2' ? 'semiBold' : 'medium'}
          color={
            tabIndex === '2'
              ? pallete.colors.primary2
              : pallete.colors.textDark5
          }
        >
          Members
        </DDSTypography.Title>
      ),
      children: (
        <div>
          <MemberOrganizationSetting />
        </div>
      )
    },
    {
      label: (
        <DDSTypography.Title
          type='h5'
          variant={tabIndex === '3' ? 'semiBold' : 'medium'}
          color={
            tabIndex === '3'
              ? pallete.colors.primary2
              : pallete.colors.textDark5
          }
        >
          Billing
        </DDSTypography.Title>
      ),
      children: (
        <div>
          <BillingTable />
        </div>
      )
    }
  ]

  return (
    <>
      <DocumentHeader header={HeaderConstants.ORGANIZATION_SETTING} />
      <div className='flex flex-col w-full pt-[8px]'>
        <div className='relative w-full flex justify-between'>
          <div
            style={{
              width: '100%',
              display: 'flex'
            }}
            className='tab-child'
          >
            <Tabs
              tabOptionsStyle={{
                minWidth: 'fit-content',
                width: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px 12px',
                marginRight: '8px'
              }}
              disabled={loading || store.uiStore.getGlobalLoader()}
              tabOptions={tabs}
              id='tabs-grouped-api'
              onChange={handleTabChange}
              activeTab={tabIndex}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(OrganizationSetting)
