import AddButton from './AddButton'
import { DDSTypography } from 'den-design-system'
import OverviewStringConstants from '../../constants/OverviewStringConstants'
import React from 'react'
import RefreshButton from './RefreshButton'
import pallete from '../../global/newPallete'

interface cardProps {
  title: string
  logo: React.ReactNode
  content: React.ReactNode
  setOpen: any
  onRefresh: () => void
}
const OverviewCard: React.FC<cardProps> = ({
  title,
  logo,
  content,
  setOpen,
  onRefresh
}) => {
  return (
    <div
      className='border flex flex-col rounded-[6px]'
      style={{ borderColor: pallete.colors.stroke2 }}
    >
      <div
        className='border-b flex items-center justify-between gap-[10px] py-[16px] px-[24px]'
        style={{ borderColor: pallete.colors.stroke2 }}
      >
        <div className='flex items-center gap-[8px] w-full'>
          <div>{logo}</div>
          <div>
            <DDSTypography.Title
              type='h4'
              variant='semiBold'
              color={pallete.colors.textDark3}
            >
              {title}
            </DDSTypography.Title>
          </div>
        </div>
        <div className='flex gap-[10px]'>
          {(title === OverviewStringConstants.UPTIME_CHECKS_TITLE ||
            title === OverviewStringConstants.JOBS_TITLE) && (
            <AddButton
              title={title}
              onClick={() => {
                if (title === OverviewStringConstants.UPTIME_CHECKS_TITLE) {
                  setOpen()
                } else {
                  setOpen(true)
                }
              }}
            />
          )}
          <RefreshButton
            title={title}
            onClick={() => {
              onRefresh()
            }}
          />
        </div>
      </div>
      <div className='flex flex-col p-[24px]'>{content}</div>
    </div>
  )
}

export default OverviewCard
