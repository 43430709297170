import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState
} from 'react'

import { Close } from '@carbon/icons-react'
import pallete from '../../../global/newPallete'

interface MultiEmailInputProps {
  width?: string
  value: string[]
  onChange: (e: { target: { value: string[] } }) => void
  onBlur: () => void
  validateInput?: boolean
  labelPosition?: 'top' | 'left'
  placeholder?: string
  label?: React.ReactNode
  disabled?: boolean
  validationRegex?: RegExp
  validate?: (value: string) => { error: boolean; message: string }
  id?: string
  name?: string
  onUpdate: () => void
}

const AdditionalEmailInput: React.FC<MultiEmailInputProps> = ({
  width = '50%',
  value,
  onChange,
  onBlur,
  validateInput = true,
  labelPosition = 'top',
  placeholder = 'Enter Additional emails',
  label,
  disabled = false,
  validationRegex,
  validate,
  id,
  name,
  onUpdate
}) => {
  const [inputValue, setInputValue] = useState('')
  const [error, setError] = useState<string | null>(null)
  const inputWrapperRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    onUpdate()
  }, [value, onUpdate])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      onUpdate()
      if (
        inputWrapperRef.current &&
        !inputWrapperRef.current.contains(event.target as Node)
      ) {
        if (inputValue.trim() && !error) {
          const newEmails = [...value, inputValue.trim()]
          onChange({ target: { value: newEmails } })
          setInputValue('')
          onBlur()
          onUpdate()
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [inputValue, error, value, onChange, onBlur])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    if (validateInput) {
      validateEmail(e.target.value)
    }
  }

  const validateEmail = (email: string) => {
    if (validate) {
      const result = validate(email)
      setError(result.error ? result.message : null)
    } else if (validationRegex && email.trim() !== '') {
      setError(
        validationRegex.test(email) ? null : 'Please enter a valid email'
      )
    } else {
      setError(null)
    }
  }

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.trim() && !error) {
      e.preventDefault()
      const newEmails = [...value, inputValue.trim()]
      onChange({ target: { value: newEmails } })
      setInputValue('')
      onBlur()
    }
  }

  const removeEmail = (index: number) => {
    const newEmails = value.filter((_, i) => i !== index)
    onChange({ target: { value: newEmails } })
    onBlur()
  }

  return (
    <div ref={inputWrapperRef} style={{ width }}>
      {label && (
        <label
          className={`block cursor-auto text-sm font-medium text-gray-700 ${
            labelPosition === 'top' ? 'mb-[12px]' : 'mr-[8px]'
          }`}
        >
          {label}
        </label>
      )}
      <div
        className={`flex ${
          labelPosition === 'left' ? 'items-center' : 'flex-col'
        }`}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      >
        <input
          type='email'
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          onBlur={onBlur}
          placeholder={placeholder}
          disabled={disabled}
          id={id}
          name={name}
          className='py-[8px] px-[16px] h-[34px]'
          style={{
            width: '100%',
            border: '1px solid',
            cursor: disabled ? 'not-allowed' : 'pointer',
            borderColor: error
              ? pallete.colors.warningRed2
              : pallete.colors.stroke2,
            borderRadius: '6px',
            outline: 'none',
            backgroundColor: 'white',
            color: 'black',
            boxShadow: error ? `0 0 0px ${pallete.colors.warningRed2}` : 'none',
            transition: 'box-shadow 0.2s, border-color 0.2s'
          }}
          onFocus={(e) => {
            e.target.style.boxShadow = `0 0 0px ${pallete.colors.textDark2}`
          }}
        />
        {error && (
          <p
            style={{
              marginTop: '4px',
              fontSize: '12px',
              lineHeight: '16px',
              color: '#d71414'
            }}
          >
            {error}
          </p>
        )}
      </div>
      <div
        className='flex flex-wrap gap-[12px] mt-[16px]'
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      >
        {value.map((email, index) => (
          <div
            key={index}
            className='flex items-center px-[8px] py-[4px]'
            style={{
              backgroundColor: pallete.colors.surface4,
              borderRadius: '100px',
              cursor: disabled ? 'not-allowed' : 'pointer'
            }}
          >
            <span
              className='flex items-center px-[8px] py-[4px]'
              style={{
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '16px',
                color: pallete.colors.textDark4
              }}
            >
              {email}
            </span>
            <button
              onClick={() => removeEmail(index)}
              className='ml-[8px] focus:outline-none'
              disabled={disabled}
            >
              <Close
                size={12}
                color={pallete.colors.textDark4}
                style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
              />
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AdditionalEmailInput
