import React, { useEffect } from 'react'

import { useLocation } from 'react-router-dom'

function PreventBackNavigation() {
  const location = useLocation()

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      event.preventDefault()
      window.history.pushState(null, '', window.location.href)
    }

    window.history.pushState(null, '', window.location.href)
    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [location])

  return null
}

export default PreventBackNavigation
