import { Chip as MuiChip } from '@mui/material'
import React from 'react'
import palette from '../../../global/pallete'

interface ChipProps {
  variant?: 'outlined' | 'filled'
  label: string | React.ReactNode
  highlighted?: boolean
  uppercase?: boolean
  onClick?: () => void
  onDelete?: () => void
  color?: string
  borderColor?: string
  padding?: string
  margin?: string
  fontSize?: string
  borderRadius?: string
  showCloseIcon?: boolean
  backgroundColor?: string
  size?: 'small' | 'medium'
  clickable?: boolean
  disabled?: boolean
  startIcon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  deleteIcon?: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >
  icon?:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined
  textTransform?: 'capitalize' | 'uppercase' | 'none'
  style?: any
}

const Chip: React.FC<ChipProps> = ({
  variant = 'outlined',
  label,
  highlighted = false,
  uppercase = false,
  disabled = false,
  onClick,
  onDelete,
  color = palette.colors.textDark3,
  borderColor,
  padding,
  fontSize,
  showCloseIcon = false,
  borderRadius = '15px',
  backgroundColor = `${palette.colors.white}`,
  deleteIcon,
  startIcon,
  size = 'medium',
  clickable = false,
  icon,
  margin,
  textTransform,
  style = {}
}) => {
  return (
    <MuiChip
      variant={variant}
      label={label}
      avatar={startIcon}
      deleteIcon={deleteIcon}
      size={size}
      sx={{
        '& .MuiChip-label': {
          fontSize: '12px',
          fontWeight: palette.fontWeight.medium,
          lineHeight: '16px',
          width: showCloseIcon ? '80%' : '100%'
        },
        border: highlighted
          ? `2px solid ${palette.colors.primary}`
          : `1px solid ${palette.colors.primary3}`,
        backgroundColor,
        color,
        padding,
        margin,
        borderRadius,
        borderColor,
        textTransform,
        cursor: 'default'
      }}
      onDelete={onDelete}
      onClick={onClick ? onClick : undefined}
      icon={icon}
      clickable={clickable}
      style={{ ...style }}
      disabled={disabled}
    />
  )
}

export default Chip
