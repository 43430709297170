import * as React from 'react'

import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai'
import { Checkbox, IconButton, Pagination, Tooltip } from '@mui/material'

import MuiTable from '@mui/material/Table'
import SearchField from '../InputField/SearchField'
import Spinner from '../Spinner/Spinner'
import StringConstants from '../../../constants/StringConstants'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { TypographyDDS } from '../Typography/TypographyDDS'
import { isUndefined } from 'lodash'
import { observer } from 'mobx-react-lite'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

type cellElementType = React.ReactNode

interface TableProps {
  data: { [key: string]: any } | string[]
  columns: {
    title: cellElementType
    label: string
    minWidth?: string
    align?: 'left' | 'right' | 'center' | 'inherit' | 'justify'
    $sortState?: string | null
    sortable?: boolean
    sortKey?: string
    customStyles?: any
    render: (
      item: cellElementType,
      fullItem: any,
      page: number,
      tableRef: any,
      index: number
    ) => cellElementType
  }[]
  rowsPerPage?: number
  rowHighlightOnHover?: boolean
  selectable?: boolean
  onSelect?: (data: Data[]) => void
  showPagination?: boolean
  onPageChange?: (page: number) => void
  totalCount?: number
  currentPage?: number
  isLoading?: boolean
  showCount?: boolean
  onSort?: (key?: string, state?: string | null) => void
  onSortClear?: (key?: string, state?: string) => void
  searchable?: boolean
  searchPlaceholder?: string
  onSearch?: (query: string) => void
  headerBackground?: string
  dangerouslySetInnerHTMLColumns?: string[]
  noPadding?: boolean
  justifyContent?: string
}

interface StyledTableCellProps {
  minWidth?: string
  $sortState?: boolean
  label?: string
  background?: string
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
`
const SearchWrapper = styled.div`
  align-self: flex-end;
`
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.25em 0;
`
const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop: string) => !prop.startsWith('$')
})<StyledTableCellProps>`
  padding-top: 8px !important;
  padding-bottom: 8px;
  max-width: ${(props) => (props.minWidth ? props.minWidth : '9.375em')};
  border-bottom: 1px solid ${palette.colors.borderColor};
  font-size: 14px;
  font-weight: 600;
  ${({ $sortState }) =>
    !$sortState &&
    ` &:hover {
    .sort-icon {
      visibility: visible;
    }
  }
  .sort-icon {
    visibility: hidden;
  }`}
  ${({ background }) => background && `background:${background}`}
`

const StyledItem = styled(TypographyDDS.Paragraph)<{
  selected?: boolean
  justifyContent: string
}>`
  border-radius: 8px;
  padding: 0.75em 1em 0.75em 1em;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'center'};
  display: flex;
`

const ColumnTitleContainer = styled(TypographyDDS.Paragraph)<{
  label?: string
}>`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.label === 'editButton' || props.label === 'deleteButton'
      ? 'flex-end'
      : props.label !== 'host'
      ? 'center'
      : 'start'};
  gap: 0.6em;
`

interface Data {
  [key: string]: React.ReactNode
}

const Table: React.FC<TableProps> = ({
  columns,
  data,
  rowsPerPage = StringConstants.DEFAULT_TABLE_SIZE,
  rowHighlightOnHover = false,
  selectable = false,
  onSelect = () => null,
  showCount = true,
  showPagination = true,
  onPageChange = () => null,
  totalCount,
  currentPage = 0,
  isLoading = false,
  onSort = () => null,
  onSortClear = () => null,
  searchable = false,
  searchPlaceholder,
  onSearch = () => null,
  headerBackground,
  dangerouslySetInnerHTMLColumns = [],
  noPadding,
  justifyContent
}) => {
  const [page, setPage] = React.useState(0)
  const [selected, setSelected] = React.useState<Data[]>([])
  const paginationCount = !isUndefined(totalCount)
    ? Math.ceil(totalCount / rowsPerPage)
    : Math.ceil(data.length / rowsPerPage)
  const [indexedData, setData] = React.useState<Data[]>([])
  const noData = data.length === 0
  const tableRef = React.useRef<any>()
  const [columnWidth, setColumnWidth] = React.useState<number[]>([])
  const columnRef = React.useCallback((node: any) => {
    if (node !== null) {
      columnWidth.push(node.getBoundingClientRect().width)
    }
  }, [])

  //this function is to get the center of the div to make the spinner at the perfect center for table loading
  //it gets the width of the table and the width of the first element and calculates the the percentage the size of the first element should be to reach 50% of table width
  //first element is considered because the spinner we place goes into the first cell as table body and hence center should be calculated based on that
  const getCenter = () => {
    const percentOfTotal =
      (columnWidth[0] / tableRef?.current?.offsetWidth) * 100
    const percentRemainingToGetHalf = 50 - percentOfTotal
    const centerPercent =
      100 + (percentRemainingToGetHalf / percentOfTotal) * 100
    return centerPercent
  }

  React.useEffect(() => {
    setData(data.map((d: any, index: any) => ({ ...d, index })))
    setSelected([])
  }, [data])

  React.useEffect(() => {
    onSelect(selected)
  }, [selected.length])

  // TODO will be used once forward ref is implemented
  // const getOffset = () => {
  //   return {
  //     page: currentPage,
  //     limit: rowsPerPage,
  //   }
  // }

  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChange(newPage)
    setPage(newPage - 1)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = indexedData
      // const newSelecteds = data
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, data: Data) => {
    const selectedIndex = selected.findIndex((d) => d.index === data.index)
    let newSelected: Data[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, data)
    } else {
      newSelected = selected.filter((d) => d.index !== data.index)
    }
    setSelected(newSelected)
  }
  const getTooltipMessage = (state?: string | null) => {
    if (state === 'ASC') return 'Sort in Descending order'
    else if (state === 'DESC') return 'Clear Sort'
    return 'Sort in Ascending order'
  }
  const isSelected = (index: any) =>
    selected.find((d) => d.index === index) ? true : false
  const numSelected = selected.length
  const rowCount = data.length

  let elementIndex = 0

  const renderContentForDangerouslySetHTMLColumns = (content: string) => (
    <span
      dangerouslySetInnerHTML={{
        __html: content
      }}
    />
  )

  return (
    <Container ref={tableRef}>
      {searchable && (
        <SearchWrapper>
          <SearchField
            placeholder={searchPlaceholder}
            onChange={(e) => onSearch(e.target.value)}
          />
        </SearchWrapper>
      )}
      <TableContainer
        component={'div'}
        style={{ overflow: isLoading ? 'hidden' : 'auto' }}
      >
        <MuiTable sx={{ minHeight: noPadding ? '0px' : '120px' }}>
          <TableHead>
            <TableRow>
              {!isLoading && selectable && (
                <StyledTableCell align='center'>
                  <Checkbox
                    color='primary'
                    onChange={handleSelectAllClick}
                    checked={rowCount > 0 && numSelected === rowCount}
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                  />
                </StyledTableCell>
              )}

              {columns.map((column, index) => (
                <StyledTableCell
                  key={index}
                  align={column.align !== undefined ? column.align : 'center'}
                  $sortState={!!column.$sortState}
                  ref={columnRef}
                  background={headerBackground}
                  // ref={(ref) => (columnRef.current[index] = ref)}
                >
                  <ColumnTitleContainer
                    size='para'
                    variant='semiBold'
                    color='black'
                    label={column.label}
                  >
                    <span>{column.title}</span>
                    {column.sortable && (
                      <Tooltip
                        title={
                          getTooltipMessage(column.$sortState)
                            ? getTooltipMessage(column.$sortState)
                            : ''
                        }
                      >
                        <span>
                          <IconButton
                            size='small'
                            className='sort-icon'
                            disabled={isLoading}
                          >
                            {column.$sortState === 'DESC' ? (
                              <AiOutlineArrowDown
                                color={palette.colors.textDark}
                                onClick={() =>
                                  onSort(column.sortKey, column.$sortState)
                                }
                              />
                            ) : (
                              <AiOutlineArrowUp
                                color={palette.colors.textDark}
                                onClick={() =>
                                  onSort(column.sortKey, column.$sortState)
                                }
                              />
                            )}
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                  </ColumnTitleContainer>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell style={{ padding: '0', border: 'none' }}>
                  <div
                    style={{
                      width: '-webkit-fill-available',
                      justifyContent: 'center'
                    }}
                  >
                    {tableRef && (
                      <Spinner data={!isNaN(getCenter()) ? getCenter() : 0} />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              !noData &&
              indexedData
                //TODO: add slice logic to render table data on selecting number of rows to be rendered
                .map((item, index) => {
                  if (index < rowsPerPage && page === 0) {
                    elementIndex = index
                  } else {
                    elementIndex = page * rowsPerPage + index
                  }
                  const isItemSelected = isSelected(item.index)
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 }
                      }}
                      onClick={(event) =>
                        selectable && handleClick(event, item)
                      }
                      selected={isItemSelected}
                    >
                      {selectable && (
                        <TableCell
                          align='center'
                          style={{
                            borderBottom: `1px solid ${palette.colors.borderColor}`
                          }}
                        >
                          <Checkbox color='primary' checked={isItemSelected} />
                        </TableCell>
                      )}
                      {columns.map((column, key) => (
                        <TableCell
                          key={key}
                          align={
                            column.align !== undefined ? column.align : 'center'
                          }
                          style={{
                            borderBottom: `1px solid ${palette.colors.borderColor}`,
                            padding: '4px 0px 4px 0px',
                            ...column.customStyles,
                            alignItems: 'center'
                          }}
                        >
                          <StyledItem
                            size='para'
                            color='textDark'
                            selected={item.selected as boolean}
                            justifyContent={justifyContent as string}
                          >
                            {column.render(
                              dangerouslySetInnerHTMLColumns.includes(
                                column.label
                              )
                                ? renderContentForDangerouslySetHTMLColumns(
                                    item[column.label] as string
                                  )
                                : item[column.label],
                              item,
                              page,
                              tableRef,
                              index
                            )}
                          </StyledItem>
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {!isLoading && showPagination && !noData && (
        <PaginationWrapper>
          <Pagination
            count={paginationCount}
            color='primary'
            size='small'
            onChange={handleChangePage}
            page={currentPage}
            sx={{
              marginLeft: showCount ? 'auto' : '',
              '& .MuiPaginationItem-root': {
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '16px'
              }
            }}
          />
          {showCount && (
            <div style={{ marginLeft: 'auto' }}>
              <TypographyDDS.Paragraph
                size='para'
                variant='medium'
                color='textDark3'
              >
                Results{' '}
                {Math.min(
                  (currentPage - 1) * rowsPerPage + 1,
                  totalCount as number
                )}{' '}
                - {Math.min(currentPage * rowsPerPage, totalCount as number)} of{' '}
                {totalCount}
              </TypographyDDS.Paragraph>
            </div>
          )}
        </PaginationWrapper>
      )}
    </Container>
  )
}

export default observer(Table)
