import { ChevronRight, Renew } from '@carbon/icons-react'
import { DDSChips, DDSTooltip, DDSTypography } from 'den-design-system'
import React, { useEffect, useRef, useState } from 'react'
import { color, mapStatusCodeToColor } from '../../../utils/ApiStatusColorCode'
import {
  findRecords,
  findRecordsForGroupByUser,
  getFiltersForGroupByUsers
} from '../../../api/apiMonitoring/ApiMonitoring.service'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'
import { isNull, isUndefined } from 'lodash'
import { msToTimeUnits, timeZone } from '../../../utils/DateTimeUtils'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import ApiGroupingStringConstants from '../../../constants/ApiGroupingStringConstants'
import { Button } from '../../New/Button/Button'
import Filter from '../../Filter/Filter'
import { Link } from '@mui/material'
import NoFilterImage from '../../../assets/images/folder-not-found 1.svg'
import NoRecord from '../../common/NoContent/NoRecord'
import NoRecordImage from '../../../assets/images/setting.svg'
import Spinner from '../../common/Spinner/Spinner'
import StatusCodeGraph from '../../common/StatusCodeGraph/StatusCodeGraph'
import StringConstants from '../../../constants/StringConstants'
import Table from '../../common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import Tooltip from '../../New/Tooltip/Tooltip'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { timeFormats } from '../../../enum/TIME'
import { useStoreContext } from '../../../store/StoreContext'

const GroupedUserAPILogDetails = () => {
  const store = useStoreContext()
  const navigate = useNavigate()

  const [tableData, setTableData] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const typographyRef = useRef<HTMLDivElement | null>(null)
  const [showTooltip, setShowTooltip] = useState(false)
  const pageSize = StringConstants.DEFAULT_TABLE_SIZE
  const { apiGroupId, apiUserId } = useParams()
  const [options, setOptions] = useState<any>([])

  const tabFromParams = searchParams.get('apiOverviewTab')

  function fetchAllRecords() {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    findRecordsForGroupByUser({
      ...queryParams,
      'api-group-id': apiGroupId as string,
      users: apiUserId as string,
      size: pageSize
    })
      .then((apiReport: any) => {
        setTableData(apiReport.data)
        setTotalCount(apiReport.totalCount)
        setLoading(false)
        store.filterStore.setFilters(apiReport.filters)
      })
      .catch((err) => {
        setLoading(false)
      })
      .finally(() => {
        store.uiStore.setGlobalLoader(false)
      })
  }

  const allAPIUserFilter = () => {
    store.filterStore.setFilterDisabled(true)
    getFiltersForGroupByUsers({
      $groupId: apiGroupId as string,
      $userId: apiUserId as string
    })
      .then((response: any) => {
        const filteredOptions = response.data.filter(
          (item: any) =>
            item.heading !== 'URL Template' &&
            item.heading !== 'HTTP Method' &&
            item.heading !== 'Users'
        )
        setOptions(filteredOptions)
      })
      .catch((err) => {
        return ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.API_MONITORING.API_MONITORING_FILTERS.ERROR
        })
      })
  }

  useEffect(() => {
    if (!isNull(searchParams.get('response-status-code'))) fetchAllRecords()
    const checkWidth = () => {
      if (typographyRef.current) {
        const width = typographyRef.current.getBoundingClientRect().width
        setShowTooltip(width === 190)
      }
    }
    checkWidth()
    window.addEventListener('resize', checkWidth)
    return () => window.removeEventListener('resize', checkWidth)
  }, [])

  useEffect(() => {
    allAPIUserFilter()
  }, [])

  useEffect(() => {
    const pageFromQueryParams = searchParams.get('page')
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (!isNull(pageFromQueryParams)) {
      setCurrentPage(Number(pageFromQueryParams))
      fetchAllRecords()
    } else {
      setSearchParams({
        ...allQueryParams,
        page: '1',
        'api-group-id': apiGroupId as string,
        users: apiUserId
      })
    }
  }, [searchParams, store.scopeStore.getScope()])

  const routeGroupedDetails = (id: number) => {
    const apiOverviewTabParam =
      tabFromParams === '1' ? 'apiOverviewTab=1' : 'apiOverviewTab=2'

    const route =
      replaceUrl(
        routeConstants.VIEW_ALL_API,
        store.scopeStore.getScope()['organization-id'] as string,
        store.scopeStore.getScope()['project-id'] as string,
        store.scopeStore.getScope()['environment-id'] as string
      ).replace(':id', String(id)) + `?page=1&userTab=1&${apiOverviewTabParam}`

    navigate(route)
  }

  useEffect(() => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (store.apiDetailsStore.getRefresh() === true) {
      findRecords({
        ...allQueryParams,
        size: StringConstants.DEFAULT_TABLE_SIZE
      })
      allAPIUserFilter()
    }
  }, [store.apiDetailsStore.getRefresh()])

  useEffect(() => {
    if (store.filterStore.getFilterDisabled()) {
      store.filterStore.setFilterDisabled(false)
    }
  }, [store.filterStore.getFilterDisabled()])

  useEffect(() => {
    const tabFromParams = searchParams.get('apiOverviewTab')

    if (!isUndefined(tableData?.apiUserName)) {
      store.breadcrumbStore.setMultipleBreadcrumbsOptions([
        {
          label: ApiGroupingStringConstants.GROUPED_API,
          link: `${replaceUrl(
            routeConstants.API_MONITORING,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          )}?page=1&contextSwitcherTab=2`
        },
        {
          label: (
            <span className='pointer-events-none'>
              {tableData?.apiUserName}
            </span>
          ),
          link: `${replaceUrl(
            routeConstants.GROUPED_USER_DETAILS,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ).replace(':id', apiUserId as string)}?tab=1`
        },
        {
          label: tabFromParams === '1' ? 'API Logs' : 'API Overview',
          link:
            replaceUrl(
              routeConstants.GROUPED_USER_DETAILS,
              store.scopeStore.getScope()['organization-id'] as string,
              store.scopeStore.getScope()['project-id'] as string,
              store.scopeStore.getScope()['environment-id'] as string
            ).replace(':id', apiUserId as string) +
            `?tab=${tabFromParams === '1' ? '1' : '2'}`
        },
        {
          label: (
            <span className='pointer-events-none'>
              {tableData?.apiGroupUrlTemplate}
            </span>
          ),
          link: ''
        }
      ])
    }
  }, [
    searchParams,
    store.breadcrumbStore,
    store.scopeStore,
    tableData,
    apiUserId
  ])

  const columns = [
    {
      columnHeaderStyle: { width: '200px' },
      columnDataStyle: { width: '200px' },
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.CLIENT_VERSION}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'clientVersion',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center max-w-[200px]'>
          <div>
            <Tooltip
              id={`tooltip-name-${text}`}
              label={text}
              className={`override-tooltip-arrow ${
                typeof text === 'string' && text.length > 15 ? text : '!hidden'
              }`}
            >
              <div>
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  color={pallete.colors.textDark4}
                  className='tooltip-container sm:block overflow-hidden truncate max-w-[200px]'
                >
                  {text || text === 0 ? text : '-'}
                </DDSTypography.Paragraph>
              </div>
            </Tooltip>
          </div>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row items-center justify-center text-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.RESPONSE_STATUS}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'responseStatusCode',
      render: (text: any) => (
        <div className='flex row justify-center items-center text-center override-statusCode-center override-status-chip'>
          <DDSChips
            label={text}
            type='filled'
            shape='rounded'
            size='small'
            style={{
              backgroundColor: mapStatusCodeToColor(text),
              borderColor: 'transparent',
              height: '24px',
              width: '60px',
              justifyContent: 'center'
            }}
          />
        </div>
      )
    },

    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.START_TIME}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'startTime',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{ justifyContent: 'center' }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {timeZone(
              store.userStore.getUserPreference().timeZone,
              text,
              timeFormats.fullMonthDateYearTimeWithSeconds
            )}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.RESPONSE_TIME}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'responseTimeInMilliseconds',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{ justifyContent: 'center' }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text ? msToTimeUnits(text) : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: { width: '20px' },
      columnDataStyle: { width: '20px' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='cursor-pointer'>
          <ChevronRight
            size={16}
            onClick={() => {
              localStorage.setItem('apiUserName', tableData?.apiUserName)
              routeGroupedDetails(fullItem.id)
            }}
            color={pallete.colors.FillButton}
          />
        </div>
      )
    }
  ]

  return (
    <div className='flex flex-col w-full'>
      <div className='relative pt-[24px]'>
        <div className='flex items-center justify-between'>
          <div>
            <div className='flex justify-start items-start'>
              <DDSTooltip
                id='overview-tooltip'
                position='top'
                shape='roundedRectangle'
                label={
                  <DDSTypography.Paragraph
                    size='para'
                    variant='regular'
                    color={pallete.colors.textDark4}
                  >
                    {tableData?.apiGroupUrlTemplate}
                  </DDSTypography.Paragraph>
                }
                className={`override-tooltip-arrow ${
                  typeof tableData?.apiGroupUrlTemplate === 'string' &&
                  tableData?.apiGroupUrlTemplate.length > 40
                    ? tableData?.apiGroupUrlTemplate
                    : '!hidden'
                }`}
                style={{
                  backgroundColor: pallete.colors.surface1,
                  zIndex: 999999
                }}
              >
                <DDSTypography.Title
                  type='h3'
                  variant='semiBold'
                  color={pallete.colors.textDark3}
                  className='truncate max-w-[600px]'
                >
                  {tableData?.apiGroupUrlTemplate}
                </DDSTypography.Title>
              </DDSTooltip>
            </div>
            <div className='flex justify-start gap-[4px] items-baseline override-version'>
              <DDSTypography.Paragraph
                size='caption'
                variant='medium'
                className='whitespace-nowrap'
                color={pallete.colors.textDark6}
              >
                HTTP Method:
              </DDSTypography.Paragraph>
              <DDSTypography.Paragraph
                size='para'
                variant='semiBold'
                className='whitespace-nowrap'
                color={pallete.colors.textDark3}
              >
                {tableData?.apiGroupHttpMethod}
              </DDSTypography.Paragraph>
            </div>
            <div className='flex justify-start gap-[4px] items-baseline override-version'>
              <DDSTypography.Paragraph
                size='caption'
                variant='medium'
                className='whitespace-nowrap'
                color={pallete.colors.textDark6}
              >
                User:
              </DDSTypography.Paragraph>
              <DDSTypography.Paragraph
                size='para'
                variant='semiBold'
                className='whitespace-nowrap'
                color={pallete.colors.textDark3}
              >
                {tableData?.apiUserName}
              </DDSTypography.Paragraph>
            </div>
          </div>
          <div className='flex flex-col gap-[10px]'>
            <div className='flex items-center justify-end gap-[8px]'>
              {/* Status Code Graph Section (Right Side) */}
              <div className='flex flex-row items-center'>
                <div className='mr-[8px]'>
                  <DDSTypography.Paragraph
                    size='caption'
                    variant='medium'
                    color={pallete.colors.textDark6}
                  >
                    {ApiGroupingStringConstants.STATUS_CODE_GRAPH}:
                  </DDSTypography.Paragraph>
                </div>

                {!loading && totalCount > 0 ? (
                  <StatusCodeGraph
                    tableHeight={''}
                    width='144px'
                    totalCount={totalCount}
                    data={tableData.statusCodeGraph}
                    color={color}
                  />
                ) : (
                  <span className='text-gray-500'>-</span>
                )}
              </div>
              <div className='flex justify-end'>
                <Tooltip id='refresh-tooltip' label='Refresh' position='top'>
                  <Button
                    id='refresh'
                    type='neutral'
                    disabled={loading}
                    startIcon={
                      <Renew
                        size={16}
                        color={pallete.colors.neuButtonText}
                        onClick={fetchAllRecords}
                      />
                    }
                    size='small'
                  />
                </Tooltip>
              </div>
            </div>
            {/* Filter Section (Left Side) */}
            <div className='flex custom-filter'>
              <Filter
                options={options}
                isScrollable={true}
                disabled={loading || store.uiStore.getGlobalLoader()}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='pt-[24px] w-full justify-between relative'>
        {loading || store.uiStore.getGlobalLoader() || !tableData ? (
          <Spinner />
        ) : totalCount > 0 ? (
          <div>
            <Table
              tableStyle={{
                borderColor: pallete.colors.stroke2
              }}
              data={tableData.apiLogs}
              columns={columns}
              totalCount={totalCount}
              rowsPerPage={10}
              currentPage={currentPage}
              onPageChange={(page: number) => {
                setCurrentPage(page)
                const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                  location.search
                )
                setSearchParams({ ...allQueryParams, page: page.toString() })
              }}
            />
          </div>
        ) : (
          <>
            <NoRecord
              imageSrc={
                totalCount === 0 && store.filterStore.getFiltersApplied()
                  ? NoFilterImage
                  : NoRecordImage
              }
              style={{ height: '74vh' }}
              text={
                totalCount === 0 && store.filterStore.getFiltersApplied() ? (
                  <>No results found for applied filter.</>
                ) : (
                  <>
                    Configure API Monitoring.
                    <Link
                      href={StringConstants.FULLSTACK_URL}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ marginLeft: '4px' }}
                    >
                      Learn More
                    </Link>
                  </>
                )
              }
            />
          </>
        )}
      </div>
    </div>
  )
}

export default observer(GroupedUserAPILogDetails)
