import React, { useEffect, useState } from 'react'
import {
  deleteProject,
  getAllProjects
} from '../../../../api/project/Project.service'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../../constants/GAConstants'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../../utils/UrlUtils'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { Button } from '../../../../components/New/Button/Button'
import { DDSTypography } from 'den-design-system'
import DocumentHeader from '../../../../components/Helmet/DocumentHeader'
import Environments from './Screens/Environments'
import GeneralProjectSetting from './Screens/GeneralProjectSetting'
import { HeaderConstants } from '../../../../constants/HeaderConstants'
import Input from '../../../../components/New/Input/Input'
import { OrganizationUserRoles } from '../../../../enum/OrganizationUserRoles.enum'
import ProjectManageMember from './Screens/ProjectManageMember'
import SettingConstants from '../../../../constants/SettingConstants'
import { SettingDeletePopup } from '../SettingPopups/SettingDeletePopup'
import Tabs from '../../../../components/common/DDS/Tabs/Tabs'
import { ToastMessageConstants } from '../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../components/common/DDS/Toast/Toast'
import { TrashCan } from '@carbon/icons-react'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../global/newPallete'
import routeConstants from '../../../../constants/RouteConstants'
import { useAnalyticsEventTracker } from '../../../../utils/GAUtils'
import { useStoreContext } from '../../../../store/StoreContext'

const ViewAllProject = () => {
  const navigate = useNavigate()
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.GENERAL_SETTINGS_CARD_PROJECT
  )
  const { projectId } = useParams()
  const [tabIndex, setTabIndex] = React.useState<string>('1')
  const [deleteProjectPopupOpen, setDeleteProjectPopupOpen] =
    React.useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const [searchParams, setSearchParams] = useSearchParams()
  const store = useStoreContext()

  const handleDeleteProject = () => {
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    deleteProject(projectId as string)
      .then(() => {
        gaEventTracker(gaEventConstants.DELETE_PROJECT)
        setDeleteProjectPopupOpen(false)
        const projects = store.scopeStore
          .getProjects()
          .filter((item) => item.id != projectId)
        store.scopeStore.setProjects(projects)
        if (projectId === store.scopeStore.getSelectedProject()?.id) {
          store.scopeStore.setProjectId(projects[0].id)
        }

        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.PROJECT.DELETE_PROJECT.SUCCESS
        })

        if (projectId === store.scopeStore.getSelectedProject()?.id) {
          store.scopeStore.setProjectId(projects[0])
          store.scopeStore.setSelectedProject(projects[0])

          navigate(routeConstants.REDIRECT)
        } else {
          navigate(
            replaceUrl(
              routeConstants.PROJECT_SETTING,
              store.scopeStore.getScope()['organization-id'] as string,
              store.scopeStore.getScope()['project-id'] as string,
              store.scopeStore.getScope()['environment-id'] as string
            )
          )
        }
        setInputValue('')
      })
      .catch((err: string) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.PROJECT.DELETE_PROJECT.ERROR
        })
        setInputValue('')
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
        setDeleteProjectPopupOpen(false)
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
        setDeleteProjectPopupOpen(false)
      })
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const tabFromUrl = queryParams.get('tab')
    if (tabFromUrl) {
      setTabIndex(tabFromUrl)
    }
  }, [location.search])

  const projectName = store.projectStore.getProjectName()
  const projectRegex = new RegExp(`^${projectName}$`)

  const tabs = [
    {
      label:
        tabIndex === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {SettingConstants.GENERAL}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {SettingConstants.GENERAL}
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <GeneralProjectSetting loading={loading} setLoading={setLoading} />
        </div>
      )
    },
    {
      label:
        tabIndex === '2' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {SettingConstants.MEMBERS}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {SettingConstants.MEMBERS}
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <ProjectManageMember loading={loading} setLoading={setLoading} />
        </div>
      )
    },
    {
      label:
        tabIndex === '3' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {SettingConstants.ENVIRONMENTS}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {SettingConstants.ENVIRONMENTS}
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <Environments loading={loading} setLoading={setLoading} />
        </div>
      )
    }
  ]
  return (
    <>
      <DocumentHeader header={HeaderConstants.PROJECT_SETTINGS} />
      <div className='flex flex-col w-full pt-[8px]'>
        <div className='relative w-full flex justify-between'>
          <div
            style={{
              width: '100%',
              display: 'flex'
            }}
            className='tab-child'
          >
            <Tabs
              tabOptionsStyle={{
                minWidth: 'fit-content',
                width: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px 12px',
                marginRight: '12px'
              }}
              disabled={store.uiStore.getGlobalLoader() || loading}
              tabOptions={tabs}
              id='tabs-grouped-allProject'
              onChange={(index: any) => {
                if (index != tabIndex) {
                  const queryParams = getAllQueryParamsAsObjectFromUrl(
                    location.search
                  )
                  setTabIndex(index.toString())
                  setSearchParams({
                    ...queryParams,
                    tab: index.toString()
                  })
                }
              }}
              activeTab={tabIndex}
            />
          </div>
          <div className='absolute flex right-[0px] items-center justify-end gap-[8px]'>
            <Button
              id='delete-project'
              type='danger'
              startIcon={
                <TrashCan
                  size={16}
                  color={pallete.colors.warningRed2}
                  style={{
                    opacity:
                      loading ||
                      store.uiStore.getGlobalLoader() ||
                      store.userStore.getUserRole() !==
                        OrganizationUserRoles.OWNER ||
                      store.projectStore.getProjects().length === 1 ||
                      store.userStore.getNoActiveSubscription()
                        ? '0.3'
                        : ''
                  }}
                />
              }
              size='small'
              label='Delete Project'
              onClick={() => setDeleteProjectPopupOpen(true)}
              disabled={
                loading ||
                store.uiStore.getGlobalLoader() ||
                store.userStore.getUserRole() !== OrganizationUserRoles.OWNER ||
                store.projectStore.getProjects().length === 1 ||
                store.userStore.getNoActiveSubscription()
              }
            />
          </div>
        </div>
        <SettingDeletePopup
          showHeader
          title='Delete Project'
          modalOpen={deleteProjectPopupOpen}
          setModalOpen={setDeleteProjectPopupOpen}
          primaryButtonText='Yes, Delete it'
          loading={loading}
          primaryButtonDisabled={
            loading ||
            inputValue !== store.projectStore.getProjectName() ||
            store.uiStore.getGlobalLoader()
          }
          secondaryButtonDisabled={loading || store.uiStore.getGlobalLoader()}
          secondaryButtonText='Cancel'
          primaryButtonClick={() => {
            if (inputValue === store.projectStore.getProjectName()) {
              handleDeleteProject()
            }
          }}
          secondaryButtonClick={() => {
            setDeleteProjectPopupOpen(false)
            setInputValue('')
          }}
          showFooter={true}
          content={
            <div className='flex flex-col p-[24px] gap-[24px]'>
              <div>
                <DDSTypography.Title
                  variant='semiBold'
                  type='h4'
                  color={pallete.colors.textDark3}
                >
                  {SettingConstants.DELETE_PROJECT}
                  <DDSTypography.Title
                    variant='semiBold'
                    type='h4'
                    color={pallete.colors.textDark3}
                  >
                    {SettingConstants.TO_PROCEED}
                  </DDSTypography.Title>
                </DDSTypography.Title>

                <DDSTypography.Title
                  variant='medium'
                  type='h5'
                  color={pallete.colors.textDark4}
                  className='mt-[8px]'
                >
                  {SettingConstants.TYPE}
                  <span style={{ color: pallete.colors.textDark2 }}>
                    {''} {store.projectStore.getProjectName()} {''}
                  </span>
                  {SettingConstants.TO_PROCEED_TYPE}
                </DDSTypography.Title>
              </div>
              <Input
                width='100%'
                onChange={(e) => {
                  setInputValue(e.target.value)
                }}
                labelPosition='top'
                validationRegex={projectRegex}
                validateInput={true}
                label={
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark3}
                  >
                    {SettingConstants.TEXT_CONFIRMATION}
                  </DDSTypography.Paragraph>
                }
                id='input'
                name='Input'
                placeholder='Type the project name to confirm deletion'
              />
            </div>
          }
        />
      </div>
    </>
  )
}

export default observer(ViewAllProject)
