import { CheckmarkOutline, Information, Warning } from '@carbon/icons-react'

import { DDSToastNotification } from 'den-design-system'
import React from 'react'
import pallete from '../../../../global/newPallete'

interface ToastProps {
  type: 'success' | 'error' | 'info'
  message: string
}

const ToastNotification = ({ type, message }: ToastProps) => {
  if (type === 'success') {
    DDSToastNotification.success(message, {
      icon: <CheckmarkOutline size={16} color={pallete.colors.warningGreen} />
    })
  } else if (type === 'error') {
    DDSToastNotification.error(message, {
      icon: <Warning size={16} color={pallete.colors.warningRed2} />
    })
  } else if (type === 'info') {
    DDSToastNotification.info(message, {
      icon: <Information size={16} color={pallete.colors.textDark2} />
    })
  }
}

export default ToastNotification
