import { Candidate, CheckData } from '../../types/store.types'

import { RootStore } from '../RootStore'
import { cloneDeep } from 'lodash'
import { makeAutoObservable } from 'mobx'

type Data = CheckData & {
  expandedAlertCandidates?: Candidate[] | null | string[]
}
const initialState: Data = {
  url: '',
  checkName: '',
  checkInterval: 15,
  authUsername: '',
  authPassword: '',
  customHeaders: {
    '': ''
  },
  alertCandidates: [],
  type: 'http',
  checkContent: false,
  checkIntervalUnit: 'mins',
  monitorAppHealthCheck: true
}
export class AddCheckStore {
  data: Data
  loading: boolean
  editMode: boolean
  userCandidate: any
  constructor(private rootStore: RootStore) {
    this.data = initialState
    this.loading = false
    this.editMode = false
    this.userCandidate = {}
    makeAutoObservable(this)
  }

  getUserCandidate() {
    return this.userCandidate
  }

  setUserCandidate(candidate: any) {
    this.userCandidate = candidate
  }

  setData(newData: Partial<Data>) {
    const data = this.rootStore.addCheckStore.data
    if (
      newData.alertCandidates &&
      typeof newData.alertCandidates[0] !== 'string'
    )
      data.expandedAlertCandidates = cloneDeep(newData.alertCandidates)
    else if (
      data.alertCandidates &&
      typeof data.alertCandidates[0] !== 'string'
    ) {
      data.expandedAlertCandidates = cloneDeep(data.alertCandidates)
    }
    this.rootStore.addCheckStore.data.alertCandidates
    this.rootStore.addCheckStore.data = {
      ...data,
      ...newData
    }
  }

  getData() {
    return this.rootStore.addCheckStore.data
  }

  resetData() {
    this.rootStore.addCheckStore.data = initialState
  }

  setLoading(open: boolean) {
    this.rootStore.addCheckStore.loading = open
  }
  getLoading() {
    return this.rootStore.addCheckStore.loading
  }
  setEditMode(editMode: boolean) {
    this.rootStore.addCheckStore.editMode = editMode
  }
  getEditMode() {
    return this.rootStore.addCheckStore.editMode
  }

  reset() {
    this.data = initialState
    this.loading = false
    this.editMode = false
  }
}
