import { BtoA, getAllQueryParamsAsObjectFromUrl } from '../../../utils/UrlUtils'
import React, { useEffect, useState } from 'react'
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import {
  getAllNotifications,
  getNotificationFilters,
  markAllNotificationsAsRead,
  markNotificationAsRead
} from '../../../api/notification/notification.service'
import { isEmpty, isNull } from 'lodash'

import { AccessRoleConstants } from '../../../constants/AccessRoleConstants'
import { Button } from '../../../components/New/Button/Button'
import ContextSwitcherTabs from '../../../components/common/DDS/ContextSwitcherTab/ContextSwitcherTab'
import { DDSTypography } from 'den-design-system'
import NoRecord from '../../../components/common/NoContent/NoRecord'
import NoRecordImage from '../../../assets/images/error 5.svg'
import SecureComponent from '../../../components/Layout/SecureComponent'
import Spinner from '../../../components/common/Spinner/Spinner'
import StringConstants from '../../../constants/StringConstants'
import Table from '../../../components/common/DDS/Table/Table'
import { findAllEnvironments } from '../../../api/environment/Environment.service'
import { observer } from 'mobx-react-lite'
import { paginate } from '../../../utils/CommonUtils'
import pallete from '../../../global/newPallete'
import { redirectFunction } from '../../../utils/NotificationUtils'
import { timeFormats } from '../../../enum/TIME'
import { timeZone } from '../../../utils/DateTimeUtils'
import unreadNotificationIcon from '../../../assets/icons/unreadNotification.svg'
import { useStoreContext } from '../../../store/StoreContext'

const Notifications: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [tabIndex, setTabIndex] = useState<string>('1')
  const [tableHeaderData, setTableHeaderData] = useState<any>([])
  const navigate = useNavigate()
  const store = useStoreContext()
  const [totalCount, setTotalCount] = useState<number>(0)
  const allSearchParams = getAllQueryParamsAsObjectFromUrl(location.search)
  const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
  const [searchParams, setSearchParams] = useSearchParams()
  const [dataTabMap, setDataTabMap] = useState<any>([])
  const [click, setClick] = useState<boolean>(false)
  const [page, setPage] = useState<number>(
    parseInt(searchParams.get('page') as string) || StringConstants.DEFAULT_PAGE
  )
  const pageSize = StringConstants.DEFAULT_TABLE_SIZE
  const [options, setOptions] = React.useState<any>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [disabled, setDisabled] = React.useState<boolean>(false)
  const [filterLoading, setFilterLoading] = React.useState<boolean>(false)

  const renderContentForDangerouslySetHTMLColumns = (content: string) => (
    <span
      dangerouslySetInnerHTML={{
        __html: content
      }}
    />
  )

  const MarkNotificationAsRead = (Notification: any) => {
    if (store.userStore.getSelectedOrganization().projectCount !== 0) {
      environmentSwitch(Notification)
      setLoading(true)
      markNotificationAsRead({ $id: Notification.id }).then(() => {
        {
          Notification.eventName !== 'DELETED' &&
          Notification.eventName !== 'REJECTED' &&
          Notification.eventName !== 'WELCOME'
            ? navigate(
                redirectFunction(Notification, store.uiStore, store.scopeStore)
              )
            : getNotifications()
        }
      })
    }
  }

  const getNotifications = () => {
    setDataTabMap([])
    setLoading(true)
    setFilterLoading(true)
    store.uiStore.setGlobalLoader(true)
    store.filterStore.setFilterDisabled(true)
    getNotificationFilters()
      .then((resp: any) => {
        setOptions(resp.data)
        setFilterLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
      .catch((err: any) => {
        store.uiStore.setGlobalLoader(false)
        setFilterLoading(false)
        console.log(err)
      })
    if (tabIndex === '1') {
      setDisabled(true)
      getAllNotifications({
        ...queryParams,
        size: pageSize
      }).then((allNotifications: any) => {
        if (
          allNotifications.data.length > 0 ||
          store.filterStore.getFiltersApplied()
        ) {
          store.filterStore.setFilterDisabled(false)
        }
        setDataTabMap(allNotifications.data)
        setTotalCount(allNotifications.totalCount)
        setPage(allNotifications.page)
        store.filterStore.setFilters(allNotifications.filters)
        console.log('Loading False')
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
        const unReadNotifications = allNotifications.data.filter(
          (eachNotification: any) => eachNotification['readAt'] === null
        )
        if (unReadNotifications.length !== 0) setDisabled(false)
      })
    } else if (tabIndex === '2') {
      getAllNotifications({
        status: 'READ',
        ...queryParams,
        size: pageSize
      })
        .then((readNotification: any) => {
          if (
            readNotification.data.length > 0 ||
            store.filterStore.getFiltersApplied()
          ) {
            store.filterStore.setFilterDisabled(false)
          }
          setDataTabMap(readNotification.data)
          setTotalCount(readNotification.totalCount)
          setPage(readNotification.page)
          store.filterStore.setFilters(readNotification.filters)
          setLoading(false)
        })
        .catch((err: any) => console.log(err))
    } else {
      setDisabled(true)
      getAllNotifications({
        status: 'UNREAD',
        ...queryParams,
        size: pageSize
      }).then((unreadNotification: any) => {
        if (
          unreadNotification.data.length > 0 ||
          store.filterStore.getFiltersApplied()
        ) {
          store.filterStore.setFilterDisabled(false)
        }
        setDataTabMap(unreadNotification.data)
        setTotalCount(unreadNotification.totalCount)
        setPage(unreadNotification.page)
        store.filterStore.setFilters(unreadNotification.filters)
        setLoading(false)
        if (unreadNotification.totalCount !== 0) {
          setDisabled(false)
        }
      })
    }
    store.uiStore.setGlobalLoader(false)
  }

  const markAllAsRead = () => {
    markAllNotificationsAsRead()
      .then(() => {
        getNotifications()
      })
      .catch((err: any) => console.log(err))
  }

  useEffect(() => {
    setSearchParams(searchParams, { replace: true })
    getNotifications()
  }, [location.search, tabIndex])

  const handleEnvironmentChange = (
    id: string,
    redirectCallback?: () => void
  ) => {
    if (id === store.scopeStore.getSelectedEnvironment().id) {
      return
    }
    store.uiStore.setGlobalLoader(true)
    store.scopeStore.setEnvironmentId(id)

    const environment = store.scopeStore
      .getEnvironments()
      .filter((record: any) => record.id == id)

    store.scopeStore.setSelectedEnvironment(environment[0])
    store.filterStore.setFiltersApplied(false)

    let url = BtoA(
      store.scopeStore.getOrganizationId() +
        '/' +
        store.scopeStore.getScope()['project-id'] +
        '/' +
        id
    )

    url += '/' + window.location.pathname.split('/')[2]

    if (
      ['dashboard', 'report'].includes(window.location.pathname.split('/')[3])
    ) {
      url += '/' + window.location.pathname.split('/')[3]
    }

    const pathName = '../' + '../' + url
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)

    // Perform the navigation
    navigate({
      pathname: pathName,
      search: `?${createSearchParams(queryParams)}`
    })

    if (redirectCallback) {
      redirectCallback()
    }

    store.breadcrumbStore.reset()
  }

  const environmentSwitch = (fullItem: any, redirectCallback?: () => void) => {
    if (
      fullItem?.parentId?.environmentId !== store.scopeStore.getEnvironmentId()
    ) {
      const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
      findAllEnvironments(fullItem?.parentId?.projectId, allQueryParams)
        .then((environments: any) => {
          if (environments.data && environments.data.length > 0) {
            store.scopeStore.setEnvironments(environments.data)
            store.scopeStore.setProjectId(fullItem?.parentId?.projectId)
            const project = store.scopeStore
              .getProjects()
              .filter(
                (record: any) => record.id === fullItem?.parentId?.projectId
              )
            store.scopeStore.setSelectedProject(project[0])
            const matchingEnvironment = environments.data.find(
              (env: any) => fullItem?.parentId?.environmentId === env?.id
            )

            if (matchingEnvironment) {
              if (
                matchingEnvironment.type ===
                StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
              ) {
                localStorage.setItem(
                  StringConstants.ENVIRONMENT_TYPE,
                  StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
                )
              } else {
                localStorage.setItem(
                  StringConstants.ENVIRONMENT_TYPE,
                  StringConstants.ENVIRONMENT_TYPE_VALUES.PROD
                )
              }

              // Pass the redirection callback to handleEnvironmentChange
              handleEnvironmentChange(matchingEnvironment.id, redirectCallback)
            } else {
              console.warn('No matching environment found')
            }
          } else {
            console.warn('No environments available')
          }
        })
        .catch((error) => {
          console.error('Error fetching environments:', error)
        })
    } else {
      if (redirectCallback) {
        redirectCallback()
      }
    }
  }

  const columns = [
    {
      columnHeaderStyle: { width: '50px' },
      columnDataStyle: { width: '50px' },
      heading: '',
      dataLabel: 'readAt',
      render: (item: any, fullItem: any) =>
        item === null && (
          <div className='w-[8px] h-[8px]'>
            <img
              src={unreadNotificationIcon}
              style={{ width: '8px', height: '8px' }}
            />
          </div>
        )
    },
    {
      columnHeaderStyle: { width: '80%' },
      columnDataStyle: { width: '80%' },
      heading: (
        <div>
          <DDSTypography.Paragraph
            size='para'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Messages
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'content',
      render: (item: any, fullItem: any) => (
        <div className='flex justify-start text-start row items-start'>
          {fullItem.readAt !== null ? (
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color='textDark'
            >
              {fullItem.eventName !== 'DELETED' &&
              fullItem.eventName !== 'REJECTED' &&
              fullItem.eventName !== 'WELCOME' ? (
                <div
                  onClick={() => {
                    if (
                      store.userStore.getSelectedOrganization().projectCount !==
                      0
                    ) {
                      environmentSwitch(fullItem, () => {
                        navigate(
                          redirectFunction(
                            fullItem,
                            store.uiStore,
                            store.scopeStore
                          )
                        )
                      })
                    }
                  }}
                >
                  {renderContentForDangerouslySetHTMLColumns(item as string)}
                </div>
              ) : (
                <div>
                  {renderContentForDangerouslySetHTMLColumns(item as string)}
                </div>
              )}
            </DDSTypography.Paragraph>
          ) : (
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color='textDark'
            >
              <div
                onClick={() => {
                  MarkNotificationAsRead(fullItem)
                }}
              >
                {renderContentForDangerouslySetHTMLColumns(item as string)}
              </div>
            </DDSTypography.Paragraph>
          )}
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '20%' },
      columnDataStyle: { width: '20%' },
      heading: '',
      dataLabel: 'createdAt',
      render: (item: any) => (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
          >
            {timeZone(
              store.userStore.getUserPreference().timeZone,
              item,
              timeFormats.monthDateYear
            )}
          </DDSTypography.Paragraph>
        </div>
      )
    }
  ]

  const ContextSwitcherTab = [
    {
      id: '1',
      label: <div className='w-[82px] px-[16px]'>All</div>,
      children: (
        <div className='mt-[24px]'>
          {loading ? (
            <Spinner />
          ) : totalCount === 0 ? (
            <NoRecord
              imageSrc={NoRecordImage}
              text='You’re all caught up'
              style={{ height: '70vh' }}
            />
          ) : (
            <div>
              <Table
                data={dataTabMap}
                loading={loading}
                columns={columns}
                totalCount={totalCount}
                rowsPerPage={10}
                currentPage={currentPage}
                tableStyle={{
                  borderColor: pallete.colors.stroke2
                }}
                onPageChange={(page: number) => {
                  console.log('PAGE:::::::::::', page)
                  const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                    location.search
                  )
                  setSearchParams({ ...allQueryParams, page: page.toString() })
                  setCurrentPage(page)
                }}
                className={`!border-[${pallete.colors.stroke2}] `}
              />
            </div>
          )}
        </div>
      )
    },
    {
      id: '2',
      label: <div className='w-[82px] px-[16px]'>Read</div>,
      children: (
        <div>
          {loading ? (
            <Spinner />
          ) : totalCount === 0 ? (
            <NoRecord
              imageSrc={NoRecordImage}
              text='You’re all caught up'
              style={{ height: '70vh' }}
            />
          ) : (
            <div>
              <Table
                data={dataTabMap}
                columns={columns}
                totalCount={totalCount}
                rowsPerPage={10}
                currentPage={currentPage}
                tableStyle={{
                  borderColor: pallete.colors.stroke2
                }}
                onPageChange={(page: number) => {
                  const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                    location.search
                  )
                  setSearchParams({ ...allQueryParams, page: page.toString() })
                  setCurrentPage(page)
                }}
                className={`!border-[${pallete.colors.stroke2}] `}
                loading={loading}
              />
            </div>
          )}
        </div>
      )
    },
    {
      id: '3',
      label: <div className='w-[82px] px-[16px]'>Unread</div>,
      children: (
        <div>
          {loading ? (
            <Spinner />
          ) : totalCount === 0 ? (
            <NoRecord
              imageSrc={NoRecordImage}
              text='You’re all caught up'
              style={{ height: '70vh' }}
            />
          ) : (
            <div>
              <Table
                data={dataTabMap}
                columns={columns}
                totalCount={totalCount}
                rowsPerPage={10}
                currentPage={currentPage}
                loading={loading}
                tableStyle={{
                  borderColor: pallete.colors.stroke2
                }}
                onPageChange={(page: number) => {
                  const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                    location.search
                  )
                  setSearchParams({ ...allQueryParams, page: page.toString() })
                  setCurrentPage(page)
                }}
                className={`!border-[${pallete.colors.stroke2}] `}
              />
            </div>
          )}
        </div>
      )
    }
  ]

  return (
    <div className='flex justify-between items-center w-full py-[24px]'>
      <div className='flex-1'>
        <ContextSwitcherTabs
          tabOptions={ContextSwitcherTab}
          shape='roundedRectangle'
          tabStyle={{
            border: '1px solid',
            borderColor: pallete.colors.stroke2,
            userSelect: 'none',
            width: '256px'
          }}
          disabled={store.uiStore.getGlobalLoader() || loading}
          tabTitleClassName='w-[88px] text-center select-none'
          activeTab={tabIndex}
          onChange={(index: any) => {
            setTabIndex(index.toString())
            const queryParams = getAllQueryParamsAsObjectFromUrl(
              location.search
            )
            setCurrentPage(1)
            setSearchParams({
              ...queryParams,
              page: '1',
              contextSwitcherTab: index.toString()
            })
          }}
          className='override-height'
        />
      </div>
      {/* Right side: Button */}
      <div className=' absolute top-[73px] right-0 flex items-center'>
        <Button
          id='mark-as-button'
          label='Mark all as read'
          onClick={markAllAsRead}
          type='outline'
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default SecureComponent(observer(Notifications), AccessRoleConstants.ALL)
