import { DDSMenu, DDSMenuList, DDSTypography } from 'den-design-system'
import { Edit, OverflowMenuVertical, TrashCan } from '@carbon/icons-react'
import React, { useEffect, useRef, useState } from 'react'

import { Button } from '../New/Button/Button'
import pallete from '../../global/newPallete'
import routeConstants from '../../constants/RouteConstants'
import { useNavigate } from 'react-router-dom'

interface UCDropProps {
  itemKey: string
  item: any
  setSelectedCheck: any
  setDeleteOpen: any
  id: string
}

const UCSummaryDropdown: React.FC<UCDropProps> = ({
  itemKey,
  id,
  item,
  setSelectedCheck,
  setDeleteOpen
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [menuPosition, setMenuPosition] = useState<'top' | 'bottom'>('bottom')
  const buttonRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  const MenuItems = [
    {
      button: (
        <div
          className='flex justify-start items-center gap-[8px]'
          onClick={() => {
            navigate('update-check/:id'.replace(':id', id as string))
            setMenuOpen(false)
          }}
        >
          <Edit
            onClick={() => {
              navigate('update-check/:id'.replace(':id', id as string))
              setMenuOpen(false)
            }}
            color={pallete.colors.textButtonText}
          />
          <DDSTypography.Paragraph
            variant='semiBold'
            size='para'
            color={pallete.colors.textButtonText}
          >
            Edit
          </DDSTypography.Paragraph>
        </div>
      ),
      onClick: () => {
        navigate('update-check/:id'.replace(':id', id as string))
        setMenuOpen(false)
      }
    },
    {
      button: (
        <div
          className='flex justify-start items-center gap-[8px]'
          onClick={() => {
            setMenuOpen(false)
          }}
        >
          <TrashCan color={pallete.colors.warningRed2} />
          <DDSTypography.Paragraph
            variant='semiBold'
            size='para'
            color={pallete.colors.warningRed2}
          >
            Delete
          </DDSTypography.Paragraph>
        </div>
      ),
      onClick: () => {
        setDeleteOpen(true)
        setMenuOpen(false)
      }
    }
  ]

  useEffect(() => {
    if (buttonRef.current) {
      const dropdownRect = buttonRef.current.getBoundingClientRect()
      const windowHeight = window.innerHeight
      if (dropdownRect.bottom + 220 > windowHeight) {
        setMenuPosition('top')
      } else {
        setMenuPosition('bottom')
      }
    }
  }, [])

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        menuOpen &&
        buttonRef.current &&
        !buttonRef.current.contains(e.target)
      ) {
        setMenuOpen(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [menuOpen])

  const toggleMenu = () => {
    setSelectedCheck(item)
    setMenuOpen(!menuOpen)
  }

  return (
    <div id='overflow-btn' ref={buttonRef}>
      <Button
        id={`overflow-btn-${itemKey}`}
        type='ghost'
        onClick={toggleMenu}
        startIcon={<OverflowMenuVertical id={`overflow-btn-${itemKey}`} />}
      />
      <DDSMenu
        open={menuOpen}
        setOpen={setMenuOpen}
        onClose={() => setMenuOpen(false)}
        id='overflow-menu'
        parentId={`overflow-btn-${itemKey}`}
        className={`absolute z-[99999] !w-auto ${
          menuPosition === 'top'
            ? 'translate-y-[-120px] right-[0px]'
            : 'right-[0px]'
        }  shadow-sm max-h-[380px]`}
        style={{
          minWidth: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          border: `1px solid ${pallete.colors.stroke2}`,
          borderRadius: '8px',
          boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.10)'
        }}
      >
        <div className='override-menu-btn'>
          {MenuItems.map((item, index) => (
            <DDSMenuList key={index} onClick={item.onClick}>
              {item.button}
            </DDSMenuList>
          ))}
        </div>
      </DDSMenu>
    </div>
  )
}

export default UCSummaryDropdown
