import JMStringConstants from '../constants/JMStringConstants'
import Joi from 'joi'
import RegexConstants from '../constants/RegexConstants'
import StringConstants from '../constants/StringConstants'
import UrlConstants from '../constants/UrlConstants'
import ValidationConstants from '../constants/ValidationConstants'
import { checkNameAvailability } from '../api/common/Common.service'
import pallete from '../global/newPallete'

export const schema = Joi.object({
  name: Joi.string().pattern(RegexConstants.NAME_REGEX).required().messages({
    'string.empty': ValidationConstants.CREATE_JOB.JOB_NAME,
    'string.pattern.base': ValidationConstants.CREATE_JOB.JOB_NAME_INVALID
  }),
  slug: Joi.string().pattern(RegexConstants.SLUG_REGEX).required().messages({
    'string.empty': ValidationConstants.CREATE_JOB.JOB_SLUG,
    'string.pattern.base': ValidationConstants.CREATE_JOB.JOB_SLUG_INVALID
  }),
  cronExpression: Joi.string()
    .pattern(RegexConstants.CRON_EXPRESSION)
    .required()
    .messages({
      'string.empty': ValidationConstants.CREATE_JOB.CRON_EXPRESSION,
      'string.pattern.base': ValidationConstants.CREATE_JOB.CRON_EXPRESSION
    }),
  description: Joi.string().optional().allow('')
})

export const checkNameAvailabilityFromServer = (
  value: string,
  isSlug = false
) => {
  return new Promise((resolve, reject) => {
    checkNameAvailability(
      UrlConstants.CHECK_JOB_NAME_AVAILABILITY.USECASE,
      value.trim(),
      { isSlug }
    )
      .then((data) => resolve(data))
      .catch((err) => {
        console.log(JSON.stringify(err))
        reject(err)
      })
  })
}

export const statusToColor = (status: string) => {
  switch (status) {
    case 'SUCCESS':
      return pallete.colors.warningGreen
    case 'RUNNING':
      return pallete.colors.warningBlue
    case 'FAILED':
      return pallete.colors.warningRed2
  }
}

export const toTitleCase = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
}

export const getStatusForTab = (tab: string) => {
  switch (tab) {
    case '2':
      return 'SUCCESS'
    case '3':
      return 'RUNNING'
    case '4':
      return 'FAILED'
  }
}

export const validateCronExpression = (expression: string) => {
  const cronRegex = new RegExp(RegexConstants.CRON_EXPRESSION)
  return cronRegex.test(expression)
}

export const getNoDataMessageForStatus = (status: string) => {
  switch (status) {
    case 'SUCCESS':
      return JMStringConstants.NO_SUCCESS_JOBS
    case 'RUNNING':
      return JMStringConstants.NO_RUNNING_JOBS
    case 'FAILED':
      return JMStringConstants.NO_FAILED_JOBS
    case '':
      return 'No Jobs found'
  }
}
