import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import APIRequestResponseCard from '../../components/APIMonitoring/APIRequestResponseCard'
import ApiGroupingStringConstants from '../../constants/ApiGroupingStringConstants'
import { DDSTypography } from 'den-design-system'
import Spinner from '../common/Spinner/Spinner'
import { isJSON } from '../../utils/CommonUtils/StringUtils'
import { isUndefined } from 'lodash'
import { observer } from 'mobx-react-lite'
import pallete from '../../global/newPallete'
import { replaceUrl } from '../../utils/UrlUtils'
import routeConstants from '../../constants/RouteConstants'
import { useStoreContext } from '../../store/StoreContext'

const ViewRequestResponse: React.FC = () => {
  const store = useStoreContext()
  const { id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState<boolean>(false)
  const apiDetails = store.apiDetailsStore.getApiData()
  const responseBody = store.apiDetailsStore.getApiData().responseBody
  const requestBody = { ...store.apiDetailsStore.getApiData().requestBody }

  useEffect(() => {
    setLoading(store.apiDetailsStore.getAPILoading())
  }, [store.apiDetailsStore.apiLoading])

  const organizations = store.userStore.getOrganizations()
  const foundOrganization = organizations.find(
    (organization: any) =>
      organization.id === store.scopeStore.getOrganizationId()
  )

  useEffect(() => {
    // Get URL parameters
    const tabFromParams = searchParams.get('tab')
    const tabFromUserParam = searchParams.get('userTab')
    const tabFromUrlParam = searchParams.get('urlViewTab')
    const tabFromAllUserParam = searchParams.get('allUserTab')
    const tabFromParamsOverviewUserTab = searchParams.get('apiOverviewTab')

    // Get store data
    const apiData = store.apiDetailsStore.getApiData()
    const userName = localStorage.getItem('apiUserName')
    const scope = store.scopeStore.getScope()

    if (isUndefined(apiData?.urlTemplate)) return

    // Create API monitoring link
    const apiMonitoringLink = replaceUrl(
      routeConstants.API_MONITORING,
      scope['organization-id'] as string,
      scope['project-id'] as string,
      scope['environment-id'] as string
    )

    const breadcrumbOptions = [
      {
        label:
          tabFromParams === '1' || tabFromUserParam === '1'
            ? ApiGroupingStringConstants.GROUPED_API
            : ApiGroupingStringConstants.ALL_API,
        link: `${apiMonitoringLink}${
          tabFromUserParam === '1'
            ? '?tab=1&contextSwitcherTab=2'
            : tabFromAllUserParam === '2'
              ? '?tab=2&contextSwitcherTab=2'
              : tabFromParams === '1'
                ? '?tab=1'
                : '?tab=2'
        }`
      }
    ]

    // Add user breadcrumb if applicable
    if (tabFromUserParam === '1' && userName) {
      breadcrumbOptions.push({
        label: userName,
        link:
          replaceUrl(
            routeConstants.GROUPED_USER_DETAILS,
            scope['organization-id'] as string,
            scope['project-id'] as string,
            scope['environment-id'] as string
          ).replace(':id', apiDetails.apiUserId as string) + '?tab=1'
      })
    }

    // Add API details breadcrumb
    if (foundOrganization?.subscription?.trackApiUsage === true) {
      if (tabFromParamsOverviewUserTab) {
        if (
          tabFromParamsOverviewUserTab === '1' ||
          tabFromParamsOverviewUserTab === '2'
        ) {
          breadcrumbOptions.push({
            label:
              tabFromParamsOverviewUserTab === '1'
                ? 'API Logs'
                : 'API Overview',

            link:
              replaceUrl(
                routeConstants.GROUPED_USER_DETAILS,
                scope['organization-id'] as string,
                scope['project-id'] as string,
                scope['environment-id'] as string
              ).replace(':id', apiDetails.apiUserId as string) +
              `?tab=${tabFromParamsOverviewUserTab === '1' ? '1' : '2'}`
          })
        }
      } else {
        if (tabFromUrlParam === '1' || tabFromUrlParam === '2') {
          breadcrumbOptions.push({
            label: tabFromUrlParam === '1' ? 'API Logs' : 'API Overview',
            link:
              replaceUrl(
                routeConstants.VIEW_GROUPED_API,
                scope['organization-id'] as string,
                scope['project-id'] as string,
                scope['environment-id'] as string
              ).replace(':id', apiDetails.apiGroupId as string) +
              `?tab=${tabFromUrlParam}`
          })
        }
      }
    }

    // Add URL template and overview breadcrumbs
    breadcrumbOptions.push(
      {
        label: apiData?.urlTemplate as string,
        link:
          tabFromUserParam || tabFromAllUserParam
            ? replaceUrl(
                routeConstants.GROUPED_USER_API_LOGS_DETAILS,
                scope['organization-id'] as string,
                scope['project-id'] as string,
                scope['environment-id'] as string
              ).replace(
                ':apiGroupId/:apiUserId',
                `${apiDetails.apiGroupId}/${apiDetails.apiUserId}` as string
              ) + '?tab=1'
            : replaceUrl(
                routeConstants.VIEW_GROUPED_API,
                scope['organization-id'] as string,
                scope['project-id'] as string,
                scope['environment-id'] as string
              ).replace(':id', apiDetails.apiGroupId as string) + '?tab=1'
      },
      {
        label: ApiGroupingStringConstants.REQUEST_RESPONSE,
        link: '/'
      }
    )

    // Set breadcrumb options in store
    store.breadcrumbStore.setMultipleBreadcrumbsOptions(breadcrumbOptions)

    // Cleanup function
    return () => {
      store.breadcrumbStore.reset()
    }
  }, [
    searchParams,
    store.apiDetailsStore.getApiData(),
    store.breadcrumbStore,
    store.apiDetailsStore.getUserName()
  ])

  const requestResponseData = [
    {
      id: '1',
      description: (
        <pre>
          <code>
            <DDSTypography.Paragraph
              size='para'
              color={pallete.colors.textDark5}
              variant='regular'
              className='overflow-x-scroll overflow-y-scroll h-[65vh] 2xl:h-[70vh]'
            >
              {JSON.stringify(requestBody, null, 4)}
            </DDSTypography.Paragraph>
          </code>
        </pre>
      ),
      bodyText: ApiGroupingStringConstants.REQUEST_BODY,
      copyText: JSON.stringify(requestBody, null, 4)
    },
    {
      id: '2',
      description: (
        <pre>
          <code>
            {!isUndefined(responseBody) ? (
              <DDSTypography.Paragraph
                size='para'
                color={pallete.colors.textDark5}
                variant='regular'
                className='overflow-x-scroll  h-[65vh] 2xl:h-[70vh]'
              >
                {!isJSON(responseBody)
                  ? responseBody
                  : JSON.stringify(JSON.parse(responseBody), null, 4)}
              </DDSTypography.Paragraph>
            ) : (
              <>{'{}'}</>
            )}
          </code>
        </pre>
      ),
      bodyText: ApiGroupingStringConstants.RESPONSE_BODY,
      copyText: JSON.stringify(responseBody, null, 4)
    }
  ]

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className='flex h-full w-full'>
          <div className='flex justify-between items-center h-full w-full'>
            <div className='py-[24px] flex flex-row gap-[24px] h-full w-full'>
              {requestResponseData.map((item) => (
                <APIRequestResponseCard
                  key={item.id}
                  id={item.id}
                  description={item.description}
                  bodyText={item.bodyText}
                  copyText={item.copyText}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default observer(ViewRequestResponse)
