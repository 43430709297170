import {
  ExtraLargeProps,
  HeadingProps,
  ParagraphProps,
  TitleProps
} from './Typography.types'

import { Typography as MuiTypography } from '@mui/material'
import { PropsWithChildren } from 'react'
import React from 'react'
import palette from '../../../global/pallete'

const ExtraLarge: React.FC<PropsWithChildren<ExtraLargeProps>> = ({
  variant = 'bold',
  color,
  ellipsis = false,
  style,
  children,
  className
}) => {
  return (
    <MuiTypography
      color={palette.colors?.[color as keyof typeof palette.colors]}
      fontWeight={palette.font.fontWeight?.[variant]}
      noWrap={ellipsis}
      className={className}
      sx={{
        ...palette.font.variant?.extraLarge,
        ...style
      }}
    >
      {children}
    </MuiTypography>
  )
}

const Heading: React.FC<PropsWithChildren<HeadingProps>> = ({
  variant = 'bold',
  color = 'textDark',
  ellipsis = false,
  style,
  children,
  className
}) => {
  return (
    <MuiTypography
      color={palette.colors?.[color as keyof typeof palette.colors]}
      fontWeight={palette.font.fontWeight?.[variant]}
      noWrap={ellipsis}
      className={className}
      sx={{
        ...palette.font.variant?.heading,
        ...style
      }}
    >
      {children}
    </MuiTypography>
  )
}

const Title: React.FC<PropsWithChildren<TitleProps>> = ({
  type = 'h3',
  variant = 'semiBold',
  color = 'textDark',
  ellipsis = false,
  style,
  children,
  id,
  className
}) => {
  return (
    <MuiTypography
      id={id}
      color={palette.colors?.[color as keyof typeof palette.colors]}
      fontWeight={palette.font.fontWeight?.[variant]}
      noWrap={ellipsis}
      className={className}
      sx={{
        ...palette.font.variant?.[type],
        ...style
      }}
    >
      {children}
    </MuiTypography>
  )
}

const Paragraph: React.FC<PropsWithChildren<ParagraphProps>> = ({
  size = 'para',
  variant = 'medium',
  color = 'textDark2',
  ellipsis = false,
  style,
  children,
  id,
  className
}) => {
  return (
    <MuiTypography
      id={id}
      color={palette.colors?.[color as keyof typeof palette.colors]}
      fontWeight={palette.font.fontWeight?.[variant]}
      noWrap={ellipsis}
      className={className}
      sx={{
        ...palette.font.variant?.[size],
        ...style
      }}
    >
      {children}
    </MuiTypography>
  )
}

export const TypographyDDS = { ExtraLarge, Heading, Title, Paragraph }
