import { Outlet, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import { Box } from '@mui/material'
import CreateIcon from '../../assets/icons/createIcon.svg'
import DashboardSectionContainer from '../../components/common/DashboardSectionContainer/DashboardSectionContainer'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../constants/HeaderConstants'
import InstanceUrlPopup from '../../components/SalesforceAudit/InstanceUrlPopup'
import NoData from '../../components/NoData/NoData'
import { OrganizationType } from '../../enum/OrganizationType.enum'
import { ProjectType } from '../../enum/ProjectType.enum'
import Spinner from '../../components/common/Spinner/Spinner'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import routeConstants from '../../constants/RouteConstants'
import { useStoreContext } from '../../store/StoreContext'

const SalesforceAuditLayout: React.FC = () => {
  const { salesforceAuditStore, scopeStore, userStore, uiStore } =
    useStoreContext()
  const navigate = useNavigate()
  const storeLoading = salesforceAuditStore.getLoading()
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [auditData, setAuditData] = useState<Record<string, any>>({})
  const [showBottomBar, setShowBottomBar] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    if (
      userStore.getSelectedOrganization().type !==
        OrganizationType.FULL_STACK_ONLY &&
      scopeStore.getSelectedProject().type !== ProjectType.FULL_STACK_ONLY
    ) {
      salesforceAuditStore
        .getSalesforceAuditFromAPI()
        .then(() => {
          setAuditData(salesforceAuditStore.getAuditData())
          setShowBottomBar(true)
        })
        .catch((e) => {
          setAuditData({})
          setShowBottomBar(false)
        })
        .finally(() => {
          setLoading(false)
          uiStore.setGlobalLoader(false)
        })
    } else {
      navigate(routeConstants.REDIRECT)
      setLoading(false)
    }
  }, [location.search, scopeStore.getScope()])

  const renderChild = () => {
    if (isEmpty(auditData)) {
      if (storeLoading || loading) return <Spinner />
      return (
        <DashboardSectionContainer
          headerText='Salesforce Audit'
          padding='1.25em 1.875em'
        >
          <NoData
            includeButton
            buttonPreText='Connect'
            buttonDescription='Organization'
            buttonIcon={<img src={CreateIcon} />}
            message={`You are not connected to a salesforce organization yet.`}
            onClick={() => setOpen(true)}
            buttonDisabled={userStore.getNoActiveSubscription()}
          />
        </DashboardSectionContainer>
      )
    }
    return <Outlet />
  }

  return (
    <>
      <DocumentHeader header={HeaderConstants.SALESFORCE_AUDIT} />
      {/* <DashboardTopNavbar
        variant='salesforceAudit'
        showTopDropdowns
        showBottomBar={showBottomBar}
      />  */}
      <Box display='flex' justifyContent='center'>
        {renderChild()}
      </Box>
      <InstanceUrlPopup open={open} handleClose={() => setOpen(false)} />
    </>
  )
}

export default observer(SalesforceAuditLayout)
