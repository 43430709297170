import ApiManager from '../Api.manager'
import HttpConstants from '../../constants/HttpConstants'
import { isEmpty } from 'lodash'

/**
 * Service function to check project name availability
 */
export const checkNameAvailability = (
  usecase: string,
  name: string,
  extras?: object,
  headers = {}
) => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      {
        name: name.trim(),
        ...(isEmpty(extras) ? {} : { ...(extras as object) }),
        ...headers
      }
    )
      .then((response) => {
        if (response.status === 200) resolve(response.data)
        else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase} :: `, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}
