import pallete from '../global/newPallete'

export const StatusTypes = [
  {
    label: 'Resolved',
    value: 'Resolved',
    style: {
      color: pallete.colors.textDark4
    }
  },
  {
    label: 'Unresolved',
    value: 'Unresolved',
    style: {
      color: pallete.colors.textDark4
    }
  }
]
