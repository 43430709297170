import Button from '../common/Button/Button'
import { IoMdAdd } from 'react-icons/io'
import React from 'react'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import breakpoints from '../../global/breakpoints'
import noData from '../../assets/icons/empty.svg'
import palette from '../../global/pallete'
import styled from '@emotion/styled'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${palette.colors.white};
  justify-content: center;
  white-space: nowrap;
`

const WatermarkWrapper = styled.div`
  width: 45em;
  @media (max-width: ${breakpoints.xl}) {
    width: 30em;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 1em;
`
interface NoDataProps {
  includeButton?: boolean
  emptyText?: boolean
  buttonPreText?: string
  buttonDescription?: string
  buttonDisabled?: boolean
  onClick?: () => void
  message?: string
  buttonIcon?: JSX.Element
  style?: React.CSSProperties
}
const NoData = ({
  message = `Let's start monitoring!`,
  emptyText = true,
  buttonIcon = <IoMdAdd />,
  style,
  ...props
}: NoDataProps) => {
  const includeButton = props.includeButton
  const buttonDescription = props.buttonDescription
  const buttonPreText = props.buttonPreText
  const buttonDisabled = props.buttonDisabled

  return (
    <>
      <Container style={style}>
        <TextContainer style={{ marginLeft: '16px' }}>
          {emptyText && (
            <TypographyDDS.Title
              variant='semiBold'
              type='h4'
              // color='textDark2'
              style={{
                flex: '1 100%'
              }}
            >
              It{`'`}s rather empty here
            </TypographyDDS.Title>
          )}

          <TypographyDDS.Title variant='semiBold' type='h4'>
            {message}
          </TypographyDDS.Title>

          {includeButton && (
            <ButtonWrapper>
              <Button
                startIcon={buttonIcon}
                variant='contained'
                width='236px'
                height='50px'
                onClick={props.onClick}
                disabled={buttonDisabled ? buttonDisabled : false}
              >
                {buttonPreText ? buttonPreText : 'Create'} {buttonDescription}
              </Button>
            </ButtonWrapper>
          )}
        </TextContainer>
        <WatermarkWrapper>
          <img
            src={noData}
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain',
              maxWidth: '400px',
              paddingTop: '20px'
            }}
          />
        </WatermarkWrapper>
      </Container>
    </>
  )
}
export default NoData
