import React, { useContext } from 'react'

import { RootStore } from './RootStore'

const StoreContext = React.createContext<RootStore>({} as RootStore)

export const useStoreContext = () => useContext(StoreContext)
export const rootStore = new RootStore()
export const StoreProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>
  )
}
