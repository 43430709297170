import { CategoryScale, ChartArea, ChartData } from 'chart.js'
import React, { useEffect, useRef, useState } from 'react'

import { Chart } from 'react-chartjs-2'
import ChartJS from 'chart.js/auto'
import annotationPlugin from 'chartjs-plugin-annotation'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

ChartJS.register(CategoryScale)
ChartJS.register(annotationPlugin)

const ChartWrapper = styled.div`
  max-width: 11.25em;
  max-height: 3.75em;
`
const ResponseTimeGraph: React.FC<{ data?: { x: number; y: number }[] }> = ({
  data = []
}) => {
  const createGradient = (ctx: CanvasRenderingContext2D, area: ChartArea) => {
    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top)

    gradient.addColorStop(0, palette.colors.white)
    gradient.addColorStop(1, palette.colors.lightBack6)

    return gradient
  }
  const chartRef = useRef<ChartJS>(null)
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    datasets: []
  })

  useEffect(() => {
    const chart = chartRef.current
    if (!chart) {
      return
    }
    const chartData = {
      labels: data.map((item) => item.x),
      datasets: [
        {
          data: data.map((item) => item.y),
          tension: 0.7,
          borderColor: palette.colors.primary2,
          pointBackgroundColor: palette.colors.white,
          pointRadius: 0,
          borderWidth: 1,
          fill: 'origin',
          backgroundColor: createGradient(chart.ctx, chart.chartArea)
        }
      ]
    }

    setChartData(chartData)
  }, [])

  const graphOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false
      },
      y: {
        display: false
      }
    },

    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    }
  }
  return (
    <ChartWrapper>
      <Chart
        type='line'
        data={chartData}
        options={graphOptions}
        ref={chartRef}
        style={{ height: '30px', width: '120px' }}
      />
    </ChartWrapper>
  )
}

export default ResponseTimeGraph
