import { cloneDeep, isEmpty } from 'lodash'
import {
  debouncedJobSearchContextsAPI,
  findAllConfiguredJobs
} from '../../api/jobs/Jobs.service'
import { makeAutoObservable, observable } from 'mobx'

import { FilterTypes } from '../../enum/Filter.enum'
import { RootStore } from '../RootStore'
import StringConstants from '../../constants/StringConstants'
import { constructContextsFromQuery } from '../../utils/CommonUtils'
import { getFiltersForApi } from '../../utils/FilterUtils'

export interface Job {
  [key: string]: string | boolean
}
const searchContextsLookup: Record<
  string,
  {
    context: string
    label: string
    query: string
  }
> = {
  name: {
    context: 'name',
    label: 'Job Name',
    query: 'name-like'
  },
  cron_expression: {
    context: 'cron_expression',
    label: 'Cron Expression',
    query: 'cron-like'
  },
  description: {
    context: 'description',
    label: 'Job Description',
    query: 'description-like'
  }
}

const SearchContextMap = {
  'cron-like': 'cron_expression',
  'name-like': 'name',
  'description-like': 'description'
}

export class JobStore {
  jobs: Job[]
  selectedJob: Job[]
  totalCount: number
  recordsPresent: boolean
  currentPage: number
  loading: boolean
  callJobLogFilter: boolean
  searchContexts: string[]
  searchContextsLoading: boolean
  searchContextKey: number
  searchQuery: string
  selectedContexts: any[]
  finalLoader: boolean
  constructor(private rootStore: RootStore) {
    this.jobs = []
    this.selectedJob = []
    this.totalCount = 0
    this.recordsPresent = true
    this.currentPage = 1
    this.loading = false
    this.callJobLogFilter = false
    this.searchContexts = []
    this.searchContextsLoading = false
    this.searchContextKey = 0
    this.searchQuery = ''
    this.selectedContexts = []
    this.finalLoader = false
    makeAutoObservable(this, {
      jobs: observable.struct,
      selectedJob: observable.struct,
      loading: observable.struct,
      callJobLogFilter: observable.struct
    })
  }

  getJobs(): Job[] {
    return this.rootStore.jobStore.jobs
  }

  setTotalCount(totalCount: number) {
    this.totalCount = totalCount
  }

  setJobs(jobs: Job[] = []) {
    this.updateJobs(jobs)
  }
  updateJobs(jobs: Job[]) {
    this.rootStore.jobStore.jobs = jobs
  }

  setRecordsPresent(recordsPresent: boolean) {
    this.recordsPresent = recordsPresent
  }

  async getJobsFromApi(
    page = StringConstants.DEFAULT_PAGE,
    id = '',
    filters?: any
  ) {
    this.setTotalCount(0)
    this.setJobs([])
    this.setLoading(true)
    return new Promise((resolve, reject) => {
      const size = StringConstants.DEFAULT_TABLE_SIZE
      this.rootStore.filterStore.setFilters({})
      const filter = getFiltersForApi(FilterTypes.JOB, filters)
      findAllConfiguredJobs({
        'expand-created-user': true,
        size,
        ...filter,
        page
      })
        .then((response: any) => {
          if (
            response.data.length > 0 ||
            this.rootStore.filterStore.getFiltersApplied()
          ) {
            this.rootStore.filterStore.setFilterDisabled(false)
          }
          this.setTotalCount(response.totalCount)
          this.setJobs(response.data)
          this.setRecordsPresent(response.recordsPresent)
          this.setCurrentPage(response.page)
          const contexts: any[] = constructContextsFromQuery(
            response.filters,
            searchContextsLookup,
            SearchContextMap
          )
          this.setSelectedContexts(contexts)
          this.rootStore.filterStore.setFilters(response.filters)
          resolve(response.data[0]?.id)
        })
        .catch((err) => {
          console.log('error while fetching jobs', err)
          reject(err)
        })
        .finally(() => this.setLoading(false))
    })
  }

  async getSearchContextsFromAPI(query: string, filters: any) {
    this.setSearchContexts([])
    if (query.trim().length < 3) return
    this.setSearchContextsLoading(true)
    try {
      const { data } = await debouncedJobSearchContextsAPI(query, filters)
      this.setSearchContexts(data)
      this.rootStore.jobStore.searchQuery = query
      this.incrementSearchContextKey()
    } catch (e) {
      console.log(e)
    } finally {
      this.setSearchContextsLoading(false)
    }
  }
  getSearchQuery() {
    return this.rootStore.jobStore.searchQuery
  }

  setCurrentPage(page: number) {
    this.currentPage = page
  }

  setSelectedJob(job: any[]) {
    this.rootStore.jobStore.selectedJob = job
  }

  getSelectedJob(): Job[] {
    return this.rootStore.jobStore.selectedJob
  }

  getTotalCount(): number {
    return this.rootStore.jobStore.totalCount
  }

  getRecordsPresent(): boolean {
    return this.rootStore.jobStore.recordsPresent
  }

  getCurrentPage() {
    return this.rootStore.jobStore.currentPage
  }

  setLoading(loading: boolean) {
    this.loading = loading
  }

  getLoading() {
    return this.rootStore.jobStore.loading
  }

  setFinalLoader(loading: boolean) {
    this.finalLoader = loading
  }

  getFinalLoader() {
    return this.rootStore.jobStore.finalLoader
  }

  setCallJobLogFilter(callJobLogFilter: boolean) {
    this.callJobLogFilter = callJobLogFilter
  }

  getCallJobLogFilter() {
    return this.callJobLogFilter
  }
  setSearchContexts(contexts: string[]) {
    this.rootStore.jobStore.searchContexts = contexts
  }
  getSearchContexts() {
    return this.rootStore.jobStore.searchContexts.map((context) => ({
      id: searchContextsLookup[context].query,
      label: `${searchContextsLookup[context].label} : ${this.getSearchQuery()}`
    }))
  }
  setSearchContextsLoading(loading: boolean) {
    this.rootStore.jobStore.searchContextsLoading = loading
  }
  getSearchContextsLoading() {
    return this.rootStore.jobStore.searchContextsLoading
  }
  getSearchContextKey() {
    return this.rootStore.jobStore.searchContextKey
  }
  incrementSearchContextKey() {
    this.rootStore.jobStore.searchContextKey =
      this.rootStore.jobStore.searchContextKey + 1
  }

  setSelectedContexts(contexts: any[]) {
    this.rootStore.ahcReportsStore.selectedContexts = contexts
  }

  getSelectedContexts() {
    return this.rootStore.ahcReportsStore.selectedContexts
  }

  reset() {
    this.currentPage = 0
    this.selectedJob = []
    this.jobs = []
    this.currentPage = 0
    this.callJobLogFilter = false
  }
}
