import React, { useEffect, useRef, useState } from 'react'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'

import Button from '../../common/Button/Button'
import EditIcon from '../../../assets/icons/Edit.svg'
import GoBack from './GoBack'
import InputField from '../../common/InputField/InputField'
import { TypographyDDS } from '../../common/Typography/TypographyDDS'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import palette from '../../../global/pallete'
import plusIcon from '../../../../src/assets/icons/plus.svg'
import styled from '@emotion/styled'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useStoreContext } from '../../../store/StoreContext'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.875em;
  align-items: center;
  gap: 1.25em;
`
const ColorText = styled.div`
  color: ${palette.colors.primary};
  display: inline;
`
const HeadersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.625em;
`

const AddButtonWrapper = styled.div`
  align-self: flex-end;
  display: flex;
  justify-content: center;
`

const ActionWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`
const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
const InputFieldWrapper = styled.div`
  height: 150px;
  display: grid;
  overflow: scroll;
  grid-gap: 10px;
`

interface Header {
  key: string
  value: string
}
const CustomHeaderStep: React.FC<{
  handleNext: (data: any) => void
  handleBack: () => void
}> = ({ handleNext, handleBack }) => {
  const store = useStoreContext()
  const [formState, setFormState] = useState<Header[]>([{ key: '', value: '' }])
  const inputFieldWrapperRef = useRef<HTMLDivElement>(null)
  const handleChange = (name: string, value: string, index: number) => {
    const newFormState = _.cloneDeep(formState)
    newFormState[index][name as keyof Header] = value
    setFormState(newFormState)
  }
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.CUSTOM_HEADER_STEP
  )
  const onAddInput = () => {
    const newFormState = _.cloneDeep(formState)
    newFormState.push({ key: '', value: '' })
    setFormState(newFormState)
  }
  useEffect(() => {
    scrollToBottom()
  }, [formState.length])

  const onNext = () => {
    const data: { [key: string]: string } = {}
    formState.forEach(({ key, value }) => key !== '' && (data[key] = value))
    store.addCheckStore.setData({ customHeaders: data })
    handleNext(null)
  }
  useEffect(() => {
    const headers = store.addCheckStore.getData().customHeaders
    const keys = Object.keys(headers)
    const newState = keys.map((key) => ({ key, value: headers[key] }))
    keys.length === 0 && newState.push({ key: '', value: '' })
    setFormState(newState)
  }, [])
  const scrollToBottom = () => {
    if (inputFieldWrapperRef.current) {
      inputFieldWrapperRef.current.scrollTo({
        top: inputFieldWrapperRef.current.scrollHeight,
        behavior: 'smooth'
      })
    }
  }
  return (
    <Container>
      <TypographyDDS.Title type='h3' variant='medium'>
        Add a custom <ColorText>header and value</ColorText>
      </TypographyDDS.Title>
      <InputFieldWrapper ref={inputFieldWrapperRef}>
        {formState.map(({ key, value }, index) => (
          <HeadersContainer key={index}>
            <InputField
              label='Header'
              value={value}
              name='value'
              onChange={(e) =>
                handleChange(e.target.name, e.target.value, index)
              }
            />
            <InputField
              label='Value'
              name='key'
              value={key}
              onChange={(e) =>
                handleChange(e.target.name, e.target.value, index)
              }
            />
          </HeadersContainer>
        ))}
      </InputFieldWrapper>
      <AddButtonWrapper>
        <Button
          startIcon={
            <img
              src={plusIcon}
              style={{
                filter: palette.colors.blueSvgFilter
              }}
            />
          }
          iconSize='12px'
          variant='outlined'
          onClick={onAddInput}
          size='medium'
          disabled={
            store.addCheckStore.getLoading() ||
            !formState.every(({ key, value }) => key !== '' && value !== '')
          }
        >
          Add Header
        </Button>
      </AddButtonWrapper>
      <Button
        id='Http Headers Add Check'
        onClick={() => {
          onNext()
          {
            store.addCheckStore.editMode
              ? gaEventTracker(gaEventConstants.UPDATED_CHECK)
              : gaEventTracker(gaEventConstants.ADDED_CHECK)
          }
          store.projectAndEnvironmentStore.setEnvironmentName('')
        }}
        variant='contained'
        iconSize={store.addCheckStore.editMode ? '16px' : '12px'}
        startIcon={
          store.addCheckStore.editMode ? (
            <img
              src={EditIcon}
              style={{
                filter: palette.colors.whiteSvgFilter
              }}
            />
          ) : (
            <img src={plusIcon} />
          )
        }
        fullWidth
        loading={store.addCheckStore.getLoading()}
      >
        {store.addCheckStore.editMode ? 'Update Check' : 'Add Check'}
      </Button>
      <ActionContainer>
        <ActionWrapper
          onClick={() => {
            const data: { [key: string]: string } = {}
            formState.forEach(
              ({ key, value }) => key !== '' && (data[key] = value)
            )
            store.addCheckStore.setData({ customHeaders: data })
            handleBack()
          }}
        >
          <GoBack />
        </ActionWrapper>
        {/* <ActionWrapper onClick={() => handleNext(null)}>
          <SkipStep />
        </ActionWrapper> */}
      </ActionContainer>
    </Container>
  )
}

export default observer(CustomHeaderStep)
