import { DDSMenu } from 'den-design-system'
import { MenuProps } from './DropdownMenu.type'
import { PropsWithChildren } from 'react'
import React from 'react'
import pallete from '../../../global/newPallete'
import triangleIcon from '../../../assets/icons/triangleIcon.svg'
type extendedMenuProps = MenuProps & {
  arrowPosition?: 'left' | 'right' | 'center'
  showArrow?: boolean
  arrowTop?: boolean
}
const DropdownMenu: React.FC<PropsWithChildren<extendedMenuProps>> = ({
  id,
  className,
  open,
  children,
  showArrow,
  arrowTop,
  onClose,
  setOpen,
  parentId,
  arrowPosition = 'center'
}) => {
  const getArrowPosition = () => {
    return arrowPosition === 'right'
      ? 'translate-x-[85%]'
      : arrowPosition === 'left'
        ? 'translate-x-[5%]'
        : 'justify-center'
  }
  return (
    <>
      {open && (
        <div className={className}>
          {showArrow && arrowTop && (
            <div className={`w-full flex items-center  ${getArrowPosition()}`}>
              {open && <img src={triangleIcon} className={`w-1/12 `} />}
            </div>
          )}

          <DDSMenu
            id={id}
            open={open}
            shape='roundedRectangle'
            onClose={onClose}
            parentId={parentId}
            setOpen={setOpen}
            className={`${showArrow && (arrowTop ? '-mt-[2px]' : '-mb-[2px]')} !min-w-[156px] flex flex-col items-start gap-[8px] border border-[${pallete.colors.stroke2}] rounded-[8px] shadow-selectMenu`}
          >
            {children}
          </DDSMenu>
          {showArrow && !arrowTop && (
            <div
              className={`w-full flex items-center rotate-180 ${getArrowPosition()}`}
            >
              {open && <img src={triangleIcon} className={`w-1/12 `} />}
            </div>
          )}
        </div>
      )}
    </>
  )
}
export default DropdownMenu
