import { BtoA, getAllQueryParamsAsObjectFromUrl } from '../../../utils/UrlUtils'
import { DDSMenu, DDSTypography } from 'den-design-system'
import React, { useEffect, useState } from 'react'
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import {
  getAllNotifications,
  markNotificationAsRead
} from '../../../api/notification/notification.service'

import { ChevronRight } from '@carbon/icons-react'
import Spinner from '../../../components/common/Spinner/Spinner'
import StringConstants from '../../../constants/StringConstants'
import Tabs from '../../../components/common/DDS/Tabs/Tabs'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import { findAllEnvironments } from '../../../api/environment/Environment.service'
import { findAllMyUserInvites } from '../../../api/userInvites/UserInvites.service'
import { getNotifiedTime } from '../../../utils/DateTimeUtils'
import pallete from '../../../global/newPallete'
import { redirectFunction } from '../../../utils/NotificationUtils'
import routeConstants from '../../../constants/RouteConstants'
import successIcon from '../../../assets/icons/successIcon.svg'
import unreadNotificationIcon from '../../../assets/icons/unreadNotification.svg'
import { useStoreContext } from '../../../store/StoreContext'

interface NotificationWrapperProps {
  isDropdownOpen: boolean
  setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedInvite: React.Dispatch<React.SetStateAction<any>>
  setInviteOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const NotificationWrapper: React.FC<NotificationWrapperProps> = ({
  isDropdownOpen,
  setDropdownOpen,
  setInviteOpen,
  setSelectedInvite
}) => {
  const navigate = useNavigate()
  const [tabIndex, setTabIndex] = useState<string>('1')
  const [loading, setLoading] = React.useState<boolean>(true)
  const [notifications, setNotifications] = React.useState<any>([])
  const [invites, setInvites] = React.useState<any>([])
  const store = useStoreContext()
  const [searchParams, setSearchParams] = useSearchParams()

  const handleEnvironmentChange = (id: string) => {
    if (id === store.scopeStore.getSelectedEnvironment().id) {
      return
    }
    store.uiStore.setGlobalLoader(true)
    store.scopeStore.setEnvironmentId(id)
    const environment = store.scopeStore
      .getEnvironments()
      .filter((record: any) => record.id == id)
    store.scopeStore.setSelectedEnvironment(environment[0])
    store.filterStore.setFiltersApplied(false)
    let url = BtoA(
      store.scopeStore.getOrganizationId() +
        '/' +
        store.scopeStore.getScope()['project-id'] +
        '/' +
        id
    )
    url += '/' + window.location.pathname.split('/')[2]
    if (
      ['dashboard', 'report'].includes(window.location.pathname.split('/')[3])
    ) {
      url += '/' + window.location.pathname.split('/')[3]
    }
    const pathName = '../' + '../' + url
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    navigate({
      pathname: pathName,
      search: `?${createSearchParams(queryParams)}`
    })
    store.breadcrumbStore.reset()
  }

  const environmentSwitch = (notification: any) => {
    if (
      notification?.parentId?.environmentId !==
      store.scopeStore.getEnvironmentId()
    ) {
      const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
      findAllEnvironments(notification?.parentId?.projectId, allQueryParams)
        .then((environments: any) => {
          if (environments.data && environments.data.length > 0) {
            store.scopeStore.setEnvironments(environments.data)
            store.scopeStore.setProjectId(notification?.parentId?.projectId)
            const project = store.scopeStore
              .getProjects()
              .filter(
                (record: any) => record.id === notification?.parentId?.projectId
              )
            store.scopeStore.setSelectedProject(project[0])
            const matchingEnvironment = environments.data.find(
              (env: any) => notification?.parentId?.environmentId === env?.id
            )

            if (matchingEnvironment) {
              if (
                matchingEnvironment.type ===
                StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
              ) {
                localStorage.setItem(
                  StringConstants.ENVIRONMENT_TYPE,
                  StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
                )
              } else {
                localStorage.setItem(
                  StringConstants.ENVIRONMENT_TYPE,
                  StringConstants.ENVIRONMENT_TYPE_VALUES.PROD
                )
              }
              handleEnvironmentChange(matchingEnvironment.id)
            } else {
              console.warn('No matching environment found')
            }
          } else {
            console.warn('No environments available')
          }
        })
        .catch((error) => {
          console.error('Error fetching environments:', error)
        })
    }
  }

  const MarkNotificationAsRead = (Notification: any) => {
    if (store.userStore.getSelectedOrganization().projectCount !== 0) {
      environmentSwitch(Notification)
      setLoading(true)
      setDropdownOpen(false)
      markNotificationAsRead({ $id: Notification.id })
        .then(() => {
          Notification.eventName !== 'DELETED' &&
          Notification.eventName !== 'REJECTED' &&
          Notification.eventName !== 'WELCOME'
            ? navigate(
                redirectFunction(Notification, store.uiStore, store.scopeStore)
              )
            : getAllNotifications({ status: 'UNREAD' })
                .then((response: any) => {
                  setNotifications(response.data)
                })
                .catch((err) => console.log(err))
                .finally(() => setLoading(false))
        })
        .catch((err) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.SOMETHING_WENT_WRONG
          })
        })
    }
  }

  useEffect(() => {
    if (isDropdownOpen) {
      if (tabIndex === '1') {
        setLoading(true)
        getAllNotifications({ status: 'UNREAD' })
          .then((response: any) => {
            setNotifications(response.data)
          })
          .catch((err) => console.log(err))
          .finally(() => setLoading(false))
      } else if (tabIndex === '2') {
        setLoading(true)
        findAllMyUserInvites()
          .then((response: any) => {
            store.userStore.setInvitationCount(response.data.length)
            setInvites(response.data)
          })
          .catch((err) => console.log(err))
          .finally(() => setLoading(false))
      }
    }
    store.uiStore.setGlobalLoader(false)
  }, [isDropdownOpen, tabIndex])

  useEffect(() => {
    const innerTab = searchParams.get('innerTab') || '1'
    setTabIndex(innerTab)
  }, [searchParams])

  const renderContentForDangerouslySetHTMLColumns = (content: string) => (
    <span
      dangerouslySetInnerHTML={{
        __html: content
      }}
    />
  )

  const isToday = (date: any) => {
    const today = new Date()
    const notificationDate = new Date(date)
    return (
      today.getDate() === notificationDate.getDate() &&
      today.getMonth() === notificationDate.getMonth() &&
      today.getFullYear() === notificationDate.getFullYear()
    )
  }

  const recentNotifications = notifications
    .slice(0, 5)
    .filter((notification: any) => isToday(notification.notifiedAt))

  const pastNotifications = notifications
    .slice(0, 5)
    .filter((notification: any) => !isToday(notification.notifiedAt))

  const tab = [
    {
      label:
        tabIndex === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            Notifications
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            Notifications
          </DDSTypography.Title>
        ),
      children: (
        <div className='flex flex-col gap-[20px] mt-[24px]'>
          {tabIndex === '1' && (
            <>
              {loading ? (
                <Spinner />
              ) : store.userStore.getNotificationCount() === 0 ||
                notifications.length === 0 ? (
                <div className='flex flex-col justify-center items-center text-center gap-[8px]'>
                  <img
                    alt='successIcon'
                    src={successIcon}
                    style={{
                      height: '24px',
                      filter: pallete.colors.FillButton
                    }}
                  />
                  <DDSTypography.Title
                    type='h5'
                    variant='semiBold'
                    color={pallete.colors.textDark3}
                  >
                    You{`'`}re all caught up
                  </DDSTypography.Title>
                </div>
              ) : (
                <>
                  {recentNotifications.length > 0 && (
                    <>
                      <DDSTypography.Title
                        variant='bold'
                        type='h5'
                        color={pallete.colors.textDark3}
                      >
                        Today
                      </DDSTypography.Title>
                      <div className='flex flex-col gap-[20px]'>
                        {recentNotifications.map(
                          (notification: any, index: any) => (
                            <div
                              key={index}
                              className='flex flex-row justify-center items-center cursor-pointer'
                              onClick={() => {
                                MarkNotificationAsRead(notification)
                              }}
                            >
                              <DDSTypography.Paragraph
                                size='para'
                                variant='medium'
                              >
                                {renderContentForDangerouslySetHTMLColumns(
                                  notification.content as string
                                )}
                                <span
                                  style={{
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    color: pallete.colors.textDark6
                                  }}
                                >
                                  &nbsp;
                                  {getNotifiedTime(notification.notifiedAt)}
                                </span>
                              </DDSTypography.Paragraph>
                              {notification.readAt === null && (
                                <div className='w-[8px] h-[8px] ml-[12px]'>
                                  <img
                                    src={unreadNotificationIcon}
                                    alt='Unread Notification'
                                    style={{ width: '8px', height: '8px' }}
                                  />
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </>
                  )}
                  {recentNotifications.length > 0 &&
                    pastNotifications.length > 0 && (
                      <hr
                        style={{
                          height: '1px',
                          background: 'var(--Stroke-Stroke-05, #E1E6EE)',
                          border: 'none'
                        }}
                      />
                    )}
                  {pastNotifications.length > 0 && (
                    <>
                      <DDSTypography.Title
                        variant='bold'
                        type='h5'
                        color={pallete.colors.textDark3}
                      >
                        Past Notification
                      </DDSTypography.Title>
                      {pastNotifications.map(
                        (notification: any, index: any) => (
                          <div
                            key={index}
                            className='flex flex-row justify-center items-center cursor-pointer'
                            onClick={() => {
                              MarkNotificationAsRead(notification)
                            }}
                          >
                            <DDSTypography.Paragraph
                              size='para'
                              variant='medium'
                            >
                              {renderContentForDangerouslySetHTMLColumns(
                                notification.content as string
                              )}
                              <span
                                style={{
                                  fontWeight: '500',
                                  fontSize: '14px',
                                  color: pallete.colors.textDark6
                                }}
                              >
                                &nbsp;{getNotifiedTime(notification.notifiedAt)}
                              </span>
                            </DDSTypography.Paragraph>
                            {notification.readAt === null && (
                              <div className='w-[8px] h-[8px] ml-[12px]'>
                                <img
                                  src={unreadNotificationIcon}
                                  alt='Unread Notification'
                                  style={{ width: '8px', height: '8px' }}
                                />
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
          {!loading && (
            <>
              <hr
                style={{
                  height: '1px',
                  background: 'var(--Stroke-Stroke-05, #E1E6EE)',
                  border: 'none'
                }}
              />
              <div
                onClick={() => {
                  navigate(routeConstants.NOTIFICATIONS)
                  setDropdownOpen(false)
                }}
                className='cursor-pointer flex items-center justify-center gap-[4px]'
              >
                <div>
                  <DDSTypography.Title
                    type='h5'
                    variant='semiBold'
                    color={pallete.colors.textDark5}
                  >
                    View all notifications
                  </DDSTypography.Title>
                </div>
                <ChevronRight size={20} color={pallete.colors.textDark2} />
              </div>
            </>
          )}
        </div>
      )
    },
    {
      label:
        tabIndex === '2' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            Invites
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            Invites
          </DDSTypography.Title>
        ),
      children: (
        <div className='flex flex-col gap-[20px] mt-[24px]'>
          {tabIndex === '2' && (
            <>
              {loading ? (
                <Spinner />
              ) : store.userStore.getInvitationCount() === 0 ? (
                <div className='flex flex-col justify-center items-center text-center gap-[8px]'>
                  <img
                    alt='successIcon'
                    src={successIcon}
                    style={{
                      height: '24px',
                      filter: pallete.colors.FillButton
                    }}
                  />
                  <DDSTypography.Title
                    type='h5'
                    variant='semiBold'
                    color={pallete.colors.textDark3}
                  >
                    You{`'`}re all caught up
                  </DDSTypography.Title>
                </div>
              ) : (
                <div className='flex flex-col gap-[20px]'>
                  {invites.slice(0, 5).map((invite: any, index: any) => (
                    <div key={index} className='flex flex-row items-center'>
                      <DDSTypography.Paragraph size='para' variant='medium'>
                        {invite.inviter.firstName} has invited you to join in
                        their{' '}
                        <span style={{ fontWeight: 700 }}>
                          {invite.project
                            ? `“Project - ${invite.project.name}”`
                            : `“Organization - ${invite.organization.name}”`}
                        </span>{' '}
                        as a{' '}
                        <span style={{ fontWeight: 700 }}>
                          {invite.role.toUpperCase()}.
                        </span>
                        <span
                          style={{
                            fontWeight: 700,
                            color: pallete.colors.textDark2
                          }}
                          className='inline-flex flex-row items-center cursor-pointer pl-[4px]'
                          onClick={() => {
                            setDropdownOpen(false)
                            setSelectedInvite(invite)
                            setInviteOpen(true)
                          }}
                        >
                          Review Invite
                          <ChevronRight
                            size={16}
                            color={pallete.colors.textDark2}
                          />
                        </span>
                      </DDSTypography.Paragraph>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
          {!loading && (
            <>
              {' '}
              <hr
                style={{
                  height: '1px',
                  background: 'var(--Stroke-Stroke-05, #E1E6EE)',
                  border: 'none'
                }}
              />
              <div
                onClick={() => {
                  const route = routeConstants.NOTIFICATIONS
                  const queryParams = { innerTab: '2' }
                  navigate({
                    pathname: route,
                    search: `?${createSearchParams(queryParams)}`
                  })
                  setDropdownOpen(false)
                }}
                className='cursor-pointer flex items-center justify-center gap-[4px]'
              >
                <div>
                  <DDSTypography.Title
                    type='h5'
                    variant='semiBold'
                    color={pallete.colors.textDark5}
                  >
                    View all invites
                  </DDSTypography.Title>
                </div>
                <ChevronRight size={20} color={pallete.colors.textDark2} />
              </div>
            </>
          )}
        </div>
      )
    }
  ]

  return (
    <div className='flex absolute right-[120px] ml-[16px] top-[65px] w-[450px] z-[9999999]'>
      <DDSMenu
        open={isDropdownOpen}
        parentId='notification-menu'
        onClose={() => setDropdownOpen(false)}
        setOpen={setDropdownOpen}
        className='shadow-lg px-[24px] pb-[20px]'
        style={{
          maxWidth: '450px',
          borderRadius: '8px',
          border: '1px solid var(--Stroke-Stroke-05, #E1E6EE)',
          background: '#FFF',
          boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.08)'
        }}
      >
        <Tabs
          tabOptionsStyle={{
            minWidth: '185px',
            display: 'flex',
            padding: '8px 12px ',
            justifyContent: 'center',
            alignItems: 'center',
            userSelect: 'none',
            width: 'auto',
            marginRight: '8px'
          }}
          tabOptions={tab}
          id='tabs-notification-wrapper'
          onChange={(index: any) => {
            setTabIndex(index.toString())
            const queryParams = getAllQueryParamsAsObjectFromUrl(
              location.search
            )
            if (queryParams['contextSwitcherTab']) {
              delete queryParams['contextSwitcherTab']
            }
            setSearchParams({
              ...queryParams,
              page: '1',
              innerTab: index.toString()
            })
          }}
          activeTab={tabIndex}
        />
      </DDSMenu>
    </div>
  )
}

export default NotificationWrapper
