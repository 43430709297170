export const ToastMessageConstants = {
  SOMETHING_WENT_WRONG:
    'Looks like something went wrong. Please try again later',
  PARTNER: {
    PARTNER_ORGANIZATION: {
      SUCCESS: 'Partner Organization has been created successfully',
      ERROR: 'Partner Organization could not be created'
    },
    PARTNER_INVITE: {
      ERROR: 'Partner invite could not be updated'
    }
  },
  JOB_MONITORING: {
    CREATE_JOB: {
      SUCCESS: 'Job has been created successfully',
      ERROR: {
        DEFAULT: 'Job could not be created',
        LIMIT_EXCEEDED: `Couldn't add job, limit exceeded. Upgrade your plan for more job limit`
      }
    },
    UPDATE_JOB: {
      SUCCESS: 'Job has been updated successfully',
      ERROR: 'Job could not be updated'
    },
    FIND_JOBS: {
      ERROR: 'Jobs could not be found'
    },
    DELETE_JOB: {
      SUCCESS: 'Job has been deleted successfully',
      ERROR: 'Job could not be deleted'
    },
    JOB_FILTERS: {
      ERROR: 'Job filters could not be retrieved'
    },
    JOB_LOGS: {
      DELETE_JOB_LOGS: {
        SUCCESS: 'Job log has been deleted successfully',
        ERROR: 'Job logs could not be deleted'
      },
      FIND_JOB_LOGS: {
        ERROR: 'Jobs logs could not be found'
      }
    },
    COPY_JOBS: {
      SUCCESS: 'Jobs have been imported successfully',
      ERROR: {
        DEFAULT: 'Jobs could not be imported',
        LIMIT_EXCEEDED: `Couldn't import jobs, limit exceeded. Upgrade your plan for more job limit`
      },
      WARNING: 'No Jobs found'
    }
  },
  SETTINGS: {
    UPDATE_ORGANIZATION: {
      SUCCESS: 'Organization has been updated successfully',
      ERROR: 'Organization could not be updated'
    },
    DELETE_ORGANIZATION: {
      SUCCESS: 'Organization has been deleted successfully',
      ERROR: 'Organization could not be deleted'
    },
    UPLOAD_AVATAR: {
      SUCCESS: 'Avatar has been uploaded successfully',
      ERROR: 'Organization avatar could not be uploaded'
    },
    DELETE_AVATAR: {
      SUCCESS: 'Avatar has been deleted successfully',
      ERROR: 'Organization avatar could not be uploaded'
    },
    UPLOAD_USER_AVATAR: {
      SUCCESS: 'Avatar has been uploaded successfully',
      ERROR: 'Account avatar could not be uploaded',
      ERROR2: 'File size is too large. Please choose a smaller file'
    },
    DELETE_ORGANIZATION_AVATAR: {
      SUCCESS: 'Avatar has been deleted successfully',
      ERROR: 'Organization avatar could not be deleted'
    },

    MEMBERS: {
      FIND_MEMBERS: {
        ERROR: 'Organization members could not be found'
      },
      FIND_INVITED_MEMBERS: {
        ERROR: 'Invited members could not be found'
      },
      DELETE_MEMBERS: {
        SUCCESS: 'Removed member successfully',
        ERROR: 'Member could not be removed'
      },
      UPDATE_MEMBERS: {
        SUCCESS: 'Role updated successfully',
        ERROR: 'Role could not be updated'
      },
      REVOKE_INVITE: {
        SUCCESS: 'Invite revoked successfully',
        ERROR: 'Invite could not be revoked'
      },
      RESEND_INVITE: {
        SUCCESS: 'Invite has been resent successfully',
        ERROR: 'Invite could not be resent'
      }
    }
  },

  API_MONITORING: {
    DELETE_RECORD: {
      SUCCESS: 'Record has been deleted successfully',
      ERROR: 'Record could not be deleted'
    },
    FIND_RECORD: {
      ERROR: 'Record could not be found'
    },
    API_MONITORING_FILTERS: {
      ERROR: 'API monitoring filters could not be retrieved'
    },
    FETCH_DETAILS: {
      ERROR: 'API records could not be found'
    }
  },

  USER: {
    SIGN_UP: {
      SUCCESS: 'Account has been created successfully',
      ERROR: 'Account could not be created'
    },
    UPLOAD_AVATAR: {
      SUCCESS: 'Avatar uploaded successfully',
      ERROR: 'Avatar could not be updated'
    },
    DELETE_USER_AVATAR: {
      SUCCESS: 'Avatar has been deleted successfully',
      ERROR: 'User avatar could not be deleted'
    },
    UPDATED_USERNAME: {
      SUCCESS: 'Username updated successfully',
      ERROR: 'Username could not be updated'
    },
    UPDATED_EMAIL: {
      SUCCESS: 'Email updated successfully',
      ERROR: 'Email could not be updated'
    },
    VERIFY_USER: {
      SUCCESS: 'Your account has been verified ',
      ERROR: 'Invalid action'
    },
    RESEND_VERIFICATION: {
      SUCCESS: 'Verification mail has been resent',
      ERROR: 'Verification mail could not be resent'
    }
  },
  AUTH: {
    SIGN_IN: {
      SUCCESS: 'Logged in successfully',
      ERROR: 'Log in failed'
    },
    RESET_PASSWORD: {
      SUCCESS: 'Password has been reset successfully',
      ERROR: 'Password could not be reset'
    },
    FORGOT_PASSWORD: {
      SUCCESS: 'Reset password link has been sent successfully',
      ERROR: 'Reset password link could not be sent'
    },
    UPDATE_PASSWORD: {
      SUCCESS: 'Updated password successfully',
      ERROR: 'Your current password is incorrect',
      VALIDATE: 'Your new password cannot be the same as the old one'
    },
    UNAUTHORIZED: 'Invalid Username/Password'
  },
  ENVIRONMENT: {
    CREATE: {
      SUCCESS: 'Environment has been added successfully',
      ERROR: 'Environment could not be added. Please try again later',
      UPGRADE_SUBSCRIPTION: 'Upgrade subscription to add more environments',
      WHITELIST_HOSTS: 'Successfully Whitelisted the hosts',
      WHITELIST_HOSTS_ERROR: 'Hosts could not be added. Please try again later'
    },
    UPDATE: {
      NAME: {
        SUCCESS: 'Environment name has been updated successfully',
        ERROR: 'Environment name could not be updated'
      },
      TYPE: {
        SUCCESS: 'Environment type has been updated successfully',
        ERROR: 'Environment type could not be updated'
      },
      NOTIFICATION: {
        SUCCESS: 'Notification Preferences has been updated successfully'
      }
    },
    DELETE: {
      SUCCESS: 'Environment has been deleted successfully',
      WHITELIST_HOSTS: 'Successfully deleted the hosts',
      WHITELIST_HOSTS_ERROR:
        'Hosts could not be deleted. Please try again later',
      ERROR: 'Environment could not be deleted'
    }
  },
  PROJECT: {
    CREATE: {
      SUCCESS: 'Project has been created successfully',
      ERROR: 'Project could not be created. Please try again later',
      UPGRADE_SUBSCRIPTION: 'Upgrade subscription to add more projects'
    },
    FIND_PROJECT: {
      ERROR: 'Project could not be found'
    },
    NO_PROJECT: {
      ERROR:
        'Access Denied: You must be added to a project to use this feature. Please contact your administrator'
    },
    FIND_ALL_PROJECTS: {
      ERROR: 'Projects could not be found'
    },
    UPDATE_PROJECT: {
      SUCCESS: 'Project has been updated successfully',
      ERROR: 'Project could not be updated'
    },
    DELETE_PROJECT: {
      SUCCESS: 'Project has been deleted successfully',
      ERROR: 'Project could not be deleted'
    },
    FIND_PROJECTS: {
      ERROR: 'Projects could not be found'
    },
    SLACK_CONNECTION: {
      SUCCESS: 'Successfully connected to Slack',
      ERROR: 'Error occured while connecting to Slack'
    },
    SLACK_DISCONNECTION: {
      SUCCESS: 'Successfully disconnected from Slack',
      ERROR: 'Unable to disconnect from Slack. Please try again'
    }
  },
  PREFERENCE: {
    GET_USER_PREFERENCE: {
      ERROR: 'User preference could be retrieved'
    },
    UPDATE_PREFERENCE: {
      SUCCESS: 'Preferences has been updated successfully',
      ERROR: 'Failed to update preferences'
    }
  },
  ORGANIZATION: {
    CREATE_ORGANIZATION: {
      SUCCESS: 'Organization has been created successfully',
      ERROR: 'Organization could not be created'
    },
    TRANSFER_OWNERSHIP: {
      SUCCESS: 'Ownership transferred successfully'
    }
  },
  AHC: {
    CREATE_AHC: {
      SUCCESS: 'Health check has been added successfully',
      ERROR: 'Health check could not be added'
    },
    DELETE_AHC: {
      SUCCESS: 'Health check has been deleted successfully',
      ERROR: 'Health check could not be deleted'
    },
    UPDATE_PROJECT: {
      SUCCESS: 'Health check updated successfully',
      ERROR: 'Failed to update health check'
    },
    FETCH_REPORTS: {
      ERROR: 'Health check reports could not be found'
    },
    FETCH_SUMMARY: {
      ERROR: 'Health check summary could not be found'
    },
    FETCH_CHECKS: {
      ERROR: 'Health checks could not be found'
    },
    UPDATE_CHECKS: {
      SUCCESS: 'Health check has been updated successfully',
      ERROR: 'Health check could not be updated'
    },
    FETCH_USER_LOOKUP: {
      SUCCESS: 'Added User to be alerted for the Health Check',
      ERROR: 'Error in adding the user to be alerted'
    },
    AHC_LOG_FILTERS: {
      ERROR: 'Health Check filters could not be retrieved'
    },
    FETCH_REPORT_DETAILS: {
      ERROR: 'Error loading health check report'
    }
  },
  EXCEPTION_INCIDENT: {
    CREATE: {
      SUCCESS: 'Incident created successfully',
      ERROR: 'Incident could not be created'
    },
    UPDATE: {
      SUCCESS: 'Incident updated successfully',
      ERROR: 'Incident could not be updated'
    },
    DELETE: {
      SUCCESS: 'Incident deleted successfully',
      ERROR: 'Incident could not be deleted'
    }
  },
  PROJECT_MEMBER: {
    FIND_ALL_PROJECT_MEMBERS: {
      ERROR: 'Project members could not be found'
    },
    REMOVE_PROJECT_MEMBER: {
      ERROR: '{RoleName} could not be removed from the project',
      SUCCESS: 'Project member removed successfully'
    },
    PROJECT_MEMBER_FILTERS: {
      ERROR: 'Project member filters could not be retrieved'
    },
    UPDATE_PROJECT_MEMBER: {
      SUCCESS: 'Project member has been updated successfully',
      ERROR: 'Project member could not be updated'
    },
    ADD_PROJECT_MEMBER: {
      SUCCESS: 'Project member has been added successfully',
      ERROR: 'Project member could not be added'
    }
  },
  ERROR: {
    GET_ALL_ERROR: {
      ERROR: 'Errors could not be found'
    },
    GET_AN_ERROR: {
      ERROR: 'Error could not be found'
    },
    DELETE_AN_ERROR: {
      SUCCESS: 'Error has been deleted successfully',
      ERROR: 'Error could not be deleted'
    },
    ERROR_FILTERS: {
      ERROR: 'Error filters could not be retrieved'
    },
    GROUPED_ERROR_FILTERS: {
      ERROR: 'Grouped Error filters could not be retrieved'
    },
    UPDATE_ERROR_GROUP: {
      SUCCESS: 'Error Group updated successfully',
      ERROR: 'Error updating error group'
    },
    UPDATE_AN_ERROR: {
      SUCCESS: 'Error updated successfully',
      ERROR: 'Error updating the error'
    },
    UPDATE_ERROR: {
      SUCCESS: 'Error updated successfully',
      ERROR: 'Error while updating  errors'
    },
    DELETE_ERROR: {
      SUCCESS: 'Error deleted successfully',
      ERROR: 'Error while deleting errors'
    }
  },

  INCIDENT: {
    CREATE_INCIDENT: {
      SUCCESS: 'Incident created successfully',
      ERROR: 'Incident could not created'
    },
    REQUIRED_FIELD: {
      ERROR: 'Please fill in all required fields'
    },
    GET_ALL_INCIDENTS: {
      ERROR: 'Incidents could not be found'
    },
    GET_STATISTICS: {
      ERROR: 'Statistics could not be found'
    },
    GET_AN_INCIDENT: {
      ERROR: 'Incident could not be found'
    },
    DELETE_AN_INCIDENT: {
      SUCCESS: 'Incident has been deleted successfully',
      ERROR: 'Incident could not be deleted'
    },
    DELETE_AN_LINK: {
      SUCCESS: 'Link deleted successfully',
      ERROR: 'Failed to delete link'
    },
    INCIDENT_FILTERS: {
      ERROR: 'Incident filters could not be retrieved'
    },
    GROUPED_INCIDENT_FILTERS: {
      ERROR: 'Grouped incident filters could not be retrieved'
    },
    ADD_A_COMMENT: {
      SUCCESS: 'Comment added successfully',
      ERROR: 'Comment could not be added'
    },
    UPDATE_A_COMMENT: {
      SUCCESS: 'Comment updated successfully',
      ERROR: 'Comment could not be updated'
    },
    ADD_A_REPLY: {
      SUCCESS: 'Reply added successfully',
      ERROR: 'Reply could not be added'
    },
    UPDATE_A_REPLY: {
      SUCCESS: 'Reply updated successfully',
      ERROR: 'Reply could not be updated'
    },
    DELETE_COMMENT: {
      SUCCESS: 'Comment deleted successfully',
      ERROR: 'Comment could not be deleted'
    },
    DELETE_REPLY: {
      SUCCESS: 'Reply deleted successfully',
      ERROR: 'Reply could not be deleted'
    },
    UPDATE_INCIDENT_GROUP: {
      SUCCESS: 'Incident Group updated successfully',
      ERROR: 'Error updating incident group'
    },
    UPDATE_AN_INCIDENT: {
      SUCCESS: 'Incident updated successfully',
      ERROR: 'Error updating the incident'
    },
    UPDATE_INCIDENTS: {
      SUCCESS: 'Incidents updated successfully',
      ERROR: 'Error while updating incidents'
    },
    DELETE_INCIDENTS: {
      SUCCESS: 'Incidents deleted successfully',
      ERROR: 'Error while deleting incidents'
    }
  },

  EXCEPTIONS: {
    GET_ALL_EXCEPTIONS: {
      ERROR: 'Exceptions could not be found'
    },
    GET_STATISTICS: {
      ERROR: 'Statistics could not be found'
    },
    GET_AN_EXCEPTION: {
      ERROR: 'Exception could not be found'
    },
    DELETE_AN_EXCEPTION: {
      SUCCESS: 'Exception has been deleted successfully',
      ERROR: 'Exception could not be deleted'
    },
    EXCEPTION_FILTERS: {
      ERROR: 'Exception filters could not be retrieved'
    },
    GROUPED_EXCEPTION_FILTERS: {
      ERROR: 'Grouped exception filters could not be retrieved'
    },
    ADD_A_COMMENT: {
      SUCCESS: 'Comment added successfully',
      ERROR: 'Comment could not be added'
    },
    UPDATE_A_COMMENT: {
      SUCCESS: 'Comment updated successfully',
      ERROR: 'Comment could not be updated'
    },
    ADD_A_REPLY: {
      SUCCESS: 'Reply added successfully',
      ERROR: 'Reply could not be added'
    },
    UPDATE_A_REPLY: {
      SUCCESS: 'Reply updated successfully',
      ERROR: 'Reply could not be updated'
    },
    DELETE_COMMENT: {
      SUCCESS: 'Comment deleted successfully',
      ERROR: 'Comment could not be deleted'
    },
    DELETE_REPLY: {
      SUCCESS: 'Reply deleted successfully',
      ERROR: 'Reply could not be deleted'
    },
    UPDATE_EXCEPTION_GROUP: {
      SUCCESS: 'Exception Group updated successfully',
      ERROR: 'Error updating exception group'
    },
    UPDATE_AN_EXCEPTION: {
      SUCCESS: 'Exception updated successfully',
      ERROR: 'Error updating the exception'
    },
    UPDATE_EXCEPTIONS: {
      SUCCESS: 'Exception updated successfully',
      ERROR: 'Error while updating  exceptions'
    },
    DELETE_EXCEPTIONS: {
      SUCCESS: 'Exception deleted successfully',
      ERROR: 'Error while deleting exceptions'
    }
  },
  EXCEPTION_ESCALATIONS: {
    CREATE_SET: {
      SUCCESS: 'Escalation set created successfully',
      ERROR: {
        DEFAULT: 'Error while creating escalation set',
        EMPTY_NAME: 'Provide a name to create an Escalation set'
      }
    },
    UPDATE_SET: {
      SUCCESS: 'Escalation set updated successfully',
      ERROR: 'Error while updating escalation set'
    },
    DELETE_SET: {
      SUCCESS: 'Escalation set deleted successfully',
      ERROR: 'Error while deleting escalation set'
    },
    CREATE_RULE: {
      SUCCESS: 'Escalation rule created successfully',
      ERROR: 'Error while creating escalation rule'
    },
    UPDATE_RULE: {
      SUCCESS: 'Escalation rule updated successfully',
      ERROR: 'Error while updating escalation rule'
    },
    DELETE_MANY_RULES: {
      SUCCESS: 'Escalation rules deleted successfully',
      ERROR: 'Error while deleting escalation rules'
    },
    CANCEL_SET: {
      SUCCESS: 'Escalation set loaded',
      ERROR: 'Error while cancelling escalation set'
    }
  },

  USER_INVITE: {
    INVITE_USER: {
      SUCCESS: 'User has been invited successfully',
      ERROR: 'User could not be invited',
      DUPLICATE_USER: 'User already in organization'
    },
    GET_ALL_MY_USER_INVITES: {
      ERROR: 'User invites could not be found'
    },
    ACCEPT_USER_INVITE: {
      SUCCESS: 'User invite has been accepted successfully',
      ERROR: 'Error while accepting User Invite'
    },
    REJECT_USER_INVITE: {
      SUCCESS: 'User invite has been rejected successfully',
      ERROR: 'Error while rejecting user invite'
    },
    REDIRECT: {
      ERROR: 'Invalid Invitation'
    }
  },
  SUBSCRIPTION: {
    SEND_EMAIL: {
      SUCCESS: 'Our sales team will reach out to you soon.',
      ERROR: 'Error in sending email'
    },
    GET_ORGANIZATION_USAGE: `It's not you, It's us. We are working on it. Please try again later.`
  },
  OVERVIEW: {
    FETCH_AHC: {
      ERROR: 'Error while fetching health check overview'
    },
    FETCH_JOB: {
      ERROR: 'Error while fetching job monitoring overview'
    },
    FETCH_API: {
      ERROR: 'Error while fetching api monitoring overview'
    },
    FETCH_EXCEPTION: {
      ERROR: 'Error while fetching exception monitoring overview'
    },
    FETCH_PROJECT_SUMMARY: {
      ERROR: 'Error while fetching project summary'
    }
  },

  SALESFORCE_AUDIT: {
    FETCH_AUDIT_DATA: {
      ERROR: 'Error while fetching audit data'
    },
    CONNECT_TO_SALESFORCE: {
      ERROR: 'Error while connecting to salesforce instance',
      SUCCESS: 'Successfully connected to salesforce instance'
    }
  },
  THINGS_TO_DO_DISMISSED: 'Dismissed from sidebar successfully'
}
