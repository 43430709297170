import React, { useState } from 'react'

import { Button } from '../New/Button/Button'
import CopyToClipboard from 'react-copy-to-clipboard'
import { DDSTypography } from 'den-design-system'
import Input from '../New/Input/Input'
import Modal from '../common/DDS/Modal/Modal'
import UptimeStringConstants from '../../constants/UptimeStringConstants'
import pallete from '../../global/newPallete'

interface SharePopupProps {
  open: boolean
  setOpen: any
  value: string
}

const UCSharePopup: React.FC<SharePopupProps> = ({ open, setOpen, value }) => {
  const [copied, setCopied] = useState(false)

  const handleCopy = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  const ShareFooter: React.FC = () => {
    return (
      <div className='flex justify-end py-[16px] px-[24px]'>
        <CopyToClipboard text={value} onCopy={handleCopy}>
          <Button
            type='filled'
            id='copy-share-link-btn'
            label={
              copied
                ? UptimeStringConstants.COPY_LINK_BUTTON_COPIED
                : UptimeStringConstants.COPY_LINK_BUTTON
            }
          />
        </CopyToClipboard>
      </div>
    )
  }

  return (
    <Modal
      open={open}
      title={UptimeStringConstants.SHARE_POPUP_TITLE}
      setOpen={setOpen}
      footer={<ShareFooter />}
      hideCloseIcon={false}
      showHeader
      onClosePress={() => {
        setOpen(false)
      }}
    >
      <div className='flex flex-col py-[32px] px-[24px] gap-[24px]'>
        <div>
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark4}
          >
            {UptimeStringConstants.SHARE_POPUP_DESCRIPTION}
          </DDSTypography.Title>
        </div>
        <div className=''>
          <Input
            width='502px'
            onChange={() => null}
            readOnlyInput
            value={value}
            id='share-link-input'
            name='Share Link'
          />
        </div>
      </div>
    </Modal>
  )
}

export default UCSharePopup
