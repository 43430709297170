import { CheckData } from '../types/store.types'
import { RootStore } from './RootStore'
import StringConstants from '../constants/StringConstants'
import { getAllHealthChecks } from '../api/appHealthCheck/AHC.service'
import { makeAutoObservable } from 'mobx'

export class AhcStore {
  data: CheckData[]
  loading: boolean
  error: string | null
  constructor(private rootStore: RootStore) {
    this.data = []
    this.loading = false
    this.error = null
    makeAutoObservable(this)
  }

  setData(data: CheckData[]) {
    this.rootStore.ahcStore.data = [...data]
  }
  getData() {
    return this.rootStore.ahcStore.data
  }
  setError(error: string | null) {
    this.rootStore.ahcStore.error = error
  }
  getError() {
    return this.rootStore.ahcStore.error
  }
  setLoading(loading: boolean) {
    this.rootStore.ahcStore.loading = loading
  }
  getLoading() {
    return this.rootStore.ahcStore.loading
  }
  async getAllChecksFromApi(page: any) {
    this.setLoading(true)
    try {
      let { data } = (await getAllHealthChecks({
        page: page,
        size: StringConstants.AHC_TABLE_SIZE
      })) as any
      data = data.map(
        ({ authUsername, authPassword, customHeaders, ...rest }: any) => ({
          authUsername: authUsername || '',
          authPassword: authPassword || '',
          customHeaders: customHeaders || { '': '' },
          ...rest
        })
      )
      this.setData(data)
      this.setError(null)
      if (
        (data.data && data.data.length > 0) ||
        this.rootStore.filterStore.getFiltersApplied()
      ) {
        this.rootStore.filterStore.setFilterDisabled(false)
      }
    } catch (e) {
      this.setError(e as string)
    } finally {
      this.setLoading(false)
    }
  }
  reset() {
    this.data = []
    this.loading = false
    this.error = null
  }
}
